import Proposal from '_models/proposal'
import { createReducer } from '_utils/redux'
import { LOGOUT } from '_modules/user/actions'
import { GET_BOOKING } from '_modules/booking/actions'

import { GET_LEASE } from '../leases/actions'

import {
  SEND_VALUE_PROPOSAL,
  SEND_PROPOSAL,
  SEND_MODIFICATION_PROPOSAL,
  SEND_APPROVATION_VALUE,
  SEND_APPROVATION_MODIFICATION,
} from './actions'

const INITIAL_STATE = new Proposal()

const proposal = createReducer(INITIAL_STATE, {
  [GET_BOOKING.FULFILLED]: (state, { payload }) => {
    return payload.lease ? new Proposal(payload.lease.proposal) : state
  },
  [SEND_PROPOSAL.FULFILLED]: (state, { payload }) => {
    return new Proposal(payload)
  },
  [SEND_VALUE_PROPOSAL.FULFILLED]: (state, { payload }) => {
    return new Proposal(payload)
  },
  [SEND_MODIFICATION_PROPOSAL.FULFILLED]: (state, { payload }) => {
    return new Proposal(payload)
  },
  [SEND_APPROVATION_VALUE.FULFILLED]: (state, { payload }) => {
    return new Proposal(payload)
  },
  [SEND_APPROVATION_MODIFICATION.FULFILLED]: (state, { payload }) => {
    return new Proposal(payload)
  },
  [GET_LEASE.FULFILLED]: (state, { payload }) => {
    return payload.proposal ? new Proposal(payload.proposal) : INITIAL_STATE
  },

  [LOGOUT]: () => INITIAL_STATE,
})

export default proposal

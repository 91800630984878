import { Map, Record, fromJS } from 'immutable'
import qs from 'qs'

import { createReducer } from '_utils/redux'
import { Lease } from '_models/'
import { LEASE_STATUS } from '_utils/constants'
import { getLeaseStatus } from '_utils/lease'
import { LOGOUT } from '_modules/user/actions'
import { getPropertyDefaultTitle } from '_utils/property'

import {
  CREATE_LEASE,
  GET_LEASE,
  UPLOAD_DOCUMENT,
  GET_LEASES,
  SEND_LEASE_TO_REVIEW,
  CANCEL_LEASE,
  SEND_TENTANT_DATA,
  SEND_TENANT_PROFESSIONAL_DATA,
  SEND_SPOUSE_DATA,
  GET_CONTRACT_PREVIEW,
  REQUEST_CONTRACT_CHANGES,
  GET_HELPER_LEASE,
  GET_ALL_LEASES,
  CREATE_LEASE_ZAP,
  SEND_USER_DATA,
  UPDATE_USER_DATA,
  UPDATE_PARTICIPANTS,
  SEND_FIRE_INSURANCE,
  SEND_MONTHLY_INCOME,
  INVITE_PARTICIPANTS,
  REGISTER_PARTICIPANT,
  REGISTER_PARTICIPANT_DOCUMENT,
  UPDATE_REGISTERED_PARTICIPANT,
  REGISTER_PARTICIPANT_SPOUSE,
  GET_PARTICIPANT_LEASE,
  DELETE_PARTICIPANT,
  SUBMIT_WARRANTY,
  UPDATE_LEASE,
} from './actions'

const INITIAL_STATE = Record({
  nextPage: undefined,
  leases: Map(),
  currentLease: undefined,
  currentParticipant: undefined,
  currentExternalId: undefined,
})()

const setNewLease = payload =>
  new Lease({
    ...payload,
    listing: { ...payload.listing, slug: getPropertyDefaultTitle(payload.listing, true) },
  })

const leases = createReducer(INITIAL_STATE, {
  [CREATE_LEASE.FULFILLED]: (state, { payload, meta }) =>
    state.setIn(['leases', meta.externalId], setNewLease(payload)),
  [CREATE_LEASE_ZAP.FULFILLED]: (state, { payload, meta }) =>
    state.setIn(['leases', meta.externalId], setNewLease(payload)),
  [INVITE_PARTICIPANTS.FULFILLED]: (state, { payload }) =>
    state.setIn(['leases', payload.listing.externalId], setNewLease(payload)),
  [SUBMIT_WARRANTY.FULFILLED]: (state, { payload }) =>
    state.setIn(['leases', payload.listing.externalId], setNewLease(payload)),
  [REGISTER_PARTICIPANT.FULFILLED]: (state, { payload }) => {
    const newParticipant = Map(payload)
    return state
      .updateIn(['leases', state.get('currentExternalId'), 'participants'], participant =>
        participant.push(newParticipant)
      )
      .setIn(['currentParticipant'], payload.id)
  },
  [REGISTER_PARTICIPANT_SPOUSE.FULFILLED]: (state, { payload }) => {
    const spouseId = state
      .getIn(['leases', state.get('currentExternalId'), 'participants'])
      .findIndex(item => item.get('id') === state.get('currentParticipant'))
    return state
      .setIn(
        ['leases', state.get('currentExternalId'), 'participants', spouseId, 'spouse'],
        payload
      )
      .setIn(['currentParticipant'], payload.id)
  },
  [REGISTER_PARTICIPANT_DOCUMENT.FULFILLED]: (state, { payload }) =>
    state.setIn(['leases', payload.listing.externalId], setNewLease(payload)),
  [UPDATE_REGISTERED_PARTICIPANT.FULFILLED]: (state, { payload }) => {
    if (payload.category === 'spouse') {
      const spouseId = state
        .getIn(['leases', state.get('currentExternalId'), 'participants'])
        .findIndex(item => item.get('id') === state.get('currentParticipant'))
      return state
        .setIn(
          ['leases', state.get('currentExternalId'), 'participants', spouseId, 'spouse'],
          payload
        )
        .set('currentParticipant', payload.id)
    }
    const participantIndex = state
      .getIn(['leases', state.get('currentExternalId'), 'participants'])
      .findIndex(item => item.get('id') === payload.id)
    return state
      .setIn(
        ['leases', state.get('currentExternalId'), 'participants', participantIndex],
        Map(payload)
      )
      .setIn(['currentParticipant'], payload.id)
  },

  [GET_LEASE.FULFILLED]: (state, { payload }) => {
    const { externalId } = payload && payload.listing
    const lease = setNewLease(payload)
    return state
      .setIn(['leases', externalId], lease)
      .set('currentLease', payload.id)
      .set('currentExternalId', externalId)
  },

  [GET_HELPER_LEASE.FULFILLED]: (state, { payload }) => {
    const lease = setNewLease(payload.lease)
    return state.setIn(['leases', lease.listing.get('externalId')], lease)
  },

  [UPLOAD_DOCUMENT.FULFILLED]: (state, { payload, meta }) =>
    state.updateIn(['leases', meta.externalId, 'documents'], documents =>
      documents.push(fromJS(payload))
    ),

  [GET_LEASES.FULFILLED]: (state, { payload }) => {
    const { results, next } = payload
    const parsedQuery = qs.parse((next || '').split('?').pop())

    const leaseList = results.reduce((accumulator, current) => {
      const lease = setNewLease(current)
      return getLeaseStatus(lease.status) === LEASE_STATUS.NOT_INITIATED
        ? accumulator
        : accumulator.set(lease.listing.get('externalId'), lease)
    }, Map())

    return state
      .set('nextPage', parsedQuery.page)
      .update('leases', currentLeases => currentLeases.mergeDeep(leaseList))
  },

  [GET_ALL_LEASES.FULFILLED]: (state, { payload }) => {
    const { results } = payload

    const leaseList = results.reduce((accumulator, current) => {
      const lease = setNewLease(current)
      return accumulator.set(lease.listing.get('externalId'), lease)
    }, Map())

    return state.update('leases', currentLeases => currentLeases.mergeDeep(leaseList))
  },

  [SEND_LEASE_TO_REVIEW.FULFILLED]: (state, { payload, meta }) =>
    state.mergeDeepIn(['leases', meta.externalId], setNewLease(payload)),

  [CANCEL_LEASE.FULFILLED]: (state, { meta }) => state.deleteIn(['leases', meta.externalId]),

  [SEND_TENTANT_DATA.FULFILLED]: (state, { payload, meta }) =>
    state.mergeDeepIn(['leases', meta.externalId], setNewLease(payload)),

  [SEND_TENANT_PROFESSIONAL_DATA.FULFILLED]: (state, { payload, meta }) =>
    state.mergeDeepIn(['leases', meta.externalId, 'user', 'professionalData'], payload),

  [SEND_SPOUSE_DATA.FULFILLED]: (state, { payload, meta }) =>
    state.mergeDeepIn(['leases', meta.externalId], setNewLease(payload)),

  [GET_CONTRACT_PREVIEW.FULFILLED]: (state, { payload, meta }) =>
    state.setIn(['leases', meta.externalId, 'contract'], URL.createObjectURL(payload)),

  [REQUEST_CONTRACT_CHANGES.FULFILLED]: (state, { payload, meta }) =>
    state.updateIn(['leases', meta.externalId, 'contractChanges'], changes =>
      changes.push(payload)
    ),

  [SEND_USER_DATA.FULFILLED]: (state, { payload, meta }) =>
    state.mergeDeepIn(['leases', meta.externalId], setNewLease(payload)),

  [UPDATE_USER_DATA.FULFILLED]: (state, { payload, meta }) =>
    state.mergeDeepIn(['leases', meta.externalId], setNewLease(payload)),

  [UPDATE_PARTICIPANTS.FULFILLED]: (state, { payload }) => {
    const participantIndex = state
      .getIn(['leases', state.currentExternalId, 'participants'])
      .findIndex(item => item.get('id') === payload.id)
    return state.mergeDeepIn(
      ['leases', state.currentExternalId, 'participants', participantIndex],
      payload
    )
  },
  [DELETE_PARTICIPANT.FULFILLED]: (state, { meta }) => {
    const participantIndex = state
      .getIn(['leases', state.currentExternalId, 'participants'])
      .findIndex(item => item.get('id') === meta.participantId)
    return state.deleteIn(['leases', state.currentExternalId, 'participants', participantIndex])
  },
  [SEND_FIRE_INSURANCE.FULFILLED]: (state, { payload, meta }) =>
    state.mergeDeepIn(['leases', meta.externalId], setNewLease(payload)),

  [LOGOUT]: () => INITIAL_STATE,

  [SEND_MONTHLY_INCOME.FULFILLED]: (state, { payload }) =>
    state.setIn(['leases', state.get('currentExternalId')], setNewLease(payload)),
  [GET_PARTICIPANT_LEASE.FULFILLED]: (state, { payload }) =>
    state.set('currentLease', payload.lease.id).set('currentExternalId', payload.lease.listing.id),
  [UPDATE_LEASE.FULFILLED]: (state, { payload }) =>
    state.set('currentLease', payload.id).set('currentExternalId', payload.listing.id),
})

export default leases

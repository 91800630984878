import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ breakpoints, palette }) => ({
  container: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr 50rem 1fr',
    padding: '4rem',

    [breakpoints.down('sm')]: {
      gridTemplateColumns: '2.4rem 1fr 2.4rem',
      padding: '4rem 0 0 0',
    },
  },
  phoneContainer: {
    gridColumn: 2,
    display: 'grid',
    alignItems: 'center',
    gridTemplateRows: 'repeat(3, auto)',
    justifyItems: 'center',
    gridGap: '0.8rem',
    marginBottom: '6.4rem',
  },
  logo: {
    width: '11rem',
    height: '15rem',
    marginBottom: '0.8rem',

    [breakpoints.down('sm')]: {
      width: '7.2rem',
      height: '9.4rem',
      marginBottom: '2.4rem',
    },
  },
  paper: {
    gridColumn: 2,
    boxShadow: 'none',
    padding: '2.4rem 10rem 5rem',
    display: 'grid',
    gridTemplateRows: 'repeat(2, auto)',
    gridGap: '2rem',
    alignItems: 'center',
    justifyContent: 'center',

    [breakpoints.down('sm')]: {
      padding: '4.8rem 2.4rem',
      marginBottom: '1.6rem',
    },
  },
  title: {
    textAlign: 'center',
    fontWeight: 'bold',
    lineHeight: 1.5,

    [breakpoints.down('sm')]: {
      fontSize: '1.6rem',
    },
  },
  stepsContainer: {
    marginTop: '2.4rem',
    display: 'grid',
    gridTemplateRows: 'repeat(4, auto)',
    gridGap: '4rem',
  },
  oneStep: {
    display: 'grid',
    gridTemplate: 'repeat(2, auto) / 5rem auto',
    gridTemplateAreas: "'image name' 'image description'",
    gridColumnGap: '4.8rem',
    textAlign: 'left',
    [breakpoints.down('sm')]: {
      gridColumnGap: '2.4rem',
    },
  },
  name: {
    gridArea: 'name',
    fontWeight: 'bold',
  },
  description: {
    gridArea: 'description',
  },
  image: {
    marginTop: '0.8rem',
    gridArea: 'image',
  },
  contact: {
    fontWeight: 'normal',

    [breakpoints.down('sm')]: {
      width: '26rem',
      textAlign: 'center',
    },
  },
  redirectButton: {
    backgroundColor: palette.gray.gallery,
    fontSize: '1.4rem',
    justifySelf: 'flex-start',
    marginBottom: '8rem',

    [breakpoints.down('sm')]: {
      marginBottom: '4rem',
    },
  },
  launchIcon: {
    width: '2rem',
    height: '2rem',
    marginLeft: '0.4rem',
  },
}))

import React from 'react'
import PropTypes from 'prop-types'

import cancelClickImage from '_assets/images/cancel-click.svg'

import ModalLayout from '../modal-negotiation/layout'

const ModalScheduleVisit = ({ text, open, onClose, onConfirm }) => {
  if (!open) {
    return null
  }

  return (
    <ModalLayout
      ariaModalTitle={text}
      buttonText="Agendar visita"
      svg={cancelClickImage}
      onClose={onClose}
      onClickButton={onConfirm}
    >
      <p>{text}</p>
      <br />
      <p>Deseja fazer uma nova visita ?</p>
    </ModalLayout>
  )
}

ModalScheduleVisit.propTypes = {
  text: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
}

ModalScheduleVisit.defaultProps = {
  text: 'Você já visitou este imóvel',
}

export default React.memo(ModalScheduleVisit)

import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from '@material-ui/core'

import Button from '_components/material/button'

import useStyles from './styles'

const DefaultModal = ({
  title,
  description,
  textButtonLeft,
  textButtonRight,
  onButtonLeftClick,
  onButtonRightClick,
  onClose,
  ariaLabel,
}) => {
  const styles = useStyles()

  const buttonStyle = useMemo(
    () => ({
      root: styles.button,
      label: styles.buttonLabel,
    }),
    [styles.button, styles.buttonLabel]
  )

  const paperProps = useMemo(
    () => ({
      classes: {
        root: styles.modal,
      },
    }),
    [styles.modal]
  )

  return (
    <Dialog open onClose={onClose} aria-describedby={ariaLabel} PaperProps={paperProps}>
      <DialogTitle className={styles.title}>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText className={styles.description}>{description}</DialogContentText>
      </DialogContent>
      <DialogActions className={styles.actions}>
        <Button onClick={onButtonLeftClick} color="secondary" classes={buttonStyle}>
          {textButtonLeft}
        </Button>
        <Divider orientation="vertical" flexItem />
        <Button onClick={onButtonRightClick} color="secondary" autoFocus classes={buttonStyle}>
          {textButtonRight}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

DefaultModal.propTypes = {
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  textButtonLeft: PropTypes.string,
  textButtonRight: PropTypes.string,
  onButtonLeftClick: PropTypes.func,
  onButtonRightClick: PropTypes.func,
  onClose: PropTypes.func,
  ariaLabel: PropTypes.string.isRequired,
}

DefaultModal.defaultProps = {
  title: '',
  description: '',
  textButtonLeft: 'CANCEL',
  textButtonRight: 'OK',
  onButtonLeftClick: () => {},
  onButtonRightClick: () => {},
  onClose: () => {},
}

export default React.memo(DefaultModal)

import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ palette }) => ({
  textField: {
    '& .MuiSelect-iconOutlined': {
      color: 'transparent',
    },
    '& .MuiSelect-root ': {
      fontSize: '1.6rem',
      color: palette.text.primary,
      padding: '1.8rem 1rem 1.4rem 0',
      boxSizing: 'border-box',
      height: '4.8rem',
    },
    '& .MuiOutlinedInput-root': {
      height: '4.8rem',
    },
    '& .MuiSvgIcon-root': {
      width: '2rem',
      height: '2rem',
    },
    '& .MuiInputBase-root': {
      paddingLeft: '1.2rem',
    },
  },
  input: {
    color: palette.text.primary,

    '&::placeholder': {
      color: palette.text.primary,
      opacity: 1,
    },
  },
  textFieldInputSort: {
    '& .MuiSelect-iconOutlined': {
      color: 'transparent',
    },
    '& .MuiSelect-root ': {
      padding: '1.2rem 1.2rem 1.2rem 0',
      boxSizing: 'border-box',
      fontSize: '1.4rem',
      color: palette.text.primary,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '4rem',
    },
    '& .MuiSvgIcon-root': {
      width: '2rem',
      height: '2rem',
      transform: 'rotate(90deg)',
    },
    '& .MuiInputBase-root': {
      paddingLeft: '1.2rem',
    },
  },
  customTooltip: {
    backgroundColor: palette.text.primary,
    fontSize: '1.4rem',
    borderRadius: '0.8rem',
    boxSizing: 'border-box',
    marginTop: '0.4rem',
    marginLeft: '0.8rem',
  },
  multipleSelect: {
    '& .MuiSelect-root ': {
      boxSizing: 'border-box',
      padding: '1.8rem 1rem 1.8rem 0',
      color: palette.text.primary,
    },
    '& .MuiSelect-icon': {
      display: 'none',
    },
  },
  root: {
    height: '4.8rem',
  },
}))

import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ breakpoints }) => ({
  propertyResultsContainer: {
    display: 'grid',
    padding: ' 1.6rem',
  },
  text: {
    display: 'block',
    fontWeight: 'normal',
    lineHeight: ' 2.4rem',
    marginTop: '0.8rem',
    fontSize: '1.6rem',
    [breakpoints.down('sm')]: {
      textAlign: 'center',
      fontSize: '1.4rem',
    },
  },
  title: {
    alignSelf: ' start',
    lineHeight: '2.4rem',
    margin: 'auto 0',

    [breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'row',

    [breakpoints.down('sm')]: {
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    },
  },
  'no-result': {
    textAlign: 'center',
    backgroundColor: 'transparent',
  },
  houseImg: {
    width: '33.4rem',
    height: '26.2rem',

    [breakpoints.down('sm')]: {
      width: '18rem',
      height: '10rem',
    },
  },
  'all-results': {
    padding: '0 10rem',
    backgroundColor: 'white',

    [breakpoints.down('sm')]: {
      padding: '2rem',
    },
  },
}))

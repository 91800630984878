/* eslint-disable */
// Project bootstrap
// e.g
// - set moment locale
// - import some global css
import 'babel-polyfill' // eslint-disable-line import/no-extraneous-dependencies
import momentImport from 'moment'
import CurrencyInput from 'react-currency-input'
import '_styles/reset.css'
import '_styles/settings.css'
import 'moment/locale/pt-br'

momentImport.updateLocale('pt-br', {
  weekdays: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
})

export const moment = momentImport

// eslint-disable-next-line
String.prototype.capitalizeAll = function(lower) {
  return (lower ? this.toLowerCase() : this).replace(/(?:^|\s)\S/g, a => a.toUpperCase())
}

// eslint-disable-next-line
String.prototype.capitalize = function(lower) {
  let string = lower ? this.toLowerCase() : this
  string = string.charAt(0).toUpperCase() + string.slice(1)
  return string
}

// eslint-disable-next-line
String.prototype.toSlug = function() {
  let str = this.replace(/^\s+|\s+$/g, '') // trim
  str = this.toLowerCase()

  // remove accents, swap ñ for n, etc
  const from = 'àáäâãāèéëêìíïîòóöôõùúüûñç·/-,:;'
  const to = 'aaaaaaeeeeiiiiooooouuuunc______'
  // eslint-disable-next-line
  for (let i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
  }

  str = str
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '_') // collapse whitespace and replace by -
    .replace(/_+/g, '_') // collapse dashes
    .replace(/_+/g, '-')

  return str
}
const componentDidMount_super = CurrencyInput.prototype.componentDidMount
CurrencyInput.prototype.componentDidMount = function() {
  if (!this.props.autoFocus) {
    const setSelectionRange_super = this.theInput.setSelectionRange
    this.theInput.setSelectionRange = () => {}
    componentDidMount_super.call(this, ...arguments)
    this.theInput.setSelectionRange = setSelectionRange_super
  } else {
    componentDidMount_super.call(this, ...arguments)
  }
}
const componentDidUpdate_super = CurrencyInput.prototype.componentDidUpdate
CurrencyInput.prototype.componentDidUpdate = function() {
  if (!this.props.autoFocus) {
    const setSelectionRange_super = this.theInput.setSelectionRange
    this.theInput.setSelectionRange = () => {}
    componentDidUpdate_super.call(this, ...arguments)
    this.theInput.setSelectionRange = setSelectionRange_super
  } else {
    componentDidMount_super.call(this, ...arguments)
  }
}

import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ breakpoints, palette }) => ({
  container: {
    margin: '2.4rem',
    display: 'grid',
    gridTemplate: 'auto / 51.4rem',
    justifyContent: 'center',
    gridGap: '0.8rem',
    [breakpoints.down('sm')]: {
      gridTemplate: 'auto / 1fr',
    },
  },
  select: {
    backgroundColor: palette.gray.main,
  },
  loading: {
    marginTop: '11rem',
  },
  button: {
    width: '10rem',
    marginLeft: 'auto',
    [breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  error: {
    fontSize: '1.4rem',
    marginBottom: '1.6rem',
  },
  form: {
    display: 'grid',
    gridGap: '4rem',
    gridTemplate: 'repeat(4, auto) / 1fr',
    marginTop: '4rem',
  },

  loader: {
    alignItems: 'center',
    display: 'flex',
    gridColumn: 2,
    height: '100vh',
    justifyContent: 'center',
  },

  mainInfos: {
    display: 'grid',
    gridGap: '2.4rem',
    gridTemplate: 'repeat(5, auto) / 1fr',
  },

  moreInfo: {
    display: 'grid',
    gridGap: '2.4rem',
    gridTemplate: '1fr / 1fr',
  },

  owner: {
    display: 'grid',
    gridColumnGap: '1.6rem',
    gridRowGap: '1.6rem',
    gridTemplate: 'auto 1fr / auto auto',
    marginTop: '0.8rem',
  },
  twoColumns: {
    display: 'grid',
    gridGap: '1.6rem',
    gridTemplate: '1fr / 1fr 1fr',
  },
  title: {
    marginTop: '2rem',
  },
}))

import React, { Fragment, useState, useCallback, useMemo } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import { TextField } from '@material-ui/core'

import MultilineTextField from '_components/modals/multiline-text-field'
import NumberFormat from '_components/number-format'

import useStyles from './styles'
import styles from './styles.css'

const MAX_CHARACTERS = 500

const Modal = ({
  className,
  title,
  description,
  textButtonLeft,
  textButtonRight,
  onButtonLeftClick,
  onButtonRightClick,
  textField,
  textFieldPlaceholder,
  open,
  onClose,
  ariaLabel,
  textFieldValue,
  textFieldCurrency,
}) => {
  const classes = useStyles()

  const [text, setText] = useState(textFieldValue || '')

  const handleOnChangeTextField = useCallback(event => {
    const { value } = event.target
    if (value.length <= MAX_CHARACTERS) setText(value)
  }, [])

  const onClickEvent = useMemo(() => {
    if (!textField) {
      return onButtonRightClick
    }

    return () => onButtonRightClick(text)
  }, [onButtonRightClick, text, textField])

  const textFieldStyle = useMemo(
    () => ({
      root: classes.textField,
    }),
    [classes.textField]
  )

  const inputProps = useMemo(
    () => ({
      inputComponent: NumberFormat,
    }),
    []
  )

  return (
    <Fragment>
      <Dialog open={open} onClose={onClose} aria-describedby={ariaLabel} PaperProps={{ classes }}>
        <div className={styles.content}>
          <h2 className={styles.title}>{title}</h2>
          <p className={styles.description}>{description}</p>
          {textField &&
            (textFieldCurrency ? (
              <TextField
                InputProps={inputProps}
                classes={textFieldStyle}
                onChange={handleOnChangeTextField}
                variant="outlined"
                value={text}
                placeholder={textFieldPlaceholder}
                helperText="R$"
              />
            ) : (
              <MultilineTextField
                onChange={handleOnChangeTextField}
                value={text}
                placeholder={textFieldPlaceholder}
                error={text.length >= MAX_CHARACTERS}
                helperText={`${text.length} / ${MAX_CHARACTERS}`}
              />
            ))}
        </div>
        <div className={classnames(styles.actions, className)}>
          <Button className={classnames(classes.label, styles.item)} onClick={onButtonLeftClick}>
            {textButtonLeft}
          </Button>
          <Button
            className={classnames(classes.label, styles.item)}
            onClick={onClickEvent}
            autoFocus
            // eslint-disable-next-line eqeqeq
            disabled={textFieldCurrency && (!text || text == 0)}
          >
            {textButtonRight}
          </Button>
        </div>
      </Dialog>
    </Fragment>
  )
}

Modal.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  textButtonLeft: PropTypes.string,
  textButtonRight: PropTypes.string,
  onButtonLeftClick: PropTypes.func,
  onButtonRightClick: PropTypes.func,
  ariaLabel: PropTypes.string,
  textField: PropTypes.bool,
  textFieldCurrency: PropTypes.bool,
  textFieldPlaceholder: PropTypes.string,
  textFieldValue: PropTypes.string,
}

Modal.defaultProps = {
  className: '',
  title: '',
  description: '',
  textButtonLeft: '',
  textButtonRight: '',
  onButtonLeftClick: () => {},
  onButtonRightClick: () => {},
  ariaLabel: '',
  textField: false,
  textFieldCurrency: false,
  textFieldPlaceholder: '',
  textFieldValue: '',
}

export default React.memo(Modal)

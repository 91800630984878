import React, { Fragment, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Fade, Grid, Link, Typography } from '@material-ui/core'

import Button, { CONTAINED_VARIANT } from '_components/material/button'
import { sendEvent } from '_utils/mixpanel'
import { useWindowSize } from '_utils/hooks'
import { GENERAL } from '_utils/constants'

import useStyles from './styles'

const CookiesModal = ({ className, closeCookiesModal, isOpen }) => {
  const styles = useStyles()
  const { isMobile } = useWindowSize()

  const handlePrivacyPolicyClick = useCallback(() => {
    sendEvent('Banner de Cookies: Selecionou navegar para Política de Privacidade')
  }, [])

  const fadeTimeout = useMemo(() => ({ enter: 1500, exit: 1500 }), [])

  return (
    <Fade in={isOpen} timeout={fadeTimeout}>
      <Grid
        alignItems="center"
        className={styles.container}
        container
        direction="column"
        justifyContent="flex-end"
      >
        <Grid
          alignItems="center"
          container
          className={classnames(styles.content, className)}
          component="section"
          direction={isMobile ? 'column' : 'row'}
          justifyContent="space-between"
        >
          <Typography
            color="textPrimary"
            component="h1"
            className={styles.text}
            variant={isMobile ? 'h6' : 'h5'}
          >
            Este site utiliza cookies para otimizar a sua experiência de navegação e oferecer a você
            um conteúdo personalizado sobre nossos produtos e serviços.{' '}
            {isMobile && (
              <Fragment>
                <br /> <br />
              </Fragment>
            )}
            Ao continuar navegando, consideramos que você está ciente de nossa{' '}
            <Link
              className={styles.link}
              href={GENERAL.privacyPolicyFile || '/politica-de-privacidade'}
              onClick={handlePrivacyPolicyClick}
              target="_blank"
            >
              Política de Privacidade.
            </Link>
          </Typography>
          <Button
            className={styles.button}
            fullWidth={isMobile}
            onClick={closeCookiesModal}
            variant={CONTAINED_VARIANT}
          >
            Aceitar
          </Button>
        </Grid>
      </Grid>
    </Fade>
  )
}

CookiesModal.propTypes = {
  className: PropTypes.string,
  closeCookiesModal: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
}

CookiesModal.defaultProps = {
  className: '',
  closeCookiesModal: () => {},
}

export default React.memo(CookiesModal)

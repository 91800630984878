import { createReducer } from '_utils/redux'
import {
  SEND_HELPER_DATA,
  SEND_HELPER_SPOUSE_DATA,
  GET_HELPER_LEASE,
  SEND_INCOME_HELPER_DATA,
  GET_PARTICIPANT_LEASE,
  SEND_PARTICIPANTS_DATA,
} from '_modules/leases/actions'
import { IncomeHelper } from '_models/'
import { LOGOUT } from '_modules/user/actions'

const INITIAL_STATE = new IncomeHelper()

const incomeHelper = createReducer(INITIAL_STATE, {
  [SEND_HELPER_DATA.FULFILLED]: (state, { payload }) => state.mergeProps({ ...payload }),

  [SEND_HELPER_SPOUSE_DATA.FULFILLED]: (state, { payload }) => state.mergeProps({ ...payload }),

  [GET_HELPER_LEASE.FULFILLED]: (state, { payload }) => state.mergeProps({ ...payload }),

  [GET_PARTICIPANT_LEASE.FULFILLED]: (state, { payload }) => state.mergeProps({ ...payload }),

  [SEND_INCOME_HELPER_DATA.FULFILLED]: (state, { payload }) => state.mergeProps({ ...payload }),

  [SEND_PARTICIPANTS_DATA.FULFILLED]: (state, { payload }) => state.mergeProps({ ...payload }),

  [LOGOUT]: () => INITIAL_STATE,
})

export default incomeHelper

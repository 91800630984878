import React, { useEffect } from 'react'
import { Link, Redirect } from '@reach/router'
import { useDispatch, useSelector } from 'react-redux'

import { primaryColor } from '_utils/colors'
import { ButtonColor } from '_components/button'
import { removeCurrentPage } from '_modules/listing-registration/actions'
import { listingId } from '_modules/listing-registration/selectors'

import ListingRegistrationFinishedContainer from '../final-step-container'

import styles from './styles.css'

const ListingRegistrationSuccess = () => {
  const dispatch = useDispatch()
  const listingRegistrationId = useSelector(listingId)

  useEffect(() => {
    dispatch(removeCurrentPage())
  })

  if (!listingRegistrationId) {
    return <Redirect to="/anunciar-imovel" noThrow />
  }

  return (
    <ListingRegistrationFinishedContainer>
      <div className={styles.body}>
        <h1 className={styles.hidden}>Registro inicial finalizado</h1>
        <p className={styles.title}>Oba!</p>
        <p className={styles.content}>
          Suas informações foram salvas e você está apto para cadastrar seu imóvel por aqui.{' '}
        </p>
      </div>
      <div className={styles.contact}>
        <Link to="/" color={ButtonColor.TEXT_BLACK} className={styles.button}>
          FAZER DEPOIS
        </Link>
        <Link
          to="/anunciar-imovel/passo-1"
          style={{ backgroundColor: primaryColor, color: 'white' }}
          className={styles.button}
        >
          CADASTRAR
        </Link>
      </div>
    </ListingRegistrationFinishedContainer>
  )
}

export default React.memo(ListingRegistrationSuccess)

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ImmutablePropTypes from 'react-immutable-proptypes'

import { buildQuerystring } from '_utils/querystring'
import { getExternalId } from '_utils/helpers'

import Base from '../base'

const mapStateToProps = ({ search: { query } }) => ({
  query,
})

const PropertyDetail = ({ id, query }) => (
  <Base
    sections={[
      {
        name: `Residenciais em ${[
          ...new Set(query.locations.map(obj => obj.get('neighborhood') || obj.get('city')).toJS()),
        ].join(', ')}`,
        url: `/buscar${buildQuerystring(query)}`,
        mixpanel: `Mapa de Navegação: Selecionou navegar para busca: ${query.locations
          .map(obj => obj.get('neighborhood') || obj.get('city'))
          .toJS()
          .join(', ')}`,
      },
      {
        name: getExternalId(id),
        url: `/imovel/${id}`,
        mixpanel: `Mapa de Navegação: Selecionou navegar para imóvel ${id}`,
      },
    ]}
  />
)

PropertyDetail.propTypes = {
  id: PropTypes.string.isRequired,
  query: ImmutablePropTypes.record.isRequired,
}

export default connect(mapStateToProps)(PropertyDetail)

import { defineAction } from '_utils/redux'
import * as utilsService from '_services/utils'

export const GET_CITIES = defineAction('GET_CITIES')
export const GET_NEIGHBORHOODS = defineAction('GET_NEIGHBORHOODS')

export const getCities = () => (dispatch, getState) =>
  dispatch({
    type: GET_CITIES.ACTION,
    payload: utilsService.getCities(getState().authToken),
  })

export const getNeighborhoods = params => (dispatch, getState) =>
  dispatch({
    type: GET_NEIGHBORHOODS.ACTION,
    payload: utilsService.getNeighborhoods(getState().authToken)(params),
  })

import React, { useMemo, useCallback, useEffect, useReducer } from 'react'
import { navigate, useLocation } from '@reach/router'
import { useSelector, useDispatch } from 'react-redux'
import TextField from '@material-ui/core/TextField'
import classnames from 'classnames'
import InputMask from 'react-input-mask'
import { Cookies } from 'react-cookie'

import { updateUser } from '_modules/user/actions'
import {
  isUpdatingUserSelector,
  updateUserErrorSelector,
  isUserLoggedIn as isUserLoggedInSelector,
  getUserInfoSelector,
} from '_modules/user/selectors'
import { usePrevious } from '_utils/hooks'
import { moment } from '_bootstrap'
import Button, { ButtonColor, ButtonVariant, ButtonFormat } from '_components/button'
import { bookProperty } from '_modules/property/actions'
import { EMAIL_REGEX } from '_utils/constants'
import { validationCPF } from '_utils/helpers'
import { getBookingLoading, getBookingError } from '_modules/property/selectors'
import { getBookingsSelector } from '_modules/booking/selectors'
import PrivacyPolicyLink from '_components/privacy-policy-link'

import { INITIAL_STATE, ON_INPUT_CHANGE, reducer } from './reducer'
import useStyles from './materialStyle'
import styles from './styles.css'

const cookies = new Cookies()

const SchedulePhoneConfirmation = () => {
  const [localState, dispatchLocal] = useReducer(reducer, INITIAL_STATE)
  const user = useSelector(getUserInfoSelector)
  const isUserLoggedIn = useSelector(isUserLoggedInSelector)
  const dispatch = useDispatch()
  const classes = useStyles()
  const location = useLocation()

  const isUpdatingUser = useSelector(isUpdatingUserSelector)
  const wasUpdatingUser = usePrevious(isUpdatingUser)

  const updateUserError = useSelector(updateUserErrorSelector)
  const isBooking = useSelector(getBookingLoading)
  const wasBooking = usePrevious(isBooking)
  const bookingError = useSelector(getBookingError)
  const bookings = useSelector(getBookingsSelector)

  useEffect(() => {
    const {
      saveQuery,
      property,
      date,
      time,
      source,
      sourceId,
      anonymousId,
      staffHash,
      recommendationHash,
    } = location.state
    if (wasUpdatingUser && !isUpdatingUser && !updateUserError.size) {
      const payload = {
        listing: property && property.externalId,
        dateAndTime: moment(date)
          .set({
            hour: time[0],
            minute: time[1],
            second: 0,
            millisecond: 0,
          })
          .add(moment().isDST() ? +1 : 0, 'hour')
          .toISOString(),
        recommendationHash: recommendationHash || cookies.get('recommendationsHash'),
        filters: saveQuery || {},
        source,
        sourceId,
        anonymousId,
        staffHash,
      }
      dispatch(bookProperty(payload))
    }
  }, [dispatch, isUpdatingUser, location.state, updateUserError.size, wasUpdatingUser])

  useEffect(() => {
    if (isUserLoggedIn) {
      dispatchLocal({
        type: ON_INPUT_CHANGE,
        payload: {
          email: user.email,
          fullName: user.fullName,
          cpf: user.cpf,
        },
      })
    }
  }, [isUserLoggedIn, user.cpf, user.email, user.fullName])

  useEffect(() => {
    const { property, date, time } = location.state
    const bookingId = bookings && bookings.first() && bookings.first().id
    const bookingLease = bookings && bookings.first() && bookings.first().lease.toJS()
    if (wasBooking && !isBooking) {
      if (bookingError.size) {
        dispatchLocal({
          type: ON_INPUT_CHANGE,
          payload: {
            errorDescription: bookingError.get('error_description') || bookingError.first(),
          },
        })
        return
      }
      navigate('/agendamento/passo-5/', {
        state: { property, time, date, bookingId, lease: bookingLease },
      })
    }
  }, [bookingError, bookingError.size, bookings, isBooking, location.state, wasBooking])

  const onInputChange = useCallback(event => {
    const { value, name } = event.target

    dispatchLocal({
      type: ON_INPUT_CHANGE,
      payload: {
        [name]: value,
      },
    })
  }, [])

  const onContinueClick = useCallback(() => {
    dispatch(
      updateUser({
        fullName: localState.fullName,
        email: localState.email,
        cpf: localState.cpf,
      })
    )
  }, [dispatch, localState.cpf, localState.email, localState.fullName])

  const validateEmail = useCallback(() => {
    const emailRegex = EMAIL_REGEX
    const emailToTest = localState.email || ''
    if (localState.email) {
      dispatchLocal({
        type: ON_INPUT_CHANGE,
        payload: !emailRegex.test(emailToTest),
      })
    } else {
      dispatchLocal({
        type: ON_INPUT_CHANGE,
        payload: false,
      })
    }
  }, [localState.email])

  const validateCPF = useCallback(() => {
    if (localState.cpf) {
      dispatchLocal({
        type: ON_INPUT_CHANGE,
        payload: !validationCPF(localState.cpf),
      })
    } else {
      dispatchLocal({
        type: ON_INPUT_CHANGE,
        payload: false,
      })
    }
  }, [localState.cpf])

  const inputProps = useMemo(
    () => ({
      className: classes.input,
    }),
    [classes.input]
  )

  const disabled = useMemo(
    () =>
      !(
        localState.fullName &&
        localState.email &&
        localState.cpf &&
        !localState.emailError &&
        !localState.cpfError
      ),
    [localState]
  )
  return (
    <div className={styles.main}>
      <div className={styles.card}>
        <h1 className={styles.title}>Número Confirmado!</h1>
        <p className={styles.subtitle}>Informe seus dados para completar o cadastro. </p>
        <TextField
          fullWidth
          variant="outlined"
          margin="normal"
          label="Nome"
          value={localState.fullName}
          name="fullName"
          onChange={onInputChange}
          aria-label="Entrada de texto para nome completo"
          className={classnames(styles.input, classes.textField)}
          InputProps={inputProps}
        />
        <TextField
          fullWidth
          variant="outlined"
          margin="normal"
          label="E-mail"
          value={localState.email}
          name="email"
          onChange={onInputChange}
          aria-label="Entrada de texto para e-mail"
          className={classnames(styles.input, classes.textField)}
          InputProps={inputProps}
          error={localState.emailError}
          onBlur={validateEmail}
        />
        <InputMask
          fullWidth
          variant="outlined"
          margin="normal"
          label="CPF"
          value={localState.cpf}
          name="cpf"
          onChange={onInputChange}
          aria-label="Entrada de texto para cpf"
          className={classnames(styles.input, classes.textField)}
          InputProps={inputProps}
          type="tel"
          mask="999.999.999-99"
          maskChar=""
          error={localState.cpfError}
          onBlur={validateCPF}
        >
          {inheritedProps => <TextField {...inheritedProps} />}
        </InputMask>
        {localState.errorDescription && (
          <p className={styles.error}>{localState.errorDescription}</p>
        )}
        <Button
          color={ButtonColor.PRIMARY}
          variant={ButtonVariant.BOLD}
          format={ButtonFormat.SQUARED}
          onClick={onContinueClick}
          className={styles.button}
          isLoading={isBooking || isUpdatingUser}
          disabled={disabled}
        >
          CONFIRMAR
        </Button>
        <PrivacyPolicyLink className={styles.terms} />
      </div>
    </div>
  )
}

export default React.memo(SchedulePhoneConfirmation)

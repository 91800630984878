import React, { useCallback, useEffect, useMemo, Fragment } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import { navigate } from '@reach/router'
import PropTypes from 'prop-types'
import { PulseLoader } from 'react-spinners'

import {
  updateListingRegistration,
  getListingRegistrationEmail,
  updateListingRegistrationEmail,
  addUserToken,
} from '_modules/listing-registration/actions'
import {
  isListingRegistrationLoading,
  hasListingRegistrationError,
  listingRegistrationFormSelector,
} from '_modules/listing-registration/selectors'
import Button, { ButtonColor, ButtonFormat, ButtonVariant } from '_components/button'
import { useWindowWidth, usePrevious, useProtectedRoute } from '_utils/hooks'
import { MOBILE_BREAKPOINT, PROPERTY_TYPES } from '_utils/constants'
import { primaryColor } from '_utils/colors'

import OutlinedField from '../../outlined-field'
import InputCarousel from '../form/input-carousel'
import ToggleSwitch from '../form/toggle-switch'
import ProgressBar from '../progress-bar'

import { OPTIONS } from './options'
import styles from './styles.css'

const {
  common: { bedrooms, ensuites, bathrooms, hobbybox, keytype },
} = OPTIONS

const ListingRegistrationDetailedStepOne = ({ location }) => {
  const listingRegistrationForm = useSelector(listingRegistrationFormSelector)
  const methods = useForm({ defaultValues: listingRegistrationForm })
  const watch = methods.watch()
  const windowWidth = useWindowWidth()
  const dispatch = useDispatch()
  const isLoading = useSelector(isListingRegistrationLoading)
  const hasRegisterError = useSelector(hasListingRegistrationError)
  const previousLoading = usePrevious(isLoading)

  const onBackClick = useCallback(() => window.history.back(), [])

  const emailToken = useMemo(
    () =>
      location.search.includes('?token=') || location.search.includes('&token=')
        ? location.search.match(/[?&]token=([^&#]*)/)[1]
        : undefined,
    [location.search]
  )
  useProtectedRoute('/anunciar-imovel', emailToken)

  const emailId = useMemo(
    () =>
      location.search.includes('?id=') || location.search.includes('&id=')
        ? location.search.match(/[?&]id=([^&#]*)/)[1]
        : undefined,
    [location.search]
  )

  useEffect(() => {
    if (emailToken) {
      dispatch(addUserToken(emailToken))
      dispatch(getListingRegistrationEmail(emailId, emailToken))
    }
  }, [dispatch, emailId, emailToken, location.search])

  useEffect(() => {
    if (previousLoading && !isLoading && !hasRegisterError) {
      navigate(['/anunciar-imovel/passo-2'])
    }
  }, [hasRegisterError, isLoading, previousLoading])
  const handleSubmit = useCallback(
    data => {
      if (emailToken) {
        const { id } = listingRegistrationForm
        return dispatch(updateListingRegistrationEmail(id, emailToken, data))
      }
      return dispatch(updateListingRegistration(data))
    },
    [dispatch, listingRegistrationForm, emailToken]
  )

  return (
    <Fragment>
      {listingRegistrationForm.id === undefined ? (
        <div className={styles.loader}>
          <PulseLoader sizeUnit="px" size={8} margin="16px" color={primaryColor} loading />
        </div>
      ) : (
        <FormContext {...methods}>
          <ProgressBar value={25} />
          <form className={styles.form} onSubmit={methods.handleSubmit(handleSubmit)}>
            <div className={styles.container}>
              {listingRegistrationForm.listingType === PROPERTY_TYPES.RES_APARTMENT ? (
                <ToggleSwitch
                  className={styles.switch}
                  key="penthouse"
                  {...OPTIONS[listingRegistrationForm.listingType].penthouse}
                />
              ) : null}
              <InputCarousel
                key={OPTIONS[listingRegistrationForm.listingType].floor.name}
                {...OPTIONS[listingRegistrationForm.listingType].floor}
              />
              <InputCarousel key={bedrooms.name} {...bedrooms} />
              <InputCarousel key={ensuites.name} {...ensuites} />
              <InputCarousel key={bathrooms.name} {...bathrooms} />
              <InputCarousel key={hobbybox.name} {...hobbybox} />
              {watch.hobbybox === 'true' && (
                <OutlinedField
                  label="Qual o número?"
                  placeholder="Qual o número?"
                  name="hobbyboxNumber"
                  className={styles['hobbybox-number']}
                  rules={{ required: '* Este campo precisa ser preenchido' }}
                />
              )}
              <InputCarousel key={keytype.name} {...keytype} />
              <InputCarousel
                key={OPTIONS[listingRegistrationForm.listingType].furnished.name}
                {...OPTIONS[listingRegistrationForm.listingType].furnished}
              >
                <p className={styles.description}>
                  <strong>Exemplo:</strong>
                </p>
                <p className={styles.description}>
                  <strong>Mobiliado:</strong> armários e cama nos quartos, mesa de jantar, banheiro
                  e cozinha completa (com geladeira e fogão) etc.
                  <br />
                  <strong>Semimobiliado:</strong> armário de cozinha e/ou de banheiro.
                </p>
              </InputCarousel>
            </div>
            <hr className={styles.separator} />
            <div className={styles.footer}>
              <Button
                color={ButtonColor.TEXT_BLACK}
                variant={ButtonVariant.BOLD}
                format={ButtonFormat.SQUARED}
                className={styles.back}
                onClick={onBackClick}
              >
                VOLTAR
              </Button>
              <Button
                color={
                  windowWidth > MOBILE_BREAKPOINT
                    ? ButtonColor.PRIMARY
                    : ButtonColor.INVERTED_PRIMARY
                }
                variant={ButtonVariant.BOLD}
                format={ButtonFormat.SQUARED}
                className={styles.next}
                type="submit"
                isLoading={isLoading}
              >
                SALVAR E CONTINUAR
              </Button>
            </div>
          </form>
        </FormContext>
      )}
    </Fragment>
  )
}

ListingRegistrationDetailedStepOne.propTypes = {
  location: PropTypes.shape({ search: PropTypes.string }).isRequired,
}

export default React.memo(ListingRegistrationDetailedStepOne)

import { makeStyles } from '@material-ui/styles'

export default makeStyles(() => ({
  customTooltip: {
    backgroundColor: '#222',
    padding: '16px',
    boxSizing: 'border-box',
    borderRadius: '4px',
    fontSize: '1.4rem',
    lineHeight: 1.8,
    color: 'white',
    width: '224px',
  },
}))

import { createSelector } from 'reselect'

const getProperties = state => state.properties
const getSearchResults = state => state.search.results
const getSearchExactResults = state => state.search.exactResults
const getSearchPartialResults = state => state.search.partialResults

export const searchSelector = state => state.search
export const nextPageSelector = state => state.search.nextPage

export const getDetailedResults = createSelector(
  [getProperties, getSearchResults],
  (properties, results) => results.valueSeq().toArray()
)

export const getExactMatches = createSelector(
  [getProperties, getSearchExactResults],
  (properties, exactResults) => exactResults.valueSeq().toArray()
)

export const getPartialMatches = createSelector(
  [getProperties, getSearchPartialResults],
  (properties, partialResults) => partialResults.valueSeq().toArray()
)

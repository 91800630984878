import React, { useCallback, Fragment, useState, useEffect } from 'react'
import classnames from 'classnames'
import { navigate } from '@reach/router'
import PropTypes from 'prop-types'

import FryCoffeeGif from '_assets/gifs/fry-coffee-gif.gif'
import FryEyeGif from '_assets/gifs/fry-eye-gif.gif'
import FryNewYearGif from '_assets/gifs/fry-new-year-gif.gif'
import FryTakeMyMoney from '_assets/gifs/fry-take-my-money.gif'
import Button, { ButtonColor, ButtonVariant, ButtonFormat } from '_components/button'
import { shadeColor, primaryColor } from '_utils/colors'

import styles from './styles.css'

const TUTORIAL_STEP = [
  {
    gif: FryCoffeeGif,
    number: 1,
    title: 'Estamos dando início ao seu contrato.',
    renderText: () => (
      <Fragment>
        Adicione suas informações no contrato preenchendo os campos abertos. Clique em{' '}
        <span className={styles.save}>“Salvar”</span> quando terminar.
      </Fragment>
    ),
  },
  {
    gif: FryEyeGif,
    number: 2,
    title: 'Edite quando quiser',
    renderText: () => (
      <Fragment>
        Ao clicar em <span className={styles.save}>“alterar dados”</span>, você consegue editar
        rapidamente as informações que já inseriu.
      </Fragment>
    ),
  },
  {
    gif: FryNewYearGif,
    number: 3,
    title: 'Insira os dados do seu cônjuge',
    renderText: () => (
      <Fragment>
        Possui cônjuge? Se sim, tenha em mãos os dados da pessoa, você precisará preencher as
        informações dela também.
      </Fragment>
    ),
  },
  {
    gif: FryTakeMyMoney,
    number: 4,
    title: 'As informações estão salvas',
    renderText: () => (
      <Fragment>
        Não quer preencher agora? Clique no botão <span className={styles.save}>“Salvar”</span> após
        inserir os seus dados pessoais e preencha as informações do cônjuge depois.
      </Fragment>
    ),
  },
]

const placeholderGif =
  'https://blog.advids.co/wp-content/uploads//2017/06/Property-Management11-min.gif'

const NegotiationTutorial = ({ id }) => {
  const lighterColor = shadeColor(primaryColor)

  const [tutorialStep, setTutorialStep] = useState(0)
  const [windowWidth, setWindowWidth] = useState(1440)
  const [isMobile, setIsMobile] = useState(false)
  const [stepColors, setStepColors] = useState({
    primary: primaryColor,
    lighter: lighterColor,
  })

  const updateWindowWidth = useCallback(() => {
    setWindowWidth(window.innerWidth)
  }, [])

  useEffect(() => {
    setWindowWidth(window.innerWidth)
    setIsMobile(windowWidth < 1440)
    window.addEventListener('resize', updateWindowWidth)

    return () => {
      window.removeEventListener('resize', updateWindowWidth)
    }
  }, [updateWindowWidth, windowWidth])

  useEffect(() => {
    setIsMobile(windowWidth < 1440)
    if (isMobile) {
      setStepColors({
        primary: 'white',
        lighter: 'rgba(255, 255, 255, 0.8)',
      })
    } else {
      setStepColors({
        primary: primaryColor,
        lighter: lighterColor,
      })
    }
  }, [isMobile, lighterColor, windowWidth])

  const onNextClick = useCallback(() => {
    if (tutorialStep <= 2) {
      setTutorialStep(tutorialStep + 1)
      return
    }

    navigate(`/minhas-negociacoes/${id}/dados-do-locatario`)
  }, [id, tutorialStep])

  return (
    <div className={styles.container}>
      <div className={styles.tutorial}>
        <img
          src={placeholderGif}
          alt={`tutorial passo ${TUTORIAL_STEP[tutorialStep].number}}`}
          className={styles['animated-gif']}
        />
        <div className={styles.section}>
          <p className={styles.number} style={{ color: primaryColor }}>
            {TUTORIAL_STEP[tutorialStep].number}
          </p>
          <p className={styles.title}>{TUTORIAL_STEP[tutorialStep].title}</p>
          <p className={styles.text}>{TUTORIAL_STEP[tutorialStep].renderText()}</p>
        </div>
        <div className={styles.dots}>
          {TUTORIAL_STEP.map(step => (
            <span
              key={step.number}
              className={classnames(styles.dot, {
                [styles['bigger-dot']]: tutorialStep + 1 === step.number,
              })}
              style={{
                backgroundColor:
                  tutorialStep + 1 === step.number ? stepColors.primary : stepColors.lighter,
              }}
            />
          ))}
        </div>
        <div className={styles['next-button-container']}>
          <Button
            className={styles['next-button']}
            color={ButtonColor.PRIMARY}
            format={ButtonFormat.ROUNDED}
            variant={ButtonVariant.BOLD}
            onClick={onNextClick}
          >
            PRÓXIMO
          </Button>
        </div>
      </div>
    </div>
  )
}

NegotiationTutorial.propTypes = {
  id: PropTypes.string.isRequired,
}

export default React.memo(NegotiationTutorial)

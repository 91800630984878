import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import InputMask from 'react-input-mask'
import memoizeOne from 'memoize-one'
import 'react-datepicker/dist/react-datepicker.css'
// eslint-disable-next-line import/no-extraneous-dependencies
import MaskedTextInput from 'react-text-mask'
import moment from 'moment'
import CurrencyInput from 'react-currency-input'

import { browserInformation } from '_utils/browser'

import styles from './styles.css'

export const InputTheme = {
  DEFAULT: 'default',
  DATA: 'data',
}

class Input extends PureComponent {
  memoizedStyle = memoizeOne((theme, isFocused, primaryColor) => {
    if (isFocused && theme === InputTheme.DEFAULT) return { border: `solid 1px ${primaryColor}` }
    return {}
  })

  static propTypes = {
    className: PropTypes.string,
    labelClassName: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onClick: PropTypes.func,
    onFocus: PropTypes.func,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
    type: PropTypes.string,
    mask: PropTypes.string,
    error: PropTypes.bool,
    ariaLabel: PropTypes.string,
    primaryColor: PropTypes.string.isRequired,
    inputClassName: PropTypes.string,
    name: PropTypes.string,
    theme: PropTypes.string,
    isCalendar: PropTypes.bool,
    isCurrency: PropTypes.bool,
    currencyAutoFocus: PropTypes.bool,
  }

  static defaultProps = {
    className: '',
    labelClassName: '',
    onChange: () => {},
    onBlur: () => {},
    onClick: () => {},
    onFocus: () => {},
    disabled: false,
    id: '',
    label: '',
    value: '',
    type: 'text',
    mask: '',
    error: false,
    ariaLabel: '',
    inputClassName: '',
    name: '',
    theme: InputTheme.DEFAULT,
    isCalendar: false,
    isCurrency: false,
    currencyAutoFocus: false,
  }

  constructor(props) {
    super(props)
    this.state = { date: props.value ? props.value : '' }
    this.browser = browserInformation()
  }

  state = {
    isFocused: false,
    date: '',
  }

  onFocus = () => {
    this.setState({
      isFocused: true,
    })
  }

  onBlur = event => {
    this.setState({
      isFocused: false,
    })
    this.props.onBlur(event)
  }

  onSelectDate = event => {
    const { onChange } = this.props
    const newDate = this.browser.isMobile
      ? moment(event.target.value, 'YYYY-MM-DD').format('DD/MM/YYYY')
      : event.target.value

    onChange(newDate)

    this.setState({
      date: newDate,
    })
  }

  getTypeInput = () => {
    const {
      labelClassName,
      onChange,
      onClick,
      onBlur,
      onFocus,
      disabled,
      id,
      label,
      value,
      type,
      mask,
      ariaLabel,
      primaryColor,
      name,
      inputClassName,
      theme,
      isCalendar,
      isCurrency,
      currencyAutoFocus,
    } = this.props
    const { isFocused, date } = this.state
    if (isCalendar) {
      return (
        <div className={styles['calendar-container']} key="calendar">
          {this.browser.isMobile ? (
            <input
              className={classnames(styles.input, inputClassName, styles['input-date-mobile'], {
                [styles['has-value']]: !value,
              })}
              placeholder={label}
              type="date"
              disabled={disabled}
              onChange={this.onSelectDate}
              value={moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD')}
            />
          ) : (
            <MaskedTextInput
              className={classnames(styles.input, inputClassName)}
              placeholder={label}
              type="text"
              mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
              onChange={this.onSelectDate}
              disabled={disabled}
              value={date}
            />
          )}
        </div>
      )
    }
    if (isCurrency) {
      return (
        <Fragment>
          <CurrencyInput
            onChangeEvent={onChange}
            id={id}
            className={classnames(styles.input, inputClassName)}
            disabled={disabled}
            onFocus={onFocus}
            onBlur={onBlur}
            onClick={onClick}
            value={value}
            placeholder={label}
            aria-label={ariaLabel}
            style={this.memoizedStyle(theme, isFocused, primaryColor)}
            name={name}
            prefix="R$ "
            decimalSeparator=","
            thousandSeparator="."
            autoFocus={currencyAutoFocus}
          />
          <div className={classnames(styles.label, labelClassName)} style={{ color: primaryColor }}>
            {label}
          </div>
        </Fragment>
      )
    }

    return (
      <Fragment>
        <InputMask
          onChange={onChange}
          id={id}
          className={classnames(styles.input, inputClassName)}
          disabled={disabled}
          onFocus={onFocus}
          onBlur={onBlur}
          onClick={onClick}
          value={value}
          placeholder={label}
          type={type}
          mask={mask}
          maskChar=""
          aria-label={ariaLabel}
          style={this.memoizedStyle(theme, isFocused, primaryColor)}
          name={name}
          inputMode=""
        />
        <div className={classnames(styles.label, labelClassName)} style={{ color: primaryColor }}>
          {label}
        </div>
      </Fragment>
    )
  }

  render() {
    const { className, value, error, theme } = this.props
    const { isFocused } = this.state
    const haveError = error && (value || theme === InputTheme.DATA)
    const HaveFocus = isFocused || value

    return (
      <div
        className={classnames(
          styles.container,
          { [styles.focus]: HaveFocus },
          { [styles.error]: haveError },
          styles[theme],
          className
        )}
      >
        {this.getTypeInput()}
      </div>
    )
  }
}

export default Input

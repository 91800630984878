import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { PulseLoader } from 'react-spinners'
import { Link } from '@reach/router'

import { onMouseDown } from '_utils/helpers'
import { calcShadowColor, primaryColor, primaryTextColor } from '_utils/colors'

import styles from './styles.css'

export const ButtonColor = {
  DEFAULT_COLOR: 'default-color',
  PRIMARY: 'primary-color',
  PRIMARY_TRANSPARENT: 'primary-transparent-color',
  PRIMARY_TEXT: 'primary-text-color',
  SECONDARY: 'secondary-color',
  TOGGLE_SELECTED: 'toggle-selected',
  TOGGLE_UNSELECTED: 'toggle-unselected',
  BLACK_WHITE: 'black-white',
  TEXT_BLACK: 'text-black',
  INVERTED_PRIMARY: 'inverted-primary',
  GHOST: 'ghost',
}

export const ButtonVariant = {
  DEFAULT: 'default',
  BOLD: 'bold',
  SMALL_ICON_TEXT: 'small-icon-text',
}

export const ButtonFormat = {
  ROUNDED: 'rounded',
  ICON: 'icon',
  SQUARED: 'squared',
}

class Button extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    color: PropTypes.oneOf(Object.keys(ButtonColor).map(key => ButtonColor[key])),
    variant: PropTypes.oneOf(Object.keys(ButtonVariant).map(key => ButtonVariant[key])),
    format: PropTypes.oneOf(Object.keys(ButtonFormat).map(key => ButtonFormat[key])),
    onClick: PropTypes.func,
    active: PropTypes.bool,
    disabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    name: PropTypes.string,
    value: PropTypes.oneOf([PropTypes.string, PropTypes.number, PropTypes.shape()]),
    ariaLabel: PropTypes.string,
    isFilterRoundButton: PropTypes.bool,
    primaryColor: PropTypes.string.isRequired,
    primaryTextColor: PropTypes.string.isRequired,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    type: PropTypes.string,
    childrenClassName: PropTypes.string,
    to: PropTypes.string,
    state: PropTypes.shape(PropTypes.any),
  }

  static defaultProps = {
    className: '',
    color: ButtonColor.DEFAULT_COLOR,
    variant: ButtonVariant.DEFAULT,
    format: ButtonFormat.ROUNDED,
    onClick: () => {},
    active: false,
    disabled: false,
    isLoading: false,
    name: '',
    ariaLabel: '',
    isFilterRoundButton: false,
    id: null,
    value: null,
    type: 'button',
    childrenClassName: '',
    to: '',
    state: {},
  }

  getButtonStyle = () => {
    const { color, active, disabled } = this.props

    const shadowColor = calcShadowColor(primaryColor)

    if (disabled) {
      return {}
    }

    if (active || color === ButtonColor.PRIMARY) {
      return {
        color: primaryTextColor,
        backgroundColor: primaryColor,
        boxShadow: `0 2px 2px 0 ${shadowColor}`,
      }
    }

    if (color === ButtonColor.PRIMARY_TRANSPARENT) {
      return {
        color: primaryColor,
        backgroundColor: `${primaryColor}24`,
        fill: primaryColor,
      }
    }

    if (color === ButtonColor.PRIMARY_TEXT) {
      return {
        color: primaryColor,
        fill: primaryColor,
        backgroundColor: 'transparent',
        boxShadow: 'none',
      }
    }

    if (color === ButtonColor.SECONDARY) {
      return {
        color: `white`,
        backgroundColor: `#222222`,
        boxShadow: `0 2px 2px 0 rgba(15, 22, 38, 0.16)`,
      }
    }

    if (color === ButtonColor.INVERTED_PRIMARY) {
      return {
        color: primaryColor,
        backgroundColor: primaryTextColor,
        boxShadow: 'none',
      }
    }

    return {}
  }

  handleClick = event => {
    event.preventDefault()
    const { id } = this.props

    if (id) {
      this.props.onClick(id)
      return
    }

    this.props.onClick(event)
  }

  renderAsLink = () => {
    const {
      children,
      className,
      color,
      variant,
      format,
      active,
      disabled,
      name,
      ariaLabel,
      isFilterRoundButton,
      to,
      state,
    } = this.props

    return (
      <Link
        to={to}
        state={state}
        style={this.getButtonStyle()}
        className={classnames(
          styles.link,
          styles.button,
          className,
          styles[color],
          styles[variant],
          styles[format],
          {
            [styles.active]: active,
            [styles['filter-round-button']]: isFilterRoundButton,
          }
        )}
        disabled={disabled}
        name={name}
        aria-label={ariaLabel}
      >
        {children}
      </Link>
    )
  }

  renderAsButton = () => {
    const {
      children,
      className,
      color,
      variant,
      format,
      active,
      disabled,
      isLoading,
      name,
      ariaLabel,
      isFilterRoundButton,
      id,
      value,
      type,
      childrenClassName,
    } = this.props

    const clickHandler = isLoading || type === 'submit' ? {} : { onClick: this.handleClick }

    return (
      // eslint-disable-next-line react/button-has-type
      <button
        className={classnames(
          styles.button,
          className,
          styles[color],
          styles[variant],
          styles[format],
          {
            [styles.active]: active,
            [styles['filter-round-button']]: isFilterRoundButton,
          }
        )}
        {...clickHandler}
        disabled={disabled}
        name={name}
        aria-label={ariaLabel}
        onMouseDown={onMouseDown}
        style={this.getButtonStyle()}
        id={id}
        value={value}
        type={type}
      >
        <div
          className={classnames(styles['spinner-container'], {
            [styles.hidden]: !isLoading,
          })}
        >
          <PulseLoader
            sizeUnit="px"
            size={8}
            margin="4px"
            color={
              color === ButtonColor.TEXT_BLACK ? `rgba(0, 0, 0, 1)` : `rgba(255, 255, 255, 0.91)`
            }
            loading
          />
        </div>
        <div className={classnames(childrenClassName, { [styles.hidden]: isLoading })}>
          {children}
        </div>
      </button>
    )
  }

  render() {
    if (this.props.to && !this.props.disabled) {
      return this.renderAsLink()
    }
    return this.renderAsButton()
  }
}

export default Button

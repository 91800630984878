import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from '@reach/router'

import { resetSearch } from '_modules/search/actions'
import RelatedSection from '_views/property/related-section'
import { searchHighlights } from '_modules/highlights/actions'
import { getDetailedHighlights } from '_modules/highlights/selectors'
import { GENERAL } from '_utils/constants'
import SEO from '_components/seo'
import { FACEBOOK_ID } from '_config/environment'

import SearchSection from './search-section'
import ChangingSection from './changing-section'
import styles from './styles.css'
import SimplifiedJourney from './simplified-journey-section'
import AnnounceSection from './announce-section'

const Landing = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const results = useSelector(getDetailedHighlights)

  useEffect(() => {
    dispatch(searchHighlights())
    dispatch(resetSearch())
  }, [dispatch])

  return (
    <section className={styles.main}>
      <SEO
        schema="WebPage"
        title={`${GENERAL.brand}`}
        description={GENERAL.metatagsHomeDescription}
        path={`${GENERAL.websiteLink}${location.pathname}`}
        contentType="website"
        image={GENERAL.brandPictureUrl}
        brand={GENERAL.brand}
        facebookAppId={FACEBOOK_ID}
      />
      <SearchSection />
      <AnnounceSection />
      <ChangingSection />
      <SimplifiedJourney />
      {!!results.length && (
        <div className={styles.carousel}>
          <h2 className={styles['carousel-title']}>Encontre o seu imóvel</h2>
          <RelatedSection className={styles['carousel-container']} properties={results} />
        </div>
      )}
    </section>
  )
}

export default React.memo(Landing)

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import ImmutablePropsTypes from 'react-immutable-proptypes'

import FeatureCard from '_components/feature-card'
import FootIcon from '_assets/images/ic-ape.svg'
import CarIcon from '_assets/images/ic-carro.svg'
import { getPropertyAddress } from '_utils/property'
import { sendEvent } from '_utils/mixpanel'
import HereMap from '_components/here-map'
import { primaryColor } from '_utils/colors'
import { HERE_KEY } from '_config/environment'

import FEATURE from './constants'
import styles from './styles.css'

class MapSection extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    title: PropTypes.string.isRequired,
    propertyPlace: PropTypes.shape().isRequired,
    establishments: PropTypes.arrayOf(PropTypes.string),
    id: PropTypes.number,
    coordinates: ImmutablePropsTypes.map.isRequired,
    citiesArray: PropTypes.arrayOf(PropTypes.string).isRequired,
  }

  static defaultProps = {
    className: '',
    establishments: [],
    id: 0,
  }

  state = {
    featureActive: FEATURE.CAR,
  }

  onFeatureClick = event => {
    event.preventDefault()
    this.setState({
      featureActive: event.target.name,
    })
    if (event.target.name === FEATURE.CAR) {
      sendEvent(`Imóvel: Selecionou carro como descolamento`)
    } else if (event.target.name === FEATURE.BUS) {
      sendEvent(`Imóvel: Selecionou ônibus como descolamento`)
    }
    if (event.target.name === FEATURE.FOOT) {
      sendEvent(`Imóvel: Selecionou a pé como descolamento`)
    } else {
      sendEvent(`Imóvel: Selecionou bicicleta como descolamento`)
    }
  }

  onMapInputChange = event => {
    const { name, value } = event.target
    this.setState({
      [name]: value,
    })
  }

  render() {
    const { className, title, propertyPlace, establishments, coordinates, citiesArray } = this.props
    const { featureActive } = this.state

    if (!propertyPlace.address) {
      return null
    }

    return (
      <div className={classnames(styles['map-section'], className)}>
        <h3>{title}</h3>
        <p className={styles.paragraph}>
          Informe o trajeto que você realiza diariamente para que a gente possa te ajudar a definir
          sua rota e quais os principais pontos de interesse que existem no seu caminho.
        </p>
        <div className={styles.filter}>
          <FeatureCard
            icon={FootIcon}
            text="A pé"
            onClick={this.onFeatureClick}
            name={FEATURE.FOOT}
            active={featureActive === FEATURE.FOOT}
            className={styles['feature-card']}
            primaryColor={primaryColor}
          />
          <FeatureCard
            icon={CarIcon}
            text="Carro"
            onClick={this.onFeatureClick}
            name={FEATURE.CAR}
            active={featureActive === FEATURE.CAR}
            className={styles['feature-card']}
          />
        </div>
        <div className={styles['map-container']}>
          <HereMap
            id={`map-${this.props.id}`}
            propertyAddress={getPropertyAddress(propertyPlace)}
            establishments={establishments}
            showEstablishments
            propertyId={this.props.id}
            featureActive={featureActive}
            coordinates={coordinates}
            primaryColor={primaryColor}
            citiesArray={citiesArray}
            hereApiKey={HERE_KEY}
          />
        </div>
      </div>
    )
  }
}

export default MapSection

import React, { Fragment, useEffect, useCallback } from 'react'
import classnames from 'classnames'
import { useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'

import { listingRegistrationFormSelector } from '_modules/listing-registration/selectors'
import InputButton from '_components/button/input-button'

import styles from '../styles.css'

export const LISTING_TYPE_OPTIONS = {
  residential: [
    { id: 'res_apartment', value: 'res_apartment', label: 'Apartamento' },
    { id: 'res_home', value: 'res_home', label: 'Casa' },
    { id: 'res_studio', value: 'res_studio', label: 'Studio' },
    { id: 'res_kitnet', value: 'res_kitnet', label: 'Kitnet' },
    { id: 'res_condo', value: 'res_condo', label: 'Casa em condomínio' },
  ],
  commercial: [
    { id: 'com_residential_income', value: 'com_residential_income', label: 'Casa' },
    { id: 'com_industrial', value: 'com_industrial', label: 'Galpão' },
    { id: 'com_retail', value: 'com_retail', label: 'Loja' },
    { id: 'com_office', value: 'com_office', label: 'Sala comercial' },
  ],
}

const Step3 = () => {
  const { listingType, purpose } = useSelector(listingRegistrationFormSelector)
  const { setValue } = useFormContext()

  useEffect(() => {
    setValue('listingType', listingType)
  }, [listingType, setValue])

  const renderFields = useCallback(fields => {
    const hasOddLength = fields.length % 2 !== 0

    return fields.map((field, index) => {
      const isLastField = index === fields.length - 1
      return (
        <InputButton
          className={classnames(styles.radio, {
            [styles['sm-grid-span-2']]: hasOddLength && isLastField,
          })}
          name="listingType"
          key={field.value}
          {...field}
        />
      )
    })
  }, [])

  return (
    <Fragment>
      <header className={styles.header}>
        <h1 className={styles.title}>Tipo de imóvel:</h1>
      </header>
      <div className={classnames(styles.group, styles['group--grid'])}>
        {renderFields(LISTING_TYPE_OPTIONS[purpose])}
      </div>
    </Fragment>
  )
}

export default React.memo(Step3)

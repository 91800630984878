import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography } from '@material-ui/core'

import Button, { ButtonTheme } from '_components/material/button'
import FileChip from '_components/file-chip'
import { getFileKey } from '_utils/helpers'

import useStyles from './styles'

const PhotosSection = ({ propertyPhotos = [], setPicturesToBeRemoved, setPropertyPhotos }) => {
  const styles = useStyles()

  const onChooseFile = useCallback(
    event => {
      const { files } = event.target
      setPropertyPhotos(prevState => {
        const addedPhotos = [...files].filter(
          file => !propertyPhotos.find(photo => getFileKey(photo) === getFileKey(file))
        )

        return [...prevState, ...addedPhotos]
      })
    },
    [propertyPhotos, setPropertyPhotos]
  )

  const onDeleteFileClick = useCallback(
    event => {
      const { id, name } = event.currentTarget
      const pictureNotSavedInDatabaseYet = !id

      if (pictureNotSavedInDatabaseYet) {
        return setPropertyPhotos(prevState => prevState.filter(file => getFileKey(file) !== name))
      }

      setPicturesToBeRemoved(prevState => [...prevState, id])
      return setPropertyPhotos(prevState => prevState.filter(file => file.id !== Number(id)))
    },
    [setPicturesToBeRemoved, setPropertyPhotos]
  )

  const renderPhotos = useMemo(
    () => (
      <Grid className={styles.photos}>
        {propertyPhotos &&
          propertyPhotos.map(
            file =>
              !!file && (
                <FileChip
                  key={file.name}
                  className={styles.file}
                  file={file}
                  onClick={onDeleteFileClick}
                  name={getFileKey(file)}
                  id={file.id}
                />
              )
          )}
      </Grid>
    ),
    [onDeleteFileClick, propertyPhotos, styles]
  )

  return (
    <Grid className={styles.container} component="section">
      <Typography className={styles.title} color="textSecondary" component="h1" variant="h4">
        Fotos
      </Typography>
      <Typography className={styles.description} color="textSecondary" component="h1" variant="h5">
        Formato .jpeg ou .png
      </Typography>
      <Grid className={styles.content}>
        <Typography color="textSecondary" variant="h5">
          Fotos do imóvel
        </Typography>
        <Button
          className={styles.button}
          variant="outlined"
          theme={ButtonTheme.WHITE_BACKGROUND}
          color="secondary"
          component="label"
        >
          Adicionar
          <input
            accept=".png, .jpeg, .jpg"
            className={styles.fileSelector}
            key="input"
            multiple="multiple"
            onChange={onChooseFile}
            type="file"
          />
        </Button>
      </Grid>
      {!!propertyPhotos.length && renderPhotos}
    </Grid>
  )
}

PhotosSection.propTypes = {
  propertyPhotos: PropTypes.string,
  setPicturesToBeRemoved: PropTypes.func,
  setPropertyPhotos: PropTypes.func,
}

PhotosSection.defaultProps = {
  propertyPhotos: '',
  setPicturesToBeRemoved: '',
  setPropertyPhotos: '',
}

export default PhotosSection

import React, { useCallback, useEffect, useContext, Fragment, useState } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { useFormik } from 'formik'
import { useSelector, useDispatch } from 'react-redux'
import { navigate } from '@reach/router'
import moment from 'moment'

import Snackbar from '_components/snackbar'
import { usePrevious } from '_utils/hooks'
import Button from '_components/material/button'
import ToggleButton from '_components/material/button/toggle-button'
import Select from '_components/material/white-select'
import TextField, { TEXTFIELD_MASK } from '_components/material/textfield'
import {
  FEATURE_FLAGS,
  WARRANTY_TYPES,
  PARTICIPANTS_INFO,
  PARTICIPANTS_LABEL,
  GENDER_VALUES,
  CIVIL_STATUS_VALUES as CIVIL_STATUS_OPTIONS,
  CIVIL_STATUS_NAMES,
  LEASE_STATUS,
  BRASIL_STATES,
  PARTICIPANTS_TYPE,
} from '_utils/constants'
import { getLeaseById } from '_modules/leases/selectors'
import {
  sendUserData,
  SEND_USER_DATA,
  sendParticipantsData,
  SEND_PARTICIPANTS_DATA,
  sendSpouseData,
  SEND_SPOUSE_DATA,
  sendParticipantsSpouseData,
  SEND_PARTICIPANTS_SPOUSE_DATA,
} from '_modules/leases/actions'
import { getIncomeHelper } from '_modules/income-helper/selectors'
import WarrantyChip from '_views/credit-analysis/negotiation/warranty-chip'
import TokenContext from '_context/token'
import useOnSuccessCall from '_hooks/use-on-success'
import useCepAutoComplete from '_hooks/use-cep-autocomplete'

import { CREDIT_ANALYSIS_ROUTES, creditAnalysisFlow } from '../../router'
import { validate } from '../validate'

import useStyles from './styles'

const COMMON_VALUES = {
  [PARTICIPANTS_INFO.FULL_NAME]: '',
  [PARTICIPANTS_INFO.EMAIL]: '',
  [PARTICIPANTS_INFO.CPF]: '',
  [PARTICIPANTS_INFO.PHONE_NUMBER]: '',
  [PARTICIPANTS_INFO.DATE_OF_BIRTH]: '',
  [PARTICIPANTS_INFO.GENDER]: '',
  [PARTICIPANTS_INFO.CIVIL_STATUS]: '',
  [PARTICIPANTS_INFO.RG]: '',
  [PARTICIPANTS_INFO.ISSUING_AGENCY]: '',
  [PARTICIPANTS_INFO.NATIONALITY]: '',
  [PARTICIPANTS_INFO.OCCUPATION]: '',
  [PARTICIPANTS_INFO.IS_TENANT]: undefined,
  [PARTICIPANTS_INFO.CONSIDER_INCOME]: undefined,
  [PARTICIPANTS_INFO.MONTHLY_INCOME]: undefined,
  [PARTICIPANTS_INFO.EMERGENCY_CONTACT_NAME]: '',
  [PARTICIPANTS_INFO.EMERGENCY_CONTACT_NUMBER]: '',
  [PARTICIPANTS_INFO.EMERGENCY_CONTACT2_NAME]: '',
  [PARTICIPANTS_INFO.EMERGENCY_CONTACT2_NUMBER]: '',
}

const INITIAL_VALUES = {
  ...COMMON_VALUES,
  [PARTICIPANTS_INFO.CURRENT_ADDRESS]: '',
}

const CREDPAGO_VALUES = {
  ...COMMON_VALUES,
  [PARTICIPANTS_INFO.CEP]: '',
  [PARTICIPANTS_INFO.ADDRESS]: '',
  [PARTICIPANTS_INFO.NEIGHBORHOOD]: '',
  [PARTICIPANTS_INFO.STREET_NUMBER]: '',
  [PARTICIPANTS_INFO.EXTRA_ADDRESS_INFO]: '',
  [PARTICIPANTS_INFO.CITY]: '',
  [PARTICIPANTS_INFO.STATE]: '',
}

const ERROR_SENDING_DATA = {
  CPF_WITH_ACTIVE_PHONE_NUMBER: PARTICIPANTS_INFO.CPF,
}

const RegisterParticipantInfo = () => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const {
    leaseId: id,
    token,
    pathname,
    locationState,
    isCredpagoFlow,
    participantType,
  } = useContext(TokenContext)
  const isSpouseFlow = pathname.includes('conjuge')
  const sendData = isSpouseFlow ? sendSpouseData : sendUserData
  const sendParticipantData = isSpouseFlow ? sendParticipantsSpouseData : sendParticipantsData
  // Get current lease
  const activeLease = useSelector(state => getLeaseById(state, id))
  const currentIncomeHelper = useSelector(getIncomeHelper)
  const warranty = token
    ? currentIncomeHelper && currentIncomeHelper.lease && currentIncomeHelper.lease.get('warranty')
    : activeLease && activeLease.warranty
  const initialValues = isCredpagoFlow ? CREDPAGO_VALUES : INITIAL_VALUES

  // previousState of asking data
  const wasAskingPersonalData = usePrevious(activeLease && activeLease.hasRequestedPersonalData)
  const wasAskingPersonalDataParticipant = usePrevious(currentIncomeHelper.hasRequestedPersonalData)
  const wasAskingProfessionalData = usePrevious(
    activeLease && activeLease.hasRequestedProfessionalData
  )
  const wasAskingProfessionalDataParticipant = usePrevious(
    currentIncomeHelper.hasRequestedProfessionalData
  )
  const [duplicatedNumber, setDuplicatedNumber] = useState()
  const [, requestError] = useOnSuccessCall(SEND_USER_DATA.ACTION)

  useEffect(() => {
    if (requestError && requestError.get('error') === 'phone_number_conflict') {
      setDuplicatedNumber('Número de telefone já cadastrado para outro usuário.')
    }
  }, [requestError])

  // TODO: Get needed documents section
  // const typeOfDocuments = useSelector(globalState =>
  //   getWarrantySelector(globalState, [warranty, participantType])
  // )
  const formik = useFormik({
    initialValues: {
      ...initialValues,
      civilStatus: isSpouseFlow ? CIVIL_STATUS_NAMES.MARRIED : '',
      // TODO: Get needed documents section
      // documents: transformArrayIntoObject(typeOfDocuments.map(({ value }) => value)),
    },
    validate,
    onSubmit: () => {
      const payload = {
        leaseId: id,
        ...formik.values,
        professionalData: {
          occupation: formik.values.occupation,
        },
      }
      if (token) {
        payload.token = token
        dispatch(
          sendParticipantData({
            payload,
            token,
          })
        )
        return
      }
      const externalId = activeLease.listing.get('externalId')

      dispatch(
        sendData({
          externalId,
          ...payload,
        })
      )
    },
  })

  const {
    handleChange,
    handleSubmit,
    errors,
    touched,
    values,
    setValues,
    setFieldValue,
    setFieldError,
  } = formik
  const [isCepLoading, data] = useCepAutoComplete(values[PARTICIPANTS_INFO.CEP])

  const handleInitialState = useCallback(
    user => {
      const initialValue = Object.keys({ ...CREDPAGO_VALUES }).reduce(
        (obj, item) => Object.assign(obj, { [item]: user[item] || '' }),
        {}
      )
      const occupation = user.professionalData ? user.professionalData.occupation : ''
      const gender = user.gender !== null ? user.gender : ''
      const civilStatus = user.civilStatus !== null ? user.civilStatus : ''
      setValues({
        ...initialValue,
        dateOfBirth: moment(user.dateOfBirth).format('DD/MM/YYYY'),
        gender,
        civilStatus,
        occupation,
        warranty,
      })
    },
    [setValues, warranty]
  )

  useEffect(() => {
    if (
      !token &&
      activeLease &&
      activeLease.user.get('fullName') &&
      values.fullName === '' &&
      !isSpouseFlow
    ) {
      handleInitialState(activeLease.user.toJS())
    }
  }, [activeLease, handleInitialState, isSpouseFlow, token, values.fullName])

  useEffect(() => {
    if (!token && activeLease && activeLease.spouse && values.fullName === '' && isSpouseFlow) {
      handleInitialState(activeLease.spouse.toJS())
    }
  }, [activeLease, handleInitialState, isSpouseFlow, token, values.fullName])

  useEffect(() => {
    if (token && currentIncomeHelper.fullName && values.fullName === '' && !isSpouseFlow) {
      handleInitialState(currentIncomeHelper.toJS())
    }
  }, [currentIncomeHelper, handleInitialState, isSpouseFlow, token, values.fullName])

  useEffect(() => {
    if (token && currentIncomeHelper.spouse && values.fullName === '' && isSpouseFlow) {
      handleInitialState(currentIncomeHelper.spouse.toJS())
    }
  }, [currentIncomeHelper.spouse, handleInitialState, isSpouseFlow, token, values.fullName])

  const handleNextStep = useCallback(
    (hasRequestedPersonalData, hasRequestedProfessionalData) => {
      if (hasRequestedPersonalData && !hasRequestedProfessionalData) {
        if (values.civilStatus === CIVIL_STATUS_NAMES.MARRIED) {
          navigate(creditAnalysisFlow(CREDIT_ANALYSIS_ROUTES.REGISTER_SPOUSE, id, token), {
            state: { wasAskingPersonalData: hasRequestedPersonalData },
          })
          return
        }
        navigate(`/garantia/cadastro-finalizado`)
        return
      }
      if (values.civilStatus === CIVIL_STATUS_NAMES.MARRIED) {
        navigate(creditAnalysisFlow(CREDIT_ANALYSIS_ROUTES.REGISTER_SPOUSE, id, token))
        return
      }
      if (token) {
        navigate(`/garantia/cadastro-finalizado`)
        return
      }
      if (activeLease.warranty === WARRANTY_TYPES.STUDENT) {
        navigate(creditAnalysisFlow(CREDIT_ANALYSIS_ROUTES.ADD_GUARANTOR, id))
        return
      }
      navigate(creditAnalysisFlow(CREDIT_ANALYSIS_ROUTES.ADD_INCOME_HELPER, id))
    },
    [activeLease.warranty, id, token, values.civilStatus]
  )

  const textFieldProps = useCallback(
    name => ({
      onChange: handleChange,
      name,
      value: values[name],
      label: PARTICIPANTS_LABEL[name],
      placeholder: PARTICIPANTS_LABEL[name],
      error: touched[name] && errors[name] && !!errors[name],
      helperText: touched[name] && errors[name],
      autoComplete: 'nope',
    }),
    [errors, handleChange, touched, values]
  )
  // TODO: Add documents for the spouse
  // const handleDocuments = useCallback(
  //   event => {
  //     const { id: documentId, file } = event
  //     const newDocuments = values.documents || {}
  //     console.log(
  //       '🚀 ~ file: index.js ~ line 250 ~ RegisterParticipantInfo ~ newDocuments',
  //       newDocuments
  //     )
  //     newDocuments[id] =
  //       newDocuments && newDocuments[Number(documentId)] && newDocuments[Number(documentId)].length
  //         ? [...newDocuments[Number(documentId)], file]
  //         : [file]
  //     setFieldValue('documents', newDocuments)
  //   },
  //   [id, setFieldValue, values.documents]
  // )
  // const handleDeleteDocuments = useCallback(
  //   event => {
  //     const { id: documentId, name } = event.currentTarget
  //     const newDocuments = values.documents
  //     newDocuments[Number(documentId)] =
  //       newDocuments && newDocuments[Number(documentId)].filter(document => document.name !== name)
  //     setFieldValue('documents', newDocuments)
  //   },
  //   [setFieldValue, values.documents]
  // )
  const handleSuccess = useCallback(() => {
    handleNextStep(wasAskingPersonalData, wasAskingProfessionalData)
  }, [handleNextStep, wasAskingPersonalData, wasAskingProfessionalData])

  const [isTenantLoading, errorSendingUserData] = useOnSuccessCall(
    SEND_USER_DATA.ACTION,
    handleSuccess
  )
  const handleParticipantData = useCallback(() => {
    handleNextStep(wasAskingPersonalDataParticipant, wasAskingProfessionalDataParticipant)
  }, [handleNextStep, wasAskingPersonalDataParticipant, wasAskingProfessionalDataParticipant])

  const [isParticipantLoading, errorSendingParticipantData] = useOnSuccessCall(
    SEND_PARTICIPANTS_DATA.ACTION,
    handleParticipantData
  )

  const handleSuccessSpouse = useCallback(() => {
    const hasRequested = locationState ? locationState.wasAskingPersonalData : false
    if (hasRequested || activeLease.status === LEASE_STATUS.INITIALLY_APPROVED) {
      navigate(`/garantia/cadastro-finalizado`)
      return
    }
    navigate(creditAnalysisFlow(CREDIT_ANALYSIS_ROUTES.ADD_INCOME_HELPER, id, token))
  }, [activeLease, id, locationState, token])

  const [isSpouseDataLoading, errorSendingSpouseData] = useOnSuccessCall(
    SEND_SPOUSE_DATA.ACTION,
    handleSuccessSpouse
  )
  const handleSuccessParticipantSpouse = useCallback(() => {
    navigate(`/garantia/cadastro-finalizado`)
  }, [])

  const [isParticipantSpouseDataLoading, errorSendingParticipantSpouseData] = useOnSuccessCall(
    SEND_PARTICIPANTS_SPOUSE_DATA.ACTION,
    handleSuccessParticipantSpouse
  )

  useEffect(() => {
    if (data && Object.keys(data).length !== 0) {
      setFieldValue(PARTICIPANTS_INFO.CITY, data.city)
      setFieldValue(PARTICIPANTS_INFO.ADDRESS, data.address)
      setFieldValue(PARTICIPANTS_INFO.NEIGHBORHOOD, data.neighborhood)
      setFieldValue(PARTICIPANTS_INFO.STATE, data.state)
    }
  }, [data, setFieldValue])

  useEffect(() => {
    if (errorSendingUserData && errorSendingUserData.size > 0) {
      setFieldError(
        ERROR_SENDING_DATA[errorSendingUserData.get('error').toUpperCase()],
        errorSendingUserData.get('error_description')
      )
    }
    if (errorSendingSpouseData && errorSendingSpouseData.size > 0) {
      setFieldError(
        ERROR_SENDING_DATA[errorSendingSpouseData.get('error').toUpperCase()],
        errorSendingSpouseData.get('error_description')
      )
    }
    if (errorSendingParticipantSpouseData && errorSendingParticipantSpouseData.size > 0) {
      setFieldError(
        ERROR_SENDING_DATA[errorSendingParticipantSpouseData.get('error').toUpperCase()],
        errorSendingParticipantSpouseData.get('error_description')
      )
    }
    if (errorSendingParticipantData && errorSendingParticipantData.size > 0) {
      setFieldError(
        ERROR_SENDING_DATA[errorSendingParticipantData.get('error').toUpperCase()],
        errorSendingParticipantData.get('error_description')
      )
    }
  }, [
    errorSendingParticipantData,
    errorSendingParticipantSpouseData,
    errorSendingSpouseData,
    errorSendingUserData,
    setFieldError,
  ])

  const showTenantToggleButton = activeLease && activeLease.warranty !== WARRANTY_TYPES.GUARANTOR
  const isButtonDisabled = showTenantToggleButton && values.isTenant === undefined
  const isLoading =
    isTenantLoading || isParticipantLoading || isSpouseDataLoading || isParticipantSpouseDataLoading

  return (
    <Grid component="section" className={styles.container}>
      {activeLease && !token && <WarrantyChip warranty={activeLease.warranty} />}
      <Typography component="h1" variant="h4" color="textPrimary" className={styles.title}>
        {isSpouseFlow ? 'Informe os dados do seu cônjuge' : 'Informe seus dados'}
      </Typography>
      <Typography component="p" variant="subtitle1" color="textSecondary">
        Informe os dados para o cadastro.
      </Typography>
      <Grid component="form" onSubmit={handleSubmit} className={styles.form}>
        <Grid className={styles.mainInfos}>
          <Typography component="h2" variant="h5" color="textSecondary">
            Dados principais
          </Typography>
          <TextField {...textFieldProps(PARTICIPANTS_INFO.FULL_NAME)} />
          <TextField {...textFieldProps(PARTICIPANTS_INFO.EMAIL)} />
          <TextField {...textFieldProps(PARTICIPANTS_INFO.CPF)} mask={TEXTFIELD_MASK.CPF} />
          <TextField
            {...textFieldProps(PARTICIPANTS_INFO.PHONE_NUMBER)}
            mask={TEXTFIELD_MASK.PHONE_NUMBER}
          />
        </Grid>
        <Grid className={styles.moreInfo}>
          <Typography component="h2" variant="h5" color="textSecondary">
            Informações Pessoais
          </Typography>
          {isSpouseFlow ? (
            <TextField
              {...textFieldProps(PARTICIPANTS_INFO.DATE_OF_BIRTH)}
              mask={TEXTFIELD_MASK.DATE}
            />
          ) : (
            <Grid className={styles.twoColumns}>
              <TextField
                {...textFieldProps(PARTICIPANTS_INFO.DATE_OF_BIRTH)}
                mask={TEXTFIELD_MASK.DATE}
              />
              <Select
                {...textFieldProps(PARTICIPANTS_INFO.CIVIL_STATUS)}
                options={CIVIL_STATUS_OPTIONS}
                selectClassName={styles.select}
                placeholder=""
              />{' '}
            </Grid>
          )}
          <Grid className={styles.twoColumns}>
            <Select
              {...textFieldProps(PARTICIPANTS_INFO.GENDER)}
              options={GENDER_VALUES}
              selectClassName={styles.select}
              placeholder=""
            />
            <TextField {...textFieldProps(PARTICIPANTS_INFO.NATIONALITY)} />
          </Grid>
          <Grid className={styles.twoColumns}>
            <TextField {...textFieldProps(PARTICIPANTS_INFO.RG)} />
            <TextField {...textFieldProps(PARTICIPANTS_INFO.ISSUING_AGENCY)} />
          </Grid>
          {isCredpagoFlow ? (
            <Fragment>
              <Grid className={styles.twoColumns}>
                <TextField {...textFieldProps(PARTICIPANTS_INFO.OCCUPATION)} />
                <TextField {...textFieldProps(PARTICIPANTS_INFO.CEP)} mask={TEXTFIELD_MASK.CEP} />
              </Grid>
              <Grid className={styles.twoColumns}>
                <TextField {...textFieldProps(PARTICIPANTS_INFO.ADDRESS)} disabled={isCepLoading} />
                <TextField
                  {...textFieldProps(PARTICIPANTS_INFO.NEIGHBORHOOD)}
                  disabled={isCepLoading}
                />
              </Grid>
              <Grid className={styles.twoColumns}>
                <TextField {...textFieldProps(PARTICIPANTS_INFO.STREET_NUMBER)} />
                <TextField {...textFieldProps(PARTICIPANTS_INFO.EXTRA_ADDRESS_INFO)} />
              </Grid>
              <Grid className={styles.twoColumns}>
                <TextField {...textFieldProps(PARTICIPANTS_INFO.CITY)} disabled={isCepLoading} />
                <Select
                  {...textFieldProps(PARTICIPANTS_INFO.STATE)}
                  disabled={isCepLoading}
                  options={BRASIL_STATES}
                  selectClassName={styles.select}
                  placeholder=""
                />
              </Grid>
            </Fragment>
          ) : (
            <Fragment>
              <TextField {...textFieldProps(PARTICIPANTS_INFO.OCCUPATION)} />
              <TextField {...textFieldProps(PARTICIPANTS_INFO.CURRENT_ADDRESS)} />
            </Fragment>
          )}
        </Grid>
        {showTenantToggleButton && (
          <ToggleButton
            label="Você será morador desse imóvel?"
            name={PARTICIPANTS_INFO.IS_TENANT}
            value={values[PARTICIPANTS_INFO.IS_TENANT]}
            onClick={handleChange}
          />
        )}{' '}
        {participantType === PARTICIPANTS_TYPE.TENANT &&
          FEATURE_FLAGS.emergencyContactEmailEnabled && (
            <Grid className={styles.moreInfo}>
              <Typography component="h2" variant="h5" color="textSecondary">
                Contatos de Emergência 1
              </Typography>
              <Grid className={styles.twoColumns}>
                <TextField {...textFieldProps(PARTICIPANTS_INFO.EMERGENCY_CONTACT_NAME)} />
                <TextField
                  {...textFieldProps(PARTICIPANTS_INFO.EMERGENCY_CONTACT_NUMBER)}
                  mask={TEXTFIELD_MASK.PHONE_NUMBER}
                />
              </Grid>
              <Typography component="h2" variant="h5" color="textSecondary">
                Contatos de Emergência 2
              </Typography>
              <Grid className={styles.twoColumns}>
                <TextField {...textFieldProps(PARTICIPANTS_INFO.EMERGENCY_CONTACT2_NAME)} />
                <TextField
                  {...textFieldProps(PARTICIPANTS_INFO.EMERGENCY_CONTACT2_NUMBER)}
                  mask={TEXTFIELD_MASK.PHONE_NUMBER}
                />
              </Grid>
            </Grid>
          )}{' '}
        {/* TODO: Add documents sections */}
        {/* <Grid className={styles.documentsWrapper}>
          {typeOfDocuments.map(document => (
            <UploadFileSection
              key={document.value}
              documentId={document.value}
              sectionName={document.label}
              handleDocument={handleDocuments}
              handleDeleteDocument={handleDeleteDocuments}
              files={values.documents}
              previousFiles={state[state.step].previousDocuments}
              handleDeletePreviousDocument={handleDeletePreviousDocument}
              percentage={
                Number(documentsPercentage.documentCategory) === document.value &&
                documentsPercentage.percentage
              }
            />
          ))}
        </Grid> */}
        <Button
          className={styles.button}
          variant="contained"
          isLoading={isLoading}
          disabled={isButtonDisabled}
          type="submit"
        >
          Próximo
        </Button>
      </Grid>
      {duplicatedNumber && (
        <Snackbar message={duplicatedNumber} variant="error" isLocalDispatch={duplicatedNumber} />
      )}
    </Grid>
  )
}

export default RegisterParticipantInfo

import React from 'react'
import Waypoint from 'react-waypoint'

import { sendEvent } from '_utils/mixpanel'

import styles from './styles.css'

const onContactClick = () => {
  sendEvent('Termos de Serviço: Selecionou link para entrar em contato')
}

export const CONTRACT = privacyPolicyRealEstateAgencyName => [
  [
    '',
    [
      <span>
        Ao se cadastrar no site {privacyPolicyRealEstateAgencyName}, você concorda em estar
        vinculado aos seguintes termos e condições (<u>o “Contrato”</u>). Ao marcar a caixa de
        seleção “Li e aceito o Contrato {privacyPolicyRealEstateAgencyName}”, você concorda com{' '}
        <u>o Contrato</u>.
      </span>,
    ],
  ],
]

export const SERVICES = (privacyPolicyRealEstateAgencyName, baseUrl) => [
  [
    '',
    [
      <span>
        Em troca do pagamento da taxa de inscrição aplicável, você poderá solicitar os serviços da
        {privacyPolicyRealEstateAgencyName}, conforme descrito em nosso site em {baseUrl}.
        Forneceremos os serviços da {privacyPolicyRealEstateAgencyName} de acordo com este Contrato
        e os demais termos e condições descritos em nosso site em {baseUrl}.
      </span>,
    ],
  ],
]

export const ACCOUNT = [
  [
    '',
    [
      <span>
        Para se tornar um cliente, você deve preencher um formulário de pedido e criar uma conta,
        fornecendo nome, sobrenome e nome da empresa (se aplicável), endereço de e-mail e qualquer
        outra informação indicada conforme necessário. Podemos rejeitar qualquer pedido ou
        solicitação de uma conta por qualquer motivo, a nosso exclusivo critério. Você reconhece que
        usaremos o endereço de e-mail fornecido por você como o principal método de comunicação.
        Você é responsável por manter a senha da sua conta segura. Nós não podemos e não seremos
        responsáveis ​​por qualquer perda ou dano decorrente de qualquer falha em manter a segurança
        da conta e senha. Se você fizer um pedido e se inscrever para uma conta em nome de seu
        empregador, seu empregador será considerado o cliente para o propósito deste Contrato, e
        você declara e garante que tem autoridade para vincular seu empregador a este Contrato. Cada
        cliente é responsável por garantir que seus funcionários, agentes e subcontratados estejam
        em conformidade com este Contrato.
      </span>,
    ],
  ],
]

export const FEES = privacyPolicyRealEstateAgencyName => [
  [
    '',
    [
      <span>
        Você pagará uma taxa pela sua assinatura, conforme especificado no formulário de pedido da
        {privacyPolicyRealEstateAgencyName}. As taxas são baseadas no prazo de assinatura e não no
        uso real, e as taxas pagas não são reembolsáveis.
      </span>,
    ],
  ],
]

export const TERMINATION = [
  [
    '',
    [
      <span>
        Qualquer uma das partes pode rescindir este Contrato a qualquer momento, com ou sem justa
        causa, imediatamente após notificação escrita ou eletrônica. Mediante a rescisão deste
        Contrato, todos os direitos concedidos a você aqui serão imediatamente cessados, incluindo,
        mas não se limitando ao direito de acessar a Conta, a menos que determinado de outra forma a
        nosso exclusivo critério.
      </span>,
    ],
  ],
]

export const INTELECTUAL_PROPERTY_RIGHTS = [
  [
    '',
    [
      <span>
        Você concorda em não nos fornecer qualquer informação confidencial ou proprietária. Cada
        parte reconhece e concorda que a outra parte pode usar livremente e explorar comercialmente
        qualquer ideia, feedback ou sugestões fornecidas por ela à outra parte.
      </span>,
    ],
  ],
]

export const RESPONSABILITY_EXEMPTION = [
  [
    '',
    [
      <span>
        OS SERVIÇOS SÃO FORNECIDOS “NO ESTADO EM QUE SE ENCONTRAM” E REJEITAMOS EXPRESSAMENTE TODAS
        AS GARANTIAS, EXPRESSAS OU IMPLÍCITAS, INCLUINDO, MAS NÃO SE LIMITANDO ÀS GARANTIAS DE
        COMERCIALIZAÇÃO, NÃO- VIOLAÇÃO E ADEQUAÇÃO A UM DETERMINADO FIM.
      </span>,
      <span>
        NENHUMA PARTE TERÁ QUALQUER RESPONSABILIDADE COM RELAÇÃO AOS SERVIÇOS OU OBRIGAÇÕES DESTE
        CONTRATO OU DE OUTRA FORMA POR QUAISQUER DANOS DIRETOS, INDIRETOS, INCIDENTAIS, ESPECIAIS,
        CONSEQÜENTES OU EXEMPLARES, INCLUINDO, MAS NÃO SE LIMITANDO A, DANOS POR PERDA DE LUCROS,
        BOA VONTADE, USO, DADOS OU OUTRAS PERDAS INTANGÍVEIS RESULTANTES DE QUALQUER MANEIRA DOS
        SERVIÇOS. EM QUALQUER CASO, CADA RESPONSABILIDADE DE CADA PARTE SOB ESTE CONTRATO POR
        QUALQUER MOTIVO SERÁ LIMITADA ÀS TAXAS PAGAS POR VOCÊ DURANTE O PERÍODO DE SEIS MESES
        IMEDIATAMENTE ANTERIOR AO EVENTO DANDO ORIGEM À REIVINDICAÇÃO DE DANOS. ESTA LIMITAÇÃO
        APLICA-SE A TODAS AS CAUSAS DE AÇÃO NO MBITO AGREGADO, INCLUINDO, MAS NÃO SE LIMITANDO A
        QUEBRA DE CONTRATO, VIOLAÇÃO DE GARANTIA, NEGLIGÊNCIA, RESPONSABILIDADE OBJETIVA,
        DESAPARECIDOS E OUTROS TORNEIROS. ESTAS LIMITAÇÕES APLICAM-SE MESMO QUE UMA PARTE TENHA SIDO
        AVISADA DA POSSIBILIDADE DE TAIS DANOS. AS LIMITAÇÕES ACIMA APLICAM-SE NA EXTENSÃO MÁXIMA
        PERMITIDA PELA LEI APLICÁVEL.
      </span>,
    ],
  ],
]

export const getGeneralProvisions = (hideSticky, showSticky, websiteLink, contectEmail) => [
  [
    '',
    [
      <span>
        Este Contrato estabelece o contrato integral e substitui todos e quaisquer acordos prévios,
        escritos ou orais, das partes com relação ao assunto aqui tratado. Reservamo-nos o direito
        de atualizar e alterar o Contrato prospectivamente publicando atualizações e alterações em{' '}
        {websiteLink}. É aconselhável verificar periodicamente o Contrato para quaisquer
        atualizações ou alterações que possam afetá-lo. Se uma alteração significativa for feita,
        forneceremos um aviso razoável por e-mail e uma oportunidade para rescindir este Contrato se
        você não concordar com a alteração.
      </span>,
      <span>
        As partes deste Contrato são contratadas independentes. Nenhuma das partes é um agente,
        representante ou entidade relacionada da outra parte. Nada neste Contrato tem a intenção de
        criar, nem será interpretado como criação, qualquer acordo exclusivo entre as partes deste
        Acordo. Este Contrato não restringe nenhuma das partes de firmar acordos semelhantes com
        outras pessoas. Cada parte entende que a outra parte pode agora ou no futuro estar envolvida
        no desenvolvimento ou fornecimento de produtos ou serviços competitivos. Nenhuma das partes
        é responsável por qualquer falha ou atraso causado por qualquer circunstância ou evento além
        de seu controle razoável.
      </span>,
      <Waypoint onEnter={hideSticky} onLeave={showSticky}>
        <span>
          Se você tiver dúvidas sobre qualquer uma das disposições descritas acima,{' '}
          <a
            href={`mailto:${contectEmail}`}
            onClick={onContactClick}
            className={styles['email-link']}
          >
            entre em contato conosco.
          </a>
        </span>
      </Waypoint>,
    ],
  ],
]

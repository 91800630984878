import React, { Fragment, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography } from '@material-ui/core'
import { useSelector } from 'react-redux'

import CardParticipant from '_components/card/participant'
import { checkIsGettingLease } from '_modules/leases/selectors'
import { getUserInfoSelector } from '_modules/user/selectors'
import { PARTICIPANTS_TYPE } from '_utils/constants'
import { Lease } from '_models/'

import useStyles from './styles'

const ParticipantsSection = ({ participants, lease, warranty }) => {
  const styles = useStyles()
  const loadingLease = useSelector(checkIsGettingLease)
  const mainTenant = useSelector(getUserInfoSelector)

  const renderParticipants = useMemo(() => {
    if (loadingLease) {
      return (
        <Grid item>
          <Typography variant="h6" component="p" className={styles.sectionText}>
            Carregando...
          </Typography>
        </Grid>
      )
    }

    if (!participants.length) {
      return (
        <Grid item>
          <Typography variant="h6" component="p" className={styles.sectionText}>
            Nenhum participante encontrado.
          </Typography>
        </Grid>
      )
    }

    const mainTenantData = mainTenant && mainTenant.toJS()

    const onlyParticipants = [
      { ...mainTenantData, registrationTag: lease.registrationTag },
      ...participants
        .filter(
          participant =>
            participant.get('category') !== PARTICIPANTS_TYPE.SPOUSE && participant.get('enabled')
        )
        .map(participant => participant.toJS()),
    ]

    return onlyParticipants.map(participant => (
      <Grid item key={participant.id} className={styles.participantWrapper}>
        <CardParticipant participant={participant} warranty={warranty} />
      </Grid>
    ))
  }, [
    lease.registrationTag,
    loadingLease,
    mainTenant,
    participants,
    styles.participantWrapper,
    styles.sectionText,
    warranty,
  ])

  return (
    <Fragment>
      <Grid item className={styles.participants}>
        <Typography variant="h5" component="p" className={styles.sectionText}>
          Participantes
        </Typography>
      </Grid>
      {renderParticipants}
    </Fragment>
  )
}

ParticipantsSection.propTypes = {
  participants: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
      label: PropTypes.string,
      name: PropTypes.string,
      status: PropTypes.shape({
        theme: PropTypes.string,
        label: PropTypes.string,
      }),
    })
  ),
  warranty: PropTypes.string,
  lease: PropTypes.instanceOf(Lease),
}

ParticipantsSection.defaultProps = {
  participants: [],
  warranty: '',
  lease: new Lease(),
}

export default ParticipantsSection

import { parseNumber, PARTICIPANTS_INFO, WARRANTY_TYPES } from '_utils/constants'
import {
  validationCPF,
  validationPhoneNumber,
  validationEmail,
  dateValidation,
} from '_utils/helpers'
import { formatDate } from '_utils/strings'

export const SET_INITIAL_STATE = 'SET_INITIAL_STATE'
export const UPDATE = 'UPDATE'
export const NEXT_STEP = 'NEXT_STEP'
export const SET_DOCUMENTS = 'SET_DOCUMENTS'
export const DELETE_DOCUMENT = 'DELETE_DOCUMENT'
export const DELETE_PREVIOUS_DOCUMENT = 'DELETE_PREVIOUS_DOCUMENT'
export const UPDATE_ADDRESS = 'UPDATE_ADDRESS'
export const STEP = {
  MAIN: 'main',
  SPOUSE: 'spouse',
}

const PERSON_INITIAL_STATE = {
  [PARTICIPANTS_INFO.FULL_NAME]: '',
  [PARTICIPANTS_INFO.EMAIL]: '',
  [PARTICIPANTS_INFO.CPF]: '',
  [PARTICIPANTS_INFO.PHONE_NUMBER]: '',
  [PARTICIPANTS_INFO.DATE_OF_BIRTH]: '',
  [PARTICIPANTS_INFO.GENDER]: '',
  [PARTICIPANTS_INFO.CIVIL_STATUS]: '',
  [PARTICIPANTS_INFO.RG_STATE_INSCRIPTION]: '',
  [PARTICIPANTS_INFO.ISSUING_AGENCY]: '',
  [PARTICIPANTS_INFO.NATIONALITY]: '',
  [PARTICIPANTS_INFO.OCCUPATION]: '',
  [PARTICIPANTS_INFO.ADDRESS]: '',
  [PARTICIPANTS_INFO.IS_TENANT]: undefined,
  [PARTICIPANTS_INFO.CONSIDER_INCOME]: undefined,
  [PARTICIPANTS_INFO.INCOME]: '',
  [STEP.CATEGORY]: '',
  [PARTICIPANTS_INFO.CEP]: '',
  [PARTICIPANTS_INFO.STATE]: '',
  [PARTICIPANTS_INFO.CITY]: '',
  [PARTICIPANTS_INFO.ADDRESS]: '',
  [PARTICIPANTS_INFO.NEIGHBORHOOD]: '',
  documents: {},
}

export const INITIAL_STATE = {
  step: STEP.MAIN,
  [STEP.MAIN]: PERSON_INITIAL_STATE,
  [STEP.SPOUSE]: PERSON_INITIAL_STATE,
  error: {},
  existingDocuments: [],
}

const REQUIRED_FIELD = 'Campo Obrigatório'
const REQUIRED_CREDPAGO_FLOW = [
  PARTICIPANTS_INFO.ADDRESS,
  PARTICIPANTS_INFO.CEP,
  PARTICIPANTS_INFO.CITY,
  PARTICIPANTS_INFO.STATE,
  PARTICIPANTS_INFO.NEIGHBORHOOD,
  PARTICIPANTS_INFO.DATE_OF_BIRTH,
]

const groupById = payload => {
  if (!payload) return {}
  const group = payload.reduce((res, obj) => {
    // for each object obj in the array arr
    const key = obj.documentCategory // let key be the concatination of locA and locB
    const newObj = { ...obj } // create a new object based on the object obj
    if (res[key])
      // if res has a sub-array for the current key then...
      res[key].push(newObj)
    // ... push newObj into that sub-array                                                        // otherwise...
    else res[key] = [newObj] // ... create a new sub-array for this key that initially contain newObj
    return res
  }, {})

  return group
}

const validate = values => {
  const errors = {}

  if (
    values[PARTICIPANTS_INFO.RG] &&
    values[PARTICIPANTS_INFO.RG].length &&
    values[PARTICIPANTS_INFO.RG].length > 16
  ) {
    errors[PARTICIPANTS_INFO.RG] = 'Número não válido'
  }

  if (values[PARTICIPANTS_INFO.CPF_CNPJ] && values[PARTICIPANTS_INFO.CPF_CNPJ].length) {
    const cpfCnpj = parseNumber(values[PARTICIPANTS_INFO.CPF_CNPJ])

    if (cpfCnpj.length === 11 && !validationCPF(cpfCnpj)) {
      errors[PARTICIPANTS_INFO.CPF_CNPJ] = 'CPF inválido'
    }
  }

  if (!validationEmail(values[PARTICIPANTS_INFO.EMAIL])) {
    errors[PARTICIPANTS_INFO.EMAIL] = 'Email inválido'
  }
  if (values[PARTICIPANTS_INFO.PHONE_NUMBER] && !values[PARTICIPANTS_INFO.PHONE_NUMBER].length) {
    errors[PARTICIPANTS_INFO.PHONE_NUMBER] = REQUIRED_FIELD
  }
  if (validationPhoneNumber(values[PARTICIPANTS_INFO.PHONE_NUMBER])) {
    errors[PARTICIPANTS_INFO.PHONE_NUMBER] = 'Insira um número válido'
  }
  if (
    parseNumber(values[PARTICIPANTS_INFO.DATE_OF_BIRTH]).length &&
    !dateValidation(values[PARTICIPANTS_INFO.DATE_OF_BIRTH])
  ) {
    errors[PARTICIPANTS_INFO.DATE_OF_BIRTH] = 'Insira uma data válida'
  }

  if (values[PARTICIPANTS_INFO.FULL_NAME] && !values[PARTICIPANTS_INFO.FULL_NAME].length) {
    errors[PARTICIPANTS_INFO.FULL_NAME] = REQUIRED_FIELD
  }

  if (values[PARTICIPANTS_INFO.EMAIL] && !values[PARTICIPANTS_INFO.EMAIL].length) {
    errors[PARTICIPANTS_INFO.EMAIL] = REQUIRED_FIELD
  }

  if (!values[PARTICIPANTS_INFO.CPF].length) {
    errors[PARTICIPANTS_INFO.CPF] = REQUIRED_FIELD
  }

  if (values.warrantyType === WARRANTY_TYPES.CREDPAGO) {
    REQUIRED_CREDPAGO_FLOW.map(field => {
      if (!values[field].length) {
        errors[field] = REQUIRED_FIELD
        return null
      }
      return null
    })
  }
  return errors
}

const setInitialState = initialState => {
  const mainInfo = { ...PERSON_INITIAL_STATE }
  Object.keys(PERSON_INITIAL_STATE).map(value => {
    mainInfo[value] = initialState[value] || ''
    return null
  })
  mainInfo.isTenant = initialState.isTenant
  mainInfo.considerIncome = initialState.considerIncome
  mainInfo.documents = {}
  if (initialState.documents) {
    mainInfo.previousDocuments = groupById(initialState.documents)
  }
  mainInfo.dateOfBirth = formatDate(initialState.dateOfBirth)
  return mainInfo
}

export const reducer = (state, action) => {
  switch (action.type) {
    case UPDATE: {
      const { step, value, name } = action.payload
      const newState = { ...state[step], [name]: value }
      const error = validate(newState)
      return { ...state, [step]: newState, error }
    }
    case NEXT_STEP: {
      return { ...state, step: STEP.SPOUSE }
    }
    case UPDATE_ADDRESS: {
      const { step, completeAddress } = action.payload
      return { ...state, [step]: { ...state[step], ...completeAddress } }
    }
    case SET_INITIAL_STATE: {
      const mainInfo = setInitialState(action.payload)

      if (action.payload.spouse && action.payload.spouse.id) {
        const spouseData = setInitialState(action.payload.spouse)
        return { ...state, [STEP.MAIN]: mainInfo, [STEP.SPOUSE]: spouseData, step: STEP.MAIN }
      }

      return { ...state, [STEP.MAIN]: mainInfo, step: STEP.MAIN }
    }
    case SET_DOCUMENTS: {
      const { documentId, file, step } = action.payload
      const documents = {
        ...state[step].documents,
        [documentId]: state[step].documents[documentId]
          ? [...state[step].documents[documentId], file]
          : [file],
      }
      return {
        ...state,
        [step]: {
          ...state[step],
          documents,
        },
      }
    }
    case DELETE_DOCUMENT: {
      const { name, step } = action.payload
      const id = Number(parseNumber(action.payload.id))
      const documents = {
        ...state[step].documents,
        [id]: state[step].documents[id].filter(file => file.name !== name),
      }
      return {
        ...state,
        [step]: {
          ...state[step],
          documents,
        },
      }
    }
    case DELETE_PREVIOUS_DOCUMENT: {
      const { name, step } = action.payload
      const id = Number(parseNumber(action.payload.id))
      const previousDocuments = {
        ...state[step].previousDocuments,
        [id]: state[step].previousDocuments[id].filter(file => file.id !== Number(name)),
      }
      return {
        ...state,
        [step]: {
          ...state[step],
          previousDocuments,
        },
      }
    }
    default:
      return state
  }
}

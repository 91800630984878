import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { moment } from '_bootstrap'
import { primaryColor } from '_utils/colors'

import styles from './styles.css'

const onMouseDown = event => {
  event.preventDefault()
}

const DateCard = ({ isSelected, isDisabled, date, onClick, name, className }) => {
  const primaryTextColor = useSelector(state => state.saas.primaryTextColor)

  return (
    <button
      className={classnames(
        styles.card,
        {
          [styles.selected]: isSelected,
          [styles.disabled]: isDisabled,
        },
        className
      )}
      name={name}
      onClick={onClick}
      onMouseDown={onMouseDown}
      disabled={isDisabled}
      style={isSelected ? { color: primaryTextColor, backgroundColor: primaryColor } : {}}
      type="button"
    >
      <h1>{moment(date).format('D')}</h1>
      <p>{moment(date).format('dddd')}</p>
    </button>
  )
}

DateCard.propTypes = {
  className: PropTypes.string,
  isSelected: PropTypes.bool,
  isDisabled: PropTypes.bool,
  date: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  name: PropTypes.number,
}

DateCard.defaultProps = {
  className: '',
  isSelected: false,
  isDisabled: false,
  onClick: () => {},
  name: undefined,
}

export default React.memo(DateCard)

import React, { useMemo, useCallback } from 'react'
import { navigate } from '@reach/router'
import PropTypes from 'prop-types'

import withAuth from '_hocs/with-auth'
import MedalIcon from '_components/icon-components/medal-icon'
import Button, { ButtonColor, ButtonVariant } from '_components/button'
import { primaryColor } from '_utils/colors'

import styles from './styles.css'

const ApprovedRegistration = ({ id }) => {
  const createTextStyle = useMemo(() => ({ color: primaryColor }), [])

  const onCancelClick = useCallback(() => navigate('/minhas-negociacoes'), [])

  const onNextClick = useCallback(() => navigate(`/minhas-negociacoes/${id}/tutorial`), [id])

  return (
    <div className={styles.main}>
      <div className={styles.content}>
        <MedalIcon primaryColor={primaryColor} />
        <h1 style={createTextStyle}>Cadastro aprovado!</h1>
        <p>Vamos seguir em frente com a sua documentação?</p>
        <div className={styles['buttons-container']}>
          <Button
            color={ButtonColor.SECONDARY}
            variant={ButtonVariant.BOLD}
            className={styles.button}
            onClick={onCancelClick}
          >
            CANCELAR
          </Button>
          <Button
            color={ButtonColor.PRIMARY}
            variant={ButtonVariant.BOLD}
            className={styles.button}
            onClick={onNextClick}
          >
            VAMOS LÁ
          </Button>
        </div>
      </div>
    </div>
  )
}

ApprovedRegistration.propTypes = {
  id: PropTypes.string.isRequired,
}

export default withAuth(React.memo(ApprovedRegistration))

import React, { useState, useCallback, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Typography, Grid, IconButton, SvgIcon } from '@material-ui/core'
import { StarBorder, Star } from '@material-ui/icons'

import HouseImg from '_assets/images/house.svg'
import { onMouseDown } from '_utils/helpers'
import Button from '_components/material/button'

import useStyles from './styles'

const VisitFeedbackStepTwo = ({ onNextClick, isLoading }) => {
  const styles = useStyles()

  const [listingRating, setRating] = useState(null)

  const handleStarClick = useCallback(event => {
    const { id } = event.currentTarget.dataset
    setRating(id)
  }, [])

  const sendFeedback = useCallback(() => {
    onNextClick({ listingRating })
  }, [listingRating, onNextClick])

  return (
    <Fragment>
      <Grid component="header" className={styles.text}>
        <Grid className={styles.imageBackground}>
          <SvgIcon aria-hidden="true" className={styles.image} viewBox={HouseImg.viewBox}>
            <use xlinkHref={HouseImg} />
          </SvgIcon>
        </Grid>
        <Typography component="h1" variant="h4" className={styles.title}>
          O que achou do imóvel?
        </Typography>
        <Typography component="p" variant="subtitle1" className={styles.description}>
          O seu feedback nos ajuda a melhorar
        </Typography>
      </Grid>
      <Grid className={styles.starsContainer}>
        {[...Array(5)].map((star, index) => (
          <IconButton
            key={`star-${index + 1}`}
            data-id={index + 1}
            onClick={handleStarClick}
            aria-label={`Botão ${index + 1} estrela`}
            onMouseDown={onMouseDown}
            type="button"
          >
            {listingRating >= index + 1 ? (
              <Star className={styles.star} />
            ) : (
              <StarBorder className={styles.emptyStar} />
            )}
          </IconButton>
        ))}
      </Grid>
      <Button
        variant="contained"
        fullWidth
        disableElevation
        color="primary"
        className={styles.button}
        disabled={!listingRating}
        onClick={sendFeedback}
        isLoading={isLoading}
      >
        Próximo
      </Button>
    </Fragment>
  )
}

VisitFeedbackStepTwo.propTypes = {
  onNextClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
}

VisitFeedbackStepTwo.defaultProps = {
  isLoading: false,
}

export default React.memo(VisitFeedbackStepTwo)

import { makeStyles } from '@material-ui/styles'

export default makeStyles(() => ({
  textField: {
    '& .MuiInputLabel-outlined': {
      fontSize: 14,
      color: 'red',
    },
  },
  input: {
    height: 48,
    width: 168,
  },
}))

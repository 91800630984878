import React, { useState, useCallback, useMemo, useEffect } from 'react'
import { Paper, Grid, Typography, TextField } from '@material-ui/core'
import InputMask from 'react-input-mask'
import { useLocation, useParams, useNavigate, Redirect } from '@reach/router'
import { useDispatch, useSelector } from 'react-redux'

import { validationPhoneNumber } from '_utils/helpers'
import { EMAIL_REGEX, GENERAL_ROUTES, OTHER_BOOKING_OPTIONS } from '_utils/constants'
import { createLead } from '_modules/booking/actions'
import Button from '_components/material/button'
import { createLeadLoadingSelector, createLeadErrorSelector } from '_modules/booking/selectors'
import { getUserInfoSelector } from '_modules/user/selectors'
import { usePrevious } from '_utils/hooks'
import { formatPhoneNumber } from '_utils/strings'
import PrivacyPolicyLink from '_components/privacy-policy-link'

import useStyles from './styles'

const initialState = {
  fullName: '',
  email: '',
  phoneNumber: '',
  emailError: false,
  phoneNumberError: false,
}
const SqueezeVisit = () => {
  const styles = useStyles()
  const location = useLocation()
  const dispatch = useDispatch()
  const { externalId } = useParams()
  const navigate = useNavigate()
  const [values, setValues] = useState(initialState)

  const user = useSelector(getUserInfoSelector)
  const hasError = useSelector(createLeadErrorSelector)
  const isLoading = useSelector(createLeadLoadingSelector)
  const wasLoading = usePrevious(isLoading)

  const onChange = useCallback(event => {
    const { name, value } = event.target
    setValues(prevValue => ({ ...prevValue, [name]: value }))
  }, [])

  const onSubmit = useCallback(
    event => {
      event.preventDefault()
      const { emailError, phoneNumberError, phoneNumber, ...userValues } = values
      if (validationPhoneNumber(phoneNumber)) {
        setValues(prevValue => ({ ...prevValue, phoneNumberError: true }))
        return
      }
      if (!EMAIL_REGEX.test(userValues.email)) {
        setValues(prevValue => ({ ...prevValue, emailError: true }))
        return
      }
      setValues(prevValue => ({ ...prevValue, emailError: false, phoneNumberError: false }))

      const neededAction = location.pathname.includes(GENERAL_ROUTES.SQUEEZE_VISIT)
        ? OTHER_BOOKING_OPTIONS.TIME_SLOT
        : OTHER_BOOKING_OPTIONS.MORE_INFO

      if (!location.state) {
        dispatch(
          createLead({
            ...userValues,
            phoneNumber: formatPhoneNumber(phoneNumber),
            listing: externalId,
            neededAction,
          })
        )
        return
      }
      const { anonymousId, source, staffHash, recommendationHash } = location.state
      const numberId = location.state.sourceId === '' ? null : Number(location.state.sourceId)

      dispatch(
        createLead({
          ...userValues,
          phoneNumber: formatPhoneNumber(phoneNumber),
          listing: externalId,
          sourceId: numberId,
          source,
          anonymousId,
          neededAction,
          staffHash,
          recommendationHash,
        })
      )
    },
    [dispatch, externalId, location.pathname, location.state, values]
  )

  const isButtonDisabled = useMemo(
    () =>
      Object.entries(values)
        .map(value => {
          if (!value[1].length && !value[0].includes('Error')) {
            return true
          }
          return false
        })
        .reduce((total, value) => total || value),
    [values]
  )

  useEffect(() => {
    if (!isLoading && wasLoading && !hasError.size) {
      navigate(`/agendamento/${externalId}/solicitacao-enviada`)
    }
  }, [externalId, hasError.size, isLoading, navigate, wasLoading])

  useEffect(() => {
    if (user && user.get('fullName')) {
      setValues(prevValues => ({
        ...prevValues,
        email: user.get('email'),
        fullName: user.get('fullName'),
        phoneNumber: user.get('phoneNumber'),
      }))
    }
  }, [user])

  if (!externalId) {
    return <Redirect to="/" noThrow />
  }
  return (
    <Grid className={styles.container}>
      <Paper component="section" className={styles.paper}>
        <Typography className={styles.title} component="h1" variant="h3">
          Informe os seus dados e um <br /> atendente entrará em contato
        </Typography>
        <Grid component="form" onSubmit={onSubmit} className={styles.inputs}>
          <TextField
            label="Nome"
            placeholder="Nome"
            fullWidth
            variant="outlined"
            value={values.fullName}
            onChange={onChange}
            margin="normal"
            name="fullName"
            className={styles.textField}
          />
          <TextField
            label="Email"
            placeholder="Email"
            fullWidth
            variant="outlined"
            value={values.email}
            onChange={onChange}
            margin="normal"
            name="email"
            className={styles.textField}
            error={values.emailError}
            helperText={values.emailError && 'Email inválido'}
          />
          <InputMask
            label="Telefone"
            placeholder="Telefone"
            fullWidth
            variant="outlined"
            value={values.phoneNumber}
            onChange={onChange}
            margin="normal"
            name="phoneNumber"
            className={styles.textField}
            mask="+99 (99) 9.9999-9999"
            maskChar=""
            type="tel"
            error={values.phoneNumberError}
            helperText={values.phoneNumberError && 'Telefone inválido'}
          >
            {inheritedProps => <TextField {...inheritedProps} />}
          </InputMask>
          {hasError.size > 0 && (
            <Typography component="span" variant="overline">
              Erro no cadastro, verifique se seus dados estão corretos
            </Typography>
          )}
          <Button
            className={styles.button}
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            disabled={isButtonDisabled}
            isLoading={isLoading}
          >
            Enviar
          </Button>
        </Grid>
        <PrivacyPolicyLink className={styles.terms} />
      </Paper>
    </Grid>
  )
}

export default React.memo(SqueezeVisit)

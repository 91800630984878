import React, { useCallback } from 'react'
import { navigate } from '@reach/router'

import Button, { ButtonVariant, ButtonFormat } from '_components/button'

import styles from './styles.css'

const AnnounceSection = () => {
  const handleClick = useCallback(() => {
    navigate('/anunciar-imovel')
  }, [])

  return (
    <section className={styles.container}>
      <div className={styles.content}>
        <div className={styles.writing}>
          <h2 className={styles.title}>Quer alugar o seu imóvel?</h2>
          <p className={styles.subtitle}>Cuidamos do seu imóvel sem burocracia.</p>
          <p className={styles.text}>
            Alugue seu imóvel rapidamente através de uma imobiliária que entende do assunto com um
            processo transparente e sem burocracia.
          </p>
        </div>
        <div className={styles['button-container']}>
          <Button
            color="black"
            variant={ButtonVariant.BOLD}
            format={ButtonFormat.SQUARED}
            className={styles.button}
            onClick={handleClick}
          >
            ANUNCIE SEU IMÓVEL!
          </Button>
        </div>
      </div>
    </section>
  )
}

export default React.memo(AnnounceSection)

import { schema } from 'normalizr'
import { List } from 'immutable'

import Model from './model'

class RecommendationsProperty extends Model({
  externalId: null,
  id: null,
  address: null,
  amenities: null,
  available: null,
  bathrooms: null,
  bedrooms: null,
  city: null,
  condominiumDescription: null,
  condominiumFee: null,
  coordinates: null,
  description: null,
  exact: null,
  furnished: null,
  insurance: null,
  listingDescription: null,
  listingType: null,
  livingArea: null,
  neighborhood: null,
  numberOfEnsuites: null,
  parkingSpots: null,
  pets: null,
  pictures: [], // TODO: check this to use immutable list
  convertedPictures: List(),
  postalCode: null,
  rentPrice: null,
  state: null,
  streetNumber: null,
  taxes: null,
  title: null,
  totalArea: null,
  transactionType: null,
  url: null,
  video: null,
  bookingStatus: null,
  booking: null,
  notes: null,
  slug: null,
}) {}

const recommendationsPropertySchema = new schema.Entity(
  'recommendationsProperties',
  {},
  {
    idAttribute: 'external_id',
    processStrategy: entity => new RecommendationsProperty({ ...entity, slug: entity.external_id }),
  }
)

export { recommendationsPropertySchema, RecommendationsProperty as default }

import { createSelector } from 'reselect'

import { GET_NEIGHBORHOODS } from './actions'

export const getNeighborhoodsLoading = state => !!state.loading.get(GET_NEIGHBORHOODS.ACTION)
export const getServer = state => state.server
export const snackbarSelector = state => ({
  message: state.utils.snackbarMessage,
  variant: state.utils.snackbarVariant,
})

const getCities = state => state.utils.cities
const getNeighborhood = state => state.utils.neighborhoods

export const getCitiesValues = createSelector([getCities], cities =>
  cities.size
    ? cities.map(item => ({
        label: item.name,
        value: item.slug,
      }))
    : []
)

export const getNeighborhoodValues = createSelector([getNeighborhood], neighborhoods =>
  neighborhoods.size
    ? neighborhoods.map(item => ({
        label: item.name,
        value: item.slug,
      }))
    : []
)

/* eslint-disable import/no-cycle */
import { createSelector } from 'reselect'

import { Lease } from '_models/'
import { LEASE_STATUS } from '_utils/constants'
import { getLeaseStatus } from '_utils/lease'

import {
  CREATE_LEASE,
  CREATE_LEASE_ZAP,
  GET_LEASE,
  UPLOAD_DOCUMENT,
  CREATE_INCOME_HELPER,
  SEND_LEASE_TO_REVIEW,
  CANCEL_LEASE,
  SEND_TENTANT_DATA,
  SEND_TENANT_PROFESSIONAL_DATA,
  SEND_SPOUSE_DATA,
  GENERATE_CONTRACT_LINK,
  GET_HELPER_LEASE,
  REQUEST_CONTRACT_CHANGES,
  SEND_HELPER_DATA,
  UPLOAD_HELPER_DOCUMENT,
  SEND_HELPER_SPOUSE_DATA,
  SEND_RATE,
  CREATE_MULTIPLE_INCOME_HELPER,
  SEND_INCOME_HELPER_DATA,
  INVITE_PARTICIPANTS,
  SUBMIT_WARRANTY,
  SEND_USER_DATA,
  UPDATE_USER_DATA,
  UPDATE_PARTICIPANTS,
  SEND_PARTICIPANTS_DATA,
  UPLOAD_PARTICIPANTS_DOCUMENT,
  SEND_PARTICIPANTS_SPOUSE_DATA,
  SEND_FIRE_INSURANCE,
  SEND_MONTHLY_INCOME,
  GET_PARTICIPANT_LEASE,
} from './actions'

const getLeases = ({ leases }) => leases.get('leases')
const getParam = (state, param) => param

export const checkIsGettingLease = ({ loading }) => loading.get(GET_LEASE.ACTION)
export const getParticipantLeaseLoadingSelector = ({ loading }) =>
  loading.get(GET_PARTICIPANT_LEASE.ACTION)
export const getCreatingLeaseZap = ({ loading }) => loading.get(CREATE_LEASE_ZAP.ACTION)

export const getCreatingLeaseZapError = state => !!state.error.get(CREATE_LEASE_ZAP.ACTION)
const checkIsGettingHelperLease = ({ loading }) => loading.get(GET_HELPER_LEASE.ACTION)
const checkIsUploadingHelperLease = ({ loading }) => loading.get(UPLOAD_HELPER_DOCUMENT.ACTION)
export const getUser = createSelector(
  state => state.user,
  user => user
)

export const getLeaseByListingId = createSelector([getLeases, getParam], (leases, id) =>
  leases.get(id)
)

export const getCreateLeaseLoading = state => state.loading.get(CREATE_LEASE.ACTION)
export const getCreateLeaseError = state => !!state.error.get(CREATE_LEASE.ACTION)
export const checkIsSendingMonthlyIncome = state => state.loading.get(SEND_MONTHLY_INCOME.ACTION)
export const checkErrorSendMonthlyIncome = state => !!state.error.get(SEND_MONTHLY_INCOME.ACTION)
export const getUpdateHelperLoading = state => state.loading.get(SEND_HELPER_DATA.ACTION)

export const getUpdateHelperError = state => !!state.error.get(SEND_HELPER_DATA.ACTION)
export const getLeaseById = createSelector([getLeases, getParam], (leases, id) => {
  const currentLease = leases.find(lease => lease.get('id') === Number(id))
  if (currentLease && currentLease.participants && currentLease.participants.size) {
    return currentLease.updateIn(['participants'], participants =>
      participants.filter(participant => participant && !participant.get('contractOnly'))
    )
  }
  return currentLease || new Lease()
})

export const getContractByIdSelector = createSelector([getLeases, getParam], (leases, params) => {
  const [leaseId, contractId] = params
  const currentLease = leases.find(lease => lease.get('id') === Number(leaseId))

  return (
    currentLease &&
    currentLease.contracts.find(contract => contract.get('id') === Number(contractId))
  )
})

export const checkIsLoadingLease = createSelector(
  [checkIsGettingLease, checkIsGettingHelperLease],
  (isLoading, isLoadingIncomeHelper) => isLoading || isLoadingIncomeHelper
)

export const hasLeaseErrorSelector = state => !!state.error.get(GET_LEASE.ACTION)
export const getParticipantLeaseErrorSelector = state =>
  !!state.error.get(GET_PARTICIPANT_LEASE.ACTION)
export const checkIsLoadingDocuments = state => state.loading.get(UPLOAD_DOCUMENT.ACTION)

export const checkIsAddingIncomeHelper = state => state.loading.get(CREATE_INCOME_HELPER.ACTION)

export const checkIsAddingIncomeHelpers = state =>
  state.loading.get(CREATE_MULTIPLE_INCOME_HELPER.ACTION)

export const checkIsSendingToReview = state => state.loading.get(SEND_LEASE_TO_REVIEW.ACTION)
export const checkErrorAddingIncomeHelpers = state =>
  !!state.error.get(CREATE_MULTIPLE_INCOME_HELPER.ACTION)
export const checkErrorSendingParticipantsData = state =>
  !!state.error.get(SEND_PARTICIPANTS_DATA.ACTION)

export const checkErrorSendingToReview = state => !!state.error.get(SEND_LEASE_TO_REVIEW.ACTION)

export const checkIsLoadingHelperDocuments = createSelector(
  [checkIsUploadingHelperLease, checkIsUploadingHelperLease],
  (loadingDocs, loadingHelperDocs) => loadingDocs || loadingHelperDocs
)

export const getFilteredLeases = createSelector([getLeases], leases =>
  leases.filter(
    lease =>
      ![
        LEASE_STATUS.NOT_INITIATED,
        LEASE_STATUS.CANCELLED,
        LEASE_STATUS.PROPOSAL_CANCELLED,
      ].includes(getLeaseStatus(lease.status)) && !lease.status.includes('booking')
  )
)

export const checkIsCancellingLease = state => state.loading.get(CANCEL_LEASE.ACTION)
export const errorCancelLease = state => state.error.get(CANCEL_LEASE.ACTION)

export const checkIsSendingTenantData = state => state.loading.get(SEND_TENTANT_DATA.ACTION)

export const checkIsSendingTenantProfessionalData = state =>
  state.loading.get(SEND_TENANT_PROFESSIONAL_DATA.ACTION)

export const checkIsSendingSpouseData = state => state.loading.get(SEND_SPOUSE_DATA.ACTION)

export const checkIsSendingHelperSpouseData = state =>
  state.loading.get(SEND_HELPER_SPOUSE_DATA.ACTION)

export const checkIsLoadingContractLink = state => state.loading.get(GENERATE_CONTRACT_LINK.ACTION)

export const checkIsRequestingContractChanges = state =>
  state.loading.get(REQUEST_CONTRACT_CHANGES.ACTION)
export const checkIsSendingRate = state => state.loading.get(SEND_RATE.ACTION)

export const checkIsSendingIncomeHelperData = state =>
  state.loading.get(SEND_INCOME_HELPER_DATA.ACTION)
export const checkIsCreatingParticipants = state => state.loading.get(INVITE_PARTICIPANTS.ACTION)

export const checkIsSubmittingWarranty = state => state.loading.get(SUBMIT_WARRANTY.ACTION)

export const checkErrorSubmittingWarranty = state => !!state.error.get(SUBMIT_WARRANTY.ACTION)

export const checkIsSendingUserData = state => state.loading.get(SEND_USER_DATA.ACTION)

export const checkIsUpdatingUserData = state => state.loading.get(UPDATE_USER_DATA.ACTION)

export const checkIsUpdatingParticipant = state => state.loading.get(UPDATE_PARTICIPANTS.ACTION)
export const checkIsSendingParticipantsData = state =>
  state.loading.get(SEND_PARTICIPANTS_DATA.ACTION)

export const checkIsUploadingParticipantsDocument = state =>
  state.loading.get(UPLOAD_PARTICIPANTS_DOCUMENT.ACTION)

export const checkIsSendingParticipantsSpouseData = state =>
  state.loading.get(SEND_PARTICIPANTS_SPOUSE_DATA.ACTION)

export const checkIsSendingFireInsurance = state => state.loading.get(SEND_FIRE_INSURANCE.ACTION)

export const hasErrorFireInsurance = state => !!state.error.get(SEND_FIRE_INSURANCE.ACTION)

export const getCurrentLeaseId = state => state.leases.get('currentLease')
export const getCurrentParticipantId = state => state.leases.get('currentParticipant')
export const getCurrentExternalId = state => state.leases.get('currentExternalId')

import { schema } from 'normalizr'

import Model from './model'

class ListingRegistration extends Model({
  additionalCost: undefined,
  additionalCosts: undefined,
  address: undefined,
  available: undefined,
  bathrooms: undefined,
  bedrooms: undefined,
  billingPlan: undefined,
  buildingName: undefined,
  buildingPermission: undefined,
  buildingPosition: undefined,
  city: undefined,
  citySlug: undefined,
  comments: undefined,
  conciergeType: undefined,
  condominiumDescription: undefined,
  condominiumGatehouse: undefined,
  condominiumName: undefined,
  condominiumPermission: undefined,
  condominiumPosition: undefined,
  condominiumManagerName: undefined,
  condominiumManagerPhone: undefined,
  condominiumCaretakerName: undefined,
  condominiumCaretakerPhone: undefined,
  condominiumFee: undefined,
  convertedPictures: undefined,
  contactPreference: undefined,
  coordinates: undefined,
  coveredParkingSpots: undefined,
  createdAt: undefined,
  currentPage: undefined,
  description: undefined,
  divulgation: undefined,
  extraAddressInfo: undefined,
  floor: undefined,
  furnished: undefined,
  garbageFee: undefined,
  hasCondominium: undefined,
  hasSign: undefined,
  hobbybox: undefined,
  hobbyboxNumber: undefined,
  id: undefined,
  immobileStatus: undefined,
  insurance: undefined,
  keyType: undefined,
  keyLocation: undefined,
  keyNumber: undefined,
  keyObservation: undefined,
  listingDescription: undefined,
  listingTupe: undefined,
  listingType: undefined,
  livingArea: undefined,
  maxPrice: undefined,
  minPrice: undefined,
  neighborhood: undefined,
  neighborhoodDescription: undefined,
  neighborhoodSlug: undefined,
  numberOfEnsuites: undefined,
  otherVisitObservations: undefined,
  parkingSpots: undefined,
  parkingSpotsNumbers: undefined,
  penthouse: undefined,
  pets: undefined,
  postalCode: undefined,
  predictedPrice: undefined,
  publicizePermission: undefined,
  publishAd: undefined,
  purpose: undefined,
  registrationStatus: undefined,
  rentPrice: undefined,
  reasonToSellOrRent: undefined,
  responsible: undefined,
  schedulesAndVisitDays: undefined,
  solarOrientation: undefined,
  state: undefined,
  status: undefined,
  streetNumber: undefined,
  taxes: undefined,
  taxesType: undefined,
  totalArea: undefined,
  transactionType: undefined,
  updatedAt: undefined,
  user: undefined,
  userToken: undefined,
  vacancy: undefined,
  video: undefined,
  videoId: undefined,
}) {}

const listingRegistrationSchema = new schema.Entity(
  'listings',
  {},
  {
    processStrategy: entity => new ListingRegistration(entity),
  }
)

export { listingRegistrationSchema, ListingRegistration as default }

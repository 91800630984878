import React, { useMemo, useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import Tooltip from '@material-ui/core/Tooltip'
import classnames from 'classnames'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import { useDispatch } from 'react-redux'

import { updateParticipants } from '_modules/leases/actions'
import Button, { ButtonColor, ButtonVariant } from '_components/button'
import { PARTICIPANTS_TYPE } from '_utils/constants'
import { formatCost } from '_utils/strings'
import { primaryColor } from '_utils/colors'

import useStyles from './styles'
import styles from './styles.css'
import ParticipantInfo from './participant-info'

const INITIAL_TOTAL_STATE = {
  total: undefined,
  considerIncome: true,
  participants: [],
}

const MainInfo = ({
  lease,
  considerIncomeRef,
  handleRefUpdated,
  hasGuarantorParticipant,
  onlyIncomeHelpers,
  hideSumRents,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [allParticipants, setAllParticipants] = useState(false)
  const [totalIncome, setTotal] = useState(INITIAL_TOTAL_STATE)
  const createSVGStyle = useMemo(() => ({ backgroundColor: primaryColor }), [])

  const setCategory = useCallback(
    participant => {
      if (onlyIncomeHelpers) {
        return participant.category === PARTICIPANTS_TYPE.INCOME_HELPER && participant.enabled
      }
      if (hasGuarantorParticipant) {
        return participant.category === PARTICIPANTS_TYPE.GUARANTOR && participant.enabled
      }
      return (
        (participant.category === PARTICIPANTS_TYPE.INCOME_HELPER ||
          participant.category === PARTICIPANTS_TYPE.GUARANTOR) &&
        participant.enabled
      )
    },
    [hasGuarantorParticipant, onlyIncomeHelpers]
  )

  const consideredParticipants = useMemo(
    () => lease && lease.participants.filter(participant => setCategory(participant)),
    [lease, setCategory]
  )

  useEffect(() => {
    // eslint-disable-next-line no-param-reassign
    considerIncomeRef.current = totalIncome
    handleRefUpdated()
  }, [considerIncomeRef, handleRefUpdated, totalIncome])

  // Set the initial condition to add all participants and its considerIncome status
  useEffect(() => {
    if (totalIncome.total === undefined) {
      const initialState = lease.considerIncome ? lease.monthlyIncome : 0
      const guarantorState = hasGuarantorParticipant ? 0 : initialState
      setTotal({
        total: consideredParticipants.length
          ? consideredParticipants
              .filter(participant => participant.considerIncome && participant.monthlyIncome)
              .reduce(
                (total, participant) => total + (participant.monthlyIncome || 0),
                guarantorState
              )
          : guarantorState,
        considerIncome: lease.considerIncome,
        participants: consideredParticipants.length
          ? consideredParticipants
              .filter(participant => participant.isTenant)
              .map(({ id, considerIncome }) => ({
                participantId: id,
                considerIncome,
              }))
          : [],
      })
    }
  }, [consideredParticipants, hasGuarantorParticipant, lease, setCategory, totalIncome])

  const handleCheckbox = useCallback(event => {
    const { checked, value, name, id } = event.target
    const isMainTenant = name === PARTICIPANTS_TYPE.MAIN_TENANT
    if (checked) {
      return setTotal(prevState => ({
        total: prevState.total + Number(value),
        considerIncome: isMainTenant ? true : prevState.considerIncome,
        participants: prevState.participants.map(participant => {
          if (participant.participantId.toString() === id) {
            return {
              participantId: participant.participantId,
              considerIncome: true,
            }
          }
          return participant
        }),
      }))
    }
    return setTotal(prevState => ({
      total: prevState.total - Number(value),
      considerIncome: isMainTenant ? false : prevState.considerIncome,
      participants: prevState.participants.map(participant => {
        if (participant.participantId.toString() === id) {
          return {
            participantId: participant.participantId,
            considerIncome: false,
          }
        }
        return participant
      }),
    }))
  }, [])

  const showAllParticipants = useCallback(() => {
    setAllParticipants(prevState => !prevState)
  }, [])

  const onDisableParticipant = useCallback(
    event => {
      const { name } = event.currentTarget
      dispatch(updateParticipants(lease.id, name, { enabled: false }, lease.listing.externalId))
    },
    [dispatch, lease.id, lease.listing.externalId]
  )

  const requiredIncome = useMemo(
    () => (hasGuarantorParticipant ? lease.requiredGuarantorIncome : lease.requiredIncome),
    [hasGuarantorParticipant, lease.requiredGuarantorIncome, lease.requiredIncome]
  )

  const tooltipProps = useMemo(() => {
    return {
      tooltip: classes.customTooltip,
    }
  }, [classes.customTooltip])

  return (
    <div className={styles.main}>
      {!hideSumRents && (
        <div className={styles['main-values']}>
          <h2 className={classnames(styles.title, styles['required-income'])}>
            Renda necessária
            <span className={classnames(styles.value, styles.normal)}>
              {formatCost(requiredIncome)}
            </span>
          </h2>
          <div className={styles['title-wrapper']}>
            <h2 className={styles.title}>Soma das Rendas</h2>
            <div className={styles.income}>
              <strong
                className={classnames(styles.value, {
                  [styles.checked]: requiredIncome < totalIncome.total,
                })}
              >
                {formatCost(totalIncome.total)}
              </strong>
              {requiredIncome > totalIncome.total && (
                <Tooltip
                  classes={tooltipProps}
                  title="Renda não suficiente"
                  arial-label="Renda não suficiente"
                >
                  <p style={createSVGStyle} className={styles.tooltip}>
                    !
                  </p>
                </Tooltip>
              )}
            </div>
          </div>
        </div>
      )}
      {lease.considerIncome !== undefined && !hasGuarantorParticipant && (
        <ParticipantInfo
          personalInfo={lease.user}
          handleCheckbox={handleCheckbox}
          isMainTenant
          values={lease}
        />
      )}
      {allParticipants &&
        consideredParticipants.map(participant => (
          <ParticipantInfo
            key={participant.id}
            personalInfo={participant}
            handleCheckbox={handleCheckbox}
            values={participant}
            onDisableParticipant={onDisableParticipant}
            hasGuarantorParticipant={hasGuarantorParticipant}
          />
        ))}
      {consideredParticipants.length > 0 && (
        <Button
          variant={ButtonVariant.DEFAULT}
          color={ButtonColor.TEXT_BLACK}
          className={styles.button}
          onClick={showAllParticipants}
        >
          <div className={styles['itens-button']}>
            <p className={styles['text-button']}>
              {allParticipants ? 'Esconder' : 'Visualizar'} todos{' '}
              {hasGuarantorParticipant ? 'fiadores' : 'locatários'}
            </p>
            {allParticipants ? (
              <KeyboardArrowUpIcon className={styles.icon} aria-hidden="true" />
            ) : (
              <KeyboardArrowDownIcon className={styles.icon} aria-hidden="true" />
            )}
          </div>
        </Button>
      )}
    </div>
  )
}

MainInfo.propTypes = {
  lease: PropTypes.shape({
    requiredGuarantorIncome: PropTypes.number,
    user: PropTypes.shape({
      fullName: PropTypes.string,
      email: PropTypes.string,
    }),
    listing: PropTypes.shape({
      externalId: PropTypes.string,
    }),
    id: PropTypes.number,
    monthlyIncome: PropTypes.number,
    considerIncome: PropTypes.bool,
    requiredIncome: PropTypes.number,
    participants: PropTypes.arrayOf(
      PropTypes.shape({
        fullName: PropTypes.string,
        email: PropTypes.string,
        id: PropTypes.number,
        totalIncome: PropTypes.number,
        considerIncome: PropTypes.bool,
        monthlyIncome: PropTypes.number,
      })
    ),
  }).isRequired,
  considerIncomeRef: PropTypes.shape({ current: PropTypes.shape({}) }).isRequired,
  handleRefUpdated: PropTypes.func.isRequired,
  hasGuarantorParticipant: PropTypes.bool,
  onlyIncomeHelpers: PropTypes.bool,
  hideSumRents: PropTypes.bool,
}

MainInfo.defaultProps = {
  hasGuarantorParticipant: false,
  onlyIncomeHelpers: false,
  hideSumRents: false,
}

export default React.memo(MainInfo)

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import ImmutablePropTypes from 'react-immutable-proptypes'

import PagedSlickCarousel from '_components/paged-slick-carousel'
import PrevArrow from '_views/property/property-carousel/prev-arrow'
import NextArrow from '_views/property/property-carousel/next-arrow'
import { primaryColor } from '_utils/colors'

import styles from './styles.css'

class PropertyCarousel extends PureComponent {
  static propTypes = {
    pictures: ImmutablePropTypes.list.isRequired,
    className: PropTypes.string,
    openModalPicturesFullScreen: PropTypes.func.isRequired,
    onPictureView: PropTypes.func.isRequired,
  }

  static defaultProps = {
    className: '',
  }

  onPageChange = () => {
    this.forceUpdate()
  }

  setCarouselRef = ref => {
    this.carousel = ref
    this.forceUpdate()
  }

  renderPictures = () => {
    const { pictures, openModalPicturesFullScreen, onPictureView } = this.props

    const carouselSettings = {
      className: styles.carousel,
      dots: false,
      infinite: true,
      speed: 100,
      initialSlide: 0,
      variableWidth: pictures.size > 3,
      centerMode: pictures.size > 3,
      draggable: false,
      responsive: [
        {
          breakpoint: 10000,
          settings: {
            slidesToShow: pictures.size > 3 ? 3 : pictures.size,
            prevArrow: <PrevArrow primaryColor={primaryColor} />,
            nextArrow: <NextArrow primaryColor={primaryColor} />,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
          },
        },
      ],
    }

    switch (pictures.size) {
      case 0:
        return null
      case 1:
        return (
          <div
            className={classnames(styles.card, {
              [styles['not-centered']]: false,
            })}
          >
            <img
              src={pictures.first().get('thumbnail')}
              alt="property"
              className={classnames({
                [styles['not-centered']]: false,
              })}
            />
          </div>
        )
      default:
        return (
          <PagedSlickCarousel
            settings={carouselSettings}
            ref={this.setCarouselRef}
            onPageChange={this.onPageChange}
            shouldHidePagingOnDesktop
            onPictureView={onPictureView}
            pictures={pictures}
          >
            {pictures.map((picture, index) => (
              <button
                type="button"
                onClick={openModalPicturesFullScreen}
                key={`key-${picture}`}
                className={classnames(styles.card, {
                  [styles['not-centered']]:
                    this.carousel && pictures.size > 2
                      ? this.carousel.getSelectedIndex() !== index
                      : false,
                })}
              >
                <div className={styles.overlay} />
                <img
                  src={picture.get('thumbnail')}
                  alt="property"
                  className={classnames({
                    [styles['not-centered']]:
                      this.carousel && pictures.size > 2
                        ? this.carousel.getSelectedIndex() !== index
                        : false,
                  })}
                />
              </button>
            ))}
          </PagedSlickCarousel>
        )
    }
  }

  render() {
    const { className } = this.props

    return (
      <section className={styles.container}>
        <div className={classnames(className, styles.main)}>{this.renderPictures()}</div>
      </section>
    )
  }
}

export default PropertyCarousel

import React from 'react'
import PropTypes from 'prop-types'

import Ilustration from '_assets/images/house.svg'

import styles from './styles.css'

const ListingRegistrationFinishedContainer = ({ children }) => {
  return (
    <div className={styles.container}>
      <svg aria-hidden="true" className={styles.svg}>
        <use xlinkHref={Ilustration} />
      </svg>
      {children}
    </div>
  )
}

ListingRegistrationFinishedContainer.propTypes = {
  children: PropTypes.node.isRequired,
}

export default React.memo(ListingRegistrationFinishedContainer)

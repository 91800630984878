import React, { useState, useCallback } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import Checkbox from '../checkbox'
import FormGroup from '../form-group'
import OutlinedField from '../../../outlined-field'

import styles from './styles.css'

const TextField = ({ label, name, showApplicableOption, placeholder, className, children }) => {
  const [isApplicable, setApplicability] = useState(true)

  const handleChange = useCallback(() => {
    setApplicability(!isApplicable)
  }, [isApplicable])

  return (
    <FormGroup
      label={label}
      disabled={!isApplicable}
      className={classnames(className, styles.container)}
    >
      <Checkbox
        label="Não se aplica"
        name={`${name}_is_applicable`}
        onChange={handleChange}
        className={classnames(styles.hide, {
          [styles['show-apply-label']]: showApplicableOption,
        })}
      />
      {children ? (
        children({ disabled: !isApplicable, className: styles['second-outlined'] })
      ) : (
        <OutlinedField
          placeholder={placeholder}
          label={placeholder}
          type="text"
          name={name}
          disabled={!isApplicable}
        />
      )}
    </FormGroup>
  )
}

TextField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  showApplicableOption: PropTypes.bool,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  /**
   * Add any custom fields inside the formGroup
   */
  children: PropTypes.func,
}

TextField.defaultProps = {
  label: 'Digite aqui',
  name: '',
  showApplicableOption: false,
  placeholder: '',
  className: '',
  children: undefined,
}

export { TextField }

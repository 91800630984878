import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

class SlickCarousel extends PureComponent {
  static propTypes = {
    settings: PropTypes.shape,
    children: PropTypes.node.isRequired,
  }

  static defaultProps = {
    settings: {
      dots: true,
      infinite: true,
      speed: 350,
      slidesToShow: 1,
    },
  }

  componentDidMount() {
    this.container.addEventListener('touchstart', this.touchStart)
    this.container.addEventListener('touchmove', this.preventTouch, { passive: false })
  }

  componentWillUnmount() {
    this.container.removeEventListener('touchstart', this.touchStart)
    this.container.removeEventListener('touchmove', this.preventTouch, { passive: false })
  }

  setComponentRef = ref => {
    this.container = ref
  }

  preventTouch(e) {
    const minValue = 5 // threshold

    this.clientX = e.touches[0].clientX - this.firstClientX
    this.clientY = e.touches[0].clientY - this.firstClientY

    // Vertical scrolling does not work when you start swiping horizontally.
    if (Math.abs(this.clientX) > minValue) {
      e.preventDefault()
      e.returnValue = false
    }

    return false
  }

  touchStart(e) {
    this.firstClientX = e.touches[0].clientX
    this.firstClientY = e.touches[0].clientY
  }

  render() {
    const { settings, children } = this.props
    return (
      <div ref={this.setComponentRef}>
        <Slider {...settings}>{children}</Slider>
      </div>
    )
  }
}

export default SlickCarousel

import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ palette, breakpoints }) => ({
  textField: {
    '& .MuiInputLabel-outlined': {
      fontSize: '1.4rem',
    },
    '& .MuiTypography-body1': {
      fontSize: '1.4rem',
      color: palette.gray.dusty,
    },
  },
  choices: {
    '& .MuiSvgIcon-root': {
      width: '2.28rem',
      height: '1.8rem',
    },
  },
  input: {
    height: '4.8rem',
    marginBottom: '2.4rem',
    outlinedInput: {
      '& .MuiInputAdornment-root.': {
        fontSize: '1.6rem',
      },
    },
  },
  descriptionSecondary: {
    color: palette.gray.dusty,
    lineHeight: '2.1rem',
    margin: '1.2rem 0',
  },
  container: {
    justifyContent: 'center',
    width: '100%',
    maxWidth: '33.6rem',
    margin: 'auto',
    textAlign: 'left',
    [breakpoints.down('sm')]: {
      maxWidth: '90%',
      margin: 'auto 2.4rem 9.6rem',
    },
  },
  title: {
    fontWeight: 700,
    lineHeight: 2.4,
    margin: '5.6rem 0 4rem',

    [breakpoints.down('sm')]: {
      margin: '4rem 0 2.4rem',
      fontSize: '1.6rem',
      color: palette.secondary.dark,
    },
  },
  titleSection: {
    fontWeight: 500,
    lineHeight: '2.4rem',
    marginBottom: '2.4rem',
    [breakpoints.down('sm')]: {
      fontSize: '1.4rem',
      margin: '0.4rem 0',
      color: palette.secondary.dark,
    },
  },
  choicesCard: {
    width: '33.6rem',
    height: '4rem',
    margin: '1.2rem 0 0.2rem',
    padding: '0 1.5rem',

    [breakpoints.down('sm')]: {
      width: '31.6rem',
    },
  },
  choicesCardObs: {
    color: palette.text.primary,
    fontWeight: '500',
  },
  sectionTwo: {
    marginTop: '4rem',
  },
  button: {
    marginTop: '4rem',
    width: '100%',
    [breakpoints.down('sm')]: {
      marginTop: '10rem',
    },
  },
  building: {
    margin: 'auto',
    marginTop: '10rem',
    width: '14.4rem',
    height: '16.5rem',
  },
}))

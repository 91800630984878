import React, { Fragment, useMemo, useState } from 'react'
import classnames from 'classnames'

import Button, { ButtonColor } from '_components/button'

import ProposalCard from '../proposal-card'

import styles from './styles.css'

const ANSWER = {
  ACCEPT_ONE: {
    title: 'O proprietário aceitou somente uma das propostas',
    body: 'Você pode aceitar a proposta e continuar com a locação clicando em enviar documentos.',
  },
  ACCEPT: {
    title: 'Sua proposta foi aceita!',
    body: 'Envie os documentos para dar início a sua análise de crédito.',
  },
  REJECTED: {
    title: 'Sua proposta foi analisada!',
    body:
      'Infelizmente sua proposta não foi aceita. Deseja aceitar as condições e seguir com a negociação?',
  },
}

const ProposalOverview = () => {
  const [answer] = useState(ANSWER.ACCEPT)

  const styleButtons = useMemo(
    () => ({
      [styles.box]: !!answer,
    }),
    [answer]
  )

  return (
    <div className={styles.container}>
      <a href="/imovel/12343" className={styles['property-link']}>
        Apartamento 12343
      </a>

      <h1 className={styles.heading}>Propostas para o imóvel</h1>

      <ProposalCard title="Proposta de novo valor" />
      <ProposalCard title="Modificações no imóvel" />

      <section className={classnames(styleButtons)}>
        {!!answer && (
          <Fragment>
            <h2 className={styles.title}>{answer.title}</h2>
            <p className={styles.body}>{answer.body}</p>
          </Fragment>
        )}
        <div className={styles.buttons}>
          <a href="/" className={styles.link}>
            DESISTIR
          </a>

          <Button className={styles.button} color={ButtonColor.PRIMARY}>
            ENVIAR DOCUMENTOS
          </Button>
        </div>
      </section>
    </div>
  )
}
export default React.memo(ProposalOverview)

import React, { Fragment, useContext } from 'react'
import { Grid, Typography, Paper, Link } from '@material-ui/core'
import { useSelector } from 'react-redux'

import ClosedIcon from '_assets/images/ic-closed.svg'
import withAuthOrIncomeHelper from '_hocs/with-auth-or-income-helper'
import Button from '_components/material/button'
import { getLeaseById } from '_modules/leases/selectors'
import TokenContext from '_context/token'

import useStyles from './styles'

const CreditAnalysisClosed = () => {
  const styles = useStyles()
  const { leaseId } = useContext(TokenContext)
  const lease = useSelector(reduxState => getLeaseById(reduxState, leaseId))
  const cancelledByStaff = lease.get('rejectionComment')
  const externalId = lease.getIn(['listing', 'externalId'])
  return (
    <Grid component="section" container direction="column" className={styles.container}>
      <svg aria-label="Icone de análise encerrada" className={styles.icon}>
        <use xlinkHref={ClosedIcon} />
      </svg>
      <Typography component="h1" variant="h3" color="secondary" className={styles.title}>
        Análise encerrada!
      </Typography>
      {!cancelledByStaff ? (
        <Fragment>
          <Typography component="h2" variant="h4" className={styles.description}>
            Que pena, você encerrou a negociação do{' '}
            <Typography component="span" variant="h4" className={styles.descriptionHighlighted}>
              {/* The page needs to reload so the server can render the AMP view */}
              <Link href={`/imovel/${lease.getIn(['listing', 'slug'])}`}>imóvel {externalId}</Link>
            </Typography>
            . Que tal ver outros imóveis que podem ser do seu interesse?
          </Typography>
          <Button to="/buscar" className={styles.button} color="primary" variant="contained">
            Ver outros imóveis
          </Button>
        </Fragment>
      ) : (
        <Fragment>
          <Typography component="h2" variant="h4" className={styles.description}>
            Que pena, a sua análise de crédito não foi aprovada.
          </Typography>
          <Grid className={styles.containerItems}>
            <Typography variant="h4" component="h2" color="secondary" className={styles.titleItems}>
              Justificativa
            </Typography>
            <Typography variant="h5" component="p" color="secondary" className={styles.comment}>
              {cancelledByStaff}
            </Typography>
          </Grid>
          <Paper variant="outlined" className={styles.secondContainer}>
            <Grid container direction="column">
              <Typography
                variant="h5"
                component="p"
                color="secondary"
                className={styles.subDescription}
              >
                Que tal ver outros imóveis que podem ser do seu interesse?
              </Typography>
            </Grid>

            <Button
              to="/buscar"
              className={styles.buttonContainer}
              color="primary"
              variant="contained"
            >
              Ver outros imóveis
            </Button>
          </Paper>
        </Fragment>
      )}
    </Grid>
  )
}

export default withAuthOrIncomeHelper(React.memo(CreditAnalysisClosed))

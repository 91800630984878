import { createSelector } from 'reselect'

import { SEND_PROPOSAL, SEND_VALUE_PROPOSAL, SEND_MODIFICATION_PROPOSAL } from './actions'

const getParam = (state, param) => param

export const getProposal = state => state.proposal

export const getProposalsByExternalId = createSelector(
  [state => state.proposal, getParam],
  (proposal, externalId) => {
    if (!proposal) return null
    return proposal.getIn(['listing', 'externalId']) === String(externalId)
  }
)

export const loadingSendProposalSelector = state => !!state.loading.get(SEND_PROPOSAL.ACTION)
export const errorSendProposalSelector = state => !!state.error.get(SEND_PROPOSAL.ACTION)
export const checkIsSendingValueProposal = state => !!state.loading.get(SEND_VALUE_PROPOSAL.ACTION)
export const isSendingModificationProposalSelector = state =>
  !!state.loading.get(SEND_MODIFICATION_PROPOSAL.ACTION)

import { signInWithPhoneNumber, checkCode } from '_utils/firebase'
import { customPhoneLogin, resetPhoneLogin } from '_services/user'

// Constants
export const STEP_ONE = 0
export const STEP_TWO = 1
export const STEP_THREE = 2
export const STEP_FOUR = 3
export const STEP_FIVE = 4
export const STEP_SIX = 5

// Phone change screen
const handlePhoneChange = ({ dispatch, requestPhoneChange, email }) => {
  dispatch(requestPhoneChange({ email }))
}

// Verify code screen
const handleVerifyCode = ({
  dispatch,
  dispatchLocal,
  Sentry,
  isCustomPhoneAuthenticationEnabled,
  userLogin,
  verifyPhoneLoginCode,
  identifierCode,
  userInputCode,
  confirmResult,
  ACTION_TYPES,
}) => {
  if (isCustomPhoneAuthenticationEnabled) {
    dispatch(
      verifyPhoneLoginCode({
        identifierCode,
        authorizationCode: userInputCode.replace(/[^0-9]+/g, ''),
      })
    )
    return
  }

  // Firebase
  dispatchLocal({
    type: ACTION_TYPES.START_LOADING,
  })
  confirmResult
    .confirm(userInputCode.replace(/[^0-9]+/g, ''))
    .then(() => {
      checkCode({ userLogin, dispatchLocal, ACTION_TYPES })
    })
    .catch(error => {
      // Send to sentry to identify issues
      Sentry.captureException(error)

      dispatchLocal({
        type: ACTION_TYPES.UPDATE_ERROR_MESSAGE,
        payload: {
          error: 'Código Inválido',
        },
      })
    })
}

// Request code screen
const handleRequestCode = ({
  dispatchLocal,
  setCurrent,
  userPhoneNumber,
  isCustomPhoneAuthenticationEnabled,
  ACTION_TYPES,
  changeNumberKey,
  changeNumberUid,
}) => {
  if (isCustomPhoneAuthenticationEnabled) {
    dispatchLocal({
      type: ACTION_TYPES.START_LOADING,
    })
    // Check if it's a phone reset or normal login
    const service = changeNumberKey ? resetPhoneLogin : customPhoneLogin
    const payload = {
      phoneNumber: userPhoneNumber,
    }
    if (changeNumberKey) {
      payload.token = changeNumberKey
      payload.uid = changeNumberUid
    }

    service(payload)
      .then(data => {
        dispatchLocal({
          type: ACTION_TYPES.UPDATE_FORM,
          payload: {
            identifierCode: data.identifier_code,
          },
        })
        setCurrent(STEP_TWO)
      })
      .catch(() => {
        dispatchLocal({
          type: ACTION_TYPES.TRY_AGAIN_LATER,
        })
      })
    return
  }

  // Firebase
  signInWithPhoneNumber({ userPhoneNumber, dispatchLocal, ACTION_TYPES, setCurrent })
}

// Update user screen
const handleUpdateUser = ({ dispatch, updateUser, fullName, cpf, email, contactWay }) => {
  dispatch(updateUser({ fullName, cpf, email: email.trim(), contactWay }))
}

// On submit actions
export const handleOnSubmit = ({
  userInfo,
  dispatch,
  dispatchLocal,
  Sentry,
  isCustomPhoneAuthenticationEnabled,
  currentPage,
  ACTION_TYPES,
  requestPhoneChange,
  verifyPhoneLoginCode,
  updateUser,
  userLogin,
  setCurrent,
  changeNumberKey,
  changeNumberUid,
}) => {
  const {
    country,
    area,
    phoneNumber,
    confirmResult,
    userInputCode,
    email,
    fullName,
    cpf,
    contactWay,
    identifierCode,
  } = userInfo
  const userPhoneNumber = country.concat(area, phoneNumber).replace(/[^0-9+]+/g, '')

  switch (currentPage) {
    case STEP_FOUR:
      handlePhoneChange({ dispatch, requestPhoneChange, email })
      return
    case STEP_TWO:
      handleVerifyCode({
        dispatch,
        dispatchLocal,
        Sentry,
        isCustomPhoneAuthenticationEnabled,
        userLogin,
        verifyPhoneLoginCode,
        identifierCode,
        userInputCode,
        confirmResult,
        ACTION_TYPES,
      })
      return
    case STEP_THREE:
      handleUpdateUser({ dispatch, updateUser, fullName, cpf, email, contactWay })
      return
    default:
      handleRequestCode({
        dispatchLocal,
        setCurrent,
        userPhoneNumber,
        isCustomPhoneAuthenticationEnabled,
        ACTION_TYPES,
        changeNumberKey,
        changeNumberUid,
      })
  }
}

import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { sentryCaptureException, sentryShowDialogReport } from '_utils/sentry'

class SentryLogger extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  }

  state = { error: null }

  onFeedbackClick = () => {
    sentryShowDialogReport()
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error })

    sentryCaptureException(error, errorInfo)
  }

  render() {
    if (this.state.error) {
      // render fallback UI
      return <div />
    }
    // when there's not an error, render children untouched
    return this.props.children
  }
}

export default SentryLogger

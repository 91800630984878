import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, Typography } from '@material-ui/core'
import { navigate } from '@reach/router'

import Button from '_components/material/button'
import withAuth from '_hocs/with-auth'
import { checkIsCancellingLease, errorCancelLease, getLeaseById } from '_modules/leases/selectors'
import RadioGroup from '_components/radio-group'
import TokenContext from '_context/token'
import { cancelLease } from '_modules/leases/actions'
import { usePrevious } from '_utils/hooks'
import useModal from '_hooks/use-modal'
import ModalAddPerson from '_components/modal-add-person'
import AlertModal from '_components/material/alert-modal'

import { creditAnalysisFlow, CREDIT_ANALYSIS_ROUTES } from '../router'

import useStyles from './styles'

const INVITE = 'invite'
const ADD = 'add'

const options = [
  { value: ADD, label: 'Quero cadastrá-lo' },
  { value: INVITE, label: 'Vou convidá-lo' },
]

const CreditAnalysisAddNewParticipant = () => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const { leaseId, participantType } = useContext(TokenContext)
  const lease = useSelector(state => getLeaseById(state, leaseId))
  const [useParticipant, setUseParticipant] = useState(ADD)
  const { modalOpen: isAddModalOpen, handleModal } = useModal()
  const externalId = lease && lease.getIn(['listing', 'externalId'])
  const hasGuarantorRequested = useMemo(
    () => lease && lease.getIn(['incomeHelperRequest', 'isGuarantor']),
    [lease]
  )
  const { modalOpen: isAlertModalOpen, handleModal: handleAlertModal } = useModal()

  const loading = useSelector(checkIsCancellingLease)
  const error = useSelector(errorCancelLease)
  const wasLoading = usePrevious(loading)

  useEffect(() => {
    if (!error && wasLoading && !loading) {
      navigate(`/analise-credito/${leaseId}/analise-encerrada`)
    }
  }, [error, leaseId, loading, wasLoading])

  const endLease = useCallback(() => {
    dispatch(cancelLease({ leaseId, externalId }))
  }, [dispatch, externalId, leaseId])

  const handleUseParticipant = useCallback(event => {
    setUseParticipant(event.target.value)
  }, [])

  const renderParticipantType = hasGuarantorRequested ? 'fiador' : 'locatário'
  const renderParticipantTypePlural = hasGuarantorRequested ? 'fiadores' : 'responsáveis'

  const handleOnClick = useCallback(() => {
    if (useParticipant === ADD) {
      handleModal()
    } else {
      navigate(
        creditAnalysisFlow(
          CREDIT_ANALYSIS_ROUTES[`INVITE_${participantType.toUpperCase()}`],
          leaseId
        )
      )
    }
  }, [handleModal, leaseId, participantType, useParticipant])

  return (
    <Grid component="section" container direction="column" className={styles.container}>
      <Grid component="section" className={styles.content}>
        <Typography variant="h3" component="h1" color="secondary" className={styles.title}>
          Adicionar novo {renderParticipantType}
        </Typography>
        <Typography variant="h4" component="h2" color="secondary" className={styles.subTitle}>
          Como você prefere adicionar um novo {renderParticipantType}?
        </Typography>
        <Typography
          variant="subtitle1"
          component="p"
          color="secondary"
          className={styles.description}
        >
          Ao{' '}
          <Typography component="span" variant="subtitle1" className={styles.descriptionHighlight}>
            cadastrá-los
          </Typography>
          , você insere dados e documentos dos {renderParticipantTypePlural}. Ao{' '}
          <Typography component="span" variant="subtitle1" className={styles.descriptionHighlight}>
            convidá-los
          </Typography>
          , você informa o e-mail e telefone deles para que eles façam o próprio cadastro através de
          um link que enviaremos no e-mail/celular.
        </Typography>

        <RadioGroup value={useParticipant} onChange={handleUseParticipant} options={options} />

        <Button
          onClick={handleOnClick}
          className={styles.button}
          color="primary"
          variant="contained"
        >
          Próximo
        </Button>

        <Button
          className={styles.buttonSecondary}
          color="secondary"
          variant="text"
          onClick={handleAlertModal}
        >
          Encerrar negociação
        </Button>
      </Grid>
      {isAlertModalOpen && (
        <AlertModal handleModal={handleAlertModal} handleConfirmClick={endLease} />
      )}

      {isAddModalOpen && (
        <ModalAddPerson
          onClose={handleModal}
          type={participantType}
          warrantyType={lease && lease.warranty}
          leaseId={leaseId}
        />
      )}
    </Grid>
  )
}

export default withAuth(React.memo(CreditAnalysisAddNewParticipant))

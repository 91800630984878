import React, { useMemo, useEffect, Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import Building from '_components/building-animated'
import { getLeaseById } from '_modules/leases/selectors'
import { getLease } from '_modules/leases/actions'
import withAuth from '_hocs/with-auth-or-income-helper'
import MobileClick from '_assets/images/mobile-click-positive.svg'
import Button, { ButtonVariant, ButtonColor, ButtonFormat } from '_components/button'
import { LEASE_STATUS } from '_utils/constants'
import { getLeaseStatus } from '_utils/lease'

import styles from './styles.css'

const CreditAnalysisApproved = ({ id }) => {
  const dispatch = useDispatch()

  const lease = useSelector(state => getLeaseById(state, Number(id)))
  useEffect(() => {
    if (!lease && id) {
      dispatch(getLease(id))
    }
  }, [dispatch, id, lease])
  const renderContent = useMemo(() => {
    if (lease && getLeaseStatus(lease.status) === LEASE_STATUS.INITIALLY_APPROVED) {
      return {
        description:
          'Envie seus dados pessoais e profissionais para que possamos elaborar seu contrato.',
        url: `/garantia/${id}/registrar-contratante-passo-2`,
        buttonText: 'ENVIAR DADOS',
        ariaLabel: 'Ir para tela de dados pessoais',
      }
    }
    return {
      description:
        '  Agora é só aguardar o seu contrato ficar pronto. Você será notificado e poderá acessá-lo aqui.',
      url: '/minhas-negociacoes',
      buttonText: 'VER NEGOCIAÇÕES',
      ariaLabel: 'Ir para minhas negociações',
    }
  }, [id, lease])

  return (
    <section className={styles.container}>
      {!lease ? (
        <Building className={styles.building} />
      ) : (
        <Fragment>
          <svg aria-hidden="true" className={styles.icon}>
            <use xlinkHref={MobileClick} />
          </svg>
          <h1 className={styles.title}>Análise aprovada!</h1>
          <h2 className={styles.description}>{renderContent.description}</h2>
          <Button
            color={ButtonColor.PRIMARY}
            variant={ButtonVariant.BOLD}
            format={ButtonFormat.ROUNDED}
            className={styles.action}
            to={renderContent.url}
            aria-label={renderContent.ariaLabel}
          >
            {renderContent.buttonText}
          </Button>
        </Fragment>
      )}
    </section>
  )
}

CreditAnalysisApproved.propTypes = {
  id: PropTypes.string.isRequired,
}

export default withAuth(React.memo(CreditAnalysisApproved))

import React from 'react'
import { useSelector } from 'react-redux'

import { getDetailedHighlights } from '_modules/highlights/selectors'
import Tag, { TagVariant } from '_components/tag'
import LinkIcon from '_assets/images/ic-launch.svg'
import QuitImage from '_assets/images/quit.svg'
import RelatedSection from '_views/property/related-section'

import styles from './styles.css'

const NewMyNegotiations = () => {
  const results = useSelector(getDetailedHighlights)

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <Tag
          text="Apartamento 00001"
          icon={LinkIcon}
          iconOptions={{ position: 'right' }}
          variant={TagVariant.BOLD}
          className={styles.tag}
        />
        <div className={styles.content}>
          <svg aria-hidden="true" className={styles['quit-image']}>
            <use xlinkHref={QuitImage} />
          </svg>
          <p className={styles.instruction}>
            Que pena que você desistiu.
            <br />
            Veja outra opções:
          </p>
        </div>
        <hr className={styles.line} />
        <p className={styles.title}>Sugestão de imóveis similares</p>
        {!!results.size && (
          <div className={styles.carousel}>
            <RelatedSection className={styles['carousel-container']} properties={results} />
          </div>
        )}
      </div>
    </div>
  )
}

NewMyNegotiations.propTypes = {}

NewMyNegotiations.defaultProps = {}

export default React.memo(NewMyNegotiations)

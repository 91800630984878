import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
  dialog: {
    width: '100%',
    height: '100%',
    '& .MuiDialog-paper': {
      borderRadius: '0.8rem',
    },
    [theme.breakpoints.down('sm')]: {
      '& .MuiDialog-paper': {
        margin: 0,
        borderRadius: 30,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        position: 'absolute',
        bottom: 0,
      },
    },
  },
  container: {
    width: '45.2rem',
    minHeight: '50rem',
    margin: 'auto',
    padding: '2.6rem 3.7rem 4rem 8.8rem',
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
      padding: '2.8rem 4.0rem 1.2rem',
    },
  },
  content: {
    padding: '0 5.1rem 0 0',
    [theme.breakpoints.down('sm')]: {
      padding: '0',
    },
  },
  closeButton: {
    padding: 0,
    display: 'flex',
    justifyContent: 'flex-end',
    background: 'transparent',
    cursor: 'pointer',
  },
  header: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
  },
  headerImage: {
    width: 70,
    height: 70,
    margin: '2.4rem auto',
  },
  title: {
    textAlign: 'center',
    fontSize: '1.6rem',
    fontWeight: '500',
    lineHeight: '2.4rem',
    color: theme.palette.text.primary,
  },
  stars: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'space-between',
    margin: '2.4rem 0 3.2rem',
  },
  star: {
    cursor: 'pointer',
    backgroundColor: 'transparent',
    border: 'none',
    padding: '1.2rem 0',
    width: '4rem',
    height: '4rem',
  },
  icon: {
    width: '2.4rem',
    height: '2.4rem',
    margin: '0 0.8rem',
  },
  selected: {
    fill: theme.palette.warning.main,
  },
  button: {
    margin: '3.2rem 0 0',
  },
}))

import firebase from 'firebase/app'
import * as Sentry from '@sentry/browser'

export const RECAPTCHA_CONTAINER_ID = 'recaptcha-container'

export const initRecaptcha = id => {
  window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(id, {
    size: 'invisible',
    'expired-callback': () => {
      window.recaptchaVerifier.render().then(widgetId => {
        window.recaptchaVerifier.reset(widgetId)
      })
    },
  })
}

export const signInWithPhoneNumber = ({
  userPhoneNumber,
  dispatchLocal,
  ACTION_TYPES,
  setCurrent,
}) => {
  const { UPDATE_FORM, TRY_AGAIN_LATER, START_LOADING, UPDATE_ERROR_MESSAGE } = ACTION_TYPES
  const STEP_TWO = 1
  if (typeof window.recaptchaVerifier !== 'undefined') {
    dispatchLocal({
      type: START_LOADING,
    })
    firebase
      .auth()
      .signInWithPhoneNumber(userPhoneNumber, window.recaptchaVerifier)
      .then(confirmResult => {
        dispatchLocal({
          type: UPDATE_FORM,
          payload: { confirmResult },
        })
        setCurrent(STEP_TWO)
      })
      .catch(error => {
        // If signInWithPhoneNumber results in an error, reset the reCAPTCHA so the user can try again
        initRecaptcha(RECAPTCHA_CONTAINER_ID)

        // Send to sentry to identify issues
        Sentry.captureException(error)

        // Show error message
        if (error.code === 'auth/too-many-requests') {
          dispatchLocal({
            type: TRY_AGAIN_LATER,
          })
        } else {
          dispatchLocal({
            type: UPDATE_ERROR_MESSAGE,
            payload: { error: 'Verifique se o seu número está correto' },
          })
        }
      })
  } else {
    dispatchLocal({
      type: TRY_AGAIN_LATER,
    })
  }
}

export const checkCode = ({ userLogin, dispatchLocal, ACTION_TYPES }) => {
  const { TRY_AGAIN_LATER } = ACTION_TYPES
  firebase
    .auth()
    .currentUser.getIdToken()
    .then(idToken => userLogin(idToken))
    .catch(error => {
      // Send to sentry to identify issues
      Sentry.captureException(error)

      dispatchLocal({
        type: TRY_AGAIN_LATER,
      })
    })
}

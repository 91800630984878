import { makeStyles } from '@material-ui/styles'

import { STATUS_CHIP_THEME } from './constants'

export default makeStyles(theme => ({
  chip: {
    padding: theme.spacing(0, 1),
    fontSize: '1.2rem',
    fontWeight: 500,
    borderRadius: 4,
  },
  [STATUS_CHIP_THEME.GREEN]: {
    color: theme.palette.success.main,
    backgroundColor: theme.palette.green.light,
  },
  [STATUS_CHIP_THEME.BLUE]: {
    color: theme.palette.custom.blue,
    backgroundColor: theme.palette.blue.light,
  },
  [STATUS_CHIP_THEME.ORANGE]: {
    color: theme.palette.orange.dark,
    backgroundColor: theme.palette.orange.light,
  },
  [STATUS_CHIP_THEME.GRAY]: {
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.gray.gallery,
  },
}))

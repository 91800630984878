import humps from 'humps'

import { notEmptyPayload } from '_utils/helpers'

import { post, get, upload, patch, del } from './requests'

export const createLease = key => payload =>
  post(['leases'], { key }, humps.decamelizeKeys(payload))

export const sendMonthlyIncome = key => (leaseId, payload) =>
  post(['leases', leaseId, 'send-monthly-income'], { key }, humps.decamelizeKeys(payload))

export const createLeaseZap = key => payload =>
  post(['leases', 'credit-analysis'], { key }, humps.decamelizeKeys(payload))

export const getLease = key => (id, params) => {
  return get(['leases', id], { key, params })
}

export const uploadDocument = key => ({ leaseId, onUploadProgress, validateStatus, payload }) =>
  upload(['leases', leaseId, 'documents'], { key, onUploadProgress, validateStatus }, payload)

export const uploadHelperDocument = () => ({
  leaseId,
  onUploadProgress,
  validateStatus,
  token,
  payload,
}) =>
  upload(
    ['leases', leaseId, 'income-helpers', 'documents'],
    { onUploadProgress, validateStatus, params: { token } },
    payload
  )

export const uploadParticipantsDocument = () => ({
  leaseId,
  onUploadProgress,
  validateStatus,
  token,
  payload,
}) =>
  upload(
    ['leases', leaseId, 'participants', 'documents'],
    { onUploadProgress, validateStatus, params: { token } },
    payload
  )

export const registerParticipantDocument = key => ({
  leaseId,
  onUploadProgress,
  validateStatus,
  payload,
  participantId,
}) =>
  post(
    ['leases', leaseId, 'participants', participantId, 'documents'],
    { onUploadProgress, validateStatus, key },
    payload
  )

export const registerParticipant = key => (leaseId, payload) =>
  post(
    ['leases', leaseId, 'participants', 'register'],
    { key },
    humps.decamelizeKeys(notEmptyPayload(payload))
  )

export const updateRegisteredParticipant = key => (participantId, leaseId, payload) =>
  patch(['leases', leaseId, 'participants', participantId], { key }, humps.decamelizeKeys(payload))

export const updateParticipant = key => (leaseId, participantId, payload) =>
  upload(['leases', leaseId, 'participants', participantId], { key }, humps.decamelizeKeys(payload))

export const createLeaseIncomeHelper = key => ({ leaseId, ...payload }) =>
  post(['leases', leaseId, 'income-helpers'], { key }, humps.decamelizeKeys(payload))

export const createMultipleIncomeHelpers = key => (leaseId, payload) =>
  post(
    ['leases', leaseId, 'income-helpers', 'create-multiple'],
    { key },
    humps.decamelizeKeys(payload)
  )

export const inviteParticipants = key => (leaseId, payload) =>
  post(['leases', leaseId, 'participants'], { key }, humps.decamelizeKeys(payload))

export const getLeases = key => page => get(['leases'], { key, params: { page } })

export const sendLeaseToReview = key => leaseId =>
  post(['leases', leaseId, 'send-to-review'], { key })

export const cancelLease = key => leaseId => post(['leases', leaseId, 'cancel'], { key })

export const sendTenantData = key => ({ leaseId, ...payload }) =>
  post(['leases', leaseId, 'send-contract-data'], { key }, humps.decamelizeKeys(payload))

export const getHelperLease = ({ leaseId, token }) =>
  get(['leases', leaseId, 'income-helpers', 'info'], { params: { token } })

export const getParticipantLease = ({ leaseId, token }) =>
  get(['leases', leaseId, 'participants', 'info'], { params: { token } })

export const sendHelperData = ({ leaseId, token, ...payload }) =>
  post(
    ['leases', leaseId, 'participants', 'complete'],
    { params: { token } },
    humps.decamelizeKeys(payload)
  )

export const sendHelperSpouseData = ({ leaseId, token, ...payload }) =>
  post(
    ['leases', leaseId, 'income-helpers', 'spouse'],
    { params: { token } },
    humps.decamelizeKeys(payload)
  )

export const sendParticipantsSpouseData = ({ leaseId, token, ...payload }) =>
  post(
    ['leases', leaseId, 'participants', 'spouse'],
    { params: { token } },
    humps.decamelizeKeys(payload)
  )

export const registerParticipantSpouse = key => (leaseId, participantId, payload) =>
  post(
    ['leases', leaseId, 'participants', participantId, 'send-spouse-data'],
    { key },
    humps.decamelizeKeys(payload)
  )

export const registerParticipantConfirm = key => leaseId =>
  post(['leases', leaseId, 'participants', 'register', 'confirm'], { key })

export const sendSpouseData = key => ({ leaseId, ...payload }) =>
  post(['leases', leaseId, 'send-spouse-data'], { key }, humps.decamelizeKeys(payload))

export const getContractPreview = key => leaseId =>
  get(['leases', leaseId, 'contract'], { key, responseType: 'blob' })

export const getContractPreviewIncomeHelper = ({ token, leaseId }) =>
  get(['leases', leaseId, 'income-helpers', 'contract'], {
    params: { token },
    responseType: 'blob',
  })

export const generateContractLink = key => (leaseId, contractId) =>
  post(['leases', leaseId, 'contracts', contractId, 'sign'], { key })

export const generateContractLinkIncomeHelper = ({ token, leaseId, contractId }) =>
  post(['leases', leaseId, 'contracts', contractId, 'sign'], { params: { token } })

export const requestContractChanges = key => ({ leaseId, contractId, payload }) =>
  post(['leases', leaseId, 'contracts', contractId, 'request-changes'], { key }, payload)

export const submitWarranty = key => (id, payload) =>
  post(['leases', id, 'submit-warranty'], { key }, payload)

export const sendRate = key => ({ leaseId, score }) =>
  post(['leases', leaseId, 'rate'], { key }, { score })

export const sendIncomeHelperData = ({ leaseId, token, ...payload }) =>
  post(
    ['leases', leaseId, 'income-helpers', 'complete-data'],
    { params: { token } },
    humps.decamelizeKeys(payload)
  )

export const sendUserData = key => ({ leaseId, ...payload }) =>
  post(['leases', leaseId, 'send-user-data'], { key }, humps.decamelizeKeys(payload))

export const updateUserData = key => ({ leaseId, ...payload }) =>
  patch(['leases', leaseId, 'send-user-data'], { key }, humps.decamelizeKeys(payload))

export const updateParticipants = key => (leaseId, participantId, payload) =>
  patch(['leases', leaseId, 'participants', participantId], { key }, humps.decamelizeKeys(payload))

export const deleteParticipant = key => (leaseId, participantId) =>
  del(['leases', leaseId, 'participants', participantId], { key })

export const sendParticipantsData = ({ leaseId, token, ...payload }) =>
  post(
    ['leases', leaseId, 'participants', 'complete-data'],
    { params: { token } },
    humps.decamelizeKeys(payload)
  )

export const deleteDocument = key => (leaseId, documentId) =>
  del(['leases', leaseId, 'documents', documentId], { key })

export const deleteParticipantDocument = key => (leaseId, documentId, participantId, token) =>
  del(
    ['leases', leaseId, 'participants', participantId, 'documents', documentId],
    token
      ? {
          params: { token },
        }
      : { key }
  )

export const sendFireInsurance = key => (leaseId, payload) =>
  post(['leases', leaseId, 'fire-insurance'], { key }, humps.decamelizeKeys(payload))

export const updateLease = key => (leaseId, payload) =>
  patch(['leases', leaseId], { key }, humps.decamelizeKeys(payload))

export const submitCredpagoAnalysis = key => leaseId =>
  post(
    ['leases', leaseId, 'credpago-submit-full-analysis'],
    { key },
    humps.decamelizeKeys({ lease: leaseId })
  )

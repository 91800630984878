import * as Sentry from '@sentry/browser'

import { SENTRY_DSN, SENTRY_ENV } from '_config/environment'

export const initSentry = () => {
  if (SENTRY_DSN) {
    Sentry.init({
      dsn: SENTRY_DSN,
      environment: SENTRY_ENV,
      beforeSend(event, hint) {
        // Ignore errors from HereAPI
        if (
          event &&
          event.request &&
          event.request.url &&
          event.request.url.includes('/imovel/') &&
          hint &&
          hint.originalException &&
          hint.originalException.stack &&
          (hint.originalException.stack.includes('np.b') ||
            hint.originalException.stack.includes('promiseReactionJob'))
        ) {
          return null
        }
        return event
      },
    })
  }
}
export const registerSentryUser = user => {
  if (SENTRY_DSN) {
    Sentry.configureScope(scope => {
      scope.setUser(user)
    })
  }
}

export const sentryCaptureException = (error, errorInfo) => {
  if (SENTRY_DSN) {
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key])
      })
      Sentry.captureException(error)
    })
  }
}

export const sentryShowDialogReport = () => {
  Sentry.showReportDialog()
}

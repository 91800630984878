import { useState, useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Map } from 'immutable'

import { usePrevious } from '_utils/hooks'

const useUploadDocument = action => {
  const isLoading = useSelector(state => !!state.loading.get(action))
  const wasLoading = usePrevious(isLoading)
  const error = useSelector(state => state.error.get(action, Map()))
  const [isLoadingDocuments, setLoadingDocuments] = useState({
    documentCategory: '',
    percentage: 0,
    file: { name: '' },
  })

  const onUploadProgress = useCallback(
    ({ documentCategory, name }) => ({ loaded, total }) => {
      const percentage = Math.round((loaded * 100) / total)
      setLoadingDocuments({
        documentCategory,
        percentage,
        file: { name },
      })
    },
    []
  )

  useEffect(() => {
    if (wasLoading && !isLoading && !error.size) {
      setLoadingDocuments({
        documentCategory: '',
        percentage: 0,
      })
    }
  }, [error.size, isLoading, wasLoading])

  return [isLoadingDocuments, onUploadProgress]
}

export default useUploadDocument

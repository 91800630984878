import React, { useState, useEffect, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import TextField from '@material-ui/core/TextField'
import { RHFInput } from 'react-hook-form-input'
import FormControl from '@material-ui/core/FormControl'
import PropTypes from 'prop-types'

import styles from './styles.css'
import useStyle from './materialStyle'

const MAX_STRING_COUNT = 500

const DescriptionTextField = ({ name, type, about }) => {
  const { register, setValue, watch, errors, setError, clearError } = useFormContext()
  const checkValues = watch(name)
  const classes = useStyle()
  const [stringCount, setStringCount] = useState(`0 / ${MAX_STRING_COUNT}`)

  useEffect(() => {
    if (checkValues) {
      const chars = checkValues.length - MAX_STRING_COUNT + MAX_STRING_COUNT
      setStringCount(`${chars} / ${MAX_STRING_COUNT}`)

      if (checkValues.length === MAX_STRING_COUNT) {
        setError(`${name}`, 'maxLength', 'Limite de caracteres: 500')
      } else {
        clearError()
      }
    }
  }, [checkValues, clearError, name, setError])

  const errorTextColor = useMemo(
    () => ({
      className: classes.input,
    }),
    [classes.input]
  )

  const rootProps = useMemo(
    () => ({
      root: classes.textField,
    }),
    [classes.textField]
  )

  return (
    <div className={styles.description}>
      <h2 className={styles.title}>{type}</h2>
      <p className={styles.about}>{about}</p>
      <FormControl>
        <RHFInput
          as={
            <TextField
              classes={rootProps}
              multiline
              fullWidth
              margin="normal"
              variant="outlined"
              helperText={stringCount}
              placeholder="Escreva algo..."
              inputProps={{ maxLength: MAX_STRING_COUNT }}
              error={errors[`${name}`]}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              InputProps={errors[`${name}`] && errorTextColor}
            />
          }
          name={name}
          register={register}
          setValue={setValue}
        />
        <span className={styles.error} aria-describedby="Maximum characters reached error">
          {errors[`${name}`] && errors[`${name}`].message}
        </span>
      </FormControl>
    </div>
  )
}

DescriptionTextField.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  about: PropTypes.string,
}

DescriptionTextField.defaultProps = {
  type: '',
  name: '',
  about: '',
}
export default React.memo(DescriptionTextField)

import { makeStyles } from '@material-ui/styles'
import { amber, green } from '@material-ui/core/colors'

export const materialStyles = makeStyles(theme => ({
  default: {
    backgroundColor: '#222222',
  },
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: '#d32f2f',
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
  },
  snackbar: {
    maxWidth: '54rem',
    [theme.breakpoints.down('md')]: {
      width: '90%',
    },
  },
}))

import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
  wrapper: {
    width: '100%',
    backgroundColor: theme.palette.gray.alabaster,
    border: `0.1rem solid ${theme.palette.gray.gallery}`,
    borderRadius: 4,
    boxSizing: 'border-box',
  },
  header: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '1.6rem 3.2rem',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
      flexDirection: 'column',
      padding: 0,
    },
  },
  textWrapper: {
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '1.6rem 1.6rem 0.8rem 1.6rem',
    },
  },
  label: {
    fontWeight: 500,
    color: theme.palette.text.primary,
  },
  name: {
    color: theme.palette.text.primary,
  },
  statusChip: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: '1.6rem',
      marginLeft: '1.6rem',
    },
  },
  divider: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  button: {
    marginLeft: '2.4rem',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      width: '100%',
    },
  },
  desktop: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  mobile: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'inline-flex',
    },
  },
  details: {
    borderTop: `0.1rem solid ${theme.palette.gray.divider}`,
    padding: '3.2rem 3.2rem 2.4rem 3.2rem',
  },
  fieldLabel: {
    fontWeight: 700,
  },
  filesWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    margin: '-0.8rem 0',
  },
  file: {
    margin: '0.8rem 1.6rem 0.8rem 0',
  },
  fields: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
}))

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

// import { GOOGLE_MAPS_KEY } from '_config/environment'

import styles from './styles.css'

class GoogleMap extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    propertyAddress: PropTypes.string.isRequired,
  }

  state = {
    propertyCoordinates: null,
  }

  componentDidMount() {
    this.onScriptLoad()
  }

  // testing
  // componentDidMount() {
  //   if (!window.google || !window.google.maps) {
  //     const script = document.createElement('script')
  //     script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_KEY}&libraries=places`
  //     script.onload = this.onScriptLoad
  //     document.head.appendChild(script)
  //   } else {
  //     this.onScriptLoad()
  //   }
  // }

  componentDidUpdate(prevProps) {
    const { propertyAddress } = this.props

    if (prevProps.propertyAddress !== propertyAddress) {
      this.onScriptLoad()
    }
  }

  onScriptLoad = () => {
    this.geocoder = new window.google.maps.Geocoder()
    this.geocoder.geocode(
      {
        address: this.props.propertyAddress,
      },
      this.setup
    )
  }

  // testing
  // onScriptLoad = () => {
  //   if (window.google && window.google.maps) {
  //     this.geocoder = new window.google.maps.Geocoder()
  //     this.geocoder.geocode(
  //       {
  //         address: this.props.propertyAddress,
  //       },
  //       this.setup
  //     )
  //   }
  // }

  setup = results => {
    const { location } = results[0].geometry
    const { id } = this.props
    const propertyLocation = { lat: location.lat(), lng: location.lng() }
    this.setState({
      propertyCoordinates: propertyLocation,
    })
    const map = new window.google.maps.Map(document.getElementById(id), {
      center: propertyLocation,
      zoom: 14,
    })
    this.map = map
    this.setStreetView()
  }

  setStreetView = () => {
    const { id } = this.props
    const panorama = new window.google.maps.StreetViewPanorama(document.getElementById(id), {
      position: this.state.propertyCoordinates,
      pov: {
        heading: 34,
        pitch: 10,
      },
    })
    this.panorama = panorama
    this.map.setStreetView(panorama)
  }

  render() {
    const { id } = this.props

    return (
      <div className={styles.container}>
        <div className={styles.map} id={id} />
      </div>
    )
  }
}

export default GoogleMap

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import ImmutablePropTypes from 'react-immutable-proptypes'

import PagedSlickCarousel from '_components/modal-pictures-full-screen/full-screen-mobile-carousel/carousel'

import styles from './styles.css'

class FullScreenMobileCarousel extends PureComponent {
  static propTypes = {
    pictures: ImmutablePropTypes.list.isRequired,
    className: PropTypes.string,
    carouselActiveIndex: PropTypes.string.isRequired,
    changeCurrentSlide: PropTypes.func.isRequired,
    onPictureView: PropTypes.func,
  }

  static defaultProps = {
    className: '',
    onPictureView: () => {},
  }

  onPageChange = () => {
    this.forceUpdate()
  }

  setCarouselRef = ref => {
    this.carousel = ref
    this.forceUpdate()
  }

  afterChange = currentSlide => this.props.changeCurrentSlide(currentSlide)

  renderPictures = () => {
    const { pictures, carouselActiveIndex, onPictureView } = this.props

    const carouselSettings = {
      className: styles.carousel,
      dots: false,
      infinite: false,
      speed: 100,
      afterChange: this.afterChange,
    }

    switch (pictures.size) {
      case 0:
        return null
      case 1:
        return (
          <div
            className={classnames(styles.card, {
              [styles['not-centered']]: false,
            })}
          >
            <img
              src={pictures.get(0)}
              alt="Imagens do imóvel"
              className={classnames({
                [styles['not-centered']]: false,
              })}
            />
          </div>
        )
      default:
        return (
          <PagedSlickCarousel
            settings={carouselSettings}
            ref={this.setCarouselRef}
            onPageChange={this.onPageChange}
            shouldHidePagingOnDesktop
            slideIndex={carouselActiveIndex}
            pictures={pictures}
            onPictureView={onPictureView}
          >
            {pictures.map((picture, index) => (
              <img
                src={picture}
                alt="Imagens do imóvel"
                className={classnames({
                  [styles['not-centered']]:
                    this.carousel && pictures.size > 2
                      ? this.carousel.getSelectedIndex() !== index
                      : false,
                })}
              />
            ))}
          </PagedSlickCarousel>
        )
    }
  }

  render() {
    const { className, carouselActiveIndex } = this.props

    return (
      <section className={styles.container}>
        <div className={classnames(className, styles.main)}>
          {this.renderPictures(carouselActiveIndex)}
        </div>
      </section>
    )
  }
}

export default FullScreenMobileCarousel

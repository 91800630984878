import React, { useState, useCallback, useRef, Fragment, useMemo } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import PlusIcon from '_assets/images/ic-plus.svg'
import LessIcon from '_assets/images/ic-menos.svg'
import CheckIcon from '_assets/images/ic-check.svg'
import { getWarrantyTitle } from '_utils/lease'

import styles from './styles.css'

const WarrantyChoiceCard = ({ data: warranty, onChange }) => {
  const [open, setOpen] = useState(false)
  const radioRef = useRef(null)

  const handleSelect = useCallback(() => {
    onChange(warranty.type)
  }, [warranty.type, onChange])

  const handleCollapse = useCallback(() => {
    if (radioRef && !open) {
      radioRef.current.click()
    }
    setOpen(prevState => !prevState)
  }, [open])

  const mainParticipantsDocuments = useMemo(
    () =>
      warranty.documentCategories &&
      warranty.documentCategories.filter(
        document => document.participant === 'main_tenant' || 'income_helper'
      ),
    [warranty.documentCategories]
  )

  const mainParticipantsDocumentsWithoutDuplicated =
    mainParticipantsDocuments &&
    mainParticipantsDocuments.filter(
      (v, i, acc) => acc.findIndex(document => document.title === v.title) === i
    )

  const guarantorDocuments = useMemo(
    () =>
      warranty.documentCategories &&
      warranty.documentCategories.filter(document => document.participant === 'guarantor'),
    [warranty.documentCategories]
  )

  const guarantorDocumentsWithoutDuplicated =
    guarantorDocuments &&
    guarantorDocuments.filter(
      (v, i, acc) => acc.findIndex(document => document.title === v.title) === i
    )

  const tenantsDocuments = useMemo(
    () =>
      warranty.documentCategories &&
      warranty.documentCategories.filter(document => document.participant === 'tenant'),
    [warranty.documentCategories]
  )

  const tenantsDocumentsWithoutDuplicated =
    tenantsDocuments &&
    tenantsDocuments.filter(
      (v, i, acc) => acc.findIndex(document => document.title === v.title) === i
    )

  const hasDocuments = useMemo(() => {
    return !!(warranty.documentCategories && warranty.documentCategories.length > 0)
  }, [warranty.documentCategories])
  return (
    <section
      className={classnames(styles.container, {
        [styles['opened-container']]: open,
      })}
    >
      <div className={styles.header}>
        <input
          type="radio"
          className={styles.radio}
          name="radio"
          ref={radioRef}
          onChange={handleSelect}
        />
        <span className={styles['radio-custom']} />
        <h2 className={styles.title}>{getWarrantyTitle(warranty.type)}</h2>
        <button
          type="button"
          className={styles['expand-icon']}
          aria-label="expandir"
          onClick={handleCollapse}
        >
          <svg alt="circle icon" className={styles['expand-svg']}>
            <use xlinkHref={!open ? PlusIcon : LessIcon} />
          </svg>
        </button>
      </div>
      <p className={styles.description}>{warranty.description}</p>
      {open && (
        <div className={styles.content}>
          {warranty.components.reverse().map(list => (
            <Fragment key={list.title}>
              <h3 className={styles['list-title']}>{list.title}:</h3>
              {list.items &&
                list.items.map(item => (
                  <div key={item.content} className={styles['list-item']}>
                    <svg aria-hidden="true" className={styles['check-icon']}>
                      <use xlinkHref={CheckIcon} />
                    </svg>
                    <p>{item.content}</p>
                  </div>
                ))}
            </Fragment>
          ))}
          {hasDocuments && <h3 className={styles['list-title']}>Documentos Exigidos:</h3>}
          {mainParticipantsDocumentsWithoutDuplicated &&
            mainParticipantsDocumentsWithoutDuplicated.length > 0 && (
              <Fragment>
                <h3 className={classnames(styles['list-title'], styles['title-document'])}>
                  Locatários:
                </h3>
                {mainParticipantsDocumentsWithoutDuplicated.map(document => (
                  <Fragment>
                    <div key={document.title} className={styles['list-item']}>
                      <svg aria-hidden="true" className={styles['check-icon']}>
                        <use xlinkHref={CheckIcon} />
                      </svg>
                      <p>{document.title}</p>
                    </div>
                    <p className={styles['item-description']}>{document.description}</p>
                  </Fragment>
                ))}
              </Fragment>
            )}
          {guarantorDocumentsWithoutDuplicated && guarantorDocumentsWithoutDuplicated.length > 0 && (
            <Fragment>
              <h3 className={classnames(styles['list-title'], styles['title-document'])}>
                Fiadores:
              </h3>
              {guarantorDocumentsWithoutDuplicated.map(document => (
                <Fragment>
                  <div key={document.title} className={styles['list-item']}>
                    <svg aria-hidden="true" className={styles['check-icon']}>
                      <use xlinkHref={CheckIcon} />
                    </svg>
                    <p>{document.title}</p>
                  </div>
                  <p className={styles['item-description']}>{document.description}</p>
                </Fragment>
              ))}
            </Fragment>
          )}
          {tenantsDocumentsWithoutDuplicated && tenantsDocumentsWithoutDuplicated.length > 0 && (
            <Fragment>
              <h3 className={classnames(styles['list-title'], styles['title-document'])}>
                Moradores:
              </h3>
              {tenantsDocumentsWithoutDuplicated.map(document => (
                <Fragment>
                  <div key={document.title} className={styles['list-item']}>
                    <svg aria-hidden="true" className={styles['check-icon']}>
                      <use xlinkHref={CheckIcon} />
                    </svg>
                    <p>{document.title}</p>
                  </div>
                  <p className={styles['item-description']}>{document.description}</p>
                </Fragment>
              ))}
            </Fragment>
          )}
          {warranty.info && <div className={styles['footer-important-info']}>{warranty.info}</div>}
          {warranty.url && (
            <p>
              Confira todas as <a href={warranty.url}>regras</a>
            </p>
          )}
        </div>
      )}
    </section>
  )
}

WarrantyChoiceCard.propTypes = {
  data: PropTypes.shape({
    type: PropTypes.string,
    description: PropTypes.string,
    footer: PropTypes.node,
  }),
  onChange: PropTypes.func,
}

WarrantyChoiceCard.defaultProps = {
  data: {
    type: '',
    description: '',
    footer: undefined,
  },
  onChange: () => {},
}

export default React.memo(WarrantyChoiceCard)

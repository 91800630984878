export const INITIAL_STATE = {
  country: '+55',
  area: '',
  phoneNumber: '',
  userInputCode: '',
  confirmResult: {},
  fullName: '',
  email: '',
  cpf: '',
  error: '',
  loading: false,
  idToken: '',
  contactPreference: 'email',
  identifierCode: null,
}

export const ACTION_TYPES = {
  UPDATE_FORM: 'UPDATE_FORM',
  TRY_AGAIN_LATER: 'TRY_AGAIN_LATER',
  STOP_LOADING: 'STOP_LOADING',
  START_LOADING: 'START_LOADING',
  UPDATE_ERROR_MESSAGE: 'UPDATE_ERROR_MESSAGE',
}

export const reducer = (state, action) => {
  const {
    UPDATE_FORM,
    TRY_AGAIN_LATER,
    STOP_LOADING,
    START_LOADING,
    UPDATE_ERROR_MESSAGE,
  } = ACTION_TYPES
  switch (action.type) {
    case UPDATE_FORM: {
      return { ...state, loading: false, ...action.payload }
    }
    case TRY_AGAIN_LATER: {
      return { ...state, error: 'Erro ao acessar. Tente novamente mais tarde', loading: false }
    }
    case STOP_LOADING: {
      return { ...state, loading: false }
    }
    case START_LOADING: {
      return { ...state, error: '', loading: true }
    }
    case UPDATE_ERROR_MESSAGE: {
      return { ...state, loading: false, ...action.payload }
    }
    default:
      return state
  }
}

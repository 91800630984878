import React, { useContext, useCallback, useMemo, useState } from 'react'
import classnames from 'classnames'
import { Grid, Typography, Paper } from '@material-ui/core'
import {
  AddCircleOutline as AddCircleOutlineIcon,
  AccountBox as AccountBoxIcon,
} from '@material-ui/icons'
import { useSelector, useDispatch } from 'react-redux'
import { navigate } from '@reach/router'

import { STATUS_OPTIONS } from '_components/status-chip/constants'
import Button, { ButtonTheme } from '_components/material/button'
import TokenContext from '_context/token'
import { PARTICIPANTS_TYPE, WARRANTY_TYPES } from '_utils/constants'
import { getLeaseById } from '_modules/leases/selectors'
import {
  deleteParticipant,
  REGISTER_PARTICIPANT_CONFIRM,
  registerParticipantConfirm,
} from '_modules/leases/actions'
import useOnSuccessCall from '_hooks/use-on-success'
import ModalAddPerson from '_components/modal-add-person'
import useModal from '_hooks/use-modal'

import { CREDIT_ANALYSIS_ROUTES, creditAnalysisFlow } from '../router'

import { CREDIT_ANALYSIS_PARTICIPANTS } from './constants'
import ParticipantCard from './card'
import useStyles from './styles'

const CreditAnalysisParticipants = () => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const { leaseId, participantType, setParticipant: setCurrentParticipant } = useContext(
    TokenContext
  )
  const { modalOpen: isAddModalOpen, handleModal } = useModal()
  const lease = useSelector(state => getLeaseById(state, leaseId))
  const [currentParticipant, setParticipant] = useState({})

  const content = CREDIT_ANALYSIS_PARTICIPANTS[participantType]
  const onDisableParticipant = useCallback(
    event => {
      const { name } = event.currentTarget
      dispatch(deleteParticipant(name))
    },
    [dispatch]
  )

  const participants = useMemo(
    () =>
      lease
        ? lease
            .get('participants')
            .toJS()
            .filter(({ category, enabled }) => category === participantType && enabled)
            .map(participant => ({
              ...participant,
              status: STATUS_OPTIONS[participant.registrationUpdateTag],
            }))
        : [],
    [lease, participantType]
  )

  const onEditClick = useCallback(
    event => {
      const { name } = event.currentTarget
      const participant = participants.find(({ id }) => id === Number(name))
      if (participant.isInvited) {
        setCurrentParticipant(participant)
        if (participantType === PARTICIPANTS_TYPE.INCOME_HELPER) {
          navigate(creditAnalysisFlow(CREDIT_ANALYSIS_ROUTES.INVITE_INCOME_HELPER, leaseId))
          return
        }
        navigate(creditAnalysisFlow(CREDIT_ANALYSIS_ROUTES.INVITE_TENANT, leaseId))
        return
      }
      if (participant.spouse && participant.spouse.id) {
        participant.spouse = lease
          .get('participants')
          .toJS()
          .find(spouse => spouse.id === participant.spouse.id)
      }

      setParticipant(participant)
      handleModal()
    },
    [handleModal, lease, leaseId, participantType, participants, setCurrentParticipant]
  )
  const onCreateClick = useCallback(() => {
    setParticipant({})
    handleModal()
  }, [handleModal])
  const onNextClick = useCallback(() => {
    dispatch(registerParticipantConfirm())
  }, [dispatch])
  const handleSuccess = useCallback(() => {
    if (lease.hasRequestedParticipants) {
      navigate(creditAnalysisFlow(CREDIT_ANALYSIS_ROUTES.STATUS, leaseId))
      return
    }
    if (
      participantType === PARTICIPANTS_TYPE.INCOME_HELPER &&
      lease.warranty === WARRANTY_TYPES.GUARANTOR
    ) {
      navigate(creditAnalysisFlow(CREDIT_ANALYSIS_ROUTES.ADD_GUARANTOR, leaseId))
      return
    }
    if (participantType === PARTICIPANTS_TYPE.INCOME_HELPER) {
      navigate(creditAnalysisFlow(CREDIT_ANALYSIS_ROUTES.ADD_TENANT, leaseId))
      return
    }
    if (participantType === PARTICIPANTS_TYPE.GUARANTOR) {
      navigate(creditAnalysisFlow(CREDIT_ANALYSIS_ROUTES.ADD_TENANT, leaseId))
      return
    }
    navigate(creditAnalysisFlow(CREDIT_ANALYSIS_ROUTES.READY, leaseId))
  }, [lease, leaseId, participantType])
  const [isLoading] = useOnSuccessCall(REGISTER_PARTICIPANT_CONFIRM.ACTION, handleSuccess)

  const renderInviteUrl = useMemo(
    () =>
      creditAnalysisFlow(
        CREDIT_ANALYSIS_ROUTES[`INVITE_${participantType.toUpperCase()}`],
        leaseId
      ),
    [leaseId, participantType]
  )

  const renderParticipant = useMemo(() => {
    const mainUserInfo = lease && {
      ...lease.user.toJS(),
      statusOption: lease.registrationUpdateTag,
      currentUser: true,
      isTenant: lease.isTenant,
      considerIncome: lease.considerIncome,
      type: participantType,
    }
    if (
      (participantType === PARTICIPANTS_TYPE.TENANT && !lease.isTenant) ||
      (participantType === PARTICIPANTS_TYPE.INCOME_HELPER && !lease.considerIncome)
    ) {
      return null
    }
    return (
      <ParticipantCard {...mainUserInfo} className={classnames(styles.participant, styles.first)} />
    )
  }, [lease, participantType, styles.first, styles.participant])

  return (
    <div className={styles.wrapper}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <Paper className={styles.formWrapper}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12}>
                <Grid className={styles.addInformation}>
                  <Grid>
                    <Typography
                      component="h1"
                      variant="h4"
                      color="textPrimary"
                      className={styles.infoTitle}
                    >
                      {content.title}
                    </Typography>
                    <Typography
                      component="p"
                      variant="h5"
                      color="textSecondary"
                      className={styles.description}
                    >
                      {content.description}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                {renderParticipant}
                {!!participants.length &&
                  participants.map((participant, index) => (
                    <ParticipantCard
                      {...participant}
                      key={participant.id}
                      type={participant.category}
                      onDisableClick={onDisableParticipant}
                      onEditClick={onEditClick}
                      className={classnames(styles.participant, { [styles.first]: index === 0 })}
                      statusOption={participant.registrationUpdateTag}
                    />
                  ))}
              </Grid>
              <Grid item xs={12} className={styles.addParticipantWrapper}>
                <Button
                  theme={ButtonTheme.GRAY_WITH_BORDER}
                  startIcon={<AddCircleOutlineIcon />}
                  className={styles.addButton}
                  onClick={onCreateClick}
                >
                  Cadastrar mais pessoas
                </Button>
                <Button
                  theme={ButtonTheme.GRAY_WITH_BORDER}
                  startIcon={<AccountBoxIcon />}
                  className={styles.addButton}
                  to={renderInviteUrl}
                >
                  Convidar mais pessoas
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} className={styles.buttonWrapper}>
          <Button
            isLoading={isLoading}
            variant="contained"
            className={styles.button}
            onClick={onNextClick}
          >
            Próximo
          </Button>
        </Grid>
      </Grid>
      {isAddModalOpen && (
        <ModalAddPerson
          onClose={handleModal}
          participant={currentParticipant}
          type={participantType}
          warrantyType={lease && lease.warranty}
          leaseId={leaseId}
        />
      )}
    </div>
  )
}
export default React.memo(CreditAnalysisParticipants)

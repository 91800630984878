import React, { memo, useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import AriaModal from 'react-aria-modal'
import { useParams } from '@reach/router'
import { useDispatch, useSelector } from 'react-redux'

import { useWindowWidth } from '_utils/hooks'
import Button from '_components/material/button'
import { onMouseDown } from '_utils/helpers'
import FullScreenMobileCarousel from '_components/modal-pictures-full-screen/full-screen-mobile-carousel'
import Carousel from '_components/modal-pictures-full-screen/carousel'
import { getProperty } from '_modules/property/actions'
import { getPropertyById, getIsLoadingProperty } from '_modules/property/selectors'
import Building from '_components/building-animated'

import styles from './styles.css'

const SIZE_TABLET = 1024

// TODO: Refactor this modal to material UI
const PicturesFullScreen = ({
  className,
  openModal,
  closeModal,
  titleText,
  externalId,
  primaryColor,
  onPictureView,
}) => {
  const width = useWindowWidth()
  const { id } = useParams()
  const dispatch = useDispatch()
  const property = useSelector(state => getPropertyById(state, id || externalId))
  const isLoading = useSelector(getIsLoadingProperty)

  const [carouselActiveIndex, setCarouselActiveIndex] = useState(0)
  const setCarouselCurrentIndex = useCallback(
    event => {
      const { dataset } = event.currentTarget
      setCarouselActiveIndex(parseInt(dataset.index, 10))
      if (externalId) {
        onPictureView(property.convertedPictures.getIn([parseInt(dataset.index, 10), 'picture']))
      }
    },
    [externalId, onPictureView, property]
  )

  const onClickArrowPreviews = useCallback(() => {
    setCarouselActiveIndex(carouselActiveIndex - 1)
    if (externalId) {
      onPictureView(property.convertedPictures.getIn([carouselActiveIndex - 1, 'picture']))
    }
  }, [carouselActiveIndex, externalId, onPictureView, property])

  const onClickArrowNext = useCallback(() => {
    setCarouselActiveIndex(carouselActiveIndex + 1)
    if (externalId) {
      onPictureView(property.convertedPictures.getIn([carouselActiveIndex + 1, 'picture']))
    }
  }, [carouselActiveIndex, externalId, onPictureView, property])

  const changeCurrentSlide = useCallback(
    index => {
      setCarouselActiveIndex(index)
      if (externalId) {
        onPictureView(property.convertedPictures.getIn([index, 'picture']))
      }
    },
    [externalId, onPictureView, property]
  )

  const handleCloseModal = useCallback(
    event => {
      if (externalId) {
        closeModal(event)
        return
      }
      // The page needs to reload so the server can render the AMP view
      window.location.assign(`${window.location.origin}/imovel/${id}`)
    },
    [closeModal, externalId, id]
  )

  useEffect(() => {
    if (id && !property) {
      dispatch(getProperty(id))
    }
  }, [dispatch, id, property])

  if (isLoading || !property) {
    return (
      <div className={styles.loading}>
        <Building isSmall className={styles.building} />
      </div>
    )
  }

  return (
    <AriaModal
      onExit={handleCloseModal}
      mounted={openModal}
      titleText={titleText}
      initialFocus="#modal"
      dialogClass={styles.modal}
      underlayClickExits
      includeDefaultStyles
    >
      <div id="modal" className={classnames(styles.container, className)}>
        <Button
          variant="contained"
          component={id ? 'a' : 'button'}
          color="primary"
          className={classnames(styles['button-close'], styles.button)}
          onClick={!id && handleCloseModal}
          href={id && `/imovel/${id}`}
        >
          Fechar
        </Button>
        <div className={styles.carousel}>
          {width > SIZE_TABLET ? (
            <Carousel
              primaryColor={primaryColor}
              pictures={property.convertedPictures.map(picture => picture.get('picture'))}
              propertyID={property.externalId}
              activePicture={carouselActiveIndex + 1}
              onClickArrowPreviews={onClickArrowPreviews}
              onClickArrowNext={onClickArrowNext}
              onPictureView={onPictureView}
            />
          ) : (
            <FullScreenMobileCarousel
              carouselActiveIndex={carouselActiveIndex}
              pictures={property.convertedPictures.map(picture => picture.get('picture'))}
              changeCurrentSlide={changeCurrentSlide}
              onPictureView={onPictureView}
            />
          )}
        </div>
        <div className={styles['container-miniatures']}>
          <div className={styles['carousel-miniatures']}>
            {property.convertedPictures.map((picture, index) => (
              <button
                className={styles['button-image']}
                onClick={setCarouselCurrentIndex}
                onMouseDown={onMouseDown}
                data-index={index}
                key={`${picture.get('thumbnail')}`}
                type="button"
              >
                <img
                  src={picture.get('thumbnail')}
                  alt="Miniatura da imágem do imóvel"
                  className={classnames(styles.pictures, {
                    [styles['active-picture']]: carouselActiveIndex === index,
                  })}
                />
              </button>
            ))}
          </div>
        </div>
        <div className={styles.count}>
          <p>
            {carouselActiveIndex + 1}/{property.pictures.size}
          </p>
        </div>
      </div>
    </AriaModal>
  )
}

PicturesFullScreen.propTypes = {
  className: PropTypes.string,
  openModal: PropTypes.bool,
  closeModal: PropTypes.func,
  titleText: PropTypes.string,
  property: PropTypes.shape({
    pictures: PropTypes.shape([]),
    externalId: PropTypes.string,
    convertedPictures: PropTypes.arrayOf([PropTypes.shape()]),
  }).isRequired,
  primaryColor: PropTypes.string.isRequired,
  externalId: PropTypes.string,
  onPictureView: PropTypes.func.isRequired,
}

PicturesFullScreen.defaultProps = {
  className: '',
  titleText: 'Modal pictures full screen',
  openModal: true,
  closeModal: () => {},
  externalId: '',
}

export default memo(PicturesFullScreen)

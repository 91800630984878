import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
  main: {
    display: 'grid',
    gridTemplateColumns: 'auto 48rem auto',
    gridTemplateAreas: "' . content . '",
    padding: '4rem 0 8rem',
    [theme.breakpoints.down('sm')]: {
      display: 'grid',
      gridTemplateColumns: 'auto 28rem auto',
      gridTemplateAreas: "' . content . '",
      padding: '11.4rem 0 4.8rem',
    },
  },
  content: {
    display: 'grid',
    gridArea: 'content',
  },
  desktopView: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  icon: {
    width: '20rem',
    height: '20.5rem',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      maxHeight: '16rem',
    },
  },
  title: {
    color: theme.palette.secondary.dark,
    textAlign: 'center',
    marginBottom: '1.6rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.6rem',
    },
  },
  description: {
    color: theme.palette.secondary.dark,
    fontWeight: 'normal',
    whiteSpace: 'normal',
    textAlign: 'center',
    marginBottom: '16.2rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.4rem',
      marginBottom: '12.8rem',
    },
  },
  button: {
    justifySelf: 'center',
    maxWidth: '18rem',
    backgroundColor: theme.palette.custom.carrotOrange,
    marginBottom: '1.6rem',
  },
  visitsButton: {
    justifySelf: 'center',
    maxWidth: '18rem',
    color: theme.palette.secondary.dark,
  },
}))

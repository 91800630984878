import React, { useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'

import Button, { ButtonColor, ButtonVariant, ButtonFormat } from '_components/button'
import MobileClickIcon from '_assets/images/mobile-click.svg'
import withAuth from '_hocs/with-auth'
import { getLeaseById } from '_modules/leases/selectors'
import { getLease } from '_modules/leases/actions'

import styles from './styles.css'

const QuitNegotiation = ({ id }) => {
  const dispatch = useDispatch()

  const lease = useSelector(state => getLeaseById(state, id))
  const request = useMemo(() => lease && lease.rejectionComment, [lease])

  useEffect(() => {
    if (!lease && id) {
      dispatch(getLease(id))
    }
  }, [dispatch, id, lease])

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <svg aria-hidden="true" className={styles.icon}>
          <use xlinkHref={MobileClickIcon} />
        </svg>
        <h1 className={styles.title}>Vamos tentar outro imóvel</h1>
        <p className={styles['sub-title']}>
          Sentimos muito, o proprietário desistiu desta negociação.
        </p>
        {request && (
          <div className={styles.justification}>
            <h2 className={styles.title}>Justificativa</h2>
            <p className={styles.comment}>{request}</p>
          </div>
        )}
        <Button
          color={ButtonColor.PRIMARY}
          variant={ButtonVariant.BOLD}
          format={ButtonFormat.ROUNDED}
          className={styles.button}
          to="/"
        >
          BUSCAR NOVOS IMÓVEIS
        </Button>
      </div>
    </div>
  )
}

QuitNegotiation.propTypes = {
  id: PropTypes.number.isRequired,
}

export default withAuth(React.memo(QuitNegotiation))

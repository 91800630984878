import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from '@material-ui/core'

import Button from '_components/material/button'
import { CANCEL_LEASE } from '_modules/leases/actions'
import useOnSuccessCall from '_hooks/use-on-success'

import useStyles from './styles'

const AlertModal = ({
  handleModal,
  title,
  description,
  handleConfirmClick,
  action,
  cancelButtonText,
  confirmButtonText,
}) => {
  const styles = useStyles()

  const onConfirmClick = useCallback(() => {
    handleConfirmClick()
  }, [handleConfirmClick])
  const [isLoading] = useOnSuccessCall(action, handleModal)
  const handleExitModal = useCallback(() => {
    if (!isLoading) {
      handleModal()
    }
  }, [handleModal, isLoading])
  return (
    <Dialog
      open
      onClose={handleExitModal}
      aria-labelledby="action-modal"
      aria-describedby={`Modal para ${title}`}
      className={styles.dialog}
    >
      <DialogTitle disableTypography className={styles.dialogTitle} id={`Modal para ${title}`}>
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText className={styles.dialogContent} id={`Modal para ${title}`}>
          {description}
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions className={styles.actions}>
        <Button className={styles.actionButton} onClick={handleExitModal} color="default">
          {cancelButtonText}
        </Button>
        <Divider orientation="vertical" flexItem />
        <Button
          isLoading={isLoading}
          className={styles.actionButton}
          onClick={onConfirmClick}
          color="default"
          autoFocus
        >
          {confirmButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

AlertModal.propTypes = {
  handleModal: PropTypes.func.isRequired,
  handleConfirmClick: PropTypes.func.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  cancelButtonText: PropTypes.string,
  confirmButtonText: PropTypes.string,
  action: PropTypes.string,
}

AlertModal.defaultProps = {
  title: 'Encerrar negociação',
  description:
    'Ao encerrar esta negociação sua análise será cancelada e não será possível continuar alocação neste imóvel.',
  cancelButtonText: 'VOLTAR',
  confirmButtonText: 'ENCERRAR',
  action: CANCEL_LEASE.ACTION,
}

export default React.memo(AlertModal)

import React, { useMemo, useCallback, useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { navigate } from '@reach/router'
import { useSelector, useDispatch } from 'react-redux'

import Snackbar from '_components/snackbar'
import { triggerSnackbar, TRIGGER_SNACKBAR } from '_modules/snackbar/actions'
import withAuth from '_hocs/with-auth'
import Button, { ButtonVariant, ButtonFormat, ButtonColor } from '_components/button'
import {
  getLeaseById,
  checkIsCreatingParticipants,
  checkIsLoadingLease,
  checkIsSendingToReview,
  checkIsUpdatingUserData,
} from '_modules/leases/selectors'
import {
  getLease,
  sendLeaseToReview,
  inviteParticipants,
  updateParticipants,
  updateUserData,
} from '_modules/leases/actions'
import { usePrevious } from '_utils/hooks'
import InviteParticipant from '_components/add-income-helpers/invite-participant'
import InviteIncomeHelper from '_components/add-income-helpers/income-helper'
import { FEATURE_FLAGS, PARTICIPANTS_TYPE, WARRANTY_TYPES } from '_utils/constants'
import { getCurrentWarranty } from '_modules/warranty/selectors'

import styles from './styles.css'

const AddRenter = ({ id }) => {
  // eslint-disable-next-line no-unused-vars
  const [isButtonDisabled, setDisabledButton] = useState(true)
  const lease = useSelector(state => getLeaseById(state, id))
  const dispatch = useDispatch()
  const tenantsRef = useRef()
  const incomeHelpersRef = useRef()
  const considerIncomeRef = useRef()
  const currentWarranty = useSelector(state =>
    getCurrentWarranty(state, lease && lease.warranty && lease.warranty)
  )

  // Loading state to get lease
  const isLoadingLease = useSelector(checkIsLoadingLease)
  const wasLoadingLease = usePrevious(isLoadingLease)

  // Loading state to invite participants
  const isAddingIncomeHelper = useSelector(checkIsCreatingParticipants)
  const wasAddingIncomeHelper = usePrevious(isAddingIncomeHelper)

  // Loading state to send the lease to review
  const isSendingLeaseToReview = useSelector(checkIsSendingToReview)
  const wasSendingLeaseToReview = usePrevious(isSendingLeaseToReview)

  // Loading state to update the mainTenant considerIncome field
  const isUpdatingUser = useSelector(checkIsUpdatingUserData)
  const wasUpdatingUser = usePrevious(isUpdatingUser)

  const externalId = useMemo(() => (lease ? lease.listing.get('externalId') : null), [lease])

  const defaultUrl = useMemo(() => `/minhas-negociacoes/${id}`, [id])

  // SIDE EFFECTS
  useEffect(() => {
    if (id) {
      dispatch(getLease(id))
    }
  }, [dispatch, id])

  const isReadyToReview = useMemo(
    () => (!isAddingIncomeHelper && wasAddingIncomeHelper) || (!isUpdatingUser && wasUpdatingUser),
    [isAddingIncomeHelper, isUpdatingUser, wasAddingIncomeHelper, wasUpdatingUser]
  )

  useEffect(() => {
    if (isReadyToReview) {
      dispatch(sendLeaseToReview({ externalId, leaseId: id }))
    }
  }, [
    defaultUrl,
    dispatch,
    externalId,
    id,
    isAddingIncomeHelper,
    isReadyToReview,
    lease,
    wasAddingIncomeHelper,
  ])

  useEffect(() => {
    if (wasLoadingLease && !isLoadingLease && !lease) {
      navigate(defaultUrl)
    }
  }, [defaultUrl, id, isLoadingLease, lease, wasLoadingLease])

  useEffect(() => {
    // TO-DO: check error state
    if (wasSendingLeaseToReview && !isSendingLeaseToReview) {
      if (
        lease &&
        lease.warranty === WARRANTY_TYPES.RAPID &&
        lease.fireInsurancePrice &&
        FEATURE_FLAGS.fireInsuranceEnabled &&
        !FEATURE_FLAGS.creditAnalysisIntegrationEnabled
      ) {
        navigate(`${defaultUrl}/seguro-incendio`)
        return
      }
      navigate(`${defaultUrl}/revisao`)
    }
  }, [defaultUrl, id, isSendingLeaseToReview, lease, wasSendingLeaseToReview])

  const handleUpdateDisableButton = useCallback(() => {
    if (lease && considerIncomeRef.current.total > lease.requiredIncome) {
      setDisabledButton(false)
      return
    }
    if (tenantsRef.current && incomeHelpersRef.current) {
      const hasErrors =
        !!incomeHelpersRef.current.filter(item => item.emailError).length ||
        !!tenantsRef.current.filter(item => item.emailError).length

      const hasParticipants = incomeHelpersRef.current.length > 1 || tenantsRef.current.length > 1
      setDisabledButton(!(hasErrors || hasParticipants))
    }
  }, [lease])
  const onSubmit = useCallback(
    event => {
      event.preventDefault()
      const allParticipants = [...incomeHelpersRef.current, ...tenantsRef.current]

      if (allParticipants.some(({ emailError }) => emailError)) {
        dispatch(triggerSnackbar())
        return
      }

      dispatch(inviteParticipants(lease.get('id'), allParticipants))
      if (!considerIncomeRef.current.considerIncome) {
        dispatch(
          updateUserData({
            leaseId: id,
            externalId: lease.listing.get('externalId'),
            considerIncome: false,
          })
        )
      }
      if (considerIncomeRef.current.participants.length) {
        considerIncomeRef.current.participants.map(({ participantId, considerIncome }) => {
          return dispatch(
            updateParticipants(
              lease.get('id'),
              participantId,
              { considerIncome },
              lease.listing.get('externalId')
            )
          )
        })
      }
    },
    [dispatch, id, lease]
  )

  const existingTenants = useMemo(
    () =>
      lease && lease.participants.size
        ? lease.participants
            .toJS()
            .filter(({ category, enabled }) => category === PARTICIPANTS_TYPE.TENANT && enabled)
        : [],
    [lease]
  )

  const TEXT_OPTION = {
    FIRST: (
      <p>
        O grupo de locatários responsáveis pela comprovação de renda precisam comprovar{' '}
        {currentWarranty && currentWarranty.get('requiredIncomeMultiplier')}x o valor do aluguel +
        encargos.
      </p>
    ),
    SECOND: (
      <p>
        <strong>Confira as informações dos locatários</strong> ou se necessário convide mais pessoas
        para compor renda.
      </p>
    ),
  }

  const incomeHelperDescription = useMemo(
    () =>
      lease && lease.warranty === WARRANTY_TYPES.RAPID ? TEXT_OPTION.FIRST : TEXT_OPTION.SECOND,
    [TEXT_OPTION.FIRST, TEXT_OPTION.SECOND, lease]
  )

  if (!lease) {
    // TO-DO: add a loading state
    return null
  }

  // TO-DO: Define lease status
  // if (lease.status === LEASE_STATUS.INCOME_HELPERS_PENDING) {
  //   return <Redirect to={`${defaultUrl}/aguardando-resposta`} noThrow />
  // }

  // if (lease.status === LEASE_STATUS.PENDING) {
  //   return <Redirect to={`/analise-credito/${id}/registrar-documentos-participante`} noThrow />
  // }

  const isLoading = isAddingIncomeHelper || isSendingLeaseToReview || isUpdatingUser

  return (
    <div className={styles.container}>
      <form className={styles.content} onSubmit={onSubmit}>
        <h1 className={styles.title}>Convide pessoas para o contrato</h1>
        <h2 className={styles['sub-title']}>
          Precisamos dos e-mails dos <span className={styles.bold}>locatários</span> que lhe
          ajudarão a compor sua renda.
        </h2>
        <InviteIncomeHelper
          incomeHelperRef={incomeHelpersRef}
          handleRefUpdated={handleUpdateDisableButton}
          lease={lease.toJS()}
          considerIncomeRef={considerIncomeRef}
          description={incomeHelperDescription}
        />
        <InviteParticipant
          title="Outros Moradores"
          description="Se necessário, atualize a lista de moradores que não comprovam renda."
          type="tenant"
          participantRef={tenantsRef}
          handleRefUpdated={handleUpdateDisableButton}
          existingTenants={existingTenants}
          leaseId={id}
          externalId={externalId}
        />
        <Button
          color={ButtonColor.PRIMARY}
          variant={ButtonVariant.BOLD}
          format={ButtonFormat.ROUNDED}
          className={styles.action}
          isLoading={isLoading}
          type="submit"
        >
          CONVIDAR
        </Button>
      </form>
      <Snackbar
        variant="error"
        message="Verifique se todos os participantes estão com os dados corretos"
        action={TRIGGER_SNACKBAR.ACTION}
      />
    </div>
  )
}

AddRenter.propTypes = {
  id: PropTypes.string.isRequired,
}

export default withAuth(React.memo(AddRenter))

import React from 'react'
import PropTypes from 'prop-types'
import AriaModal from 'react-aria-modal'

import CloseIcon from '_assets/images/ic-fechar.svg'
import Button, { ButtonColor, ButtonVariant } from '_components/button'
import CalendarPage, { CalendarTheme } from '_components/calendar-page'
import { onMouseDown } from '_utils/helpers'

import styles from './styles.css'

const UpdateDateModal = ({
  closeModal,
  onUpdateDate,
  primaryColor,
  month,
  day,
  isUpdatingBooking,
}) => (
  <AriaModal titleText="Agendamento de visita" underlayClass={styles.underlay}>
    <div className={styles.modal}>
      <button className={styles.close} onClick={closeModal} onMouseDown={onMouseDown} type="button">
        <svg alt="Fechar" className={styles.image}>
          <use xlinkHref={CloseIcon} />
        </svg>
      </button>
      <div className={styles.content}>
        <CalendarPage
          className={styles.calendar}
          month={month}
          day={day}
          primaryColor={primaryColor}
          havePencilIcon
          theme={CalendarTheme.BIG}
        />
        <p className={styles.info}>Tem certeza que deseja alterar a data da sua visita?</p>
        <div className={styles.buttons}>
          <Button
            color={ButtonColor.SECONDARY}
            variant={ButtonVariant.BOLD}
            onClick={closeModal}
            className={styles.cancel}
          >
            VOLTAR
          </Button>
          <Button
            color={ButtonColor.PRIMARY}
            variant={ButtonVariant.BOLD}
            onClick={onUpdateDate}
            className={styles.ok}
            isLoading={isUpdatingBooking}
          >
            SIM
          </Button>
        </div>
      </div>
    </div>
  </AriaModal>
)

UpdateDateModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  onUpdateDate: PropTypes.func.isRequired,
  primaryColor: PropTypes.string.isRequired,
  month: PropTypes.string.isRequired,
  day: PropTypes.string.isRequired,
  isUpdatingBooking: PropTypes.bool,
}

UpdateDateModal.defaultProps = {
  isUpdatingBooking: false,
}

export default UpdateDateModal

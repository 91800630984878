import React from 'react'
import PropTypes from 'prop-types'
import AriaModal from 'react-aria-modal'

import IconClose from '_assets/images/ic-close.svg'
import IconModal from '_assets/images/modal-reserved.svg'
import { GENERAL } from '_utils/constants'

import styles from './styles.css'

const ModalReserved = ({ openModal, closeModal }) => (
  <AriaModal
    titleText="Agendamento de visita"
    underlayClass={styles.modal}
    focusDialog
    className={styles.modal}
    onExit={closeModal}
    mounted={openModal}
  >
    <div className={styles.content}>
      <button type="button" onClick={closeModal} className={styles.button}>
        <svg aria-hidden="true" className={styles['icon-close']}>
          <use xlinkHref={IconClose} />
        </svg>
      </button>
      <svg aria-hidden="true" className={styles['svg-icon']}>
        <use xlinkHref={IconModal} />
      </svg>
      <h1>Imóvel reservado</h1>
      <p className={styles.description}>
        Outra pessoa está de olho nesse imóvel. Se você tiver sorte, em
        {` ${GENERAL.hoursReserved} `}horas ele voltará a estar disponível.
      </p>
      <button type="button" onClick={closeModal} className={styles.button}>
        ENTENDI
      </button>
    </div>
  </AriaModal>
)

ModalReserved.propTypes = {
  openModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
}

export default React.memo(ModalReserved)

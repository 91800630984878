import React, { useMemo, useCallback } from 'react'
import AriaModal from 'react-aria-modal'
import PropTypes from 'prop-types'
import { navigate } from '@reach/router'

import Button, { ButtonFormat, ButtonVariant, ButtonColor } from '_components/button'
import { onMouseDown } from '_utils/helpers'
import CloseIcon from '_assets/images/ic-fechar.svg'
import HomeIcon from '_assets/images/ic-home.svg'

import styles from './styles.css'

const AccountModal = ({ onClose, primaryColor }) => {
  const textColorStyle = useMemo(
    () => ({
      color: primaryColor,
    }),
    [primaryColor]
  )

  const fillColorStyle = useMemo(
    () => ({
      fill: primaryColor,
    }),
    [primaryColor]
  )

  const onContinueClick = useCallback(() => {
    navigate('/login')
    onClose()
  }, [onClose])

  return (
    <AriaModal titleText="Agendamento de visita" underlayClass={styles.underlay} focusDialog>
      <div className={styles.modal}>
        <div className={styles.header}>
          <button
            type="button"
            className={styles.close}
            onClick={onClose}
            onMouseDown={onMouseDown}
            aria-label="fechar"
          >
            <svg aria-hidden="true" className={styles.icon}>
              <use xlinkHref={CloseIcon} />
            </svg>
          </button>
        </div>
        <div className={styles.body}>
          <svg aria-hidden="true" className={styles['home-icon']} style={fillColorStyle}>
            <use xlinkHref={HomeIcon} />
          </svg>
          <p className={styles.title} style={textColorStyle}>
            Acesse sua conta
          </p>
          <p className={styles.description}>
            Para realizar essa ação é necessário acessar a sua conta
          </p>
          <Button
            color={ButtonColor.PRIMARY}
            variant={ButtonVariant.BOLD}
            format={ButtonFormat.ROUNDED}
            className={styles.button}
            onClick={onContinueClick}
          >
            MINHA CONTA
          </Button>
        </div>
        <div className={styles.footer}>
          <p className={styles['call-to-action']}>Ainda não possui uma conta?</p>
          <Button
            color={ButtonColor.PRIMARY_TEXT}
            className={styles.link}
            style={textColorStyle}
            onClick={onContinueClick}
          >
            Cadastre-se aqui.
          </Button>
        </div>
      </div>
    </AriaModal>
  )
}

AccountModal.propTypes = {
  onClose: PropTypes.func,
  primaryColor: PropTypes.string.isRequired,
}

AccountModal.defaultProps = {
  onClose: () => {},
}

export default React.memo(AccountModal)

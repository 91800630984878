import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ breakpoints }) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',

    [breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      width: '19rem',
      margin: 'auto',
    },
  },
  button: {
    borderRadius: '3rem',
    height: '4rem',

    [breakpoints.down('sm')]: {
      marginBottom: '4rem',
    },
  },
}))

import React, { useState, useMemo, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { InputAdornment, SvgIcon, Tooltip, MenuItem, TextField } from '@material-ui/core'
import { SwapHoriz, Search, Room, Home, MeetingRoom } from '@material-ui/icons'
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import { useDebounce } from 'use-debounce'

import Price from '_assets/images/price.svg'
import Button from '_components/material/button'
import { searchSelector } from '_modules/search/selectors'
import {
  getCitiesValues,
  getNeighborhoodValues,
  getNeighborhoodsLoading,
} from '_modules/utils/selectors'
import IconFilter from '_assets/images/ic_filter.svg'
import { getCities, getNeighborhoods } from '_modules/utils/actions'
import { setFilter } from '_modules/search/actions'
import { setFilterRecommendations } from '_modules/recommendations/actions'
import { usePrevious, useWindowSize } from '_utils/hooks'
import {
  FEATURE_FLAGS,
  SEARCH_BEDROOMS_OPTIONS,
  SEARCH_SORT_OPTIONS,
  SEARCH_FILTERS,
  SEARCH_PROPERTY_TYPES_OPTIONS,
} from '_utils/constants'
import SelectMultipleCheckbox from '_components/select-multiple-checkbox'
// import CurrencyField from '_components/currency-field'

import useMaterial from './materialStyle'
import styles from './styles.css'

const ADORNMENTS_ICONS = {
  city: <Search />,
  neighborhood: <Room />,
  price: (
    <svg aria-hidden="true" className={styles['filter-icon']}>
      <use xlinkHref={Price} />
    </svg>
  ),
  bedrooms: <MeetingRoom />,
  purpose: <MeetingRoom />,
}
const INITIAL_CITY = { value: 'Cidade', label: 'Cidade' }
const INITIAL_NEIGHBORHOOD = []
const INITIAL_BEDROOMS = { value: 'Quartos', label: 'Quartos' }
const INITIAL_PURPOSE_TYPE = { value: 'Tipo de imóvel', label: 'Tipo de imóvel' }
const INITIAL_SORT = { value: 'Ordenar', label: 'Ordenar' }
const ALL_NEIGHBORHOOD = 'todos'

const MAX_CURRENCY_INPUT_VALUE = 10 ** 9
const MIN_CURRENCY_INPUT_VALUE = 0

const FiltersSection = ({
  onChange,
  onOpenMoreFilters,
  className,
  initialState,
  reorderProperties,
  isRecommendation,
}) => {
  const materialStyle = useMaterial()

  const dispatch = useDispatch()
  const isGettingNeighborhoods = useSelector(getNeighborhoodsLoading)
  const { query } = useSelector(searchSelector)
  const valuesQueries = query && query.toJS()
  const { isMobile } = useWindowSize()

  const [selectedCity, setSelectedCity] = useState(INITIAL_CITY)
  const [selectedBedrooms, setBedrooms] = useState(INITIAL_BEDROOMS)
  const [selectedPurposeType, setSelectedPurposeType] = useState(INITIAL_PURPOSE_TYPE)
  const [selectedSorted, setSelectedOrder] = useState(INITIAL_SORT)
  const [currency, setCurrency] = useState('')
  const [debouncedCurrency] = useDebounce(currency, 1000)

  const fields = FEATURE_FLAGS.commercialListingsEnabled
    ? SEARCH_PROPERTY_TYPES_OPTIONS
    : SEARCH_BEDROOMS_OPTIONS

  const disableNeighborhoodField = useMemo(() => {
    if (!isGettingNeighborhoods && selectedCity) {
      return false
    }
    return true
  }, [selectedCity, isGettingNeighborhoods])

  const cityOptions = useSelector(state => getCitiesValues(state))
  const neighborhoodOptions = useSelector(state => getNeighborhoodValues(state))

  useEffect(() => {
    dispatch(getCities())
  }, [dispatch])

  const resetFilters = useCallback(() => {
    setBedrooms(INITIAL_BEDROOMS)
    setCurrency('')
  }, [])

  useEffect(() => {
    if (initialState) {
      resetFilters()
      if (initialState.city && cityOptions.size) {
        setSelectedCity(
          cityOptions.find(option => option.value === initialState.city) || INITIAL_CITY
        )
      }
      if (initialState.price) {
        const price = Number(initialState.price) || ''
        if (price > MAX_CURRENCY_INPUT_VALUE) {
          setCurrency(MAX_CURRENCY_INPUT_VALUE)
          return
        }
        if (price < MIN_CURRENCY_INPUT_VALUE) {
          setCurrency('')
          return
        }
        setCurrency(price)
      }
      if (initialState.bedrooms) {
        const bedrooms =
          SEARCH_BEDROOMS_OPTIONS.find(option => option.value === Number(initialState.bedrooms)) ||
          INITIAL_BEDROOMS
        setBedrooms(bedrooms)
      }

      if (initialState.purposeType) {
        const purposeType =
          SEARCH_PROPERTY_TYPES_OPTIONS.find(option => option.value === initialState.purposeType) ||
          INITIAL_PURPOSE_TYPE
        setSelectedPurposeType(purposeType)
      }
    }
    // initialState can't be dependency or it will override the search parameters
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cityOptions, resetFilters])

  const neighborhoodValues = isRecommendation
    ? initialState && initialState.neighborhoods
    : valuesQueries.neighborhoods

  const prevSelectedCity = usePrevious(selectedCity)
  useEffect(() => {
    if (selectedCity && selectedCity !== prevSelectedCity) {
      if (selectedCity.value === 'Todas') {
        dispatch(getNeighborhoods({}))
      }

      if (selectedCity.value === 'Cidade') {
        return
      }

      dispatch(
        getNeighborhoods({
          cities: selectedCity.value,
        })
      )
      valuesQueries.neighborhoods = []
    }
  }, [selectedCity, dispatch, prevSelectedCity, valuesQueries.neighborhoods])

  const onFilterChange = useCallback(
    (city, neighborhoods, price, bedrooms, purposeType) => {
      onChange({
        ...(!city || city === INITIAL_CITY.value ? {} : { city }),
        ...(!neighborhoods || neighborhoods === INITIAL_NEIGHBORHOOD
          ? INITIAL_NEIGHBORHOOD
          : { neighborhoods }),
        ...(price === undefined || price === null ? {} : { price }),
        ...(!bedrooms || bedrooms === INITIAL_BEDROOMS.value ? {} : { bedrooms }),
        ...(!purposeType || purposeType === INITIAL_PURPOSE_TYPE.value ? {} : { purposeType }),
      })
    },
    [onChange]
  )

  const onInputChange = useCallback(
    event => {
      const { value, name } = event.target
      if (isMobile) {
        return
      }
      if (isRecommendation) {
        dispatch(setFilterRecommendations({ [name]: value }))
      } else {
        dispatch(setFilter({ [name]: value }))
      }
      const initialNeighborhoods =
        initialState && initialState.neighborhoods && initialState.neighborhoods.length
          ? initialState.neighborhoods
          : INITIAL_NEIGHBORHOOD
      switch (name) {
        case SEARCH_FILTERS.CITY: {
          setSelectedCity(cityOptions.find(option => option.value === value))
          onFilterChange(
            value,
            [],
            debouncedCurrency,
            selectedBedrooms.value,
            selectedPurposeType.value
          )
          if (isRecommendation) {
            dispatch(
              setFilterRecommendations({
                initialNeighborhoods,
              })
            )
            return
          }
          dispatch(setFilter({ neighborhoods: [] }))

          return
        }
        case SEARCH_FILTERS.NEIGHBORHOODS: {
          let neighborhoods = initialNeighborhoods
          if (value.includes(ALL_NEIGHBORHOOD)) {
            if (value[0] === ALL_NEIGHBORHOOD && value.length > 1) {
              neighborhoods = [value[1]]
            } else {
              neighborhoods = [ALL_NEIGHBORHOOD]
            }
          } else {
            neighborhoods = value
          }
          dispatch(setFilter({ neighborhoods }))
          onFilterChange(
            selectedCity.value,
            neighborhoods,
            debouncedCurrency,
            selectedBedrooms.value,
            selectedPurposeType.value
          )
          return
        }

        case SEARCH_FILTERS.PURPOSE_TYPE: {
          const property = SEARCH_PROPERTY_TYPES_OPTIONS.find(option => option.value === value)
          setSelectedPurposeType(property)
          onFilterChange(
            selectedCity.value,
            valuesQueries.neighborhoods,
            debouncedCurrency,
            selectedBedrooms.value,
            property.value
          )
          return
        }

        default: {
          const bedrooms = SEARCH_BEDROOMS_OPTIONS.find(option => option.value === value)
          setBedrooms(bedrooms)
          onFilterChange(
            selectedCity.value,
            valuesQueries.neighborhoods,
            debouncedCurrency,
            value,
            selectedPurposeType.value
          )
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      cityOptions,
      debouncedCurrency,
      dispatch,
      isMobile,
      isRecommendation,
      onFilterChange,
      selectedBedrooms,
      selectedCity,
      selectedPurposeType,
    ]
  )
  const handleCurrency = useCallback((_, value) => {
    if (!value) {
      setCurrency(0)
    } else {
      setCurrency(value)
    }
  }, [])

  useEffect(
    () => {
      // it doesn't work when debouncedCurrency is removed from this condition
      if (!isMobile && (debouncedCurrency === 0 || debouncedCurrency)) {
        const neighborhoods = isRecommendation
          ? initialState.neighborhoods
          : valuesQueries.neighborhoods
        onFilterChange(selectedCity.value, neighborhoods, debouncedCurrency, selectedBedrooms.value)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedCurrency, isMobile, selectedBedrooms, selectedCity]
  )

  const onChangeOrder = useCallback(
    event => {
      const { value } = event.target

      const order = SEARCH_SORT_OPTIONS.find(option => option.value === value)
      setSelectedOrder(order)
      reorderProperties(order.value)
    },
    [reorderProperties]
  )
  const renderAdornment = useCallback(
    icon => ({
      startAdornment: <InputAdornment position="start">{icon}</InputAdornment>,
      classes: { input: materialStyle.input },
    }),
    [materialStyle.input]
  )

  useEffect(() => {
    if (query.bedrooms || query.bedrooms === undefined) {
      const bedrooms =
        SEARCH_BEDROOMS_OPTIONS.find(option => option.value === Number(query.bedrooms)) ||
        INITIAL_BEDROOMS
      setBedrooms(bedrooms)
    }
  }, [onFilterChange, query.bedrooms])

  const tooltipCustom = useMemo(
    () => ({
      tooltip: materialStyle.customTooltip,
    }),
    [materialStyle.customTooltip]
  )
  return (
    // TODO: Refactor to MUI
    <section className={classnames(styles.container, className)}>
      <Tooltip
        placement="bottom-start"
        classes={tooltipCustom}
        title="Cidade"
        arial-label="Cidade a ser selecionada"
        open={false}
      >
        <TextField
          id="outlined-select-city"
          select
          className={classnames(styles.input, styles['input-city'], materialStyle.textField)}
          value={selectedCity.value}
          onChange={onInputChange}
          margin="normal"
          variant="outlined"
          name="city"
          InputProps={renderAdornment(ADORNMENTS_ICONS.city)}
        >
          <MenuItem value="Cidade" disabled>
            Cidade
          </MenuItem>
          {cityOptions.map(optionsCity => (
            <MenuItem key={optionsCity.value} value={optionsCity.value}>
              {optionsCity.label}
            </MenuItem>
          ))}
        </TextField>
      </Tooltip>

      <Tooltip
        placement="bottom-start"
        classes={tooltipCustom}
        title="Bairro"
        arial-label="Bairro a ser selecionado"
        disableTouchListener
        open={false}
      >
        <SelectMultipleCheckbox
          id="outlined-select-neighborhood"
          displayEmpty
          classNames={classnames(
            styles.input,
            styles['input-neighborhood'],
            materialStyle.multipleSelect,
            materialStyle.root
          )}
          value={neighborhoodValues}
          handleChange={onInputChange}
          name={SEARCH_FILTERS.NEIGHBORHOODS}
          disabled={disableNeighborhoodField}
          startAdornmentIcon={<Home />}
          labelItem="Bairro"
          optionsItens={neighborhoodOptions}
        />
      </Tooltip>
      {/* it works and emptyInputBehavior="zero" */}
      <CurrencyTextField
        emptyInputBehavior="zero"
        minimumValue="0"
        InputProps={renderAdornment(ADORNMENTS_ICONS.price)}
        variant="outlined"
        value={currency}
        onChange={handleCurrency}
        digitGroupSeparator="."
        decimalCharacter=","
        textAlign="left"
        className={classnames(styles.input, styles['input-price'], materialStyle.textField)}
        name="price"
        placeholder="Aluguel até"
      />
      {/* <CurrencyField
        InputProps={renderAdornment(ADORNMENTS_ICONS.price)}
        variant="outlined"
        value={currency}
        onChange={handleCurrency}
        textAlign="left"
        className={classnames(styles.input, styles['input-price'], materialStyle.textField)}
        name="price"
        placeholder="Aluguel até"
      /> */}
      <Tooltip
        placement="bottom-start"
        classes={tooltipCustom}
        title={FEATURE_FLAGS.commercialListingsEnabled ? 'Tipo de Imóvel' : 'Quartos'}
        arial-label={
          FEATURE_FLAGS.commercialListingsEnabled
            ? 'Tipo de Imóvel a serem selecionados'
            : 'Número de quartos a serem selecionados'
        }
        disableTouchListener
        open={false}
      >
        <TextField
          id={
            FEATURE_FLAGS.commercialListingsEnabled
              ? 'outlined-select-purposeType'
              : 'outlined-select-bedrooms'
          }
          select
          className={classnames(styles.input, styles['input-bedrooms'], materialStyle.textField)}
          value={
            FEATURE_FLAGS.commercialListingsEnabled
              ? selectedPurposeType.value
              : selectedBedrooms.value
          }
          onChange={onInputChange}
          margin="normal"
          variant="outlined"
          name={FEATURE_FLAGS.commercialListingsEnabled ? 'purposeType' : 'bedrooms'}
          InputProps={renderAdornment(
            FEATURE_FLAGS.commercialListingsEnabled
              ? ADORNMENTS_ICONS.purpose
              : ADORNMENTS_ICONS.bedrooms
          )}
        >
          <MenuItem
            key={FEATURE_FLAGS.commercialListingsEnabled ? 'empty-bedrooms' : 'empty-bedrooms'}
            value={FEATURE_FLAGS.commercialListingsEnabled ? 'Tipo de imóvel' : 'Quartos'}
            disabled
          >
            {FEATURE_FLAGS.commercialListingsEnabled ? 'Tipo de imóvel' : 'Quartos'}
          </MenuItem>
          {fields.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.description}
            </MenuItem>
          ))}
        </TextField>
      </Tooltip>
      <Button
        className={styles['more-button-filters']}
        onClick={onOpenMoreFilters}
        variant="contained"
      >
        Mais Filtros
      </Button>
      <Button
        onClick={onOpenMoreFilters}
        variant="contained"
        className={classnames(styles['button-component'], styles['mobile-button'])}
        startIcon={
          <SvgIcon
            aria-hidden="true"
            className={styles['filter-icon']}
            viewBox={IconFilter.viewBox}
          >
            <use xlinkHref={IconFilter} />
          </SvgIcon>
        }
      >
        Filtros
      </Button>
      <TextField
        id="outlined-select-sort"
        select
        className={classnames(styles['input-sort'], materialStyle.textFieldInputSort)}
        onChange={onChangeOrder}
        value={selectedSorted.value}
        margin="normal"
        variant="outlined"
        name="sort"
      >
        <MenuItem key="sort" value="Ordenar" disabled>
          <SwapHoriz className={styles.icon} />
          Ordenar
        </MenuItem>
        {SEARCH_SORT_OPTIONS.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </section>
  )
}

FiltersSection.propTypes = {
  onChange: PropTypes.func,
  onOpenMoreFilters: PropTypes.func,
  className: PropTypes.string,
  initialState: PropTypes.shape({
    city: PropTypes.string,
    neighborhoods: PropTypes.arrayOf(PropTypes.string),
    price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    bedrooms: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    purposeType: PropTypes.string,
  }).isRequired,
  reorderProperties: PropTypes.func.isRequired,
  isRecommendation: PropTypes.bool,
}

FiltersSection.defaultProps = {
  onChange: () => {},
  onOpenMoreFilters: () => {},
  className: '',
  isRecommendation: false,
}

export default React.memo(FiltersSection)

import React, { memo } from 'react'
import PropTypes from 'prop-types'

import { shadeColor } from '_utils/colors'

import styles from './styles.css'

const SpaceShipIcon = ({ className, primaryColor }) => {
  const lighterColor = shadeColor(primaryColor)

  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="291.629"
      height="283.859"
      viewBox="0 0 291.629 283.859"
    >
      <g transform="translate(-537.408 -144)">
        <path
          className={styles.a}
          d="M142.868,53.383c75.352-5.126,110.33,33.124,129.648,84.38s48.037,68.913,28.23,127.922-91.335,66.8-119.229,59.911S124.593,300.818,89.8,287.412c-51.759-19.988-64.547-46.26-65.577-72.12-1.468-36.614,17.837-40.7,24.044-80.839C55.693,86.326,83.923,57.388,142.868,53.383Z"
          transform="translate(513.272 94.132)"
        />
        <path
          className={styles.b}
          d="M40.857,113.684C3.883,173.028,34.135,248.329,74.341,287.145c35.854,34.6,98.791,58.623,133.5,36.5,25.358-16.175,19.034-46.363,57.528-76.846,25.01-19.794,37.348-14.681,45.5-28.758,19.46-33.664-21.031-114.464-86.711-144.677C169.256,48.042,80.227,50.463,40.857,113.684Z"
          transform="translate(513.272 95.686)"
        />
        <path
          className={styles.c}
          d="M182.1,174.913a.4.4,0,0,1-.373-.258,5.01,5.01,0,0,0-3.374-3.374.425.425,0,0,1-.258-.386.4.4,0,0,1,.258-.373,5.01,5.01,0,0,0,3.374-3.374.4.4,0,0,1,.373-.258.425.425,0,0,1,.386.258,4.958,4.958,0,0,0,3.374,3.374.4.4,0,0,1,.258.373.425.425,0,0,1-.258.386,4.958,4.958,0,0,0-3.374,3.374A.425.425,0,0,1,182.1,174.913Z"
          transform="translate(557.588 126.938)"
        />
        <g transform="translate(0 16)">
          <path
            className={styles.d}
            d="M118.1,170.945h0a2.576,2.576,0,0,0,2.576-2.576v-3.284a2.576,2.576,0,0,0-2.576-2.576h0a2.576,2.576,0,0,0-2.576,2.576v3.271a2.576,2.576,0,0,0,2.576,2.589Z"
            transform="translate(539.577 160.677)"
          />
          <path
            className={styles.d}
            d="M126.006,178.82h0a2.576,2.576,0,0,0-2.576,2.576v3.271a2.576,2.576,0,0,0,2.576,2.576h0a2.576,2.576,0,0,0,2.576-2.576v-3.3A2.576,2.576,0,0,0,126.006,178.82Z"
            transform="translate(541.854 165.372)"
          />
          <path
            className={styles.d}
            d="M137.886,165.82h0a2.576,2.576,0,0,0-2.576,2.576v7.122a2.576,2.576,0,0,0,2.576,2.576h0a2.576,2.576,0,0,0,2.576-2.576V168.37A2.576,2.576,0,0,0,137.886,165.82Z"
            transform="translate(551.273 146.63)"
          />
          <path
            className={styles.d}
            d="M115.55,128.91v36.472a2.576,2.576,0,0,0,2.576,2.576h0a2.576,2.576,0,0,0,2.576-2.576V154.01a2.576,2.576,0,0,1,2.576-2.576h0a2.576,2.576,0,0,1,2.576,2.576v33.252a2.576,2.576,0,0,0,2.576,2.576h0A2.576,2.576,0,0,0,131,187.263V162.652a2.576,2.576,0,0,1,2.576-2.576h0a2.576,2.576,0,0,1,2.576,2.576v6.375a2.576,2.576,0,0,0,2.576,2.576h0a2.576,2.576,0,0,0,2.576-2.576V148.885a2.576,2.576,0,0,1,2.576-2.576h0a2.576,2.576,0,0,1,2.576,2.576v7.186a2.576,2.576,0,0,0,2.576,2.576h0a2.576,2.576,0,0,0,2.576-2.576V128.923Z"
            transform="translate(539.585 151.005)"
          />
          <path
            style={{ fill: lighterColor }}
            d="M105.854,84.09c0-3.864.142-7.573.4-11.32-11.179,6.285-18.88,20.309-18.893,36.549a46.891,46.891,0,0,0,6.169,23.658,2.048,2.048,0,0,0,2.576.863,45.19,45.19,0,0,1,16.845-3.323,151.35,151.35,0,0,1-7.1-46.427Z"
            transform="translate(531.47 134.845)"
          />
          <path
            style={{ fill: primaryColor }}
            d="M92.638,109.319c0-13.407,5.254-25.306,13.394-32.583,0-1.288.129-2.653.232-3.967-11.191,6.285-18.893,20.309-18.893,36.549a46.749,46.749,0,0,0,6.156,23.658,2.048,2.048,0,0,0,2.576.863c.876-.361,1.764-.67,2.653-.979a46.7,46.7,0,0,1-6.117-23.542Z"
            transform="translate(531.473 134.845)"
          />
          <path
            style={{ fill: lighterColor }}
            d="M158.387,72.78c.258,3.761.4,7.534.4,11.32a151.3,151.3,0,0,1-7.1,46.427,45.2,45.2,0,0,1,16.845,3.336,2.022,2.022,0,0,0,.773.155A2.073,2.073,0,0,0,171.085,133a46.891,46.891,0,0,0,6.169-23.658C177.267,93.1,169.565,79.078,158.387,72.78Z"
            transform="translate(549.988 134.848)"
          />
          <path
            style={{ fill: lighterColor }}
            d="M115.63,121.76v10.715a2.331,2.331,0,0,0,2.318,2.318h30.908a2.331,2.331,0,0,0,2.318-2.331v-10.7Z"
            transform="translate(539.608 148.947)"
          />
          <path
            style={{ fill: primaryColor }}
            d="M123.537,132.475V121.76H115.63v10.715a2.331,2.331,0,0,0,2.318,2.318h7.907a2.331,2.331,0,0,1-2.318-2.318Z"
            transform="translate(539.608 148.947)"
          />
          <path
            className={styles.g}
            d="M165.846,43.54H109.284a150.834,150.834,0,0,0-7.933,48.938,150.1,150.1,0,0,0,8.564,50.909,2.061,2.061,0,0,0,1.932,1.352h51.36a2.061,2.061,0,0,0,1.932-1.288,150.1,150.1,0,0,0,8.59-50.883A150.473,150.473,0,0,0,165.846,43.54Z"
            transform="translate(535.497 126.431)"
          />
          <path
            className={styles.h}
            d="M116.506,143.382A150.1,150.1,0,0,1,107.942,92.5a150.486,150.486,0,0,1,7.933-48.938h-6.594a150.833,150.833,0,0,0-7.92,48.938,149.79,149.79,0,0,0,8.551,50.883,2.061,2.061,0,0,0,1.932,1.352h6.581A2.061,2.061,0,0,1,116.506,143.382Z"
            transform="translate(535.5 126.437)"
          />
          <path
            style={{ fill: lighterColor }}
            d="M119.07,43.533V51.26a2.331,2.331,0,0,0,2.318,2.318h22.138a2.344,2.344,0,0,0,2.331-2.331V43.52Z"
            transform="translate(540.599 126.425)"
          />
          <path
            style={{ fill: primaryColor }}
            d="M124.337,51.247V43.52H119.07v7.727a2.331,2.331,0,0,0,2.318,2.318h5.319a2.331,2.331,0,0,1-2.37-2.318Z"
            transform="translate(540.599 126.425)"
          />
          <path
            style={{ fill: lighterColor }}
            d="M135.836,11a2.125,2.125,0,0,0-1.288.451c-10.38,8.41-19.125,21.159-25.293,36.871C108.6,50,107.967,51.7,107.4,53.473h56.872c-.592-1.726-1.2-3.426-1.855-5.151-6.156-15.725-14.9-28.474-25.281-36.884a2.086,2.086,0,0,0-1.3-.438Z"
            transform="translate(537.239 117.064)"
          />
          <path
            style={{ fill: primaryColor }}
            d="M139.09,13.1c-.657-.58-1.288-1.146-2-1.7a2.086,2.086,0,0,0-1.288-.451,2.125,2.125,0,0,0-1.288.451c-10.38,8.41-19.125,21.159-25.293,36.871-.657,1.674-1.288,3.374-1.855,5.151h6.581c.592-1.726,1.211-3.426,1.867-5.151C121.6,33.578,129.624,21.472,139.09,13.1Z"
            transform="translate(537.231 117.05)"
          />
          <circle
            className={styles.i}
            cx="15.377"
            cy="15.377"
            r="15.377"
            transform="translate(657.672 196.693)"
          />
          <circle
            className={styles.h}
            cx="9.867"
            cy="9.867"
            r="9.867"
            transform="translate(663.182 202.204)"
          />
          <path
            className={styles.j}
            d="M124.455,78.967a12.235,12.235,0,0,0,9.981,12.016,12.944,12.944,0,0,1-2.241.206,12.235,12.235,0,1,1,0-24.469,12.725,12.725,0,0,1,2.241.206A12.222,12.222,0,0,0,124.455,78.967Z"
            transform="translate(540.855 133.103)"
          />
          <path
            className={styles.c}
            d="M92.573,233.468a.58.58,0,0,1-.528-.348,6.851,6.851,0,0,0-4.649-4.649.58.58,0,0,1,0-1.056,6.851,6.851,0,0,0,4.649-4.649.58.58,0,0,1,1.056,0,6.851,6.851,0,0,0,4.649,4.649.58.58,0,0,1,0,1.056A6.851,6.851,0,0,0,93.1,233.12a.58.58,0,0,1-.528.348Z"
            transform="translate(531.383 109.924)"
          />
        </g>
        <path
          className={styles.c}
          d="M216.757,185.978a.335.335,0,0,1-.322-.206,4.186,4.186,0,0,0-2.846-2.846.348.348,0,0,1,0-.644,4.185,4.185,0,0,0,2.846-2.833.348.348,0,0,1,.322-.219.361.361,0,0,1,.322.219,4.173,4.173,0,0,0,2.833,2.833.348.348,0,0,1,0,.644,4.173,4.173,0,0,0-2.833,2.846A.348.348,0,0,1,216.757,185.978Z"
          transform="translate(567.744 130.49)"
        />
        <path
          className={styles.c}
          d="M65.394,151.248a.335.335,0,0,1-.322-.206,4.173,4.173,0,0,0-2.833-2.846.348.348,0,0,1,0-.644,4.173,4.173,0,0,0,2.833-2.833.348.348,0,0,1,.644,0,4.173,4.173,0,0,0,2.846,2.833.361.361,0,0,1,0,.644,4.16,4.16,0,0,0-2.846,2.846A.335.335,0,0,1,65.394,151.248Z"
          transform="translate(524.177 120.493)"
        />
        <path
          className={styles.c}
          d="M58.893,202.13a.592.592,0,0,1-.541-.361A7.006,7.006,0,0,0,53.6,197a.58.58,0,0,1,0-1.069,7.019,7.019,0,0,0,4.752-4.752.58.58,0,0,1,1.082,0,7.019,7.019,0,0,0,4.752,4.752.58.58,0,0,1,0,1.069,7.006,7.006,0,0,0-4.752,4.765.592.592,0,0,1-.541.361Z"
          transform="translate(521.65 133.823)"
        />
      </g>
    </svg>
  )
}

SpaceShipIcon.propTypes = {
  className: PropTypes.string,
  primaryColor: PropTypes.string.isRequired,
}

SpaceShipIcon.defaultProps = {
  className: '',
}

export default memo(SpaceShipIcon)

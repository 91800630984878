import React, { useMemo, useRef, useCallback, useState, Fragment } from 'react'
import PropTypes from 'prop-types'
import { useDropzone } from 'react-dropzone'
import { List } from 'immutable'
import AddIcon from '@material-ui/icons/Add'

import PictureIcon from '_assets/images/ic_imagem.svg'
import DocumentIcon from '_assets/images/mdi_file_copy.svg'
import { onMouseDown } from '_utils/helpers'
import { useWindowWidth } from '_utils/hooks'
import CameraIcon from '_assets/images/ic_add_photo.svg'
import { MOBILE_BREAKPOINT } from '_utils/constants'

import FileStatus from '../file-status'

import styles from './styles.css'

const getFileExtension = fileName =>
  fileName.slice(fileName.lastIndexOf('.'), fileName.length).toLowerCase()

const allowedDocuments = [
  '.doc',
  '.docx',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  '.xls',
  '.xlsx',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  '.pdf',
  '.csv',
]

const allowedImages = ['.png', '.jpg', '.jpeg']

const getArrayOfFiles = acceptedFiles =>
  acceptedFiles.filter(file => allowedDocuments.includes(getFileExtension(file.name)))

const getArrayOfImages = acceptedFiles =>
  acceptedFiles.filter(file =>
    allowedImages.includes(getFileExtension(file.name))
      ? Object.assign(file, { preview: URL.createObjectURL(file) })
      : false
  )

const InputDocument = ({
  title,
  description,
  isMobile,
  documentCategory,
  documents,
  handleAddDocuments,
  handleRemoveDocument,
  isUploading,
  disabled,
  previousDocuments,
  deletePrevious,
}) => {
  const captureRef = useRef(null)
  const documentRef = useRef(null)
  const imageRef = useRef(null)
  const windowWidth = useWindowWidth()

  const [showButtons, setShowButtons] = useState(false)

  const onOpenFileClick = useCallback(
    ref => event => {
      event.preventDefault()
      ref.current.click()
    },
    []
  )

  const handleRemoveDocumentClick = useCallback(
    id => handleRemoveDocument({ documentCategory, id }),
    [documentCategory, handleRemoveDocument]
  )

  const onPictureInputChange = useCallback(
    ref => () => {
      const selectedFiles = Array.from(ref.current.files)
      handleAddDocuments({
        documentCategory,
        files: getArrayOfImages(selectedFiles),
      })
      /* eslint-disable no-param-reassign */
      ref.current.value = null // this resets the image inside <input /> to prevent bug in changing pictures
      setShowButtons(false)
    },
    [documentCategory, handleAddDocuments]
  )

  const renderCameraButton = useMemo(
    () => (
      <Fragment>
        <input
          type="file"
          accept={`${allowedImages.toString()};capture=camera`}
          multiple
          className={styles.input}
          ref={captureRef}
          onChange={onPictureInputChange(captureRef)}
        />
        <button
          type="button"
          className={styles.button}
          aria-label="Tirar foto"
          onMouseDown={onMouseDown}
          onClick={onOpenFileClick(captureRef)}
          disabled={!isMobile || isUploading || disabled}
        >
          <svg aria-hidden="true" className={styles.icon}>
            <use xlinkHref={CameraIcon} />
          </svg>
          <p className={styles.description}>Câmera</p>
        </button>
      </Fragment>
    ),
    [disabled, isMobile, isUploading, onOpenFileClick, onPictureInputChange]
  )

  const renderPictureButton = useMemo(
    () => (
      <Fragment>
        <input
          type="file"
          accept={allowedImages}
          className={styles.input}
          multiple
          onChange={onPictureInputChange(imageRef)}
          ref={imageRef}
        />
        <button
          type="button"
          className={styles.button}
          aria-label="Adicionar imagem"
          onMouseDown={onMouseDown}
          onClick={onOpenFileClick(imageRef)}
          disabled={isUploading || disabled}
        >
          <svg aria-hidden="true" className={styles.icon}>
            <use xlinkHref={PictureIcon} />
          </svg>
          <p className={styles.description}>Imagem</p>
        </button>
      </Fragment>
    ),
    [disabled, isUploading, onOpenFileClick, onPictureInputChange]
  )

  const onFileInputChange = useCallback(() => {
    const selectedFiles = Array.from(documentRef.current.files)
    handleAddDocuments({
      documentCategory,
      files: getArrayOfFiles(selectedFiles),
    })
    /* eslint-disable no-param-reassign */
    documentRef.current.value = null // this resets the image inside <input /> to prevent bug in changing pictures
    setShowButtons(false)
  }, [documentCategory, handleAddDocuments])

  const renderFileButton = useMemo(
    () => (
      <Fragment>
        <input
          type="file"
          accept={allowedDocuments}
          className={styles.input}
          onChange={onFileInputChange}
          ref={documentRef}
          multiple
        />
        <button
          type="button"
          className={styles.button}
          aria-label="Adicionar arquivo"
          onMouseDown={onMouseDown}
          onClick={onOpenFileClick(documentRef)}
          disabled={isUploading || disabled}
        >
          <svg aria-hidden="true" className={styles.icon}>
            <use xlinkHref={DocumentIcon} />
          </svg>
          <p className={styles.description}>Arquivo</p>
        </button>
      </Fragment>
    ),
    [disabled, isUploading, onFileInputChange, onOpenFileClick]
  )

  const renderContent = useMemo(
    () => (
      <div className={styles['files-container']}>
        {documents.map((document, index) => (
          <FileStatus
            key={`${document.get('file').name} new`}
            name={document.get('file').name}
            type={document.get('file').type}
            onRemoveClick={handleRemoveDocumentClick}
            id={index}
            isUploading={documents.get(0).get('isUploading')}
            percentage={documents.get(0).get('percentage')}
            hasError={documents.get(0).get('hasError')}
          />
        ))}
      </div>
    ),
    [documents, handleRemoveDocumentClick]
  )

  const renderPreviousContent = useMemo(
    () => (
      <div className={styles['files-container']}>
        {previousDocuments.map((document, index) => (
          <FileStatus
            key={`${document.title} ${index + 1} previous`}
            name={`${document.title} ${index + 1}`}
            preview={document.url}
            onRemoveClick={deletePrevious}
            id={document.documentId}
          />
        ))}
      </div>
    ),
    [deletePrevious, previousDocuments]
  )

  const { getRootProps } = useDropzone({
    accept: `${allowedDocuments.toString()},${allowedImages.toString()}`,
    noClick: true,
    noKeyboard: true,
  })

  const onShowFilesClick = useCallback(() => setShowButtons(prevState => !prevState), [])

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>
        {title} <p className={styles.observation}>{description}</p>
      </h2>
      <div className={styles.body} {...getRootProps()}>
        {previousDocuments.length > 0 ? renderPreviousContent : null}
        {documents.size ? renderContent : null}
        <button
          type="button"
          aria-label="Adicionar arquivo"
          onMouseDown={onMouseDown}
          className={styles['no-content']}
          onClick={onShowFilesClick}
        >
          <AddIcon className={styles.icon} />
          <p className={styles['add-files']}>Adicionar Arquivos</p>
        </button>
        {showButtons && (
          <div className={styles.footer}>
            {renderPictureButton}
            {renderFileButton}
            {windowWidth < MOBILE_BREAKPOINT && renderCameraButton}
          </div>
        )}
      </div>
    </div>
  )
}

InputDocument.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  isMobile: PropTypes.bool,
  documentCategory: PropTypes.number.isRequired,
  documents: PropTypes.instanceOf(List),
  handleAddDocuments: PropTypes.func,
  handleRemoveDocument: PropTypes.func,
  isUploading: PropTypes.bool,
  disabled: PropTypes.bool,
  previousDocuments: PropTypes.arrayOf(PropTypes.shape({})),
  deletePrevious: PropTypes.func,
}

InputDocument.defaultProps = {
  description: null,
  isMobile: false,
  handleAddDocuments: () => {},
  handleRemoveDocument: () => {},
  documents: List(),
  isUploading: false,
  disabled: false,
  previousDocuments: [],
  deletePrevious: () => {},
}

export default React.memo(InputDocument)

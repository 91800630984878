import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { getLease, getParticipantLease } from '_modules/leases/actions'
import {
  checkIsGettingLease,
  getParticipantLeaseLoadingSelector,
  hasLeaseErrorSelector,
  getParticipantLeaseErrorSelector,
  getLeaseById,
} from '_modules/leases/selectors'
import { getIncomeHelper } from '_modules/income-helper/selectors'

const useGetLease = (leaseId, token) => {
  const dispatch = useDispatch()
  const isGettingLeaseLoading = useSelector(checkIsGettingLease)
  const isGettingParticipantLeaseLoading = useSelector(getParticipantLeaseLoadingSelector)
  const leaseError = useSelector(hasLeaseErrorSelector)
  const leaseParticipantError = useSelector(getParticipantLeaseErrorSelector)
  const lease = useSelector(state => getLeaseById(state, leaseId))
  const participantLease = useSelector(getIncomeHelper)
  const isLoading = isGettingLeaseLoading || isGettingParticipantLeaseLoading
  const error = leaseError || leaseParticipantError

  useEffect(() => {
    if (token && leaseId && !participantLease.id) {
      dispatch(
        getParticipantLease({
          leaseId,
          token,
        })
      )
    }
  }, [dispatch, leaseId, participantLease.id, token])

  useEffect(() => {
    if (!!leaseId && !lease.id && !token) {
      dispatch(getLease(leaseId))
    }
  }, [dispatch, lease.id, leaseId, token])
  return [isLoading, error]
}

export default useGetLease

import React, { useCallback, Fragment } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Link } from '@reach/router'
import Divider from '@material-ui/core/Divider'

import styles from './styles.css'

const NavigationMenu = ({ className, options, isMenuOpen, isLoggedIn, onOpenAccountModal }) => {
  const onMenuClick = useCallback(
    event => {
      if (!isLoggedIn) {
        event.preventDefault()
        event.stopPropagation()
        onOpenAccountModal(event.currentTarget.href)
      }
    },
    [isLoggedIn, onOpenAccountModal]
  )

  return (
    <div
      className={classnames(className, styles['options-container'], {
        [styles.hide]: !isMenuOpen,
      })}
    >
      {options.map(option => (
        <Fragment>
          {option.hasDividerBefore && <Divider variant="middle" className={styles.divider} />}
          {option.targetBlank ? (
            <a
              className={styles.option}
              href={option.path}
              target="_blank"
              rel="noopener noreferrer"
            >
              {option.name}
            </a>
          ) : (
            <Link
              key={`option-${option.name}`}
              name={option.name}
              to={option.path}
              onClick={option.onClick || onMenuClick}
              className={styles.option}
            >
              <p>{option.name}</p>
            </Link>
          )}
          {option.hasDividerAfter && <Divider variant="middle" className={styles.divider} />}
        </Fragment>
      ))}
    </div>
  )
}
NavigationMenu.propTypes = {
  className: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      icon: PropTypes.string,
    })
  ).isRequired,
  isMenuOpen: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  onOpenAccountModal: PropTypes.func,
}

NavigationMenu.defaultProps = {
  className: '',
  isMenuOpen: false,
  isLoggedIn: false,
  onOpenAccountModal: () => {},
}

export default React.memo(NavigationMenu)

import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/styles'
import MUILinearProgress from '@material-ui/core/LinearProgress'

import { shadeColor, primaryColor } from '_utils/colors'

const ProgressBar = ({ value, className }) => {
  const lighterColor = shadeColor(primaryColor)

  const LinearProgress = useMemo(
    () =>
      withStyles({
        root: {
          height: 8,
          backgroundColor: lighterColor,
        },
        bar: {
          borderRadius: 20,
          backgroundColor: primaryColor,
        },
      })(MUILinearProgress),
    [lighterColor]
  )

  return <LinearProgress value={value} className={className} variant="determinate" />
}

ProgressBar.propTypes = {
  value: PropTypes.number,
  className: PropTypes.string,
}

ProgressBar.defaultProps = {
  value: 0,
  className: '',
}

export default React.memo(ProgressBar)

import { schema } from 'normalizr'
import { List, Map } from 'immutable'

import Model from './model'

class User extends Model({
  activeLease: undefined,
  address: undefined,
  authToken: undefined,
  cep: undefined,
  city: '',
  civilStatus: '',
  clearFilterOpened: false,
  contactPreference: '',
  cpf: '',
  currentAddress: '',
  dateOfBirth: '',
  dislikedOpened: false,
  dislikedProperties: List(),
  email: '',
  extraAddressInfo: undefined,
  fullName: '',
  gender: '',
  id: undefined,
  issuingAgency: '',
  likedOpened: false,
  likedProperties: List(),
  likedPropertiesList: List(),
  nationality: '',
  neighborhood: undefined,
  occupation: '',
  phoneNumber: undefined,
  professionalData: Map(),
  rg: '',
  state: undefined,
  streetNumber: undefined,
}) {}

const userSchema = new schema.Entity(
  'users',
  {},
  {
    processStrategy: entity => new User(entity),
  }
)

export { userSchema, User as default }

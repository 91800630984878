import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ palette }) => ({
  wrapper: {
    paddingTop: '1.2rem',
    backgroundColor: palette.gray.main,
    border: `0.1rem solid ${palette.gray.default}`,
    borderRadius: '0.4rem',
  },
  titleRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 1.6rem',
    marginBottom: '1.2rem',
  },
  button: {
    fontSize: '1.4rem',
  },
  filesWrapper: {
    borderTop: `0.1rem solid ${palette.gray.mercury}`,
    display: 'flex',
    flexWrap: 'wrap',
    padding: '1.6rem 1.6rem 0 1.6rem',
    marginBottom: '0.4rem',
    justifyContent: 'flex-start',
  },
  file: {
    marginRight: '0.8rem',
    marginBottom: '0.8rem',
  },
  fileSelector: {
    display: 'none',
  },
}))

import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Grid, SvgIcon, Typography } from '@material-ui/core'

import HouseImg from '_assets/images/house.svg'
import { useWindowSize } from '_utils/hooks'

import useStyles from './styles'

export const PropertyResultsSectionTheme = {
  NO_RESULT: 'no-result',
  ALL_RESULTS: 'all-results',
}

const PropertyResultsSection = ({ className, theme, error }) => {
  const styles = useStyles()
  const { isMobile } = useWindowSize()

  return (
    <Grid className={classnames(className, styles[theme], styles.propertyResultsContainer)}>
      <Grid className={styles.content}>
        <SvgIcon aria-hidden="true" className={styles.houseImg}>
          <use xlinkHref={HouseImg} />
        </SvgIcon>
        <Typography
          component="p"
          color="textPrimary"
          variant={isMobile ? 'h4' : 'h3'}
          className={styles.title}
        >
          {theme === PropertyResultsSectionTheme.NO_RESULT
            ? 'Ops, nenhum imóvel encontrado.'
            : 'Isso era tudo.'}
          {!error && (
            <Typography
              component="span"
              color="textSecondary"
              variant="subtitle1"
              className={styles.text}
            >
              Veja outros resultados parecidos com o que você pesquisou:
            </Typography>
          )}
        </Typography>
      </Grid>
    </Grid>
  )
}

PropertyResultsSection.propTypes = {
  className: PropTypes.string,
  theme: PropTypes.string,
  error: PropTypes.bool,
}

PropertyResultsSection.defaultProps = {
  className: '',
  theme: PropertyResultsSectionTheme.ALL_RESULTS,
  error: false,
}

export default React.memo(PropertyResultsSection)

import humps from 'humps'

import { defineAction } from '_utils/redux'
import * as listingRegistrationService from '_services/listing-registration'

export const CREATE_LISTING_REGISTRATION = defineAction('CREATE_LISTING_REGISTRATION')
export const UPDATE_LISTING_REGISTRATION = defineAction('UPDATE_LISTING_REGISTRATION')
export const GET_LISTING_PICTURES = defineAction('GET_LISTING_PICTURES')
export const GET_LISTING_REGISTRATION = defineAction('GET_LISTING_REGISTRATION')
export const SEND_EMAIL_LISTING_REGISTRATION = defineAction('SEND_EMAIL_LISTING_REGISTRATION')
export const GET_PRICE_PREDICTION = defineAction('GET_PRICE_PREDICTION')
export const UPDATE_CURRENT_PAGE = 'UPDATE_CURRENT_PAGE'
export const ADD_USER_TOKEN = 'ADD_USER_TOKEN'
export const REMOVE_CURRENT_PAGE = 'REMOVE_CURRENT_PAGE'
export const REMOVE_LISTING_REGISTRATION = 'REMOVE_LISTING_REGISTRATION'
export const UPLOAD_LISTING_PICTURES = defineAction('UPLOAD_LISTING_PICTURES')

const camelizeResponse = response => humps.camelizeKeys(response)

export const createListingRegistration = payload => (dispatch, getState) =>
  dispatch({
    type: CREATE_LISTING_REGISTRATION.ACTION,
    payload: listingRegistrationService
      .createListingRegistration(getState().user.authToken)(payload)
      .then(camelizeResponse),
  })

export const updateListingRegistration = payload => (dispatch, getState) => {
  return dispatch({
    type: UPDATE_LISTING_REGISTRATION.ACTION,
    payload: listingRegistrationService
      .updateListingRegistration(getState().user.authToken)(
        getState().listingRegistrationForm.get('currentId'),
        payload
      )
      .then(camelizeResponse),
  })
}

export const getPricePrediction = () => (dispatch, getState) => {
  return dispatch({
    type: GET_PRICE_PREDICTION.ACTION,
    payload: listingRegistrationService
      .getPricePrediction(getState().user.authToken)(
        getState().listingRegistrationForm.get('currentId')
      )
      .then(camelizeResponse),
  })
}

export const getPricePredictionEmail = (id, token) => dispatch => {
  return dispatch({
    type: GET_PRICE_PREDICTION.ACTION,
    payload: listingRegistrationService
      .getPricePredictionEmail(token)(id)
      .then(camelizeResponse),
  })
}

export const getListingPictures = id => (dispatch, getState) =>
  dispatch({
    type: GET_LISTING_PICTURES.ACTION,
    payload: listingRegistrationService.getListingPictures(getState().user.authToken)(id),
    meta: { id },
  })

export const getListingRegistration = id => (dispatch, getState) =>
  dispatch({
    type: GET_LISTING_REGISTRATION.ACTION,
    payload: listingRegistrationService
      .getListingRegistration(getState().user.authToken)(id)
      .then(camelizeResponse),
  })

export const getListingRegistrationEmail = (id, token) => dispatch => {
  return dispatch({
    type: GET_LISTING_REGISTRATION.ACTION,
    payload: listingRegistrationService
      .getListingRegistration(token)(id)
      .then(camelizeResponse),
  })
}

export const updateListingRegistrationEmail = (id, token, payload) => dispatch => {
  return dispatch({
    type: UPDATE_LISTING_REGISTRATION.ACTION,
    payload: listingRegistrationService
      .updateListingRegistrationEmail(token)(id, payload)
      .then(camelizeResponse),
  })
}
export const sendEmailListingRegistration = () => (dispatch, getState) =>
  dispatch({
    type: SEND_EMAIL_LISTING_REGISTRATION.ACTION,
    payload: listingRegistrationService
      .sendEmailListingRegistration(getState().user.authToken)(
        getState().listingRegistrationForm.get('currentId')
      )
      .then(camelizeResponse),
  })

export const updateCurrentPage = currentPage => dispatch => {
  return dispatch({
    type: UPDATE_CURRENT_PAGE,
    payload: { currentPage },
  })
}
export const addUserToken = userToken => dispatch => {
  return dispatch({
    type: ADD_USER_TOKEN,
    payload: { userToken },
  })
}
export const removeCurrentPage = () => dispatch => {
  return dispatch({
    type: REMOVE_CURRENT_PAGE,
  })
}

export const removeListingRegistration = () => dispatch => {
  return dispatch({
    type: REMOVE_LISTING_REGISTRATION,
  })
}

export const uploadListingPictures = (externalId, payload) => (dispatch, getState) =>
  dispatch({
    type: UPLOAD_LISTING_PICTURES.ACTION,
    payload: listingRegistrationService.uploadListingPictures(getState().user.authToken)(
      externalId,
      payload
    ),
  })

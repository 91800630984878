import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import styles from './styles.css'

class CarouselPaging extends PureComponent {
  static propTypes = {
    numberOfItems: PropTypes.number,
    selectedItem: PropTypes.number,
    className: PropTypes.string,
  }

  static defaultProps = {
    numberOfItems: 0,
    selectedItem: 0,
    className: '',
  }

  getTranslateAmount = () => {
    const { numberOfItems, selectedItem } = this.props
    const totalNumberOfDotsShown = 5
    const middleDot = 3
    const distanceBetweenDots = 14
    if (numberOfItems > totalNumberOfDotsShown) {
      if (selectedItem < middleDot) {
        return 0
      }
      if (selectedItem > numberOfItems - middleDot) {
        return -distanceBetweenDots * (numberOfItems - totalNumberOfDotsShown)
      }
      return -distanceBetweenDots * selectedItem + 2 * distanceBetweenDots
    }
    return 0
  }

  render() {
    const { numberOfItems, selectedItem, className } = this.props
    const trackWidth = 14 * numberOfItems
    return (
      <div className={classnames(styles.container, className)}>
        <div
          className={styles.track}
          style={{
            transform: `translateX(${this.getTranslateAmount()}px)`,
            width: `${trackWidth}px`,
          }}
        >
          {[...Array(numberOfItems)].map((dot, index) => (
            <div
              key={`carousel-dot-${index}`} // eslint-disable-line react/no-array-index-key
              className={classnames(styles.dot, { [styles.selected]: selectedItem === index })}
            />
          ))}
        </div>
      </div>
    )
  }
}
export default CarouselPaging

import { Record, List, fromJS, Map } from 'immutable'
import qs from 'qs'
import humps from 'humps'

import { createReducer } from '_utils/redux'
import { LOGOUT } from '_modules/user/actions'
import { DELETE_OTHER_FILTERS } from '_modules/search/actions'

import { GET_RECOMMENDATIONS_PROPERTIES, SET_FILTER_RECOMMENDATIONS } from './actions'

const INITIAL_STATE = new Record({
  nextPage: undefined,
  results: List(),
  name: undefined,
  filters: undefined,
  hideFilters: true,
})()

const recommendations = createReducer(INITIAL_STATE, {
  [GET_RECOMMENDATIONS_PROPERTIES.FULFILLED]: (state, { payload, meta: { initialSearch } }) => {
    const page = fromJS(payload.entities.paginated).first()
    const results = page.get('results')
    const name = page.get('name')
    const listingType =
      page.get('filters') &&
      page.getIn(['filters', 'listing_type']) &&
      page.getIn(['filters', 'listing_type']).includes(',')
        ? page.getIn(['filters', 'listing_type']).split(',')
        : [page.getIn(['filters', 'listing_type'])]
    const neighborhoods =
      page.get('filters') &&
      page.getIn(['filters', 'neighborhoods']) &&
      page.getIn(['filters', 'neighborhoods']).includes(',')
        ? page.getIn(['filters', 'neighborhoods']).split(',')
        : [page.getIn(['filters', 'neighborhoods'])]
    const filters =
      page.get('filters') &&
      fromJS(humps.camelizeKeys(page.get('filters').toJS()))
        .set('listingType', new List(listingType))
        .set('neighborhoods', new List(neighborhoods))

    const hideFilters = page.get('hide_filters')
    const parsedQuery = qs.parse((page.get('next') || '').split('?').pop())

    return state
      .set('results', initialSearch ? results : state.results.concat(results))
      .set('nextPage', parsedQuery.page)
      .set('name', name)
      .set('filters', filters)
      .set('hideFilters', hideFilters)
  },
  [SET_FILTER_RECOMMENDATIONS]: (state, { payload }) =>
    state.set('filters', state.filters.merge(payload)),
  [DELETE_OTHER_FILTERS]: state => {
    if (state.filters) {
      const newQuery = state.getIn(['filters', 'city'], state.filters.city)
      return state.set('filters', Map()).setIn(['filters', 'city'], newQuery)
    }
    return state
  },
  [LOGOUT]: () => INITIAL_STATE,
})

export default recommendations

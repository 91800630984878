import React from 'react'

import Building from '_components/building-animated'

import styles from './styles.css'

const Loading = () => (
  <div className={styles.loading}>
    <Building className={styles.building} />
  </div>
)

export default React.memo(Loading)

import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import { useSelector } from 'react-redux'

import { getLeaseById } from '_modules/leases/selectors'
import ParticipantsSection from '_components/participants-section'
import TokenContext from '_context/token'

import useStyles from './styles'

const CreditAnalysisWrapper = ({ children, footer }) => {
  const styles = useStyles()
  const { leaseId } = useContext(TokenContext)
  const lease = useSelector(state => getLeaseById(state, leaseId))
  const participants = lease.get('user')
    ? [lease.get('user').setIn(['spouse'], lease.get('spouse')), ...lease.get('participants')]
    : []

  return (
    <div className={styles.wrapper}>
      <Grid container spacing={2} alignItems="center" direction="column">
        {children}
        <ParticipantsSection
          participants={participants}
          lease={lease}
          warranty={lease.get('warranty')}
        />
        {footer}
      </Grid>
    </div>
  )
}

CreditAnalysisWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  footer: PropTypes.node,
}

CreditAnalysisWrapper.defaultProps = {
  footer: null,
}

export default CreditAnalysisWrapper

import React, { useMemo, useCallback, useState, useEffect, Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import classnames from 'classnames'
import { useParams, navigate } from '@reach/router'
import moment from 'moment'
import { Grid, Typography, Card, CardContent, CardActions, Link } from '@material-ui/core'
import { CalendarToday, Room, VpnKey, Info, HowToReg } from '@material-ui/icons'
import Alarm from '@material-ui/icons/Alarm'

import {
  getBookingById,
  isLoadingBookingSelector,
  isCancellingBookingSelector,
  bookingErrorSelector,
} from '_modules/booking/selectors'
import { getUserInfoSelector } from '_modules/user/selectors'
import ExternalIdButton from '_components/material/button/external-id-button'
import Button, { ButtonFormat } from '_components/material/button'
import { getBooking, cancelBooking } from '_modules/booking/actions'
import { usePrevious, useWindowWidth } from '_utils/hooks'
import ModalReserved from '_components/modal-reserved'
import ModalNegotiation from '_components/modal-negotiation'
import withAuth from '_hocs/with-auth'
import DefaultModal from '_components/default-modal'
import { fullDateFormat } from '_utils/strings'
import Loading from '_components/loading'
import { getPropertyAddress } from '_utils/property'
import { getLeaseById } from '_modules/leases/selectors'
import useGetLease from '_hooks/use-get-lease'
import { FEATURE_FLAGS, BOOKING_INFO } from '_utils/constants'

import ListItem from './list-item'
import useStyles from './styles'
import PropertyDetail from './property-detail'

const VisitDetails = () => {
  const { id } = useParams()
  const styles = useStyles()
  const { isMobile, isTablet } = useWindowWidth()
  const dispatch = useDispatch()

  const user = useSelector(getUserInfoSelector)
  const booking = useSelector(state => getBookingById(state, id))
  const isLoadingBooking = useSelector(isLoadingBookingSelector)
  const isCancellingBooking = useSelector(isCancellingBookingSelector)
  const errorLoadingBooking = useSelector(bookingErrorSelector)

  const [state, setState] = useState({
    cancelVisitModal: false,
    updateVisitModal: false,
  })

  const [isWarningModalOpen, setWarningModalOpen] = useState(false)
  const [isCloseDealModalOpen, setCloseDealModalOpen] = useState(false)
  const [isReservedModalOpen, setReservedModalOpen] = useState(false)

  const wasLoadingBooking = usePrevious(isLoadingBooking)
  const wasCancellingBooking = usePrevious(isCancellingBooking)
  const currentLease = useMemo(() => booking && booking.get('lease'), [booking])
  const reserved = useMemo(() => booking && booking.getIn(['listing', 'reserved']), [booking])
  const activeLease = useMemo(() => booking && booking.getIn(['lease', 'id']), [booking])
  const isBookingConfirmed = useMemo(() => booking && booking.isConfirmed, [booking])
  const [isLoading] = useGetLease(activeLease)
  const lease = useSelector(item => getLeaseById(item, activeLease))

  const hasNegotiation = useMemo(() => {
    if (currentLease) {
      return currentLease.get('status').includes('booking')
    }
    return false
  }, [currentLease])

  const isCommercialLease = useMemo(() => {
    if (!isLoading && lease.id) {
      const listing = lease.get('listing').toJS()
      return listing.listingType.includes('com_')
    }
    return false
  }, [isLoading, lease])

  const bookingResponsibleStaffData = useMemo(() => {
    if (booking && booking.responsibleStaffFullName) {
      const data = [booking.responsibleStaffFullName]
      if (booking.responsibleStaffFormattedPhoneNumber) {
        data.push(` - Fone: ${booking.responsibleStaffFormattedPhoneNumber}`)
      }
      return data
    }
    return ['À definir']
  }, [booking])

  useEffect(() => {
    if (id) {
      dispatch(getBooking(id))
    }
  }, [dispatch, id])

  useEffect(() => {
    if (wasCancellingBooking && !isCancellingBooking) {
      navigate('/minhas-visitas/cancelada')
    }
  }, [wasCancellingBooking, isCancellingBooking])

  useEffect(() => {
    if (wasLoadingBooking && !isLoadingBooking && errorLoadingBooking.size !== 0) {
      navigate('/404')
    }
  }, [wasLoadingBooking, isLoadingBooking, errorLoadingBooking])

  const onCancelVisitClick = useCallback(() => {
    setState({
      cancelVisitModal: true,
    })
  }, [])

  const onCloseCancelModal = useCallback(() => {
    setState({
      cancelVisitModal: false,
    })
  }, [])

  const onConfirmCancelVisit = useCallback(() => {
    dispatch(cancelBooking(id))
  }, [dispatch, id])

  const onCloseModal = useCallback(() => {
    setWarningModalOpen(false)
    setReservedModalOpen(false)
    setCloseDealModalOpen(false)
  }, [])
  const confirmUpdateVisit = useCallback(() => {
    if (booking) {
      navigate(`/agendamento/${booking.listing.get('externalId')}/mudar-horario/${id}`)
    }
  }, [booking, id])

  const onUpdateVisitClick = useCallback(() => {
    if (booking && moment().isAfter(booking.get('dateAndTime'))) {
      setWarningModalOpen(true)
      return
    }
    confirmUpdateVisit()
  }, [booking, confirmUpdateVisit])

  const urlMakeProposal = useMemo(
    () => booking && `/proposta/resumo-locacao/${booking.getIn(['lease', 'id'])}`,
    [booking]
  )

  const onConfirmContractClick = useCallback(() => {
    if (reserved) {
      setReservedModalOpen(true)
      return
    }
    if (user.activeLease && user.activeLease !== activeLease) {
      if (!isCloseDealModalOpen) {
        setCloseDealModalOpen(true)
        return
      }
    }
    navigate(booking && `/analise-credito/${booking.getIn(['lease', 'id'])}/inicio`, {
      state: { listing: booking && booking.get('listing').toJS() },
    })
  }, [activeLease, booking, isCloseDealModalOpen, reserved, user.activeLease])

  const openMaps = useCallback(
    address => {
      if (isMobile || isTablet) {
        return `maps://maps.google.com/maps?daddr=${address}&amp;ll=`
      }
      return `https://maps.google.com/maps?daddr=${address}&amp;ll=`
    },
    [isMobile, isTablet]
  )

  const renderTime = useMemo(
    () =>
      booking && (
        <Fragment>
          <Typography component="span" variant="subtitle1">
            {moment(booking.get('dateAndTime')).format(fullDateFormat[0])}
          </Typography>
          <br />
          <Typography component="span" variant="subtitle1">
            {moment(booking.get('dateAndTime')).format(fullDateFormat[1])}
          </Typography>
        </Fragment>
      ),
    [booking]
  )

  const renderAddress = useMemo(
    () =>
      booking && (
        <Link
          href={openMaps(booking.getIn(['listing', 'address']))}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.link}
        >
          {getPropertyAddress(booking.getIn(['listing']).toJS())}
        </Link>
      ),
    [booking, openMaps, styles.link]
  )

  if (!booking || isLoadingBooking) {
    return <Loading />
  }

  const externalId = booking && booking.getIn(['listing', 'externalId'])
  const slug = booking && booking.getIn(['listing', 'slug'])

  return (
    <Grid className={styles.main}>
      <Grid className={styles.content}>
        <ExternalIdButton
          className={styles.externalId}
          externalId={externalId}
          slug={slug}
          listingType={booking.listing.get('listingType')}
        />
        {!isBookingConfirmed && (
          <Grid className={styles.visitRequestNotify}>
            <Alarm className={styles.alarmIcon} />
            <Typography variant="h4" className={styles.visitRequestText}>
              Aguarde a confirmação da visita pela imobiliária
            </Typography>
          </Grid>
        )}
        <Card className={styles.card} component="section">
          <CardContent>
            <Typography component="h1" variant="h4" className={styles.title}>
              Informações do imóvel
            </Typography>
            <Typography variant="subtitle1" className={styles.description}>
              Confira os dados do imóvel e informações da visita
            </Typography>
            <Grid>
              <ListItem icon={CalendarToday} title="Visita agendada para:" content={renderTime} />
              <ListItem icon={Room} title="Como chegar:" content={renderAddress} />
              <ListItem
                icon={VpnKey}
                title="Retirada da chave"
                content={BOOKING_INFO.bookingKeyOption}
              />
              <ListItem
                icon={HowToReg}
                title="Consultor(a) responsável"
                content={bookingResponsibleStaffData}
              />
              <ListItem
                icon={Info}
                title="Outras informações"
                content={
                  <Fragment>
                    {BOOKING_INFO.bookingExtraInfo.map(item => (
                      <Typography component="p" variant="subtitle1" key={item}>
                        {item}
                      </Typography>
                    ))}
                  </Fragment>
                }
              />
            </Grid>
          </CardContent>
          <CardActions className={styles.cardActions}>
            <Typography className={styles.description} component="p" variant="subtitle1">
              Não poderá comparecer?
            </Typography>
            <Grid className={styles.cardActionsButtons}>
              {!booking.cancelled && (
                <Button className={styles.button} onClick={onCancelVisitClick}>
                  CANCELAR
                </Button>
              )}
              <Button className={styles.button} onClick={onUpdateVisitClick}>
                REAGENDAR VISITA
              </Button>
            </Grid>
          </CardActions>
        </Card>
        <Card className={classnames(styles.card, styles.cardProperty)} component="section">
          <Typography component="p" variant="subtitle1">
            Quer ver mais detalhes do imóvel?
          </Typography>
          {/* // The page needs to reload so the server can render the AMP view  */}
          <Button href={`/imovel/${booking.getIn(['listing', 'slug'])}`} className={styles.button}>
            ABRIR IMÓVEL
          </Button>
        </Card>
        {FEATURE_FLAGS.contractEnabled &&
          hasNegotiation &&
          !FEATURE_FLAGS.productVersionOneEnabled &&
          !isCommercialLease && (
            <PropertyDetail
              title="Gostou do imovel?"
              description="Já visitou o imóvel ou gostaria de seguir com a locação? Aproveite e faça uma proposta."
            >
              <Button
                className={styles.buttonContinue}
                format={ButtonFormat.ROUNDED}
                variant="contained"
                to={urlMakeProposal}
              >
                FAZER UMA PROPOSTA
              </Button>
            </PropertyDetail>
          )}
      </Grid>
      {state.cancelVisitModal && (
        <DefaultModal
          title="Cancelar visita"
          ariaLabel="Cancelar visita"
          description="Tem certeza que deseja cancelar a sua visita? Essa ação não poderá ser revertida."
          textButtonLeft="VOLTAR"
          textButtonRight="CONFIRMAR"
          onButtonLeftClick={onCloseCancelModal}
          onButtonRightClick={onConfirmCancelVisit}
          onClose={onCloseCancelModal}
          isLoading={isCancellingBooking}
        />
      )}
      {isWarningModalOpen && (
        <DefaultModal
          title="Alterar a data da visita"
          ariaLabel="Alterar a data da visita"
          description="Tem certeza que deseja alterar a data da sua visita?"
          textButtonLeft="CANCELAR"
          textButtonRight="ALTERAR"
          onButtonLeftClick={onCloseModal}
          onButtonRightClick={confirmUpdateVisit}
        />
      )}
      {isCloseDealModalOpen && (
        <ModalNegotiation
          isOpen={isCloseDealModalOpen}
          onClose={onCloseModal}
          onConfirmNegotiation={onConfirmContractClick}
        />
      )}
      {isReservedModalOpen && (
        <ModalReserved openModal={isReservedModalOpen} closeModal={onCloseModal} />
      )}
    </Grid>
  )
}

export default withAuth(VisitDetails)

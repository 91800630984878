import React from 'react'

import ContractSigned from '_assets/images/contract-signed.png'
import ScheduleVisit from '_assets/images/schedule-visit.png'
import MakeProposal from '_assets/images/proposal-make.png'
import CreditAnalysis from '_assets/images/credit-analysis.png'

import styles from './styles.css'

const STEPS = [
  {
    id: 1,
    title: 'Agende uma visita',
    description: 'Agende através da plataforma sem sair de casa.',
    image: ScheduleVisit,
  },
  {
    id: 2,
    title: 'Faça uma proposta',
    description: 'Negocie rapidamente com o proprietário, acompanhando todo o processo.',
    image: MakeProposal,
  },
  {
    id: 3,
    title: 'Análise de crédito',
    description:
      'Encaminhe todos os dados e documentos através da plataforma sem cartório de forma rápida e segura.',
    image: CreditAnalysis,
  },
  {
    id: 4,
    title: 'Assine o contrato',
    description: 'Após o processo você recebe e assina o contrato online.',
    image: ContractSigned,
  },
]

const SimplifiedJourney = () => {
  return (
    <section className={styles.container}>
      <div className={styles.content}>
        <h2 className={styles.title}>Jornada Simplificada</h2>
        <p className={styles.subtitle}>
          <span>Em poucos cliques você pode encontrar um lugar para morar.</span>
          <br />
          <span>E o melhor: sem sair do sofá.</span>
        </p>
        <div className={styles.steps}>
          {STEPS.map(({ title, description, image, id }) => (
            <div key={id}>
              <img alt={`Figura de ${title}`} src={image} className={styles.image} />
              <h3 className={styles['title-description']}>{title}</h3>
              <p className={styles.description}>{description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default React.memo(SimplifiedJourney)

export const sendEvent = event => {
  if (window.MIXPANEL_ID) {
    window.mixpanel.track(event)
  }
}

export const registerEvent = event => {
  if (window.MIXPANEL_ID) {
    window.mixpanel.register(event)
  }
}

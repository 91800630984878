import axios from 'axios'

import { get } from './requests'

const widthRegex = /\width="(.*?)\"/ //eslint-disable-line
const heightRegex = /\height="(.*?)\"/ //eslint-disable-line

export const getCities = key => get(['cities'], { key })
export const getNeighborhoods = key => params => get(['neighborhoods'], { key, params })

export const fetchedSvg = (svgUrl, primaryColor, svgHeight) =>
  axios
    .get(svgUrl)
    .then(response => response.data)
    .then(text => {
      const parsedText = text
        .split('<svg')[1]
        .split('>')[0]
        .replace(widthRegex, '')
        .replace(heightRegex, '')

      const newSvgString = `<svg height="${svgHeight ||
        37}" ${parsedText} fill="${primaryColor}" ${text.substring(text.indexOf('>'))}`

      return newSvgString
    })

import React from 'react'
import { Grid, Typography, SvgIcon } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { useParams } from '@reach/router'

import Button from '_components/material/button'
import ExternalIdButton from '_components/material/button/external-id-button'
import withAuth from '_hocs/with-auth'
import MobileIcon from '_assets/images/ic-mobile-click-check-blue.svg'
import { getBookingById } from '_modules/booking/selectors'

import useStyles from './styles'

const ProblemReported = () => {
  const styles = useStyles()
  const { bookingId } = useParams()

  const booking = useSelector(state => getBookingById(state, bookingId))

  return (
    <Grid component="section" className={styles.main}>
      <Grid className={styles.content}>
        <ExternalIdButton
          listingType={booking.listing.get('listingType')}
          slug={booking.listing.get('slug')}
          externalId={bookingId}
          className={styles.desktopView}
        />
        <SvgIcon aria-hidden="true" className={styles.icon} viewBox={MobileIcon.viewBox}>
          <use xlinkHref={MobileIcon} />
        </SvgIcon>
        <Typography component="h1" variant="h3" className={styles.title}>
          Problema reportado
        </Typography>
        <Typography component="p" variant="h4" className={styles.description}>
          Seu caso será analisado e em breve entraremos em contato
        </Typography>
        <Button variant="contained" disableElevation className={styles.button} to="/">
          Ver outros imóveis
        </Button>
      </Grid>
    </Grid>
  )
}

export default withAuth(ProblemReported)

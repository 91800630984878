import React from 'react'
import { List } from 'immutable'
import { Select, MenuItem, FormControl, InputLabel, FormHelperText } from '@material-ui/core'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import useStyles from './styles'

const WhiteSelect = ({
  placeholder,
  className,
  options,
  height,
  keyValue,
  keyDisplay,
  label,
  helperText,
  selectClassName,
  ...otherProps
}) => {
  const styles = useStyles({ height })

  return (
    <FormControl variant="outlined" className={classnames(className, styles.control)}>
      <InputLabel>{label}</InputLabel>
      <Select
        className={classnames(styles.select, selectClassName)}
        variant="outlined"
        placeholder={placeholder}
        displayEmpty
        label={label}
        {...otherProps}
      >
        <MenuItem disabled value="">
          {placeholder}
        </MenuItem>
        {options.map(option => (
          <MenuItem key={option[keyValue]} value={option[keyValue]}>
            {option[keyDisplay]}
          </MenuItem>
        ))}
      </Select>
      {helperText && <FormHelperText error>{helperText}</FormHelperText>}
    </FormControl>
  )
}

WhiteSelect.propTypes = {
  placeholder: PropTypes.string,
  className: PropTypes.string,
  keyValue: PropTypes.string,
  keyDisplay: PropTypes.string,
  label: PropTypes.string,
  helperText: PropTypes.string,
  options: PropTypes.oneOfType([
    PropTypes.instanceOf(List),
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      })
    ),
  ]).isRequired,
  height: PropTypes.number,
  selectClassName: PropTypes.string,
}

WhiteSelect.defaultProps = {
  placeholder: '',
  className: '',
  label: '',
  helperText: '',
  height: 4.8,
  keyValue: 'value',
  keyDisplay: 'label',
  selectClassName: '',
}

export default React.memo(WhiteSelect)

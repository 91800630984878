import React, { useMemo, useState, useEffect } from 'react'
import TextField from '@material-ui/core/TextField'
import InputMask from 'react-input-mask'
import PropTypes from 'prop-types'
import MenuItem from '@material-ui/core/MenuItem'
import { useSelector } from 'react-redux'

import Button, { ButtonColor, ButtonVariant, ButtonFormat } from '_components/button'
import { validationCPF } from '_utils/helpers'
import { EMAIL_REGEX } from '_utils/constants'
import { getLocationChanged } from '_modules/modal/selectors'
import { usePrevious } from '_utils/hooks'

import useStyles from '../styles'

import FormField from './form-field'
import styles from './styles.css'

const MAX_CPF_LENGTH = 11
const MIN_CHARACTERS = 2
const OWNER_VIEW = '/anunciar-imovel'

const SignUp = ({ userInfo, onChange, isLoading, isRegisterProperty, error }) => {
  const classes = useStyles()
  const prevLocation = useSelector(getLocationChanged)
  const wasLoading = usePrevious(isLoading)
  const [errorDescription, setError] = useState('')

  const isDisabled = useMemo(() => {
    const cpfNumbers = userInfo.cpf ? userInfo.cpf.replace(/\D/g, '') : ''

    return (
      !(
        userInfo.fullName.length > MIN_CHARACTERS &&
        EMAIL_REGEX.test(userInfo.email) &&
        cpfNumbers.length === MAX_CPF_LENGTH
      ) || !validationCPF(userInfo.cpf)
    )
  }, [userInfo.cpf, userInfo.email, userInfo.fullName.length])

  const inputProps = useMemo(
    () => ({
      className: classes.input,
    }),
    [classes.input]
  )

  const cpfErrorMessage = useMemo(
    () => userInfo.cpf && !validationCPF(userInfo.cpf) && 'Insira um CPF válido',
    [userInfo.cpf]
  )

  useEffect(() => {
    if (!isLoading && wasLoading && error.length) {
      setError(error)
    }
  }, [error, isLoading, wasLoading])

  return (
    <div className={styles.content}>
      <div className={styles.login}>
        <h1 className={styles.title}>Número confirmado!</h1>
        <p className={styles.description}>Informe seus dados para completar o cadastro.</p>
        <div className={styles['user-information']}>
          <TextField
            variant="outlined"
            margin="normal"
            label="Nome"
            name="fullName"
            className={classes.textField}
            onChange={onChange}
            fullWidth
            value={userInfo.fullName}
            InputProps={inputProps}
          />
          <TextField
            variant="outlined"
            margin="none"
            label="E-mail"
            name="email"
            value={userInfo.email}
            onChange={onChange}
            className={classes.textField}
            InputProps={inputProps}
            fullWidth
          />
          <InputMask
            mask="999.999.999-99"
            maskChar=""
            className={classes.textField}
            variant="outlined"
            margin="none"
            label="CPF"
            name="cpf"
            value={userInfo.cpf}
            onChange={onChange}
            InputProps={inputProps}
            fullWidth
            error={!validationCPF(userInfo.cpf)}
            helperText={cpfErrorMessage}
            type="tel"
          >
            {inheritedProps => <TextField {...inheritedProps} />}
          </InputMask>
          {prevLocation === OWNER_VIEW && isRegisterProperty && (
            <FormField
              id="outlined-select-contact-way"
              className={styles['form-field']}
              select
              margin="normal"
              variant="outlined"
              name="contactPreference"
              error={!!userInfo.error}
              helperText={userInfo.error}
              handleChange={onChange}
              value={userInfo.contactPreference}
            >
              <MenuItem value="default" disabled>
                Por onde você prefere ser contatado?
              </MenuItem>
              <MenuItem value="whatsapp">E-mail e WhatsApp</MenuItem>
              <MenuItem value="email">E-mail</MenuItem>
            </FormField>
          )}
          {!!errorDescription && <p className={styles.error}>{errorDescription}</p>}
        </div>
      </div>
      <Button
        color={ButtonColor.PRIMARY}
        variant={ButtonVariant.BOLD}
        format={ButtonFormat.SQUARED}
        className={styles.button}
        disabled={isDisabled || isLoading}
        isLoading={isLoading}
        type="submit"
      >
        ENTRAR
      </Button>
    </div>
  )
}

SignUp.propTypes = {
  userInfo: PropTypes.shape({
    cpf: PropTypes.string,
    fullName: PropTypes.string,
    email: PropTypes.string,
    error: PropTypes.string,
    contactPreference: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func,
  isLoading: PropTypes.bool,
  isRegisterProperty: PropTypes.bool,
  error: PropTypes.string,
}

SignUp.defaultProps = {
  onChange: () => {},
  isLoading: false,
  isRegisterProperty: false,
  error: '',
}

export default React.memo(SignUp)

import React from 'react'
import { Router as ReachRouter } from '@reach/router'

import Landing from '_views/landing'
import App from '_views/app'
import NotFoundPage from '_views/not-found'
import Property from '_views/property'
import ScheduleStepTwo from '_views/schedule/step-two'
import ScheduleStepThree from '_views/schedule/step-three'
import ScheduleStepFour from '_views/schedule/step-four'
import ScheduleStepFive from '_views/schedule/step-five'
import PrivacyPolicy from '_views/privacy-policy'
import TermsOfUse from '_views/terms-of-use'
import SignUp from '_views/sign-up'
import ScheduledVisits from '_views/visit/scheduled-visits'
import VisitDetails from '_views/visit/visit-details'
import CanceledVisit from '_views/visit/canceled-visit'
import UpdateVisit from '_views/visit/update-visit'
import Documents from '_views/lease/attach-documents'
import DocumentAssessment from '_views/lease/document-assessment'
import LeaseOverview from '_views/lease/lease-overview'
import WaitingNegotiationResponse from '_views/negotiations/waiting-negotiation-response'
import NegotiationNeedMoreData from '_views/negotiations/negotiation-need-more-data'
import ApprovedRegistration from '_views/negotiations/approved-registration'
import DocumentationApproved from '_views/lease/documentation-approved'
import ContractPreview from '_views/contract/contract-preview'
import SuccessfullContractAlteration from '_views/contract/successfull-contract-alteration'
import AddRenter from '_views/lease/add-renter'
import ContractChange from '_views/contract/contract-change'
import PDFPreview from '_views/contract/pdf-preview'
import ContractComplete from '_views/contract/complete'
import WaitingNegotiationApproval from '_views/negotiations/waiting-negotiation-approval'
import NegotiationTutorial from '_views/negotiations/tutorial'
import Nps from '_views/negotiations/nps'
import OwnerLanding from '_views/owner/owner-landing'
import Favorites from '_views/favorites'
import ProposalMake from '_views/proposal/proposal-make'
import ProposalPresentation from '_views/proposal/proposal-presentation'
import ProposalSummary from '_views/proposal/proposal-summary'
import ListingRegistrationForm from '_views/owner/listing-registration/listing-registration-initial'
import ListingRegistrationSuccess from '_views/owner/listing-registration/listing-registration-initial/final-step-success'
import ListingRegistrationPending from '_views/owner/listing-registration/listing-registration-initial/final-step-pending'
import ListingRegistrationDetailedStepOne from '_views/owner/listing-registration/listing-registration-detailed/step-one'
import ListingRegistrationDetailedStepTwo from '_views/owner/listing-registration/listing-registration-detailed/step-two'
import ListingRegistrationDetailedStepThree from '_views/owner/listing-registration/listing-registration-detailed/step-three'
import ListingRegistrationDetailedStepFour from '_views/owner/listing-registration/listing-registration-detailed/step-four'
import ListingRegistrationDetailedFinalStep from '_views/owner/listing-registration/listing-registration-detailed/final-step'
import ZapMonthlyIncome from '_views/zap/montly-income'
import Warranty from '_views/warranty'
import CreditAnalysisNewWarranty from '_views/credit-analysis/new-warranty'
import WarrantyChoice from '_views/warranty/choice'
import WarrantyInviteParticipants from '_views/warranty/contracting-party/invite-participants'
import WarrantyFeedback from '_views/warranty/contracting-party/warranty-feedback'
import WarrantyMonthlyIncome from '_views/warranty/montly-income'
import NeedMoreData from '_views/credit-analysis/need-more-data'
import NewMyNegotiations from '_views/new-my-negotiations'
import WarrantyFinished from '_views/warranty/finished'
import HelperInvited from '_views/income-helper/helper-invited'
import Invite from '_views/income-helper/invite'
import QuitNegotiation from '_views/lease/quit-negotiation'
import Login from '_views/login'
import ProposalOverview from '_views/proposal/proposal-overview'
import Recommendations from '_views/recommendations'
import RecommendationsEnd from '_views/recommendations-end'
import MissingData from '_views/negotiations/missing-data'
import FireInsurance from '_views/lease/fire-insurance'
import SearchProperties from '_views/search-properties'
import ScheduleVisit from '_views/schedule/step-one'
import SqueezeVisit from '_views/schedule/squeeze-visit'
import ScheduleFeedback from '_views/schedule/feedback'
import VisitFeedback from '_views/visit/visit-feedback'
import ProblemReported from '_views/visit/problem-reported'
import CreditAnalysisStart from '_views/credit-analysis/start'
import CreditAnalysisConsiderIncome from '_views/credit-analysis/consider-income'
import CreditAnalysisFlow, { CREDIT_ANALYSIS_ROUTES } from '_views/credit-analysis/router'
import CreditAnalysisAddNewParticipant from '_views/credit-analysis/add-new-participant'
import RegisterParticipantDocuments from '_views/credit-analysis/register/documents'
import RegisterParticipantInfo from '_views/credit-analysis/register/personal-info'
import CreditAnalysisNegotiationInvite from '_views/credit-analysis/negotiation/invite'
import CreditAnalysisParticipants from '_views/credit-analysis/participants'
import CreditAnalysisNegotiation from '_views/credit-analysis/negotiation'
import CreditAnalysisAvailable from '_views/credit-analysis/available'
import CreditAnalysisPending from '_views/credit-analysis/pending'
import CreditAnalysisUpdated from '_views/credit-analysis/updated'
import CreditAnalysisClosed from '_views/credit-analysis/closed'
import ModalPictures from '_components/modal-pictures-full-screen'
import StartNegotiation from '_views/negotiations/start-negotiation'
import SubscribeGuarantorStepThree from '_views/warranty/contracting-party/professional-info'
import CreditAnalysisApproved from '_views/negotiations/credit-analysis-approved'
import ProposalFeedback from '_views/proposal/proposal-feedback'
import ProposalApproved from '_views/proposal/proposal-approved'
import ProposalSent from '_views/proposal/proposal-sent'
import ProposalDisapproved from '_views/proposal/proposal-disapproved'
import MyNegotiations from '_views/negotiations/my-negotiations'

const CHOICES = [...new Array(20).keys()].map((value, index) => {
  const newRoute = `:type/`.repeat(index + 1)
  return `/buscar/${newRoute}`
})

const Router = () => (
  <ReachRouter>
    <App path="/">
      <Login path="login" />
      <Landing exact path="/" />
      {CHOICES.map(value => (
        <SearchProperties key={value} path={value} />
      ))}
      <SearchProperties path="/buscar" />
      <Property path="imovel/:id" />
      <Property path="imovel/:title/:id" />
      <ModalPictures path="imovel/:id/mais-fotos" />
      <ScheduleVisit path="agendamento/:externalId" />
      <SqueezeVisit path="agendamento/:externalId/solicitar-encaixe" />
      <SqueezeVisit path="agendamento/:externalId/mais-informacoes" />
      <ScheduleFeedback path="agendamento/:externalId/solicitacao-enviada" />
      <ScheduleStepTwo path="agendamento/passo-2" />
      <ScheduleStepThree path="agendamento/passo-3" />
      <ScheduleStepFour path="agendamento/passo-4" />
      <ScheduleStepFive path="agendamento/passo-5" />
      <ScheduleVisit path="agendamento/:externalId/mudar-horario/:bookingId" />
      <SignUp path="cadastro" />
      <PrivacyPolicy path="politica-de-privacidade" />
      <TermsOfUse path="termos-de-servico" />
      <Favorites path="favoritos" />
      <ScheduledVisits path="minhas-visitas" />
      <VisitDetails path="minhas-visitas/:id" />
      <UpdateVisit path="minhas-visitas/:id/atualizar-visita" />
      <CanceledVisit path="minhas-visitas/cancelada" />
      <MyNegotiations path="minhas-negociacoes" />
      <WaitingNegotiationResponse path="minhas-negociacoes/:id/aguardando-resposta" />
      <ApprovedRegistration path="minhas-negociacoes/:id/cadastro-aprovado" />
      <NegotiationNeedMoreData path="minhas-negociacoes/:id/mais-dados" />
      <ContractPreview path="minhas-negociacoes/:leaseId/contrato/:contractId/antecipacao" />
      <PDFPreview path="minhas-negociacoes/:leaseId/contrato/:contractId/revisao" />
      <ContractComplete path="minhas-negociacoes/:leaseId/contrato/:contractId/concluido" />
      <WaitingNegotiationApproval path="minhas-negociacoes/:id/aguardando-aprovacao" />
      <NegotiationTutorial path="minhas-negociacoes/:id/tutorial" />
      <Nps path="minhas-negociacoes/:leaseId/contrato/:contractId/avaliacao" />
      <SuccessfullContractAlteration path="contrato/alteracao" />
      <LeaseOverview path="minhas-negociacoes/:id" />
      <ContractChange path="minhas-negociacoes/:leaseId/contrato/:contractId/alteracao-contrato" />
      <ZapMonthlyIncome path="minhas-negociacoes/renda-mensal" />
      <CreditAnalysisApproved path="/minhas-negociacoes/:id/analise-aprovada" />
      <Documents path="minhas-negociacoes/:id/documentacao" />
      <DocumentAssessment path="minhas-negociacoes/:leaseId/revisao" />
      <DocumentationApproved path="minhas-negociacoes/:id/aprovado" />
      <AddRenter path="minhas-negociacoes/:id/adicionar-locatario" />
      <OwnerLanding path="/anunciar-imovel" />
      <ProposalSent path="/proposta/:id/enviada" />
      <ProposalApproved path="minhas-negociacoes/:id/proposta-aprovada" />
      <ProposalDisapproved path="/proposta/reprovada/:id" />
      <StartNegotiation path="/proposta/resumo-locacao/:id" />
      <ProposalPresentation path="/proposta/apresentacao/:leaseId" />
      <ProposalPresentation path="/minhas-negociacoes/:leaseId/apresentacao" />
      <ProposalMake path="/proposta/fazer-proposta/:id" />
      <ProposalSummary path="/proposta/resumo/:id" />
      <ProposalFeedback path="/proposta/feedback/:id" />
      <ListingRegistrationForm path="/anunciar-imovel/cadastro-inicial" />
      <ListingRegistrationSuccess path="/anunciar-imovel/finalizado" />
      <ListingRegistrationPending path="/anunciar-imovel/andamento" />
      <ListingRegistrationDetailedStepOne path="/anunciar-imovel/passo-1" />
      <ListingRegistrationDetailedStepTwo path="/anunciar-imovel/passo-2" />
      <ListingRegistrationDetailedStepThree path="/anunciar-imovel/passo-3" />
      <ListingRegistrationDetailedStepFour path="/anunciar-imovel/passo-4" />
      <ListingRegistrationDetailedFinalStep path="/anunciar-imovel/cadastro-finalizado" />
      <Warranty path="/minhas-negociacoes/:id/garantia" />
      <WarrantyChoice path="/minhas-negociacoes/:id/garantia/escolha" />
      <WarrantyMonthlyIncome path="/garantia/:id/renda-mensal" />
      <FireInsurance path="/minhas-negociacoes/:id/seguro-incendio" />
      <NeedMoreData path="/minhas-negociacoes/:id/mais-informacoes" />
      <QuitNegotiation path="/minhas-negociacoes/:id/desistiu" />
      <Invite path="/minhas-negociacoes/:id/convidar" />
      <HelperInvited path="/minhas-negociacoes/convidado" />
      <NewMyNegotiations path="/minhas-negociacoes/desistir" />
      <ProposalOverview path="minhas-negociacoes/:id/propostas" />
      <WarrantyFinished path="/garantia/concluido" />
      <SubscribeGuarantorStepThree path="/garantia/:id/registrar-contratante-passo-3" />
      <WarrantyInviteParticipants path="/garantia/:id/convidar-participantes" />
      <MissingData path="/garantia/:id/documentacao-extra" />
      <WarrantyFeedback path="/garantia/cadastro-finalizado" />
      <Recommendations path="/recomendacoes/:hash" />
      <RecommendationsEnd path="/interesse-encerrado" />
      <MissingData path="minhas-negociacoes/:id/dados-requisitados" />
      <VisitFeedback path="/feedback-visita/:bookingId" />
      <ProblemReported path="/problema-reportado/:bookingId" />
      <NotFoundPage default />
      {/* Credit analysis related */}
      <CreditAnalysisFlow path="/analise-credito/:leaseId">
        <CreditAnalysisStart path={CREDIT_ANALYSIS_ROUTES.START} />
        <CreditAnalysisParticipants path={CREDIT_ANALYSIS_ROUTES.INCOME_HELPERS} />
        <CreditAnalysisParticipants path={CREDIT_ANALYSIS_ROUTES.GUARANTORS} />
        <CreditAnalysisParticipants path={CREDIT_ANALYSIS_ROUTES.TENANTS} />
        <CreditAnalysisNegotiationInvite path={CREDIT_ANALYSIS_ROUTES.INVITE_INCOME_HELPER} />
        <CreditAnalysisNegotiationInvite path={CREDIT_ANALYSIS_ROUTES.INVITE_GUARANTOR} />
        <CreditAnalysisNegotiationInvite path={CREDIT_ANALYSIS_ROUTES.INVITE_TENANT} />
        <CreditAnalysisUpdated path={CREDIT_ANALYSIS_ROUTES.UPDATED} />
        <CreditAnalysisNegotiation path={CREDIT_ANALYSIS_ROUTES.ADD_TENANT} />
        <CreditAnalysisNegotiation path={CREDIT_ANALYSIS_ROUTES.ADD_GUARANTOR} />
        <CreditAnalysisAddNewParticipant path={CREDIT_ANALYSIS_ROUTES.ADD_GUARANTOR_DATA} />
        <CreditAnalysisNegotiation path={CREDIT_ANALYSIS_ROUTES.ADD_INCOME_HELPER} />
        <CreditAnalysisAvailable path={CREDIT_ANALYSIS_ROUTES.AVAILABLE} />
        <CreditAnalysisPending path={CREDIT_ANALYSIS_ROUTES.STATUS} />
        <CreditAnalysisPending path="/" />
        <RegisterParticipantInfo path={CREDIT_ANALYSIS_ROUTES.REGISTER_INFORMATION} />
        <RegisterParticipantInfo path={CREDIT_ANALYSIS_ROUTES.REGISTER_SPOUSE} />
        <CreditAnalysisConsiderIncome path={CREDIT_ANALYSIS_ROUTES.MONTHLY_INCOME} />
        <RegisterParticipantDocuments path={CREDIT_ANALYSIS_ROUTES.REGISTER_DOCUMENTS} />
        <CreditAnalysisNewWarranty path={CREDIT_ANALYSIS_ROUTES.NEW_WARRANTY} />
        <CreditAnalysisClosed path={CREDIT_ANALYSIS_ROUTES.CLOSED_LEASE} />
        <NeedMoreData path={CREDIT_ANALYSIS_ROUTES.NEED_MORE_DATA} />
        <DocumentAssessment path={CREDIT_ANALYSIS_ROUTES.READY} />
      </CreditAnalysisFlow>
      {/* Keep old urls to not break the flow */}
      <CreditAnalysisNewWarranty path="/minhas-negociacoes/:leaseId/garantia" />
      <RegisterParticipantDocuments path="/garantia/:leaseId/registrar-contratante-passo-1" />
      <RegisterParticipantInfo path="/garantia/:leaseId/registrar-contratante-passo-2" />
      <NeedMoreData path="/minhas-negociacoes/:leaseId/mais-informacoes" />
      <NeedMoreData path="/minhas-negociacoes/:leaseId/mais-dados" />
      <CreditAnalysisConsiderIncome path="/minhas-negociacoes/:leaseId/renda" />
    </App>
  </ReachRouter>
)

export default Router

import { schema } from 'normalizr'
import { Map } from 'immutable'

import Model from './model'

class IncomeHelper extends Model({
  id: '',
  category: undefined,
  fullName: '',
  email: '',
  lease: Map(),
  monthlyIncome: undefined,
  cpf: '',
  phoneNumber: undefined,
  dateOfBirth: '',
  rg: '',
  issuingAgency: '',
  gender: '',
  civilStatus: '',
  nationality: '',
  occupation: '',
  professionalData: undefined,
  currentAddress: '',
  spouse: Map(),
  documents: Map(),
  incomeApproved: undefined,
  isTenant: undefined,
  hasSpouse: undefined,
  enabled: undefined,
  hasRequestedDocuments: undefined,
  hasRequestedPersonalData: undefined,
  hasRequestedProfessionalData: undefined,
  hasNewDocuments: undefined,
  hasNewPersonalData: undefined,
  hasNewProfessionalData: undefined,
  dataRequest: undefined,
  considerIncome: undefined,
  requestedDocuments: undefined,
}) {}

const incomeHelperSchema = new schema.Entity(
  'income-helpers',
  {},
  {
    processStrategy: entity => new IncomeHelper(entity),
  }
)

export { incomeHelperSchema, IncomeHelper as default }

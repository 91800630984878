/* eslint-disable camelcase */
import React, {
  useMemo,
  useEffect,
  useCallback,
  useReducer,
  useState,
  Fragment,
  useContext,
} from 'react'
import { Paper, Grid, Typography } from '@material-ui/core'
import { CalendarToday, Portrait } from '@material-ui/icons'
import classnames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, Link as RouterLink, useLocation, Redirect, navigate } from '@reach/router'
import { PulseLoader } from 'react-spinners'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import URI from 'urijs'

import { getAgency } from '_modules/agency/actions'
import { getAgencyLoading, getCurrentAgency } from '_modules/agency/selectors'
import { getProperty } from '_modules/property/actions'
import { getPropertyById, getIsLoadingProperty } from '_modules/property/selectors'
import { moment } from '_bootstrap'
import { useWindowSize, usePrevious } from '_utils/hooks'
import useOnSuccessCall from '_hooks/use-on-success'
import Arrow from '_components/slick-carousel/next-arrow'
import { getAvailability } from '_services/property'
import { TABLET } from '_config/media-queries'
import { updateBooking, UPDATE_BOOKING } from '_modules/booking/actions'
import { updateBookingLoadingSelector, getNewBookingsSelector } from '_modules/booking/selectors'
import { isUserLoggedIn } from '_modules/user/selectors'
import { RedirectContext } from '_context/redirect'
import Button from '_components/material/button'
import { primaryColor } from '_utils/colors'
import { FEATURE_FLAGS } from '_utils/constants'

import useStyles from './styles'
import DateButton from './date-button'
import {
  initialState,
  reducer,
  SET_VALUE,
  LOAD_MORE_DATES,
  sortDate,
  filterWeekdays,
  filterAndSortWeekdays,
} from './reducer'

const ScheduleVisit = () => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const { externalId, bookingId } = useParams()
  const { isMobile } = useWindowSize()
  const location = useLocation()
  const [localState, dispatchLocal] = useReducer(reducer, initialState)
  const [isLoadingAvailability, setAvailability] = useState(true)
  const { setRedirectUri } = useContext(RedirectContext)

  const agency = useSelector(getCurrentAgency)
  const property = useSelector(state => getPropertyById(state, externalId))
  const isPropertyLoading = useSelector(getIsLoadingProperty)
  const isAgencyLoading = useSelector(getAgencyLoading)
  const isUpdatingBookingLoading = useSelector(updateBookingLoadingSelector)
  const newBooking = useSelector(getNewBookingsSelector)
  const isLoggedIn = useSelector(isUserLoggedIn)
  // Current month of the slider day's
  const monthName = useMemo(
    () =>
      moment(localState.centeredDate || new Date())
        .format('MMMM [de] YYYY')
        .capitalize(),
    [localState.centeredDate]
  )

  // Initial date of the day's Slider
  const initialDate = useMemo(() => {
    if (!agency.minHours) return 0

    let momentDate = moment().add(agency.minHours, 'hours')

    let latestSlot = filterAndSortWeekdays(agency.slots, momentDate)

    let allTimeArray = latestSlot ? latestSlot.get('endTime').split(':') : []

    // TO-DO: Refactor this logic
    while (
      !latestSlot ||
      momentDate
        .clone()
        .set({ hour: allTimeArray[0], minute: allTimeArray[1], second: allTimeArray[2] })
        .diff(moment(), 'hours') < agency.minHours
    ) {
      momentDate = momentDate.add(1, 'days')
      latestSlot = filterAndSortWeekdays(agency.slots, momentDate)

      allTimeArray = latestSlot ? latestSlot.get('endTime').split(':') : []
    }

    return momentDate
      .set({ hour: 0, minute: 0, second: 5 })
      .diff(moment().set({ hour: 0, minute: 0, second: 0 }), 'days')
  }, [agency.minHours, agency.slots])

  // Get availability from the property to schedule a visit
  // set the firstAvailableSlot on the hour Slider
  const searchAvailability = useCallback(
    initial => {
      const { dataArray } = localState
      setAvailability(true)
      getAvailability(externalId, moment(dataArray[initial]).format('YYYY-MM-DD'))
        .then(payload => {
          const availabilityArray = payload.map(slot => slot.available)
          const timeArray = [
            {
              available: false,
              time: moment(payload[0].date_and_time)
                .subtract(30, 'minutes')
                .format('HH[H]mm'),
            },
            ...payload.map(slot => ({
              available: slot.available,
              time: moment(slot.date_and_time).format('HH[H]mm'),
            })),
            {
              available: false,
              time: moment(payload[payload.length - 1].date_and_time)
                .add(30, 'minutes')
                .format('HH[H]mm'),
            },
          ]
          const firstAvailableSlot =
            availabilityArray.indexOf(true) > 0 ? availabilityArray.indexOf(true) : 0
          dispatchLocal({
            type: SET_VALUE,
            payload: {
              firstAvailableSlot,
              timeArray,
              ready: true,
            },
          })
        })
        .catch(() => {
          dispatchLocal({
            type: SET_VALUE,
            payload: {
              firstAvailableSlot: -1,
              ready: true,
            },
          })
        })
      setAvailability(false)
    },
    [localState, externalId]
  )

  const isDateDisabled = useCallback(
    date => {
      if (!agency.minHours) return true

      const momentDate = moment(date)
      const dateSlot = filterWeekdays(agency.slots, momentDate)

      if (!dateSlot.size) return true

      const latestSlot = sortDate(dateSlot, momentDate)

      const allTimeArray = latestSlot.get('endTime').split(':')

      return (
        momentDate
          .set({ hour: allTimeArray[0], minute: allTimeArray[1], second: allTimeArray[2] })
          .diff(moment(), 'hours') < agency.minHours
      )
    },
    [agency]
  )

  const isTimeDisabled = useCallback(
    index => {
      const { selectedDateID, timeArray } = localState

      if (!selectedDateID && !timeArray[index].available) return true

      return !timeArray[index].available
    },
    [localState]
  )

  const onDateChange = useCallback(
    index => {
      const position = index + 2

      dispatchLocal({
        type: SET_VALUE,
        payload: {
          centeredDate: localState.dataArray[position],
        },
      })

      if (localState.dataArray.length - position < 15) {
        dispatchLocal({
          type: LOAD_MORE_DATES,
        })
      }
    },
    [localState.dataArray]
  )

  const onDateClick = useCallback(
    event => {
      dispatchLocal({
        type: SET_VALUE,
        payload: {
          selectedDateID: Number(event.currentTarget.id),
          selectedOnce: true,
          selectedTimeID: undefined,
        },
      })
      searchAvailability(Number(event.currentTarget.id))
    },
    [searchAvailability]
  )

  const onTimeClick = useCallback(event => {
    dispatchLocal({
      type: SET_VALUE,
      payload: {
        selectedTimeID: Number(event.currentTarget.id),
        selectedOnce: true,
      },
    })
  }, [])

  const setSliderSettings = useCallback(
    moreOptions => {
      const { isTime, ...options } = moreOptions
      return {
        dots: false,
        infinite: false,
        speed: 100,
        draggable: false,
        slidesToShow: isTime ? 4 : 5,
        prevArrow: <Arrow smallSize={isTime} className={styles.arrow} inverse />,
        nextArrow: <Arrow smallSize={isTime} className={styles.arrow} />,
        responsive: [
          {
            breakpoint: TABLET,
            settings: {
              draggable: true,
              arrows: false,
              slidesToShow: isTime ? 4 : 5,
              slidesToScroll: isTime ? 4 : 5,
            },
          },
        ],
        ...options,
      }
    },
    [styles]
  )

  const onRescheduleVisitClick = useCallback(() => {
    const { timeArray, dataArray, selectedDateID, selectedTimeID } = localState
    const time =
      selectedTimeID !== undefined && timeArray[selectedTimeID]
        ? timeArray[selectedTimeID].time.split('H')
        : [0, 0]

    const date =
      selectedDateID !== undefined && dataArray[selectedDateID]
        ? dataArray[selectedDateID].split('T')[0]
        : undefined
    /* 
      Actually the way time is being formated
      with timezone is a mystery so we subtract 3
      to fix the timezone issue but need a better solution 
    */
    const payload = {
      listing: externalId,
      dateAndTime: moment(date)
        .set({
          hour: time[0],
          minute: time[1],
          second: 0,
          millisecond: 0,
        })
        .add(moment().isDST() ? +1 : 0, 'hour')
        .toISOString(),
    }
    dispatch(updateBooking(payload))
  }, [dispatch, externalId, localState])

  const handleUpdateBookingSuccess = useCallback(() => {
    navigate(`/minhas-visitas/${newBooking}`)
  }, [newBooking])
  useOnSuccessCall(UPDATE_BOOKING.ACTION, handleUpdateBookingSuccess)

  const dateSettings = useMemo(
    () =>
      setSliderSettings({
        className: styles.datePicker,
        initialSlide: localState.selectedDateID - 2,
        afterChange: onDateChange,
      }),
    [localState.selectedDateID, onDateChange, setSliderSettings, styles.datePicker]
  )
  const timeSettings = useMemo(
    () =>
      setSliderSettings({
        className: styles.timePicker,
        initialSlide: localState.firstAvailableSlot - 1 > 0 ? localState.firstAvailableSlot - 1 : 0,
        isTime: true,
      }),
    [localState.firstAvailableSlot, setSliderSettings, styles.timePicker]
  )

  // Set values on the location's state
  const locationNextState = useMemo(() => {
    // Get the selected time and date
    const { timeArray, dataArray, selectedDateID, selectedTimeID } = localState
    const time =
      selectedTimeID !== undefined && timeArray[selectedTimeID]
        ? timeArray[selectedTimeID].time.split('H')
        : [0, 0]

    const date =
      selectedDateID !== undefined && dataArray[selectedDateID]
        ? dataArray[selectedDateID].split('T')[0]
        : undefined

    // Get recommendation info from the URI
    const { search, state } = location
    const { source: hasSource, source_id, anonymous_id, staff_hash, recommendation_hash } = URI(
      search
    ).search(true)
    const source = hasSource ? hasSource.toSlug() : ''
    const sourceId = source_id ? source_id.toSlug() : ''
    const anonymousId = anonymous_id ? anonymous_id.toSlug() : ''
    const hash = staff_hash ? staff_hash.toSlug() : ''
    const recommendationFromProperty = recommendation_hash || ''

    const staffHash = (state && state.staffHash && state.staffHash.toSlug()) || hash.toSlug() || ''
    const recommendationHash =
      (state && state.recommendationHash) || recommendationFromProperty || ''

    return {
      saveQuery: state ? state.saveQuery : '',
      property: property && property.toJS(),
      time,
      date,
      source,
      sourceId,
      anonymousId,
      staffHash,
      recommendationHash,
    }
  }, [localState, location, property])

  const isButtonDisabled = useMemo(
    () =>
      !(
        localState.selectedDateID !== 'undefined' &&
        typeof localState.selectedTimeID !== 'undefined'
      ),
    [localState.selectedDateID, localState.selectedTimeID]
  )

  const isLoading = isPropertyLoading || isAgencyLoading
  const wasLoading = usePrevious(isLoading)
  useEffect(() => {
    if (!isLoading && wasLoading) {
      dispatchLocal({
        type: SET_VALUE,
        payload: {
          selectedDateID: initialDate,
        },
      })
      if (property) {
        searchAvailability(initialDate)
      }
    }
  }, [dispatch, initialDate, isLoading, property, searchAvailability, wasLoading])

  useEffect(() => {
    const listing = externalId
    dispatch(getAgency(listing))
    if (!property) {
      dispatch(getProperty(externalId))
    }
  }, [dispatch, externalId, property])

  const hasVerificationToken =
    location.search.includes('?verification_token=') ||
    location.search.includes('&verification_token=')
  if (!isLoggedIn && bookingId && !hasVerificationToken) {
    setRedirectUri(`/agendamento/${externalId}/mudar-horario/${bookingId}`)
    return <Redirect to="/login" noThrow />
  }

  if (!externalId) {
    return <Redirect to="/" noThrow />
  }

  const featureFlagEnabled = FEATURE_FLAGS.bookingNeededActionEnabled && localState.ready

  return (
    <Grid className={styles.container}>
      <Paper component="section" className={styles.paper} variant={!isMobile && 'outlined'}>
        {!localState.ready ? (
          <Grid className={styles.loading}>
            <PulseLoader sizeUnit="px" size={8} margin="4px" loading color={primaryColor} />
          </Grid>
        ) : (
          <Fragment>
            <Typography className={styles.title} component="h1" variant="h4">
              Escolha a data e a hora <br /> da sua visita
            </Typography>
            <Typography className={styles.date} component="h2" variant="h5">
              {monthName}
            </Typography>
            <Slider {...dateSettings}>
              {localState.dataArray.map((date, index) => (
                <Grid key={date} className={styles.dateButton}>
                  <DateButton
                    date={date}
                    isDisabled={isDateDisabled(date)}
                    onClick={onDateClick}
                    id={index}
                    isSelected={localState.selectedDateID === index && localState.selectedOnce}
                  />
                </Grid>
              ))}
            </Slider>
            <Typography className={styles.date} component="h2" variant="h5">
              Hora
            </Typography>
            <Grid className={styles.hourCarrousel}>
              {isLoadingAvailability ? (
                <Grid className={styles.loading}>
                  <PulseLoader color={primaryColor} sizeUnit="px" size={8} margin="4px" loading />
                </Grid>
              ) : (
                <Slider {...timeSettings}>
                  {localState.timeArray.map((hour, index) => (
                    <Grid className={styles.timeItem} key={hour.time}>
                      <Button
                        onClick={onTimeClick}
                        id={index}
                        disabled={isTimeDisabled(index)}
                        color={localState.selectedTimeID === index ? 'primary' : 'default'}
                        variant="contained"
                        className={classnames(styles.timeButton, {
                          [styles.defaultButton]: localState.selectedTimeID !== index,
                        })}
                      >
                        {hour.time}
                      </Button>
                    </Grid>
                  ))}
                </Slider>
              )}
            </Grid>
            {bookingId ? (
              <Button
                className={styles.noShadow}
                color="primary"
                variant="contained"
                disabled={isButtonDisabled}
                onClick={onRescheduleVisitClick}
                isLoading={isUpdatingBookingLoading}
              >
                Reagendar
              </Button>
            ) : (
              <Button
                to="/agendamento/passo-2"
                state={locationNextState}
                className={styles.noShadow}
                color="primary"
                variant="contained"
                disabled={isButtonDisabled}
              >
                Continuar
              </Button>
            )}
            {featureFlagEnabled && !bookingId && (
              <Grid className={styles.reschedule}>
                <CalendarToday className={styles.icon} />
                <Typography variant="subtitle2">
                  <strong>Não pode nesses horários?</strong> <br />
                  <Typography component="span" variant="subtitle2">
                    {isMobile ? 'Entraremos em contato.' : 'Um consultor entrará em contato.'}
                  </Typography>
                </Typography>
                <Button
                  to="solicitar-encaixe"
                  state={locationNextState}
                  className={styles.button}
                  variant="outlined"
                >
                  Solicitar {isMobile ? 'encaixe' : 'outro'}
                </Button>
              </Grid>
            )}
          </Fragment>
        )}
      </Paper>
      {featureFlagEnabled && !bookingId && !isLoadingAvailability && (
        <Paper className={styles.doubt} component="section" variant="outlined">
          <Portrait className={styles.icon} />
          <Typography className={styles.doubtText} component="p" variant="subtitle1">
            Está com dúvidas?
          </Typography>
          <Button
            component={RouterLink}
            to="mais-informacoes"
            state={locationNextState}
            size="small"
            className={styles.button}
            variant="outlined"
          >
            Quero saber mais sobre o imóvel
          </Button>
        </Paper>
      )}
    </Grid>
  )
}

export default React.memo(ScheduleVisit)

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import SearchIcon from '@material-ui/icons/Search'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import HotelIcon from '@material-ui/icons/Hotel'
import Home from '@material-ui/icons/Home'
import { InputAdornment } from '@material-ui/core'
import { MeetingRoom } from '@material-ui/icons'
import CurrencyTextField from '@unicef/material-ui-currency-textfield'

import Button from '_components/material/button'
import { setFilter } from '_modules/search/actions'
import { getCities, getNeighborhoods, GET_NEIGHBORHOODS } from '_modules/utils/actions'
import { usePrevious } from '_utils/hooks'
import {
  FEATURE_FLAGS,
  SEARCH_BEDROOMS_OPTIONS,
  SEARCH_PROPERTY_TYPES_OPTIONS,
  DEFAULT_SEARCH,
} from '_utils/constants'
import SelectMultipleCheckbox from '_components/select-multiple-checkbox'
import { parseFilterToUrl } from '_utils/url-friendly'

import useMaterial from './materialStyle'
import styles from './styles.css'

const ALL_NEIGHBORHOOD = 'todos'

const SearchSection = ({ className }) => {
  const materialStyle = useMaterial()

  const dispatch = useDispatch()
  const isGettingNeighborhoods = useSelector(state => !!state.loading.get(GET_NEIGHBORHOODS.ACTION))

  const [price, setPrice] = useState('')
  const [selectedCity, setSelectedCity] = useState({ value: 'Cidade', label: 'Cidade' })
  const [selectedNeighborhood, setSelectedNeighborhood] = useState([])
  const [selectedBedrooms, setBedrooms] = useState({ value: 'Quartos', label: 'Quartos' })
  const [selectedPropertyTypes, setSelectedPropertyTypes] = useState({
    value: 'Tipo de Imóvel',
    label: 'Tipo de Imóvel',
  })

  const selectPrice = useCallback(() => {
    if (price === '') {
      return DEFAULT_SEARCH.defaultRentPrice || 0
    }
    return price
  }, [price])

  const disableNeighborhoodField = useMemo(() => {
    if (!isGettingNeighborhoods && selectedCity) {
      return false
    }
    return true
  }, [selectedCity, isGettingNeighborhoods])

  const cityOptions = useSelector(state =>
    state.utils.cities.map(item => ({
      label: item.name,
      value: item.slug,
    }))
  )

  const neighborhoodOptions = useSelector(state =>
    state.utils.neighborhoods.map(item => ({
      label: item.name,
      value: item.slug,
    }))
  )

  useEffect(() => {
    if (!cityOptions.size) {
      dispatch(getCities())
    }
  }, [cityOptions, cityOptions.size, dispatch])

  const prevSelectedCity = usePrevious(selectedCity)
  useEffect(() => {
    if (selectedCity && selectedCity !== prevSelectedCity) {
      if (selectedCity.value === 'Todas') {
        dispatch(getNeighborhoods({}))
      }

      if (selectedCity.value === 'Cidade') {
        return
      }

      dispatch(
        getNeighborhoods({
          cities: selectedCity.value,
        })
      )
    }
  }, [selectedCity, dispatch, prevSelectedCity, neighborhoodOptions])

  const handleSelectCityChange = useCallback(
    event => {
      const { value } = event.target
      setSelectedCity(cityOptions.find(option => option.value === value))
      setSelectedNeighborhood([])
    },
    [cityOptions]
  )

  useEffect(() => {
    if (selectedCity) {
      if (selectedCity.value === 'Todas') {
        dispatch(
          setFilter({
            city: undefined,
            neighborhoods: [],
          })
        )
        setSelectedNeighborhood([])
      } else
        dispatch(
          setFilter({
            city: selectedCity.value,
            neighborhoods: [],
          })
        )
      setSelectedNeighborhood([])
    }
  }, [dispatch, selectedCity])

  const handleSelectNeighborhoodChange = useCallback(
    event => {
      const { value } = event.target
      if (value.includes(ALL_NEIGHBORHOOD)) {
        if (value[0] === ALL_NEIGHBORHOOD && value.length > 1) {
          setSelectedNeighborhood([value[1]])
          dispatch(
            setFilter({
              neighborhoods: selectedNeighborhood,
            })
          )
          return
        }
        setSelectedNeighborhood([ALL_NEIGHBORHOOD])
        dispatch(
          setFilter({
            neighborhoods: [],
          })
        )
        return
      }
      setSelectedNeighborhood(
        value.map(item => neighborhoodOptions.find(option => option.value === item).value)
      )
      dispatch(
        setFilter({
          neighborhoods: value,
        })
      )
    },
    [dispatch, neighborhoodOptions, selectedNeighborhood]
  )

  const onChangeFilter = useCallback(
    event => {
      const { value, name } = event.target

      if (name === 'bedrooms') {
        const payload = SEARCH_BEDROOMS_OPTIONS.find(bedroom => bedroom.value === value)

        setBedrooms(payload)
      }

      if (name === 'purposeType') {
        const payload = SEARCH_PROPERTY_TYPES_OPTIONS.find(property => property.value === value)
        setSelectedPropertyTypes(payload)
      }
      dispatch(setFilter({ [name]: value }))
    },
    [dispatch]
  )

  const selectCity = useCallback(() => {
    if (selectedCity.value === 'Cidade') {
      return DEFAULT_SEARCH.defaultCity.toLowerCase()
    }

    return selectedCity.value
  }, [selectedCity])

  const selectPropertyType = useCallback(() => {
    return selectedPropertyTypes.value
  }, [selectedPropertyTypes.value])

  const selectNeighborhood = useCallback(() => {
    if (
      selectedNeighborhood &&
      selectedNeighborhood.length > 0 &&
      !selectedNeighborhood.includes(ALL_NEIGHBORHOOD)
    ) {
      return [...selectedNeighborhood]
    }

    if (
      neighborhoodOptions.size === 0 ||
      neighborhoodOptions.some(item => item.value === DEFAULT_SEARCH.defaultNeighborhood)
    ) {
      return [DEFAULT_SEARCH.defaultNeighborhood.toLowerCase()]
    }

    return []
  }, [neighborhoodOptions, selectedNeighborhood])

  const onSearchClick = useMemo(() => {
    const searchQuery = parseFilterToUrl(`/buscar`, {
      city: selectCity(),
      neighborhoods: selectNeighborhood(),
      price: selectPrice(),
      bedrooms: selectedBedrooms.value,
      purposeType: selectPropertyType(),
    })
    return searchQuery
  }, [selectCity, selectNeighborhood, selectPrice, selectedBedrooms.value, selectPropertyType])

  const adornmentPrice = useMemo(
    () => ({
      startAdornment: (
        <InputAdornment position="start">
          <AttachMoneyIcon />
        </InputAdornment>
      ),
    }),
    []
  )
  const handlePrice = useCallback((_, value) => {
    setPrice(value)
  }, [])

  return (
    <div className={classnames(styles.container, className)}>
      <div className={styles.content}>
        <h1 className={styles.title}>Aluguel 100% digital</h1>
        <h2 className={styles.description}>
          Alugue seu imóvel sem sair de casa! Sem burocracia, rápido, prático e seguro.
        </h2>
        <TextField
          id="outlined-select-city"
          select
          className={classnames(styles.input, styles['input-city'], materialStyle.textField)}
          value={selectedCity ? selectedCity.value : 'Selecione...'}
          onChange={handleSelectCityChange}
          margin="normal"
          variant="outlined"
          name="city"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        >
          <MenuItem key="empty-city" value="Cidade" disabled>
            Cidade
          </MenuItem>
          {cityOptions.map(optionsCity => (
            <MenuItem key={optionsCity.value} value={optionsCity.value}>
              {optionsCity.label}
            </MenuItem>
          ))}
        </TextField>
        <SelectMultipleCheckbox
          id="outlined-select-neighborhood"
          displayEmpty
          classNames={classnames(
            styles.input,
            styles['input-neighborhood'],
            materialStyle.multipleSelect
          )}
          value={selectedNeighborhood}
          handleChange={handleSelectNeighborhoodChange}
          name="neighborhood"
          disabled={disableNeighborhoodField}
          startAdornmentIcon={<Home />}
          labelItem="Bairro"
          optionsItens={neighborhoodOptions}
          selected={selectedNeighborhood}
        />
        <CurrencyTextField
          InputProps={adornmentPrice}
          variant="outlined"
          value={price}
          onChange={handlePrice}
          digitGroupSeparator="."
          decimalCharacter=","
          currencySymbol="R$"
          textAlign="left"
          className={classnames(
            styles.input,
            styles['input-price'],
            materialStyle.currencyTextField
          )}
          name="price"
          placeholder="Faixa de preço"
        />
        {!FEATURE_FLAGS.commercialListingsEnabled ? (
          <TextField
            id="outlined-select-bedrooms"
            select
            className={classnames(styles.input, styles['input-bedrooms'], materialStyle.textField)}
            value={selectedBedrooms.value}
            onChange={onChangeFilter}
            margin="normal"
            variant="outlined"
            name="bedrooms"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <HotelIcon />
                </InputAdornment>
              ),
            }}
          >
            <MenuItem key="empty-bedrooms" value="Quartos" disabled>
              Quartos
            </MenuItem>
            {SEARCH_BEDROOMS_OPTIONS.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        ) : (
          <TextField
            id="outlined-select-bedrooms"
            select
            className={classnames(styles.input, styles['input-bedrooms'], materialStyle.textField)}
            value={selectedPropertyTypes.value}
            onChange={onChangeFilter}
            margin="normal"
            variant="outlined"
            name="purposeType"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MeetingRoom />
                </InputAdornment>
              ),
            }}
          >
            <MenuItem key="empty-propertyTypes" value="Tipo de Imóvel" disabled>
              Tipo de imóvel
            </MenuItem>
            {SEARCH_PROPERTY_TYPES_OPTIONS.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.description}
              </MenuItem>
            ))}
          </TextField>
        )}
        <Button to={onSearchClick} variant="contained" className={styles.button}>
          BUSCAR
        </Button>
      </div>
    </div>
  )
}

SearchSection.propTypes = {
  className: PropTypes.string,
}

SearchSection.defaultProps = {
  className: '',
}

export default React.memo(SearchSection)

import React, { useMemo } from 'react'
import { Typography, IconButton, Paper, Tooltip, Grid, Link } from '@material-ui/core'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { InsertDriveFile, Panorama, Close } from '@material-ui/icons'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'

import 'react-circular-progressbar/dist/styles.css'
import useStyles, { WhiteTooltip } from './styles'

const CIRCULAR_STYLE = buildStyles({
  pathColor: '#32ba7c',
})

const FileChip = ({ className, file, percentage, showOnly, ...otherProps }) => {
  const styles = useStyles()
  const renderIcon = useMemo(() => {
    if (percentage) {
      return (
        <Grid className={styles.iconFile}>
          <CircularProgressbar value={percentage} styles={CIRCULAR_STYLE} />
        </Grid>
      )
    }
    const isImageType = file && file.file && !file.file.includes('.pdf')

    if (isImageType) {
      return <Panorama className={styles.iconFile} />
    }
    return <InsertDriveFile className={styles.iconFile} />
  }, [file, percentage, styles.iconFile])

  const renderLabel = useMemo(() => {
    if (file.name) return file.name
    if (file.fileName) return file.fileName
    return 'Documento'
  }, [file.fileName, file.name])

  return (
    <Paper variant="outlined" className={classnames(styles.file, className)}>
      {renderIcon}
      <WhiteTooltip title={renderLabel}>
        {file && file.file ? (
          <Link
            className={styles.fileName}
            variant="subtitle1"
            color="textSecondary"
            noWrap
            href={file.file}
            target="_blank"
          >
            {renderLabel}
          </Link>
        ) : (
          <Typography
            className={styles.fileName}
            component="p"
            variant="subtitle1"
            color="textSecondary"
            noWrap
          >
            {renderLabel}
          </Typography>
        )}
      </WhiteTooltip>
      {!showOnly && (
        <Tooltip title="Excluir" arrow>
          <IconButton
            disabled={!!percentage}
            className={styles.iconButton}
            size="small"
            name={file && file.file ? file.id : file.name}
            aria-label="Excluir arquivo"
            {...otherProps}
          >
            <Close className={styles.iconFile} />
          </IconButton>
        </Tooltip>
      )}
    </Paper>
  )
}

FileChip.propTypes = {
  file: PropTypes.shape({
    name: PropTypes.string,
    fileName: PropTypes.string,
    type: PropTypes.string,
    file: PropTypes.string,
    id: PropTypes.number,
  }).isRequired,
  className: PropTypes.string,
  percentage: PropTypes.number,
  showOnly: PropTypes.bool,
}

FileChip.defaultProps = {
  className: '',
  percentage: undefined,
  showOnly: false,
}
export default React.memo(FileChip)

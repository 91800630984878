import React, { useCallback, useMemo, Fragment, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import AriaModal from 'react-aria-modal'
import classnames from 'classnames'
import { Avatar, Grid, Typography } from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'

import { getLeaseById, checkIsCancellingLease, errorCancelLease } from '_modules/leases/selectors'
import { getActiveLeaseSelector } from '_modules/user/selectors'
import cancelClickImage from '_assets/images/cancel-click.svg'
import { getLease, cancelLease } from '_modules/leases/actions'
import { usePrevious, useWindowSize } from '_utils/hooks'
import Button, { ButtonColor, ButtonVariant, ButtonFormat } from '_components/button'
import { PROPERTY_TYPES_LABELS } from '_utils/constants'
import { formatCost } from '_utils/strings'

import { useStyles } from './styles'

const ModalNegotiation = ({ isOpen, onClose, onConfirmNegotiation }) => {
  const dispatch = useDispatch()
  const activeLeaseId = useSelector(getActiveLeaseSelector)
  const activeLease = useSelector(state => getLeaseById(state, activeLeaseId))
  const loading = useSelector(checkIsCancellingLease)
  const error = useSelector(errorCancelLease)
  const wasLoading = usePrevious(loading)
  const { isDesktop, isMobile } = useWindowSize()
  const styles = useStyles()

  const hasActiveLease = useMemo(() => !!activeLease.toJS().id, [activeLease])
  const listing = useMemo(() => activeLease.toJS().listing, [activeLease])
  const listingPicture = useMemo(
    () => (listing.convertedPictures ? listing.convertedPictures[0].picture : ''),
    [listing.convertedPictures]
  )
  const listingAddress = useMemo(() => (listing.address ? listing.address.toLowerCase() : ''), [
    listing.address,
  ])
  const closeModal = useCallback(() => {
    onClose()
  }, [onClose])
  const cancelPreviousLease = useCallback(() => {
    if (!activeLease) {
      onConfirmNegotiation()
    }

    dispatch(cancelLease({ leaseId: activeLeaseId, externalId: listing.externalId }))
  }, [activeLease, activeLeaseId, dispatch, listing.externalId, onConfirmNegotiation])

  useEffect(() => {
    if (!error && wasLoading && !loading) {
      onConfirmNegotiation()
    }
  }, [error, loading, onConfirmNegotiation, wasLoading])

  useEffect(() => {
    if (!hasActiveLease && activeLeaseId) {
      dispatch(getLease(activeLeaseId))
    }
  }, [activeLeaseId, dispatch, hasActiveLease])

  if (!hasActiveLease || !isOpen) {
    return null
  }
  return (
    <Fragment>
      <AriaModal
        titleText="Cancelar negociação"
        svg={cancelClickImage}
        onClose={closeModal}
        onClickButton={cancelPreviousLease}
        dialogClass={styles.modal}
        underlayClass={styles.underlay}
        onExit={onClose}
      >
        <Grid className={styles.container}>
          <Grid className={styles.listingContainer} component="section">
            {isMobile && (
              <Button
                ariaLabel="Fechar"
                className={styles.closeButton}
                color={ButtonColor.GHOST}
                onClick={onClose}
              >
                <CloseIcon className={styles.closeIcon} />
              </Button>
            )}
            {listingPicture && (
              <Avatar src={listingPicture} alt={listing.title} className={styles.listingImage} />
            )}
            <Typography variant="h1" className={styles.title}>
              Negociação em andamento
            </Typography>
            <Grid className={styles.listingInfos}>
              <Typography className={styles.listingType}>
                {PROPERTY_TYPES_LABELS[listing.listingType]} • Código {listing.externalId}
              </Typography>
              <Typography className={styles.listingNeighborhood}>{listing.neighborhood}</Typography>
              <Typography className={styles.listingAddress}>{listingAddress}</Typography>
              <Typography className={styles.listingTotalPrice}>
                Total {formatCost(listing.totalPrice)}
              </Typography>
            </Grid>
          </Grid>

          <Grid className={styles.adviceContainer}>
            <Typography className={styles.adviceTitle}>
              Você está iniciando uma nova análise de crédito
            </Typography>
            <Typography className={styles.adviceDescription}>
              Para seguir com esta negociação, é necessário encerrar a negociação a anterior.
            </Typography>
          </Grid>

          <Grid className={styles.actions}>
            {isDesktop && (
              <Button
                format={ButtonFormat.SQUARED}
                variant={ButtonVariant.BOLD}
                className={classnames(styles.button, styles.cancelButton)}
                onClick={onClose}
              >
                Voltar
              </Button>
            )}
            <Button
              format={ButtonFormat.SQUARED}
              variant={ButtonVariant.BOLD}
              className={styles.button}
              onClick={cancelPreviousLease}
            >
              Encerrar negociação anterior
            </Button>
          </Grid>
        </Grid>
      </AriaModal>
    </Fragment>
  )
}

ModalNegotiation.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirmNegotiation: PropTypes.func.isRequired,
}

export default React.memo(ModalNegotiation)

import React, { useRef, useCallback, useMemo, useEffect, Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { navigate } from '@reach/router'

import withAuth from '_hocs/with-auth'
import Button, { ButtonVariant, ButtonColor, ButtonFormat } from '_components/button'
import {
  getLease,
  inviteParticipants,
  submitWarranty,
  updateParticipants,
  updateUserData,
} from '_modules/leases/actions'
import {
  checkIsCreatingParticipants,
  checkIsSubmittingWarranty,
  checkIsUpdatingUserData,
  getLeaseById,
} from '_modules/leases/selectors'
import { getCurrentWarranty } from '_modules/warranty/selectors'
import { usePrevious } from '_utils/hooks'
import InviteParticipant from '_components/add-income-helpers/invite-participant'
import InviteIncomeHelper from '_components/add-income-helpers/income-helper'
import { PARTICIPANTS_TYPE, WARRANTY_TYPES } from '_utils/constants'
import Building from '_components/building-animated'
import Snackbar from '_components/snackbar'
import { triggerSnackbar, TRIGGER_SNACKBAR } from '_modules/snackbar/actions'

import WarrantyType from '../warranty-type'

import styles from './styles.css'

const WarrantyInviteParticipants = ({ id }) => {
  const guarantorsRef = useRef()
  const tenantsRef = useRef()
  const incomeHelpersRef = useRef()
  const considerIncomeRef = useRef()
  // const [isButtonDisabled, setDisabledButton] = useState(true)
  const activeLease = useSelector(state => getLeaseById(state, id))
  const dispatch = useDispatch()

  const currentWarranty = useSelector(state =>
    getCurrentWarranty(state, activeLease && activeLease.warranty && activeLease.warranty)
  )

  // Loading state to invite participants
  const isInvitingLoading = useSelector(checkIsCreatingParticipants)
  const wasInvitingLoading = usePrevious(isInvitingLoading)

  // Loading state to update the mainTenant considerIncome field
  const isUpdatingUser = useSelector(checkIsUpdatingUserData)
  const wasUpdatingUser = usePrevious(isUpdatingUser)

  // Loading state to submit the lease
  const isSubmittingLoading = useSelector(checkIsSubmittingWarranty)
  const wasSubmittingLoading = usePrevious(isSubmittingLoading)

  useEffect(() => {
    if (!activeLease) {
      dispatch(getLease(id))
    }
  }, [activeLease, dispatch, id])

  const TEXT_OPTIONS = {
    FIRST_OPTION: (
      <p>
        Lembre-se de que não podem ser os moradores e precisam ser <strong>parentes diretos</strong>{' '}
        seus ou de quem irá morar com você.
      </p>
    ),
    SECOND_OPTION: (
      <p>
        Lembre-se: <strong>Fiadores não podem ser moradores</strong>. Os fiadores devem somar uma
        renda igual ou superior a{' '}
        {currentWarranty && currentWarranty.get('guarantorIncomeMultiplier')}x o valor do aluguel +
        encargos.
      </p>
    ),
    THIRD_OPTION: (
      <p>
        <strong>Confira as informações dos locatários</strong> ou se necessário convide mais pessoas
        para compor renda.
      </p>
    ),
    FOURTH_OPTION: (
      <p>
        Pessoas convidadas deverão informar o RG/CPF e seus dados pessoais para a análise de
        crédito.
      </p>
    ),
    FIFTH_OPTION: <p>Informações dos locatários</p>,
    SIXTH_OPTION: <p>Convide outras pessoas</p>,
  }

  const incomeHelperDescription = useMemo(() => {
    if (activeLease && activeLease.warranty === WARRANTY_TYPES.CAPITALIZATION) {
      return TEXT_OPTIONS.FOURTH_OPTION
    }
    return TEXT_OPTIONS.THIRD_OPTION
  }, [TEXT_OPTIONS.FOURTH_OPTION, TEXT_OPTIONS.THIRD_OPTION, activeLease])

  const incomeHelperTitle = useMemo(() => {
    if (activeLease && activeLease.warranty === WARRANTY_TYPES.CAPITALIZATION) {
      return TEXT_OPTIONS.SIXTH_OPTION
    }
    return TEXT_OPTIONS.FIFTH_OPTION
  }, [TEXT_OPTIONS.FIFTH_OPTION, TEXT_OPTIONS.SIXTH_OPTION, activeLease])

  const guarantorDescription = useMemo(() => {
    if (activeLease && activeLease.warranty === WARRANTY_TYPES.STUDENT) {
      return TEXT_OPTIONS.FIRST_OPTION
    }
    return TEXT_OPTIONS.SECOND_OPTION
  }, [TEXT_OPTIONS.FIRST_OPTION, TEXT_OPTIONS.SECOND_OPTION, activeLease])

  const handleUpdateDisableButton = useCallback(() => {
    if (
      activeLease &&
      considerIncomeRef.current &&
      considerIncomeRef.current.total > activeLease.requiredIncome
    ) {
      // setDisabledButton(false)
      return
    }
    if (guarantorsRef.current && tenantsRef.current && incomeHelpersRef.current) {
      // const hasErrors =
      //   !!guarantorsRef.current.filter(item => item.emailError).length ||
      //   !!incomeHelpersRef.current.filter(item => item.emailError).length ||
      //   !!tenantsRef.current.filter(item => item.emailError).length
      // const hasParticipants =
      //   guarantorsRef.current.length > 1 ||
      //   incomeHelpersRef.current.length > 1 ||
      //   tenantsRef.current.length > 1
      // setDisabledButton(!(hasErrors || hasParticipants))
    }
  }, [activeLease])

  const hasGuarantorParticipant = useMemo(() => {
    return !![WARRANTY_TYPES.GUARANTOR, WARRANTY_TYPES.STUDENT].includes(
      activeLease && activeLease.warranty
    )
  }, [activeLease])

  const onSubmit = useCallback(
    event => {
      event.preventDefault()
      const payload = hasGuarantorParticipant
        ? [...incomeHelpersRef.current, ...tenantsRef.current, ...guarantorsRef.current]
        : [...incomeHelpersRef.current, ...tenantsRef.current]
      if (payload.some(({ emailError }) => emailError)) {
        dispatch(triggerSnackbar())
        return
      }

      dispatch(inviteParticipants(activeLease.get('id'), payload))
      if (!considerIncomeRef.current.considerIncome) {
        dispatch(
          updateUserData({
            leaseId: id,
            externalId: activeLease.listing.get('externalId'),
            considerIncome: false,
          })
        )
      }
      if (considerIncomeRef.current.participants.length) {
        considerIncomeRef.current.participants.map(({ participantId, considerIncome }) => {
          return dispatch(
            updateParticipants(
              activeLease.get('id'),
              participantId,
              { considerIncome },
              activeLease.listing.get('externalId')
            )
          )
        })
      }
    },
    [activeLease, dispatch, hasGuarantorParticipant, id]
  )

  const isReadyToSubmit = useMemo(
    () => (!isInvitingLoading && wasInvitingLoading) || (!isUpdatingUser && wasUpdatingUser),
    [isInvitingLoading, isUpdatingUser, wasInvitingLoading, wasUpdatingUser]
  )

  const existingTenants = useMemo(
    () =>
      activeLease && activeLease.participants.size
        ? activeLease.participants
            .toJS()
            .filter(({ category, enabled }) => category === PARTICIPANTS_TYPE.TENANT && enabled)
        : [],
    [activeLease]
  )

  useEffect(() => {
    if (isReadyToSubmit) {
      dispatch(submitWarranty(activeLease.get('id'), { warranty: activeLease.get('warranty') }))
    }
  }, [activeLease, dispatch, isInvitingLoading, isReadyToSubmit, wasInvitingLoading])

  useEffect(() => {
    if (!isSubmittingLoading && wasSubmittingLoading) {
      navigate(`/garantia/cadastro-finalizado`)
    }
  }, [isSubmittingLoading, wasSubmittingLoading])

  const hasTitleScreen = useMemo(() => {
    return ![WARRANTY_TYPES.CAPITALIZATION].includes(activeLease && activeLease.warranty)
  }, [activeLease])

  const hideSumRents = useMemo(() => {
    return !![WARRANTY_TYPES.CAPITALIZATION].includes(activeLease && activeLease.warranty)
  }, [activeLease])

  const isLoading = isInvitingLoading || isUpdatingUser || isSubmittingLoading

  return (
    <section className={styles.container}>
      {activeLease ? (
        <form className={styles.content} onSubmit={onSubmit}>
          <WarrantyType
            type={activeLease.get('warranty')}
            className={{ [styles['margin-bottom']]: !hasTitleScreen }}
          />
          {hasTitleScreen && (
            <Fragment>
              <h1 className={styles.title}>Convide pessoas para o contrato</h1>
              <h2 className={styles['sub-title']}>
                Precisamos dos e-mails dos <span className={styles.bold}>locatários</span> que lhe
                ajudarão a compor sua renda
                {activeLease.warranty === WARRANTY_TYPES.GUARANTOR && ` e dos fiadores`}.
              </h2>
            </Fragment>
          )}
          <InviteIncomeHelper
            incomeHelperRef={incomeHelpersRef}
            handleRefUpdated={handleUpdateDisableButton}
            lease={activeLease.toJS()}
            considerIncomeRef={considerIncomeRef}
            description={incomeHelperDescription}
            title={incomeHelperTitle}
            hideSumRents={hideSumRents}
          />
          {hasGuarantorParticipant && (
            <InviteParticipant
              title="Convide os fiadores"
              description={guarantorDescription}
              type="guarantor"
              participantRef={guarantorsRef}
              handleRefUpdated={handleUpdateDisableButton}
              externalId={activeLease.listing.get('externalId')}
            />
          )}
          <InviteParticipant
            title="Outros Moradores"
            description="Se necessário, atualize a lista de moradores que não comprovam renda."
            type="tenant"
            participantRef={tenantsRef}
            existingTenants={existingTenants}
            leaseId={id}
            handleRefUpdated={handleUpdateDisableButton}
            externalId={activeLease.listing.get('externalId')}
          />
          <Button
            color={ButtonColor.PRIMARY}
            variant={ButtonVariant.BOLD}
            format={ButtonFormat.ROUNDED}
            className={styles.action}
            // disabled={isButtonDisabled}
            isLoading={isLoading}
            type="submit"
          >
            PRÓXIMO
          </Button>
        </form>
      ) : (
        <div className={styles.loader}>
          <Building className={styles.building} />
        </div>
      )}
      <Snackbar
        variant="error"
        message="Verifique se todos os participantes estão com os dados corretos"
        action={TRIGGER_SNACKBAR.ACTION}
      />
    </section>
  )
}

WarrantyInviteParticipants.propTypes = {
  id: PropTypes.string.isRequired,
}

export default withAuth(React.memo(WarrantyInviteParticipants))

import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
  image: {
    width: 300,
    [theme.breakpoints.down('sm')]: {
      width: 170,
    },
  },
  title: {
    marginTop: theme.spacing(2),
    color: theme.palette.text.primary,
    fontWeight: 700,
  },
  text: {
    fontWeight: 400,
    lineHeight: '21px',
    textAlign: 'center',
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(1),
    maxWidth: 427,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 271,
    },
  },
}))

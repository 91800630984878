import React, { Fragment, useMemo, useEffect, useCallback } from 'react'
import { Link, navigate } from '@reach/router'
import { useSelector, useDispatch } from 'react-redux'
import classnames from 'classnames'
import memoizeOne from 'memoize-one'

import { getLikedProperties, GET_LIKED_PROPERTIES } from '_modules/user/actions'
import ArrowIcon from '_assets/images/angle-left-solid.svg'
import { TABLET } from '_config/media-queries'
import FavoriteCard from '_views/favorites/favorite-card'
import Loading from '_components/loading'
import HeartIcon from '_assets/images/ic-gostei.svg'
import Button, { ButtonColor } from '_components/button'
import { primaryColor } from '_utils/colors'

import FavoriteCarousel from './favorite-carousel'
import styles from './styles.css'

const defaultUrl = '/imovel'
const createSvgStyle = memoizeOne(color => ({ fill: color }))
const createTextStyle = memoizeOne(color => ({ color }))

const Favorites = () => {
  const dispatch = useDispatch()
  const isMobile = useSelector(state => state.server.isMobile)
  const likedProperties = useSelector(state => state.user.likedPropertiesList)

  const isLoading = useSelector(state => !!state.loading.get(GET_LIKED_PROPERTIES.ACTION))

  useEffect(() => {
    dispatch(getLikedProperties())
  }, [dispatch])

  const isMobileDevice = useMemo(
    () => (typeof window !== 'undefined' ? window.innerWidth <= TABLET : isMobile),
    [isMobile]
  )

  const mainContainerStyle = useMemo(
    () => ({
      [styles['no-visits']]: !likedProperties.length,
      [styles['multiple-cards']]: likedProperties.length > 1,
      [styles['with-carousel']]: isMobileDevice,
    }),
    [isMobileDevice, likedProperties.length]
  )

  const onFindProperties = useCallback(() => {
    navigate('/')
  }, [])

  if (isLoading) {
    return <Loading />
  }

  return (
    <div className={classnames(styles.main, mainContainerStyle)}>
      <div className={styles.container}>
        <Fragment>
          <h1>Favoritos</h1>
          {likedProperties.length > 0 ? (
            <Fragment>
              {isMobileDevice ? (
                <FavoriteCarousel>
                  {likedProperties.map(visit => (
                    // The page needs to reload so the server can render the AMP view
                    <a
                      key={visit.id}
                      href={`${defaultUrl}/${visit.slug}`}
                      className={styles['spaced-card']}
                    >
                      <FavoriteCard
                        property={visit}
                        primaryColor={primaryColor}
                        isSmall={false}
                        className={styles['favorite-card']}
                      />
                    </a>
                  ))}
                </FavoriteCarousel>
              ) : (
                likedProperties.map(visit => (
                  // The page needs to reload so the server can render the AMP view
                  <a key={visit.id} href={`${defaultUrl}/${visit.slug}`}>
                    <FavoriteCard
                      property={visit}
                      primaryColor={primaryColor}
                      isSmall
                      className={styles['favorite-card']}
                    />
                  </a>
                ))
              )}
              <p className={styles.text}>Esses são os seus favoritos.</p>
            </Fragment>
          ) : (
            <div className={styles.card}>
              <svg
                aria-hidden="true"
                className={styles['calendar-icon']}
                style={createSvgStyle(primaryColor)}
              >
                <use xlinkHref={HeartIcon} />
              </svg>
              <p style={createTextStyle(primaryColor)} className={styles['no-visits-title']}>
                Você ainda não favoritou nenhum imóvel.
              </p>
              <p className={styles['no-visits-text']}>
                A partir da nossa lista de imóveis, você consegue favoritar um imóvel.
              </p>
              <Button color={ButtonColor.PRIMARY} onClick={onFindProperties}>
                VER IMÓVEIS
              </Button>
            </div>
          )}
        </Fragment>
        <Link to="/">
          <svg aria-hidden="true" className={styles.icon}>
            <use xlinkHref={ArrowIcon} />
          </svg>
          VOLTAR PARA PÁGINA INICIAL
        </Link>
      </div>
    </div>
  )
}

Favorites.propTypes = {}

Favorites.defaultProps = {}

export default React.memo(Favorites)

import React, { Fragment, useMemo, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, navigate } from '@reach/router'
import memoizeOne from 'memoize-one'
import classnames from 'classnames'
import moment from 'moment'

import { getBookings, GET_BOOKINGS } from '_modules/booking/actions'
import { getActiveBookings } from '_modules/booking/selectors'
import Button, { ButtonColor } from '_components/button'
import ArrowIcon from '_assets/images/angle-left-solid.svg'
import CalendarIcon from '_assets/images/visits-calendar-icon.svg'
import { TABLET } from '_config/media-queries'
import VisitsCarousel from '_views/visit/scheduled-visits/visits-carousel'
import withAuth from '_hocs/with-auth'
import Loading from '_components/loading'
import { BOOKING_STATUS } from '_utils/constants'
import { primaryColor } from '_utils/colors'

import VisitCard from './visit-card'
import styles from './styles.css'

const createSvgStyle = memoizeOne(color => ({ fill: color }))

const createTextStyle = memoizeOne(color => ({ color }))

const ScheduledVisits = () => {
  const dispatch = useDispatch()
  const isMobile = useSelector(state => state.server.isMobile)
  const bookings = useSelector(state => getActiveBookings(state))
  const isGettingBooking = useSelector(state => !!state.loading.get(GET_BOOKINGS.ACTION))

  useEffect(() => {
    dispatch(getBookings())
  }, [dispatch])

  const booking = useMemo(() => bookings[0], [bookings])
  const isMobileDevice = useMemo(
    () => (typeof window !== 'undefined' ? window.innerWidth <= TABLET : isMobile),
    [isMobile]
  )

  const renderCard = useCallback(
    (visit, link) =>
      visit.available ? (
        <Link key={visit.id} to={link}>
          <VisitCard
            booking={visit}
            available={visit.available}
            primaryColor={primaryColor}
            className={styles['visit-card']}
          />
        </Link>
      ) : (
        <VisitCard
          key={visit.id}
          booking={visit}
          available={visit.available}
          primaryColor={primaryColor}
          className={styles['visit-card']}
        />
      ),
    []
  )

  const getVisitLink = useCallback(
    id => {
      const { available, lease } = bookings[id]
      const { status } = lease

      if (status === BOOKING_STATUS.BOOKING_WAITING_FEEDBACK)
        return `/feedback-visita/${bookings[id].id}`

      if (!available) return ''

      return `/minhas-visitas/${bookings[id].id}`
    },
    [bookings]
  )

  const getTargetUrl = useCallback(
    (visit, index = 0) => {
      if (visit && visit.listing && visit.listing.contractSigned) {
        return ''
      }
      return getVisitLink(index)
    },
    [getVisitLink]
  )

  const handleClickOneVisit = useCallback(() => {
    navigate(getTargetUrl(booking))
  }, [booking, getTargetUrl])

  const onSeeMoreClick = useCallback(() => {
    navigate('/')
  }, [])

  if (isGettingBooking) {
    return <Loading />
  }
  return (
    <div
      className={classnames(styles.main, {
        [styles['no-visits']]: !bookings.length,
        [styles['multiple-cards']]: bookings.length > 1,
        [styles['with-carousel']]: bookings.length > 1 && isMobileDevice,
      })}
    >
      <div className={styles.container}>
        {bookings.length ? (
          <Fragment>
            <h1>Visitas agendadas</h1>
            {bookings.length === 1 && isMobileDevice ? (
              renderCard(booking, getTargetUrl(booking))
            ) : (
              <Fragment>
                {isMobileDevice ? (
                  <VisitsCarousel visits={bookings} primaryColor={primaryColor} />
                ) : (
                  bookings
                    .sort(
                      (bookingA, bookingB) =>
                        moment(bookingA.dateAndTime) - moment(bookingB.dateAndTime)
                    )
                    .sort((bookingA, bookingB) =>
                      bookingA.cancelled > bookingB.cancelled ? 1 : -1
                    )
                    .map((visit, index) => renderCard(visit, getTargetUrl(visit, index)))
                )}
              </Fragment>
            )}
            {bookings.length === 1 && !booking.listing.contractSigned && (
              <Button color={ButtonColor.PRIMARY} onClick={handleClickOneVisit}>
                VER MAIS
              </Button>
            )}
            <p className={styles.text}>Essas são as suas visitas.</p>
          </Fragment>
        ) : (
          <div className={styles.card}>
            <svg
              aria-hidden="true"
              className={styles['calendar-icon']}
              style={createSvgStyle(primaryColor)}
            >
              <use xlinkHref={CalendarIcon} />
            </svg>
            <p style={createTextStyle(primaryColor)} className={styles['no-visits-title']}>
              Você ainda não possui visitas agendadas.
            </p>
            <p className={styles['no-visits-text']}>
              A partir da nossa lista de imóveis, você consegue agendar uma visita.
            </p>
            <Button color={ButtonColor.PRIMARY} onClick={onSeeMoreClick}>
              VER IMÓVEIS
            </Button>
          </div>
        )}
        <Link to="/">
          <svg aria-hidden="true" className={styles.icon}>
            <use xlinkHref={ArrowIcon} />
          </svg>
          VOLTAR PARA PÁGINA INICIAL
        </Link>
      </div>
    </div>
  )
}

export default withAuth(React.memo(ScheduledVisits))

import { transformQuery } from '_utils/strings'

import {
  PROPERTY_TYPES,
  PROPERTY_FURNITURES,
  PROPERTY_BOOKING,
  PROPERTY_TRANSACTIONS,
} from './constants'

export const PROPERTY_FRIENDLY_URL = {
  [PROPERTY_TYPES.RES_HOME]: 'casa',
  [PROPERTY_TYPES.RES_APARTMENT]: 'apartamento',
  [PROPERTY_TYPES.RES_FARM_RANCH]: 'sitio',
  [PROPERTY_TYPES.RES_CONDO]: 'condominio',
  [PROPERTY_TYPES.RES_FLAT]: 'flat',
  [PROPERTY_TYPES.RES_LAND_LOT]: 'terreno',
  [PROPERTY_TYPES.RES_SOBRADO]: 'sobrado',
  [PROPERTY_TYPES.RES_PENTHOUSE]: 'cobertura',
  [PROPERTY_TYPES.RES_STUDIO]: 'studio',
  [PROPERTY_TYPES.RES_KITNET]: 'kitnet',
  [PROPERTY_TYPES.COM_CONSULTORIO]: 'consultorio',
  [PROPERTY_TYPES.COM_OFFICE]: 'escritorio',
  [PROPERTY_TYPES.COM_AGRICULTURAL]: 'comercial',
  [PROPERTY_TYPES.COM_INDUSTRIAL]: 'comercial',
  [PROPERTY_TYPES.COM_BUILDING]: 'ponto-comercial',
  [PROPERTY_TYPES.COM_RESIDENTIAL_INCOME]: 'casa-comercial',
  [PROPERTY_TYPES.COM_LOJA]: 'loja',
  [PROPERTY_TYPES.COM_LAND_LOT]: 'terreno-comercial',
  [PROPERTY_TYPES.COM_RETAIL]: 'sala-comercial',
}

export const FRIENDLY_URL_POSSIBILITY = [
  ...Object.values(PROPERTY_FRIENDLY_URL),
  'sala',
  'ponto',
  'terreno',
  'propriedade',
]

export const isFriendlyUrl = url => {
  return FRIENDLY_URL_POSSIBILITY.includes(url.split('-')[0])
}

export const getPropertyType = type => {
  switch (type) {
    case PROPERTY_TYPES.RES_HOME:
      return 'Casa'

    case PROPERTY_TYPES.RES_APARTMENT:
      return 'Apartamento'

    case PROPERTY_TYPES.RES_FLAT:
      return 'Flat'

    case PROPERTY_TYPES.RES_FARM_RANCH:
      return 'Sítio'

    case PROPERTY_TYPES.RES_CONDO:
      return 'Condomínio'

    case PROPERTY_TYPES.RES_SOBRADO:
      return 'Sobrado'

    case PROPERTY_TYPES.RES_STUDIO:
      return 'Studio'

    case PROPERTY_TYPES.RES_KITNET:
      return 'Kitnet'

    case PROPERTY_TYPES.RES_PENTHOUSE:
      return 'Cobertura'

    case PROPERTY_TYPES.RES_LAND_LOT:
      return 'Terreno'

    case PROPERTY_TYPES.COM_CONSULTORIO:
      return 'Consultório'

    case PROPERTY_TYPES.COM_OFFICE:
      return 'Escritório'

    case PROPERTY_TYPES.COM_RETAIL:
      return 'Sala Comercial'

    case PROPERTY_TYPES.COM_RESIDENTIAL_INCOME:
      return 'Casa Comercial'

    case PROPERTY_TYPES.COM_LOJA:
      return 'Loja'

    case PROPERTY_TYPES.COM_AGRICULTURAL:
      return 'Comercial de agricultura'

    case PROPERTY_TYPES.COM_INDUSTRIAL:
      return 'Comercial industrial'

    case PROPERTY_TYPES.COM_BUILDING:
      return 'Edifício comercial'

    case PROPERTY_TYPES.COM_LAND_LOT:
      return 'Terreno comercial'

    default:
      return 'Apartamento / Casa'
  }
}

export const getPropertyFurnishedOption = option => {
  switch (option) {
    case PROPERTY_FURNITURES.SEMI_FURNISHED:
      return 'Semimobiliado'
    case PROPERTY_FURNITURES.UNFURNISHED:
      return 'Sem mobília'
    default:
      return 'Mobiliado'
  }
}

export const getPropertyAvailability = status => {
  switch (status) {
    case PROPERTY_BOOKING.AVAILABLE:
      return 'Disponível'
    case PROPERTY_BOOKING.VISITING:
      return 'Em vista'
    case PROPERTY_BOOKING.BOOKED:
      return 'Reservado'
    case PROPERTY_BOOKING.KEY_OWNER:
      return 'Chaves com o proprietário'
    default:
      return 'Disponível'
  }
}

export const getTransactionType = type => {
  switch (type) {
    case PROPERTY_TRANSACTIONS.RENT:
      return 'Alugar'
    default:
      return 'Alugar'
  }
}

export const getPropertyAddress = property =>
  `${property.address ? property.address.capitalizeAll(true) : ''}${
    property.neighborhood ? `, ${property.neighborhood.capitalizeAll(true)}` : ''
  }${property.city ? `, ${property.city.capitalizeAll(true)}` : ''}${
    property.state ? `, ${property.state.toUpperCase()}` : ''
  }${property.postalCode ? `, ${property.postalCode}` : ''}`

/* 
  getPropertyDefaultTitle is responsible for create strings based 
  in the listing object model, and also can output strings with slug format
  for navigate, have diff conditions for listings with externalsIds to access 
  the property
*/
export const getPropertyDefaultTitle = (data, isSlug = false) => {
  const dataListing = isSlug
    ? PROPERTY_FRIENDLY_URL[data.listingType] || 'propriedade'
    : getPropertyType(data.listingType) || 'Imóvel'
  const title = `${dataListing} ${
    data.bedrooms ? `de ${data.bedrooms} ${data.bedrooms > 1 ? 'quartos' : 'quarto'}` : ''
  }  ${data.neighborhood ? ` no Bairro ${data.neighborhood.capitalize(true)}` : ''}`
  if (isSlug) {
    return `${title &&
      title
        .toLowerCase()
        .split(/\s+/g)
        .join(' ')
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .split(' ')
        .join('-')}/${data.externalId || data.external_id}`
  }
  if (!isSlug || !data.externalId) {
    return `${title &&
      title
        .toLowerCase()
        .replace(/\s/g, '')
        .replace('/', '-')}`
  }
  return title
}

export const getPropertyDefaultAddress = data => {
  return data.address
    ? data.address.capitalizeAll(true)
    : `
  ${data.city ? `${data.city.capitalize(true)}` : ''}`
}
export const sortingProperty = (values, returnLower = false) =>
  values.sort((propertyA, propertyB) => {
    const priceA =
      propertyA.rentPrice + propertyA.condominiumFee + propertyA.taxes + propertyA.insurance
    const priceB =
      propertyB.rentPrice + propertyB.condominiumFee + propertyB.taxes + propertyB.insurance
    return returnLower ? priceA - priceB : priceB - priceA
  })

export const createSearchQuery = listing => {
  let searchRoute = '/buscar'
  if (listing.get('city')) {
    searchRoute = searchRoute.concat(`?city=${transformQuery(listing.get('city'))}`)
  }
  if (listing.get('bedrooms')) {
    searchRoute = searchRoute.concat(`&bedrooms=${listing.get('bedrooms')}`)
  }
  if (listing.get('rentPrice')) {
    searchRoute = searchRoute.concat(`&price=${listing.get('rentPrice')}`)
  }
  if (listing.get('neighborhood')) {
    searchRoute = searchRoute.concat(
      `&neighborhoods=${transformQuery(listing.get('neighborhood'))}`
    )
  }
  return searchRoute
}

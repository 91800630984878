import React, { useCallback, useState } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import Checkbox from '../../form/checkbox'
import CurrencyInput from '../currency-input'

import styles from './styles.css'

const OptionalExpense = ({ label, name, maxValue, minValue, valuePace, setValue, value }) => {
  const [isApplicable, setApplicability] = useState(false)

  const disableInput = useCallback(() => {
    setApplicability(prevState => !prevState)
  }, [])

  return (
    <div className={styles['add-value']}>
      <div className={styles['label-container']}>
        <p className={classnames(styles.label, { [styles.disabled]: isApplicable })}>{label}</p>
        <Checkbox
          label="Não se aplica"
          value={isApplicable}
          onChange={disableInput}
          className={classnames(styles.checkbox, {
            [styles['show-apply-label']]: isApplicable,
          })}
        />
      </div>
      <CurrencyInput
        valuePace={valuePace}
        maxValue={maxValue}
        minValue={minValue}
        name={name}
        amount={value}
        setValue={setValue}
        className={styles['inline-field']}
        disabled={isApplicable}
      />
    </div>
  )
}
OptionalExpense.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  valuePace: PropTypes.number,
  maxValue: PropTypes.number,
  minValue: PropTypes.number,
  value: PropTypes.number.isRequired,
  setValue: PropTypes.func.isRequired,
}

OptionalExpense.defaultProps = {
  label: '',
  name: '',
  valuePace: 10,
  maxValue: 10000,
  minValue: 0,
}

export default React.memo(OptionalExpense)

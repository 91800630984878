import { FILTER_VALUES } from '_utils/url-friendly'

import Model from './model'

class Filter extends Model({
  ...Object.values(FILTER_VALUES).reduce((acc, value) => ({ ...acc, [value]: '' }), {}),
  neighborhoods: [],
  price: '',
  city: 'Cidade',
  bedrooms: 'Quartos',
  purposeType: '',
  pets: 'TANTO FAZ',
  furnished: 'TANTO FAZ',
  listingType: [],
}) {}

export default Filter

import React from 'react'
import PropTypes from 'prop-types'
import { ArrowBackIos as ArrowBackIcon } from '@material-ui/icons'
import { Fab } from '@material-ui/core'

import useStyles from './styles'

const PrevArrow = ({ onClick }) => {
  const styles = useStyles()

  return (
    <Fab aria-label="Imóvel anterior" className={styles.button} onClick={onClick}>
      <ArrowBackIcon className={styles.arrow} />
    </Fab>
  )
}

PrevArrow.propTypes = {
  onClick: PropTypes.func,
}

PrevArrow.defaultProps = {
  onClick: () => {},
}

export default PrevArrow

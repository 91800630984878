import React, { useMemo, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { primaryColor, primaryTextColor } from '_utils/colors'

import styles from './styles.css'

const InputButton = ({ className, name, id, value, label, type, rules }) => {
  const { register, getValues, watch } = useFormContext()

  useEffect(() => {
    watch()
  }, [watch])

  const selected = getValues()[name]

  const checkedStyles = useMemo(
    () =>
      (Array.isArray(selected) && selected.includes(value)) || selected === value
        ? {
            color: primaryTextColor,
            backgroundColor: primaryColor,
            fontWeight: 'bold',
          }
        : {},
    [selected, value]
  )

  return (
    <label style={checkedStyles} htmlFor={id} className={classnames(styles.label, className)}>
      {label}
      <input
        ref={register(rules)}
        type={type}
        className={styles.input}
        name={name}
        id={id}
        value={value}
        key={id}
      />
    </label>
  )
}

InputButton.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  rules: PropTypes.shape({ required: PropTypes.oneOf([PropTypes.string, PropTypes.bool]) }),
}

InputButton.defaultProps = {
  className: '',
  name: '',
  id: '',
  value: '',
  label: '',
  type: 'radio',
  rules: { required: false },
}

export default React.memo(InputButton)

import React, { useState, useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, Redirect, useParams } from '@reach/router'

import withAuth from '_hocs/with-auth'
import Button, { ButtonVariant, ButtonFormat } from '_components/button'
import { requestContractChanges, getLease } from '_modules/leases/actions'
import {
  getLeaseById,
  checkIsLoadingLease,
  checkIsRequestingContractChanges,
} from '_modules/leases/selectors'
import { usePrevious } from '_utils/hooks'
import { LEASE_STATUS } from '_utils/constants'
import { getLeaseStatus } from '_utils/lease'

import styles from './styles.css'

const buttonProps = isEnabled => (isEnabled ? { active: true } : { disabled: true })

const ContractChange = () => {
  const { leaseId, contractId } = useParams()
  const [text, setText] = useState('')
  const lease = useSelector(state => getLeaseById(state, leaseId))
  const isLoadingLease = useSelector(checkIsLoadingLease)
  const isRequestingContractChanges = useSelector(checkIsRequestingContractChanges)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const wasLoadingLease = usePrevious(isLoadingLease)
  const wasRequestingContractChanges = usePrevious(isRequestingContractChanges)

  const onTextChange = useCallback(event => {
    setText(event.target.value)
  }, [])

  const onSendClick = useCallback(() => {
    if (lease) {
      dispatch(
        requestContractChanges({
          leaseId,
          contractId,
          externalId: lease.listing.get('externalId'),
          payload: {
            comment: text,
          },
        })
      )
    }
  }, [contractId, dispatch, lease, leaseId, text])

  useEffect(() => {
    if (leaseId) {
      dispatch(getLease(leaseId))
    }
  }, [dispatch, leaseId])

  useEffect(() => {
    if (wasLoadingLease && !isLoadingLease && !lease) {
      navigate('/minhas-negociacoes')
    }
  }, [isLoadingLease, lease, navigate, wasLoadingLease])

  useEffect(() => {
    // TODO: error state
    if (wasRequestingContractChanges && !isRequestingContractChanges) {
      navigate('/contrato/alteracao')
    }
  }, [isRequestingContractChanges, navigate, wasRequestingContractChanges])

  if (!lease) {
    // TODO: loading state
    return null
  }

  if (getLeaseStatus(lease.status) !== LEASE_STATUS.CONTRACT_READY) {
    return <Redirect to="/minhas-negociacoes" noThrow />
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <p className={styles.title}>Alterações do contrato</p>
        <p className={styles.description}>Insira as alterações aqui.</p>
        <textarea className={styles.input} placeholder="Escreva algo..." onChange={onTextChange} />
        <Button
          variant={ButtonVariant.BOLD}
          format={ButtonFormat.ROUNDED}
          className={styles.action}
          {...buttonProps(text.length)}
          onClick={onSendClick}
          isLoading={isRequestingContractChanges}
        >
          ENVIAR
        </Button>
      </div>
    </div>
  )
}

export default withAuth(React.memo(ContractChange))

import React, { Fragment, useCallback } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { onMouseDown } from '_utils/helpers'

import styles from './styles.css'

export const BUTTON_THEME = {
  DEFAULT: 'default',
  GREEN: 'green',
}

const RowButton = ({ name, icon, open, onClick, iconFirst, className, theme }) => {
  const renderIcon = useCallback(
    Icon => (
      <svg aria-hidden="true" className={classnames(styles.icon, { [styles.reverse]: open })}>
        <use xlinkHref={Icon} />
      </svg>
    ),
    [open]
  )

  return (
    <button
      className={classnames(styles.button, className, styles[theme], {
        [styles['use-pointer']]: !!onClick,
      })}
      onClick={onClick}
      onMouseDown={onMouseDown}
    >
      {iconFirst ? (
        <Fragment>
          {icon && renderIcon(icon)}
          <p className={styles.label}>{name}</p>
        </Fragment>
      ) : (
        <Fragment>
          <p className={styles.label}>{name}</p>
          {icon && renderIcon(icon)}
        </Fragment>
      )}
    </button>
  )
}

RowButton.propTypes = {
  iconFirst: PropTypes.bool,
  name: PropTypes.string.isRequired,
  icon: PropTypes.node,
  onClick: PropTypes.func,
  className: PropTypes.string,
  theme: PropTypes.string,
  open: PropTypes.bool,
}

RowButton.defaultProps = {
  open: false,
  iconFirst: true,
  className: '',
  theme: BUTTON_THEME.DEFAULT,
  icon: null,
  onClick: null,
}

export default RowButton

import { PARTICIPANTS_INFO } from '_utils/constants'

export const FIELDS = [
  { value: PARTICIPANTS_INFO.FULL_NAME, md: 8 },
  { value: PARTICIPANTS_INFO.EMAIL, md: 4 },
  { value: PARTICIPANTS_INFO.PHONE_NUMBER, md: 8 },
  { value: PARTICIPANTS_INFO.GENDER, md: 4 },
  { value: PARTICIPANTS_INFO.RG, md: 8 },
  { value: PARTICIPANTS_INFO.ISSUING_AGENCY, md: 4 },
  { value: PARTICIPANTS_INFO.CPF, md: 8 },
  { value: PARTICIPANTS_INFO.NATIONALITY, md: 4 },
  { value: PARTICIPANTS_INFO.DATE_OF_BIRTH, md: 8 },
  { value: PARTICIPANTS_INFO.CIVIL_STATUS, md: 4 },
  { value: PARTICIPANTS_INFO.CURRENT_ADDRESS, md: 8 },
  { occupation: true, md: 4 },
]

export const FIELDS_CREDPAGO = [
  { value: PARTICIPANTS_INFO.FULL_NAME, md: 8 },
  { value: PARTICIPANTS_INFO.EMAIL, md: 4 },
  { value: PARTICIPANTS_INFO.PHONE_NUMBER, md: 8 },
  { value: PARTICIPANTS_INFO.GENDER, md: 4 },
  { value: PARTICIPANTS_INFO.RG, md: 8 },
  { value: PARTICIPANTS_INFO.ISSUING_AGENCY, md: 4 },
  { value: PARTICIPANTS_INFO.CPF, md: 8 },
  { value: PARTICIPANTS_INFO.NATIONALITY, md: 4 },
  { value: PARTICIPANTS_INFO.DATE_OF_BIRTH, md: 8 },
  { value: PARTICIPANTS_INFO.CIVIL_STATUS, md: 4 },
  { occupation: true, md: 8 },
  { value: PARTICIPANTS_INFO.ADDRESS, md: 4 },
  { value: PARTICIPANTS_INFO.STREET_NUMBER, md: 8 },
  { value: PARTICIPANTS_INFO.NEIGHBORHOOD, md: 4 },
  { value: PARTICIPANTS_INFO.CEP, md: 8 },
  { value: PARTICIPANTS_INFO.EXTRA_ADDRESS_INFO, md: 4 },
  { value: PARTICIPANTS_INFO.CITY, md: 8 },
  { value: PARTICIPANTS_INFO.STATE, md: 4 },
]

import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

const getMetaTags = ({ title, description, url, contentType, image, brand, facebookAppId }) => {
  const metaTags = [
    { itemprop: 'name', content: title },
    { itemprop: 'description', content: description },
    { itemprop: 'image', content: image },
    { name: 'description', content: description },
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:title', content: title },
    { name: 'twitter:description', content: description },
    { name: 'twitter:image:src', content: image },
    { property: 'og:title', content: title },
    { property: 'og:type', content: contentType },
    { property: 'og:url', content: url },
    { property: 'og:image', content: image },
    { property: 'og:image:secure_url', content: image },
    { property: 'og:image:type', content: 'image/png' },
    { property: 'og:description', content: description },
    { property: 'og:site_name', content: brand },
    { property: 'fb:app_id', content: facebookAppId },
  ]

  return metaTags
}

const SEO = ({
  schema,
  title,
  description,
  path,
  contentType,
  twitter,
  image,
  brand,
  facebookAppId,
}) => (
  <Helmet
    htmlAttributes={{
      lang: 'pt-BR',
      itemscope: undefined,
      itemtype: `http://schema.org/${schema}`,
    }}
    title={title}
    link={[{ rel: 'canonical', href: path }]}
    meta={getMetaTags({
      title,
      description,
      contentType,
      url: path,
      twitter,
      image,
      brand,
      facebookAppId,
    })}
  />
)

SEO.propTypes = {
  schema: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  path: PropTypes.string.isRequired,
  contentType: PropTypes.string,
  twitter: PropTypes.string,
  image: PropTypes.string,
  brand: PropTypes.string,
  facebookAppId: PropTypes.string,
}

SEO.defaultProps = {
  schema: '',
  title: '',
  description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam molestie sapien feugiat nisl sollicitudin, in vehicula nulla aliquam. Morbi imperdiet neque vitae sollicitudin lacinia. Mauris ac diam sed enim placerat ultrices. In pharetra commodo semper.',
  contentType: 'website',
  twitter: '',
  image: '',
  brand: '',
  facebookAppId: null,
}

export default SEO

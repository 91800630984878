import React, { useCallback, useEffect, useMemo, useContext } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { navigate } from '@reach/router'
import ReactGA from 'react-ga'
import { Grid, Typography, SvgIcon, Paper } from '@material-ui/core'

import { usePrevious } from '_utils/hooks'
import { FEATURE_FLAGS, WARRANTY_TYPES } from '_utils/constants'
import Button from '_components/material/button'
import MobileClickIcon from '_assets/images/mobile-click.svg'
import withAuth from '_hocs/with-auth'
import { getLeaseById, checkIsLoadingLease, hasLeaseErrorSelector } from '_modules/leases/selectors'
import TokenContext from '_context/token'
import { creditAnalysisFlow, CREDIT_ANALYSIS_ROUTES } from '_views/credit-analysis/router'

import useStyles from './styles'

const NeedMoreData = ({ leaseId }) => {
  useContext(TokenContext)
  const styles = useStyles()

  const isGettingLease = useSelector(checkIsLoadingLease)
  const hasLeaseError = useSelector(hasLeaseErrorSelector)
  const lease = useSelector(state => getLeaseById(state, leaseId))
  const request = (lease && lease.getIn(['incomeHelperRequest', 'comment'])) || ''
  const hasGuarantorRequested = !!lease && lease.hasRequestedGuarantors
  const wasGettingLease = usePrevious(isGettingLease)

  useEffect(() => {
    if (wasGettingLease && !isGettingLease && hasLeaseError) {
      navigate('/')
    }
  })

  const inviteLinkTarget = useMemo(() => {
    const target = {
      url: '',
      state: {},
    }
    if (
      FEATURE_FLAGS.creditAnalysisIntegrationEnabled &&
      lease &&
      lease.warranty === WARRANTY_TYPES.RAPID
    ) {
      target.url = '/minhas-negociacoes/renda-mensal'
      target.state = lease && { externalId: lease.getIn(['listing', 'externalId']) }
    } else if (hasGuarantorRequested) {
      target.url = creditAnalysisFlow(CREDIT_ANALYSIS_ROUTES.ADD_GUARANTOR_DATA, leaseId)
    } else {
      target.url = creditAnalysisFlow(CREDIT_ANALYSIS_ROUTES.ADD_INCOME_HELPER, leaseId)
    }

    return target
  }, [hasGuarantorRequested, lease, leaseId])

  const inviteNewParticipantsClick = useCallback(() => {
    ReactGA.event({
      category: 'Nova análise de crédito',
      action: 'Click',
      label: `Convidar participantes`,
    })
  }, [])

  const chooseNewWarrantyClick = useCallback(() => {
    ReactGA.event({
      category: 'Nova análise de crédito',
      action: 'Click',
      label: `Escolher nova garantia`,
    })
  }, [])
  const showJustification = useMemo(() => {
    if (lease && request && lease.warranty !== WARRANTY_TYPES.RAPID) {
      return true
    }
    return false
  }, [lease, request])

  const showChooseWarranty = useMemo(() => {
    return (
      FEATURE_FLAGS.creditAnalysisIntegrationEnabled &&
      lease &&
      lease.warranty === WARRANTY_TYPES.RAPID
    )
  }, [lease])

  const renderParticipantTypeText = useMemo(() => {
    return hasGuarantorRequested ? (
      <span>
        A renda dos convidados não foi suficiente. Por favor, convide
        <strong> novos</strong> fiadores para completar sua análise cadastral.
      </span>
    ) : (
      <span>
        Convide
        <strong> novos</strong> locatários para completar sua análise cadastral
      </span>
    )
  }, [hasGuarantorRequested])

  const renderText = useMemo(() => {
    if (
      FEATURE_FLAGS.creditAnalysisIntegrationEnabled &&
      lease &&
      lease.warranty === WARRANTY_TYPES.RAPID
    ) {
      return (
        <span>
          A sua análise não foi aprovada. Você ainda pode tentar uma nova análise convidando
          <strong> novas pessoas.</strong>
        </span>
      )
    }
    return renderParticipantTypeText
  }, [lease, renderParticipantTypeText])

  const renderTitle = useMemo(() => {
    if (
      FEATURE_FLAGS.creditAnalysisIntegrationEnabled &&
      lease &&
      lease.warranty === WARRANTY_TYPES.RAPID
    ) {
      return 'Tente uma nova análise'
    }
    if (hasGuarantorRequested) {
      return 'Precisamos de mais dados'
    }
    return 'Convide novas pessoas'
  }, [hasGuarantorRequested, lease])

  return (
    <Grid component="section" container direction="column" className={styles.container}>
      <Grid component="section" container className={styles.content}>
        <Grid className={styles.firstSection}>
          <SvgIcon aria-hidden="true" aria-label="Ícone de celular" className={styles.icon}>
            <use xlinkHref={MobileClickIcon} />
          </SvgIcon>
          <Typography variant="h3" component="h1" color="secondary" className={styles.title}>
            {renderTitle}
          </Typography>
        </Grid>
        <Typography variant="h4" component="p" color="secondary" className={styles.subTitle}>
          {renderText}
        </Typography>
        {showJustification && (
          <Paper variant="outlined" className={styles.justification}>
            <Typography
              variant="subtitle2"
              component="p"
              color="secondary"
              className={styles.justificationTitle}
            >
              Justificativa
            </Typography>
            <Grid className={styles.horizontalDivision} />
            <Typography
              variant="subtitle2"
              component="p"
              color="secondary"
              className={styles.subDescriptionJustification}
            >
              {request}
            </Typography>
          </Paper>
        )}
        <Button
          to={inviteLinkTarget.url}
          state={inviteLinkTarget.state}
          className={styles.button}
          color="primary"
          variant="contained"
          onClick={inviteNewParticipantsClick}
        >
          CONVIDAR
        </Button>
        {showChooseWarranty && (
          <Paper variant="outlined" className={styles.warranty}>
            <Grid container direction="column">
              <Typography
                variant="subtitle1"
                component="p"
                color="secondary"
                className={styles.warrantyTitle}
              >
                Caso queira, escolha uma garantia
              </Typography>
              <Typography
                variant="subtitle2"
                component="p"
                color="secondary"
                className={styles.subDescription}
              >
                Se a opção de convidar novas pessoas não fizer sentido para você, escolha uma
                garantia.
              </Typography>
            </Grid>

            <Grid className={styles.line} />
            <Button
              to={`/minhas-negociacoes/${leaseId}/garantia/escolha`}
              className={styles.buttonWarranty}
              color="secondary"
              variant="text"
              onClick={chooseNewWarrantyClick}
            >
              Ver garantias
            </Button>
          </Paper>
        )}
        {/** TODO: Button needs to be commented for now, while we decided how the flows goes on */}
        {/* <Button className={styles.buttonSecondary} color="secondary" variant="text">
          Encerrar negociação
        </Button> */}
      </Grid>
    </Grid>
  )
}

NeedMoreData.propTypes = {
  leaseId: PropTypes.string.isRequired,
}

export default withAuth(React.memo(NeedMoreData))

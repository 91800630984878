import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'

import Button from '_components/material/button'

import useStyles from './styles'

const ProposalAnswer = ({ onClickSubmit, onClickQuit, isButtonDisabled }) => {
  const styles = useStyles()

  return (
    <Grid className={styles.container}>
      <Button color="secondary" onClick={onClickQuit}>
        DESISTIR
      </Button>
      <Button
        disableElevation
        className={styles.button}
        variant="contained"
        onClick={onClickSubmit}
        disabled={isButtonDisabled}
      >
        INICIAR NEGOCIAÇÃO
      </Button>
    </Grid>
  )
}

ProposalAnswer.propTypes = {
  onClickSubmit: PropTypes.func,
  onClickQuit: PropTypes.func,
  isButtonDisabled: PropTypes.bool,
}

ProposalAnswer.defaultProps = {
  onClickSubmit: () => {},
  onClickQuit: () => {},
  isButtonDisabled: false,
}

export default React.memo(ProposalAnswer)

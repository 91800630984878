import React, { useState, useCallback, useEffect, useMemo } from 'react'
import { useParams, useNavigate } from '@reach/router'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import RadioGroup from '@material-ui/core/RadioGroup'
import { useDispatch, useSelector } from 'react-redux'

import Button, { ButtonVariant, ButtonColor, ButtonFormat } from '_components/button'
import logoPorto from '_assets/images/logo-porto.svg'
import withAuth from '_hocs/with-auth'
import { sendFireInsurance, getLease } from '_modules/leases/actions'
import {
  checkIsSendingFireInsurance,
  hasErrorFireInsurance,
  getLeaseById,
} from '_modules/leases/selectors'
import { usePrevious } from '_utils/hooks'
import { toCurrency } from '_utils/helpers'

import styles from './styles.css'

const FireInsurance = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [contactInsurance, setInsurance] = useState(false)
  const lease = useSelector(state => getLeaseById(state, id))

  const isLoading = useSelector(checkIsSendingFireInsurance)
  const wasLoading = usePrevious(isLoading)
  const fireInsuranceError = useSelector(hasErrorFireInsurance)

  useEffect(() => {
    dispatch(getLease(id))
  }, [dispatch, id])

  const handleInsurance = useCallback(event => {
    setInsurance(event.target.value === 'true')
  }, [])

  const onConfirmClick = useCallback(() => {
    const externalId = lease && lease.listing.get('externalId')
    dispatch(sendFireInsurance(externalId, id, { agreed: contactInsurance }))
  }, [contactInsurance, dispatch, id, lease])

  useEffect(() => {
    if (!isLoading && wasLoading && !fireInsuranceError) {
      navigate(`/minhas-negociacoes/${id}/revisao`)
    }
  }, [fireInsuranceError, id, isLoading, navigate, wasLoading])

  const fireInsurancePrice = useMemo(
    () =>
      lease && lease.fireInsurancePrice !== null
        ? `${toCurrency(lease.fireInsurancePrice)}/mês`
        : 'Contratar',
    [lease]
  )

  return (
    <section className={styles.container}>
      <h1 className={styles.title}>Seguro Incêndio</h1>
      <p className={styles.description}>
        Em nosso contrato é expressa a obrigatoriedade da contratação de seguro incêndio pelo
        inquilino.
      </p>
      <h2 className={styles.subtitle}>Vantagens para você</h2>
      <p className={styles.description}>
        A nossa imobiliária tem parceria com a <strong>Porto Seguro</strong> que disponibiliza a
        melhor experiência e qualidade 100% digital.
      </p>
      <p className={styles.description}>Para o seu imóvel, conseguimos essa oferta.</p>
      <div className={styles.insurance}>
        <svg aria-hidden="true" className={styles['insurance-logo']}>
          <use xlinkHref={logoPorto} />
        </svg>
        <p className={styles['insurance-value']}>{fireInsurancePrice}</p>
        <span className={styles['insurance-type']}>
          Seguro incêndio da <strong>Porto Seguro</strong>
        </span>
      </div>
      <h2 className={styles.title}>Garanta o seu seguro</h2>
      <p className={styles.description}>
        Selecione a opção <strong>&quot;contratar seguro incêndio da Porto Seguro&quot;</strong> e
        você passará por uma análise. Entraremos em contato para informar o resultado.
      </p>
      <RadioGroup className={styles.choices} value={contactInsurance} onChange={handleInsurance}>
        <div className={styles['choices-card']}>
          <FormControlLabel
            value
            control={<Radio color="primary" />}
            label={
              <p className={styles['choices-card-text']}>
                <strong>Contratar</strong> seguro incêndio da Porto Seguro <br />
                <span className={styles['choices-card-obs']}>(sujeito a análise)</span>
              </p>
            }
            defaultChecked
          />
        </div>
        <div className={styles['choices-card']}>
          <FormControlLabel
            value={false}
            control={<Radio color="primary" />}
            label={
              <p className={styles['choices-card-text']}>
                <strong>Não contratar</strong> seguro incêndio da Porto Seguro*
                <br />
                <span className={styles['choices-card-obs']}>(contratar por fora)</span>
              </p>
            }
          />
        </div>
      </RadioGroup>
      <span className={styles.observation}>
        *Você deverá fazer a contratação de um seguro incêndio por outra seguradora.
      </span>
      <Button
        color={ButtonColor.PRIMARY}
        variant={ButtonVariant.BOLD}
        format={ButtonFormat.ROUNDED}
        className={styles.button}
        onClick={onConfirmClick}
        isLoading={isLoading}
      >
        CONFIRMAR
      </Button>
    </section>
  )
}

export default withAuth(React.memo(FireInsurance))

import React from 'react'
import { Link } from '@reach/router'

import ArrowIcon from '_assets/images/angle-left-solid.svg'
import HourglassSvg from '_components/icon-components/hourglass-icon'
import { primaryColor } from '_utils/colors'
import { GENERAL } from '_utils/constants'

import styles from './styles.css'

const WaitingNegotiationApproval = () => (
  <div className={styles.main}>
    <div className={styles.content}>
      <HourglassSvg className={styles.icon} primaryColor={primaryColor} />
      <h1>Oba, estamos quase lá!</h1>
      <p>
        Se os documentos estiverem corretos, o contrato logo estará pronto e você poderá conferir no
        menu em <span className={styles.negociations}>Negociações</span>.
      </p>
      <Link to="/" className={styles['return-link']}>
        <svg aria-hidden="true" className={styles.icon}>
          <use xlinkHref={ArrowIcon} />
        </svg>
        VOLTAR PARA PÁGINA INICIAL
      </Link>
      <div className={styles.information}>
        <p>
          Você também receberá um e-mail com mais informações. Em caso de dúvidas, entre em contato
          com{' '}
          <a href={`mailto:${GENERAL.email}`} className={styles.email}>
            {GENERAL.email}
          </a>
        </p>
      </div>
    </div>
  </div>
)

export default React.memo(WaitingNegotiationApproval)

import React, { Fragment, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Link } from '@reach/router'
import { Map } from 'immutable'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import memoizeOne from 'memoize-one'
import { useDispatch } from 'react-redux'

import { RedirectContext } from '_context/redirect'
import ReturnIcon from '_assets/images/angle-left-solid.svg'
import ShareIcon from '_assets/images/ic-compartilhar.svg'
import NovaFreitasLogo from '_assets/images/novafreitas_logo.svg'
import { closeCookiesModal } from '_modules/modal/actions'
import { logout } from '_modules/user/actions'
import { onMouseDown } from '_utils/helpers'
import { primaryColor } from '_utils/colors'
import { sendEvent } from '_utils/mixpanel'
import NavbarIcon from '_components/icon-components/navbar-icon'
import HeartIcon from '_assets/images/menu-heart.svg'
import GoBackIcon from '_assets/images/ic-voltar.svg'
import { GENERAL } from '_utils/constants'
import { usePrevious } from '_utils/hooks'

import styles from './styles.css'
import NavigationMenu from './navigation-menu'
import AccountModal from './account-modal'

export const HeaderTheme = {
  DEFAULT: 'default-theme',
  TRANSPARENT: 'transparent-theme',
  SCHEDULE: 'schedule-theme',
  PRIVACY: 'privacy-theme',
  NONE: 'none-theme',
  VISITS: 'visits-theme',
}

const createHeaderStyle = memoizeOne(brandHeaderLogoHeight => ({
  height: `${brandHeaderLogoHeight}px`,
}))

const Header = ({ theme, goBack, isLoggedIn, menuTitle, isLogging, loggingError }) => {
  const dispatch = useDispatch()

  const [haveScrolled, setHaveScrolled] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const { openLogin, setOpenLogin, setRedirectUri } = useContext(RedirectContext)

  const logoutFunc = useCallback(() => {
    setRedirectUri(null)
    dispatch(logout())
  }, [dispatch, setRedirectUri])

  const dropdownOptions = useMemo(
    () =>
      [
        {
          name: 'Favoritos',
          icon: HeartIcon,
          path: '/favoritos',
          isHidden: false,
        },
        // {
        //   name: 'Blog',
        //   path: GENERAL.blogUrl,
        //   onClick: () => { },
        //   isHidden: false,
        //   targetBlank: true,
        // },
        {
          name: 'Minhas visitas',
          path: '/minhas-visitas',
          isHidden: false,
        },
        {
          name: 'Negociações',
          path: '/minhas-negociacoes',
          isHidden: false,
        },
        {
          name: 'Anunciar Imóvel',
          path: GENERAL.landlordUrl,
          onClick: () => {},
          isHidden: false,
          targetBlank: true,
          hasDividerBefore: true,
        },
        {
          name: 'Sair',
          path: '/',
          onClick: logoutFunc,
          hasDividerBefore: true,
          isHidden: !isLoggedIn,
        },
      ].filter(option => !option.isHidden),
    [isLoggedIn, logoutFunc]
  )

  const closeMenu = useCallback(() => {
    setIsMenuOpen(false)
  }, [])

  const prevIsMenuOpen = usePrevious(isMenuOpen)
  const prevIsLogging = usePrevious(isLogging)

  useEffect(() => {
    if (isMenuOpen && !prevIsMenuOpen) {
      document.addEventListener('click', closeMenu)
    } else if (!isMenuOpen && prevIsMenuOpen) {
      document.removeEventListener('click', closeMenu)
    }

    if (prevIsLogging && !isLogging && loggingError.size === 0) {
      setOpenLogin(false)
    }
  }, [
    closeMenu,
    isLogging,
    isMenuOpen,
    loggingError.size,
    prevIsLogging,
    prevIsMenuOpen,
    setOpenLogin,
  ])

  useEffect(() => {
    document.removeEventListener('click', closeMenu)
  }, [closeMenu])

  const onLogoClick = useCallback(() => {
    sendEvent('Cabeçalho: Selecionou o botão da Logo para retornar para página inicial')
  }, [])

  const onReturnHomeClick = useCallback(() => {
    sendEvent('Cabeçalho: Selecionou o botão seta para retornar para página inicial')
  }, [])

  const onOpenAccountModal = useCallback(
    href => {
      setRedirectUri(href)
      setOpenLogin(true)
    },
    [setOpenLogin, setRedirectUri]
  )

  const onCloseAccountModal = useCallback(() => {
    setOpenLogin(false)
    dispatch(closeCookiesModal())
  }, [dispatch, setOpenLogin])

  const listenScrollEvent = useCallback(() => {
    if (window.scrollY > 1) {
      setHaveScrolled(true)
    } else {
      setHaveScrolled(false)
    }
  }, [])

  const openMenu = useCallback(() => {
    setIsMenuOpen(true)
  }, [])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', listenScrollEvent)
    }

    document.removeEventListener('click', closeMenu)
  }, [closeMenu, listenScrollEvent])

  return (
    <Fragment>
      <header
        className={classnames(styles.header, {
          [styles['header-with-shadow']]: haveScrolled && theme !== HeaderTheme.TRANSPARENT,
        })}
        style={createHeaderStyle(GENERAL.brandHeaderLogoHeight + 20)}
      >
        <div
          className={classnames(styles.wrapper, styles[theme], {
            [styles['menu-open']]: isMenuOpen,
          })}
        >
          <button
            onClick={goBack}
            aria-label="voltar"
            className={styles.action}
            type="button"
            onMouseDown={onMouseDown}
          >
            <svg aria-hidden="true" className={styles.arrow}>
              <use xlinkHref={GoBackIcon} />
            </svg>
          </button>
          <Link
            to="/"
            className={styles['back-home-button']}
            aria-label="Voltar para página inicial"
            onClick={onReturnHomeClick}
          >
            <svg aria-hidden="true" className={styles['back-home-icon']} focusable="false">
              <use xlinkHref={ReturnIcon} />
            </svg>
            <p className={styles['back-home-text']}>VOLTAR</p>
          </Link>
          <Link
            className={styles.logo}
            aria-label="Voltar para página inicial"
            to="/"
            onClick={onLogoClick}
          >
            <svg aria-hidden="true" className={styles['logo-svg']}>
              <use xlinkHref={NovaFreitasLogo} />
            </svg>
          </Link>
          <p className={styles.schedule}>{menuTitle}</p>
          <svg aria-hidden="true" className={styles.share}>
            <use xlinkHref={ShareIcon} />
          </svg>
          <button
            type="button"
            aria-label="abrir menu"
            onClick={openMenu}
            onMouseDown={onMouseDown}
            className={classnames(styles['menu-button'], {
              [styles['menu-button-open']]: isMenuOpen,
            })}
          >
            <NavbarIcon isOpen={isMenuOpen} />
          </button>
        </div>
        <NavigationMenu
          options={dropdownOptions}
          isMenuOpen={isMenuOpen}
          isLoggedIn={isLoggedIn}
          onOpenAccountModal={onOpenAccountModal}
          className={styles['hamburger-menu']}
        />
      </header>
      {openLogin && <AccountModal onClose={onCloseAccountModal} primaryColor={primaryColor} />}
    </Fragment>
  )
}

Header.propTypes = {
  theme: PropTypes.oneOf(Object.keys(HeaderTheme).map(key => HeaderTheme[key])),
  goBack: PropTypes.func,
  isLoggedIn: PropTypes.bool,
  isLogging: PropTypes.bool,
  loggingError: PropTypes.instanceOf(Map),
  menuTitle: PropTypes.string,
}

Header.defaultProps = {
  theme: HeaderTheme.DEFAULT,
  goBack: () => {},
  isLoggedIn: false,
  isLogging: false,
  loggingError: Map(),
  menuTitle: null,
}

export default React.memo(Header)

import React, { useCallback } from 'react'
import classnames from 'classnames'
import { useFormContext } from 'react-hook-form'
import PropTypes from 'prop-types'

import Plus from '_assets/images/ic-plus-slim.svg'

import styles from './styles.css'

const InputControls = ({ disabled, name, maxValue, minValue, valuePace }) => {
  const { setValue, getValues } = useFormContext()
  const value = getValues()[name]

  const handleIncrementValue = useCallback(() => {
    if (value >= maxValue) {
      setValue(name, maxValue)
    } else {
      setValue(name, String(Number(value) + valuePace))
    }
  }, [maxValue, name, setValue, value, valuePace])

  const handleDecrementValue = useCallback(() => {
    if (value <= minValue) {
      setValue(name, minValue)
    } else {
      setValue(name, String(Number(value) - valuePace))
    }
  }, [minValue, name, setValue, value, valuePace])

  return (
    <div className={styles.controls}>
      <button
        className={styles['controls-button']}
        type="button"
        onClick={handleDecrementValue}
        disabled={disabled}
        aria-label="Diminuir o valor"
      >
        <div
          className={classnames(styles['controls-less'], {
            [styles['icon-disabled']]: disabled,
          })}
          aria-hidden="true"
        />
      </button>
      <button
        className={styles['controls-button']}
        type="button"
        onClick={handleIncrementValue}
        disabled={disabled}
        aria-label="Aumentar o valor"
      >
        <svg
          className={classnames(styles['controls-icon'], { [styles.disabled]: disabled })}
          aria-hidden="true"
        >
          <use xlinkHref={Plus} />
        </svg>
      </button>
    </div>
  )
}

InputControls.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.string,
  maxValue: PropTypes.number,
  minValue: PropTypes.number,
  valuePace: PropTypes.number,
}

InputControls.defaultProps = {
  disabled: false,
  name: '',
  maxValue: 0,
  minValue: 0,
  valuePace: 1,
}

export default React.memo(InputControls)

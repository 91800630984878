import React, { useState, useCallback, useRef, useMemo, useEffect } from 'react'
import { useFormContext, ErrorMessage } from 'react-hook-form'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { RHFInput } from 'react-hook-form-input'
import InputMask from 'react-input-mask'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import InputAdornment from '@material-ui/core/InputAdornment'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import FormHelperText from '@material-ui/core/FormHelperText'

import InputControls from './input-control'
import styles from './styles.css'

const OutlinedField = ({
  label,
  placeholder,
  type,
  className,
  name,
  rules,
  mask,
  endAdornment,
  showInputControls,
  valuePace,
  maxValue,
  minValue,
  disabled,
  autoComplete,
}) => {
  const [labelWidth, setLabelWidth] = useState(0)
  const { register, setValue, errors } = useFormContext()
  const labelRef = useRef(null)

  const [error, setError] = useState(!!errors[name])

  const onChangeInputValue = useCallback(
    event => {
      if (rules && rules.required) {
        setError(!Number(event.target.value))
      }
    },
    [rules]
  )

  useEffect(() => {
    if (!!errors[name] === true) {
      setError(!!errors[name])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors])

  useEffect(() => {
    setLabelWidth(labelRef.current.offsetWidth)
  }, [])

  const EndAdornmentComponent = useMemo(
    () =>
      endAdornment && (
        <InputAdornment
          className={classnames(styles.adornment, { [styles['adornment-disabled']]: disabled })}
          disableTypography
          disablePointerEvents
          position="end"
        >
          {endAdornment}
        </InputAdornment>
      ),
    [disabled, endAdornment]
  )

  const inputStyles = useMemo(
    () => ({
      root: styles['input-outlined'],
      notchedOutline: styles.notch,
    }),
    []
  )

  const inputProps = useMemo(
    () => ({
      disabled,
      classes: inputStyles,
      id: name,
      type,
      placeholder,
      labelWidth,
      endAdornment: EndAdornmentComponent,
      'aria-invalid': errors.name ? 'true' : 'false',
      'aria-describedby': `${name}-error`,
      inputProps: {
        autocomplete: autoComplete,
        min: minValue,
      },
    }),
    [
      EndAdornmentComponent,
      autoComplete,
      disabled,
      errors.name,
      inputStyles,
      labelWidth,
      minValue,
      name,
      placeholder,
      type,
    ]
  )

  const InputComponent = useMemo(
    () =>
      mask ? (
        <InputMask mask={mask} disabled={disabled}>
          {inheritedProps => <OutlinedInput {...inheritedProps} {...inputProps} error={error} />}
        </InputMask>
      ) : (
        <OutlinedInput {...inputProps} error={error} />
      ),
    [disabled, error, inputProps, mask]
  )

  return (
    <FormControl
      className={classnames(
        styles['input-container'],
        {
          [styles['grid-container']]: showInputControls && type === 'number',
        },
        className
      )}
      variant="outlined"
    >
      <InputLabel error={error} className={styles['input-label']} ref={labelRef} htmlFor={name}>
        {label}
      </InputLabel>
      <RHFInput
        as={InputComponent}
        rules={rules}
        name={name}
        register={register}
        setValue={setValue}
        onChange={onChangeInputValue}
      />
      <input name="chrome-autofill-dummy1" style={{ display: 'none' }} disabled />
      {showInputControls && (
        <InputControls
          disabled={disabled}
          name={name}
          maxValue={maxValue}
          minValue={minValue}
          valuePace={valuePace}
        />
      )}

      {errors[name] && !errors[name].ref.name.includes('parkingSpotsNumbers') && (
        <FormHelperText
          component="span"
          id={`${name}-error`}
          className={styles['input-error-message']}
        >
          <ErrorMessage name={name} />
        </FormHelperText>
      )}
    </FormControl>
  )
}

OutlinedField.propTypes = {
  placeholder: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  mask: PropTypes.string,
  endAdornment: PropTypes.string,
  showInputControls: PropTypes.bool,
  rules: PropTypes.shape({
    required: PropTypes.string,
    minLength: PropTypes.shape({
      value: PropTypes.number,
      message: PropTypes.string,
    }),
    pattern: PropTypes.shape({
      value: PropTypes.instanceOf(RegExp),
      message: PropTypes.string,
    }),
  }),
  valuePace: PropTypes.number,
  maxValue: PropTypes.number,
  minValue: PropTypes.number,
  disabled: PropTypes.bool,
  autoComplete: PropTypes.string,
}

OutlinedField.defaultProps = {
  placeholder: '',
  label: '',
  type: 'text',
  className: '',
  name: '',
  mask: '',
  endAdornment: '',
  showInputControls: false,
  rules: {},
  valuePace: 10,
  maxValue: 290,
  minValue: 40,
  disabled: false,
  autoComplete: 'off',
}

export default React.memo(OutlinedField)

import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ breakpoints, palette }) => ({
  main: {
    color: palette.text.primary,
    padding: '6.8rem 0 4.8rem',
    textAlign: 'left',
  },
  content: {
    paddingLeft: '1rem',
  },
  titleContainer: {
    display: 'flex',
    paddingBottom: '0.8rem',
  },
  icon: {
    marginRight: '1.8rem',
    width: '2rem',
    height: '2.2rem',
  },
  title: {
    color: palette.text.primary,
    fontSize: '1.6rem',
    fontWeight: '500',
    lineHeight: '2.4rem',
    padding: '0 0.8rem 0.8rem',
  },
  subContentContainer: {
    paddingBottom: '1.8rem',
    marginLeft: '2.5rem',
  },
  subContentText: {
    fontSize: '1.4rem',
    lineHeight: '1.6rem',
    color: palette.text.secondary,
    fontWeight: 'normal',
    paddingBottom: '0.8rem',
  },
  information: {
    fontSize: '1.2rem',
    fontWeight: 'normal',
    color: palette.gray.dusty,
  },
  addressContainer: {
    display: 'flex',
  },
  address: {
    fontSize: '1.4rem',
    lineHeight: '1.8rem',
    textAlign: 'left',
    ccolor: palette.text.secondary,
    fontWeight: 'normal',
    marginBottom: '2rem',
    marginLeft: '2.5rem',
  },
  fullAddress: {
    color: palette.text.secondary,
    textDecoration: 'none',

    [breakpoints.down('sm')]: {
      color: palette.info.email,
      textDecoration: 'underline',
      textDecorationColor: palette.info.email,
    },
  },
  addressBold: {
    fontWeight: 'bold',
    fontSize: '1.4rem',
    lineHeight: '1.8rem',
  },
  addToCalendarSection: {
    marginTop: '2.5em auto 0',
    boxSizing: 'border-box',
    '& .calendar-icon': {
      width: '0.875rem',
      height: '0.875rem',
      marginRight: '0.93rem',
    },
  },
  addToCalendarRoot: {
    position: 'relative',
    boxSizing: 'border-box',
    fontSize: '1.2rem',
    lineHeight: '1.6rem',
    fontWeight: 'normal',
  },

  addToCalendar: {
    display: 'flex',
    justifyContent: 'center',

    '& a': {
      borderRadius: '0.25rem',
      whiteSpace: 'nowrap',
      color: palette.text.primary,
      padding: '0.813rem',
      width: 'fit-content',
      margin: 'auto',
      border: `0.1rem solid ${palette.text.disabled}`,
      backgroundColor: palette.gray.gallery,
      fontSize: '1.4rem',
      fontWeight: '500',
      cursor: 'pointer',
    },
  },

  dropdown: {
    position: 'absolute',
    transform: 'translateX(-50%)',
    left: '50%',
    top: 'calc(100% + 1rem)',
    width: '18rem',
    backgroundColor: 'white',
    borderRadius: '0.25rem',
    boxShadow: '0 0 0.5rem rgba(0, 0, 0, 0.25)',
    textAlign: 'left',
    padding: '0.5rem 0',

    '& > ul > li': {
      padding: '0.625rem 1.625rem',
      backgroundColor: 'white',
      transition: 'background-color 0.2s',

      '&:hover': {
        backgroundColor: palette.text.disabled,
      },
      '&:last-child': {
        marginBottom: '0',
      },
    },

    '& > ul > li > a': {
      textDecoration: 'none',
      color: palette.text.primary,
      width: '100%',
      display: 'block',
    },
  },
}))

import React, { PureComponent, createRef } from 'react'

import TermsAndPrivacy from '_components/terms-and-privacy'
import { GENERAL } from '_utils/constants'
import { formatDate } from '_utils/strings'

import {
  CONTRACT,
  SERVICES,
  ACCOUNT,
  FEES,
  TERMINATION,
  INTELECTUAL_PROPERTY_RIGHTS,
  RESPONSABILITY_EXEMPTION,
  getGeneralProvisions,
} from './constants'

class TermsOfUse extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      isStickyHidden: false,
    }

    this.contractSectionRef = createRef()
    this.servicesSectionRef = createRef()
    this.accountSectionRef = createRef()
    this.feesSectionRef = createRef()
    this.terminationSectionRef = createRef()
    this.intelectualRightsSectionRef = createRef()
    this.responsabilityExemptionSectionRef = createRef()
    this.generalProvisionsSectionRef = createRef()
  }

  hideSticky = () => {
    this.setState({ isStickyHidden: true })
  }

  showSticky = () => {
    this.setState({ isStickyHidden: false })
  }

  render() {
    const { isStickyHidden } = this.state

    const navigationItems = [
      [`Contrato ${GENERAL.realEstateAgency}`, this.contractSectionRef],
      [`Serviços da ${GENERAL.realEstateAgency}`, this.servicesSectionRef],
      [`Conta da ${GENERAL.realEstateAgency}`, this.accountSectionRef],
      ['Taxas', this.feesSectionRef],
      ['Terminação', this.terminationSectionRef],
      ['Direito da Propriedade Intelectual', this.intelectualRightsSectionRef],
      [
        'Isenções de Responsabilidade e Limitação da Responsabilidade da Garantia',
        this.responsabilityExemptionSectionRef,
      ],
      ['General Provisions', this.generalProvisionsSectionRef],
    ]
    const sectionsArray = [
      [
        `Contrato ${GENERAL.realEstateAgency}`,
        false,
        CONTRACT(GENERAL.realEstateAgency),
        this.contractSectionRef,
      ],
      [
        `Serviços da ${GENERAL.realEstateAgency}`,
        true,
        SERVICES(GENERAL.realEstateAgency, GENERAL.baseUrl),
        this.servicesSectionRef,
      ],
      [`Conta da ${GENERAL.realEstateAgency}`, true, ACCOUNT, this.accountSectionRef],
      ['Taxas', true, FEES(GENERAL.realEstateAgency), this.feesSectionRef],
      ['Terminação', true, TERMINATION, this.terminationSectionRef],
      [
        'Direito da Propriedade Intelectual',
        true,
        INTELECTUAL_PROPERTY_RIGHTS,
        this.intelectualRightsSectionRef,
      ],
      [
        'Isenções de Responsabilidade e Limitação da Responsabilidade da Garantia',
        true,
        RESPONSABILITY_EXEMPTION,
        this.responsabilityExemptionSectionRef,
      ],
      [
        'General Provisions',
        true,
        getGeneralProvisions(this.hideSticky, this.showSticky, GENERAL.baseUrl, GENERAL.email),
        this.generalProvisionsSectionRef,
      ],
    ]

    return (
      <TermsAndPrivacy
        navigationItems={navigationItems}
        title="Termos de Serviço"
        update={`Última atualização em ${formatDate(GENERAL.privacyPolicyUpdateDate)}.`}
        sectionsArray={sectionsArray}
        isStickyHidden={isStickyHidden}
        view="Termos de Serviço"
      />
    )
  }
}

export default TermsOfUse

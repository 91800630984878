import React, { useState, useEffect, useCallback, Fragment, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, Redirect, useParams, useLocation } from '@reach/router'
import qs from 'qs'

import Button, { ButtonVariant, ButtonFormat, ButtonColor } from '_components/button'
import ChangeContract from '_components/modals/change-contract'
import {
  getLease,
  generateContractLink,
  generateContractLinkIncomeHelper,
} from '_modules/leases/actions'
import {
  getLeaseById,
  getContractByIdSelector,
  checkIsLoadingLease,
  checkIsLoadingContractLink,
} from '_modules/leases/selectors'
import { usePrevious } from '_utils/hooks'
import useModal from '_hooks/use-modal'
import { LEASE_STATUS } from '_utils/constants'
import { getLeaseStatus } from '_utils/lease'

import PDFViewer from './pdf-viewer'
import styles from './styles.css'

const PDFPreview = () => {
  const { leaseId, contractId } = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const contract = useSelector(state => getContractByIdSelector(state, [leaseId, contractId]))
  const parsedQuery = useMemo(() => qs.parse((location.search || '').split('?').pop()), [
    location.search,
  ])
  const lease = useSelector(state => getLeaseById(state, leaseId))
  const isLoadingLease = useSelector(checkIsLoadingLease)
  const isLoadingContractLink = useSelector(checkIsLoadingContractLink)

  const { modalOpen: isModalOpen, handleModal: onToggleModalClick } = useModal()
  const [isLoadingDocuSign, setLoadingDocuSignStatus] = useState(false)

  const wasLoadingLease = usePrevious(isLoadingLease)

  const onChangeContractClick = useCallback(() => {
    navigate(`/minhas-negociacoes/${leaseId}/contrato/${contractId}/alteracao-contrato`)
  }, [contractId, leaseId, navigate])

  const onSignClick = useCallback(() => {
    if (lease) {
      setLoadingDocuSignStatus(true)
      if (parsedQuery.token) {
        dispatch(
          generateContractLinkIncomeHelper({
            leaseId,
            contractId,
            externalId: lease.listing.get('externalId'),
            token: parsedQuery.token,
          })
        )
          .then(response => {
            window.location.href = response.value.signing_url
          })
          .catch(err => {
            console.info(err)
            setLoadingDocuSignStatus(false)
          })
      } else {
        dispatch(
          generateContractLink({
            leaseId,
            contractId,
            externalId: lease.listing.get('externalId'),
          })
        )
          .then(response => {
            window.location.href = response.value.signing_url
          })
          .catch(err => {
            console.info(err)
            setLoadingDocuSignStatus(false)
          })
      }
    }
  }, [lease, parsedQuery.token, dispatch, leaseId, contractId])

  useEffect(() => {
    const params = {}
    if (parsedQuery.token) {
      params.token = parsedQuery.token
      dispatch(getLease(leaseId, params))
    } else if (leaseId) {
      dispatch(getLease(leaseId))
    }
  }, [dispatch, leaseId, parsedQuery.token])

  useEffect(() => {
    if (wasLoadingLease && !isLoadingLease) {
      if (!lease) {
        navigate('/minhas-negociacoes')
      }
    }
  }, [isLoadingLease, lease, navigate, wasLoadingLease])

  if (!lease) {
    // TODO: loading state
    return null
  }

  if (
    !isLoadingLease &&
    wasLoadingLease &&
    getLeaseStatus(lease.status) !== LEASE_STATUS.CONTRACT_READY
  ) {
    return <Redirect to="minhas-negociacoes" noThrow />
  }

  const isLoading = isLoadingLease || isLoadingContractLink || isLoadingDocuSign

  return (
    <Fragment>
      <div className={styles.container}>
        <h1 className={styles.title}>Contrato</h1>
        <div className={styles['contract-container']}>
          {contract && contract.get('contract') && <PDFViewer file={contract.get('contract')} />}
        </div>
        <div className={styles.actions}>
          {!parsedQuery.token && (
            <Button
              variant={ButtonVariant.BOLD}
              format={ButtonFormat.ROUNDED}
              color={ButtonColor.SECONDARY}
              onClick={onToggleModalClick}
              isLoading={isLoadingLease}
              disabled={isLoading}
            >
              SOLICITAR ALTERAÇÃO
            </Button>
          )}
          <Button
            variant={ButtonVariant.BOLD}
            format={ButtonFormat.ROUNDED}
            active
            onClick={onSignClick}
            isLoading={isLoading}
            disabled={isLoading}
          >
            ASSINAR
          </Button>
        </div>
      </div>
      {isModalOpen && (
        <ChangeContract closeModal={onToggleModalClick} changeContract={onChangeContractClick} />
      )}
    </Fragment>
  )
}

export default React.memo(PDFPreview)

export const primaryColor = '#A61B1A'
export const primaryTextColor = '#fff'

export const calcShadowColor = color => `#32${color.replace(/^#+/i, '')}`

export const calcLighterColor = color => `#B3${color.replace(/^#+/i, '')}`

// Convert HEX to RGBA to add opacity
export const shadeColor = (color, percent = 0.6) => {
  let r = 0
  let g = 0
  let b = 0

  // 3 digits
  if (color.length === 4) {
    r = `0x${color[1]}${color[1]}`
    g = `0x${color[2]}${color[2]}`
    b = `0x${color[3]}${color[3]}`

    // 6 digits
  } else if (color.length === 7) {
    r = `0x${color[1]}${color[2]}`
    g = `0x${color[3]}${color[4]}`
    b = `0x${color[5]}${color[6]}`
  }

  return `rgba(${+r},${+g},${+b}, ${percent})`
}

export const primaryColorOpacity = shadeColor(primaryColor, 0.1)
export const primaryColorDarker = shadeColor(primaryColor, 0.8)

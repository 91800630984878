import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ breakpoints, palette }) => ({
  container: {
    color: palette.secondary.dark,
    alignItems: 'center',
    marginBottom: '12rem',
  },

  content: {
    width: '100%',
    maxWidth: '51.2rem',
    padding: '0 2.4rem 2.4rem',
    textAlign: 'center',
    marginTop: '8.8rem',

    [breakpoints.down('sm')]: {
      maxWidth: '51.2rem',
      borderRadius: 'none',
      boxShadow: 'none',
      backgroundColor: 'transparent',
      padding: '0 0.8rem 0.8rem',
      marginTop: '4.8rem',
    },
  },

  icon: {
    width: '14.8rem',
    height: '9.2rem',
    margin: 'auto auto 2.4rem',
    [breakpoints.down('sm')]: {
      maxWidth: '11.7rem',
    },
  },

  title: {
    justifySelf: 'center',
    margin: 'auto auto 4.8rem',
    maxWidth: '33.6rem',
    fontWeight: 'bold',
    [breakpoints.down('sm')]: {
      maxWidth: '25.5rem',
      margin: 'auto auto 3.2rem',
      fontSize: '1.6rem',
    },
  },

  subTitle: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto auto 1.1rem 2.4rem',
    textAlign: 'left',
    [breakpoints.down('sm')]: {
      fontSize: '1.4rem',
    },
  },

  containerItems: {
    textAlign: 'left',
    background: palette.gray.gallery,
    borderRadius: '0.4rem',
    border: `0.1rem solid ${palette.divider}`,
    margin: 'auto 1.2rem 2.4rem',
    [breakpoints.down('sm')]: {
      maxWidth: '31.2rem',
    },
  },

  titleItems: {
    margin: '0',
    padding: '2.4rem 2.4rem 0.8rem',
    color: palette.secondary.dark,
    borderBottom: `0.1rem solid ${palette.secondary.light}`,
    [breakpoints.down('sm')]: {
      fontSize: '1.4rem',
    },
  },

  comment: {
    padding: '2.4rem 2.4rem 3.2rem',
    color: palette.secondary.main,
  },

  button: {
    margin: '0.8rem auto 1.2rem',
    padding: '1rem 4.8rem',
    [breakpoints.down('sm')]: {
      padding: '1rem 3.8rem',
    },
  },

  buttonSecondary: {
    fontWeight: 'bold',
    maxWidth: '24.5rem',
    width: '100%',
    margin: '4rem auto',
    color: palette.secondary.main,
    display: 'flex',
    flexDirection: 'column',
  },

  building: {
    display: 'flex',
    height: '18rem',
    margin: '20rem auto',
  },
}))

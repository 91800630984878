import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
  wrapper: {
    maxWidth: '51.2rem',
    margin: 'auto',
    boxSizing: 'content-box',
    padding: '8rem 2.4rem 2.4rem',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '4rem',
    },
  },
  formWrapper: {
    padding: '3.2rem 1.6rem',
    [theme.breakpoints.down('sm')]: {
      padding: '2.4rem 1.6rem',
    },
  },
  participant: {
    margin: '0 -1.6rem',
  },
  first: {
    borderTop: `0.1rem solid ${theme.palette.gray.gallery}`,
  },
  addInformation: {
    gap: '1.6rem',
    width: '100%',
    display: 'grid',
    alignItems: 'center',
    gridAutoFlow: 'column',
    justifyContent: 'flex-start',
  },
  infoTitle: {
    marginBottom: '0.8rem',
  },
  description: {
    marginBottom: '0.8rem',
  },
  addParticipantWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  addButton: {
    color: theme.palette.text.primary,
    [theme.breakpoints.down('md')]: {
      margin: '0.8rem 0',
    },
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    color: 'white',
    height: '4rem',
    width: '14.4rem',
    fontSize: '1.4rem',
    marginTop: '2.4rem',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
}))

export const API_URL = process.env.REACT_APP_API_URL || 'https://api.cliqueealugue.com.br/api/v1/'
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN
export const SENTRY_ENV = process.env.REACT_APP_SENTRY_ENV
export const API_KEY = process.env.REACT_APP_API_KEY
export const AUTH_DOMAIN = process.env.REACT_APP_AUTH_DOMAIN
export const DATABASE_URL = process.env.REACT_APP_DATABASE_URL
export const PROJECT_ID = process.env.REACT_APP_PROJECT_ID
export const MESSAGING_SENDER_ID = process.env.REACT_APP_MESSAGING_SENDER_ID
export const APP_ID = process.env.REACT_APP_ID
export const MEASUREMENT_ID = process.env.REACT_MEASUREMENT_ID
export const FIREBASE_KEY =
  process.env.REACT_APP_FIREBASE_KEY || 'AIzaSyAbkKSGkQ38dv6dx0X5qihBCfIhKOg20l4'
export const GA_ID = process.env.REACT_APP_GA_ID || 'UA-143740986-13'
export const GOOGLE_MAPS_KEY =
  process.env.REACT_APP_GOOGLE_MAPS_API_KEY || 'AIzaSyDnnnWWeCSXnDHW-OcDtud98_BFS3-WZQ8'
export const GOOGLE_TAG_MANAGER_ID = process.env.REACT_APP_GTM_ID || 'GTM-K3HCV67'
export const FACEBOOK_ID = process.env.REACT_APP_FACEBOOK_APP_ID || '1492713004204659'
export const MIXPANEL_ID = process.env.REACT_APP_MIXPANEL_ID
export const BITRIX_ID = process.env.REACT_APP_BLIP_CHAT_API_KEY
export const HERE_API_ID = process.env.REACT_APP_HERE_API_ID || 'ZzPwcK5FaH4IMO8VpyWm'
export const HERE_KEY =
  process.env.REACT_APP_HERE_API_CODE || 'JvRAB53cTozSfZzE2-y5pV3lhZxJg5Muw0x6mhLacUA'

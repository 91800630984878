import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import CarouselPaging from '_components/modal-pictures-full-screen/full-screen-mobile-carousel/carousel/carousel-paging'

class Carousel extends PureComponent {
  static propTypes = {
    settings: PropTypes.shape(),
    children: PropTypes.node.isRequired,
    onPageChange: PropTypes.func,
    shouldHidePagingOnDesktop: PropTypes.bool,
    slideIndex: PropTypes.number,
  }

  static defaultProps = {
    settings: {
      dots: false,
      infinite: true,
      speed: 350,
      slidesToShow: 1,
    },
    onPageChange: () => {},
    shouldHidePagingOnDesktop: false,
    slideIndex: 0,
  }

  state = {
    selectedItem: 0,
  }

  componentDidMount() {
    this.container.addEventListener('touchstart', this.touchStart)
    this.container.addEventListener('touchmove', this.preventTouch, { passive: false })
  }

  componentDidUpdate(prevProps, prevState) {
    const { slideIndex } = this.props

    if (slideIndex >= 0 && prevProps.slideIndex !== slideIndex) {
      this.slider.slickGoTo(slideIndex)
    }
    if (prevState.selectedItem !== this.state.selectedItem) {
      this.props.onPageChange(this.state.selectedItem)
    }
  }

  componentWillUnmount() {
    this.container.removeEventListener('touchstart', this.touchStart)
    this.container.removeEventListener('touchmove', this.preventTouch, { passive: false })
  }

  onPictureChange = (oldIndex, newIndex) => {
    this.setState({
      selectedItem: newIndex,
    })
  }

  setSliderRef = ref => {
    this.slider = ref
  }

  getSelectedIndex = () => this.state.selectedItem

  setComponentRef = ref => {
    this.container = ref
  }

  preventTouch = e => {
    const threshold = 5

    const horizontalScroll = Math.abs(e.touches[0].clientX - this.firstClientX)
    // Vertical scrolling does not work when you start swiping horizontally.
    if (horizontalScroll > threshold) {
      e.preventDefault()
      e.returnValue = false
    }

    return false
  }

  touchStart = e => {
    this.firstClientX = e.touches[0].clientX
  }

  render() {
    const { settings, children } = this.props
    const { selectedItem } = this.state
    const enhancedSettings = {
      ...settings,
      beforeChange: this.onPictureChange,
      arrows: true,
    }

    return (
      <div onMouseEnter={this.showArrows} onMouseLeave={this.hideArrows} ref={this.setComponentRef}>
        <Slider ref={this.setSliderRef} {...enhancedSettings}>
          {children}
        </Slider>
        <CarouselPaging
          numberOfItems={children.size || children.length}
          selectedItem={selectedItem}
        />
      </div>
    )
  }
}

export default Carousel

import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { CookiesProvider, Cookies } from 'react-cookie'
import ReactGA from 'react-ga'
import TagManager from 'react-gtm-module'
import { Map } from 'immutable'
import axios from 'axios'
import { ThemeProvider, StylesProvider } from '@material-ui/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import app from 'firebase/app'

import 'firebase/auth'
import { User, Property } from '_models'
import {
  API_URL,
  GA_ID,
  GOOGLE_TAG_MANAGER_ID,
  FIREBASE_KEY,
  MIXPANEL_ID,
} from '_config/environment'
import { INITIAL_STATE as modalInitialState } from '_modules/modal/reducer'
import SentryLogger from '_components/sentry'
import { initSentry, registerSentryUser } from '_utils/sentry'
import { theme } from '_utils/material-ui'

import './bootstrap'
import configureStore from './store/configure-store'
import Router from './router-with-tracker'

const cookies = new Cookies()

const getInitialState = () => {
  const initialState = {
    user: new User({
      ...cookies.get('user'),
      authToken: cookies.get('authToken'),
      likedProperties: cookies.get('likedProperties'),
      dislikedProperties: cookies.get('dislikedProperties'),
      likedOpened: Boolean(cookies.get('likedOpened')),
      dislikedOpened: Boolean(cookies.get('dislikedOpened')),
      clearFilterOpened: Boolean(cookies.get('clearFilterOpened')),
    }),
    modal: modalInitialState.set('showCookiesModal', !cookies.get('showCookiesModal')),
  }

  // eslint-disable-next-line no-underscore-dangle
  const serverState = window.__INITIAL_STATE__ || {}

  if (serverState.properties) {
    const { properties } = serverState
    initialState.properties = Map(
      Object.keys(properties).reduce(
        (prevVal, key) => ({
          ...prevVal,
          [key]: new Property(properties[key]),
        }),
        {}
      )
    )
  }

  return initialState
}

const root = () => {
  const initialState = getInitialState()

  // Set Mixpanel on window
  window.MIXPANEL_ID = MIXPANEL_ID

  // Set Google analytics id on window
  window.GA_ID = GA_ID

  // Set the default axios baseURL
  axios.defaults.baseURL = API_URL

  // Initialize firebase
  app.initializeApp({
    apiKey: FIREBASE_KEY,
  })
  app.auth()

  // Configure Store
  const store = configureStore(initialState)

  // Google Analytics initialize
  if (GA_ID) {
    ReactGA.initialize(GA_ID)
  }

  // Google Tag Manager initialize
  if (GOOGLE_TAG_MANAGER_ID) {
    const tagManagerArgs = {
      gtmId: GOOGLE_TAG_MANAGER_ID,
    }

    TagManager.initialize(tagManagerArgs)
  }

  // Mix Panel initialize
  if (MIXPANEL_ID) {
    window.mixpanel.init(MIXPANEL_ID)
  }

  // Sentry initialize
  initSentry()
  registerSentryUser(initialState.user.toJS())

  // Remove material UI injected from server-side
  const jssStyles = document.querySelector('#jss-server-side')
  if (jssStyles) {
    jssStyles.parentNode.removeChild(jssStyles)
  }

  render(
    <SentryLogger>
      <Provider store={store}>
        <StylesProvider injectFirst>
          <ThemeProvider theme={theme(initialState)}>
            <CssBaseline />
            <CookiesProvider>
              <Router />
            </CookiesProvider>
          </ThemeProvider>
        </StylesProvider>
      </Provider>
    </SentryLogger>,
    document.getElementById('root')
  )
}

root()

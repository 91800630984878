import React, { useMemo, useCallback, useState } from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import PropTypes from 'prop-types'

import { toCurrency } from '_utils/helpers'
import CheckIcon from '_assets/images/ic-check.svg'
import PlanDetailsModal from '_components/modals/plan-details-modal'
import Button, { ButtonColor, ButtonVariant, ButtonFormat } from '_components/button'
import { planInfoShape } from '_utils/proptypes'
import { primaryColor } from '_utils/colors'

import styles from './styles.css'

const PlanPreview = ({ planInfo, onChooseButton }) => {
  const createSVGStyle = useMemo(() => ({ fill: primaryColor }), [])
  const [isModalOpen, setModalOpen] = useState(false)

  const handleClose = useCallback(() => {
    setModalOpen(prevState => !prevState)
  }, [])

  const onButtonClick = useCallback(() => {
    onChooseButton(planInfo.title)
    setModalOpen(false)
  }, [onChooseButton, planInfo.title])

  const valueFee = useMemo(() => {
    if (planInfo.isPercentage) {
      return planInfo.fee ? `${planInfo.fee}%` : '0%'
    }
    return planInfo.fee ? toCurrency(planInfo.fee) : 'R$ 0'
  }, [planInfo.fee, planInfo.isPercentage])

  return (
    <div className={styles.plan}>
      {planInfo.competitor ? (
        <div className={styles.competitor} />
      ) : (
        <FormControlLabel control={<Radio color="primary" value={planInfo.title} />} />
      )}

      <p className={styles.type}>
        {planInfo.title}
        <br />
        <span className={styles.taxes}>{valueFee}</span>
      </p>
      <div>
        {planInfo.list && (
          <ul className={styles.list}>
            {planInfo.list.map(
              (item, index) =>
                index < 2 && (
                  <li className={styles.item} key={item}>
                    <svg aria-hidden="true" className={styles['check-icon']} style={createSVGStyle}>
                      <use xlinkHref={CheckIcon} />
                    </svg>
                    <p className={styles.description}>{item}</p>
                  </li>
                )
            )}
          </ul>
        )}
        <Button
          color={ButtonColor.TEXT_BLACK}
          variant={ButtonVariant.BOLD}
          format={ButtonFormat.SQUARED}
          className={styles.button}
          onClick={handleClose}
        >
          SAIBA MAIS
        </Button>
        <PlanDetailsModal
          onChooseClick={onButtonClick}
          handleClose={handleClose}
          isOpen={isModalOpen}
          planInfo={planInfo}
        />
      </div>
    </div>
  )
}

PlanPreview.propTypes = {
  planInfo: planInfoShape.isRequired,
  onChooseButton: PropTypes.func.isRequired,
}
export default React.memo(PlanPreview)

import React, { useMemo } from 'react'
import { Link } from '@reach/router'

import ArrowIcon from '_assets/images/angle-left-solid.svg'
import MagnifierIcon from '_assets/images/negotiation-magnifier.svg'
import withAuth from '_hocs/with-auth'
import { primaryColor } from '_utils/colors'

import styles from './styles.css'

const WaitingNegotiationResponse = () => {
  const createSVGStyle = useMemo(() => ({ fill: primaryColor }), [])

  return (
    <div className={styles.main}>
      <div className={styles.content}>
        <svg aria-hidden="true" className={styles.icon} style={createSVGStyle}>
          <use xlinkHref={MagnifierIcon} />
        </svg>
        <h1>Volte logo!</h1>
        <p>Ainda estamos aguardando uma resposta.</p>
        <Link to="/" className={styles['return-link']}>
          <svg aria-hidden="true" className={styles.icon}>
            <use xlinkHref={ArrowIcon} />
          </svg>
          VOLTAR PARA PÁGINA INICIAL
        </Link>
      </div>
    </div>
  )
}

export default withAuth(React.memo(WaitingNegotiationResponse))

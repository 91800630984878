import React, { Fragment } from 'react'

import RegisterSection from './register-section'
import PlansSection from './plans-section'
import PlansContact from './plans-contact'

const OwnerLanding = () => (
  <Fragment>
    <RegisterSection />
    <PlansSection />
    <PlansContact />
  </Fragment>
)

export default React.memo(OwnerLanding)

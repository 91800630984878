import React, { useMemo, useCallback } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { Link } from '@reach/router'

import SlickCarousel from '_components/slick-carousel'
import VisitCard, { VisitCardTheme } from '_views/visit/scheduled-visits/visit-card'
import { LEASE_STATUS, BOOKING_STATUS } from '_utils/constants'
import { getLeaseStatus } from '_utils/lease'
import { primaryColor } from '_utils/colors'

import styles from './styles.css'

const leasesUrl = '/minhas-negociacoes'
const visitsUrl = '/minhas-visitas'

const getLink = (status, id) => {
  const defaultUrlWithLease = `${leasesUrl}/${id}`

  switch (status) {
    case LEASE_STATUS.CONTRACT_READY:
      return `${defaultUrlWithLease}/aprovado`
    case LEASE_STATUS.INCOME_HELPERS_PENDING:
    case LEASE_STATUS.IN_REVIEW:
    case LEASE_STATUS.APPROVED:
    case LEASE_STATUS.PRE_CONTRACT_IN_REVIEW:
      return `${defaultUrlWithLease}/aguardando-resposta`
    case LEASE_STATUS.CONTRACT_SIGNED:
      return `/contrato/${id}/concluido`
    case LEASE_STATUS.PROPOSAL_PENDING:
      return `/proposta/fazer-proposta/${id}`
    case LEASE_STATUS.INSUFFICIENT_INCOME:
      return `${defaultUrlWithLease}/adicionar-locatario`
    case LEASE_STATUS.PENDING:
      return `${defaultUrlWithLease}/documentacao`
    case LEASE_STATUS.INITIALLY_APPROVED:
      return `${defaultUrlWithLease}/cadastro-aprovado`
    case LEASE_STATUS.CONTRACT_IN_REVIEW:
      return `/contrato/alteracao`
    case LEASE_STATUS.MISSING_DOCUMENTS:
      return `${defaultUrlWithLease}/documentacao-extra`
    case LEASE_STATUS.PRE_CONTRACT_MISSING_DOCUMENTS:
      return `${defaultUrlWithLease}/mais-dados`
    default:
      return leasesUrl
  }
}

const VisitsCarousel = ({ className, visits, isNegotiation, creditAnalysisRedirect }) => {
  const carouselSettings = useMemo(
    () => ({
      className: styles.carousel,
      dots: false,
      infinite: false,
      speed: 100,
      initialSlide: 0,
      draggable: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      centerMode: true,
      centerPadding: '15px',
    }),
    []
  )

  const getVisitUrl = useCallback(visit => {
    const { available } = visit
    if (!available) {
      return ''
    }
    if (visit.lease.statusBooking === BOOKING_STATUS.BOOKING_WAITING_FEEDBACK) {
      return `/feedback-visita/${visit.id}`
    }

    return `${visitsUrl}/${visit.id}`
  }, [])

  const getNegotiationLink = useCallback(lease => {
    if (lease.status === LEASE_STATUS.BOOKING_WAITING_FEEDBACK) {
      const bookingId =
        lease &&
        lease.bookings &&
        lease.bookings.length &&
        lease.bookings.find(booking => booking.active).id
      return `/feedback-visita/${bookingId}`
    }

    return getLink(getLeaseStatus(lease.status), lease.id)
  }, [])

  const disabledCardLink = useCallback(
    lease => {
      if (lease.listing.contractSigned === true) {
        return ''
      }

      if (
        lease &&
        lease.status &&
        (lease.status.includes('credit_analysis') || lease.status.includes('credpago'))
      ) {
        return creditAnalysisRedirect(lease)
      }

      if (isNegotiation) {
        return getNegotiationLink(lease)
      }
      return getVisitUrl(lease)
    },
    [creditAnalysisRedirect, getNegotiationLink, getVisitUrl, isNegotiation]
  )

  return (
    <div className={classnames(className, styles.carousel)}>
      <SlickCarousel settings={carouselSettings}>
        {visits.map(visit => (
          <Link key={visit.id} className={styles.card} to={disabledCardLink(visit)}>
            <VisitCard
              booking={visit}
              available={visit.available}
              primaryColor={primaryColor}
              theme={isNegotiation ? VisitCardTheme.NEGOTIATION : VisitCardTheme.DEFAULT}
            />
          </Link>
        ))}
      </SlickCarousel>
    </div>
  )
}

VisitsCarousel.propTypes = {
  className: PropTypes.string,
  visits: PropTypes.arrayOf(PropTypes.shape()),
  isNegotiation: PropTypes.bool,
  creditAnalysisRedirect: PropTypes.func,
}

VisitsCarousel.defaultProps = {
  className: '',
  visits: [],
  isNegotiation: false,
  creditAnalysisRedirect: () => {},
}

export default React.memo(VisitsCarousel)

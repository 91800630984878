import React, { Fragment, useMemo, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import classnames from 'classnames'

import Button, { ButtonColor } from '_components/button'
import { COLLAPSE_MINIMUM_REQUESTS } from '_utils/constants'

import styles from './styles.css'

const ProposalModification = ({ requests, fromStaff }) => {
  const [showLeaseHistory, setShowLeaseHistory] = useState(false)

  const handleShowLeaseHistory = useCallback(() => {
    setShowLeaseHistory(prev => !prev)
  }, [])

  const proposalTitle = useCallback(
    modification => {
      if (fromStaff) {
        return 'Modificação solicitada'
      }
      if (requests.last()) {
        return modification.get('isStaffRequest') ? 'Proprietário' : 'Você'
      }

      return 'Proposta recusada'
    },
    [fromStaff, requests]
  )

  const styleRejected = useCallback(
    modification => ({
      [styles['modification-row-rejected']]: modification.approved === false,
    }),
    []
  )

  const createdDay = useCallback(value => moment(value).format('DD/MM'), [])

  const createdHour = useCallback(value => moment(value).format('HH:mm'), [])

  const renderRequests = useMemo(() => {
    const list = showLeaseHistory ? requests : requests.slice(0, COLLAPSE_MINIMUM_REQUESTS)

    return list.map(modification => (
      <Fragment>
        <hr className={styles.line} />
        <div
          key={modification.id}
          className={classnames(styles['modification-row'], styleRejected(modification))}
        >
          <p className={classnames(styles.row, styles['sub-title'])}>
            <span>{proposalTitle(modification)}</span>
            <span>
              {createdDay(modification.createdAt)} • {createdHour(modification.createdAt)}h
            </span>
          </p>
          <p className={styles.row}>
            <span>{modification.get('description')}</span>
          </p>
        </div>
      </Fragment>
    ))
  }, [createdDay, createdHour, proposalTitle, requests, showLeaseHistory, styleRejected])
  if (requests.size > 0) {
    return (
      <Fragment>
        {renderRequests}
        {requests.size > COLLAPSE_MINIMUM_REQUESTS && (
          <Fragment>
            <hr className={styles.line} />
            <Button
              color={ButtonColor.TEXT_BLACK}
              className={styles['more-data']}
              onClick={handleShowLeaseHistory}
            >
              {showLeaseHistory ? 'Ver menos' : 'Ver mais'}
            </Button>
          </Fragment>
        )}
      </Fragment>
    )
  }

  return (
    <p className={styles.row}>
      <span>Se você deseja mudar algo no imóvel solicite ao proprietário.</span>
    </p>
  )
}

ProposalModification.propTypes = {
  requests: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  fromStaff: PropTypes.bool,
}

ProposalModification.defaultProps = {
  fromStaff: false,
}

export default React.memo(ProposalModification)

import React, { useCallback, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Redirect, navigate } from '@reach/router'
import { useSelector, useDispatch } from 'react-redux'

import { getUser } from '_modules/leases/selectors'
import Button, { ButtonVariant, ButtonFormat, ButtonColor } from '_components/button'
import { getProperty } from '_modules/property/actions'
import { checkIsLoadingProperty, getPropertyById } from '_modules/property/selectors'
import { CREDIT_ANALYSIS_ROUTES, creditAnalysisFlow } from '_views/credit-analysis/router'
import { FEATURE_FLAGS } from '_utils/constants'

import UserDetails from './user-details'
import RentDetails from './rent-details'
import styles from './styles.css'

const LeaseOverview = ({ id, location }) => {
  const user = useSelector(getUser)
  const property = useSelector(state => getPropertyById(state, id))
  const isLoading = useSelector(checkIsLoadingProperty)
  const dispatch = useDispatch()
  const { lease } = location.state
  const nextRoute = useMemo(() => {
    if (FEATURE_FLAGS.creditAnalysisIntegrationEnabled) {
      return `/minhas-negociacoes/renda-mensal`
    }
    return creditAnalysisFlow(CREDIT_ANALYSIS_ROUTES.MONTHLY_INCOME, lease.id)
  }, [lease.id])

  const handleCancelClick = useCallback(() => navigate('/minhas-visitas'), [])

  useEffect(() => {
    dispatch(getProperty(id))
  }, [dispatch, id])

  const totalValue = useMemo(() => {
    const approvedList =
      lease && lease.proposal && lease.proposal.priceRequests.filter(item => item.approved === true)

    if (lease && lease.proposal && lease.proposal.ownerPrice) {
      return property
        ? lease.proposal.ownerPrice + property.condominiumFee + property.taxes + property.insurance
        : 0
    }
    if (approvedList && approvedList.length > 0) {
      return lease.totalPrice
    }

    return property
      ? property.rentPrice + property.condominiumFee + property.taxes + property.insurance
      : 0
  }, [lease, property])

  const rentPrice = useMemo(() => {
    if (lease && lease.proposal && lease.proposal.ownerPrice) {
      return lease.proposal.ownerPrice
    }

    const approvedList =
      (lease && lease.proposal && lease.proposal.ownerPrice) ||
      (lease &&
        lease.proposal &&
        lease.proposal.priceRequests.filter(item => item.approved === true))

    if (approvedList && approvedList.length > 0) {
      return lease.proposal.rentPrice
    }

    const propertyRentPrice = property && property.rentPrice
    return lease ? lease.rentPrice : propertyRentPrice
  }, [lease, property])

  if (!id || !user.authToken) {
    return <Redirect to="404" noThrow />
  }

  if (isLoading || !property) {
    // TO-DO: Add a loading spinner
    return null
  }

  return (
    <div className={styles.container}>
      {/* USER INFORMATION */}
      <UserDetails name={user.fullName} document={user.cpf} email={user.email} />
      {/* REAL ESTATE INFORMATION */}
      <RentDetails
        externalId={id}
        rentPrice={rentPrice}
        condominum={property.condominiumFee}
        taxes={property.taxes}
        insurance={lease && lease.fireInsurancePrice}
        totalValue={lease.proposal ? totalValue : lease.totalPrice}
      />
      {/* CONFIRMATION BOX */}
      <div className={styles.content}>
        <p className={styles.title}>
          Deseja fazer a análise cadastral para seguir com esse imóvel?
        </p>
        <p className={styles.description}>
          Para continuar, é preciso enviar os seus documentos para análise.
        </p>
        <div className={styles.actions}>
          <Button
            variant={ButtonVariant.BOLD}
            format={ButtonFormat.ROUNDED}
            color={ButtonColor.BLACK_WHITE}
            onClick={handleCancelClick}
          >
            CANCELAR
          </Button>
          <Button
            variant={ButtonVariant.BOLD}
            format={ButtonFormat.ROUNDED}
            active
            to={nextRoute}
            state={{ externalId: id }}
          >
            SIM
          </Button>
        </div>
      </div>
    </div>
  )
}

LeaseOverview.propTypes = {
  id: PropTypes.string,
  location: PropTypes.shape({
    state: PropTypes.shape({
      lease: PropTypes.shape({
        id: PropTypes.number,
        fireInsurancePrice: PropTypes.number,
        proposal: PropTypes.shape({
          rentPrice: PropTypes.number,
          totalPrice: PropTypes.number,
          priceRequests: PropTypes.arrayOf(PropTypes.shape()),
          ownerPrice: PropTypes.number,
        }),
        rentPrice: PropTypes.number,
        totalPrice: PropTypes.number,
      }),
    }),
  }).isRequired,
}

LeaseOverview.defaultProps = {
  id: null,
}

export default React.memo(LeaseOverview)

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import Button, { ButtonColor, ButtonVariant, ButtonFormat } from '_components/button'
import { initRecaptcha, RECAPTCHA_CONTAINER_ID } from '_utils/firebase'

import styles from './styles.css'

const ReesendCode = ({ userInfo, onNumberChange }) => {
  useEffect(() => {
    initRecaptcha(RECAPTCHA_CONTAINER_ID)
  }, [])

  return (
    <div className={styles.content}>
      <div className={styles.login}>
        <h1 className={styles.title}>Se você não recebeu um código</h1>
        <p className={styles.description}>Verificar seu número de telefone</p>
        <strong className={styles['phone-number']}>
          {userInfo.country} {userInfo.area} {userInfo.phoneNumber}
        </strong>
      </div>
      <div className={styles.footer}>
        <Button onClick={onNumberChange} color={ButtonColor.TEXT_BLACK} className={styles.link}>
          Alterar Número
        </Button>
        <Button
          color={ButtonColor.PRIMARY}
          variant={ButtonVariant.BOLD}
          format={ButtonFormat.SQUARED}
          className={styles.button}
          isLoading={userInfo.loading}
          type="submit"
        >
          REENVIAR CÓDIGO
        </Button>
        <div id={RECAPTCHA_CONTAINER_ID} />
      </div>
    </div>
  )
}

ReesendCode.propTypes = {
  userInfo: PropTypes.shape({
    area: PropTypes.string,
    country: PropTypes.string,
    phoneNumber: PropTypes.string,
    loading: PropTypes.bool,
  }).isRequired,
  onNumberChange: PropTypes.func,
}

ReesendCode.defaultProps = {
  onNumberChange: () => {},
}

export default React.memo(ReesendCode)

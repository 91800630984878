import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
  text: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    margin: '3.2rem 0 0.4rem',
    [theme.breakpoints.down('sm')]: {
      margin: '3.6rem 0 0.4rem',
    },
  },
  description: {
    lineHeight: '2.1rem',
    color: theme.palette.gray.dusty,
    marginBottom: '2.4rem',
  },
  button: {
    height: '4rem',
    marginTop: '3.2rem',
  },
}))

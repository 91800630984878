import { createOptions } from '../helpers'

export const OPTIONS = {
  res_house: {},
  res_apartment: {},
  common: {
    animals: {
      label: 'Você aceita animais no seu imóvel?',
      name: 'pets',
      rules: { required: '* Este campo precisa ser preenchido' },
      values: [
        { value: 'true', id: 'pets-yes', label: 'Sim' },
        { value: 'false', id: 'pets-no', label: 'Não' },
      ],
    },
    solarOrientation: {
      label: 'Posição solar',
      name: 'solarOrientation',
      rules: { required: '* Este campo precisa ser preenchido' },
      values: [
        {
          id: 'morning',
          value: 'morning',
          label: 'Sol da manhã',
        },
        {
          id: 'afternoon',
          value: 'afternoon',
          label: 'Sol da tarde',
        },
        {
          id: 'both',
          value: 'both',
          label: 'Ambos',
        },
        {
          id: 'unknown',
          value: 'unknown',
          label: 'Prefiro não informar',
        },
      ],
    },
    parkingSpots: {
      label: 'Quantas vagas de garagem?',
      name: 'parkingSpots',
      rules: { required: '* Este campo precisa ser preenchido' },
      values: createOptions(11, 'parkingspots', {
        zeroIndex: true,
      }),
    },
    coveredParkingSpots: {
      label: 'Quantas vagas cobertas?',
      name: 'coveredParkingSpots',
      rules: { required: '* Este campo precisa ser preenchido' },
      values: createOptions(11, 'coveredparkingspots', {
        zeroIndex: true,
      }),
    },
    publicizePermission: {
      label: 'Condomínio aceita placa de divulgação?',
      name: 'publicizePermission',
      rules: { required: '* Este campo precisa ser preenchido' },
      values: [
        { value: 'true', id: 'publicizepermission-yes', label: 'Sim' },
        { value: 'false', id: 'publicizepermission-no', label: 'Não' },
        { value: 'null', id: 'donotknow', label: 'Não sei' },
      ],
    },
    buildingPosition: {
      label: 'Em relação a fachada do prédio, o seu imóvel está em qual posição?',
      name: 'buildingPostion',
      rules: { required: '* Este campo precisa ser preenchido' },
      values: [
        { label: 'Frente', id: 'front', value: 'front' },
        { label: 'Fundos', id: 'back', value: 'back' },
        { label: 'Lateral', id: 'sides', value: 'sides' },
      ],
    },
    concierge: {
      label: 'Possui portaria?',
      name: 'conciergeType',
      rules: { required: '* Este campo precisa ser preenchido' },
      values: [
        { label: 'Porteiro', id: 'concierge', value: 'concierge' },
        { label: 'Remota', id: 'remote', value: 'remote' },
        { label: 'Não possui', id: 'none', value: 'none' },
      ],
    },
    extras: {
      label: 'Extras',
      name: 'extras',
      values: [
        { value: 'porch', label: 'Sacada', id: 'porch' },
        { value: 'remote_concierge', label: 'Portaria remota', id: 'remote_concierge' },
        { value: 'pool', label: 'Piscina', id: 'pool' },
        { value: 'sauna', label: 'Sauna', id: 'sauna' },
        { value: 'grill', label: 'Churrasqueira', id: 'grill' },
        { value: 'gym', label: 'Academia', id: 'gym' },
      ],
    },
  },
}

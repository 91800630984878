import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ breakpoints, palette }) => ({
  text: {
    borderTop: `0.05rem solid ${palette.gray.chateau}`,
    color: palette.gray.chateau,
    lineHeight: '2',
    margin: '0.8rem auto 0',
    paddingTop: '1.6rem',
    textAlign: 'justify',
    width: '33rem',

    [breakpoints.down('md')]: {
      borderTop: 'none',
      width: '90%',
    },
  },
  link: {
    color: palette.gray.chateau,
  },
}))

import { useState, useCallback } from 'react'

const useModal = (initialMode = false) => {
  const [modalOpen, setModalOpen] = useState(initialMode)
  const handleModal = useCallback(() => {
    setModalOpen(prevState => !prevState)
  }, [])

  return { modalOpen, setModalOpen, handleModal }
}

export default useModal

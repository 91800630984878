import React, { Fragment, useMemo, useCallback, useEffect } from 'react'
import { Link, navigate } from '@reach/router'
import { useSelector, useDispatch } from 'react-redux'
import classnames from 'classnames'
import { Map } from 'immutable'

import ArrowIcon from '_assets/images/angle-left-solid.svg'
import VisitCard, { VisitCardTheme } from '_views/visit/scheduled-visits/visit-card'
import Loading from '_components/loading'
import Button, { ButtonColor, ButtonVariant } from '_components/button'
import { TABLET } from '_config/media-queries'
import VisitsCarousel from '_views/visit/scheduled-visits/visits-carousel'
import EmptyIcon from '_assets/images/empty-negotiation.svg'
import { getLeases, GET_LEASES } from '_modules/leases/actions'
import { getFilteredLeases } from '_modules/leases/selectors'
import withAuth from '_hocs/with-auth'
import { usePrevious } from '_utils/hooks'
import { CREDIT_ANALYSIS_STATUS, LEASE_STATUS } from '_utils/constants'
import { getLeaseStatus } from '_utils/lease'
import { CREDIT_ANALYSIS_ROUTES } from '_views/credit-analysis/router'
import { primaryColor } from '_utils/colors'

import styles from './styles.css'

const defaultUrl = '/minhas-negociacoes'

const getLink = (status, id, bookingId, initiallyApprovedStatusUrl) => {
  const defaultUrlWithLease = `${defaultUrl}/${id}`

  switch (status) {
    case LEASE_STATUS.CONTRACT_READY:
      return `${defaultUrlWithLease}/aprovado`
    case LEASE_STATUS.INCOME_HELPERS_PENDING:
    case LEASE_STATUS.IN_REVIEW:
    case LEASE_STATUS.PRE_CONTRACT_IN_REVIEW:
    case LEASE_STATUS.API_ERROR:
      return `${defaultUrlWithLease}/aguardando-resposta`
    case LEASE_STATUS.CONTRACT_SIGNED:
      return `/contrato/${id}/concluido`
    case LEASE_STATUS.PROPOSAL_APPROVED:
    case LEASE_STATUS.PROPOSAL_PENDING:
    case LEASE_STATUS.PROPOSAL_REJECTED:
      return `/proposta/fazer-proposta/${id}`
    case LEASE_STATUS.INSUFFICIENT_INCOME:
      return `${defaultUrlWithLease}/adicionar-locatario`
    case LEASE_STATUS.PENDING:
      return `${defaultUrlWithLease}/documentacao`
    case LEASE_STATUS.INITIALLY_APPROVED:
      return `${initiallyApprovedStatusUrl}`
    case LEASE_STATUS.APPROVED:
      return `/minhas-negociacoes/${id}/analise-aprovada`
    case LEASE_STATUS.CONTRACT_IN_REVIEW:
      return `/contrato/alteracao`
    case LEASE_STATUS.DATA_REQUESTED:
    case LEASE_STATUS.DOCUMENTS_REQUESTED:
      return `${defaultUrlWithLease}/dados-requisitados`
    case LEASE_STATUS.PRE_CONTRACT_MISSING_DOCUMENTS:
      return `${defaultUrlWithLease}/mais-dados`
    case LEASE_STATUS.WARRANTY_REQUESTED:
      return `${defaultUrlWithLease}/garantia/`
    case LEASE_STATUS.PARTICIPANT_REQUESTED:
      return `${defaultUrlWithLease}/mais-informacoes/`
    default:
      return defaultUrl
  }
}

const MyNegotiations = () => {
  const dispatch = useDispatch()
  const isMobile = useSelector(state => state.server.isMobile)
  const leases = useSelector(getFilteredLeases)
  const isMobileDevice = useMemo(
    () => (typeof window !== 'undefined' ? window.innerWidth <= TABLET : isMobile),
    [isMobile]
  )
  const loadingLeases = useSelector(state => !!state.loading.get(GET_LEASES.ACTION))
  const wasLoadingLeases = usePrevious(loadingLeases)

  const createSVGStyle = useMemo(() => ({ fill: primaryColor }), [])
  const createTitleStyle = useMemo(() => ({ color: primaryColor }), [])
  const filteredLeases = useMemo(
    () =>
      leases
        .valueSeq()
        .toArray()
        .filter(lease => lease.status !== LEASE_STATUS.DEACTIVATED),
    [leases]
  )
  useEffect(() => {
    dispatch(getLeases())
  }, [dispatch])

  const onSeeOtherPropertiesClick = useCallback(() => navigate('/'), [])

  const initiallyApprovedStatusUrl = useCallback(lease => {
    const defaultUrlWithLease = `${defaultUrl}/${lease.id}`
    if (
      !lease.hasRequestedDocuments &&
      !lease.hasRequestedPersonalData &&
      !lease.hasRequestedProfessionalData
    ) {
      return `${defaultUrlWithLease}/aguardando-resposta`
    }

    return `/analise-credito/${lease.id}/inicio`
  }, [])

  const creditAnalysisRedirect = useCallback(currentLease => {
    const lease = currentLease instanceof Map ? currentLease.toJS() : currentLease
    const creditAnalysisDefaultRoute = `/analise-credito/${lease.id}`
    switch (lease.status) {
      case CREDIT_ANALYSIS_STATUS.IN_REVIEW: {
        if (lease.previousWarranty) {
          return creditAnalysisDefaultRoute + CREDIT_ANALYSIS_ROUTES.NEW_WARRANTY
        }
        return creditAnalysisDefaultRoute + CREDIT_ANALYSIS_ROUTES.STATUS
      }
      case LEASE_STATUS.WARRANTY_REQUESTED:
        return creditAnalysisDefaultRoute + CREDIT_ANALYSIS_ROUTES.NEW_WARRANTY
      case LEASE_STATUS.CONTRACT_READY:
      case CREDIT_ANALYSIS_STATUS.APPROVED:
      case CREDIT_ANALYSIS_STATUS.CONTRACT_READY:
      case CREDIT_ANALYSIS_STATUS.INITIALLY_APPROVED:
        return creditAnalysisDefaultRoute + CREDIT_ANALYSIS_ROUTES.AVAILABLE
      case LEASE_STATUS.CANCELLED:
        return creditAnalysisDefaultRoute + CREDIT_ANALYSIS_ROUTES.CLOSED_LEASE
      case LEASE_STATUS.DATA_REQUESTED:
      case LEASE_STATUS.DOCUMENTS_REQUESTED:
        return `${defaultUrl}/${lease.id}/dados-requisitados`
      case LEASE_STATUS.PARTICIPANT_REQUESTED:
        return creditAnalysisDefaultRoute + CREDIT_ANALYSIS_ROUTES.NEED_MORE_DATA
      default:
        return creditAnalysisDefaultRoute + CREDIT_ANALYSIS_ROUTES.STATUS
    }
  }, [])

  const getLinkUrl = useCallback(
    lease => {
      if (!lease.getIn(['listing', 'available'])) {
        return ''
      }
      if (!lease.proposal && getLeaseStatus(lease.status) === LEASE_STATUS.PROPOSAL_PENDING)
        return `/proposta/resumo-locacao/${lease.id}`
      if (lease.proposal && getLeaseStatus(lease.status) === LEASE_STATUS.PROPOSAL_PENDING) {
        const { approved } = lease.proposal.toJS()
        if (approved !== null) return `/proposta/fazer-proposta/${lease.id}`
      }

      if (
        lease &&
        lease.status &&
        (lease.status.includes('credit_analysis') || lease.status.includes('credpago'))
      ) {
        return creditAnalysisRedirect(lease)
      }

      return lease
        ? getLink(
            getLeaseStatus(lease.status),
            lease.id,
            lease.getIn(['listing', 'bookingId']),
            initiallyApprovedStatusUrl(lease)
          )
        : defaultUrl
    },
    [creditAnalysisRedirect, initiallyApprovedStatusUrl]
  )

  const handleSeeMore = useCallback(
    externalId => {
      const lease = leases.get(externalId)
      if (!lease) {
        return
      }
      navigate(creditAnalysisRedirect(lease), { state: { status: lease.get('status') } })
    },
    [creditAnalysisRedirect, leases]
  )

  const disabledCardLink = useCallback(
    value => {
      if (value.getIn(['listing', 'contractSigned'])) {
        return ''
      }
      return getLinkUrl(value)
    },
    [getLinkUrl]
  )

  const emptyState = useMemo(
    () => (
      <section className={styles.empty}>
        <svg aria-hidden="true" className={styles['empty-icon']} style={createSVGStyle}>
          <use xlinkHref={EmptyIcon} />
        </svg>
        <h1 className={styles['empty-title']} style={createTitleStyle}>
          Você não possui negociações.
        </h1>
        <p className={styles['empty-text']}>
          Você ainda não possui nenhuma negociação. Que tal olhar os imóveis que adicionamos hoje?
        </p>
        <Button
          color={ButtonColor.PRIMARY}
          variant={ButtonVariant.BOLD}
          className={styles['empty-button']}
          onClick={onSeeOtherPropertiesClick}
        >
          VER OUTROS IMÓVEIS
        </Button>
      </section>
    ),
    [createSVGStyle, createTitleStyle, onSeeOtherPropertiesClick]
  )
  if (loadingLeases || wasLoadingLeases === undefined) {
    return <Loading />
  }
  return (
    <div
      className={classnames(styles.main, {
        [styles['with-carousel']]: leases.size > 1 && isMobileDevice,
      })}
    >
      <div className={styles.container}>
        {filteredLeases.length ? (
          <Fragment>
            <h1>Minhas negociações</h1>
            {filteredLeases.length === 1 ? (
              <Fragment>
                <Link
                  to={getLinkUrl(filteredLeases[0])}
                  state={{ status: filteredLeases[0].get('status') }}
                  alt={filteredLeases[0].getIn(['listing', 'title'])}
                  key={filteredLeases[0].get('id')}
                >
                  <VisitCard
                    booking={filteredLeases[0].toJS()}
                    primaryColor={primaryColor}
                    theme={VisitCardTheme.NEGOTIATION}
                    available={filteredLeases[0].getIn(['listing', 'available'])}
                  />
                </Link>
                {!filteredLeases[0].getIn(['listing', 'contractSigned']) && (
                  <Button
                    className={styles['see-more-button']}
                    color={ButtonColor.PRIMARY}
                    variant={ButtonVariant.BOLD}
                    onClick={handleSeeMore}
                    id={filteredLeases[0].getIn(['listing', 'externalId'])}
                  >
                    VER MAIS
                  </Button>
                )}
              </Fragment>
            ) : (
              <Fragment>
                {isMobileDevice ? (
                  <VisitsCarousel
                    visits={Object.values(leases.toJS())}
                    primaryColor={primaryColor}
                    isNegotiation
                    rootLink={defaultUrl}
                    creditAnalysisRedirect={creditAnalysisRedirect}
                  />
                ) : (
                  filteredLeases.map(lease => (
                    <Link
                      to={disabledCardLink(lease)}
                      alt={lease.getIn(['listing', 'title'])}
                      key={lease.get('id')}
                    >
                      <VisitCard
                        booking={lease.toJS()}
                        primaryColor={primaryColor}
                        theme={VisitCardTheme.NEGOTIATION}
                        isSmall
                        className={styles['negotiation-card']}
                        fireInsurance={lease.fireInsurancePrice}
                        available={lease.getIn(['listing', 'available'])}
                      />
                    </Link>
                  ))
                )}
              </Fragment>
            )}
            <p className={styles.text}>Essas são as suas negociações.</p>
            <Link to="/" className={styles['return-link']}>
              <svg aria-hidden="true" className={styles.icon}>
                <use xlinkHref={ArrowIcon} />
              </svg>
              VOLTAR PARA PÁGINA INICIAL
            </Link>
          </Fragment>
        ) : (
          emptyState
        )}
      </div>
    </div>
  )
}

export default withAuth(React.memo(MyNegotiations))

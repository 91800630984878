import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Waypoint from 'react-waypoint'

import styles from './styles.css'

class CarouselPicker extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    waypointCallback: PropTypes.func,
    setCarouselRef: PropTypes.func,
  }

  static defaultProps = {
    className: '',
    waypointCallback: () => {},
    setCarouselRef: () => {},
  }

  onEnterWaypoint = index => () => {
    this.props.waypointCallback(index)
  }

  render() {
    const { className, setCarouselRef, children } = this.props
    return (
      <div className={classnames(styles.carousel, className)}>
        <div className={styles.wrapper}>
          <div className={styles.container} ref={setCarouselRef}>
            {children.map((child, index) => (
              <Waypoint
                onEnter={this.onEnterWaypoint(index)}
                bottomOffset="48%"
                topOffset="48%"
                horizontal
              >
                <div className={styles.slot}>{child}</div>
              </Waypoint>
            ))}
          </div>
        </div>
      </div>
    )
  }
}

export default CarouselPicker

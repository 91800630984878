import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
  container: {
    display: 'grid',
    alignItems: 'center',
    justifyContent: 'center',
    gridTemplateColumns: 'minmax(24px, 1fr) minmax(auto, 1040px) minmax(24px, 1fr)',
    gridTemplateRows: 'repeat(5, auto)',
    gridTemplateAreas:
      "'. filter .' '. order .' '. exact .' '. button .' 'section section section' '. partial .' ",
    gridGap: '16px',
    paddingBottom: '5rem',

    [theme.breakpoints.down('md')]: {
      gridTemplateAreas:
        "'filter filter filter' '. order .' '. exact .' '. button .' 'section section section' '. partial .' ",
      gridRowGap: '10px',
      gridColumnGap: 0,
    },
  },
  line: {
    borderTop: `0.01rem solid ${theme.palette.gray.background}`,
    gridColumn: 2,
  },
  filter: {
    gridArea: 'filter',
    borderBottom: `0.01rem solid ${theme.palette.gray.background}`,
  },
  section: {
    gridArea: 'section',
    marginBottom: '2rem',
  },
  orderOptions: {
    gridArea: 'order',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  propertiesFound: {
    fontSize: '1.4rem',
  },
  listingProperties: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridTemplateRows: 'auto',
  },
  partial: {
    gridArea: 'partial',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
  },
  result: {
    fontWeight: '500',
    marginBottom: '1.6rem',
    marginRight: 'auto',
  },
  building: {
    gridColumn: 2,
    height: '80px',
    margin: '8rem auto',
  },
  exactMatches: {
    gridArea: 'exact',
  },
  properties: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridTemplateRows: 'auto',
    gridGap: '8px',
    margin: '1rem 0 5rem',

    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [theme.breakpoints.down('725')]: {
      gridTemplateColumns: '1fr',
      justifyItems: 'center',
    },
  },
  snackBar: {
    maxWidth: '500px',
  },
  buttonSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gridColumn: 2,
    gridArea: 'button',
  },
  moreButton: {
    marginBottom: '16px',
  },
  error: {
    gridArea: 'exact',
  },
}))

import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import { shadeColor, primaryColor } from '_utils/colors'

import styles from './styles.css'

const Building = ({ className, isSmall }) => {
  const lighterColor = shadeColor(primaryColor)

  return (
    <svg
      className={classnames(styles.building, className, { [styles.small]: isSmall })}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 144 165"
      id="el_STO3YR6_a"
      alt="loading icon"
    >
      <g className={styles.rectangle} style={{ fill: lighterColor }}>
        <path d="M0 0h23.879v23.879H0z" data-name="Rectangle 980" />
      </g>
      <g className={styles.triangle} style={{ fill: lighterColor }}>
        <path
          id="el_TcceRt1KrY"
          d="M11796-10916.113l23.7-23.822h-23.7v23.822"
          data-name="Path 242"
        />
      </g>
      <path
        id="el_dGqO41OC7Q"
        d="M141 155.231h-6.468V7.762A7.761 7.761 0 0 0 126.772 0H18.11a7.761 7.761 0 0 0-7.762 7.762v147.469H3.881A3.881 3.881 0 0 0 0 159.111v6.468h144.882v-6.468a3.881 3.881 0 0 0-3.882-3.88zM41.395 24.578a3.881 3.881 0 0 1 3.881-3.878h12.936a3.881 3.881 0 0 1 3.881 3.881v12.933a3.881 3.881 0 0 1-3.881 3.881H45.276a3.881 3.881 0 0 1-3.881-3.881zm0 31.046a3.881 3.881 0 0 1 3.881-3.881h12.936a3.881 3.881 0 0 1 3.881 3.881V68.56a3.881 3.881 0 0 1-3.881 3.881H45.276a3.881 3.881 0 0 1-3.881-3.881zm16.817 47.863H45.276a3.881 3.881 0 0 1-3.881-3.881V86.67a3.881 3.881 0 0 1 3.881-3.881h12.936a3.881 3.881 0 0 1 3.881 3.881v12.936a3.881 3.881 0 0 1-3.881 3.881zm24.578 51.744h-20.7v-27.166a3.881 3.881 0 0 1 3.881-3.881h12.938a3.881 3.881 0 0 1 3.881 3.881zm20.7-55.624a3.881 3.881 0 0 1-3.881 3.881H86.67a3.881 3.881 0 0 1-3.881-3.881V86.67a3.881 3.881 0 0 1 3.881-3.88h12.936a3.881 3.881 0 0 1 3.881 3.881zm0-31.046a3.881 3.881 0 0 1-3.881 3.881H86.67a3.881 3.881 0 0 1-3.88-3.882V55.624a3.881 3.881 0 0 1 3.881-3.881h12.935a3.881 3.881 0 0 1 3.881 3.881zm0-31.046a3.881 3.881 0 0 1-3.881 3.881H86.67a3.881 3.881 0 0 1-3.881-3.881V24.578A3.881 3.881 0 0 1 86.67 20.7h12.936a3.881 3.881 0 0 1 3.881 3.881z"
      />
    </svg>
  )
}

Building.propTypes = {
  className: PropTypes.string,
  isSmall: PropTypes.bool,
}

Building.defaultProps = {
  className: '',
  isSmall: false,
}

export default React.memo(Building)

import { Map } from 'immutable'
import { schema } from 'normalizr'

import Model from './model'

class Proposal extends Model({
  id: undefined,
  externalId: undefined,
  active: null,
  listing: Map(),
  approved: undefined,
  livingWithWho: undefined,
  numberOfPeople: undefined,
  hasPet: undefined,
  petDescription: undefined,
  description: undefined,
  rentPrice: undefined,
  requestDescription: undefined,
  documents: Map(),
  createdAt: undefined,
  priceRequests: Map(),
  modificationRequests: Map(),
  fromStaff: undefined,
  suggestedPrice: undefined,
  ownerPrice: undefined,
}) {}

const proposalSchema = new schema.Entity(
  'proposals',
  {},
  { processStrategy: entity => new Proposal(entity) }
)

export { proposalSchema, Proposal as default }

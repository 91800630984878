import { PROPERTY_FRIENDLY_URL } from '_utils/property'
import { PET_VALUES } from '_utils/constants'

export const FILTER_VALUES = {
  BATHROOMS: 'bathrooms',
  BEDROOMS: 'bedrooms',
  CITY: 'city',
  COMMERCIAL: 'commercial',
  CONDOMINIUM_FEATURES: 'condominiumAmenities',
  EXTERNAL_ID: 'externalId',
  FURNISHED: 'furnished',
  LISTING_DESCRIPTION: 'listingDescription',
  LISTING_TYPE: 'listingType',
  NEIGHBORHOODS: 'neighborhoods',
  PARKING_SPOTS: 'parkingSpots',
  PETS: 'pets',
  PRICE: 'price',
  PROPERTY_FEATURES: 'furnished',
  PROPERTY_MAX_AREA: 'maxArea',
  PROPERTY_MIN_AREA: 'minArea',
  PURPOSE_TYPE: 'purposeType',
  RESIDENTIAL: 'residential',
  SUITE: 'numberOfEnsuites',
  TAX_INCLUDED: 'taxesIncluded',
}

export const URL_LABELS = {
  [FILTER_VALUES.COMMERCIAL]: 'commercial',
  [FILTER_VALUES.RESIDENTIAL]: 'residencial',
  [FILTER_VALUES.PRICE]: '-reais',
  [FILTER_VALUES.NEIGHBORHOODS]: 'bairro-',
  [FILTER_VALUES.BEDROOMS]: '-quartos',
  [FILTER_VALUES.BATHROOMS]: '-banheiros',
  [FILTER_VALUES.SUITE]: '-suites',
  [FILTER_VALUES.PARKING_SPOTS]: '-garagens',
  [FILTER_VALUES.PETS]: '-pets',
  [FILTER_VALUES.TAX_INCLUDED]: 'taxas-inclusas',
  [FILTER_VALUES.PROPERTY_FEATURES]: '-mobilia',
  [FILTER_VALUES.EXTERNAL_ID]: '-imovel',
}

export const URL_NAMES = Object.entries(URL_LABELS).reduce((acc, [key, value]) => {
  return { ...acc, [value]: key }
}, {})

export const COMMERCIAL_LISTINGS = Object.entries(PROPERTY_FRIENDLY_URL).reduce(
  (acc, [key, value]) => {
    if (key.includes('com')) {
      return { ...acc, [value]: key }
    }
    return acc
  },
  {}
)

export const RESIDENTIAL_LISTINGS = Object.entries(PROPERTY_FRIENDLY_URL).reduce(
  (acc, [key, value]) => {
    if (key.includes('res')) {
      return { ...acc, [value]: key }
    }
    return acc
  },
  {}
)

const FRIENDLY_URL_LISTINGS = Object.values(PROPERTY_FRIENDLY_URL)
const FRIENDLY_PROPERTY_VALUES = {
  SEMIMOBILIADO: `semi${URL_LABELS[FILTER_VALUES.PROPERTY_FEATURES]}`,
  SIM: `com${URL_LABELS[FILTER_VALUES.PROPERTY_FEATURES]}`,
  NÃO: `sem${URL_LABELS[FILTER_VALUES.PROPERTY_FEATURES]}`,
  'TANTO FAZ': '',
}

const FRIENDLY_PETS_VALUES = {
  [PET_VALUES.YES]: `com${URL_LABELS[FILTER_VALUES.PETS]}`,
  [PET_VALUES.NO]: `sem${URL_LABELS[FILTER_VALUES.PETS]}`,
  [PET_VALUES.MAYBE]: '',
}
export const parseUrlFriendly = (string, addSlash = true) =>
  `${string &&
    string
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .split(' ')
      .join('-')
      .toLowerCase()}${addSlash ? '/' : ''}`

export const parseURLToString = string =>
  string &&
  string
    .toLowerCase()
    .split(' ')
    .map(s => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ')
    .split('-')
    .join(' ')

const insert = (arr, index, ...newItems) => [
  // part of the array before the specified index
  ...arr.slice(0, index),
  // inserted items
  ...newItems,
  // part of the array after the specified index
  ...arr.slice(index),
]

export const NUMBER_VALUES = [
  FILTER_VALUES.PRICE,
  FILTER_VALUES.BEDROOMS,
  FILTER_VALUES.BATHROOMS,
  FILTER_VALUES.SUITE,
  FILTER_VALUES.PARKING_SPOTS,
]

export const insertIntoPathname = (pathname, name, value) =>
  pathname.includes(name)
    ? pathname
        .split('/')
        .map(filter => (filter.includes(name) ? `${value}${name}` : filter))
        .join('/')
    : `${pathname}/${value}${name}`

export const handleFilterUrl = (pathname, filter, name, value) => {
  let newUrl = pathname[pathname.length - 1] === '/' ? pathname.slice(0, -1) : pathname
  if (name !== FILTER_VALUES.CITY && newUrl.split('/').filter(Boolean).length === 1) {
    newUrl += '/todas'
  }
  switch (name) {
    case FILTER_VALUES.CITY: {
      // Remove neighborhoods selected
      return newUrl.includes(filter.city)
        ? newUrl
            .replace(filter.city, value)
            .split('/')
            .filter(type => !type.includes('bairro-'))
            .join('/')
        : insert(newUrl.split('/'), 2, value).join('/')
    }
    case FILTER_VALUES.NEIGHBORHOODS: {
      return value.length
        ? insert(
            newUrl.split('/').filter(type => !type.includes('bairro-')),
            3,
            value.map(neighborhood => `bairro-${neighborhood}`).join('/')
          ).join('/')
        : newUrl
            .split('/')
            .filter(type => !type.includes('bairro-'))
            .join('/')
    }
    case FILTER_VALUES.BEDROOMS: {
      return insertIntoPathname(pathname, URL_LABELS[FILTER_VALUES.BEDROOMS], value)
    }
    case FILTER_VALUES.PURPOSE_TYPE: {
      if (value === FILTER_VALUES.RESIDENTIAL) {
        if (newUrl.includes(URL_LABELS[FILTER_VALUES.COMMERCIAL])) {
          return newUrl.replace(
            URL_LABELS[FILTER_VALUES.COMMERCIAL],
            URL_LABELS[FILTER_VALUES.RESIDENTIAL]
          )
        }
        return newUrl.includes(URL_LABELS[FILTER_VALUES.RESIDENTIAL])
          ? newUrl
          : `${newUrl}/${URL_LABELS[FILTER_VALUES.RESIDENTIAL]}`
      }
      if (value === FILTER_VALUES.COMMERCIAL) {
        if (newUrl.includes(URL_LABELS[FILTER_VALUES.RESIDENTIAL])) {
          return newUrl.replace(
            URL_LABELS[FILTER_VALUES.RESIDENTIAL],
            URL_LABELS[FILTER_VALUES.COMMERCIAL]
          )
        }
        return newUrl.includes(URL_LABELS[FILTER_VALUES.COMMERCIAL])
          ? newUrl
          : `${newUrl}/${URL_LABELS[FILTER_VALUES.COMMERCIAL]}`
      }
      return newUrl
    }
    default:
      return null
  }
}

export const parseFilterToUrl = (pathname, filter) => {
  let newUrl = pathname[pathname.length - 1] === '/' ? pathname.slice(0, -1) : pathname
  if (
    (!filter[FILTER_VALUES.CITY] || !filter[FILTER_VALUES.CITY].length) &&
    newUrl.split('/').filter(Boolean).length === 1
  ) {
    newUrl += '/todas'
  }
  Object.entries(filter).map(([key, value]) => {
    switch (key) {
      case FILTER_VALUES.CITY: {
        const currentCity = newUrl.split('/').filter(Boolean)[1]
        // Remove neighborhoods selected
        newUrl = newUrl.includes(currentCity)
          ? newUrl
              .replace(currentCity, value)
              .split('/')
              .filter(type => !type.includes('bairro-'))
              .join('/')
          : insert(newUrl.split('/'), 2, value).join('/')
        break
      }
      case FILTER_VALUES.NEIGHBORHOODS: {
        if (value.length) {
          newUrl = insert(
            newUrl.split('/').filter(type => !type.includes('bairro-')),
            3,
            value.map(neighborhood => `bairro-${neighborhood}`).join('/')
          ).join('/')
          break
        }
        newUrl = newUrl
          .split('/')
          .filter(type => !type.includes('bairro-'))
          .join('/')
        break
      }
      case FILTER_VALUES.PRICE:
      case FILTER_VALUES.PARKING_SPOTS:
      case FILTER_VALUES.SUITE:
      case FILTER_VALUES.BATHROOMS:
      case FILTER_VALUES.BEDROOMS: {
        if (value === 'Quartos') {
          break
        }
        if (newUrl.includes(URL_LABELS[key])) {
          const oldValue = newUrl.split('/').find(url => url.includes(URL_LABELS[key]))
          newUrl = value
            ? newUrl.replace(oldValue, `${value}${URL_LABELS[key]}`)
            : newUrl.replace(`/${oldValue}`, '')
          break
        }
        if (!value) {
          break
        }
        newUrl = `${newUrl}/${value}${URL_LABELS[key]}`
        break
      }
      case FILTER_VALUES.PROPERTY_FEATURES:
      case FILTER_VALUES.PETS: {
        const possibleValues =
          key === FILTER_VALUES.PETS ? FRIENDLY_PETS_VALUES : FRIENDLY_PROPERTY_VALUES
        if (newUrl.includes(URL_LABELS[key])) {
          const oldValue = newUrl.split('/').find(url => url.includes(URL_LABELS[key]))
          newUrl =
            value !== 'TANTO FAZ'
              ? newUrl.replace(oldValue, possibleValues[value])
              : newUrl.replace(`/${oldValue}`, possibleValues[value])
          break
        }
        if (value === 'TANTO FAZ' || !value) {
          break
        }
        newUrl = `${newUrl}/${possibleValues[value]}`
        break
      }
      case FILTER_VALUES.LISTING_TYPE: {
        const newValues = value.map(listing => PROPERTY_FRIENDLY_URL[listing])
        const uniqueNewValues = [...new Set(newValues)]
        newUrl = [
          ...newUrl.split('/').filter(type => !FRIENDLY_URL_LISTINGS.includes(type)),
          ...uniqueNewValues,
        ].join('/')
        break
      }
      case FILTER_VALUES.PURPOSE_TYPE: {
        if (value === FILTER_VALUES.RESIDENTIAL) {
          if (newUrl.includes(URL_LABELS[FILTER_VALUES.COMMERCIAL])) {
            newUrl = newUrl.replace(
              URL_LABELS[FILTER_VALUES.COMMERCIAL],
              URL_LABELS[FILTER_VALUES.RESIDENTIAL]
            )
          }
          newUrl = newUrl.includes(URL_LABELS[FILTER_VALUES.RESIDENTIAL])
            ? newUrl
            : `${newUrl}/${URL_LABELS[FILTER_VALUES.RESIDENTIAL]}`
        }
        if (value === FILTER_VALUES.COMMERCIAL) {
          if (newUrl.includes(URL_LABELS[FILTER_VALUES.RESIDENTIAL])) {
            newUrl = newUrl.replace(
              URL_LABELS[FILTER_VALUES.RESIDENTIAL],
              URL_LABELS[FILTER_VALUES.COMMERCIAL]
            )
          }
          newUrl = newUrl.includes(URL_LABELS[FILTER_VALUES.COMMERCIAL])
            ? newUrl
            : `${newUrl}/${URL_LABELS[FILTER_VALUES.COMMERCIAL]}`
        }
        if (!value) {
          if (newUrl.includes(URL_LABELS[FILTER_VALUES.RESIDENTIAL])) {
            newUrl = newUrl.replace(`/${URL_LABELS[FILTER_VALUES.RESIDENTIAL]}`, '')
          }
          if (newUrl.includes(URL_LABELS[FILTER_VALUES.COMMERCIAL])) {
            newUrl = newUrl.replace(`/${URL_LABELS[FILTER_VALUES.COMMERCIAL]}`, '')
          }
        }
        return newUrl
      }
      case FILTER_VALUES.TAX_INCLUDED: {
        if (newUrl.includes(URL_LABELS[key])) {
          if (!value) {
            const oldValue = newUrl.split('/').find(url => url.includes(URL_LABELS[key]))
            newUrl = newUrl.replace(`/${oldValue}`, '')
            break
          }
          break
        }
        if (value) {
          newUrl = `${newUrl}/${URL_LABELS[key]}`
        }
        break
      }
      case FILTER_VALUES.EXTERNAL_ID: {
        if (newUrl.includes(URL_LABELS[key])) {
          const oldValue = newUrl.split('/').find(url => url.includes(URL_LABELS[key]))
          newUrl = value
            ? newUrl.replace(oldValue, !filter[key] ? '' : `${filter[key]}${URL_LABELS[key]}`)
            : newUrl.replace(`/${oldValue}`, '')
          break
        }
        if (value) {
          newUrl = `${newUrl}/${filter[key]}${URL_LABELS[key]}`
        }
        break
      }
      default:
        return null
    }
    return null
  })

  return newUrl
}

import humps from 'humps'
import moment from 'moment'

import { formatPhoneNumber, PHONE_NUMBER_REGEX } from '_utils/strings'
import { EMAIL_REGEX } from '_utils/constants'

export const onMouseDown = event => event.preventDefault()

export const randomString = Math.random()
  .toString(36)
  .substring(7)

export const removeIndentation = value => value.replace(/(\s{2,})|(\t{1,})/g, '\n')

export const createFormData = (data, isCamelized = false) => {
  const formData = new FormData()
  Object.keys(data).forEach(field => {
    const fieldValue = data[field]
    const formDataValue = (() => {
      if (!fieldValue) {
        return ''
      }
      if (
        fieldValue instanceof Blob ||
        typeof fieldValue !== 'object' ||
        Array.isArray(fieldValue)
      ) {
        return fieldValue
      }
      return JSON.stringify(fieldValue)
    })()
    if (Array.isArray(formDataValue)) {
      formDataValue.forEach(value => {
        if (value instanceof Blob && value.constructor === Blob) {
          formData.append(isCamelized ? field : humps.decamelize(field), value, value.name)
        } else {
          formData.append(isCamelized ? field : humps.decamelize(field), value)
        }
      })
    } else if (formDataValue instanceof Blob && formDataValue.constructor === Blob) {
      formData.append(
        isCamelized ? field : humps.decamelize(field),
        formDataValue,
        formDataValue.name
      )
    } else {
      formData.append(isCamelized ? field : humps.decamelize(field), formDataValue)
    }
  })
  return formData
}

export const formatNumberPad2 = number => (number > 9 ? number : `0${number}`)

const formatNumber = value => value.toLocaleString('pt-BR')

export const toCurrency = value => {
  if ([null, undefined, 'A consultar', 0].includes(value)) {
    return 'A consultar'
  }
  return `R$ ${`${formatNumber(value)}`}`
}

export const toTitleCase = phrase =>
  phrase
    ? phrase
        .toLowerCase()
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
    : ''

// Take off slug of external id to render just the first part
export const getExternalId = externalId => {
  return externalId.split('-')[0]
}

export const validationCPF = inputCPF => {
  const cpf = inputCPF ? inputCPF.replace(/[^0-9]+/g, '') : '00000000000'
  let sum = 0
  let rest

  if (cpf === '00000000000') {
    return false
  }

  // eslint-disable-next-line no-plusplus
  for (let i = 1; i <= 9; i++) {
    sum += parseInt(cpf.substring(i - 1, i), 10) * (11 - i)
  }

  rest = (sum * 10) % 11

  if (rest === 10 || rest === 11) {
    rest = 0
  }

  if (rest !== parseInt(cpf.substring(9, 10), 10)) {
    return false
  }

  sum = 0
  // eslint-disable-next-line no-plusplus
  for (let i = 1; i <= 10; i++) {
    sum += parseInt(cpf.substring(i - 1, i), 10) * (12 - i)
  }

  rest = (sum * 10) % 11

  if (rest === 10 || rest === 11) {
    rest = 0
  }

  if (rest !== parseInt(cpf.substring(10, 11), 10)) {
    return false
  }

  return true
}

export const validationPhoneNumber = phone => {
  const phoneNumber = formatPhoneNumber(phone)
  return phoneNumber.length === 0 || !PHONE_NUMBER_REGEX.test(phoneNumber)
}

export const getYoutubeDataId = url => {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
  const match = url.match(regExp)
  return match && match[7].length === 11 ? match[7] : false
}

export const dateValidation = date => moment(date, 'DD/MM/YYYY', true).isValid()

export const validationEmail = email => {
  if (!email) {
    return false
  }

  return EMAIL_REGEX.test(email)
}

export const notEmptyPayload = payload => {
  const undefinedValues = [undefined, null, [], '', 0]
  const newPayload = Object.keys(payload).reduce(
    (acc, key) =>
      undefinedValues.includes(payload[key]) ||
      (Array.isArray(payload[key]) && payload[key].length === 0)
        ? acc
        : { ...acc, [key]: payload[key] },
    {}
  )
  return newPayload
}

export const getFileKey = file => `${file.name}-${file.size}`

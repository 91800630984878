import UAParser from 'ua-parser-js'

const platforms = {
  Android: 'android',
  iOS: 'ios',
  'Mac OS': 'mac',
  Ubuntu: 'linux',
  Unix: 'linux',
  Windows: 'windows',
  'Windows Phone': 'win_phone',
  'Windows Mobile': 'win_phone',
}

const browsers = {
  'Chrome Headless': 'chrome',
  'Chrome WebView': 'chrome',
  Chrome: 'chrome',
  Chromium: 'chrome',
  Edge: 'edge',
  Firefox: 'firefox',
  IE: 'ie',
  IEMobile: 'ie',
  'Mobile Safari': 'safari',
  Mozilla: 'firefox',
  'Opera Coast': 'opera',
  'Opera Mini': 'opera',
  'Opera Mobi': 'opera',
  'Opera Tablet': 'opera',
  Opera: 'opera',
  Safari: 'safari',
}

export const browserInformation = () => {
  const parser = new UAParser()
  const result = parser.getResult()

  const information = {}

  information.isMobile = ['tablet', 'mobile'].includes(result.device.type)
  information.browser = browsers[result.browser.name] || 'opera'
  information.platform = platforms[result.os.name] || 'linux'

  return information
}

export const detectWebGLContext = () => {
  // Create canvas element. The canvas is not added to the
  // document itself, so it is never displayed in the
  // browser window.
  if (typeof window === 'undefined') {
    return false
  }
  const canvas = document.createElement('canvas')
  // Get WebGLRenderingContext from canvas element.
  const gl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl')
  // Report the result.
  if (gl && gl instanceof WebGLRenderingContext) {
    return true
  }
  return false
}

import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ breakpoints, palette }) => ({
  container: {
    padding: '2.4rem',
    borderRadius: '0.4rem',
    backgroundColor: palette.gray.gallery,
  },
  description: {
    [breakpoints.down('md')]: {
      fontSize: '1.2rem',
    },
    fontWeight: 'normal',
    fontSize: '1.4rem',
    color: palette.secondary.main,
  },
  children: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}))

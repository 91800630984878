import humps from 'humps'

import { defineAction } from '_utils/redux'
import { FURNITURE_VALUES, PET_VALUES } from '_utils/constants'
import { notEmptyPayload } from '_utils/helpers'
import * as propertyService from '_services/property'

export const GET_PROPERTIES = defineAction('GET_PROPERTIES')
export const SET_FILTER = 'SET_FILTER'
export const SET_SCROLL = 'SET_SCROLL'
export const SET_FILTER_MODAL = 'SET_FILTER_MODAL'
export const RESET_SEARCH = 'RESET_SEARCH'
export const DELETE_OTHER_FILTERS = 'DELETE_OTHER_FILTERS'

const camelizeResponse = response => humps.camelizeKeys(response)
const getFurniture = furnished => {
  switch (furnished) {
    case FURNITURE_VALUES.WITH_FURNISHED:
    case FURNITURE_VALUES.YES:
      return 'furnished'
    case FURNITURE_VALUES.WITHOUT_FURNISHED:
    case FURNITURE_VALUES.NO:
      return 'unfurnished'
    case FURNITURE_VALUES.SEMI_FURNISHED:
      return 'semi_furnished'
    default:
      return undefined
  }
}
const getPets = pets => {
  if (pets === PET_VALUES.YES) {
    return true
  }
  if (pets === PET_VALUES.NO) {
    return false
  }
  return undefined
}

const formatPayload = value => {
  const payload = { ...notEmptyPayload(value) }
  if (payload.bedrooms === 'Quartos') {
    delete payload.bedrooms
  }
  if (payload.city === 'Cidade' || payload.city === 'todas') {
    delete payload.city
  }
  if (payload.purposeType === 'Tipo de imóvel') {
    delete payload.purposeType
  }

  if (
    payload.neighborhoods &&
    payload.neighborhoods.length === 1 &&
    payload.neighborhoods.includes('todos')
  ) {
    delete payload.neighborhoods
  }

  if (payload.pets) {
    if (payload.pets === 'TANTO FAZ') {
      delete payload.pets
    } else {
      payload.pets = getPets(payload.pets)
    }
  }

  if (payload.furnished) {
    if (payload.furnished === 'TANTO FAZ') {
      delete payload.furnished
    } else {
      payload.furnished = getFurniture(payload.furnished)
    }
  }

  if (Array.isArray(payload.listingType) && payload.listingType[0] === 'both') {
    delete payload.listingType
  }

  if (payload.residential) {
    delete payload.residential
  }

  return payload
}

const getPayload = (query, version) => {
  const {
    city,
    neighborhoods,
    bedrooms,
    furnished,
    parkingSpots,
    price,
    listingType,
    pets,
    bathrooms,
    numberOfEnsuits,
    externalId,
    purposeType,
    taxesIncluded,
  } = query.toJS()

  return {
    bedrooms,
    price,
    parkingSpots,
    furnished: furnished === undefined ? undefined : getFurniture(furnished),
    listingType,
    city: city && city !== 'todas' ? city : undefined,
    neighborhoods: neighborhoods && !neighborhoods.includes('todos') ? neighborhoods : undefined,
    pets: getPets(pets),
    version,
    coordinates: undefined,
    bathrooms,
    numberOfEnsuits,
    externalId,
    purposeType,
    taxesIncluded,
  }
}

// Actions
export const setFilter = filter => dispatch =>
  dispatch({
    type: SET_FILTER,
    payload: {
      ...filter,
    },
  })

export const setScroll = scroll => dispatch =>
  dispatch({
    type: SET_SCROLL,
    payload: scroll,
  })

export const searchPropertiesFromRecommendations = () => (dispatch, getState) =>
  dispatch({
    type: GET_PROPERTIES.ACTION,
    payload: propertyService.getProperties(getState().user.authToken)(
      getPayload(getState().recommendations.filters),
      'v2'
    ),
    meta: { loadMore: false, version: 'v2', isRecommendation: true },
  })

export const searchProperties = () => (dispatch, getState) =>
  dispatch({
    type: GET_PROPERTIES.ACTION,
    payload: propertyService
      .getProperties(getState().user.authToken)(formatPayload(getState().filter.toJS()))
      .then(camelizeResponse),
    meta: { loadMore: false },
  })

export const getNextPage = (version = 'v1') => (dispatch, getState) =>
  dispatch({
    type: GET_PROPERTIES.ACTION,
    payload: propertyService
      .getProperties(getState().user.authToken)({
        ...formatPayload(getState().filter.toJS()),
        page: getState().search.nextPage,
      })
      .then(camelizeResponse),
    meta: { loadMore: true, version },
  })

export const setFilterModalFlag = () => dispatch =>
  dispatch({
    type: SET_FILTER_MODAL,
  })

export const resetSearch = () => dispatch =>
  dispatch({
    type: RESET_SEARCH,
  })

export const deleteOtherFilters = () => dispatch =>
  dispatch({
    type: DELETE_OTHER_FILTERS,
  })

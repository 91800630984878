import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import CloseIcon from '@material-ui/icons/Close'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'

import PictureIcon from '_assets/images/ic_imagem.svg'
import DocumentIcon from '_assets/images/mdi_file_copy.svg'
import { onMouseDown } from '_utils/helpers'

import styles from './styles.css'

const FileStatus = ({
  onRemoveClick,
  id,
  name,
  type,
  isUploading,
  percentage,
  hasError,
  preview,
}) => {
  const onDeleteClick = useCallback(
    event => {
      event.preventDefault()
      onRemoveClick(Number(event.currentTarget.id))
    },
    [onRemoveClick]
  )

  const icon = useMemo(() => (type.includes('image') ? PictureIcon : DocumentIcon), [type])

  const hasProgress = useMemo(() => isUploading || percentage === 100 || hasError, [
    hasError,
    isUploading,
    percentage,
  ])

  return (
    <div className={styles.file}>
      {hasProgress ? (
        <div className={styles.placeholder}>
          <CircularProgressbar
            value={percentage}
            styles={buildStyles({
              pathColor: '#32ba7c',
            })}
          />
        </div>
      ) : (
        <svg aria-label="Arquivo enviado" role="img" className={styles.placeholder}>
          <use xlinkHref={icon} />
        </svg>
      )}
      {preview ? (
        <a href={preview} target="_blank" rel="noopener noreferrer" className={styles.name}>
          {name}
        </a>
      ) : (
        <p className={styles.name}>{name}</p>
      )}

      <button
        type="button"
        aria-label="Remover arquivo"
        className={styles.action}
        onMouseDown={onMouseDown}
        onClick={onDeleteClick}
        id={id}
      >
        <CloseIcon aria-hidden="true" className={styles['close-icon']} />
      </button>
    </div>
  )
}

FileStatus.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  onRemoveClick: PropTypes.func,
  id: PropTypes.number,
  isUploading: PropTypes.bool,
  percentage: PropTypes.number,
  hasError: PropTypes.bool,
  preview: PropTypes.string,
}

FileStatus.defaultProps = {
  type: '',
  name: '',
  onRemoveClick: () => {},
  id: null,
  isUploading: false,
  percentage: 0,
  hasError: false,
  preview: '',
}

export default React.memo(FileStatus)

import React, { Fragment, useReducer, useCallback, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import { AppBar, Dialog, Grid, IconButton, Slide, Toolbar, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { useSelector, useDispatch } from 'react-redux'
import { navigate } from '@reach/router'
import CurrencyTextField from '@unicef/material-ui-currency-textfield/dist/CurrencyTextField'

import {
  PARTICIPANTS_INFO,
  PARTICIPANTS_TYPE,
  WARRANTY_TYPES,
  PARTICIPANTS_LABEL,
} from '_utils/constants'
import Button from '_components/material/button'
import Select from '_components/material/white-select'
import TextField from '_components/material/textfield'
import { arrayValuesShape, formShape } from '_utils/proptypes'
import { getWarrantySelector } from '_modules/warranty/selectors'
import {
  registerParticipant,
  REGISTER_PARTICIPANT,
  registerParticipantDocument,
  REGISTER_PARTICIPANT_DOCUMENT,
  UPDATE_REGISTERED_PARTICIPANT,
  updateRegisteredParticipant,
  deleteParticipantDocument,
  registerParticipantSpouse,
  REGISTER_PARTICIPANT_SPOUSE,
} from '_modules/leases/actions'
import useOnSuccessCall from '_hooks/use-on-success'
import useUploadDocument from '_hooks/use-upload-document'
import { CREDIT_ANALYSIS_ROUTES, creditAnalysisFlow } from '_views/credit-analysis/router'
import useCepAutoComplete from '_hooks/use-cep-autocomplete'

import UploadFileSection from './upload-file-section'
import {
  reducer,
  INITIAL_STATE,
  UPDATE,
  STEP,
  NEXT_STEP,
  SET_DOCUMENTS,
  DELETE_DOCUMENT,
  SET_INITIAL_STATE,
  DELETE_PREVIOUS_DOCUMENT,
  UPDATE_ADDRESS,
} from './reducer'
import useStyles from './styles'
import { INPUTS, TYPE } from './constants'

const MARRIED = 'married'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const ModalAddPerson = ({ onClose, type, warrantyType, participant, leaseId }) => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const [documentsPercentage, onUploadProgress] = useUploadDocument(
    REGISTER_PARTICIPANT_DOCUMENT.ACTION
  )
  const isCredpagoFlow = warrantyType === WARRANTY_TYPES.CREDPAGO
  const [state, dispatchLocal] = useReducer(reducer, { ...INITIAL_STATE, warrantyType })
  const [isCepLoading, data] = useCepAutoComplete(
    isCredpagoFlow ? state && state[state.step] && state[state.step][PARTICIPANTS_INFO.CEP] : ''
  )
  const typeOfDocuments = useSelector(globalState =>
    getWarrantySelector(globalState, [
      warrantyType,
      type,
      state[state.step][PARTICIPANTS_INFO.CONSIDER_INCOME],
    ])
  )

  const getTitle = useCallback(
    (spouse, isSecondaryTitle = false) => {
      if (spouse) {
        return 'cônjuge'
      }
      if (type === TYPE.TENANT) {
        return 'morador'
      }
      if (type === TYPE.INCOME_HELPER) {
        if (isSecondaryTitle) {
          return 'locatário'
        }
        return 'responsável'
      }
      return 'fiador'
    },
    [type]
  )

  const onSubmit = useCallback(
    event => {
      event.preventDefault()
      if (state.step === STEP.MAIN) {
        const { documents, previousDocuments, occupation, ...payload } = state[STEP.MAIN]
        payload.professionalData = { occupation }
        const participantId = participant && participant.id
        payload.category = type
        if (participantId) {
          dispatch(updateRegisteredParticipant(participantId, payload))
          return
        }
        dispatch(registerParticipant({ ...payload, category: type }))
      } else {
        const { documents, previousDocuments, ...spousePayload } = state[STEP.SPOUSE]
        const spouseId = participant && participant.spouse && participant.spouse.id
        if (spouseId) {
          dispatch(updateRegisteredParticipant(spouseId, spousePayload))
          return
        }
        dispatch(registerParticipantSpouse(spousePayload))
      }
    },
    [dispatch, participant, state, type]
  )

  const handleFinishDocuments = useCallback(() => {
    if (state.step === STEP.MAIN && state[STEP.MAIN][PARTICIPANTS_INFO.CIVIL_STATUS] === MARRIED) {
      dispatchLocal({ type: NEXT_STEP })
      return
    }
    if (type === PARTICIPANTS_TYPE.INCOME_HELPER) {
      navigate(creditAnalysisFlow(CREDIT_ANALYSIS_ROUTES.INCOME_HELPERS, leaseId))
    }
    if (type === PARTICIPANTS_TYPE.TENANT) {
      navigate(creditAnalysisFlow(CREDIT_ANALYSIS_ROUTES.TENANTS, leaseId))
    }
    if (type === PARTICIPANTS_TYPE.GUARANTOR) {
      navigate(creditAnalysisFlow(CREDIT_ANALYSIS_ROUTES.GUARANTORS, leaseId))
    }
    onClose()
  }, [leaseId, onClose, state, type])

  const handleSendClick = useCallback(() => {
    const { documents: documentsParticipant } = state[state.step]
    const documents = Object.entries(documentsParticipant)

    if (!documents.length) {
      handleFinishDocuments()
      return
    }
    // send documents to BE
    documents.forEach(async ([documentCategory, listOfDocuments]) => {
      if (!listOfDocuments.length) {
        return
      }

      const payload = {
        documentCategory,
        onUploadProgress: onUploadProgress({
          documentCategory,
          fileIndex: 0,
        }),
      }

      if (listOfDocuments.length > 1) {
        const files = []
        const filesNames = []
        listOfDocuments.forEach(fileMap => {
          files.push(fileMap)
          filesNames.push(fileMap.name.replace(',', ''))
        })
        payload.files = files
        payload.filesNames = filesNames.join()
      } else {
        // eslint-disable-next-line prefer-destructuring
        payload.file = listOfDocuments[0]
        payload.fileName = [listOfDocuments[0].name.replace(',', '')]
      }
      dispatch(registerParticipantDocument(payload))
    })
  }, [dispatch, handleFinishDocuments, onUploadProgress, state])
  const [isRegisteringParticipant] = useOnSuccessCall(REGISTER_PARTICIPANT.ACTION, handleSendClick)
  const [isUpdatingUserLoading] = useOnSuccessCall(
    UPDATE_REGISTERED_PARTICIPANT.ACTION,
    handleSendClick
  )
  const [isAddDocumentsLoading] = useOnSuccessCall(
    REGISTER_PARTICIPANT_DOCUMENT.ACTION,
    handleFinishDocuments
  )
  const [isRegisterSpouseLoading] = useOnSuccessCall(
    REGISTER_PARTICIPANT_SPOUSE.ACTION,
    handleFinishDocuments
  )

  const onChange = useCallback(
    ({ target: { name, value } }) => {
      dispatchLocal({
        type: UPDATE,
        payload: { name, value, step: state.step },
      })
    },
    [state.step]
  )

  const handleMonthlyIncome = useCallback(
    (_, value) => {
      dispatchLocal({
        type: UPDATE,
        payload: { name: PARTICIPANTS_INFO.MONTHLY_INCOME, value, step: state.step },
      })
    },
    [state.step]
  )

  const handleDocument = useCallback(
    event => {
      dispatchLocal({
        type: SET_DOCUMENTS,
        payload: { documentId: event.id, file: event.file, step: state.step },
      })
    },
    [state.step]
  )

  const handleDeleteDocument = useCallback(
    event => {
      const { id, name } = event.currentTarget
      dispatchLocal({
        type: DELETE_DOCUMENT,
        payload: { id, name, step: state.step },
      })
    },
    [state.step]
  )

  const handleDeletePreviousDocument = useCallback(
    event => {
      const { id, name } = event.currentTarget
      dispatchLocal({
        type: DELETE_PREVIOUS_DOCUMENT,
        payload: { id, name, step: state.step },
      })
      dispatch(deleteParticipantDocument(Number(name), participant.id))
    },
    [dispatch, participant, state.step]
  )

  const inputProps = useMemo(
    () => ({
      className: styles.input,
    }),
    [styles.input]
  )

  const secondSectionInfo = useMemo(
    () => (isCredpagoFlow ? INPUTS.SECTION_CREDPAGO : INPUTS.SECTION_2),
    [isCredpagoFlow]
  )

  useEffect(() => {
    if (participant && participant.fullName && !state[STEP.MAIN].fullName) {
      dispatchLocal({
        type: SET_INITIAL_STATE,
        payload: participant,
      })
    }
  }, [participant, state, state.step])

  useEffect(() => {
    if (data && data.address && !isCepLoading) {
      const { cep, ...completeAddress } = data
      dispatchLocal({
        type: UPDATE_ADDRESS,
        payload: completeAddress,
      })
    }
  }, [data, isCepLoading, state.step])

  const isButtonDisabled = Object.keys(state.error).length > 0
  const isLoading =
    isRegisteringParticipant ||
    isUpdatingUserLoading ||
    isAddDocumentsLoading ||
    isRegisterSpouseLoading

  return (
    <Dialog fullScreen open onClose={onClose} TransitionComponent={Transition}>
      <AppBar className={styles.appBar}>
        <Toolbar>
          <Typography component="h1" variant="h6" color="textPrimary" className={styles.title}>
            Cadastro {getTitle()}
          </Typography>
          <IconButton edge="start" aria-label="Fechar tela de detalhes" onClick={onClose}>
            <CloseIcon className={styles.root} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Grid component="form" onSubmit={onSubmit} className={styles.contentWrapper}>
        <Grid container className={styles.content} spacing={3}>
          <Grid item xs={12}>
            <Typography
              component="h2"
              variant="h3"
              color="textSecondary"
              className={styles.formTitle}
            >
              Dados do {getTitle(state.step === STEP.SPOUSE, true)}
            </Typography>
          </Grid>
          {INPUTS.SECTION_1.map(({ value, mask, ...sizes }) => (
            <Grid item xs={12} {...sizes} key={value}>
              <TextField
                label={PARTICIPANTS_LABEL[value]}
                value={state[state.step][value]}
                onChange={onChange}
                name={value}
                mask={mask}
                error={state.error && !!state.error[value]}
                helperText={state.error && state.error[value]}
              />
            </Grid>
          ))}
          <Grid item xs={12}>
            <Typography
              component="h2"
              variant="h4"
              color="textSecondary"
              className={styles.subsequentSections}
            >
              Informações pessoais
            </Typography>
          </Grid>
          {secondSectionInfo.map(({ value, mask, options, isCredpagoRelated, ...sizes }) => (
            <Grid item xs={12} {...sizes} key={value}>
              {options ? (
                <Select
                  className={styles.select}
                  value={state[state.step][value]}
                  onChange={onChange}
                  options={options}
                  label={PARTICIPANTS_LABEL[value]}
                  name={value}
                  error={state.error && !!state.error[value]}
                  helperText={state.error && state.error[value]}
                  disabled={isCredpagoRelated && isCepLoading}
                />
              ) : (
                <TextField
                  label={PARTICIPANTS_LABEL[value]}
                  value={state[state.step][value]}
                  onChange={onChange}
                  name={value}
                  mask={mask}
                  error={state.error && !!state.error[value]}
                  helperText={state.error && state.error[value]}
                  disabled={isCredpagoRelated && isCepLoading}
                />
              )}
            </Grid>
          ))}
          {type === PARTICIPANTS_TYPE.INCOME_HELPER && (
            <Fragment>
              <Grid item xs={12}>
                <Typography
                  component="h2"
                  variant="h4"
                  color="textSecondary"
                  className={styles.subsequentSections}
                >
                  Qual a sua renda mensal líquida?
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <CurrencyTextField
                  variant="outlined"
                  value={state[state.step][PARTICIPANTS_INFO.MONTHLY_INCOME]}
                  currencySymbol="R$"
                  outputFormat="number"
                  onChange={handleMonthlyIncome}
                  InputProps={inputProps}
                  fullWidth
                  className={styles.textField}
                  decimalCharacter=","
                  digitGroupSeparator="."
                  placeholder="0,00"
                />
              </Grid>
            </Fragment>
          )}
          <Grid item xs={12}>
            <Typography
              component="h2"
              variant="h4"
              color="textSecondary"
              className={styles.subsequentSections}
            >
              Documentos
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid className={styles.documentsWrapper}>
              {typeOfDocuments.map(document => (
                <UploadFileSection
                  key={document.value}
                  documentId={document.value}
                  sectionName={document.label}
                  handleDocument={handleDocument}
                  handleDeleteDocument={handleDeleteDocument}
                  files={state[state.step].documents}
                  previousFiles={state[state.step].previousDocuments}
                  handleDeletePreviousDocument={handleDeletePreviousDocument}
                  percentage={
                    Number(documentsPercentage.documentCategory) === document.value &&
                    documentsPercentage.percentage
                  }
                />
              ))}
            </Grid>
          </Grid>
        </Grid>
        <Grid className={styles.buttonWrapper}>
          <Button
            color="primary"
            variant="contained"
            className={styles.nextButton}
            disabled={isButtonDisabled}
            type="submit"
            isLoading={isLoading}
          >
            {state.step === STEP.MAIN &&
            state[STEP.MAIN][PARTICIPANTS_INFO.CIVIL_STATUS] === MARRIED
              ? 'Próximo'
              : 'Cadastrar'}
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  )
}

ModalAddPerson.propTypes = {
  onClose: PropTypes.func.isRequired,
  leaseId: PropTypes.string.isRequired,
  warrantyType: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.values(TYPE)),
  typeOfDocuments: arrayValuesShape.isRequired,
  participant: formShape,
}

ModalAddPerson.defaultProps = {
  type: TYPE.TENANT,
  participant: {},
}

export default ModalAddPerson

import { makeStyles } from '@material-ui/styles'

export default makeStyles(() => ({
  textField: {
    '& .MuiInputLabel-outlined': {
      fontSize: 14,
    },
  },
  input: {
    height: 48,
    marginBottom: 24,
    borderRadius: 24,
    outlinedInput: {
      '& .MuiInputAdornment-root.': {
        fontSize: 16,
      },
    },
  },
}))

import React from 'react'
import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form'

import Checkbox from '../checkbox'
import FormGroup from '../form-group'

import styles from './styles.css'

const CheckboxGroup = ({ label, name, values }) => {
  const { register } = useFormContext()

  return (
    <FormGroup label={label}>
      <div className={styles.container}>
        {values.map(({ value, label: checkboxLabel, id }) => (
          <Checkbox name={name} value={value} label={checkboxLabel} inputRef={register} key={id} />
        ))}
      </div>
    </FormGroup>
  )
}

CheckboxGroup.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      id: PropTypes.string.isRequired,
      label: PropTypes.string,
    })
  ),
}

CheckboxGroup.defaultProps = {
  label: '',
  name: '',
  values: {
    value: '',
    label: '',
  },
}

export default React.memo(CheckboxGroup)

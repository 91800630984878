import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ breakpoints, palette }) => ({
  container: {
    justifyContent: 'center',
    textAlign: 'center',
    width: '46.4rem',
    margin: 'auto auto 11rem',
    [breakpoints.down('sm')]: {
      width: '24.5rem',
      margin: 'auto auto 9.6rem',
    },
  },
  icon: {
    width: '30rem',
    height: '15rem',
    margin: 'auto',
    marginTop: '12rem',
    [breakpoints.down('sm')]: {
      width: '24rem',
    },
  },

  title: {
    marginTop: '4.6rem',
    [breakpoints.down('sm')]: {
      fontSize: '1.6rem',
      marginTop: '4rem',
    },
  },

  button: {
    maxWidth: '24.5rem',
    margin: 'auto',
  },

  buttonSecondary: {
    fontWeight: 'bold',
    maxWidth: '24.5rem',
    margin: '15.9rem auto auto',
    color: palette.secondary.dark,
  },

  description: {
    lineHeight: '1.5',
    color: palette.secondary.main,
    margin: '2.4rem auto 3.2rem',
    letterSpacing: 'normal',
    width: '100%',
    [breakpoints.down('sm')]: {
      width: '24.5rem',
      fontSize: '1.4rem',
      margin: '1.6rem auto 4rem',
    },
  },

  action: {
    padding: '1rem 2.4rem',
    margin: 'auto',
    width: '10.6rem',
  },
}))

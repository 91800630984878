import * as Sentry from '@sentry/browser'
import React, { useCallback, useContext, useEffect, useMemo, useReducer } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { navigate } from '@reach/router'

import { bookProperty, likeProperty } from '_modules/property/actions'
import {
  confirmPhoneChange,
  firebaseLogin,
  requestPhoneChange,
  updateUser,
  verifyPhoneLoginCode,
} from '_modules/user/actions'
import {
  getBookingError,
  getBookingLoading,
  getLikingProperty,
  hasLikedPropertyError,
} from '_modules/property/selectors'
import {
  getConfirmRequestFields,
  getConfirmRequestFieldsError,
  getLoadingFields,
  getLoadingFieldsError,
  getLoadingLogin,
  getLoginError,
  getRequestPhoneChangeLoading,
  getUserInfoSelector,
} from '_modules/user/selectors'
import { usePrevious, useWizardForm } from '_utils/hooks'
import { FEATURE_FLAGS } from '_utils/constants'
import PrivacyPolicyLink from '_components/privacy-policy-link'
import { RedirectContext } from '_context/redirect'
import { getBookingsSelector } from '_modules/booking/selectors'
import { moment } from '_bootstrap'

import InsertCode from './insert-code'
import NewUser from './new-user'
import RecoverNumber from './recover-number'
import ReesendCode from './reesend-code'
import SendEmail from './send-email'
import SignUp from './sign-up'
import {
  STEP_FIVE,
  STEP_FOUR,
  STEP_ONE,
  STEP_SIX,
  STEP_THREE,
  STEP_TWO,
  handleOnSubmit,
} from './utils'
import { ACTION_TYPES, INITIAL_STATE, reducer } from './reducer'
import styles from './styles.css'

const Login = ({ location }) => {
  const {
    redirectUri,
    setRedirectUri,
    bookingState,
    setBookingState,
    likedProperty,
    setLikedProperty,
  } = useContext(RedirectContext)
  const { UPDATE_FORM, TRY_AGAIN_LATER, UPDATE_ERROR_MESSAGE } = ACTION_TYPES
  const { currentPage, nextPage, setCurrent } = useWizardForm(STEP_ONE, STEP_SIX)
  const [userInfo, dispatchLocal] = useReducer(reducer, INITIAL_STATE)
  const dispatch = useDispatch()
  const wasLoading = usePrevious(userInfo.loading)
  const user = useSelector(getUserInfoSelector)
  const booking = useSelector(getBookingsSelector)

  const loadingLogin = useSelector(getLoadingLogin)
  const wasLoadingLogIn = usePrevious(loadingLogin)
  const errorLogin = useSelector(getLoginError)

  const loadingFields = useSelector(getLoadingFields)
  const wasLoadingFields = usePrevious(loadingFields)
  const loadingFieldsError = useSelector(getLoadingFieldsError)

  const isRequestPhoneChangeLoading = useSelector(getRequestPhoneChangeLoading)
  const wasRequestPhoneChangeLoading = usePrevious(isRequestPhoneChangeLoading)

  const isConfirmRequestLoading = useSelector(getConfirmRequestFields)
  const wasConfirmRequestLoading = usePrevious(isConfirmRequestLoading)
  const ConfirmRequestLoadingError = useSelector(getConfirmRequestFieldsError)

  const isBookingLoading = useSelector(getBookingLoading)
  const wasBookingLoading = usePrevious(isBookingLoading)
  const bookingError = useSelector(getBookingError)

  const isLikingProperty = useSelector(getLikingProperty)
  const wasLikingProperty = usePrevious(isLikingProperty)
  const hasLikingPropertyError = useSelector(hasLikedPropertyError)

  const changeNumberKey = useMemo(
    () =>
      location.search.includes('?key=') || location.search.includes('&key=')
        ? location.search.match(/[?&]key=([^&#]*)/)[1]
        : undefined,
    [location.search]
  )

  const changeNumberUid = useMemo(
    () =>
      location.search.includes('?uid=') || location.search.includes('&uid=')
        ? location.search.match(/[?&]uid=([^&#]*)/)[1]
        : undefined,
    [location.search]
  )

  const isLoadingCode = useMemo(() => userInfo.loading || loadingLogin, [
    loadingLogin,
    userInfo.loading,
  ])

  const userLogin = useCallback(
    idToken => {
      dispatchLocal({
        type: UPDATE_FORM,
        payload: {
          idToken,
        },
      })
      if (changeNumberKey) {
        dispatch(confirmPhoneChange({ token: changeNumberKey, uid: changeNumberUid, idToken }))
      } else {
        dispatch(firebaseLogin({ idToken }))
      }
    },
    [UPDATE_FORM, changeNumberKey, changeNumberUid, dispatch]
  )

  const onSubmit = useCallback(
    event => {
      event.preventDefault()
      handleOnSubmit({
        userInfo,
        dispatch,
        dispatchLocal,
        Sentry,
        currentPage,
        ACTION_TYPES,
        requestPhoneChange,
        verifyPhoneLoginCode,
        updateUser,
        userLogin,
        setCurrent,
        changeNumberKey,
        changeNumberUid,
        isCustomPhoneAuthenticationEnabled: true,
      })
    },
    [changeNumberKey, changeNumberUid, currentPage, dispatch, setCurrent, userInfo, userLogin]
  )

  const onUserChange = useCallback(
    event => {
      const { name, value } = event.target
      const payload = {
        [name]: value,
      }

      dispatchLocal({
        type: UPDATE_FORM,
        payload,
      })
    },
    [UPDATE_FORM]
  )

  useEffect(() => {
    if (user.fullName) {
      const { fullName, cpf, email } = user
      const payload = { fullName, cpf, email }
      dispatchLocal({
        type: UPDATE_FORM,
        payload,
      })
    }
  }, [UPDATE_FORM, user])

  const onStepChange = useCallback(step => () => setCurrent(step), [setCurrent])

  const isRegisterProperty = useMemo(
    () =>
      location.state &&
      location.state.nextStep &&
      location.state.nextStep.includes('/anunciar-imovel'),
    [location.state]
  )

  const singUpError = useMemo(() => {
    if (bookingError && bookingError.size > 0) {
      return bookingError.get('error_description')
    }
    if (loadingFieldsError && loadingFieldsError.size > 0) {
      return loadingFieldsError.get('error_description')
    }
    return ''
  }, [bookingError, loadingFieldsError])

  const renderLoginSteps = useMemo(() => {
    switch (currentPage) {
      case STEP_ONE:
        return (
          <NewUser
            userInfo={userInfo}
            onChange={onUserChange}
            onNumberChange={onStepChange(STEP_FOUR)}
            changeNumberKey={changeNumberKey}
          />
        )
      case STEP_TWO:
        return (
          <InsertCode
            userInfo={userInfo}
            onNumberChange={onStepChange(STEP_SIX)}
            onChange={onUserChange}
            isLoading={isLoadingCode}
          />
        )
      case STEP_THREE:
        return (
          <SignUp
            userInfo={userInfo}
            onChange={onUserChange}
            isLoading={loadingFields || isBookingLoading}
            isRegisterProperty={isRegisterProperty}
            error={singUpError}
          />
        )
      case STEP_FOUR:
        return (
          <SendEmail
            userInfo={userInfo}
            onChange={onUserChange}
            onBackClick={onStepChange(STEP_ONE)}
            isLoading={isRequestPhoneChangeLoading}
          />
        )
      case STEP_FIVE:
        return <RecoverNumber userInfo={userInfo} onLoginClick={onStepChange(STEP_ONE)} />
      default:
        return (
          <ReesendCode
            userInfo={userInfo}
            onNumberChange={onStepChange(STEP_ONE)}
            isLoading={isLoadingCode}
          />
        )
    }
  }, [
    changeNumberKey,
    currentPage,
    isBookingLoading,
    isLoadingCode,
    isRegisterProperty,
    isRequestPhoneChangeLoading,
    loadingFields,
    onStepChange,
    onUserChange,
    singUpError,
    userInfo,
  ])

  useEffect(() => {
    if (location.state && location.state.redirectUri && !redirectUri) {
      setRedirectUri(location.state.redirectUri)
    }
  }, [location.state, redirectUri, setRedirectUri])

  useEffect(() => {
    if (!userInfo.loading && !loadingLogin && wasLoadingLogIn) {
      if (errorLogin) {
        if (FEATURE_FLAGS.customPhoneAuthenticationEnabled) {
          dispatchLocal({
            type: UPDATE_ERROR_MESSAGE,
            payload: {
              error: 'Código Inválido',
            },
          })
          return
        }
        dispatchLocal({
          type: TRY_AGAIN_LATER,
        })
        return
      }
      nextPage()

      if (changeNumberKey !== null && typeof changeNumberKey !== 'undefined') {
        nextPage()
      }
    }
  }, [
    errorLogin,
    loadingFields,
    loadingLogin,
    changeNumberKey,
    wasLoadingLogIn,
    userInfo.error,
    userInfo.loading,
    wasLoadingFields,
    nextPage,
    userInfo,
    wasLoading,
    TRY_AGAIN_LATER,
    UPDATE_ERROR_MESSAGE,
  ])

  useEffect(
    () => {
      if (!loadingFields && wasLoadingFields) {
        if (loadingFieldsError) {
          dispatchLocal({
            type: TRY_AGAIN_LATER,
          })
          return
        }

        if (likedProperty) {
          dispatch(likeProperty(likedProperty))
          return
        }
        if (bookingState) {
          const {
            saveQuery,
            property,
            time,
            date,
            source,
            sourceId,
            anonymousId,
            staffHash,
            recommendationHash,
          } = bookingState
          /* 
          Actually the way time is being formated
          with timezone is a mystery so we subtract 3
          to fix the timezone issue but need a better solution 
          */
          const payload = {
            listing: property && property.externalId,
            dateAndTime: moment(date)
              .set({
                hour: time[0],
                minute: time[1],
                second: 0,
                millisecond: 0,
              })
              .add(moment().isDST() ? +1 : 0, 'hour')
              .toISOString(),
            filters: saveQuery || {},
            source,
            sourceId,
            anonymousId,
            staffHash,
            recommendationHash,
          }
          dispatch(bookProperty(payload))

          return
        }

        if (redirectUri) {
          navigate(redirectUri)
          setRedirectUri(null)
          return
        }

        navigate('/')
      }
    },
    // eslint-disable-next-line
    [loadingFields]
  )

  useEffect(() => {
    const { idToken } = userInfo
    if (!isConfirmRequestLoading && wasConfirmRequestLoading && !ConfirmRequestLoadingError) {
      dispatch(firebaseLogin({ idToken }))
    } else if (ConfirmRequestLoadingError) {
      dispatchLocal({
        type: UPDATE_ERROR_MESSAGE,
        payload: {
          error:
            'Link inválido, cheque a sua caixa de mensagem e abra o link do email mais recente',
        },
      })
    }
  }, [
    ConfirmRequestLoadingError,
    UPDATE_ERROR_MESSAGE,
    UPDATE_FORM,
    dispatch,
    isConfirmRequestLoading,
    userInfo,
    wasConfirmRequestLoading,
  ])

  useEffect(() => {
    if (wasLikingProperty && !isLikingProperty && !hasLikingPropertyError) {
      navigate(redirectUri)
      setRedirectUri(null)
      setLikedProperty(null)
    }
  }, [
    hasLikingPropertyError,
    isLikingProperty,
    redirectUri,
    setLikedProperty,
    setRedirectUri,
    wasLikingProperty,
  ])

  useEffect(() => {
    if (!isBookingLoading && wasBookingLoading && !bookingError.size) {
      navigate(redirectUri, { state: bookingState })
      const firstBooking = booking && booking.first()
      setRedirectUri(null)
      setBookingState({
        bookingId: firstBooking.id,
        leaseId: firstBooking.lease.get('id'),
      })
    }
  }, [
    booking,
    bookingError.size,
    bookingState,
    isBookingLoading,
    redirectUri,
    setBookingState,
    setRedirectUri,
    wasBookingLoading,
  ])

  useEffect(() => {
    if (!isRequestPhoneChangeLoading && wasRequestPhoneChangeLoading) {
      nextPage()
    }
  }, [isRequestPhoneChangeLoading, nextPage, wasRequestPhoneChangeLoading])

  useEffect(() => {
    if (currentPage === STEP_ONE && user.fullName) {
      setCurrent(STEP_THREE)
    }
  }, [currentPage, setCurrent, user.fullName])

  return (
    <section className={styles.wrapper}>
      <form onSubmit={onSubmit} className={styles.content}>
        {renderLoginSteps}
        {currentPage < STEP_FOUR && <PrivacyPolicyLink />}
      </form>
    </section>
  )
}

Login.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
    state: PropTypes.shape({
      nextStep: PropTypes.string,
      redirectUri: PropTypes.string,
      saveQuery: PropTypes.shape(),
      property: PropTypes.shape(),
      date: PropTypes.string,
      time: PropTypes.arrayOf(PropTypes.string),
      pictures: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          picture_url: PropTypes.string,
        })
      ),
      source: PropTypes.string,
      sourceId: PropTypes.string,
      anonymousId: PropTypes.string,
      staffHash: PropTypes.string,
      recommendationHash: PropTypes.string,
    }),
  }).isRequired,
}

export default React.memo(Login)

import { makeStyles } from '@material-ui/styles'

export default makeStyles(() => ({
  textfield: {
    marginBottom: '2.4rem',
    '& .MuiInputLabel-outlined': {
      fontSize: '1.4rem',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '2.4rem',
      height: '4.8rem',
    },
  },
}))

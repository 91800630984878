import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'

import { SEARCH_SORT_OPTIONS } from '_utils/constants'

import useMaterial from './materialStyle'
import styles from './styles.css'

const ReorderButton = ({ className, reorderProperties }) => {
  const materialStyle = useMaterial()

  const [selectedOrder, setSelectedOrder] = useState({ value: 'Ordenar por', label: 'Ordenar por' })

  const onChangeOrder = useCallback(
    event => {
      const { value } = event.target

      const order = SEARCH_SORT_OPTIONS.find(option => option.value === value)
      setSelectedOrder(order)
      reorderProperties(order.value)
    },
    [reorderProperties]
  )

  return (
    <section className={classnames(styles.container, className)}>
      <TextField
        id="outlined-select-sort"
        select
        value={selectedOrder ? selectedOrder.value : 'Ordenar por'}
        onChange={onChangeOrder}
        className={classnames(styles['input-sort-by'], materialStyle.inputSortBy)}
        margin="normal"
        variant="outlined"
        name="sort"
      >
        <MenuItem key="sort" value="Ordenar por" disabled>
          Ordenar por
        </MenuItem>
        {SEARCH_SORT_OPTIONS.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </section>
  )
}

ReorderButton.propTypes = {
  className: PropTypes.string,
  reorderProperties: PropTypes.func,
}

ReorderButton.defaultProps = {
  className: '',
  reorderProperties: () => {},
}

export default React.memo(ReorderButton)

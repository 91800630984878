import { Map, List } from 'immutable'

import NoPicture from '_assets/images/ic-no-picture.jpg'
import { createReducer } from '_utils/redux'
import { GET_PROPERTIES } from '_modules/search/actions'
import { GET_HIGHLIGHTS } from '_modules/highlights/actions'
import { Property } from '_models/'
import { LOGOUT } from '_modules/user/actions'

import { GET_PROPERTY } from './actions'

// Initial State
const INITIAL_STATE = Map()

const NO_PICTURE_OBJECT = Map({
  picture: NoPicture,
  picture800x600: NoPicture,
  thumbnail: NoPicture,
})

const mergeFunction = (prev, next) => prev || next
// Reducer
const properties = createReducer(INITIAL_STATE, {
  [GET_PROPERTY.FULFILLED]: (state, { payload }) => {
    const property = new Property(payload)

    return state.setIn(
      [property.externalId],
      property.convertedPictures.size === 0
        ? property.set('convertedPictures', List([NO_PICTURE_OBJECT]))
        : property
    )
  },

  [GET_PROPERTIES.FULFILLED]: (state, { payload }) => {
    const mappedProperties = Map(payload.result)

    const newProperties = mappedProperties.mergeDeep(
      mappedProperties
        .filter(property => property.convertedPictures.size === 0)
        .map(property => property.set('convertedPictures', List([NO_PICTURE_OBJECT])))
    )

    return state.mergeDeepWith(mergeFunction, newProperties)
  },

  [GET_HIGHLIGHTS.FULFILLED]: (state, { payload }) => {
    const mappedProperties = Map(payload.result)

    const newProperties = mappedProperties.mergeDeep(
      mappedProperties
        .filter(property => property.convertedPictures.size === 0)
        .map(property => property.set('convertedPictures', List([NO_PICTURE_OBJECT])))
    )

    return state.mergeDeepWith(mergeFunction, newProperties)
  },

  [LOGOUT]: () => INITIAL_STATE,
})

export default properties

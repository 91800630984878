import { makeStyles } from '@material-ui/styles'

export default makeStyles(() => ({
  title: {
    marginBottom: '4rem',
  },
  slider: {
    maxWidth: '104.0rem',
  },
  card: {
    margin: 'auto',
    textAlign: 'left',
  },
}))

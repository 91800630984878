import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import CheckIcon from '_assets/images/ic-check-cor-prim-ria.svg'
import ClearIcon from '_assets/images/mdi_clear.svg'
import { primaryColor } from '_utils/colors'

import styles from './styles.css'

const CardItem = ({ item, competitor }) => {
  const icon = useMemo(
    () => (
      <svg
        style={{ fill: primaryColor, stroke: primaryColor }}
        aria-hidden="true"
        className={classnames(styles['plan-list-icon'], {
          [styles['plan-competitor']]: competitor,
        })}
      >
        <use xlinkHref={competitor ? ClearIcon : CheckIcon} />
      </svg>
    ),
    [competitor]
  )

  return (
    <li className={styles['plan-item']}>
      {icon}
      {item}
    </li>
  )
}

CardItem.propTypes = {
  item: PropTypes.string.isRequired,
  competitor: PropTypes.bool,
}

CardItem.defaultProps = {
  competitor: false,
}

export default React.memo(CardItem)

import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, navigate } from '@reach/router'

import Button, { ButtonColor, ButtonVariant, ButtonFormat } from '_components/button'
import IconDocument from '_assets/images/ic-doc-approved.svg'
import withAuth from '_hocs/with-auth'
import ModalNegotiation from '_components/modal-negotiation'
import ModalReserved from '_components/modal-reserved'
import { getLease } from '_modules/leases/actions'
import { getLeaseById } from '_modules/leases/selectors'

import styles from './styles.css'

const ProposalApproved = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const user = useSelector(state => state.user)
  const lease = useSelector(state => getLeaseById(state, id))
  const reserved = useMemo(() => lease && lease.getIn(['listing', 'reserved']), [lease])
  const externalId = useMemo(() => lease && lease.getIn(['listing', 'externalId']), [lease])

  const [closeDeal, setCloseDeal] = useState(false)
  const [reservedModal, setReservedModal] = useState(false)

  const onCloseModal = useCallback(() => {
    setCloseDeal(false)
    setReservedModal(false)
  }, [])

  useEffect(() => {
    if (!lease) {
      dispatch(getLease(id))
    }
  }, [dispatch, id, lease])

  const onClickConfirm = useCallback(() => {
    if (reserved) {
      setReservedModal(true)
      return
    }

    if (user.activeLease && user.activeLease !== id) {
      if (!closeDeal) {
        setCloseDeal(true)
        return
      }
    }

    setCloseDeal(false)

    navigate(`/minhas-negociacoes/${externalId}`, {
      state: {
        lease: lease && lease.toJS(),
      },
    })
  }, [closeDeal, externalId, id, lease, reserved, user.activeLease])

  return (
    <div className={styles.container}>
      {closeDeal && (
        <ModalNegotiation
          isOpen={closeDeal}
          onClose={onCloseModal}
          onConfirmNegotiation={onClickConfirm}
        />
      )}
      <ModalReserved openModal={reservedModal} closeModal={onCloseModal} />
      <div className={styles.content}>
        <svg aria-label="Ícone de um documento" className={styles.icon}>
          <use xlinkHref={IconDocument} />
        </svg>
        <p className={styles.title}>Eba! Sua proposta foi aprovada pelo proprietário.</p>
        <p className={styles['sub-title']}>
          A próxima etapa é enviar os <span>seus dados e documentos para a análise cadastral.</span>
        </p>
        <Button
          color={ButtonColor.PRIMARY}
          variant={ButtonVariant.BOLD}
          ButtonFormat={ButtonFormat.ROUNDED}
          onClick={onClickConfirm}
          className={styles.button}
        >
          INICIAR NEGOCIAÇÃO
        </Button>
      </div>
    </div>
  )
}

export default withAuth(React.memo(ProposalApproved))

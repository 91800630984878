import { get, post, patch, del } from './requests'

export const sendProposal = key => (id, payload) =>
  post(['leases', id, 'proposals'], { key }, payload)

export const deleteProposal = key => (idLease, idProposal) =>
  del(['user-proposals', idProposal], { key })

export const sendValue = key => (leaseId, proposalId, payload) =>
  post(['leases', leaseId, 'proposals', proposalId, 'request-price'], { key }, payload)

export const sendModifications = key => (leaseId, proposalId, payload) =>
  post(['leases', leaseId, 'proposals', proposalId, 'request-modification'], { key }, payload)

export const sendApprovationValue = key => (leaseId, proposalId, payload) =>
  patch(['leases', leaseId, 'proposals', proposalId, 'request-price'], { key }, payload)

export const sendApprovationModification = key => (leaseId, proposalId, payload) =>
  patch(['leases', leaseId, 'proposals', proposalId, 'request-modification'], { key }, payload)

export const getAllProposals = key => get(['user-proposals'], { key })

import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ palette, typography }) => ({
  dialog: {
    '& .MuiDialog-paperScrollPaper': {
      maxWidth: '65rem',
    },
  },
  dialogTitle: {
    ...typography.h4,
    padding: '3.2rem 2.4rem 0',
    color: palette.text.primary,
  },
  dialogContent: {
    ...typography.body2,
  },
  actions: {
    padding: 0,
    color: palette.text.primary,
    textTransform: 'uppercase',
    display: 'grid',
    gridTemplateColumns: '1fr 0.01rem 1fr',
  },
  actionButton: {
    color: palette.text.primary,
    textTransform: 'uppercase',
    padding: '1.6rem',
  },
}))

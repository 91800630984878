import React, { useEffect, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { navigate } from '@reach/router'

import Button, { ButtonColor, ButtonVariant, ButtonFormat } from '_components/button'
import MobileClickIcon from '_assets/images/mobile-click.svg'
import withAuth from '_hocs/with-auth'
import { getLeaseById } from '_modules/leases/selectors'
import { getLease } from '_modules/leases/actions'
import Building from '_components/building-animated'
import { FEATURE_FLAGS, WARRANTY_TYPES } from '_utils/constants'

import styles from './styles.css'

const Warranty = ({ id }) => {
  const dispatch = useDispatch()
  const lease = useSelector(state => getLeaseById(state, id))

  const request = useMemo(() => {
    const warrantyRequest = lease && lease.getIn(['warrantyRequest', 'comment'])

    return warrantyRequest || ''
  }, [lease])

  useEffect(() => {
    if (!lease && id) {
      dispatch(getLease(id))
    }
  }, [dispatch, id, lease])

  const onClickChoose = useCallback(() => {
    navigate(`/minhas-negociacoes/${id}/garantia/escolha`)
  }, [id])

  const inviteLinkTarget = useMemo(() => {
    const target = {
      url: '',
      state: {},
    }

    if (FEATURE_FLAGS.creditAnalysisIntegrationEnabled) {
      target.url = '/minhas-negociacoes/renda-mensal'
      target.state = lease && { externalId: lease.getIn(['listing', 'externalId']) }
    } else {
      target.url = `/minhas-negociacoes/${id}/convidar`
    }

    return target
  }, [id, lease])

  const isFirstChoice = useMemo(() => {
    return !!lease && lease.getIn(['warranty']) === WARRANTY_TYPES.RAPID
  }, [lease])

  if (!lease) return null

  return (
    <section className={styles.container}>
      {!lease ? (
        <Building className={styles.building} />
      ) : (
        <div className={styles.content}>
          <svg aria-label="Ícone de celular" className={styles.icon}>
            <use xlinkHref={MobileClickIcon} />
          </svg>
          <h1 className={styles.title}>Vamos tentar {isFirstChoice ? 'uma' : 'outra'} garantia?</h1>
          <p className={styles['sub-title']}>
            Não conseguimos aprovar a sua análise. Confira {isFirstChoice ? 'nossas' : 'outras'}{' '}
            modalidades de garantia disponíveis!
          </p>
          {request && (
            <div className={styles.justification}>
              <h2 className={styles.title}>Justificativa</h2>
              <p className={styles.comment}>{request}</p>
            </div>
          )}
          <Button
            color={ButtonColor.PRIMARY}
            variant={ButtonVariant.BOLD}
            ButtonFormat={ButtonFormat.ROUNDED}
            className={styles.button}
            onClick={onClickChoose}
          >
            ESCOLHER
          </Button>
          <div className={styles.warranty}>
            <p className={styles['warranty-title']}>Caso queira, tente uma nova análise</p>
            <p>
              Você também pode inserir novas pessoas para comprovar renda com a garantia anterior.
            </p>
            <div className={styles.line} />
            <Button
              className={styles['button-warranty']}
              to={inviteLinkTarget.url}
              state={inviteLinkTarget.state}
              color={ButtonColor.PRIMARY_TEXT}
            >
              TENTAR NOVA ANÁLISE
            </Button>
          </div>
        </div>
      )}
      {/* TODO add this button when we've the cancel concept in backend */}
      {/* <Button
        color={ButtonColor.TEXT_BLACK}
        variant={ButtonVariant.BOLD}
        ButtonFormat={ButtonFormat.ROUNDED}
        className={styles.button}
      >
        CANCELAR NEGOCIAÇÃO
      </Button> */}
    </section>
  )
}

Warranty.propTypes = {
  id: PropTypes.number.isRequired,
}

export default withAuth(React.memo(Warranty))

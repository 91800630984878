import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'

import styles from './styles.css'

const PrevArrow = ({ onClick, visible, className }) => (
  <div className={classnames(styles['arrow-container'], { [styles.visible]: visible })}>
    <button type="button" onClick={onClick} className={classnames(className, styles.button)}>
      <NavigateBeforeIcon aria-label="previous" className={styles.icon} />
      Anterior
    </button>
  </div>
)

PrevArrow.propTypes = {
  onClick: PropTypes.func,
  visible: PropTypes.bool,
  className: PropTypes.string,
}

PrevArrow.defaultProps = {
  onClick: () => {},
  visible: true,
  className: '',
}

export default PrevArrow

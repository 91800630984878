import React, { useCallback, useState, useEffect, useMemo } from 'react'
import TextField from '@material-ui/core/TextField'
import { useFormik } from 'formik'
import InputMask from 'react-input-mask'
import { navigate } from '@reach/router'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'

import { usePrevious } from '_utils/hooks'
import {
  checkIsSendingParticipantsData,
  getLeaseById,
  checkIsUpdatingUserData,
} from '_modules/leases/selectors'
import withAuthOrIncomeHelper from '_hocs/with-auth-or-income-helper'
import Button, { ButtonVariant, ButtonFormat, ButtonColor } from '_components/button'
import {
  getLease,
  updateUserData,
  sendParticipantsData,
  getParticipantLease,
} from '_modules/leases/actions'
import { getIncomeHelper } from '_modules/income-helper/selectors'
import { LEASE_STATUS, CIVIL_STATUS_NAMES } from '_utils/constants'

import WarrantyType from '../warranty-type'
import useStyles from '../styles'

import styles from './styles.css'

const INITIAL_VALUES = {
  occupation: '',
  companyName: '',
  cnpj: '',
  address: '',
  complement: '',
  neighborhood: '',
  city: '',
  building: '',
  phoneNumber: '',
  cep: '',
}

const SubscribeGuarantorStepThree = ({ id, location }) => {
  const classes = useStyles()
  const [isLoadingCEP, setLoadingCEP] = useState(false)
  const [cep, setCep] = useState(() => {})
  const [address, setAddress] = useState('')
  const dispatch = useDispatch()

  // Get current lease
  const activeLease = useSelector(state => getLeaseById(state, id))

  // Tenant data
  const isTenantLoading = useSelector(checkIsUpdatingUserData)
  const wasTenantLoading = usePrevious(isTenantLoading)

  // Participant
  const token = useMemo(
    () =>
      location.search.includes('?token=') || location.search.includes('&token=')
        ? location.search.match(/[?&]token=([^&#]*)/)[1]
        : undefined,
    [location.search]
  )
  const currentIncomeHelper = useSelector(getIncomeHelper)
  const isParticipantLoading = useSelector(checkIsSendingParticipantsData)
  const wasParticipantLoading = usePrevious(isParticipantLoading)
  const wasAskingProfessionalData = usePrevious(
    activeLease && activeLease.hasRequestedProfessionalData
  )
  const wasAskingProfessionalDataParticipant = usePrevious(
    currentIncomeHelper.hasRequestedProfessionalData
  )

  // Get current lease
  useEffect(() => {
    if (!token && !activeLease) {
      dispatch(getLease(id))
    }
  }, [activeLease, dispatch, id, token])

  // Get participant
  useEffect(() => {
    if (token && !currentIncomeHelper.id) {
      dispatch(
        getParticipantLease({
          leaseId: id,
          token,
        })
      )
    }
  }, [currentIncomeHelper.id, dispatch, id, token])

  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit: () => {
      const payload = {
        leaseId: id,
        professionalData: {
          ...formik.values,
          cep: address ? address.replace('-', '') : '',
          phoneNumber:
            formik.values.phoneNumber && formik.values.phoneNumber.replace(/[^0-9+]+/g, ''),
        },
      }
      if (token) {
        dispatch(sendParticipantsData({ ...payload, token }))
        return
      }

      const externalId = activeLease.listing.get('externalId')
      dispatch(
        updateUserData({
          ...payload,
          externalId,
        })
      )
    },
  })
  const { handleChange, handleSubmit, errors, setValues, touched, values, setFieldValue } = formik

  useEffect(() => {
    import('cep-promise').then(module => {
      setCep(module)
    })
  }, [])

  const findZipCode = useCallback(
    event => {
      const { value } = event.target
      setAddress(value)
      if (value.replace(/[^0-9]+/g, '').length === 8) {
        setLoadingCEP(true)
        cep
          .default(value.replace(/[^0-9]+/g, ''))
          .then(info => {
            const { city, neighborhood, street } = info
            setLoadingCEP(false)
            if (city) {
              setValues({
                ...formik.values,
                city,
                neighborhood,
                address: street,
                cep: value,
              })
            }
          })
          .catch(() => {
            setLoadingCEP(false)
            setFieldValue('cep', value)
          })
      }
    },
    [cep, formik.values, setFieldValue, setValues]
  )

  const handleInitialState = useCallback(
    user => {
      const initialValue = Object.keys(INITIAL_VALUES).reduce(
        (obj, item) => Object.assign(obj, { [item]: user.professionalData[item] }),
        {}
      )
      if (initialValue.cep !== null) {
        const newCep = [initialValue.cep.slice(0, 5), '-', initialValue.cep.slice(5)].join('')
        setAddress(newCep)
      }
      setValues(initialValue)
    },
    [setValues]
  )

  useEffect(() => {
    if (
      !token &&
      activeLease &&
      activeLease.user.get('professionalData') &&
      activeLease.user.toJS().professionalData.occupation &&
      values.occupation === ''
    ) {
      handleInitialState(activeLease.user.toJS())
    }
  }, [
    activeLease,
    handleInitialState,
    setFieldValue,
    setValues,
    token,
    values.fullName,
    values.occupation,
  ])

  useEffect(() => {
    if (token && currentIncomeHelper.professionalData && values.occupation === '') {
      handleInitialState(currentIncomeHelper.toJS())
    }
  }, [
    activeLease,
    currentIncomeHelper,
    handleInitialState,
    setValues,
    token,
    values.fullName,
    values.occupation,
  ])

  useEffect(() => {
    if (!isTenantLoading && wasTenantLoading) {
      const { user, status } = activeLease
      const hasRequested = location.state ? location.state.wasAskingProfessionalData : false
      if (user.get('civilStatus') !== CIVIL_STATUS_NAMES.MARRIED) {
        if (
          wasAskingProfessionalData ||
          hasRequested ||
          status === LEASE_STATUS.INITIALLY_APPROVED
        ) {
          navigate(`/garantia/cadastro-finalizado`)
          return
        }
        navigate(`/garantia/${id}/convidar-participantes`)
        return
      }
      navigate(`/garantia/${id}/registrar-contratante-passo-4`, {
        state: location.state,
      })
    }
  }, [
    activeLease,
    id,
    isTenantLoading,
    location.state,
    wasAskingProfessionalData,
    wasTenantLoading,
  ])

  useEffect(() => {
    if (!isParticipantLoading && wasParticipantLoading) {
      if (
        currentIncomeHelper.civilStatus !== CIVIL_STATUS_NAMES.MARRIED ||
        wasAskingProfessionalDataParticipant
      ) {
        navigate(`/garantia/cadastro-finalizado`)
        return
      }
      navigate(`/garantia/${id}/registrar-contratante-passo-4/?token=${token}`)
    }
  }, [
    activeLease,
    currentIncomeHelper,
    id,
    isParticipantLoading,
    token,
    wasAskingProfessionalDataParticipant,
    wasParticipantLoading,
  ])

  return (
    <section className={styles.container}>
      {activeLease && !token && <WarrantyType type={activeLease.warranty} />}
      <h1 className={styles.title}>Informe seus dados</h1>
      <p className={styles.description}>Informe os seus dados profissionais para o cadastro.</p>
      <form onSubmit={handleSubmit} className={styles.form}>
        <div className={styles['main-infos']}>
          <h2 className={styles.type}>Dados profissionais</h2>
          <TextField
            className={classes.textField}
            margin="normal"
            placeholder="Ocupação"
            label="Ocupação"
            error={touched.occupation && !!errors.occupation}
            helperText={touched.occupation && errors.occupation}
            onChange={handleChange}
            value={values.occupation}
            variant="outlined"
            name="occupation"
          />
          <TextField
            className={classes.textField}
            margin="normal"
            placeholder="Nome da empresa"
            label="Nome da empresa"
            error={touched.companyName && !!errors.companyName}
            helperText={touched.companyName && errors.companyName}
            onChange={handleChange}
            value={values.companyName}
            variant="outlined"
            name="companyName"
          />
          <InputMask
            mask="99.999.999/9999-99"
            className={classes.textField}
            margin="normal"
            placeholder="CNPJ"
            label="CNPJ"
            error={touched.cnpj && !!errors.cnpj}
            helperText={touched.cnpj && errors.cnpj}
            onChange={handleChange}
            value={values.cnpj}
            variant="outlined"
            name="cnpj"
          >
            {inheritedProps => <TextField {...inheritedProps} />}
          </InputMask>
          <InputMask
            mask="99999-999"
            className={classes.textField}
            margin="normal"
            placeholder="CEP"
            label="CEP"
            error={touched.cep && !!errors.cep}
            helperText={touched.cep && errors.cep}
            onChange={findZipCode}
            value={address}
            variant="outlined"
          >
            {inheritedProps => <TextField {...inheritedProps} />}
          </InputMask>
          <TextField
            className={classes.textField}
            margin="normal"
            placeholder="Endereço do trabalho"
            label="Endereço do trabalho"
            error={touched.address && !!errors.address}
            helperText={touched.address && errors.address}
            onChange={handleChange}
            value={values.address}
            variant="outlined"
            name="address"
            disabled={isLoadingCEP}
          />
          <TextField
            className={classes.textField}
            margin="normal"
            placeholder="Complemento"
            label="Complemento"
            error={touched.complement && !!errors.complement}
            helperText={touched.complement && errors.complement}
            onChange={handleChange}
            value={values.complement}
            variant="outlined"
            name="complement"
          />
          <div className={styles['two-columns']}>
            <TextField
              className={classes.textField}
              margin="normal"
              placeholder="Bairro"
              label="Bairro"
              error={touched.neighborhood && !!errors.neighborhood}
              helperText={touched.neighborhood && errors.neighborhood}
              onChange={handleChange}
              value={values.neighborhood}
              variant="outlined"
              name="neighborhood"
              disabled={isLoadingCEP}
            />
            <TextField
              className={classes.textField}
              margin="normal"
              placeholder="Cidade"
              label="Cidade"
              onChange={handleChange}
              value={values.city}
              error={touched.city && !!errors.city}
              helperText={touched.city && errors.city}
              variant="outlined"
              name="city"
              disabled={isLoadingCEP}
            />
          </div>
          <div className={styles['two-columns']}>
            <TextField
              className={classes.textField}
              margin="normal"
              placeholder="Edificio (se houver)"
              label="Edificio (se houver)"
              error={touched.building && !!errors.building}
              helperText={touched.building && errors.building}
              onChange={handleChange}
              value={values.building}
              variant="outlined"
              name="building"
            />
            <InputMask
              mask="+55 (99) 9999-99999"
              className={classes.textField}
              margin="normal"
              placeholder="Telefone"
              label="Telefone"
              error={touched.phoneNumber && !!errors.phoneNumber}
              helperText={touched.phoneNumber && errors.phoneNumber}
              onChange={handleChange}
              value={values.phoneNumber}
              variant="outlined"
              name="phoneNumber"
            >
              {inheritedProps => <TextField {...inheritedProps} />}
            </InputMask>
          </div>
        </div>
        <Button
          className={styles.button}
          variant={ButtonVariant.BOLD}
          format={ButtonFormat.ROUNDED}
          color={ButtonColor.PRIMARY}
          type="submit"
          isLoading={isTenantLoading || isParticipantLoading}
        >
          PRÓXIMO
        </Button>
      </form>
    </section>
  )
}

SubscribeGuarantorStepThree.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
    state: PropTypes.shape({
      wasAskingProfessionalData: PropTypes.bool,
    }),
  }).isRequired,
  id: PropTypes.string.isRequired,
}

export default withAuthOrIncomeHelper(React.memo(SubscribeGuarantorStepThree))

import { List } from 'immutable'
import { Cookies } from 'react-cookie'

import { User } from '_models/'
import { createReducer } from '_utils/redux'
import {
  LIKE_PROPERTY,
  DISLIKE_PROPERTY,
  UNLIKE_PROPERTY,
  UNDISLIKE_PROPERTY,
  BULK_LIKE_PROPERTY,
  BULK_DISLIKE_PROPERTY,
} from '_modules/property/actions'
import { SET_FILTER_MODAL } from '_modules/search/actions'
import { registerEvent } from '_utils/mixpanel'
import { registerSentryUser } from '_utils/sentry'
import { SEND_TENTANT_DATA } from '_modules/leases/actions'
import {
  LOGOUT,
  PHONE_LOGIN,
  GET_USER,
  UPDATE_USER,
  UNAUTHORIZED,
  GET_LIKED_PROPERTIES,
  CREATE_OWNER,
  FIREBASE_LOGIN,
  VERIFY_PHONE_LOGIN_CODE,
  GET_AUTH_TOKEN,
} from '_modules/user/actions'
import { getPropertyDefaultTitle } from '_utils/property'

const cookies = new Cookies()

// Initial State
const INITIAL_STATE = new User()

// Reducer
const user = createReducer(INITIAL_STATE, {
  [PHONE_LOGIN.FULFILLED]: (state, { payload }) => {
    cookies.set('user', payload.user, { path: '/' })
    cookies.set('authToken', payload.key, { path: '/' })
    registerEvent({ Nome: payload.user.full_name, Email: payload.user.email, Id: payload.user.id })
    registerSentryUser(payload.user)
    return state.mergeProps({
      authToken: payload.key,
      ...payload.user,
    })
  },

  [FIREBASE_LOGIN.FULFILLED]: (state, { payload }) => {
    const data = payload.user
    const { key } = payload
    cookies.set('authToken', payload.key, { path: '/' })
    cookies.set('user', JSON.stringify(data), { path: '/' })
    return state.mergeProps({
      authToken: key,
      ...data,
    })
  },

  [GET_AUTH_TOKEN.FULFILLED]: (state, { payload }) => {
    // does nothing if the user is already logged in
    if (state.authToken === payload.key) {
      return state
    }
    cookies.remove()
    const data = payload.user
    const { key } = payload
    cookies.set('authToken', payload.key, { path: '/' })
    cookies.set('user', JSON.stringify(data), { path: '/' })
    return state.mergeProps({
      authToken: key,
      ...data,
    })
  },

  [VERIFY_PHONE_LOGIN_CODE.FULFILLED]: (state, { payload }) => {
    const data = payload.user
    const { key } = payload
    cookies.set('authToken', payload.key, { path: '/' })
    cookies.set('user', JSON.stringify(data), { path: '/' })
    return state.mergeProps({
      authToken: key,
      ...data,
    })
  },

  [CREATE_OWNER.FULFILLED]: (state, { payload }) => {
    cookies.set('user', payload.user, { path: '/' })
    cookies.set('authToken', payload.key, { path: '/' })
    registerEvent({ Nome: payload.user.full_name, Email: payload.user.email, Id: payload.user.id })
    registerSentryUser(payload.user)
    return state.mergeProps({
      authToken: payload.key,
      ...payload.user,
    })
  },

  [GET_USER.FULFILLED]: (state, { payload }) => {
    cookies.set('user', payload, { path: '/' })
    return state.mergeProps({
      ...payload,
    })
  },

  [UPDATE_USER.FULFILLED]: (state, { payload }) => {
    cookies.set('user', payload, { path: '/' })
    registerEvent({ Nome: payload.full_name, Email: payload.email, Id: payload.id })
    registerSentryUser(payload.user)
    return state.mergeProps({
      ...payload,
    })
  },

  [LIKE_PROPERTY.FULFILLED]: (state, { meta: { propertyId } }) => {
    const likedProperties = (state.likedProperties || List())
      .push(propertyId)
      .toSet()
      .toList()

    cookies.set('likedProperties', likedProperties, { path: '/' })
    cookies.set('likedOpened', true, { path: '/' })
    return state
      .mergeProps({
        likedProperties,
      })
      .set('likedOpened', true)
  },

  [GET_LIKED_PROPERTIES.FULFILLED]: (state, { payload }) => {
    const newPayload = payload.map(value => ({
      ...value,
      slug: getPropertyDefaultTitle(value, true),
    }))
    return state.setIn(['likedPropertiesList'], newPayload)
  },

  [BULK_LIKE_PROPERTY.FULFILLED]: state => {
    const likedProperties = (state.likedProperties || List()).toSet().toList()

    cookies.set('likedProperties', likedProperties, { path: '/' })
    cookies.set('likedOpened', true, { path: '/' })
    return state.mergeProps({
      likedProperties,
    })
  },

  [LIKE_PROPERTY]: (state, { meta: { propertyId } }) => {
    const likedProperties = (state.likedProperties || List())
      .push(propertyId)
      .toSet()
      .toList()

    cookies.set('likedProperties', likedProperties, { path: '/' })
    cookies.set('likedOpened', true, { path: '/' })
    return state
      .mergeProps({
        likedProperties,
      })
      .set('likedOpened', true)
  },

  [UNLIKE_PROPERTY.FULFILLED]: (state, { meta: { propertyId } }) => {
    const likedProperties = (state.likedProperties || List())
      .filter(id => id !== propertyId)
      .toSet()
      .toList()

    const likedPropertiesList = state.likedPropertiesList.filter(
      property => property.externalId !== propertyId
    )

    cookies.set('likedProperties', likedProperties, { path: '/' })
    return state
      .mergeProps({
        likedProperties,
      })
      .setIn(['likedPropertiesList'], likedPropertiesList)
  },

  [UNLIKE_PROPERTY]: (state, { meta: { propertyId } }) => {
    const likedProperties = (state.likedProperties || List())
      .filter(id => id !== propertyId)
      .toSet()
      .toList()

    cookies.set('likedProperties', likedProperties, { path: '/' })
    return state.mergeProps({
      likedProperties,
    })
  },

  [DISLIKE_PROPERTY.FULFILLED]: (state, { meta: { propertyId } }) => {
    const dislikedProperties = (state.dislikedProperties || List())
      .push(propertyId)
      .toSet()
      .toList()

    cookies.set('dislikedProperties', dislikedProperties, { path: '/' })
    cookies.set('dislikedOpened', true, { path: '/' })
    return state
      .mergeProps({
        dislikedProperties,
      })
      .set('dislikedOpened', true)
  },

  [DISLIKE_PROPERTY]: (state, { meta: { propertyId } }) => {
    const dislikedProperties = (state.dislikedProperties || List())
      .push(propertyId)
      .toSet()
      .toList()

    cookies.set('dislikedProperties', dislikedProperties, { path: '/' })
    cookies.set('dislikedOpened', true, { path: '/' })
    return state
      .mergeProps({
        dislikedProperties,
      })
      .set('dislikedOpened', true)
  },

  [BULK_DISLIKE_PROPERTY.FULFILLED]: state => {
    const dislikedProperties = (state.dislikedProperties || List()).toSet().toList()

    cookies.set('dislikedProperties', dislikedProperties, { path: '/' })
    return state.mergeProps({
      dislikedProperties,
    })
  },

  [UNDISLIKE_PROPERTY.FULFILLED]: (state, { meta: { propertyId } }) => {
    const dislikedProperties = (state.dislikedProperties || List())
      .filter(id => id !== propertyId)
      .toSet()
      .toList()

    cookies.set('dislikedProperties', dislikedProperties, { path: '/' })
    return state.mergeProps({
      dislikedProperties,
    })
  },

  [UNDISLIKE_PROPERTY]: (state, { meta: { propertyId } }) => {
    const dislikedProperties = (state.dislikedProperties || List())
      .filter(id => id !== propertyId)
      .toSet()
      .toList()

    cookies.set('dislikedProperties', dislikedProperties, { path: '/' })
    return state.mergeProps({
      dislikedProperties,
    })
  },

  [UNAUTHORIZED]: () => {
    cookies.remove('user', { path: '/' })
    cookies.remove('authToken', { path: '/' })
    return INITIAL_STATE
  },

  [SET_FILTER_MODAL]: state => {
    cookies.set('clearFilterOpened', true, { path: '/' })
    return state.set('clearFilterOpened', true)
  },

  [SEND_TENTANT_DATA.FULFILLED]: (state, { payload }) => {
    cookies.set('user', payload, { path: '/' })
    registerEvent({ Nome: payload.fullName, Email: payload.email, Id: payload.id })
    registerSentryUser(payload)
    return state.mergeProps({
      ...payload.user,
    })
  },

  [LOGOUT]: () => {
    cookies.remove('user', { path: '/' })
    cookies.remove('authToken', { path: '/' })
    return INITIAL_STATE
  },
})

export default user

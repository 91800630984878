import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { navigate, Redirect } from '@reach/router'

import AlertCard from '_components/alert-card'
import { moment } from '_bootstrap'
import CalendarIcon from '_assets/images/ic-data.svg'
import LocationIcon from '_assets/images/ic-localizacao.svg'
import PeopleIcon from '_assets/images/ic-pessoas.svg'
import AlertIcon from '_assets/images/ic-alerta.svg'
import Button, { ButtonColor, ButtonFormat, ButtonVariant } from '_components/button'
import { sendEvent } from '_utils/mixpanel'
import { primaryColor } from '_utils/colors'

import styles from './styles.css'

class ScheduleReview extends PureComponent {
  static propTypes = {
    location: PropTypes.shape({
      state: PropTypes.shape({
        saveQuery: PropTypes.shape(),
        property: PropTypes.shape(),
        date: PropTypes.string,
        time: PropTypes.arrayOf(PropTypes.string),
        source: PropTypes.string,
        sourceId: PropTypes.string,
        anonymousId: PropTypes.string,
        hash: PropTypes.string,
        staffHash: PropTypes.string,
        recommendationHash: PropTypes.string,
      }),
    }).isRequired,
  }

  static defaultProps = {}

  onConfirmClick = () => {
    const {
      location: {
        state: {
          saveQuery,
          property,
          time,
          date,
          source,
          sourceId,
          anonymousId,
          staffHash,
          recommendationHash,
        },
      },
    } = this.props

    sendEvent('Agendamento Passo 2: Selecionou Confirmar Data')
    navigate('/agendamento/passo-3', {
      state: {
        saveQuery,
        property,
        time,
        date,
        source,
        sourceId,
        anonymousId,
        staffHash,
        recommendationHash,
      },
    })
  }

  render() {
    const {
      location: { state },
    } = this.props

    if (!state || (state && !state.property)) {
      return <Redirect to="/" noThrow />
    }
    const { property, date, time } = this.props.location.state

    const address = `${property.address ? `${property.address.capitalizeAll(true)}, ` : ''}${
      property.neighborhood ? `${property.neighborhood.capitalizeAll(true)}` : ''
    }${property.city ? `, ${property.city.capitalizeAll(true)}` : ''}
     `
    const newTime = time.toString().replace(',', 'h')
    const { pictures } = property

    return (
      <div className={styles.main}>
        <div className={styles.container}>
          <h3 className={styles.title}>Revise e agende a sua visita</h3>
          <div className={styles['alert-cards']}>
            <AlertCard
              icon={AlertIcon}
              content={['Confirme que você vai poder comparecer nessa data']}
              primaryColor={primaryColor}
            />
            <AlertCard icon={CalendarIcon} className={styles.rounded} primaryColor={primaryColor}>
              <p className={styles['date-text']}>
                {moment(date).format('dddd[,]')}
                <span>{moment(date).format(' D [de] MMMM')}</span> às
                <span>{` ${newTime}`}</span>
              </p>
            </AlertCard>
            <AlertCard
              icon={PeopleIcon}
              content={['Sua visita será acompanhada por um corretor']}
              primaryColor="#6F737D"
            />
          </div>
          <div className={styles['date-container']}>
            <svg aria-label="ícone de calendário" role="img" className={styles['calendar-icon']}>
              <use xlinkHref={CalendarIcon} />
            </svg>
            <p className={styles['date-text']}>
              {moment(date).format('dddd[,]')}
              <span className={styles['bold-text']}>{`${moment(date).format(
                ' D [de] MMMM'
              )}`}</span>{' '}
              às <span className={styles['bold-text']}>{`${newTime}`}</span>
            </p>
          </div>
          <div className={styles.card}>
            <img src={pictures[0]} className={styles.picture} alt="property" />
            <div className={styles['location-container']}>
              <svg
                alt="calendar icon"
                className={styles['location-icon']}
                style={{ fill: primaryColor }}
              >
                <use xlinkHref={LocationIcon} />
              </svg>
              <p className={styles.text}>{address}</p>
            </div>
          </div>
          <Button
            color={ButtonColor.PRIMARY}
            format={ButtonFormat.ROUNDED}
            onClick={this.onConfirmClick}
            variant={ButtonVariant.BOLD}
          >
            CONFIRMAR DATA
          </Button>
        </div>
      </div>
    )
  }
}

export default ScheduleReview

import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, globalHistory } from '@reach/router'

import { RedirectContext } from '_context/redirect'
import { isUserLoggedIn } from '_modules/user/selectors'
import { primaryColor } from '_utils/colors'

const withAuth = WrappedComponent => {
  const WithAuth = props => {
    const isLoggedIn = useSelector(isUserLoggedIn)
    const isMobile = useSelector(state => state.server.isMobile)
    const redirect = useContext(RedirectContext)

    const { location } = globalHistory

    const hasVerificationToken =
      location.search.includes('?verification_token=') ||
      location.search.includes('&verification_token=')

    if (!isLoggedIn && location.pathname !== '/' && !hasVerificationToken) {
      redirect.setRedirectUri(location.pathname + location.search)
      redirect.setOpenLogin(true)
      return <Redirect to="/" noThrow />
    }

    return <WrappedComponent primaryColor={primaryColor} isMobile={isMobile} {...props} />
  }

  return React.memo(WithAuth)
}

export default withAuth

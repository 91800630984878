import React, { PureComponent } from 'react'
import { StickyContainer, Sticky } from 'react-sticky'
import classnames from 'classnames'
import Waypoint from 'react-waypoint'
import uuidv4 from 'uuid'
import PropTypes from 'prop-types'

import { sendEvent } from '_utils/mixpanel'
import CheckListIcon from '_components/icon-components/check-list-icon'
import PrivacyIcon from '_components/icon-components/privacy-icon'
import { primaryColor } from '_utils/colors'

import styles from './styles.css'

const headerOffset = 60
const sectionOffset = 150
const overlayOffset = 500

class TermsAndPrivacy extends PureComponent {
  static propTypes = {
    navigationItems: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.array, PropTypes.bool, PropTypes.string])
    ),
    title: PropTypes.string,
    update: PropTypes.string,
    sectionsArray: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.func])
    ),
    isStickyHidden: PropTypes.bool,
    description: PropTypes.node,
    view: PropTypes.string.isRequired,
  }

  static defaultProps = {
    navigationItems: [],
    title: '',
    update: '',
    sectionsArray: [],
    isStickyHidden: false,
    description: null,
  }

  constructor(props) {
    super(props)
    this.uuid = uuidv4()

    this.state = {
      selectedSection: [],
    }
  }

  onScrollToSectionClick = (item, sectionRef) => () => {
    window.scrollTo(0, sectionRef.current.offsetTop - headerOffset)
    sendEvent(`${this.props.view}: Selecionou ${item} na barra de navegação lateral`)
  }

  onMouseDown = event => {
    event.preventDefault()
  }

  onEnterSection = index => () => {
    this.setState(state => {
      const newState = [...state.selectedSection]
      newState.push(index)
      return { selectedSection: newState }
    })
  }

  onLeaveSection = index => () => {
    this.setState(state => ({ selectedSection: state.selectedSection.filter(i => i !== index) }))
  }

  setArrayKey = (subtitle, index) => `${this.uuid}-${subtitle}-${index}`

  renderStickySection = navigationItems => {
    const { selectedSection } = this.state
    const orderedSections = selectedSection.sort((a, b) => a - b)
    return (
      <StickyContainer className={styles.fixed}>
        <Sticky disableCompensation topOffset={-headerOffset}>
          {({ style, isSticky }) => (
            <div
              style={style}
              className={classnames(styles['sticky-content'], { [styles.sticky]: isSticky })}
            >
              <PrivacyIcon className={styles.background} primaryColor={primaryColor} />
              <div
                className={styles['navigation-bar']}
                style={{ borderLeft: `solid 2px ${primaryColor}` }}
              >
                {navigationItems.map(([item, itemRef], index) => (
                  <button
                    onClick={this.onScrollToSectionClick(item, itemRef)}
                    onMouseDown={this.onMouseDown}
                    type="button"
                  >
                    <p
                      className={classnames({
                        [styles.selected]: orderedSections[0] === index,
                      })}
                    >
                      {item}
                    </p>
                  </button>
                ))}
              </div>
            </div>
          )}
        </Sticky>
      </StickyContainer>
    )
  }

  renderListItems = listItems => (
    <div>
      {listItems.map(listItem => (
        <div className={styles['list-item']} key={`${this.uuid}-${listItem}`}>
          <CheckListIcon className={styles['list-icon']} />
          <p className={styles['list-text']}>{listItem}</p>
        </div>
      ))}
    </div>
  )

  renderTopSection = () => {
    const { title, update, description } = this.props
    return (
      <div className={styles['top-section']}>
        <h1 className={styles.title}>{title}</h1>
        <p className={styles.update}>{update}</p>
        {description}
      </div>
    )
  }

  renderSection = (title, isSubtitle, contentArray, onEnterWaypoint, onLeaverWaypoint, ref) => (
    <div ref={ref} className={styles.section} key={`section-${title}`}>
      <Waypoint onEnter={onEnterWaypoint} onLeave={onLeaverWaypoint} topOffset={sectionOffset}>
        <div>
          <h2 className={classnames(styles.title, { [styles.subtitle]: isSubtitle })}>{title}</h2>
          {contentArray.map(([subtitle, textArray]) => (
            <div key={`${this.uuid}-${subtitle}`}>
              {subtitle !== '' && <p className={styles.subtitle}>{subtitle}</p>}
              {textArray.map((text, index) => (
                <p className={styles.text} key={this.setArrayKey(subtitle, index)}>
                  {text}
                </p>
              ))}
            </div>
          ))}
        </div>
      </Waypoint>
    </div>
  )

  render() {
    const { isStickyHidden, navigationItems, sectionsArray } = this.props

    return (
      <div className={styles.main}>
        <div className={styles.content}>
          {this.renderStickySection(navigationItems)}
          <PrivacyIcon className={styles['mobile-image']} primaryColor={primaryColor} />
          <div className={styles.scrollable}>
            <StickyContainer className={styles['overlay-container']}>
              <Sticky disableCompensation topOffset={-overlayOffset}>
                {({ style, isSticky }) => {
                  const { top, ...usefulStyle } = style
                  const newStyle = { ...usefulStyle, bottom: '0' }
                  return (
                    <div
                      style={newStyle}
                      className={classnames(
                        styles.overlay,
                        {
                          [styles['hidden-overlay']]: isStickyHidden,
                        },
                        {
                          [styles['sticky-overlay']]: isSticky,
                        }
                      )}
                    />
                  )
                }}
              </Sticky>
              {this.renderTopSection()}
              <div className={styles['sections-container']}>
                {sectionsArray.map(([sectionTitle, isSubtitle, sectionContent, ref], index) =>
                  this.renderSection(
                    sectionTitle,
                    isSubtitle,
                    sectionContent,
                    this.onEnterSection(index),
                    this.onLeaveSection(index),
                    ref
                  )
                )}
              </div>
            </StickyContainer>
          </div>
        </div>
      </div>
    )
  }
}

export default TermsAndPrivacy

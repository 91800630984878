import { List, Record } from 'immutable'

import { createReducer } from '_utils/redux'
import { RESET_SEARCH } from '_modules/search/actions'
import { LOGOUT } from '_modules/user/actions'
import { City, Neighborhood } from '_models/'
import { TRIGGER_SNACKBAR } from '_modules/snackbar/actions'

import { GET_CITIES, GET_NEIGHBORHOODS } from './actions'

const INITIAL_STATE = new Record({
  cities: List(),
  neighborhoods: List(),
  snackbarMessage: '',
  snackbarVariant: '',
})()

const utils = createReducer(INITIAL_STATE, {
  [GET_CITIES.FULFILLED]: (state, { payload }) => {
    const results = List(payload.map(city => new City(city))).insert(
      0,
      new City({ name: 'Todas', slug: 'todas' })
    )
    return state.set('cities', results)
  },

  [GET_NEIGHBORHOODS.FULFILLED]: (state, { payload }) => {
    const results = List(payload.map(neighborhood => new Neighborhood(neighborhood))).insert(
      0,
      new Neighborhood({ name: 'Todos', slug: 'todos' })
    )
    return state.set('neighborhoods', results)
  },

  [RESET_SEARCH]: () => INITIAL_STATE,

  [TRIGGER_SNACKBAR.FULFILLED]: (state, { meta }) => {
    return state.set('snackbarMessage', meta.message).set('snackbarVariant', meta.variant)
  },

  [LOGOUT]: () => INITIAL_STATE,
})

export default utils

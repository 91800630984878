import { Map } from 'immutable'
import { Cookies } from 'react-cookie'

import { createReducer } from '_utils/redux'
import {
  LIKE_PROPERTY,
  UNLIKE_PROPERTY,
  DISLIKE_PROPERTY,
  UNDISLIKE_PROPERTY,
} from '_modules/property/actions'
import { LOCATION_CHANGED } from '_modules/location/actions'
import { sendEvent } from '_utils/mixpanel'
import { LOGOUT } from '_modules/user/actions'

import { CLOSE_COOKIES_MODAL } from './actions'

// Initial State
export const INITIAL_STATE = Map({
  showCookiesModal: true,
  prevLocation: undefined,
})

const cookies = new Cookies()
// Close modal
const closeModal = state => {
  if (state.get('showCookiesModal') === true) {
    cookies.set('showCookiesModal', false, { path: '/' })
    sendEvent('Fechou banner de cookies')
  }
  return state.set('showCookiesModal', false)
}

// Reducer
const modal = createReducer(INITIAL_STATE, {
  [CLOSE_COOKIES_MODAL]: closeModal,
  [LIKE_PROPERTY]: closeModal,
  [UNLIKE_PROPERTY]: closeModal,
  [DISLIKE_PROPERTY]: closeModal,
  [UNDISLIKE_PROPERTY]: closeModal,
  [LOCATION_CHANGED]: (state, { payload }) => {
    return closeModal(state.set('prevLocation', payload))
  },

  [LOGOUT]: () => {
    cookies.remove('showCookiesModal', false, { path: '/' })
    return INITIAL_STATE
  },
})

export default modal

import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography } from '@material-ui/core'

import useStyles from './styles'

const PropertyDetail = ({ title, description, children }) => {
  const styles = useStyles()
  return (
    <Grid component="section" className={styles.container}>
      <Typography component="h1" variant="h5" className={styles.title}>
        {title}
      </Typography>
      <Typography component="p" className={styles.description}>
        {description}
      </Typography>
      <Grid className={styles.children}>{children}</Grid>
    </Grid>
  )
}

PropertyDetail.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default React.memo(PropertyDetail)

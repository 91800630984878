import React, { useMemo, useContext } from 'react'
import { Redirect, useLocation } from '@reach/router'
import { useSelector } from 'react-redux'
import { Grid, Typography } from '@material-ui/core'
import Alarm from '@material-ui/icons/Alarm'

import useModal from '_hooks/use-modal'
import { getBookingById } from '_modules/booking/selectors'
import { moment } from '_bootstrap'
import Button from '_components/material/button'
import Feedback from '_views/schedule/feedback'
import Snackbar from '_components/snackbar'
import { SEND_BOOKING_FEEDBACK } from '_modules/booking/actions'
import ExternalIdButton from '_components/material/button/external-id-button'
import { toTitleCase } from '_utils/helpers'
import { getPropertyDefaultTitle } from '_utils/property'
import { RedirectContext } from '_context/redirect'
import withAuth from '_hocs/with-auth'
import { getLeaseById } from '_modules/leases/selectors'
import useGetLease from '_hooks/use-get-lease'
import { primaryColor } from '_utils/colors'
import { FEATURE_FLAGS } from '_utils/constants'

import CalendarCard from './calendar-card'
import styles from './styles.css'
import FloatingFeedbackButton from './floating-feedback-button'
import FeedbackModal from './feedback-modal'

const ScheduleFinalConfirmation = () => {
  const { bookingState } = useContext(RedirectContext)
  const location = useLocation()
  const leaseId =
    (location.state && location.state.lease && location.state.lease.id) || bookingState.leaseId
  const [isLoading] = useGetLease(leaseId)
  const lease = useSelector(state => getLeaseById(state, leaseId))
  const { modalOpen, handleModal } = useModal(true)
  const property = location.state && location.state.property
  const bookingId = (location.state && location.state.bookingId) || bookingState.bookingId
  const booking = useSelector(mainState => getBookingById(mainState, bookingId))
  const visitRequested =
    lease.status === 'booking_requested' || lease.status === 'booking_rescheduled'
  const getMakeProposalUrl = useMemo(() => {
    if (leaseId) {
      return `/proposta/resumo-locacao/${leaseId}`
    }
    return '/'
  }, [leaseId])

  const isCommercialLease = useMemo(() => {
    if (!isLoading && lease.id) {
      const listing = lease.get('listing').toJS()
      return listing.listingType.includes('com_')
    }
    return false
  }, [isLoading, lease])

  const address = useMemo(
    () => property && (property.address ? toTitleCase(property.address) : ''),
    [property]
  )
  const fullAddress = useMemo(
    () =>
      property &&
      `${property.neighborhood ? toTitleCase(property.neighborhood) : ''}${
        property.city ? `, ${toTitleCase(property.city)}` : ''
      }
     `,
    [property]
  )

  const dateAndTime = useMemo(() => {
    const date = location.state && location.state.date
    const time = location.state && location.state.time
    if (!date || !time) {
      return moment()
    }
    /* 
      Actually the way time is being formated
      with timezone is a mystery so we subtract 3
      to fix the timezone issue but need a better solution 
    */
    return moment(date).set({
      hour: time[0],
      minute: time[1],
      second: 0,
      millisecond: 0,
    })
  }, [location.state])

  const slug = `${getPropertyDefaultTitle(booking.get('listing'))}/${property.externalId}`

  if (!location.state || !property) {
    return <Redirect to="/" noThrow />
  }

  return (
    <Grid className={styles.main}>
      {!modalOpen && booking && !booking.get('feedbackId') && (
        <FloatingFeedbackButton onClick={handleModal} />
      )}
      {modalOpen && <FeedbackModal onClose={handleModal} bookingId={bookingId} />}
      <Snackbar
        action={SEND_BOOKING_FEEDBACK.ACTION}
        variant="default"
        message="Avaliação realizada com sucesso!"
        snackbarClassName={styles.snackbar}
      />
      <div className={styles.cards}>
        <ExternalIdButton
          slug={slug}
          externalId={property.externalId}
          listingType={property.listingType}
        />
        {visitRequested && (
          <Grid className={styles.visitRequestNotify}>
            <Alarm className={styles.icon} />
            <Typography variant="h4" className={styles.visitRequestText}>
              Aguarde a confirmação da visita pela imobiliária
            </Typography>
          </Grid>
        )}
        {property && (
          <CalendarCard
            date={dateAndTime}
            address={address}
            fullAddress={fullAddress}
            propertyTitle={property.title}
            primaryColor={primaryColor}
          />
        )}
        {!FEATURE_FLAGS.productVersionOneEnabled && !isCommercialLease && (
          <Grid>
            <Grid component="section" className={styles['how-it-works']}>
              <Typography variant="h1" className={styles.heading}>
                Como funciona para alugar um imóvel de forma digital?
              </Typography>
              <Feedback
                showLabelRequestSent={false}
                className={styles.feedback}
                paperStyle={styles['feedback-paper']}
              />
            </Grid>

            <Button
              variant="contained"
              color="primary"
              to={getMakeProposalUrl}
              fullWidth
              className={styles['button-proposal']}
            >
              Fazer proposta
            </Button>
          </Grid>
        )}
      </div>
    </Grid>
  )
}

export default withAuth(ScheduleFinalConfirmation)

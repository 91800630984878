import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ palette, breakpoints }) => ({
  fullWidth: {
    width: '100%',
  },
  header: {
    width: '100%',
    display: 'grid',
    gridAutoFlow: 'column',
    gap: '2.4rem',
    alignItems: 'center',
    justifyContent: 'flex-start',
    [breakpoints.down('sm')]: {
      gap: '1.6rem',
    },
  },
  image: {
    width: '6.4rem',
    height: '6.4rem',
  },
  title: {
    color: palette.text.primary,
    lineHeight: '2.4rem',
    fontWeight: 700,
    [breakpoints.down('sm')]: {
      fontSize: '1.6rem',
    },
  },
  subtitle: {
    color: palette.secondary.main,
    lineHeight: '2.4rem',
    fontWeight: 400,
    [breakpoints.down('sm')]: {
      fontSize: '1.4rem',
    },
  },
  notificationWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: palette.gray.alabaster,
    border: `1px solid ${palette.secondary.light}`,
    padding: '1.6rem 3.2rem',
    marginTop: '2.4rem',
    boxSizing: 'border-box',
    borderRadius: 4,
    [breakpoints.down('sm')]: {
      padding: '1.6rem 2.4rem',
      marginTop: '0.8rem',
      flexDirection: 'column',
      alignItems: 'baseline',
    },
  },
  iconWrapper: {
    display: 'grid',
    gap: '1.6rem',
    gridAutoFlow: 'column',
    alignItems: 'center',
  },
  icon: {
    width: '2rem',
    height: '2rem',
    color: palette.secondary.main,
    [breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  text: {
    fontWeight: 400,
    lineHeight: '2.1rem',
    color: palette.text.primary,
    maxWidth: '44.3rem',
  },
  button: {
    fontSize: '1.4rem',
    height: '3.1rem',
    [breakpoints.down('sm')]: {
      marginTop: '1.6rem',
    },
  },
  footerButton: {
    marginTop: '1.6rem',
  },
}))

import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  imageBackground: {
    display: 'flex',
    width: '7rem',
    height: '7rem',
    backgroundColor: theme.palette.custom.zircon,
    borderRadius: '50%',
    margin: '2.4rem 0',
    [theme.breakpoints.down('sm')]: {
      margin: '1.6rem 0',
    },
  },
  image: {
    width: '60%',
    height: '60%',
    margin: 'auto auto',
  },
  title: {
    marginBottom: '0.4rem',
  },
  description: {
    lineHeight: '2.1rem',
    color: theme.palette.gray.dusty,
    marginBottom: '3rem',
  },
  starsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '3.2rem',
  },
  star: {
    color: theme.palette.warning.main,
    width: '2.5rem',
    height: '2.5rem',
  },
  emptyStar: {
    color: theme.palette.gray.dusty,
  },
  button: {
    height: '4rem',
    marginBottom: '0.4rem',
  },
}))

import { combineReducers } from 'redux'

import leases from '_modules/leases/reducer'
import highlights from '_modules/highlights/reducer'
import modal from '_modules/modal/reducer'
import properties from '_modules/property/reducer'
import user from '_modules/user/reducer'
import search from '_modules/search/reducer'
import error from '_modules/error/reducer'
import loading from '_modules/loading/reducer'
import server from '_modules/server/reducer'
import bookings from '_modules/booking/reducer'
import proposal from '_modules/proposal/reducer'
import incomeHelper from '_modules/income-helper/reducer'
import utils from '_modules/utils/reducer'
import listingRegistrationForm from '_modules/listing-registration/reducer'
import recommendations from '_modules/recommendations/reducer'
import recommendationsProperties from '_modules/recommendations-property/reducer'
import filter from '_modules/filter/reducer'

import agency from './agency/reducer'

const rootReducer = combineReducers({
  agency,
  bookings,
  error,
  filter,
  highlights,
  incomeHelper,
  leases,
  listingRegistrationForm,
  loading,
  modal,
  properties,
  proposal,
  recommendations,
  recommendationsProperties,
  search,
  server,
  user,
  utils,
})

export default rootReducer

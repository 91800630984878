import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { navigate, Redirect } from '@reach/router'

import withAuth from '_hocs/with-auth'
import Button, { ButtonVariant, ButtonFormat, ButtonColor } from '_components/button'
import { FEATURE_FLAGS, PARTICIPANTS_TYPE, LEASE_STATUS } from '_utils/constants'
import { getLeaseStatus } from '_utils/lease'
import { usePrevious } from '_utils/hooks'
import {
  getLeaseById,
  checkIsLoadingLease,
  checkIsLoadingDocuments,
  checkIsSendingToReview,
} from '_modules/leases/selectors'
import {
  getLease,
  uploadDocument,
  sendLeaseToReview,
  deleteDocument,
} from '_modules/leases/actions'
import { warrantRapid } from '_modules/warranty/selectors'
import InputDocument from '_components/input-document'

import { INITIAL_STATE, reducer, ADD_DOCUMENTS, REMOVE_DOCUMENT, UPDATE_DOCUMENT } from './reducer'
import styles from './styles.css'

const CLIENT_ERROR = 400

const Documents = ({ isMobile, id }) => {
  // REDUX HOOKS
  const lease = useSelector(state => getLeaseById(state, id))
  const isLoadingLease = useSelector(checkIsLoadingLease)
  const isUploadingDocuments = useSelector(checkIsLoadingDocuments)
  const isSendingToReview = useSelector(checkIsSendingToReview)
  const { documentCategories } = useSelector(warrantRapid)
  const [previousDocuments, setPreviousDocuments] = useState(undefined)

  const dispatch = useDispatch()

  // STATE HOOKS
  const [documents, dispatchLocal] = useReducer(reducer, INITIAL_STATE)

  // PREVIOUS STATES HOOK
  const wasLoadingLease = usePrevious(isLoadingLease)
  const wasUploadingDocuments = usePrevious(isUploadingDocuments)
  const wasSendingToReview = usePrevious(isSendingToReview)

  // PRE DEFINED VALUES
  const externalId = useMemo(() => (lease ? lease.listing.get('externalId') : null), [lease])
  const defaultNegotiationUrl = useMemo(() => `/minhas-negociacoes/${id}`, [id])

  const handleAddDocuments = useCallback(
    payload =>
      dispatchLocal({
        type: ADD_DOCUMENTS,
        payload,
      }),
    []
  )

  const handleRemoveDocument = useCallback(
    payload =>
      dispatchLocal({
        type: REMOVE_DOCUMENT,
        payload,
      }),
    []
  )

  const handleDeletePreviousDocument = useCallback(
    documentId => {
      dispatch(deleteDocument(lease.id, documentId))
      setPreviousDocuments(prevState =>
        prevState.filter(document => documentId !== document.documentId)
      )
    },
    [dispatch, lease]
  )
  const allDocuments = useMemo(
    () =>
      documentCategories &&
      documentCategories.filter(
        ({ participant, considerIncome }) =>
          participant === PARTICIPANTS_TYPE.MAIN_TENANT &&
          lease &&
          lease.get('considerIncome') === considerIncome
      ),
    [documentCategories, lease]
  )

  const requestedDocuments = useMemo(() => {
    if (lease && lease.hasRequestedDocuments) {
      return lease.dataRequest.get('documents').toJS()
    }
    return false
  }, [lease])

  const documentsNeeded = useMemo(() => {
    if (lease) {
      const requiredDocuments =
        documentCategories &&
        documentCategories
          .filter(document => document.participant === PARTICIPANTS_TYPE.MAIN_TENANT)
          .filter(document => document.considerIncome === lease.get('considerIncome'))
      if (requestedDocuments) {
        return requestedDocuments
          .map(document => requiredDocuments.find(doc => doc.id.toString() === document))
          .filter(document => document)
      }
      return requiredDocuments
    }
    return []
  }, [documentCategories, lease, requestedDocuments])

  const isButtonDisabled = useMemo(() => {
    if (!documentsNeeded.length) return false
    return (
      previousDocuments &&
      documentsNeeded &&
      documentsNeeded
        .map(document => {
          const isOptional = document.optional !== null
          const category = document.id.toString()
          if (isOptional) {
            return false
          }
          if (
            previousDocuments &&
            previousDocuments.find(file => file.documentCategory === category)
          ) {
            if (
              lease &&
              lease.hasRequestedDocuments &&
              (!documents.get(category) || documents.get(category).size === 0)
            ) {
              return true
            }
            return false
          }
          if (previousDocuments.find(file => file.documentCategory === category)) {
            return false
          }
          if (documents.get(category) !== undefined) {
            return !documents.get(category).size
          }

          return true
        })
        .reduce((total, value) => total || value)
    )
  }, [documents, documentsNeeded, lease, previousDocuments])

  useEffect(() => {
    if (lease && lease.documents && !previousDocuments) {
      setPreviousDocuments(
        lease.documents.toJS().map(document => ({
          url: document.file,
          documentCategory: document.documentCategory,
          title:
            documentsNeeded.find(name => name.id === document.documentCategory) &&
            documentsNeeded.find(name => name.id === document.documentCategory).title,
          leaseId: lease.id,
          documentId: document.id,
        }))
      )
    }
  }, [lease, allDocuments, previousDocuments, documentsNeeded])
  // Keep track of each upload progress
  const onUploadProgress = useCallback(
    ({ documentCategory, fileIndex }) => ({ loaded, total }) => {
      const percentage = Math.round((loaded * 100) / total)
      const payload = {
        type: UPDATE_DOCUMENT,
        payload: {
          percentage,
          isUploading: percentage < 100,
          documentCategory,
          id: fileIndex,
        },
      }
      dispatchLocal(payload)
    },
    []
  )

  // Validate response status
  // if has error, update the thumbnail
  // with error status
  const validateStatus = useCallback(
    ({ documentCategory, fileIndex }) => status => {
      if (status < CLIENT_ERROR) {
        return status >= 200 && status < 300
      }

      dispatchLocal({
        type: UPDATE_DOCUMENT,
        payload: {
          documentCategory,
          id: fileIndex,
          hasError: true,
          isUploading: false,
          percentage: 100,
        },
      })

      return false
    },
    []
  )

  const handleSendClick = useCallback(
    event => {
      event.preventDefault()
      if (
        previousDocuments &&
        !documents.size &&
        !isButtonDisabled &&
        !lease.hasRequestedDocuments
      ) {
        if (getLeaseStatus(lease.status) === LEASE_STATUS.INSUFFICIENT_INCOME)
          navigate(`${defaultNegotiationUrl}/adicionar-locatario`)
        else navigate(`${defaultNegotiationUrl}/revisao`)
      }
      documents.entrySeq().forEach(async ([documentCategory, listOfDocuments]) => {
        if (!listOfDocuments.size) {
          return
        }

        const payload = {
          leaseId: id,
          documentCategory,
          externalId,
          onUploadProgress: onUploadProgress({
            documentCategory,
            fileIndex: 0,
          }),
          validateStatus: validateStatus({
            documentCategory,
            fileIndex: 0,
          }),
          fileName: [
            listOfDocuments
              .first()
              .get('file')
              .name.replace(',', ''),
          ],
        }

        if (listOfDocuments.size > 1) {
          const files = []
          const filesNames = []
          listOfDocuments.forEach(fileMap => {
            const file = fileMap.get('file')
            files.push(file)
            filesNames.push(file.name.replace(',', ''))
          })
          payload.files = files
          payload.filesNames = filesNames.join()
        } else {
          payload.file = listOfDocuments.get(0).get('file')
          payload.filesNames = [
            listOfDocuments
              .get(0)
              .get('file')
              .name.replace(',', ''),
          ]
        }
        dispatch(uploadDocument(payload))
      })
    },
    [
      defaultNegotiationUrl,
      dispatch,
      documents,
      externalId,
      id,
      isButtonDisabled,
      lease,
      onUploadProgress,
      previousDocuments,
      validateStatus,
    ]
  )

  useEffect(() => {
    if (wasLoadingLease && !isLoadingLease && !lease) {
      navigate('/minhas-visitas')
    }
  }, [defaultNegotiationUrl, id, isLoadingLease, lease, wasLoadingLease])

  useEffect(() => {
    if (id) {
      dispatch(getLease(id))
    }
  }, [dispatch, id])

  useEffect(() => {
    if (wasUploadingDocuments && !isUploadingDocuments) {
      const hasError = documents.filter(
        category => category.filter(document => document.get('hasError')).size
      ).size

      if (!hasError) {
        dispatch(sendLeaseToReview({ leaseId: id, externalId }))
      }
    }
  }, [dispatch, documents, externalId, id, isUploadingDocuments, lease, wasUploadingDocuments])

  useEffect(() => {
    // TO-DO: handle error state
    if (wasSendingToReview && !isSendingToReview) {
      if (FEATURE_FLAGS.creditAnalysisIntegrationEnabled) {
        if (FEATURE_FLAGS.fireInsuranceEnabled && lease && lease.fireInsurancePrice) {
          navigate(`${defaultNegotiationUrl}/seguro-incendio`)
          return
        }
        navigate(`${defaultNegotiationUrl}/revisao`)
        return
      }
      navigate(`${defaultNegotiationUrl}/adicionar-locatario`)
    }
  }, [defaultNegotiationUrl, isSendingToReview, lease, wasSendingToReview])

  if (!lease) {
    // TO-DO: Add a loading state
    return null
  }

  if (
    getLeaseStatus(lease.status) !== LEASE_STATUS.PENDING &&
    getLeaseStatus(lease.status) !== LEASE_STATUS.INCOME_HELPERS_PENDING &&
    getLeaseStatus(lease.status) !== LEASE_STATUS.DOCUMENTS_REQUESTED
  ) {
    return <Redirect to="/minhas-negociacoes" noThrow />
  }

  const isLoading = isUploadingDocuments || isSendingToReview

  return (
    <section className={styles.container}>
      <h1 className={styles.type}>Precisamos de alguns documentos.</h1>
      <p className={styles.description}>
        Agora vamos precisar dos documentos a seguir para completar o seu cadastro.
      </p>
      {documentCategories && documentCategories.length && (
        <form className={styles.form} onSubmit={handleSendClick}>
          {documentsNeeded.map(document => (
            <InputDocument
              key={document.id}
              title={document.title}
              description={document.description}
              isMobile={isMobile}
              documentCategory={document.id}
              documents={documents.get(document.id.toString())}
              handleAddDocuments={handleAddDocuments}
              handleRemoveDocument={handleRemoveDocument}
              previousDocuments={
                previousDocuments
                  ? previousDocuments.filter(file => file.documentCategory === document.id)
                  : []
              }
              deletePrevious={handleDeletePreviousDocument}
            />
          ))}
          <Button
            className={styles.button}
            variant={ButtonVariant.BOLD}
            format={ButtonFormat.ROUNDED}
            color={ButtonColor.PRIMARY}
            type="submit"
            disabled={isButtonDisabled}
            isLoading={isLoading}
          >
            ENVIAR
          </Button>
        </form>
      )}
    </section>
  )
}

Documents.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
}

export default withAuth(React.memo(Documents))

import { createTheme } from '@material-ui/core/styles'

import { TABLET, MOBILE } from '_config/media-queries'
import { primaryColor } from '_utils/colors'

export const theme = () =>
  createTheme({
    palette: {
      primary: {
        main: primaryColor,
      },
      secondary: {
        dark: '#222', // or #212121
        main: '#4b4b4b',
        light: '#dadada',
      },
      text: {
        primary: '#222', // or #212121
        secondary: '#4b4b4b',
        disabled: '#e0e0e0',
      },
      error: {
        main: '#b00020',
        light: '#eec1c1',
      },
      success: {
        main: '#32ba7c',
        dark: '#23A067',
      },
      info: {
        main: '#00bcd4',
      },
      green: {
        main: '#3db791',
        light: '#ebf8f2',
      },
      orange: {
        light: '#FDF2E6',
        dark: '#EA8004',
      },
      blue: {
        light: '#ECEEF8',
      },
      gray: {
        main: '#f7f7f7',
        gallery: '#eeeeee',
        default: '#e0e0e0',
        light: '#bdbdbd',
        mercury: '#e3e3e3',
        background: '#e5e5e5',
        chateau: '#9fa2a8',
        backgroundLight: 'rgba(196, 196, 196, 0.1)',
        opaque: 'rgba(189, 189, 189, 0.4)',
        solid: '#808080',
        dusty: '#979797', // or #9f9f9f
        alabaster: '#fcfcfc',
        divider: '#e3e3e3',
        pale: '#EEEFF0',
        lightest:
          'transparent linear-gradient(262deg, #FFFFFF 0%, #EEEFF0 100%) 0% 0% no-repeat padding-box',
        dark: '#0000001F',
        darkest: '#00000029',
        transparent: 'rgba(0, 0, 0, 0.26)',
        paleSky: '#6f737d',
      },
      divider: '#e4e7e8',
      custom: {
        yellow: '#f2c50b',
        red: '#f15858',
        blue: '#3f51b5',
        eerieBlack: '#212121',
        zircon: '#f4f9ff',
        goldenrod: '#F2B140',
      },
    },
    typography: {
      fontFamily: 'Roboto, sans-serif',
      h1: {
        fontSize: '3.2rem',
        fontWeight: '500',
      },
      h2: {
        fontSize: '2.4rem',
        fontWeight: '500',
      },
      h3: {
        fontSize: '2rem',
        fontWeight: '500',
      },
      h4: {
        fontSize: '1.6rem',
        fontWeight: '500',
      },
      h5: {
        fontSize: '1.4rem',
        fontWeight: '500',
      },
      h6: {
        fontSize: '1.2rem',
        fontWeight: '500',
        lineHeight: '1.8rem',
      },
      subtitle1: {
        fontSize: '1.4rem',
      },
      subtitle2: {
        fontSize: '1.2rem',
        fontWeight: 'normal',
      },
      body2: {
        fontSize: '1.6rem',
      },
      overline: {
        fontSize: '1.2rem',
        textTransform: 'none',
        color: '#b00020',
      },
      button: {
        textTransform: 'none',
        fontSize: '1.4rem',
        padding: '1.2rem 2.4rem',
      },
    },
    breakpoints: {
      values: {
        md: MOBILE,
        lg: TABLET,
      },
    },
    layers: {
      cookiesModal: '10',
    },
    overrides: {
      MuiButton: {
        containedPrimary: {
          color: 'white',
          fontSize: '1.4rem',
          fontWeight: 'bold',
          padding: '1rem 2.4rem',
        },
      },
      MuiFormControl: {
        marginNormal: {
          marginTop: '0px',
          marginBottom: '0px',
        },
      },
      MuiCard: {
        root: {
          borderRadius: '0.4rem',
          backgroundColor: 'white',
          boxShadow: '0 0.2rem 0.4rem 0 rgba(0, 0, 0, 0.16)',
        },
      },
      MuiSvgIcon: {
        root: {
          width: '2.4rem',
          height: '2.4rem',
        },
      },
      MuiInputBase: {
        input: {
          fontSize: '1.6rem',
          color: 'rgba(0, 0, 0, 0.4)',
        },
      },
      MuiInputLabel: {
        root: {
          fontSize: '2rem',
        },
        outlined: {
          transform: 'translate(20px, 20px) scale(1)',
        },
      },
      MuiOutlinedInput: {
        root: {
          height: '56px',

          '&.Mui-error .MuiOutlinedInput-notchedOutline': { borderColor: '#b00020' },
        },
        input: {
          padding: '23px 14px',
        },
      },
      MuiMenuItem: {
        root: {
          fontSize: '1.6rem',
        },
      },
    },
  })

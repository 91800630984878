import { PARTICIPANTS_TYPE } from '_utils/constants'

// TODO: Integrate options and prop name "TENANT", "GUARANTOR", "INCOME_HELPER"
export const CREDIT_ANALYSIS_PARTICIPANTS = {
  [PARTICIPANTS_TYPE.TENANT]: {
    title: 'Moradores',
    description: 'Adicione ou cadastre pessoas para participar da sua análise cadastral.',
  },
  [PARTICIPANTS_TYPE.GUARANTOR]: {
    title: 'Fiadores',
    description: 'Adicione ou cadastre pessoas para participar da sua análise cadastral.',
  },
  [PARTICIPANTS_TYPE.INCOME_HELPER]: {
    title: 'Responsáveis pelo pagamento',
    description: 'Adicione ou cadastre pessoas para participar da sua análise cadastral.',
  },
}

import { Record } from 'immutable'

import { createReducer } from '_utils/redux'

const INITIAL_STATE = new Record({
  isMobile: false,
  origin: undefined,
})()

const server = createReducer(INITIAL_STATE, {})

export default server

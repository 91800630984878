import React, { memo } from 'react'
import PropTypes from 'prop-types'

import { shadeColor } from '_utils/colors'

const HourglassIcon = ({ className, primaryColor }) => {
  const lighterColor = shadeColor(primaryColor)

  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 176 176">
      <path
        fill="#C5CED5"
        d="M12.7 137.3h-1.2c-1.9 0-3.5 1.6-3.5 3.6v7.1c0 2 1.6 3.6 3.5 3.6h104.7c1.9 0 3.5-1.6 3.5-3.6v-7.1c0-2-1.6-3.6-3.5-3.6H115c0-23.8-9.4-49.1-28.3-61.6 19-12.5 28.3-37.9 28.3-61.6h1.2c1.9 0 3.5-1.6 3.5-3.6V3.6c0-2-1.6-3.6-3.5-3.6H11.5C9.6 0 8 1.6 8 3.6v7.1c0 2 1.6 3.6 3.5 3.6h1.2c0 23.7 9.3 49 28.2 61.5-19 12.5-28.2 37.8-28.2 61.5zm88.4 0H26.6c0-30.1 16.7-54.5 37.2-54.5 20.5 0 37.3 24.4 37.3 54.5zM26.6 14.2H101c0 30.1-16.7 54.5-37.2 54.5-20.5 0-37.2-24.4-37.2-54.5z"
      />
      <path fill="#DDE2E9" d="M103.1 14.2H24.6c0 30.1 17.6 54.5 39.2 54.5s39.3-24.4 39.3-54.5z" />
      <path
        fill="#ECEFF1"
        d="M38.198 53.9h-1.3c7 9.2 16.5 14.8 26.9 14.8 21.7 0 39.2-24.4 39.2-54.5h-.3c-3.3 22.3-30.9 39.7-64.5 39.7z"
      />
      <path fill="#DDE2E9" d="M24.6 137.3h78.5c0-30.1-17.6-54.5-39.2-54.5s-39.3 24.5-39.3 54.5z" />
      <path
        fill="#ECEFF1"
        d="M63.8 82.9C46 82.9 31 99.3 26.2 121.8c-.3 1.2-.5 2.5-.7 3.7v.1c-.2 1.2-.4 2.5-.5 3.8v.2c-.1.6-.1 1.1-.2 1.7v.3c0 .6-.1 1.2-.1 1.8v.2c0 .5 0 1.1-.1 1.6v2.3h.3c.8-5.5 3.2-10.8 6.7-15.5 10.7-14.3 32.6-24.2 57.8-24.2h1.3c-7-9.3-16.5-14.9-26.9-14.9z"
      />
      <path
        style={{ fill: primaryColor }}
        d="M119.399 110.6c.2 1 1 1.7 2 1.7h2.1c1.2 0 2.2-1.1 2.1-2.3-.5-3.6-2.1-7-4.6-9.6-1.8-1.9-3-4.3-3.3-6.9-.1-1-1-1.8-2-1.8h-2.1c-1.2 0-2.2 1.1-2.1 2.3.5 4.1 2.3 7.9 5.1 10.8 1.5 1.7 2.5 3.7 2.8 5.8zM133.501 110.6c.2 1 1 1.7 2 1.7h2.1c1.2 0 2.2-1.1 2.1-2.3-.5-3.6-2.1-7-4.6-9.6-1.8-1.9-3-4.3-3.3-6.9-.1-1-1-1.8-2-1.8h-2.1c-1.2 0-2.2 1.1-2.1 2.3.5 4.1 2.3 7.9 5.1 10.8 1.5 1.7 2.5 3.7 2.8 5.8zM153.898 122.5h-46.5c-2.2 0-4 1.8-4 4.1v24.7c0 6.8 5.4 12.3 12.1 12.3h24.2c6.7 0 12.1-5.5 12.1-12.3h2c7.8 0 14.1-6.5 14.1-14.4s-6.2-14.4-14-14.4zm0 20.6h-2v-12.3h2c3.3 0 6.1 2.8 6.1 6.2-.1 3.3-2.8 6.1-6.1 6.1zM161.9 176H97.3c-6 0-7.7-8.2-4.5-8.2h73.6c3.2 0 1.5 8.2-4.5 8.2z"
      />
      <path
        style={{ fill: lighterColor }}
        d="M119.399 110.6c.2 1 1 1.7 2 1.7h2.1c1.2 0 2.2-1.1 2.1-2.3-.5-3.6-2.1-7-4.6-9.6-1.8-1.9-3-4.3-3.3-6.9-.1-1-1-1.8-2-1.8h-2.1c-1.2 0-2.2 1.1-2.1 2.3.5 4.1 2.3 7.9 5.1 10.8 1.5 1.7 2.5 3.7 2.8 5.8zM133.501 110.6c.2 1 1 1.7 2 1.7h2.1c1.2 0 2.2-1.1 2.1-2.3-.5-3.6-2.1-7-4.6-9.6-1.8-1.9-3-4.3-3.3-6.9-.1-1-1-1.8-2-1.8h-2.1c-1.2 0-2.2 1.1-2.1 2.3.5 4.1 2.3 7.9 5.1 10.8 1.5 1.7 2.5 3.7 2.8 5.8z"
      />
    </svg>
  )
}

HourglassIcon.propTypes = {
  className: PropTypes.string,
  primaryColor: PropTypes.string.isRequired,
}

HourglassIcon.defaultProps = {
  className: '',
}

export default memo(HourglassIcon)

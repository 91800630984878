import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Slider from 'react-slick'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { List } from 'immutable'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import NextArrow from './next-arrow'
import PreviousArrow from './prev-arrow'
import CarouselPaging from './carousel-paging'
import styles from './styles.css'

class PagedSlickCarousel extends PureComponent {
  static propTypes = {
    settings: PropTypes.shape(),
    children: PropTypes.node.isRequired,
    onPageChange: PropTypes.func,
    onPictureView: PropTypes.func,
    pictures: ImmutablePropTypes.list,
    shouldHidePagingOnDesktop: PropTypes.bool,
  }

  static defaultProps = {
    settings: {
      dots: false,
      infinite: true,
      speed: 350,
      slidesToShow: 1,
    },
    onPageChange: () => {},
    onPictureView: () => {},
    pictures: List(),
    shouldHidePagingOnDesktop: false,
  }

  state = {
    selectedItem: 0,
    showArrows: false,
  }

  componentDidMount() {
    this.container.addEventListener('touchstart', this.touchStart)
    this.container.addEventListener('touchmove', this.preventTouch, { passive: false })
  }

  componentWillUnmount() {
    this.container.removeEventListener('touchstart', this.touchStart)
    this.container.removeEventListener('touchmove', this.preventTouch, { passive: false })
  }

  onPictureChange = (oldIndex, newIndex) => {
    const { pictures, onPictureView } = this.props
    this.setState(
      {
        selectedItem: newIndex,
      },
      () => this.props.onPageChange(newIndex)
    )
    if (pictures.size) {
      onPictureView(pictures.getIn([newIndex, 'picture']))
    }
  }

  getSelectedIndex = () => this.state.selectedItem

  setComponentRef = ref => {
    this.container = ref
  }

  showArrows = () => {
    this.setState({
      showArrows: true,
    })
  }

  hideArrows = () => {
    this.setState({
      showArrows: false,
    })
  }

  touchStart(e) {
    this.firstClientX = e.touches[0].clientX
    this.firstClientY = e.touches[0].clientY
  }

  preventTouch(e) {
    const minValue = 5 // threshold

    this.clientX = e.touches[0].clientX - this.firstClientX
    this.clientY = e.touches[0].clientY - this.firstClientY

    // Vertical scrolling does not work when you start swiping horizontally.
    if (Math.abs(this.clientX) > minValue) {
      e.preventDefault()
      e.returnValue = false
    }

    return false
  }

  render() {
    const { settings, children, shouldHidePagingOnDesktop } = this.props
    const { selectedItem, showArrows } = this.state
    const enhancedSettings = {
      ...settings,
      beforeChange: this.onPictureChange,
      nextArrow: <NextArrow visible={showArrows} />,
      prevArrow: <PreviousArrow visible={showArrows} />,
      arrows: true,
    }

    return (
      <div
        className={styles.container}
        onMouseEnter={this.showArrows}
        onMouseLeave={this.hideArrows}
        ref={this.setComponentRef}
      >
        <Slider {...enhancedSettings}>{children}</Slider>
        <CarouselPaging
          numberOfItems={children.size || children.length}
          selectedItem={selectedItem}
          className={classnames(styles.paging, {
            [styles['hide-on-desktop']]: shouldHidePagingOnDesktop,
          })}
        />
      </div>
    )
  }
}

export default PagedSlickCarousel

import { makeStyles } from '@material-ui/styles'

export default makeStyles(() => ({
  inputSortBy: {
    '& .MuiSelect-iconOutlined': {
      color: 'var(--mine-shaft)',
    },
    '& .MuiSelect-root ': {
      padding: '1.4rem 1.6rem 1rem 0',
      height: '4rem',
      boxSizing: 'border-box',
      fontSize: '1.4rem',
      color: 'var(--mine-shaft)',
    },
    '& .MuiOutlinedInput-root': {
      height: '4rem',
    },
    '& .MuiInputBase-root': {
      paddingLeft: '1.6rem',
    },
  },
}))

import React from 'react'

import ListingRegistrationFinishedContainer from '../final-step-container'

import styles from './styles.css'

const ListingRegistrationPending = () => (
  <ListingRegistrationFinishedContainer>
    <div className={styles.body}>
      <h1 className={styles.hidden}>Registro inicial finalizado</h1>
      <p className={styles.title}>Quase lá!</p>
      <p className={styles.content}>Em breve um especialista entrará em contato com você.</p>
    </div>
    <div className={styles.contact}>
      <h2 className={styles['secondary-title']}>Dúvidas?</h2>
      <a className={styles.email} href="mailto: atendimento@imobiliaria.com.br">
        atendimento@imobiliaria.com.br
      </a>
    </div>
  </ListingRegistrationFinishedContainer>
)

export default React.memo(ListingRegistrationPending)

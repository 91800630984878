import React, { PureComponent, Fragment, createRef } from 'react'
import { navigate } from '@reach/router'
import PropTypes from 'prop-types'
import { PulseLoader } from 'react-spinners'
import throttle from 'lodash.throttle'
import { connect } from 'react-redux'
import { Map } from 'immutable'
import memoizeOne from 'memoize-one'

import SlickCarousel from '_components/slick-carousel'
import Button, { ButtonColor, ButtonVariant } from '_components/button'
import { moment } from '_bootstrap'
import PrevArrow from '_components/slick-carousel/prev-arrow'
import NextArrow from '_components/slick-carousel/next-arrow'
import { getAvailability } from '_services/property'
import { sendEvent } from '_utils/mixpanel'
import { getBookingById } from '_modules/booking/selectors'
import UpdateDateModal from '_components/modals/update-date-modal'
import { getBooking, updateBooking, GET_BOOKING, UPDATE_BOOKING } from '_modules/booking/actions'
import { getAgency, GET_AGENCY } from '_modules/agency/actions'
import { primaryColor } from '_utils/colors'

import DateCard from './date-card'
import CarouselPicker from './carousel-picker'
import styles from './styles.css'

const day = memoizeOne(
  date =>
    moment(date)
      .format('D')
      .split('Z')[0]
)

const formattedMonth = memoizeOne(date => {
  const month = moment(date).format('MMM')
  return month.charAt(0).toUpperCase() + month.slice(1)
})

const DAY_MAPPING = {
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
  sunday: 7,
}

const mapStateToProps = (state, { id }) => ({
  scheduleTimesArray: state.saas.timesArray,
  booking: getBookingById(state, id),
  isLoadingBooking: !!state.loading.get(GET_BOOKING.ACTION),
  errorLoadingBooking: state.error.get(GET_BOOKING.ACTION),
  isUpdatingBooking: !!state.loading.get(UPDATE_BOOKING.ACTION),
  errorUpdatingBooking: state.error.get(UPDATE_BOOKING.ACTION),
  isLoadingAgency: !!state.loading.get(GET_AGENCY.ACTION),
  agency: state.agency.toJS(),
})

const mapDispatchToProps = {
  getAgency,
  getBooking,
  updateBooking,
}

class ScheduleContainer extends PureComponent {
  static propTypes = {
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }).isRequired,
    getBooking: PropTypes.func.isRequired,
    booking: PropTypes.shape(),
    updateBooking: PropTypes.func.isRequired,
    isUpdatingBooking: PropTypes.bool,
    errorUpdatingBooking: PropTypes.instanceOf(Map),
    isLoadingBooking: PropTypes.bool,
    errorLoadingBooking: PropTypes.instanceOf(Map),
    getAgency: PropTypes.func.isRequired,
    isLoadingAgency: PropTypes.bool.isRequired,
    agency: PropTypes.shape({
      minHours: PropTypes.string,
      slots: PropTypes.string,
    }).isRequired,
  }

  static defaultProps = {
    booking: undefined,
    isUpdatingBooking: false,
    errorUpdatingBooking: Map(),
    isLoadingBooking: false,
    errorLoadingBooking: Map(),
  }

  constructor(props) {
    super(props)
    this.waypointCallback = throttle(this.waypointCallback, 200)
    this.dateCarouselRef = createRef()

    this.state = {
      ready: !!props.agency.minHours,
      selectedOnce: false,
      selectedDateID: this.getInitialDateID(),
      centeredDate: undefined,
      datesArray: [
        ...new Array(30).fill(undefined).map((val, index) =>
          moment()
            .set({
              hour: 17,
              minute: 30,
              second: 0,
              millisecond: 0,
            })
            .add(index, 'days')
            .toISOString()
        ),
      ],
      selectedTimeID: undefined,
      timesArray: [],
      firstAvailableSlot: 0,
      isLoadingAvailability: false,
      updateVisitModal: false,
    }
  }

  componentDidMount() {
    const {
      location: { pathname },
      agency,
    } = this.props

    const id = pathname.split('minhas-visitas/')[1].split('/')[0]
    this.props.getBooking(id)

    if (!agency.minHours) {
      this.props.getAgency()
    }
  }

  componentDidUpdate(prevProps) {
    const {
      agency,
      booking,
      isUpdatingBooking,
      errorUpdatingBooking,
      isLoadingBooking,
      errorLoadingBooking,
      isLoadingAgency,
    } = this.props

    if (
      !!agency.minHours &&
      booking &&
      ((prevProps.isLoadingBooking && !isLoadingBooking && !errorLoadingBooking.size) ||
        (prevProps.isLoadingAgency && !isLoadingAgency))
    ) {
      this.setState(
        {
          selectedDateID: this.getInitialDateID(),
          ready: true,
        },
        this.searchAvailability
      )
    }

    if (prevProps.isUpdatingBooking && !isUpdatingBooking && !!errorUpdatingBooking) {
      navigate(`/minhas-visitas/${booking.id}`)
    }
  }

  onContinueClick = () => {
    this.setState({
      updateVisitModal: true,
    })
  }

  onTimeClick = id => {
    this.setState({
      selectedTimeID: Number(id),
      selectedOnce: true,
    })

    sendEvent('Agendamento Passo 1: Selecionou horário de agendamento')
  }

  onDateClick = event => {
    this.setState(
      {
        selectedDateID: Number(event.currentTarget.name),
        selectedOnce: true,
        selectedTimeID: undefined,
      },
      this.searchAvailability
    )
    sendEvent('Agendamento Passo 1: Selecionou data de agendamento')
  }

  onDateChange = index => {
    const position = index + 2

    this.setState(
      state => ({
        centeredDate: state.datesArray[position],
      }),
      () => {
        if (this.state.datesArray.length - position < 15) {
          this.loadMoreDates()
        }
      }
    )
  }

  onCloseUpdateModal = () => {
    this.setState({
      updateVisitModal: false,
    })
  }

  onConfirmUpdate = () => {
    const {
      booking: { id },
    } = this.props

    const { timesArray, datesArray, selectedTimeID, selectedDateID } = this.state

    const time =
      typeof selectedTimeID !== 'undefined' ? timesArray[selectedTimeID].time.split('H') : [0, 0]
    const date =
      typeof selectedDateID !== 'undefined' ? datesArray[selectedDateID].split('T')[0] : undefined
    /* 
      Actually the way time is being formated
      with timezone is a mystery so we subtract 3
      to fix the timezone issue but need a better solution 
    */
    const dateAndTime = moment(date)
      .set({
        hour: time[0],
        minute: time[1],
        second: 0,
        millisecond: 0,
      })
      .toISOString()

    this.props.updateBooking(
      {
        dateAndTime,
      },
      id
    )
  }

  getInitialDateID = () => {
    const { agency } = this.props

    if (!agency.minHours) return 0

    let momentDate = moment().add(agency.minHours, 'hours')

    const sortFunc = (a, b) => {
      const timeAArray = a.endTime.split(':')
      const timeBArray = b.endTime.split(':')
      const momentA = momentDate
        .clone()
        .set('hour', timeAArray[0])
        .set('minute', timeAArray[1])
        .set('second', timeAArray[2])
      const momentB = momentDate
        .clone()
        .set('hour', timeBArray[0])
        .set('minute', timeBArray[1])
        .set('second', timeBArray[2])

      return momentB.valueOf() - momentA.valueOf()
    }

    const filterFunc = slot => DAY_MAPPING[slot.weekday] === momentDate.isoWeekday()

    let latestSlot = agency.slots.filter(filterFunc).sort(sortFunc)[0]

    let timeArray = latestSlot ? latestSlot.endTime.split(':') : []

    while (
      !latestSlot ||
      momentDate
        .clone()
        .set('hour', timeArray[0])
        .set('minute', timeArray[1])
        .set('second', timeArray[2])
        .diff(moment(), 'hours') < agency.minHours
    ) {
      momentDate = momentDate.add(1, 'days')
      latestSlot = agency.slots.filter(filterFunc).sort(sortFunc)[0] // eslint-disable-line prefer-destructuring

      timeArray = latestSlot ? latestSlot.endTime.split(':') : []
    }

    return momentDate
      .set('hour', 0)
      .set('minute', 0)
      .set('second', 5)
      .diff(
        moment()
          .set('hour', 0)
          .set('minute', 0)
          .set('second', 0),
        'days'
      )
  }

  setTimeCarouselRef = ref => {
    this.timeCarouselRef = ref
  }

  searchAvailability = () => {
    const {
      booking: { listing },
    } = this.props
    const { selectedDateID, datesArray } = this.state
    this.setState({
      isLoadingAvailability: true,
    })
    getAvailability(
      listing.get('externalId'),
      moment(datesArray[selectedDateID]).format('YYYY-MM-DD')
    )
      .then(payload => {
        const availabilityArray = payload.map(slot => slot.available)
        const timesArray = [
          {
            available: false,
            time: moment(payload[0].date_and_time)
              .subtract(30, 'minutes')
              .format('HH[H]mm'),
          },
          ...payload.map(slot => ({
            available: slot.available,
            time: moment(slot.date_and_time).format('HH[H]mm'),
          })),
          {
            available: false,
            time: moment(payload[payload.length - 1].date_and_time)
              .add(30, 'minutes')
              .format('HH[H]mm'),
          },
        ]
        const firstAvailableSlot =
          availabilityArray.indexOf(true) > 0 ? availabilityArray.indexOf(true) : 0
        this.setState(
          {
            firstAvailableSlot,
            timesArray,
            isLoadingAvailability: false,
          },
          () => {
            this.scrollTimeCarousel()
            this.scrollDateCarousel()
          }
        )
      })
      .catch(() => {
        this.setState({
          firstAvailableSlot: -1,
          isLoadingAvailability: false,
        })
      })
  }

  scrollTimeCarousel = () => {
    const { firstAvailableSlot } = this.state
    const offset = 55
    const timeSize = 104
    const disabledInitialvalues = 2
    this.timeCarouselRef.scrollLeft =
      firstAvailableSlot > 2 ? (firstAvailableSlot - disabledInitialvalues) * timeSize + offset : 0
  }

  scrollDateCarousel = () => {
    const { selectedDateID } = this.state
    const offset = 0
    const dateSize = 80
    const disabledInitialvalues = 2
    this.dateCarouselRef.current.scrollLeft =
      selectedDateID > 2 ? (selectedDateID - disabledInitialvalues) * dateSize + offset : 0
  }

  loadMoreDates = () => {
    const { datesArray } = this.state
    const lastDate = [...datesArray].pop()

    this.setState(state => ({
      datesArray: [
        ...state.datesArray,
        ...new Array(30).fill(undefined).map((val, index) =>
          moment(lastDate)
            .add(index + 1, 'days')
            .toISOString()
        ),
      ],
    }))
  }

  waypointCallback = index => {
    const { datesArray } = this.state
    if (datesArray.length - index < 15) {
      this.loadMoreDates()
    }

    this.setState({
      centeredDate: datesArray[index],
    })
  }

  isTimeDisabled = index => {
    const { selectedDateID, timesArray } = this.state

    if (!selectedDateID) return true

    return !timesArray[index].available
  }

  isDateDisabled = date => {
    const { agency } = this.props
    if (!agency.minHours) return true

    const momentDate = moment(date)
    const dateSlot = agency.slots.filter(
      slot => DAY_MAPPING[slot.weekday] === momentDate.isoWeekday()
    )

    if (!dateSlot.length) return true

    const latestSlot = dateSlot.sort((a, b) => {
      const timeAArray = a.endTime.split(':')
      const timeBArray = b.endTime.split(':')
      const momentA = moment(date)
        .set('hour', timeAArray[0])
        .set('minute', timeAArray[1])
        .set('second', timeAArray[2])
      const momentB = moment(date)
        .set('hour', timeBArray[0])
        .set('minute', timeBArray[1])
        .set('second', timeBArray[2])

      return momentB.valueOf() - momentA.valueOf()
    })[0]

    const timeArray = latestSlot.endTime.split(':')

    return (
      momentDate
        .set('hour', timeArray[0])
        .set('minute', timeArray[1])
        .set('second', timeArray[2])
        .diff(moment(), 'hours') < agency.minHours
    )
  }

  renderTitle = () =>
    moment(this.state.centeredDate || new Date())
      .format('MMMM [de] YYYY')
      .capitalize()

  render() {
    const {
      ready,
      datesArray,
      selectedDateID,
      timesArray,
      selectedTimeID,
      firstAvailableSlot,
      isLoadingAvailability,
      selectedOnce,
      updateVisitModal,
    } = this.state
    const { isUpdatingBooking } = this.props

    const dateSettings = {
      className: styles['date-picker'],
      dots: false,
      infinite: false,
      speed: 100,
      slidesToShow: 5,
      initialSlide: selectedDateID - 2,
      prevArrow: <PrevArrow />,
      nextArrow: <NextArrow />,
      afterChange: this.onDateChange,
      draggable: false,
    }

    const timeSettings = {
      className: styles['time-picker'],
      dots: false,
      infinite: false,
      speed: 100,
      slidesToShow: 4,
      initialSlide: firstAvailableSlot - 1 > 0 ? firstAvailableSlot - 1 : 0,
      prevArrow: <PrevArrow smallSize />,
      nextArrow: <NextArrow smallSize />,
      draggable: false,
    }

    const nextButtonEnabled = selectedDateID && typeof selectedTimeID !== 'undefined'

    const selectedDate = selectedDateID ? datesArray[selectedDateID].split('T')[0] : undefined
    return (
      <div className={styles.main}>
        <div className={styles.container}>
          <div className={styles.content}>
            <h1 className={styles.title}>Escolha a data e hora da sua visita</h1>
            {!ready ? (
              <div className={styles.load}>
                <PulseLoader sizeUnit="px" size={8} margin="4px" color={primaryColor} loading />
              </div>
            ) : (
              <Fragment>
                <div className={styles['date-container']}>
                  <p className={styles['date-title']}>{this.renderTitle()}</p>
                  <div className={styles['desktop-carousel']}>
                    <SlickCarousel settings={dateSettings}>
                      {datesArray.map((date, index) => (
                        <Fragment key={`key-${date}`}>
                          <DateCard
                            key={`key-date-card-${date}`}
                            isSelected={selectedDateID === index && selectedOnce}
                            date={date}
                            name={index}
                            onClick={this.onDateClick}
                            isDisabled={this.isDateDisabled(date)}
                            className={styles['date-card']}
                          />
                        </Fragment>
                      ))}
                    </SlickCarousel>
                  </div>
                  <div className={styles['mobile-carousel']}>
                    <CarouselPicker
                      className={styles['dates-carousel']}
                      waypointCallback={this.waypointCallback}
                      setCarouselRef={this.dateCarouselRef}
                    >
                      {datesArray.map((date, index) => (
                        <DateCard
                          key={`key-date-card-${date}`}
                          isSelected={selectedDateID === index && selectedOnce}
                          date={date}
                          name={index}
                          onClick={this.onDateClick}
                          isDisabled={this.isDateDisabled(date)}
                        />
                      ))}
                    </CarouselPicker>
                  </div>
                </div>
                <div className={styles['time-container']}>
                  {isLoadingAvailability ? (
                    <div className={styles.loader}>
                      <PulseLoader
                        sizeUnit="px"
                        size={8}
                        margin="4px"
                        color={primaryColor}
                        loading
                      />
                    </div>
                  ) : (
                    <Fragment>
                      <p className={styles['time-title']}>Hora</p>
                      <div className={styles['desktop-carousel']}>
                        <SlickCarousel settings={timeSettings}>
                          {timesArray.map((time, index) => (
                            <Fragment key={`key-${time.time}`}>
                              <Button
                                name={index}
                                onClick={this.onTimeClick}
                                active={selectedTimeID === index}
                                className={styles['hour-button']}
                                disabled={this.isTimeDisabled(index)}
                                id={index}
                              >
                                {time.time}
                              </Button>
                            </Fragment>
                          ))}
                        </SlickCarousel>
                      </div>
                      <div className={styles['mobile-carousel']}>
                        <CarouselPicker
                          className={styles['times-carousel']}
                          setCarouselRef={this.setTimeCarouselRef}
                        >
                          {timesArray.map((time, index) => (
                            <Button
                              name={index}
                              key={`key-${time.time}`}
                              onClick={this.onTimeClick}
                              active={selectedTimeID === index}
                              className={styles['hour-button']}
                              disabled={this.isTimeDisabled(index)}
                              variant={ButtonVariant.BOLD}
                              id={index}
                            >
                              {time.time}
                            </Button>
                          ))}
                        </CarouselPicker>
                      </div>
                    </Fragment>
                  )}
                </div>
              </Fragment>
            )}
            <div className={styles['action-container']}>
              <Button
                color={!nextButtonEnabled ? ButtonColor.SECONDARY : ButtonColor.PRIMARY}
                variant={ButtonVariant.BOLD}
                disabled={!nextButtonEnabled}
                onClick={this.onContinueClick}
              >
                CONTINUAR
              </Button>
            </div>
          </div>
        </div>
        {updateVisitModal && (
          <UpdateDateModal
            closeModal={this.onCloseUpdateModal}
            onUpdateDate={this.onConfirmUpdate}
            primaryColor={primaryColor}
            isUpdatingBooking={isUpdatingBooking}
            day={day(selectedDate)}
            month={formattedMonth(selectedDate)}
          />
        )}
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(ScheduleContainer))

import qs from 'qs'
import { Record, List } from 'immutable'

import { Booking } from '_models'
import { createReducer } from '_utils/redux'
import { LOGOUT } from '_modules/user/actions'
import { BOOK_PROPERTY } from '_modules/property/actions'
import { getPropertyDefaultTitle } from '_utils/property'

import {
  GET_BOOKINGS,
  GET_BOOKING,
  CANCEL_BOOKING,
  UPDATE_BOOKING,
  SEND_BOOKING_FEEDBACK,
  GIVE_UP_LISTING,
  UPDATE_OCCURRENCE,
  UPDATE_BOOKING_FEEDBACK,
} from './actions'

const INITIAL_STATE = new Record({
  nextPage: undefined,
  bookings: List(),
  newBooking: '',
})()

export const setNewBooking = payload =>
  new Booking({
    ...payload,
    listing: { ...payload.listing, slug: getPropertyDefaultTitle(payload.listing, true) },
  })

const updateBooking = (state, { payload }) => {
  const bookingIndex = state.get('bookings').findIndex(booking => booking.get('id') === payload.id)

  return state.setIn(['bookings', bookingIndex], setNewBooking(payload))
}
const bookings = createReducer(INITIAL_STATE, {
  [BOOK_PROPERTY.FULFILLED]: (state, { payload }) => {
    const newBooking = setNewBooking(payload)
    const bookingIndex = state.bookings.findIndex(booking => booking.get('id') === payload.id)
    return state.setIn(['bookings', bookingIndex], newBooking)
  },
  [UPDATE_BOOKING.FULFILLED]: (state, { payload }) => {
    const newBooking = setNewBooking(payload)
    const bookingIndex = state.bookings.findIndex(booking => booking.get('id') === payload.id)
    return state.setIn(['bookings', bookingIndex], newBooking).set('newBooking', payload.id)
  },
  [GET_BOOKINGS.FULFILLED]: (state, { payload }) => {
    const { results, next } = payload
    const parsedQuery = qs.parse((next || '').split('?').pop())
    const newBookings = results.map(booking => setNewBooking(booking))
    return state
      .set('nextPage', parsedQuery.page)
      .set('bookings', state.bookings.concat(newBookings))
  },

  [GET_BOOKINGS.PENDING]: (state, { meta }) => {
    if (meta.loadMore) {
      return state
    }
    return INITIAL_STATE
  },

  [GET_BOOKING.FULFILLED]: (state, { payload }) => {
    const newBooking = setNewBooking(payload)
    const bookingIndex = state.bookings.findIndex(booking => booking.get('id') === payload.id)

    if (bookingIndex >= 0) {
      return state.setIn(['bookings', bookingIndex], newBooking)
    }

    return state.set('bookings', state.bookings.concat(List([newBooking])))
  },

  [CANCEL_BOOKING.FULFILLED]: (state, { meta }) =>
    state.deleteIn([
      'bookings',
      state.bookings.findIndex(booking => booking.get('id') === meta.bookingId),
    ]),

  [SEND_BOOKING_FEEDBACK.FULFILLED]: (state, { payload }) => {
    const bookingIndex = state
      .get('bookings')
      .findIndex(booking => booking.get('id') === payload.booking)

    return state
      .updateIn(['bookings', bookingIndex, 'feedback'], feedback =>
        feedback && feedback.length ? feedback.push(payload) : [payload]
      )
      .setIn(['bookings', bookingIndex, 'feedbackId'], payload.id)
      .setIn(['bookings', bookingIndex, 'hasReportedFeedback'], true)
      .setIn(['bookings', bookingIndex, 'userAction'], payload.userAction)
  },
  [UPDATE_BOOKING_FEEDBACK.FULFILLED]: (state, { payload }) => {
    const bookingIndex = state
      .get('bookings')
      .findIndex(booking => booking.get('id') === payload.booking)
    return state.updateIn(['bookings', bookingIndex, 'feedback'], feedback =>
      feedback && feedback.length ? feedback.push(payload) : [payload]
    )
  },
  [GIVE_UP_LISTING.FULFILLED]: updateBooking,
  [UPDATE_OCCURRENCE.FULFILLED]: (state, { payload }) => {
    const bookingIndex = state
      .get('bookings')
      .findIndex(booking => booking.get('id') === payload.id)

    return state
      .setIn(['bookings', bookingIndex], setNewBooking(payload))
      .setIn(['bookings', bookingIndex, 'isReportProblemFlow'], true)
  },

  [LOGOUT]: () => INITIAL_STATE,
})

export default bookings

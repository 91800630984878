export const STATUS_CHIP_THEME = {
  ORANGE: 'orange',
  GREEN: 'green',
  BLUE: 'blue',
  GRAY: 'gray',
}

export const STATUS_TYPE = {
  NEW: 'new',
  WAITING: 'waiting',
  COMPLETE: 'complete',
  INCOMPLETE: 'incomplete',
  UPDATED_BY_STAFF: 'updated_by_staff',
  WAITING_INVITATION: 'waiting_invitation',
}

export const STATUS_OPTIONS = {
  [STATUS_TYPE.COMPLETE]: {
    theme: STATUS_CHIP_THEME.GREEN,
    label: 'Cadastro completo',
  },
  [STATUS_TYPE.WAITING_INVITATION]: {
    theme: STATUS_CHIP_THEME.ORANGE,
    label: 'Aguardando envio dos dados',
  },
  [STATUS_TYPE.WAITING]: {
    theme: STATUS_CHIP_THEME.ORANGE,
    label: 'Aguardando envio dos dados',
  },
  [STATUS_TYPE.INCOMPLETE]: {
    theme: STATUS_CHIP_THEME.GRAY,
    label: 'Dados incompletos',
  },
  [STATUS_TYPE.NEW]: {
    label: 'Novo',
    theme: STATUS_CHIP_THEME.GREEN,
  },
  [STATUS_TYPE.UPDATED_BY_STAFF]: {
    label: 'Alterado pela imobiliária',
    theme: STATUS_CHIP_THEME.BLUE,
  },
}

import { Record, fromJS, Iterable } from 'immutable'
import Humps from 'humps'

const Model = defaultValues =>
  class extends Record({
    ...defaultValues,
  }) {
    constructor(props) {
      const pureObj = Object.keys(props || {}).reduce((newProps, key) => {
        if (Iterable.isIterable(props[key])) {
          return { ...newProps, [key]: props[key].toJS() }
        }

        return { ...newProps, [key]: props[key] }
      }, {})
      const camelizedProps = Humps.camelizeKeys({ ...pureObj })
      const sanitizedProps = Object.keys(camelizedProps).reduce((newProps, key) => {
        if (camelizedProps[key] !== undefined) {
          return { ...newProps, [key]: camelizedProps[key] }
        }

        return newProps
      }, {})

      super(fromJS(sanitizedProps))
    }

    sanitizeProps(props) {
      const pureObj = Object.keys(props || {}).reduce((newProps, key) => {
        if (Iterable.isIterable(props[key])) {
          return { ...newProps, [key]: props[key].toJS() }
        }

        return { ...newProps, [key]: props[key] }
      }, {})
      const camelizedProps = Humps.camelizeKeys({ ...pureObj })
      return fromJS(
        this.keySeq().reduce((newProps, key) => {
          if (camelizedProps[key] !== undefined) {
            return { ...newProps, [key]: camelizedProps[key] }
          }

          return newProps
        }, {})
      )
    }

    mergeProps(props) {
      return this.merge(this.sanitizeProps(props))
    }

    mergeDeepProps(props) {
      return this.mergeDeep(this.sanitizeProps(props))
    }

    setVal(key, val) {
      return this.set(key, val)
    }

    delKey(key) {
      return this.delete(key)
    }

    reset() {
      return this.clear()
    }

    toApi() {
      return Humps.decamelizeKeys(this.toJS())
    }
  }

export default Model

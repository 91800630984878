import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import CardContent from '../card-content'

import styles from './styles.css'

const Card = ({ hasCompetitor, ...props }) => {
  const { competitor, exclusivity } = props
  return (
    <article
      className={classnames(styles.card, {
        [styles.competition]: competitor,
        [styles['has-competitor']]: hasCompetitor,
        [styles.mobile]: hasCompetitor && competitor,
        [styles.advantageous]: exclusivity && !hasCompetitor,
      })}
    >
      <CardContent hasCompetitor={hasCompetitor} {...props} />
    </article>
  )
}

Card.propTypes = {
  competitor: PropTypes.bool,
  exclusivity: PropTypes.bool,
  hasCompetitor: PropTypes.bool,
}

Card.defaultProps = {
  competitor: false,
  exclusivity: false,
  hasCompetitor: false,
}

export default React.memo(Card)

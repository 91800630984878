import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { toCurrency } from '_utils/helpers'
import { LABEL_RENT_PRICE, DEFAULT_VALUE_PRICE } from '_utils/constants'

import styles from './styles.css'

const RentDetail = ({
  rentPrice,
  condominium,
  fee,
  insurance,
  garbageFee,
  totalValue,
  className,
}) => (
  <div className={classnames(styles.details, className)}>
    <div className={classnames(styles['rent-section'], styles.description)}>
      <span>{LABEL_RENT_PRICE}</span>
      <span>{toCurrency(rentPrice)}</span>
    </div>
    {condominium > 0 && (
      <div className={classnames(styles['condominium-section'], styles.description)}>
        <span>Condomínio*</span>
        <span>{toCurrency(condominium)}</span>
      </div>
    )}
    {fee > 0 && (
      <div className={classnames(styles['fee-section'], styles.description)}>
        <span>IPTU*</span>
        <span>{toCurrency(fee)}</span>
      </div>
    )}
    {insurance > 0 && (
      <div className={classnames(styles['insurance-section'], styles.description)}>
        <span>Seguro incêndio</span>
        <span>{toCurrency(insurance)}</span>
      </div>
    )}
    {garbageFee > 0 && (
      <div className={classnames(styles['insurance-section'], styles.description)}>
        <span>Taxa de lixo</span>
        <span>{toCurrency(insurance)}</span>
      </div>
    )}
    <div className={styles['comment-section']}>
      <p className={styles.comment}>*O valor pode variar.</p>
      <hr />
    </div>

    <div className={classnames(styles.total, styles.description)}>
      <span>Valor Total</span>
      <span>{toCurrency(totalValue)}</span>
    </div>
  </div>
)
RentDetail.propTypes = {
  rentPrice: PropTypes.string,
  condominium: PropTypes.string,
  fee: PropTypes.string,
  insurance: PropTypes.string,
  garbageFee: PropTypes.string,
  totalValue: PropTypes.string,
  className: PropTypes.string,
}

RentDetail.defaultProps = {
  rentPrice: DEFAULT_VALUE_PRICE,
  condominium: '',
  fee: '',
  insurance: '',
  garbageFee: '',
  totalValue: '',
  className: '',
}

export default RentDetail

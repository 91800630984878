import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ palette, breakpoints }) => ({
  container: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr 46rem 1fr',
    padding: '4rem',

    [breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
      padding: '0',
    },
  },
  paper: {
    gridColumn: 2,
    boxShadow: 'none',
    padding: '4rem 7rem 5rem',
    display: 'grid',
    gridTemplateRows: 'repeat(3, auto)',
    gridGap: '4rem',
    alignItems: 'center',
    justifyContent: 'center',

    [breakpoints.down('sm')]: {
      gridColumn: 1,
      backgroundColor: 'inherit',
      padding: '4.8rem 2.4rem',
    },
  },
  title: {
    textAlign: 'center',
    fontWeight: 'bold',
    lineHeight: 1.5,

    [breakpoints.down('sm')]: {
      fontSize: '1.6rem',
    },
  },
  terms: {
    textAlign: 'center',
    color: palette.gray.dusty,
    paddingTop: '1.6rem',
    borderTop: `0.1rem solid ${palette.gray.dusty}`,
    lineHeight: 2,

    [breakpoints.down('sm')]: {
      marginTop: '4rem',
    },
  },
  textField: {
    marginBottom: '1.6rem',

    '& .MuiOutlinedInput-root': {
      height: '4.8rem',
    },
    '& .MuiInputLabel-root': {
      fontSize: '1.4rem',
    },
  },
  button: {
    marginTop: '2.4rem',
  },
  link: {
    fontWeight: 'bold',
  },
}))

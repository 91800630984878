import React, { Fragment, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'

import { listingRegistrationFormSelector } from '_modules/listing-registration/selectors'
import InputButton from '_components/button/input-button'

import styles from '../styles.css'

const Step5 = () => {
  const { responsible } = useSelector(listingRegistrationFormSelector)
  const { setValue } = useFormContext()

  useEffect(() => {
    setValue('responsible', responsible)
  }, [responsible, setValue])

  return (
    <Fragment>
      <header className={styles.header}>
        <h1 className={styles.title}>O que você é do imóvel?</h1>
      </header>
      <div className={styles.group}>
        <InputButton
          className={styles.radio}
          name="responsible"
          id="owner"
          value="owner"
          label="Proprietário (a)"
        />
        <InputButton
          className={styles.radio}
          name="responsible"
          id="realtor"
          value="realtor"
          label="Corretor (a)"
        />
        <InputButton
          className={styles.radio}
          name="responsible"
          id="other"
          value="other"
          label="Outro (a)"
        />
      </div>
    </Fragment>
  )
}

export default React.memo(Step5)

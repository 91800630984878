import React, { useMemo, useCallback } from 'react'
import { Select, Checkbox, MenuItem, InputAdornment, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'

import useStyles from './styles'

const SelectMultipleCheckbox = ({
  id,
  name,
  classNames,
  value,
  labelItem,
  optionsItens,
  handleChange,
  disableSelect,
  startAdornmentIcon,
  variant,
  displayEmpty,
}) => {
  const styles = useStyles()

  const adornment = useMemo(
    () => ({
      startAdornment: <InputAdornment position="start">{startAdornmentIcon}</InputAdornment>,
      endAdornment: value.length > 1 && (
        <InputAdornment position="end" className={styles.totalSelected}>
          <Typography>+{value.length}</Typography>
        </InputAdornment>
      ),
    }),
    [value, startAdornmentIcon, styles]
  )

  const selectLabel = useCallback(
    itemSelected => {
      if (
        itemSelected &&
        itemSelected.length > 0 &&
        optionsItens &&
        optionsItens.size > 0 &&
        optionsItens.some(item => item.value === itemSelected[0])
      ) {
        const neighborhood = optionsItens.find(item => item.value === itemSelected[0]).label
        return neighborhood
      }
      return labelItem
    },
    [optionsItens, labelItem]
  )

  return (
    <Select
      id={id}
      multiple
      displayEmpty={displayEmpty}
      className={classNames}
      value={value}
      renderValue={selectLabel}
      onChange={handleChange}
      variant={variant}
      name={name}
      disabled={disableSelect}
      startAdornment={startAdornmentIcon ? adornment.startAdornment : ''}
      endAdornment={adornment.endAdornment}
    >
      {labelItem && (
        <MenuItem key="empty" value={labelItem} disabled>
          {labelItem}
        </MenuItem>
      )}
      {optionsItens.map(item => (
        <MenuItem key={item.value} value={item.value}>
          <Checkbox
            checked={value && value.some(itemSelected => itemSelected === item.value)}
            name={item.label}
            color="primary"
          />
          {item.label}
        </MenuItem>
      ))}
    </Select>
  )
}

SelectMultipleCheckbox.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  classNames: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.string),
  labelItem: PropTypes.string.isRequired,
  optionsItens: PropTypes.shape([
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ]),

  disableSelect: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  startAdornmentIcon: PropTypes.node,
  variant: PropTypes.string,
  displayEmpty: PropTypes.bool,
}

SelectMultipleCheckbox.defaultProps = {
  id: null,
  name: '',
  classNames: '',
  disableSelect: false,
  variant: 'outlined',
  displayEmpty: true,
  startAdornmentIcon: '',
  optionsItens: [],
  value: [],
}

export default React.memo(SelectMultipleCheckbox)

import React, { useCallback, useContext } from 'react'
import { navigate } from '@reach/router'
import { useSelector } from 'react-redux'

import { getUserInfoSelector } from '_modules/user/selectors'
import Illustration from '_assets/images/customer-phone-1.svg'
import { MOBILE_BREAKPOINT } from '_utils/constants'
import { useWindowWidth } from '_utils/hooks'
import Button, { ButtonColor, ButtonVariant, ButtonFormat } from '_components/button'
import { RedirectContext } from '_context/redirect'

import styles from './styles.css'

const RegisterSection = () => {
  const { setRedirectUri } = useContext(RedirectContext)

  const userInfo = useSelector(getUserInfoSelector)
  const windowWidth = useWindowWidth()

  const onAnnounceClick = useCallback(() => {
    if (userInfo.fullName) {
      navigate('/anunciar-imovel/cadastro-inicial')
      return
    }
    setRedirectUri('/anunciar-imovel/cadastro-inicial')
    navigate('/login')
  }, [setRedirectUri, userInfo.fullName])

  return (
    <section className={styles.container}>
      <div className={styles.announce}>
        <header className={styles.header}>
          <h1 className={styles.title}>Anuncie seu imóvel com alguns cliques</h1>
          <p className={styles.paragraph}>
            Para alugar seu imóvel, deixe seu imóvel com quem tem experiência.
          </p>
          <Button
            color={ButtonColor.PRIMARY}
            variant={ButtonVariant.BOLD}
            format={ButtonFormat.ROUNDED}
            className={styles.link}
            onClick={onAnnounceClick}
          >
            {windowWidth > MOBILE_BREAKPOINT ? 'QUERO ANUNCIAR' : 'ANUNCIAR IMÓVEL'}
          </Button>
        </header>
        <svg aria-hidden="true" className={styles.illustration}>
          <use xlinkHref={Illustration} />
        </svg>
      </div>
    </section>
  )
}

export default React.memo(RegisterSection)

import React, { PureComponent } from 'react'
import { Redirect, navigate } from '@reach/router'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Map } from 'immutable'
import memoizeOne from 'memoize-one'

import ConfirmationIcon from '_assets/images/ic-check-circle.svg'
import Input from '_components/input'
import Button, { ButtonColor, ButtonFormat } from '_components/button'
import { updateUser, UPDATE_USER } from '_modules/user/actions'
import { User } from '_models'
import { sendEvent, registerEvent } from '_utils/mixpanel'
import { validationCPF } from '_utils/helpers'
import { primaryColor } from '_utils/colors'

import styles from './styles.css'

const mapStateToProps = ({ user, loading, error }) => ({
  user,
  isUpdatingUser: !!loading.get(UPDATE_USER.ACTION),
  updateUserError: error.get(UPDATE_USER.ACTION),
})

const mapDispatchToProps = {
  updateUser,
}

const fillColorStyle = memoizeOne(color => ({ fill: color }))

const fontColorStyle = memoizeOne(color => ({ color }))

const onTermsClick = memoizeOne(() =>
  sendEvent('Agendamento Passo 4: Selecionou para navegar para Termos de serviço')
)

const onPrivacyClick = memoizeOne(() =>
  sendEvent('Agendamento Passo 4: Selecionou para navegar para Política de privacidade')
)

class SignUp extends PureComponent {
  static propTypes = {
    location: PropTypes.shape({
      state: PropTypes.shape({
        redirectTo: PropTypes.string,
      }),
    }).isRequired,
    user: PropTypes.instanceOf(User),
    updateUser: PropTypes.func.isRequired,
    isUpdatingUser: PropTypes.bool.isRequired,
    updateUserError: PropTypes.instanceOf(Map),
  }

  static defaultProps = {
    updateUserError: Map(),
    user: new User(),
  }

  constructor(props) {
    super(props)

    this.state = {
      name: props.user.fullName || '',
      email: props.user.email || '',
      cpf: props.user.cpf || '',
      emailError: false,
      cpfError: false,
    }
  }

  componentDidUpdate(prevProps) {
    const { isUpdatingUser, updateUserError, user, location } = this.props

    if (prevProps.isUpdatingUser && !isUpdatingUser && !updateUserError.size) {
      registerEvent({ Nome: user.fullName, Email: user.email, Id: user.id })

      navigate(location.state.redirectTo)
    }
  }

  onNameChange = event => {
    const { value } = event.target
    this.setState({
      name: value,
    })
  }

  onEmailChange = event => {
    const { value } = event.target
    this.setState({
      email: value,
    })
  }

  onCPFChange = event => {
    const { value } = event.target
    this.setState({
      cpf: value,
    })
  }

  onContinueClick = () => {
    const { name, email, cpf } = this.state
    this.props.updateUser({
      fullName: name,
      email,
      cpf,
    })
  }

  validateEmail = () => {
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const emailToCheck = this.state.email ? this.state.email : ''
    if (this.state.email) {
      this.setState({
        emailError: !emailRegex.test(emailToCheck),
      })
    } else {
      this.setState({
        emailError: false,
      })
    }
  }

  validateCPF = () => {
    const { cpf } = this.state

    if (this.state.cpf) {
      this.setState({
        cpfError: !validationCPF(cpf),
      })
    } else {
      this.setState({
        cpfError: false,
      })
    }
  }

  render() {
    const { user, location } = this.props

    const hasRedirectTo = location.state && location.state.redirectTo

    if (!user.authToken || !hasRedirectTo) {
      return <Redirect to="/" noThrow />
    }

    const { name, email, cpf, emailError, cpfError } = this.state
    const { isUpdatingUser } = this.props

    return (
      <div className={styles.main}>
        <div className={styles.card}>
          <svg aria-hidden="true" className={styles.icon} style={fillColorStyle(primaryColor)}>
            <use xlinkHref={ConfirmationIcon} />
          </svg>
          <h3>Número Confirmado!</h3>
          <p className={styles.subtitle}>Estamos quase terminando...</p>
          <p className={styles.info}>
            Para finalizar o seu cadastro, precisamos de mais alguns dados.
          </p>
          <Input
            className={styles.input}
            label="Nome completo"
            value={name}
            onChange={this.onNameChange}
            labelClassName={styles.label}
            ariaLabel="Entrada de texto para nome Completo"
            primaryColor={primaryColor}
          />
          <Input
            className={styles.input}
            label="E-mail"
            value={email}
            onChange={this.onEmailChange}
            type="email"
            onBlur={this.validateEmail}
            error={emailError}
            disabled={!name}
            labelClassName={styles.label}
            ariaLabel="Entrada de texto para e-mail"
            primaryColor={primaryColor}
          />
          <Input
            className={styles.input}
            label="CPF"
            value={cpf}
            onChange={this.onCPFChange}
            type="tel"
            mask="999.999.999-99"
            onBlur={this.validateCPF}
            error={cpfError}
            disabled={!name || !email}
            labelClassName={styles.label}
            ariaLabel="Entrada de texto para cpf"
            primaryColor={primaryColor}
          />
          <Button
            color={ButtonColor.PRIMARY}
            format={ButtonFormat.ROUNDED}
            onClick={this.onContinueClick}
            className={styles.button}
            isLoading={isUpdatingUser}
            disabled={!(name && email && cpf && !emailError && !cpfError)}
          >
            CONTINUAR
          </Button>
          <p className={styles.terms}>
            Ao confirmar você concorda com os
            <a
              href="/termos-de-servico"
              target="_blank"
              onClick={onTermsClick}
              style={fontColorStyle(primaryColor)}
            >
              Termos de serviço
            </a>{' '}
            e{' '}
            <a
              href="/politica-de-privacidade"
              target="_blank"
              onClick={onPrivacyClick}
              style={fontColorStyle(primaryColor)}
            >
              Política de Privacidade
            </a>
          </p>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(SignUp))

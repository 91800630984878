import React, { useMemo, Fragment, useEffect, useCallback, useState } from 'react'
import { Grid, Typography, SvgIcon, Paper, Tooltip } from '@material-ui/core'
import classnames from 'classnames'
import { useParams, navigate, useLocation } from '@reach/router'
import { useDispatch, useSelector } from 'react-redux'
import { Map } from 'immutable'
import qs from 'qs'

import { getUser } from '_modules/user/actions'
import { toCurrency, getExternalId } from '_utils/helpers'
import { getPropertyType, createSearchQuery } from '_utils/property'
import { getLeaseById } from '_modules/leases/selectors'
import SimpleCard from '_components/card/simple-card'
import { getLease } from '_modules/leases/actions'
import { getBookingById } from '_modules/booking/selectors'
import ExternalIdButton from '_components/material/button/external-id-button'
import withAuth from '_hocs/with-auth'
import HouseIcon from '_assets/images/feedback-header.svg'
import { usePrevious } from '_utils/hooks'
import useModal from '_hooks/use-modal'
import FloatingFeedbackButton from '_views/schedule/step-five/floating-feedback-button'
import VisitFeedbackModal from '_views/visit/visit-feedback-modal'
import Snackbar from '_components/snackbar'
import { getUserInfoSelector } from '_modules/user/selectors'
import ModalNegotiation from '_components/modal-negotiation'
import ModalReserved from '_components/modal-reserved'
import Loading from '_components/loading'
import { getBooking } from '_modules/booking/actions'
import RoundPhoneIcon from '_assets/images/round-phone.svg'
import { FEATURE_FLAGS } from '_utils/constants'

import useStyles from './styles'

const StartNegotiation = () => {
  const styles = useStyles()
  const { id: leaseId } = useParams()
  const { search } = useLocation()
  const dispatch = useDispatch()

  const user = useSelector(getUserInfoSelector)
  const lease = useSelector(state => getLeaseById(state, leaseId))

  const [isSnackBarOpen, setSnackbar] = useState(false)
  const { modalOpen: isCloseDealModalOpen, handleModal: handleCloseDealModal } = useModal()
  const { modalOpen: isReservedModalOpen, handleModal: handleReservedModal } = useModal()
  const { modalOpen, handleModal } = useModal(search.includes('feedback'))
  const wasFeedbackModalOpen = usePrevious(modalOpen)

  const listingType = lease && lease.listing && getPropertyType(lease.listing.get('listingType'))
  const externalId =
    lease &&
    lease.listing &&
    lease.listing.get('externalId') &&
    getExternalId(lease.listing.get('externalId'))
  const bookingId = useMemo(() => {
    if (lease && lease.bookings) {
      if (lease.bookings.size > 1) {
        return lease.bookings.find(booking => booking.get('active')).get('id')
      }
      if (lease.bookings.size === 1) {
        return lease.bookings.first().get('id')
      }
    }
    return false
  }, [lease])
  const booking = useSelector(state => getBookingById(state, bookingId))
  const hasFeedback =
    booking &&
    ((booking.feedback && booking.feedback.size) || booking.staffRating || !booking.unaccompanied)
  const parsedQuery = useMemo(() => qs.parse((search || '').split('?').pop()), [search])

  const isCommercialLease = useMemo(() => {
    if (lease.id) {
      const listing = lease.get('listing').toJS()
      return listing.listingType.includes('com_')
    }
    return false
  }, [lease])

  useEffect(() => {
    if (leaseId) {
      dispatch(getLease(leaseId))
    }
  }, [dispatch, leaseId])

  useEffect(() => {
    if (bookingId) {
      dispatch(getBooking(bookingId))
    }
  }, [bookingId, dispatch])

  useEffect(() => {
    if (!user) {
      dispatch(getUser())
    }
  }, [dispatch, user])

  const onConfirmNegotiation = useCallback(() => {
    const reserved = lease && lease.getIn(['listing', 'reserved'])

    if (reserved) {
      handleReservedModal()
      return
    }
    if (user.activeLease && !isCloseDealModalOpen) {
      handleCloseDealModal()
      return
    }

    handleCloseDealModal()

    navigate(`/minhas-negociacoes/${leaseId}/apresentacao`, {
      state: { listing: lease.get('listing').toJS() },
    })
  }, [
    handleCloseDealModal,
    handleReservedModal,
    leaseId,
    isCloseDealModalOpen,
    lease,
    user.activeLease,
  ])

  const propertyDetails = useMemo(() => {
    const listing = lease ? lease.listing : Map()
    return [
      {
        name: listingType,
        value: externalId,
        isBold: true,
      },
      {
        name: 'Aluguel',
        value: toCurrency(listing.get('rentPrice')),
        isBold: true,
      },
      {
        name: 'Condomínio¹',
        value: toCurrency(listing.get('condominiumFee')),
        isBold: false,
      },
      {
        name: 'IPTU',
        value: toCurrency(listing.get('taxes')),
        isBold: false,
      },
      {
        name: FEATURE_FLAGS.fireInsuranceEnabled ? 'Seguro incêndio²' : 'Seguro incêndio',
        value: lease && toCurrency(lease.fireInsurancePrice),
        isBold: false,
      },
    ]
  }, [externalId, lease, listingType])

  const tooltipTextBox = useMemo(() => ({ tooltip: styles.tooltipTextBox }), [
    styles.tooltipTextBox,
  ])

  useEffect(() => {
    if (!modalOpen && wasFeedbackModalOpen && booking.hasReportedFeedback) {
      setSnackbar(true)
    }
  }, [booking.hasReportedFeedback, modalOpen, wasFeedbackModalOpen])

  if (!lease) {
    return <Loading />
  }

  return (
    <Fragment>
      <Grid component="section" className={styles.main}>
        <Grid className={styles.content}>
          <ExternalIdButton
            listingType={lease.listing.get('listingType')}
            externalId={externalId}
          />
          {!isCommercialLease ? (
            <Grid>
              <SvgIcon aria-hidden="true" className={styles.houseIcon} viewBox={HouseIcon.viewBox}>
                <use xlinkHref={HouseIcon} />
              </SvgIcon>
              <Grid className={styles.visitRealized}>
                <Typography
                  component="h1"
                  variant="h3"
                  className={classnames(styles.title, styles.mainTitle)}
                >
                  Falta pouco para você alugar o seu imóvel!
                </Typography>
              </Grid>
              <Grid className={styles.toDoContainer}>
                <Typography component="h2" variant="h4" className={styles.title}>
                  O que você deseja fazer?
                </Typography>
                <SimpleCard
                  title="Proposta"
                  description="Quero solicitar uma alteração no valor do aluguel ou alguma modificação no imóvel."
                  buttonLabel="Fazer proposta"
                  to={`/proposta/apresentacao/${leaseId}`}
                />
                <SimpleCard
                  title="Análise de crédito"
                  description="Está tudo certo com o valor e o imóvel, desejo enviar meus documentos e seguir em frente com a negociação."
                  buttonLabel="Iniciar a análise de crédito"
                  onClick={onConfirmNegotiation}
                />
                <Typography component="p" variant="subtitle1" className={styles.text}>
                  Observação: ao iniciar a análise de crédito você não poderá mais fazer propostas.
                  Caso queira solicitar alguma alteração, faça uma proposta.
                </Typography>
              </Grid>
              <Typography
                component="h2"
                variant="h4"
                color="secondary"
                className={styles.propertyContainerTitle}
              >
                Sobre o imóvel
              </Typography>
              <Paper component="section" elevation={0} className={styles.propertyPaper}>
                <Grid className={styles.propertyContent}>
                  {propertyDetails.map(row => (
                    <Grid key={row.name} className={styles.row}>
                      <Typography
                        component="p"
                        variant="subtitle1"
                        color="secondary"
                        className={classnames({ [styles.bold]: row.isBold })}
                      >
                        {row.name}&nbsp;
                      </Typography>
                      <Typography
                        component="p"
                        variant="subtitle1"
                        color="secondary"
                        className={classnames({ [styles.bold]: row.isBold })}
                      >
                        {row.value}
                      </Typography>
                    </Grid>
                  ))}
                  <Typography component="p" variant="subtitle2" className={styles.text}>
                    ¹O valor do condomínio pode variar.
                  </Typography>
                  {FEATURE_FLAGS.fireInsuranceEnabled && (
                    <Fragment>
                      <Tooltip
                        title="Em nosso contrato é expressa a obrigatoriedade da contratação de seguro incêndio pelo inquilino. A Porto Seguro disponibiliza a melhor experiência e qualidade 100% digital."
                        className={styles.tooltip}
                        classes={tooltipTextBox}
                        placement="bottom"
                      >
                        <Typography
                          component="p"
                          variant="subtitle2"
                          className={styles.tooltipText}
                        >
                          i
                        </Typography>
                      </Tooltip>
                      <Typography component="p" variant="subtitle2" className={styles.text}>
                        ²Valor do seguro oferecido pela <b> Porto Seguro</b>.
                      </Typography>
                    </Fragment>
                  )}
                  <Grid className={classnames(styles.row, styles.bold, styles.totalContainer)}>
                    <Typography
                      component="p"
                      variant="subtitle1"
                      color="secondary"
                      className={styles.bold}
                    >
                      Total
                    </Typography>
                    <Typography
                      component="p"
                      variant="subtitle1"
                      color="secondary"
                      className={styles.bold}
                    >
                      {toCurrency(lease.totalPrice)}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
              {isCloseDealModalOpen && (
                <ModalNegotiation
                  open={isCloseDealModalOpen}
                  onClose={handleCloseDealModal}
                  onConfirmNegotiation={onConfirmNegotiation}
                />
              )}
              {isReservedModalOpen && (
                <ModalReserved openModal={isReservedModalOpen} closeModal={handleReservedModal} />
              )}
              {bookingId && !modalOpen && !hasFeedback && (
                <FloatingFeedbackButton onClick={handleModal} label="Avaliar a visita" />
              )}
              <Snackbar
                variant="default"
                message="Feedback enviado"
                isLocalDispatch={isSnackBarOpen}
                snackbarClassName={styles.snackbar}
              />
            </Grid>
          ) : (
            <Grid>
              <SvgIcon
                aria-hidden="true"
                className={styles.phoneIcon}
                viewBox={RoundPhoneIcon.viewBox}
              >
                <use xlinkHref={RoundPhoneIcon} />
              </SvgIcon>
              <Typography
                component="h1"
                variant="h3"
                className={classnames(styles.title, styles.mainTitle)}
              >
                Falta pouco para você alugar este imóvel!
              </Typography>
              <Typography component="p" className={styles.textMiddle}>
                Um consultor entrará em contato para informar os próximos passos da locação.
              </Typography>
            </Grid>
          )}
        </Grid>
        {isCloseDealModalOpen && (
          <ModalNegotiation
            isOpen={isCloseDealModalOpen}
            onClose={handleCloseDealModal}
            onConfirmNegotiation={onConfirmNegotiation}
          />
        )}
        {isReservedModalOpen && (
          <ModalReserved openModal={isReservedModalOpen} closeModal={handleReservedModal} />
        )}
        {bookingId && !modalOpen && !hasFeedback && (
          <FloatingFeedbackButton onClick={handleModal} label="Avaliar a visita" />
        )}
        {bookingId && modalOpen && (
          <VisitFeedbackModal
            bookingId={bookingId}
            feedbackId={booking && booking.feedbackId}
            onClose={handleModal}
            isNegotiationFlow
            userAction={parsedQuery.feedback}
            exitRoute={createSearchQuery(lease.listing)}
          />
        )}
      </Grid>
    </Fragment>
  )
}

export default withAuth(StartNegotiation)

import React, { PureComponent, createRef } from 'react'

import CheckListIcon from '_components/icon-components/check-list-icon'
import TermsAndPrivacy from '_components/terms-and-privacy'
import { formatDate } from '_utils/strings'
import { GENERAL } from '_utils/constants'

import {
  GENERAL_INFORMATION,
  GATHERING_INFORMATION,
  USE_OF_INFORMATION,
  SECURITY,
  EMAIL_LIST,
  getOthers,
} from './constants'
import styles from './styles.css'

class PrivacyPolicy extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      isStickyHidden: false,
    }

    this.generalInfoRef = createRef()
    this.gatherInfoRef = createRef()
    this.useOfInfoRef = createRef()
    this.securityRef = createRef()
    this.emailListRef = createRef()
    this.othersRef = createRef()
  }

  hideSticky = () => {
    this.setState({ isStickyHidden: true })
  }

  showSticky = () => {
    this.setState({ isStickyHidden: false })
  }

  renderListItems = listItems => (
    <div>
      {listItems.map(listItem => (
        <div className={styles['list-item']} key={`${this.uuid}-${listItem}`}>
          <CheckListIcon className={styles['list-icon']} />
          <p className={styles['list-text']}>{listItem}</p>
        </div>
      ))}
    </div>
  )

  render() {
    const { isStickyHidden } = this.state
    const navigationItems = [
      ['Informações gerais', this.generalInfoRef],
      ['Levantamento de informações', this.gatherInfoRef],
      ['Uso e compartilhamento das informações', this.useOfInfoRef],
      ['Segurança', this.securityRef],
      ['Listas de e-mail e como se desinscrever', this.emailListRef],
      ['Outras provisões', this.othersRef],
    ]
    const sectionsArray = [
      [
        'Informações gerais',
        false,
        GENERAL_INFORMATION(GENERAL.realEstateAgency),
        this.generalInfoRef,
      ],
      [
        'Levantamento de informações',
        false,
        GATHERING_INFORMATION(GENERAL.realEstateAgency),
        this.gatherInfoRef,
      ],
      [
        'Uso e compartilhamento das informações',
        false,
        USE_OF_INFORMATION(GENERAL.realEstateAgency),
        this.useOfInfoRef,
      ],
      ['Segurança', false, SECURITY, this.securityRef],
      ['Listas de e-mail e como se desinscrever', false, EMAIL_LIST, this.emailListRef],
      [
        'Outras provisões',
        false,
        getOthers(this.hideSticky, this.showSticky, GENERAL.realEstateAgency, GENERAL.email),
        this.othersRef,
      ],
    ]

    return (
      <TermsAndPrivacy
        navigationItems={navigationItems}
        title="Política de Privacidade"
        update={`Última atualização em ${formatDate(GENERAL.privacyPolicyUpdateDate)}.`}
        description={
          <div className={styles.description}>
            <p className={styles.subtitle}>O que você encontrará nessa política de privacidade?</p>
            {this.renderListItems([
              'Como coletamos informações sobre você.',
              'Como usamos ou compartilhamos informações sobre você.',
              'Como protegemos as informações sobre você.',
              'Outros assuntos relacionados às informações que coletamos sobre você.',
            ])}
          </div>
        }
        sectionsArray={sectionsArray}
        isStickyHidden={isStickyHidden}
        view="Política de Privacidade"
      />
    )
  }
}

export default PrivacyPolicy

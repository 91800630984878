import React, { Fragment, useMemo } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Grid, Typography, IconButton } from '@material-ui/core'
import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'

import { PARTICIPANTS_TYPE } from '_utils/constants'
import { phoneNumberMask } from '_utils/strings'
import StatusChip from '_components/status-chip'

import useStyles from './styles'

const PARTICIPANTS_LABEL = {
  [PARTICIPANTS_TYPE.INCOME_HELPER]: 'Responsável pelo pagamento',
  [PARTICIPANTS_TYPE.TENANT]: 'Morador',
}

const ParticipantCard = ({
  fullName,
  type,
  email,
  phoneNumber,
  statusOption,
  className,
  currentUser,
  id,
  onDisableClick,
  onEditClick,
}) => {
  const styles = useStyles()

  const renderButtons = useMemo(
    () => (
      <Fragment>
        <IconButton
          name={id}
          aria-label="Editar Informações"
          onClick={onEditClick}
          className={styles.iconButton}
        >
          <EditIcon className={styles.icon} />
        </IconButton>
        <IconButton
          name={id}
          aria-label="Deletar Participante"
          onClick={onDisableClick}
          className={styles.iconButton}
        >
          <DeleteIcon className={styles.icon} />
        </IconButton>
      </Fragment>
    ),
    [id, onDisableClick, onEditClick, styles.icon, styles.iconButton]
  )

  return (
    <Grid className={classnames(styles.wrapper, className)}>
      <Grid container spacing={2} justify="space-between">
        <Grid item className={styles.content}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5" component="p" color="textPrimary">
                {fullName}
              </Typography>
              <Typography variant="h6" component="p" color="textSecondary">
                {PARTICIPANTS_LABEL[type]} {currentUser && '(Você)'}
              </Typography>
              <Typography variant="subtitle1" component="p" color="textSecondary">
                {email} | {phoneNumberMask(phoneNumber)}
              </Typography>
            </Grid>
            <Grid item xs={12} className={styles.status}>
              <StatusChip option={statusOption} />
              {!currentUser && <div className={styles.mobile}>{renderButtons}</div>}
            </Grid>
          </Grid>
        </Grid>
        {!currentUser && (
          <Grid item className={styles.desktop}>
            {renderButtons}
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

ParticipantCard.propTypes = {
  currentUser: PropTypes.bool,
  fullName: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  type: PropTypes.string,
  email: PropTypes.string,
  phoneNumber: PropTypes.string,
  className: PropTypes.string,
  statusOption: PropTypes.string,
  onDisableClick: PropTypes.func,
  onEditClick: PropTypes.func,
}

ParticipantCard.defaultProps = {
  currentUser: false,
  fullName: '',
  type: PARTICIPANTS_TYPE.INCOME_HELPER,
  email: '',
  phoneNumber: '',
  className: '',
  statusOption: '',
  id: '',
  onDisableClick: () => {},
  onEditClick: () => {},
}

export default React.memo(ParticipantCard)

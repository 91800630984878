import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ breakpoints, palette }) => ({
  main: {
    color: palette.text.primary,
    width: ' 100%',
    height: ' calc(100% - 5.6rem)',
    display: ' grid',
    gridTemplate: '7.2rem 1fr 10.4rem / 1fr minmax(25rem, 51.2rem) 1fr',
    gridTemplateAreas: '". . ." ". content . " ". . ."',
    [breakpoints.down('md')]: {
      gridTemplate:
        '2.4rem 1fr 7.4rem / minmax(3.4rem, 1fr) minmax(25.2rem, 36rem) minmax(3.4rem, 1fr)',
      gridTemplateAreas: '". . ." ". content . " ". . ."',
    },
  },
  content: {
    gridArea: 'content',
    display: 'flex',
    flexDirection: 'column',
  },
  externalId: {
    margin: '0 0 2.4rem',
  },
  card: {
    marginBottom: '2.4rem',
  },
  cardProperty: {
    padding: '0.7rem 1.6rem',
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cardActions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '0.7rem 2rem',
    borderTop: `0.1rem solid ${palette.divider}`,
  },
  cardActionsButtons: {
    [breakpoints.down('md')]: {
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
  title: {
    marginBottom: '1rem',
  },
  description: {
    [breakpoints.down('md')]: {
      display: 'none',
    },
  },
  link: {
    color: palette.info.main,
    textDecoration: 'underline',
  },
  button: {
    fontSize: '1.2rem',
    color: palette.text.primary,
  },
  buttonContinue: {
    marginTop: '2.4rem',
    height: '4rem',
  },
  icon: {
    marginRight: '1.8rem',
    width: '2rem',
    height: '2.2rem',
  },
  visitRequestNotify: {
    padding: '1.2rem',
    marginBottom: '1rem',
    fontWeight: '500',
    maxWidth: '42.5rem',
    textAlign: 'left',
    backgroundColor: '#eceef8',
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    color: 'var(lightblue)',
    borderCollapse: 'separate',
    borderRadius: '0.4rem',
  },
  alarmIcon: {
    color: '#3f51b5',
  },
  visitRequestText: {
    color: '#3f51b5',
  },
}))

import React, { useMemo } from 'react'
// import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import Shiitake from 'shiitake'
import classnames from 'classnames'

// import { unLikeProperty, UNLIKE_PROPERTY } from '_modules/property/actions'
import RentDetail from '_components/rent-detail'
import LocationIcon from '_assets/images/menu-location.svg'
import HouseIcon from '_assets/images/floor-icon.svg'
// import RemoveIcon from '_assets/images/trash-alt-solid.svg'
import { getPropertyDefaultTitle, getPropertyDefaultAddress } from '_utils/property'

import styles from './styles.css'

const FavoriteCard = ({ className, property, primaryColor, isSmall }) => {
  // const dispatch = useDispatch()
  const createSvgStyle = useMemo(
    () => ({
      fill: primaryColor,
    }),
    [primaryColor]
  )
  // const loading = useSelector(state => !!state.loading.get(UNLIKE_PROPERTY.ACTION))

  const picture = useMemo(() => {
    if (property && property.convertedPictures[0]) {
      return property.convertedPictures[0].thumbnail
    }
    return null
  }, [property])

  // const onClickRemove = useCallback(
  //   () => {
  //     dispatch(unLikeProperty(property.externalId))
  //   },
  //   [dispatch, property.externalId]
  // )

  const { rentPrice, condominiumFee, taxes, insurance, totalPrice } = property

  return (
    <div
      className={classnames(className, styles.card, styles.default, {
        [styles.smaller]: isSmall,
      })}
    >
      <img src={picture} alt="imagem do imóvel" />
      <div className={styles.info}>
        <div className={styles['property-details']}>
          <div className={styles['title-container']}>
            <svg aria-hidden="true" style={createSvgStyle} className={styles['house-icon']}>
              <use xlinkHref={HouseIcon} />
            </svg>
            <Shiitake className={styles.title} lines={2} tagName="p">
              {getPropertyDefaultTitle(property)}
            </Shiitake>
          </div>
          <div className={styles.divider} />
          <div className={styles['location-container']}>
            <svg aria-hidden="true" style={createSvgStyle} className={styles.icon}>
              <use xlinkHref={LocationIcon} />
            </svg>
            <Shiitake className={styles.address} lines={2} tagName="p">
              {getPropertyDefaultAddress(property)}
            </Shiitake>
          </div>
        </div>
        {/* TODO: Uncomment after refactor the design */}
        {/* <div className={styles['buttons-container']}>
          <button
            onClick={onClickRemove}
            disabled={loading}
            aria-label="Botão remover dos favoritos"
            className={styles['remove-button']}
          >
            <svg aria-hidden="true">
              <use xlinkHref={RemoveIcon} />
            </svg>
          </button>
        </div> */}
      </div>
      <RentDetail
        rentPrice={rentPrice}
        condominium={condominiumFee}
        fee={taxes}
        insurance={insurance}
        totalValue={totalPrice}
        className={styles['rent-detail']}
      />
    </div>
  )
}

FavoriteCard.propTypes = {
  className: PropTypes.string,
  property: PropTypes.shape({
    externalId: PropTypes.string,
    rentPrice: PropTypes.number,
    condominiumFee: PropTypes.number,
    taxes: PropTypes.number,
    insurance: PropTypes.number,
    totalPrice: PropTypes.number,
    convertedPictures: PropTypes.arrayOf(),
  }),
  primaryColor: PropTypes.string.isRequired,
  isSmall: PropTypes.bool,
}

FavoriteCard.defaultProps = {
  className: '',
  property: {},
  isSmall: true,
}

export default React.memo(FavoriteCard)

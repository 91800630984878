import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ palette }) => ({
  container: {
    '& .MuiOutlinedInput-root': {
      height: props => `${props.height}rem`,
    },
    '& .MuiFormHelperText-root': {
      fontSize: '1.2rem',
    },
    '& input': {
      height: props => `${props.height}rem`,
      color: palette.text.secondary,
      padding: '0 1.4rem',
    },
    '& label': {
      fontSize: '1.4rem',
      height: props => `${props.height}rem`,
      top: '-0.2rem',
    },
    "& label[data-shrink='true']": {
      top: 0,
    },
    '& .MuiInputBase-input.Mui-disabled': {
      color: palette.gray.main,
    },
    '& .MuiFormLabel-root.Mui-disabled': {
      color: palette.gray.main,
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(1.4rem, -0.6rem) scale(0.857)',
    },
    '& legend': {
      fontSize: '1.2rem',
    },
  },
  endAdornment: {
    color: palette.text.primary,
    fontSize: '1.4rem',
    fontWeight: 500,
    lineHeight: '2.4rem',
  },
}))

import React, { useMemo, useCallback, useState, useEffect } from 'react'
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { navigate } from '@reach/router'

import withAuthOrIncomeHelper from '_hocs/with-auth-or-income-helper'
import Button, { ButtonVariant, ButtonColor, ButtonFormat } from '_components/button'
import MoneyIcon from '_assets/images/ic-money.svg'
import { sendParticipantsData } from '_modules/leases/actions'
import { checkIsSendingParticipantsData } from '_modules/leases/selectors'
import { usePrevious } from '_utils/hooks'

import styles from './styles.css'
import useStyles from './styles'

const WarrantyMonthlyIncome = ({ location, id }) => {
  const classes = useStyles()
  const [monthlyIncome, setMonthlyIncome] = useState()
  const dispatch = useDispatch()
  const isLoading = useSelector(checkIsSendingParticipantsData)
  const wasLoading = usePrevious(isLoading)

  const token = useMemo(
    () =>
      location.search.includes('?token=') || location.search.includes('&token=')
        ? location.search.match(/[?&]token=([^&#]*)/)[1]
        : undefined,
    [location.search]
  )

  const isButtonDisabled = useMemo(() => monthlyIncome === undefined, [monthlyIncome])

  const inputProps = useMemo(
    () => ({
      className: classes.input,
    }),
    [classes.input]
  )

  const handleMontlyIncome = useCallback((_, value) => {
    setMonthlyIncome(value)
  }, [])

  const onSendIncomeClick = useCallback(() => {
    dispatch(
      sendParticipantsData({
        leaseId: id,
        token,
        monthlyIncome,
      })
    )
  }, [dispatch, id, monthlyIncome, token])
  useEffect(() => {
    if (!isLoading && wasLoading) {
      navigate(`/analise-credito/${id}/registrar-documentos-participante/?token=${token}`)
    }
  }, [id, isLoading, token, wasLoading])

  return (
    <section className={styles.container}>
      <h1 className={styles.title}>Análise de crédito</h1>
      <section className={styles['section-one']}>
        <svg aria-hidden="true" className={styles.icon}>
          <use xlinkHref={MoneyIcon} />
        </svg>
        <h1 className={styles['title-section-one']}>Comprovação de renda</h1>
        <h2 className={styles['description-section-one']}>
          Informe a sua renda para continuar o processo de locação.
        </h2>
      </section>
      <section className={styles['section-two']}>
        <h1 className={styles['title-section-two']}>Informe a sua renda mensal liquida</h1>
        <CurrencyTextField
          variant="outlined"
          value={monthlyIncome}
          currencySymbol="R$"
          outputFormat="number"
          type="tel"
          onChange={handleMontlyIncome}
          InputProps={inputProps}
          fullWidth
          className={classnames(classes.textField, styles.adornment)}
          decimalCharacter="."
          digitGroupSeparator=","
          id="monthly-income"
          placeholder="Digite um valor"
        />
      </section>
      <Button
        color={ButtonColor.PRIMARY}
        variant={ButtonVariant.BOLD}
        format={ButtonFormat.ROUNDED}
        className={styles.action}
        aria-label="Ir para minhas negociações"
        onClick={onSendIncomeClick}
        isLoading={isLoading}
        disabled={isButtonDisabled}
      >
        PRÓXIMO
      </Button>
    </section>
  )
}

WarrantyMonthlyIncome.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  id: PropTypes.string.isRequired,
}

export default withAuthOrIncomeHelper(React.memo(WarrantyMonthlyIncome))

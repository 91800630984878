import humps from 'humps'

import { defineAction } from '_utils/redux'
import * as bookingService from '_services/bookings'

export const GET_BOOKINGS = defineAction('GET_BOOKINGS')
export const GET_BOOKING = defineAction('GET_BOOKING')
export const CANCEL_BOOKING = defineAction('CANCEL_BOOKING')
export const UPDATE_BOOKING = defineAction('UPDATE_BOOKING')
export const CREATE_LEAD = defineAction('CREATE_LEAD')
export const SEND_BOOKING_FEEDBACK = defineAction('SEND_BOOKING_FEEDBACK')
export const UPDATE_BOOKING_FEEDBACK = defineAction('UPDATE_BOOKING_FEEDBACK')
export const GIVE_UP_LISTING = defineAction('GIVE_UP_LISTING')
export const UPDATE_OCCURRENCE = defineAction('UPDATE_OCCURRENCE')

const camelizeResponse = response => humps.camelizeKeys(response)

export const getBookings = () => (dispatch, getState) =>
  dispatch({
    type: GET_BOOKINGS.ACTION,
    payload: bookingService.getBookings(getState().user.authToken).then(camelizeResponse),
    meta: { loadMore: false },
  })

export const getBooking = bookingId => (dispatch, getState) =>
  dispatch({
    type: GET_BOOKING.ACTION,
    payload: bookingService
      .getBooking(getState().user.authToken)(bookingId)
      .then(camelizeResponse),
  })

export const cancelBooking = bookingId => (dispatch, getState) =>
  dispatch({
    type: CANCEL_BOOKING.ACTION,
    payload: bookingService
      .cancelBooking(getState().user.authToken)(bookingId)
      .then(camelizeResponse),
    meta: {
      bookingId,
    },
  })

export const updateBooking = (payload, bookingId) => (dispatch, getState) =>
  dispatch({
    type: UPDATE_BOOKING.ACTION,
    payload: bookingService
      .updateBooking(getState().user.authToken)(payload, bookingId)
      .then(camelizeResponse),
  })

export const sendBookingFeedback = (payload, bookingId) => (dispatch, getState) =>
  dispatch({
    type: SEND_BOOKING_FEEDBACK.ACTION,
    payload: bookingService
      .sendBookingFeedback(getState().user.authToken)(payload, bookingId)
      .then(camelizeResponse),
  })

export const updateBookingFeedback = (payload, bookingId, feedbackId) => (dispatch, getState) =>
  dispatch({
    type: UPDATE_BOOKING_FEEDBACK.ACTION,
    payload: bookingService
      .updateBookingFeedback(getState().user.authToken)(payload, bookingId, feedbackId)
      .then(camelizeResponse),
  })

export const giveUpListing = (payload, bookingId) => (dispatch, getState) =>
  dispatch({
    type: GIVE_UP_LISTING.ACTION,
    payload: bookingService
      .giveUpListing(getState().user.authToken)(payload, bookingId)
      .then(camelizeResponse),
  })

export const updateOccurrence = (payload, bookingId) => (dispatch, getState) =>
  dispatch({
    type: UPDATE_OCCURRENCE.ACTION,
    payload: bookingService
      .updateOccurrence(getState().user.authToken)(payload, bookingId)
      .then(camelizeResponse),
  })

export const createLead = payload => dispatch =>
  dispatch({
    type: CREATE_LEAD.ACTION,
    payload: bookingService.createLead(payload).then(camelizeResponse),
  })

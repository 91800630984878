import { createSelector } from 'reselect'

import { GET_RECOMMENDATIONS_PROPERTIES } from './actions'

const getRecommendationsProperties = state => state.recommendationsProperties
const getRecommendationsResults = state => state.recommendations.results
export const getRecommendations = state => state.recommendations

export const getRecommendationsPropertiesSelector = state =>
  !!state.loading.get(GET_RECOMMENDATIONS_PROPERTIES.ACTION)

export const getDetailedResults = createSelector(
  [getRecommendationsProperties, getRecommendationsResults],
  (properties, results) => results.map(id => properties.get(id))
)

import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ palette, breakpoints }) => ({
  choices: {
    '& .MuiSvgIcon-root': {
      width: '2.28rem',
      height: '1.8rem',
    },
  },
  choicesCard: {
    width: '33.6rem',
    minHeight: '4rem',
    margin: '1.2rem 0 0.2rem',
    padding: '0 1.5rem',

    [breakpoints.down('sm')]: {
      width: '31.6rem',
    },
  },
  selected: {
    border: `0.1rem solid ${palette.primary.main}`,
  },
  choicesCardObs: {
    color: palette.text.primary,
    fontWeight: '500',
  },
}))

import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ breakpoints, palette }) => ({
  button: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: palette.gray.gallery,
    width: 'fit-content',
    borderRadius: '0.4rem',
    margin: '0.8rem 0',
    color: palette.secondary.main,
  },
  icon: {
    [breakpoints.down('sm')]: {
      display: 'none',
    },
    width: '1.6rem',
    height: '1.6rem',
  },
}))

import React, { useMemo, useCallback, useState } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import moment from 'moment'
import Tooltip from '@material-ui/core/Tooltip'

import IconCheck from '_assets/images/ic-check-circle-full.svg'
import IconClock from '_assets/images/ic-clock-sent.svg'
import IconPen from '_assets/images/ic-pen.svg'
import IconPlus from '_assets/images/ic-plus.svg'
import IconDropdown from '_assets/images/ic-dropdown.svg'
import RowButton, { BUTTON_THEME } from '_components/row-button'
import { toCurrency } from '_utils/helpers'

import ProposalTag, { TAG_STATUS } from '../proposal-tag'

import styles from './styles.css'
import useStyles from './styles'

export const BUTTON_STATUS = {
  EMPTY: 'EMPTY',
  ACTION: 'action',
  SENT: 'sent',
  DROPDOWN: 'dropdown',
  ACCEPT: 'accept',
  NO_BUTTON: 'no_button',
}

const ProposalCard = ({
  title,
  status,
  requests,
  children,
  footer,
  buttonClick,
  actionAnswer,
  actionCounterproposal,
  listing,
  showTooltip,
  fromStaff,
  hasOwnerPrice,
  showInitialTag,
}) => {
  const [expanded, setExpanded] = useState(false)
  const classes = useStyles()

  const tagStatus = useMemo(() => {
    const lastRequest = requests
      .sort((a, b) => moment(a.get('createdAt')).unix() - moment(b.get('createdAt')).unix())
      .last()

    if (showInitialTag) {
      return TAG_STATUS.INITIAL
    }

    if (fromStaff) {
      if (hasOwnerPrice) {
        return TAG_STATUS.APPROVED
      }
      if (lastRequest.get('description')) {
        return TAG_STATUS.APPROVED
      }
      return TAG_STATUS.PENDING
    }
    if (!lastRequest) return TAG_STATUS.INITIAL

    if (!lastRequest.get('isStaffRequest') && lastRequest.get('approved') !== null) {
      if (lastRequest.get('approved')) return TAG_STATUS.APPROVED

      return TAG_STATUS.REJECTED
    }

    if (!lastRequest.get('isStaffRequest') && lastRequest.get('approved') === null) {
      return TAG_STATUS.PENDING
    }

    return TAG_STATUS.INITIAL
  }, [fromStaff, hasOwnerPrice, requests, showInitialTag])

  const onClickDropDown = useCallback(() => {
    setExpanded(prev => !prev)
  }, [])

  const createCounterproposal = useCallback(() => {
    // TODO show modal
    actionCounterproposal()
  }, [actionCounterproposal])
  const renderButton = useMemo(() => {
    if (status === BUTTON_STATUS.NO_BUTTON) {
      return null
    }

    let button

    switch (status) {
      case BUTTON_STATUS.SENT:
        button = { name: 'PROPOSTA ENVIADA', icon: IconClock, onClick: null }
        break
      case BUTTON_STATUS.DROPDOWN:
        button = {
          name: 'RESPONDER PROPOSTA',
          icon: IconDropdown,
          onClick: onClickDropDown,
        }
        break
      case BUTTON_STATUS.ACCEPT:
        button = {
          name: 'PROPOSTA ACEITA',
          theme: BUTTON_THEME.GREEN,
          icon: IconCheck,
          onClick: null,
        }
        break
      case BUTTON_STATUS.EMPTY:
        if (requests.size) {
          button = {
            name: 'ALTERAR PROPOSTA',
            icon: IconPen,
            onClick: buttonClick,
          }
        } else {
          button = {
            name: 'FAZER UMA PROPOSTA',
            icon: IconPlus,
            onClick: buttonClick,
          }
        }
        break
      default:
        button = {
          name: 'NOVA PROPOSTA',
          icon: IconPlus,
          onClick: buttonClick,
        }
        break
    }

    return (
      <RowButton
        button={button}
        name={button.name}
        open={status === BUTTON_STATUS.DROPDOWN ? expanded : false}
        icon={button.icon}
        theme={button.theme}
        onClick={button.onClick}
        iconFirst={status !== BUTTON_STATUS.ACTION && status !== BUTTON_STATUS.DROPDOWN}
      />
    )
  }, [buttonClick, expanded, onClickDropDown, requests, status])

  const renderDropdownContent = useMemo(() => {
    if (status !== BUTTON_STATUS.DROPDOWN) return null
    const buttons = [
      {
        name: 'ACEITAR',
        theme: BUTTON_THEME.GREEN,
        icon: IconCheck,
        onClick: () => actionAnswer(),
      },
      {
        name: 'CONTRAPROPOSTA',
        theme: BUTTON_THEME.DEFAULT,
        icon: IconPen,
        onClick: createCounterproposal,
      },
    ]

    return (
      <div className={classnames(styles.dropdown, { [styles['dropdown-expanded']]: expanded })}>
        {buttons.map(button => (
          <RowButton
            key={button.name}
            name={button.name}
            icon={button.icon}
            onClick={button.onClick}
            theme={button.theme}
            iconFirst
            className={styles.option}
          />
        ))}
      </div>
    )
  }, [actionAnswer, createCounterproposal, expanded, status])

  const tooltipValues = useMemo(() => {
    if (listing) {
      const { rentPrice, condominiumFee, taxes, totalPrice, insurance } = listing
      return (
        <div className={styles['rent-values']}>
          <div className={styles.value}>
            <strong>Aluguel</strong> <strong>{toCurrency(rentPrice)}</strong>
          </div>
          <div className={styles.value}>
            <p>Condomínio</p> <p>{toCurrency(condominiumFee)}</p>
          </div>
          <div className={styles.value}>
            <p>IPTU</p> <p>{toCurrency(taxes)}</p>
          </div>
          <div className={styles.value}>
            <p>Seguro</p> <p>{toCurrency(insurance)}</p>
          </div>
          <span className={styles.line} />
          <div className={styles.value}>
            <p>Total</p> <p>{toCurrency(totalPrice)}</p>
          </div>
        </div>
      )
    }
    return null
  }, [listing])

  const tooltipClasses = useMemo(() => ({ tooltip: classes.customTooltip }), [
    classes.customTooltip,
  ])

  return (
    <section className={styles.card}>
      <div className={styles.container}>
        <ProposalTag status={tagStatus} />
        <div className={styles.content}>
          <div className={styles.title}>
            <h2 className={styles.heading}>{title}</h2>{' '}
            {showTooltip && (
              <Tooltip
                classes={tooltipClasses}
                title={tooltipValues}
                arial-label="Renda não suficiente"
              >
                <p className={styles.tooltip}>!</p>
              </Tooltip>
            )}
          </div>
          {children}
        </div>
      </div>
      {footer}

      {renderButton}
      {renderDropdownContent}
    </section>
  )
}

ProposalCard.propTypes = {
  title: PropTypes.string,
  status: PropTypes.string,
  children: PropTypes.node,
  footer: PropTypes.node,
  buttonClick: PropTypes.func,
  actionAnswer: PropTypes.func,
  actionCounterproposal: PropTypes.func,
  requests: PropTypes.arrayOf(
    PropTypes.shape({
      approved: PropTypes.bool,
    })
  ),
  listing: PropTypes.shape({
    rentPrice: PropTypes.number,
    condominiumFee: PropTypes.number,
    totalPrice: PropTypes.number,
    insurance: PropTypes.number,
    taxes: PropTypes.number,
  }),
  showTooltip: PropTypes.bool,
  fromStaff: PropTypes.bool,
  hasOwnerPrice: PropTypes.bool,
  showInitialTag: PropTypes.bool,
}

ProposalCard.defaultProps = {
  title: 'Proposta de novo valor',
  status: BUTTON_STATUS.ACTION,
  children: null,
  footer: null,
  buttonClick: () => {},
  actionAnswer: () => {},
  actionCounterproposal: () => {},
  requests: [],
  listing: {
    rentPrice: 0,
    condominiumFee: 0,
    totalPrice: 0,
    insurance: 0,
    taxes: 0,
  },
  showTooltip: false,
  fromStaff: false,
  hasOwnerPrice: false,
  showInitialTag: false,
}

export default React.memo(ProposalCard)

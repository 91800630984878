import { useRef, useEffect, useState, useMemo, useCallback, useLayoutEffect } from 'react'
import { useSelector } from 'react-redux'
import { navigate } from '@reach/router'
import { Cookies } from 'react-cookie'

import { TABLET, MOBILE } from '_config/media-queries'
import { HERE_KEY } from '_config/environment'

const cookies = new Cookies()
export const usePrevious = value => {
  const ref = useRef()

  useEffect(() => {
    ref.current = value
  }, [value])

  return ref.current
}

export const useCall = (action, onSuccess, onReject = () => {}) => {
  const isLoading = useSelector(state => state.loading.get(action))
  const wasLoading = usePrevious(isLoading)
  const error = useSelector(state => state.error.get(action, null))

  useEffect(() => {
    if (!isLoading && wasLoading) {
      if (!error) {
        onSuccess()
      } else {
        onReject(error)
      }
    }
  })
  return [isLoading, error]
}

// SRC: https://gist.github.com/gaearon/cb5add26336003ed8c0004c4ba820eae
export const useWindowWidth = () => {
  const isMobile = useSelector(state => state.server.isMobile)
  const serverWidth = isMobile ? 480 : 1440

  const [width, setWidth] = useState(
    typeof window === 'undefined' ? serverWidth : window.innerWidth
  )

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleResize = () => setWidth(window.innerWidth)
      window.addEventListener('resize', handleResize)
      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }
    return () => {}
  })

  return width
}

export const useProtectedRoute = (redirectRoute = null, hasToken) => {
  const user = useSelector(state => state.user)
  const userToken = cookies.get('userToken', { path: '/anunciar-imovel' })

  useEffect(() => {
    if (!user.authToken && !userToken && !hasToken) {
      navigate(redirectRoute || '/')
    }
  }, [hasToken, redirectRoute, user, userToken])
}

export const useWizardForm = (initialPage, maxPage) => {
  const [currentPage, setCurrentPage] = useState(initialPage)

  return {
    currentPage,
    maxPage,
    nextPage: () => {
      if (currentPage < maxPage) {
        setCurrentPage(currentPage + 1)
      }
    },
    prevPage: () => {
      if (currentPage > 0) {
        setCurrentPage(currentPage - 1)
      } else {
        window.history.back()
      }
    },
    setCurrent: page => {
      setCurrentPage(page)
    },
  }
}

export const useCarouselScroll = () => {
  const carouselRef = useRef()
  const [scrollState, setScrollState] = useState(0)
  const [hasScrollLeft, setHasScrollLeft] = useState(false)
  const [hasScrollRight, setHasScrollRight] = useState(false)

  const calculatePixelsToScroll = (width, numChildElements, numElementsToSkip = 3) =>
    (width / numChildElements) * numElementsToSkip

  const scrollRight = useCallback(() => {
    if (carouselRef.current) {
      const { scrollWidth, childElementCount, offsetWidth } = carouselRef.current
      const pixelsToScroll = calculatePixelsToScroll(scrollWidth, childElementCount)

      if (Math.abs(scrollState) + pixelsToScroll > scrollWidth - offsetWidth) {
        setScrollState(offsetWidth - 25 - scrollWidth)
        setTimeout(() => {
          setScrollState(offsetWidth - scrollWidth)
        }, 300)
      } else {
        setScrollState(scrollState - pixelsToScroll)
      }
    }
  }, [scrollState])

  const scrollLeft = useCallback(() => {
    if (carouselRef.current) {
      const { scrollWidth, childElementCount } = carouselRef.current
      const pixelsToScroll = calculatePixelsToScroll(scrollWidth, childElementCount)

      if (Math.abs(scrollState) < pixelsToScroll) {
        setScrollState(25)
        setTimeout(() => {
          setScrollState(0)
        }, 300)
      } else {
        setScrollState(scrollState + pixelsToScroll)
      }
    }
  }, [scrollState])

  const translateStyle = useMemo(
    () => ({
      transform: `translateX(${scrollState}px)`,
    }),
    [scrollState]
  )

  useLayoutEffect(() => {
    if (carouselRef.current) {
      const { offsetWidth, scrollWidth } = carouselRef.current
      setHasScrollLeft(!(scrollState === 0))
      setHasScrollRight(
        !(Math.abs(scrollState) >= Math.abs(offsetWidth - scrollWidth)) &&
          offsetWidth + 25 < scrollWidth
      )
    }
  }, [carouselRef, hasScrollLeft, hasScrollRight, scrollState])

  return {
    translateStyle,
    scrollLeft,
    scrollRight,
    carouselRef,
    hasScrollLeft,
    hasScrollRight,
  }
}
export const useHereMap = (elementID, centerCoordinates) => {
  const [platform, setPlatform] = useState()
  const [map, setMap] = useState()

  // Load HERE map on mount
  useEffect(
    () => {
      if (window && window.H && window.H.service && window.H.Map && window.H.mapevents) {
        const herePlatform = new window.H.service.Platform({
          apikey: HERE_KEY,
        })

        // Initialize map
        const mapOptions = {
          center: centerCoordinates,
          zoom: 14,
        }
        const defaultLayers = herePlatform.createDefaultLayers()

        const hereMap = new window.H.Map(
          document.getElementById(elementID),
          defaultLayers.raster.normal.map,
          {
            ...mapOptions,
            engineType: window.H.map.render.RenderEngine.EngineType.P2D, // Add canvas engine type
          }
        )

        // Initialize controls
        new window.H.mapevents.Behavior(new window.H.mapevents.MapEvents(hereMap)) // eslint-disable-line no-new
        window.H.ui.UI.createDefault(hereMap, defaultLayers, 'pt-BR')

        setPlatform(herePlatform)
        setMap(hereMap)
      }
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  return [platform, map]
}

export const useWindowSize = () => {
  const isClient = typeof window === 'object'

  const getSize = useCallback(
    () => ({
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    }),
    [isClient]
  )

  const [windowSize, setWindowSize] = useState(getSize)

  useEffect(() => {
    if (!isClient) {
      return false
    }

    const handleResize = () => {
      setWindowSize(getSize())
    }

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [getSize, isClient])

  const isMobile = useMemo(() => windowSize.width < MOBILE, [windowSize.width])
  const isDesktop = useMemo(() => windowSize.width >= TABLET, [windowSize.width])
  const isTablet = useMemo(() => !isMobile && !isDesktop, [isDesktop, isMobile])

  return { ...windowSize, isMobile, isDesktop, isTablet }
}

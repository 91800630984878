import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
  button: {
    position: 'fixed',
    left: 56,
    bottom: 104,
    backgroundColor: theme.palette.custom.eerieBlack,
    color: 'white',
    fontSize: '1.4rem',
    fontWeight: '500',
    padding: '1.2rem 1.6rem',
    zIndex: 11, // bigger than footer container
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
    [theme.breakpoints.down('sm')]: {
      left: '50%',
      bottom: 48,
      transform: 'translateX(-50%)',
    },
  },
  icon: {
    width: 16,
    height: 16,
    marginRight: 8,
  },
}))

import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ breakpoints, palette }) => ({
  main: {
    display: 'grid',
    gridTemplateColumns: 'auto 48rem auto',
    gridTemplateAreas: "' . content . '",
    padding: '4rem 0 8rem',
    [breakpoints.down('sm')]: {
      display: 'grid',
      gridTemplateColumns: 'auto 28rem auto',
      gridTemplateAreas: "' . content . '",
      padding: '4rem 0 4.8rem',
    },
  },
  content: {
    display: 'grid',
    gridArea: 'content',
  },

  phoneIcon: {
    width: '20rem',
    height: '20.5rem',
    margin: '0 auto',
    paddingTop: '3.2rem',
    [breakpoints.down('sm')]: {
      maxHeight: '16rem',
    },
  },
  mainTitle: {
    margin: '1.6rem auto',
    [breakpoints.down('sm')]: {
      paddingBottom: '2.4rem',
    },
  },
  title: {
    color: palette.secondary.dark,
    fontSize: '2rem',
    fontWeight: 500,
    lineHeight: '2.4rem',
    textAlign: 'center',
    [breakpoints.down('sm')]: {
      fontSize: '1.6rem',
    },
  },
  text: {
    fontSize: '1.6rem',
    fontWeight: 'normal',
    lineHeight: '2.4rem',
    textAlign: 'center',
    color: palette.secondary.main,
  },
}))

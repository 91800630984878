import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Card, CardActions, CardContent, Typography } from '@material-ui/core'

import Button from '_components/material/button'

import useStyles from './styles'

const SimpleCard = ({ className, title, description, to, onClick, buttonLabel, ...otherProps }) => {
  const styles = useStyles()

  return (
    <Card className={classnames(styles.simpleCard, className)} component="section" {...otherProps}>
      <CardContent className={styles.cardContent}>
        <Typography component="h1" variant="subtitle1" className={styles.title}>
          {title}
        </Typography>
        <Typography
          component="p"
          variant="subtitle1"
          text="secondary"
          className={styles.description}
        >
          {description}
        </Typography>
      </CardContent>
      <CardActions className={styles.cardActions}>
        <Button variant="contained" className={styles.button} to={to} onClick={onClick}>
          {buttonLabel}
        </Button>
      </CardActions>
    </Card>
  )
}

SimpleCard.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  to: PropTypes.string,
  buttonLabel: PropTypes.string.isRequired,
  onClick: PropTypes.func,
}

SimpleCard.defaultProps = {
  className: '',
  title: '',
  description: '',
  to: null,
  onClick: () => {},
}

export default React.memo(SimpleCard)

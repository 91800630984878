import { makeStyles } from '@material-ui/styles'

export default makeStyles(() => ({
  textField: {
    '& .MuiInputBase-root': {
      lineHeight: '1.5',
      minHeight: '110px',
      alignItems: 'baseline',
      maxHeight: '110px',
      height: 'auto',
    },
    '& .MuiOutlinedInput-input': {
      fontSize: '1.2rem',
      maxHeight: '65px',
      color: '#000',
    },
    '& .MuiFormHelperText-root': {
      position: 'absolute',
      fontSize: '1.2rem',
      bottom: '8px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '12px',
    },
  },
  card: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '2.4rem',
    margin: '0 0 3.2rem',
  },
  title: {
    fontWeight: '500',
    marginBottom: '0.8rem',
  },
  coinIcon: {
    width: '4.8rem',
    height: '4.8rem',
  },
}))

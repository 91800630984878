import React, { useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Typography, Grid } from '@material-ui/core'

import { onMouseDown } from '_utils/helpers'
import Button from '_components/material/button'
import FileChip from '_components/file-chip'

import useStyles from './styles'

const UploadFileSection = ({
  documentId,
  sectionName,
  files,
  handleDocument,
  handleDeleteDocument,
  handleDeletePreviousDocument,
  percentage,
  previousFiles,
}) => {
  const styles = useStyles()
  const fileUpload = useRef(null)

  const onFileClick = useCallback(() => {
    fileUpload.current.click()
  }, [])

  const handleFile = useCallback(
    event => {
      event.stopPropagation()
      const newFile = event.target.files[0]
      handleDocument({ id: documentId, file: newFile })
    },
    [documentId, handleDocument]
  )

  return (
    <Grid className={styles.wrapper}>
      <Grid className={styles.titleRow}>
        <Typography variant="h5" component="p">
          {sectionName}
        </Typography>
        <Button
          className={styles.button}
          color="primary"
          onMouseDown={onMouseDown}
          variant="contained"
          size="small"
          onClick={onFileClick}
        >
          Adicionar
        </Button>
      </Grid>
      {((files[documentId] && !!files[documentId].length) ||
        (previousFiles[documentId] && !!previousFiles[documentId].length)) && (
        <Grid className={styles.filesWrapper}>
          {files[documentId] &&
            files[documentId].map(file => (
              <FileChip
                className={styles.file}
                file={file}
                key={file.name}
                onClick={handleDeleteDocument}
                id={documentId}
                percentage={percentage}
              />
            ))}
          {previousFiles[documentId] &&
            previousFiles[documentId].map(file => (
              <FileChip
                className={styles.file}
                file={file}
                key={file.name}
                onClick={handleDeletePreviousDocument}
                id={file.documentCategory}
              />
            ))}
        </Grid>
      )}
      <input
        ref={fileUpload}
        onChange={handleFile}
        id={`file-upload-${sectionName}`}
        className={styles.fileSelector}
        type="file"
        accept="image/*,application/pdf"
        key="input"
      />
    </Grid>
  )
}

UploadFileSection.propTypes = {
  documentId: PropTypes.number.isRequired,
  sectionName: PropTypes.string.isRequired,
  percentage: PropTypes.string,
  handleDocument: PropTypes.func.isRequired,
  handleDeleteDocument: PropTypes.func,
  handleDeletePreviousDocument: PropTypes.func,
  files: PropTypes.shape({
    [PropTypes.number]: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })),
  }),
  previousFiles: PropTypes.shape({
    [PropTypes.number]: PropTypes.arrayOf(PropTypes.shape({ file: PropTypes.string })),
  }),
}

UploadFileSection.defaultProps = {
  files: {},
  previousFiles: {},
  percentage: null,
  handleDeleteDocument: () => {},
  handleDeletePreviousDocument: () => {},
}

export default UploadFileSection

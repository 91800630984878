import React, { useMemo, useContext } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { useLocation } from '@reach/router'
import { useSelector } from 'react-redux'

import MobileClick from '_assets/images/mobile-click-positive.svg'
import Button from '_components/material/button'
import { CREDIT_ANALYSIS_STATUS, LEASE_STATUS } from '_utils/constants'
import TokenContext from '_context/token'
import { getLeaseById } from '_modules/leases/selectors'

import CreditAnalysisWrapper from '..'

import useStyles from './styles'

const CreditAnalysisAvailable = () => {
  const styles = useStyles()
  const { state } = useLocation()
  const { leaseId } = useContext(TokenContext)
  const lease = useSelector(reduxState => getLeaseById(reduxState, leaseId))
  const status = state && state.status
  const contracts = lease.get('contracts') || []
  const info = useMemo(() => {
    const contractId = contracts.length ? contracts[0].id : '-'
    switch (status) {
      case CREDIT_ANALYSIS_STATUS.INITIALLY_APPROVED:
        return {
          title: 'Análise aprovada!',
          description: 'Envie seus dados pessoais para que possamos elaborar seu contrato.',
          buttonLabel: 'Enviar dados',
          link: `/garantia/${leaseId}/registrar-contratante-passo-2`,
        }
      case CREDIT_ANALYSIS_STATUS.APPROVED:
        return {
          title: 'Análise aprovada!',
          description:
            'Agora é só aguardar o seu contrato ficar pronto. Você será notificado para realizar a assinatura.',
        }
      case LEASE_STATUS.CONTRACT_READY:
      case CREDIT_ANALYSIS_STATUS.CONTRACT_READY:
        return {
          title: 'Contrato disponível!',
          description:
            'Sua análise de crédito foi aprovada! Agora você precisa assinar o contrato. Vamos lá?',
          buttonLabel: 'Assinar contrato',
          link: `minhas-negociacoes/${leaseId}/contrato/${contractId}/antecipacao/`,
        }
      default:
        return {
          title: '-',
          description: '-',
        }
    }
  }, [contracts, leaseId, status])

  return (
    <CreditAnalysisWrapper>
      <Grid item>
        <svg aria-hidden="true" className={styles.image}>
          <use xlinkHref={MobileClick} />
        </svg>
      </Grid>
      <Grid item>
        <Typography component="h1" variant="h3" className={styles.title}>
          {info.title}
        </Typography>
      </Grid>
      <Grid item>
        <Typography component="p" variant="h4" className={styles.text}>
          {info.description}
        </Typography>
      </Grid>
      {info.buttonLabel && (
        <Grid item>
          <Button variant="contained" to={info.link}>
            {info.buttonLabel}
          </Button>
        </Grid>
      )}
    </CreditAnalysisWrapper>
  )
}

export default CreditAnalysisAvailable

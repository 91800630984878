import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
  main: {
    display: 'grid',
    gridTemplateColumns: 'auto 42.4rem auto',
    gridTemplateAreas: "' . content . '",
    padding: '4.8rem 0 8rem',
    [theme.breakpoints.down('sm')]: {
      display: 'grid',
      gridTemplateColumns: 'auto 31.2rem auto',
      gridTemplateAreas: "' . content . '",
      padding: '3.2rem 0 4.8rem',
    },
  },
  content: {
    display: 'grid',
    gridArea: 'content',
  },
  houseIcon: {
    width: '24rem',
    height: '14rem',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      width: '18rem',
      height: '11rem',
    },
  },
  toDoContainer: {
    display: 'grid',
    gridRowGap: '1.6rem',
    paddingBottom: '4rem',
  },
  mainTitle: {
    maxWidth: '20rem',
    margin: '0 auto',
    paddingBottom: '4rem',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.6rem',
    },
  },
  title: {
    color: theme.palette.text.secondary,
  },
  text: {
    color: theme.palette.gray.dusty,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem',
    },
  },
  propertyContainerTitle: {
    paddingBottom: '1.6rem',
  },
  propertyPaper: {
    position: 'relative',
    padding: '2.4rem',
    backgroundColor: theme.palette.gray.gallery,
  },
  propertyContent: {
    display: 'grid',
  },
  tooltip: {
    position: 'absolute',
    left: '13.4rem',
    top: '16.2rem',
    width: '2rem',
    height: '2rem',
  },
  tooltipText: {
    padding: '0.2rem',
    borderRadius: '50%',
    backgroundColor: theme.palette.gray.dusty,
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tooltipTextBox: {
    padding: '1.6rem',
    boxSizing: 'border-box',
    borderRadius: '0.4rem',
    backgroundColor: 'black',
    fontSize: '1.4rem',
    lineHeight: 1.8,
    color: 'white',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    marginBottom: '0.5rem',

    '&:first-child': {
      justifyContent: 'flex-start',
      paddingBottom: '2.1rem',
    },
  },
  bold: {
    fontWeight: 'bold',
  },
  totalContainer: {
    borderTop: `0.1rem solid ${theme.palette.gray.default}`,
    marginTop: '1.3rem',
    paddingTop: '1.3rem',
  },
  snackbar: {
    width: '35rem',

    [theme.breakpoints.down('sm')]: {
      width: '95%',
    },
  },
  phoneIcon: {
    margin: '5rem auto',
    width: '18.3rem',
    height: '18.3rem',
    display: 'flex',
  },

  textMiddle: {
    fontSize: '1.6rem',
    lineHeight: '2.4rem',
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}))

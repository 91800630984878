import isPromise from '_utils/is-promise'
import { UNAUTHORIZED } from '_modules/user/actions'

const ERRORS = {
  INVALID_TOKEN: 'Invalid token.',
}

export default function errorMiddleware() {
  return next => action => {
    const result = next(action)

    if (!isPromise(result)) {
      return action
    }

    return result.catch(error => {
      if (error.detail === ERRORS.INVALID_TOKEN) {
        return next({
          type: UNAUTHORIZED,
          error: false,
        })
      }

      return action
    })
  }
}

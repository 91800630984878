import { LEASE_STATUS, WARRANTY_TYPES } from './constants'

export const getLeaseStatus = status => {
  if (
    status === LEASE_STATUS.BOT_WITHOUT_ANSWER ||
    status === LEASE_STATUS.BOT_WITHOUT_BOOKING ||
    status === LEASE_STATUS.BOT_RECOMMENDATIONS_CREATED ||
    status === LEASE_STATUS.BOT_MANUAL_SDR
  ) {
    return LEASE_STATUS.NOT_INITIATED
  }

  return status
}

export const getWarrantyTitle = warrantyType => {
  switch (warrantyType) {
    case WARRANTY_TYPES.CAPITALIZATION:
      return 'Título de Capitalização'
    case WARRANTY_TYPES.RAPID:
      return 'Alugue rápido'
    case WARRANTY_TYPES.OLD_CREDPAGO:
    case WARRANTY_TYPES.CREDPAGO:
      return 'CredPago'
    case WARRANTY_TYPES.INSURANCE:
      return 'Seguro Fiança'
    case WARRANTY_TYPES.GUARANTOR:
      return 'Fiador'
    case WARRANTY_TYPES.STUDENT:
      return 'Estudante'
    case WARRANTY_TYPES.DEPOSIT:
      return 'Caução'
    case WARRANTY_TYPES.VELO:
      return 'Velo'
    case WARRANTY_TYPES.SIGAFY:
      return 'Seguro Fiança'
    default:
      return warrantyType
  }
}

import React, { useCallback, useState, useMemo, useEffect, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { navigate } from '@reach/router'
import { Map, List } from 'immutable'
import moment from 'moment'
import { Grid, Typography, Card, SvgIcon } from '@material-ui/core'

import withAuth from '_hocs/with-auth'
import Building from '_components/building-animated'
import Button, { ButtonColor, ButtonVariant, ButtonFormat } from '_components/button'
import LaunchIcon from '_assets/images/ic-launch.svg'
import CoinIcon from '_assets/images/ic-coin.svg'
import { loadingRemoveProposal } from '_modules/booking/selectors'
import { getLease } from '_modules/leases/actions'
import { toCurrency } from '_utils/helpers'
import Tag, { TagColor, TagVariant } from '_components/tag'
import SimpleModal from '_components/modals/simple-modal'
import {
  sendValueProposal,
  sendProposal,
  sendModificationProposal,
  sendApprovationValue,
  sendApprovationModification,
  SEND_PROPOSAL,
  SEND_VALUE_PROPOSAL,
  SEND_MODIFICATION_PROPOSAL,
  SEND_APPROVATION_VALUE,
  SEND_APPROVATION_MODIFICATION,
  removeProposal,
} from '_modules/proposal/actions'
import {
  checkIsSendingValueProposal,
  isSendingModificationProposalSelector,
  getProposal,
} from '_modules/proposal/selectors'
import RowButton from '_components/row-button'
import IconDropdown from '_assets/images/ic-dropdown2.svg'
import ModalNegotiation from '_components/modal-negotiation'
import ModalReserved from '_components/modal-reserved'
import { getLeaseById, checkIsGettingLease } from '_modules/leases/selectors'
import { FEATURE_FLAGS, COLLAPSE_MINIMUM_REQUESTS } from '_utils/constants'
import Snackbar from '_components/snackbar'
import { usePrevious } from '_utils/hooks'
import { getUserInfoSelector } from '_modules/user/selectors'

import ProposalCard, { BUTTON_STATUS } from '../proposal-card'

import styles from './styles.css'
import useStyles from './materialStyle'
import ProposalAnswer from './proposal-answer'
import ProposalModification from './proposal-modification'
import ProposalValue from './proposal-value'

const tagIconPosition = { position: 'right' }

const ProposalMake = ({ id, location }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const lease = useSelector(state => getLeaseById(state, id))
  const [showModalModifications, setShowModalModifications] = useState(false)
  const [showModalValue, setShowModalValue] = useState(false)
  const [showModalQuit, setShowModalQuit] = useState(false)
  const [initialPrice, setInitialPrice] = useState(Map())
  const [initialModification, setInitialModification] = useState(Map())
  const [values, setValues] = useState({
    rentPrice: 0,
    description: '',
  })
  const [rentPrice, setRentPrice] = useState(0)
  const [listingPrice, setListingPrice] = useState(0)
  const [requestDescription, setRequestDescription] = useState('')
  const [showLeaseHistory, setShowLeaseHistory] = useState(false)
  const [closeDeal, setCloseDeal] = useState(false)
  const [reservedModal, setReservedModal] = useState(false)
  const reserved = useMemo(() => lease && lease.getIn(['listing', 'reserved']), [lease])
  const user = useSelector(getUserInfoSelector)
  const isLoadingSendValue = useSelector(checkIsSendingValueProposal)
  const isGettingLease = useSelector(checkIsGettingLease)

  const isLoadingSendModification = useSelector(isSendingModificationProposalSelector)

  const isLoadingRemoveProposal = useSelector(loadingRemoveProposal)
  const wasLoadingRemoveProposal = usePrevious(isLoadingRemoveProposal)
  useEffect(() => {
    if (id) {
      dispatch(getLease(id))
    }
  }, [dispatch, id])
  const onCloseModal = useCallback(() => {
    setCloseDeal(false)
    setReservedModal(false)
  }, [])

  const proposal = useSelector(getProposal)

  const fromStaff = proposal.get('fromStaff')

  useEffect(() => {
    if (location.state) {
      setRequestDescription(location.state.requestDescription)
      setRentPrice(location.state.rentPrice)
    }

    setRequestDescription('')
    setRentPrice(0)
  }, [location.state])

  const orderedPrices = useMemo(() => {
    if (initialPrice.size) {
      return List([initialPrice])
    }
    return proposal.get('priceRequests').sortBy(a => a.get('createdAt'))
  }, [initialPrice, proposal])

  const orderedModifications = useMemo(() => {
    if (initialModification.size) {
      return List([initialModification])
    }
    return proposal
      .get('modificationRequests')
      .sortBy(a => a.get('createdAt'))
      .reverse()
  }, [initialModification, proposal])

  const valueProposalStatus = useMemo(() => {
    if (proposal && proposal.fromStaff) {
      return BUTTON_STATUS.NO_BUTTON
    }
    if (!proposal || !proposal.priceRequests.size) return BUTTON_STATUS.EMPTY
    const lastRequest = orderedPrices.last()
    if (lastRequest.get('isStaffRequest')) {
      switch (lastRequest.get('approved')) {
        case true:
          return BUTTON_STATUS.ACCEPT
        case false:
          return BUTTON_STATUS.ACTION
        default:
          return BUTTON_STATUS.DROPDOWN
      }
    }
    switch (lastRequest.get('approved')) {
      case true:
        return BUTTON_STATUS.NO_BUTTON
      case false:
        return BUTTON_STATUS.NO_BUTTON
      default:
        return BUTTON_STATUS.SENT
    }
  }, [orderedPrices, proposal])

  const modificationProposalStatus = useMemo(() => {
    if (!proposal || !proposal.modificationRequests.size) return BUTTON_STATUS.EMPTY

    const lastRequest = orderedModifications.first()

    if (proposal.fromStaff) {
      return BUTTON_STATUS.NO_BUTTON
    }
    if (!lastRequest.get('isStaffRequest')) {
      switch (lastRequest.get('approved')) {
        case true:
          return BUTTON_STATUS.NO_BUTTON
        case false:
          return BUTTON_STATUS.ACTION
        default:
          return BUTTON_STATUS.SENT
      }
    }

    switch (lastRequest.get('approved')) {
      case true:
        return BUTTON_STATUS.ACCEPT
      case false:
        return BUTTON_STATUS.ACTION
      default:
        return BUTTON_STATUS.DROPDOWN
    }
  }, [orderedModifications, proposal])

  useEffect(
    () => {
      if (!lease) return

      const listing = lease.get('listing').toJS()
      setListingPrice(listing.rentPrice)
      setValues({ listing })
      setRequestDescription(requestDescription)
      setRentPrice(rentPrice || listing.rentPrice)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [lease, rentPrice]
  )

  useEffect(() => {
    if (!isLoadingRemoveProposal && wasLoadingRemoveProposal) {
      navigate('/minhas-visitas')
    }
  }, [isLoadingRemoveProposal, wasLoadingRemoveProposal])

  const quitProposal = useCallback(() => {
    if (proposal.get('id')) {
      dispatch(removeProposal(lease.getIn(['id']), proposal.get('id')))
    }
    navigate('/minhas-visitas')
  }, [dispatch, lease, proposal])
  const handleQuitProposalModal = useCallback(() => {
    setShowModalQuit(state => !state)
  }, [])

  const handleShowModalValue = useCallback(() => {
    setShowModalValue(state => !state)
  }, [])

  const handleShowModalModifications = useCallback(() => {
    setShowModalModifications(state => !state)
  }, [])
  const hasRequestsProposal = useMemo(() => {
    return !(!proposal.get('priceRequests').size && !proposal.get('modificationRequests').size)
  }, [proposal])

  const onRequestValue = useCallback(
    value => {
      if (!proposal) return

      if (showModalValue) {
        setShowModalValue(false)
      }
      setValues({
        ...values,
        rentPrice: Number(value),
      })

      if (hasRequestsProposal) {
        dispatch(
          sendValueProposal(lease.getIn(['id']), proposal.getIn(['id']), {
            rentPrice: Number(value),
          })
        )
      } else {
        setInitialPrice(
          Map({
            rentPrice: Number(value),
            isStaffRequest: false,
            createdAt: moment().format(),
            approved: null,
          })
        )
      }
    },
    [dispatch, hasRequestsProposal, lease, proposal, showModalValue, values]
  )

  const onRequestModifications = useCallback(
    value => {
      if (!proposal) return
      setShowModalModifications(false)

      setValues({
        ...values,
        description: value,
      })
      if (hasRequestsProposal) {
        dispatch(
          sendModificationProposal(lease.getIn(['id']), lease.getIn(['proposal', 'id']), {
            description: value,
          })
        )
      } else {
        setInitialModification(
          Map({
            description: String(value),
            isStaffRequest: false,
            createdAt: moment().format(),
            approved: null,
          })
        )
      }
    },
    [dispatch, hasRequestsProposal, lease, proposal, values]
  )

  const handleSendInitialRequest = useCallback(() => {
    const state = JSON.parse(localStorage.getItem('proposalState'))
    dispatch(sendProposal(lease.getIn(['id'], state))).then(res => {
      const newProposal = res.value
      if (initialModification.get('description')) {
        dispatch(
          sendModificationProposal(lease.getIn(['id']), newProposal.id, {
            description: initialModification.get('description'),
          })
        )
      }
      if (initialPrice.get('rentPrice')) {
        dispatch(
          sendValueProposal(lease.getIn(['id']), newProposal.id, {
            rentPrice: initialPrice.get('rentPrice'),
          })
        )
      }
    })
    navigate(`/proposta/${lease.get('id')}/enviada`)
  }, [dispatch, initialModification, initialPrice, lease])

  const handleLeaseHistory = useCallback(() => {
    setShowLeaseHistory(prev => !prev)
  }, [])

  const answerValue = useCallback(() => {
    dispatch(sendApprovationValue(lease.getIn(['id']), proposal.get('id'), { approved: true }))
  }, [dispatch, lease, proposal])

  const answerModification = useCallback(() => {
    dispatch(
      sendApprovationModification(lease.getIn(['id']), proposal.get('id'), {
        approved: true,
      })
    )
  }, [dispatch, lease, proposal])

  const onConfirmNegotiation = useCallback(() => {
    const hasModificationProposal =
      modificationProposalStatus === BUTTON_STATUS.DROPDOWN ||
      modificationProposalStatus === BUTTON_STATUS.SENT
    const hasValueProposal =
      valueProposalStatus === BUTTON_STATUS.DROPDOWN || valueProposalStatus === BUTTON_STATUS.SENT
    if (reserved) {
      setReservedModal(true)
      return
    }
    if (user.activeLease && !closeDeal) {
      setCloseDeal(true)
      return
    }
    if (hasValueProposal) {
      answerValue()
    }
    if (hasModificationProposal) {
      answerModification()
    }
    if (FEATURE_FLAGS.allowAllWarrantiesEnabled && lease) {
      navigate(`/minhas-negociacoes/${lease.getIn(['id'])}/garantia/escolha`, {
        state: { listing: lease.get('listing').toJS() },
      })
      return
    }

    setCloseDeal(false)
    navigate(lease && `/analise-credito/${lease && lease.getIn(['id'])}/inicio`, {
      state: { listing: lease.get('listing').toJS() },
    })
  }, [
    answerModification,
    answerValue,
    closeDeal,
    lease,
    modificationProposalStatus,
    reserved,
    user.activeLease,
    valueProposalStatus,
  ])

  const rentValueContent = useMemo(
    () => (
      <p className={styles.row}>
        <span>{rentPrice ? 'Aluguel anunciado' : 'Aluguel'}</span>
        <span>{toCurrency(rentPrice)}</span>
      </p>
    ),
    [rentPrice]
  )
  const proposalValueFooter = useMemo(() => {
    const list = proposal.get('priceRequests').sortBy(item => item.get('createdAt'))
    const last = list.last()
    const historyList = list.filter(p => p !== last)

    if (last && last.get('approved') === false) {
      const lastCounterProposal = historyList.last()
      return (
        list.size > COLLAPSE_MINIMUM_REQUESTS && (
          <Fragment>
            {showLeaseHistory && (
              <div className={styles.history}>
                {list.map(price => (
                  <p className={classnames(styles.row, styles.bold, styles.disabled)}>
                    <span>{price.get('isStaffRequest') ? 'Contraproposta' : 'Proposta'}</span>
                    <span>{toCurrency(price.get('rentPrice'))}</span>
                  </p>
                ))}
              </div>
            )}
            <RowButton
              icon={IconDropdown}
              open={showLeaseHistory}
              onClick={handleLeaseHistory}
              name={
                showLeaseHistory ? 'Fechar negociações anteriores' : 'Abrir negociações anteriores'
              }
              iconFirst={false}
              className={classnames(styles.dropdown, {
                [styles['dropdown-expanded']]: showLeaseHistory,
              })}
            />
            <div className={styles.footer}>
              <p className={classnames(styles.row, styles.bold)}>
                <span>Valor Final</span>
                <span>{toCurrency(lastCounterProposal.get('rentPrice'))}</span>
              </p>
            </div>
          </Fragment>
        )
      )
    }

    return (
      list.size > COLLAPSE_MINIMUM_REQUESTS && (
        <Fragment>
          {showLeaseHistory && (
            <div className={styles.history}>
              {historyList.map(price => (
                <p className={classnames(styles.row, styles.bold, styles.disabled)}>
                  <span>{price.get('isStaffRequest') ? 'Contraproposta' : 'Proposta'}</span>
                  <span>{toCurrency(price.get('rentPrice'))}</span>
                </p>
              ))}
            </div>
          )}
          <RowButton
            icon={IconDropdown}
            open={showLeaseHistory}
            onClick={handleLeaseHistory}
            name={
              showLeaseHistory ? 'Fechar negociações anteriores' : 'Abrir negociações anteriores'
            }
            iconFirst={false}
            className={classnames(styles.dropdown, {
              [styles['dropdown-expanded']]: showLeaseHistory,
            })}
          />
          <div className={styles.footer}>
            <p className={classnames(styles.row, styles.bold)}>
              <span>{last.get('isStaffRequest') ? 'Contraproposta' : 'Proposta'}</span>
              <span>{toCurrency(last.get('rentPrice'))}</span>
            </p>
          </div>
        </Fragment>
      )
    )
  }, [handleLeaseHistory, proposal, showLeaseHistory])

  const isInitialButtonDisabled = useMemo(() => !orderedPrices.size && !orderedModifications.size, [
    orderedModifications.size,
    orderedPrices.size,
  ])

  const isInitialButtonLoading = useMemo(() => {
    return isLoadingSendValue || isLoadingSendModification
  }, [isLoadingSendModification, isLoadingSendValue])

  const showProposalCardValue = useMemo(() => (fromStaff ? !!proposal.get('ownerPrice') : true), [
    fromStaff,
    proposal,
  ])

  const showProposalCardModification = useMemo(
    () => (fromStaff ? !!orderedModifications.size : true),
    [fromStaff, orderedModifications.size]
  )
  const showInitialPriceTag = useMemo(() => proposal && !proposal.priceRequests.size, [proposal])

  const showInitialModificationTag = useMemo(
    () => proposal && !proposal.modificationRequests.size,
    [proposal]
  )

  if (!values.listing) {
    return null
  }
  return (
    <div className={styles.container}>
      {isGettingLease ? (
        <Building className={styles.building} />
      ) : (
        <Fragment>
          {' '}
          <Snackbar
            variant="default"
            message="Operação realizada com sucesso!"
            action={[
              SEND_PROPOSAL.ACTION,
              SEND_VALUE_PROPOSAL.ACTION,
              SEND_MODIFICATION_PROPOSAL.ACTION,
              SEND_APPROVATION_VALUE.ACTION,
              SEND_APPROVATION_MODIFICATION.ACTION,
            ]}
          />
          {showModalQuit && (
            <SimpleModal
              open
              onClose={handleQuitProposalModal}
              onButtonLeftClick={handleQuitProposalModal}
              onButtonRightClick={quitProposal}
              title="Deseja apagar a proposta atual ?"
              description="Tem certeza que deseja apagar a proposta atual?"
              textButtonLeft="CANCELAR"
              textButtonRight="SIM"
            />
          )}
          {showModalValue && (
            <SimpleModal
              open
              onClose={handleShowModalValue}
              onButtonLeftClick={handleShowModalValue}
              onButtonRightClick={onRequestValue}
              title="Solicite um novo valor"
              description={rentValueContent}
              textField
              textFieldCurrency
              textFieldPlaceholder="Digite o valor da proposta"
              textFieldValue={proposal.value}
              textButtonLeft="CANCELAR"
              textButtonRight="ENVIAR"
            />
          )}
          {showModalModifications && (
            <SimpleModal
              open
              onClose={handleShowModalModifications}
              onButtonLeftClick={handleShowModalModifications}
              onButtonRightClick={onRequestModifications}
              title="Especifique sua nova proposta"
              textField
              textFieldPlaceholder="Exemplo: Gostaria de retirar alguns móveis"
              textFieldValue={proposal.description}
              textButtonLeft="CANCELAR"
              textButtonRight="ENVIAR"
            />
          )}
          <div className={styles.content}>
            <Tag
              className={styles.tag}
              icon={LaunchIcon}
              color={TagColor.GRAY}
              variant={TagVariant.BOLD}
              iconOptions={tagIconPosition}
              text={`Apartamento ${values.listing.externalId}`}
              to={`/imovel/${values.listing.slug}`}
            />
            {proposal.get('approved') && (
              <Card classes={{ root: classes.card }}>
                <Grid container direction="column">
                  <Typography className={classes.title} component="h2" variant="subtitle1">
                    Oba! Sua proposta foi aceita.
                  </Typography>
                  <Typography component="p" variant="subtitle2">
                    Dê início a negociação e prossiga para etapa de análise de crédito.
                  </Typography>
                </Grid>
                <SvgIcon aria-hidden="true" className={classes.coinIcon}>
                  <use xlinkHref={CoinIcon} />
                </SvgIcon>
              </Card>
            )}
            <h1 className={styles.title}>Proposta para o imóvel</h1>
            {!fromStaff && (
              <p className={styles.description}>Faça uma proposta de valor ou estrutural.</p>
            )}
            {showProposalCardValue && (
              <ProposalCard
                title="Proposta de novo valor"
                status={valueProposalStatus}
                requests={orderedPrices}
                footer={proposalValueFooter}
                buttonClick={handleShowModalValue}
                actionAnswer={answerValue}
                actionCounterproposal={handleShowModalValue}
                fromStaff={proposal.fromStaff}
                hasOwnerPrice={proposal.ownerPrice}
                listing={lease.listing.toJS()}
                showTooltip
                showInitialTag={showInitialPriceTag}
              >
                <ProposalValue
                  proposal={proposal}
                  requests={orderedPrices}
                  fromStaff={proposal.fromStaff}
                  listtingRentPrice={listingPrice}
                />
              </ProposalCard>
            )}
            {showProposalCardModification && (
              <ProposalCard
                title="Modificações no imóvel"
                status={modificationProposalStatus}
                requests={orderedModifications}
                buttonClick={handleShowModalModifications}
                actionAnswer={answerModification}
                actionCounterproposal={handleShowModalModifications}
                fromStaff={proposal.fromStaff}
                showInitialTag={showInitialModificationTag}
              >
                <ProposalModification
                  fromStaff={proposal.fromStaff}
                  requests={orderedModifications}
                />
              </ProposalCard>
            )}
            {closeDeal && (
              <ModalNegotiation
                isOpen={closeDeal}
                onClose={onCloseModal}
                onConfirmNegotiation={onConfirmNegotiation}
              />
            )}
            <ModalReserved openModal={reservedModal} closeModal={onCloseModal} />
            {hasRequestsProposal ? (
              <ProposalAnswer
                proposal={proposal}
                onClickSubmit={onConfirmNegotiation}
                onClickQuit={handleQuitProposalModal}
              />
            ) : (
              <div className={styles['buttons-list']}>
                <Button
                  className={styles.button}
                  color={ButtonColor.TEXT_BLACK}
                  variant={ButtonVariant.BOLD}
                  format={ButtonFormat.ROUNDED}
                  onClick={handleQuitProposalModal}
                >
                  DESISTIR
                </Button>
                <Button
                  className={styles.button}
                  color={ButtonColor.PRIMARY}
                  variant={ButtonVariant.BOLD}
                  format={ButtonFormat.ROUNDED}
                  disabled={isInitialButtonDisabled}
                  onClick={handleSendInitialRequest}
                  isLoading={isInitialButtonLoading}
                >
                  ENVIAR
                </Button>
              </div>
            )}
          </div>
        </Fragment>
      )}
    </div>
  )
}

ProposalMake.propTypes = {
  id: PropTypes.string.isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      livingWithWho: PropTypes.string,
      numberOfPeople: PropTypes.string,
      hasPet: PropTypes.bool,
      petDescription: PropTypes.string,
      description: PropTypes.string,
      requestDescription: PropTypes.string,
      rentPrice: PropTypes.number,
    }),
  }).isRequired,
}

export default withAuth(React.memo(ProposalMake))

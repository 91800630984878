import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import styles from './styles.css'

const PropertySection = ({ title, description, className }) => {
  const descriptionWhitoutHtmlTag = description ? description.replace(/(<([^>]+)>)/gi, ' ') : ''
  return (
    <div className={classnames(styles.section, className)}>
      <h3>{title}</h3>
      <p>{descriptionWhitoutHtmlTag}</p>
    </div>
  )
}

PropertySection.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  className: PropTypes.string,
}

PropertySection.defaultProps = {
  className: '',
}
export default PropertySection

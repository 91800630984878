import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ palette }) => ({
  totalSelected: {
    textAlign: 'center',
    width: '3.2rem',
    height: '3.2rem',
    background: palette.text.disabled,
    borderRadius: '0.4rem',
    color: palette.secondary.main,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '1.2rem',
    lineHeight: '1.8rem',
    display: 'flex',
    justifyContent: 'center',
  },
}))

/* Base */
import React, { useMemo, useContext } from 'react'
import { useSelector } from 'react-redux'

import { getLeaseById } from '_modules/leases/selectors'
import { getWarrantiesSelector } from '_modules/warranty/selectors'
import WomanMoney from '_assets/images/woman-money.svg'
import IconCheck from '_assets/images/ic-check.svg'
import Button from '_components/material/button'
import { FEATURE_FLAGS, WARRANTY_TYPES, WARRANTY_TRANSLATION } from '_utils/constants'
import TokenContext from '_context/token'

import { CREDIT_ANALYSIS_ROUTES, creditAnalysisFlow } from '../router'

import styles from './styles.css'

// TODO: Refactor to material-ui

const CreditAnalysisStart = () => {
  const { leaseId: id } = useContext(TokenContext)
  const lease = useSelector(state => getLeaseById(state, id))
  const warranties = useSelector(getWarrantiesSelector)

  const getWarrantySteps = useMemo(() => {
    if (lease) {
      return warranties.filter(warranty => warranty.get('type') === lease.warranty).first()
    }
    return []
  }, [lease, warranties])

  const nextRoute = useMemo(() => {
    if (FEATURE_FLAGS.allowAllWarrantiesEnabled) {
      return creditAnalysisFlow(CREDIT_ANALYSIS_ROUTES.MONTHLY_INCOME, id)
    }
    if (lease && lease.warranty !== WARRANTY_TYPES.RAPID) {
      return creditAnalysisFlow(CREDIT_ANALYSIS_ROUTES.REGISTER_DOCUMENTS, id)
    }
    return `/minhas-negociacoes/${lease && lease.getIn(['listing', 'externalId'])}`
  }, [id, lease])

  const renderTitle = useMemo(() => {
    if (lease && lease.warranty === WARRANTY_TYPES.RAPID) {
      return {
        title: 'Você está na etapa: Análise de Crédito',
        subtitle: 'Última etapa antes de você assinar o contrato',
      }
    }
    if (FEATURE_FLAGS.allowAllWarrantiesEnabled) {
      return {
        title: `Você selecionou a garantia: ${lease && WARRANTY_TRANSLATION[lease.warranty]}`,
        subtitle: 'Última etapa antes de você assinar o contrato',
      }
    }
    return {
      title: `Você alterou sua garantia para: ${lease && WARRANTY_TRANSLATION[lease.warranty]}`,
      subtitle: 'Se necessário, atualize as suas informações.',
    }
  }, [lease])

  return (
    <div className={styles.container}>
      <section className={styles['section-header']}>
        <svg aria-hidden="true" className={styles.image}>
          <use xlinkHref={WomanMoney} />
        </svg>
        <div className={styles.header}>
          <h1 className={styles.title}>{renderTitle.title}</h1>
          <p className={styles['sub-title']}>{renderTitle.subtitle}</p>
        </div>
      </section>
      <section className={styles.list}>
        <h1 className={styles.title}>Como funciona</h1>
        <ul>
          {getWarrantySteps &&
            getWarrantySteps.size &&
            getWarrantySteps.get('steps').map(step => (
              <li key={`${step.get('type')}`} className={styles.item}>
                <svg aria-hidden="true" className={styles.icon}>
                  <use xlinkHref={IconCheck} />
                </svg>
                <p className={styles['list-item-description']}>{step.get('description')}</p>
              </li>
            ))}
        </ul>
      </section>
      <section className={styles.buttons}>
        <Button color="secondary" className={styles['give-up-button']} to="/minhas-negociacoes">
          Desistir
        </Button>
        <Button
          color="primary"
          variant="contained"
          className={styles.button}
          to={nextRoute}
          state={{ lease: lease && lease.toJS() }}
        >
          Próxima
        </Button>
      </section>
    </div>
  )
}

export default React.memo(CreditAnalysisStart)

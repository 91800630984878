import React, { useCallback, useMemo, useEffect, Fragment, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { FormContext, useForm, useFieldArray } from 'react-hook-form'
import classnames from 'classnames'
import { navigate, Link } from '@reach/router'

import PlusIcon from '_assets/images/ic-plus-slim.svg'
import {
  updateListingRegistration,
  updateListingRegistrationEmail,
  uploadListingPictures,
  getListingPictures,
} from '_modules/listing-registration/actions'
import {
  getPropertyPhotosSelector,
  isListingRegistrationLoading,
  hasListingRegistrationError,
  listingRegistrationFormSelector,
} from '_modules/listing-registration/selectors'
import Button, { ButtonColor, ButtonVariant, ButtonFormat } from '_components/button'
import { MOBILE_BREAKPOINT, PROPERTY_TYPES } from '_utils/constants'
import { useWindowWidth, usePrevious, useProtectedRoute } from '_utils/hooks'
import TextField from '_components/material/textfield'

import OutlinedField from '../../outlined-field'
import FormGroup from '../form/form-group'
import InputCarousel from '../form/input-carousel'
import { TextField as FormTextField } from '../form/text-field'
import ProgressBar from '../progress-bar'

import styles from './styles.css'
import DescriptionTextField from './description-textfield'
import OptionalExpense from './optional-expense'
import CurrencyInput from './currency-input'
import PhotosSection from './photos-section'

const IPTUInfo = {
  name: 'taxesType',
  rules: { required: '* Este campo precisa ser preenchido' },
  values: [
    {
      id: 'monthly',
      value: 'monthly',
      label: 'MENSAL',
    },
    {
      id: 'annual',
      value: 'annual',
      label: 'ANUAL',
    },
  ],
}

const VALUE_PACE = 25
const MAX_VALUE = 1000000
const MIN_VALUE = 0

const ListingRegistrationDetailedStepThree = () => {
  useProtectedRoute('/anunciar-imovel')

  const listingRegistrationForm = useSelector(listingRegistrationFormSelector)
  const { id, listingType, userToken } = listingRegistrationForm
  const [price, setPrices] = useState({
    condominiumFee: 250,
    garbageFee: 50,
    taxes: 1000,
    insurance: 250,
  })
  const methods = useForm({
    defaultValues: {
      listingRegistrationForm,
    },
  })
  const windowWidth = useWindowWidth()
  const buttonColor = useMemo(
    () => (windowWidth > MOBILE_BREAKPOINT ? ButtonColor.PRIMARY : ButtonColor.INVERTED_PRIMARY),
    [windowWidth]
  )

  const dispatch = useDispatch()
  const isLoading = useSelector(isListingRegistrationLoading)
  const hasRegisterError = useSelector(hasListingRegistrationError)
  const previousLoading = usePrevious(isLoading)
  const { fields, append } = useFieldArray({ control: methods.control, name: 'additionalCost' })
  const appendAdditionalCostField = useCallback(() => append({ name: 'additionalCost' }), [append])
  const initialPropertyPhotos = useSelector(getPropertyPhotosSelector)
  const [propertyPhotos, setPropertyPhotos] = useState(initialPropertyPhotos)
  const [picturesToBeRemoved, setPicturesToBeRemoved] = useState([])

  const isPictureNew = picture => {
    return (
      typeof picture.id === 'undefined' || picture.thumbnail.includes('listing-default-square.png')
    )
  }

  const handleSubmit = useCallback(
    data => {
      if (userToken) {
        return dispatch(updateListingRegistrationEmail(id, userToken, { ...data, ...price }))
      }

      const newPicturesFromInput = propertyPhotos.filter(picture => isPictureNew(picture))

      if (newPicturesFromInput.length > 0) {
        const picturesToBeSaved = { pictures: newPicturesFromInput }
        dispatch(uploadListingPictures(id, picturesToBeSaved))
      }

      if (picturesToBeRemoved.length > 0) {
        return dispatch(
          updateListingRegistration({ ...data, ...price, remove_pictures: picturesToBeRemoved })
        )
      }

      return dispatch(updateListingRegistration({ ...data, ...price }))
    },
    [userToken, propertyPhotos, picturesToBeRemoved, dispatch, price, id]
  )

  useEffect(() => {
    if (previousLoading && !isLoading && !hasRegisterError) {
      navigate(['/anunciar-imovel/passo-4'])
    }
  }, [hasRegisterError, isLoading, previousLoading])

  useEffect(() => {
    dispatch(getListingPictures(id))
  }, [dispatch, id])

  useEffect(() => {
    if (initialPropertyPhotos.length) {
      const filesFromServerMinusTheEmpyStatePicture = initialPropertyPhotos.filter(picture => {
        const valueThatOnlyEmptyStatePicsHave = 'listing-default-square.png'

        if (Object.keys(picture).includes('id')) {
          return !picture.thumbnail.includes(valueThatOnlyEmptyStatePicsHave)
        }

        return []
      })

      setPropertyPhotos(filesFromServerMinusTheEmpyStatePicture)
    }
  }, [initialPropertyPhotos])

  return (
    <FormContext {...methods}>
      <ProgressBar value={75} />
      <form className={styles.container} onSubmit={methods.handleSubmit(handleSubmit)}>
        <DescriptionTextField
          type="Descrição do imóvel"
          about="Ninguém melhor do que você para dizer as principais qualidades do seu imóvel"
          name="description"
        />
        <DescriptionTextField
          type="Descrição do bairro"
          about="O que tem de atrativo no seu bairro?"
          name="neighborhoodDescription"
        />
        <div className={styles.expenses}>
          <h1 className={classnames(styles.title, styles.section)}>Custos</h1>
          {listingType === PROPERTY_TYPES.RES_APARTMENT && (
            <FormGroup label="Qual o valor médio do condomínio?" className={styles['inline-field']}>
              <CurrencyInput
                valuePace={VALUE_PACE}
                maxValue={MAX_VALUE}
                minValue={MIN_VALUE}
                name="condominiumFee"
                amount={price.condominiumFee}
                setValue={setPrices}
              />
            </FormGroup>
          )}
          <FormGroup
            label="Qual o valor da taxa de coleta de lixo?"
            className={styles['inline-field']}
          >
            <CurrencyInput
              valuePace={VALUE_PACE}
              maxValue={MAX_VALUE}
              minValue={MIN_VALUE}
              name="garbageFee"
              amount={price.garbageFee}
              setValue={setPrices}
            />
          </FormGroup>
        </div>
        <div className={styles.expenses}>
          <h1 className={styles.title}>IPTU</h1>
          {windowWidth > MOBILE_BREAKPOINT && (
            <p className={styles.about}>Caso exista IPTU de garagem, adicione no valor total.</p>
          )}
          <InputCarousel key={IPTUInfo.name} {...IPTUInfo} />
          <CurrencyInput
            valuePace={VALUE_PACE}
            maxValue={MAX_VALUE}
            minValue={MIN_VALUE}
            name="taxes"
            amount={price.taxes}
            setValue={setPrices}
            className={styles['inline-field']}
          />
          <OptionalExpense
            valuePace={VALUE_PACE}
            maxValue={MAX_VALUE}
            minValue={MIN_VALUE}
            label="O imóvel tem seguro?"
            name="insurance"
            endAdornmentType="R$"
            value={price.insurance}
            setValue={setPrices}
          />
          <FormTextField
            showApplicableOption
            className={styles.textfield}
            label="O imóvel tem outros gastos?"
          >
            {({ ...props }) => {
              return (
                <Fragment>
                  <OutlinedField
                    {...props}
                    name="additionalCost[0].name"
                    placeholder="Especifique o gasto"
                    label="Especifique o gasto"
                  />
                  <TextField
                    name="additionalCost[0].value"
                    placeholder="Digite um valor"
                    label="Digite um valor"
                    endAdornment="R$"
                    {...props}
                  />
                  {fields.map((item, index) => (
                    <Fragment key={item.id}>
                      <OutlinedField
                        {...props}
                        name={`additionalCost[${index + 1}].name`}
                        placeholder="Especifique o gasto"
                        label="Especifique o gasto"
                      />
                      <TextField
                        {...props}
                        name={`additionalCost[${index + 1}].value`}
                        placeholder="Digite um valor"
                        endAdornment="R$"
                        label="Digite um valor"
                      />
                    </Fragment>
                  ))}
                </Fragment>
              )
            }}
          </FormTextField>
          <button
            className={styles['append-button']}
            type="button"
            onClick={appendAdditionalCostField}
            aria-label="Adicionar novo campo de gastos"
          >
            <svg aria-hidden="true" focusable="false" className={styles.icon}>
              <use xlinkHref={PlusIcon} />
            </svg>
            Adicionar mais gastos
          </button>
        </div>
        <PhotosSection
          propertyPhotos={propertyPhotos}
          setPicturesToBeRemoved={setPicturesToBeRemoved}
          setPropertyPhotos={setPropertyPhotos}
        />
        <DescriptionTextField type="Deseja comentar algo a mais?" name="comments" />
        <div className={styles['buttons-container']}>
          {windowWidth > MOBILE_BREAKPOINT && (
            <Link to="/anunciar-imovel/passo-2" className={styles.back}>
              VOLTAR
            </Link>
          )}
          <Button
            color={buttonColor}
            variant={ButtonVariant.BOLD}
            format={ButtonFormat.SQUARED}
            type="submit"
            className={styles.button}
            isLoading={isLoading}
          >
            SALVAR E CONTINUAR
          </Button>
        </div>
      </form>
    </FormContext>
  )
}

export default React.memo(ListingRegistrationDetailedStepThree)

import React from 'react'
import PropTypes from 'prop-types'

import emailOpenImage from '_assets/images/ic-email-open.svg'
import Button, { ButtonColor, ButtonVariant, ButtonFormat } from '_components/button'

import styles from './styles.css'

const RecoverNumber = ({ userInfo, onLoginClick }) => (
  <div className={styles.content}>
    <div className={styles.body}>
      <svg aria-hidden="true" className={styles['email-image']}>
        <use xlinkHref={emailOpenImage} />
      </svg>
      <h1 className={styles.title}>E-mail enviado</h1>
      <p className={styles.description}>
        Enviamos um e-mail para <strong>{userInfo.email}</strong> com o link para redefinir o seu
        número.
      </p>
    </div>
    <Button
      color={ButtonColor.PRIMARY}
      variant={ButtonVariant.BOLD}
      format={ButtonFormat.SQUARED}
      className={styles.button}
      onClick={onLoginClick}
    >
      LOGIN
    </Button>
  </div>
)

RecoverNumber.propTypes = {
  userInfo: PropTypes.shape({
    email: PropTypes.string,
  }).isRequired,
  onLoginClick: PropTypes.func.isRequired,
}

export default React.memo(RecoverNumber)

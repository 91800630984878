import React from 'react'
import { Location } from '@reach/router'
import ReactGA from 'react-ga'

import { GA_ID } from '_config/environment'

import Router from './router'

const fireTracking = ({ pathname, search }) => {
  if ((GA_ID && !window.GA_ID) || (!GA_ID && window.GA_ID)) {
    ReactGA.pageview(pathname + search)
  }
  if (GA_ID && window.GA_ID) {
    ReactGA.pageview(pathname + search, ['woliverId', 'brandId'])
  }
}

const RouterWithTracking = () => (
  <Location>
    {({ location }) => {
      fireTracking(location)
      return <Router />
    }}
  </Location>
)

export default RouterWithTracking

import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ breakpoints, palette }) => ({
  container: {
    margin: '4rem 0 3.2rem',

    [breakpoints.down('sm')]: {
      margin: '0 auto',
      width: '87%',
    },
  },
  content: {
    display: 'grid',
    gridTemplateRows: 'auto',
    gridTemplateColumns: '1fr 1fr',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'space-between',
    height: '5.6rem',
    padding: '1.2rem 1.6rem',
    background: palette.gray.main,
    border: `0.1rem solid ${palette.gray.default}`,
    borderRadius: '0.4rem',
    marginTop: '1.6rem',
  },
  button: {
    marginLeft: 'auto',
    maxHeight: '3.2rem',
    maxWidth: '9.7rem',
  },
  description: {
    fontWeight: '400',
  },
  photos: {
    display: 'grid',
    gridTemplateRows: 'auto',
    gridTemplateColumns: '20rem 20rem',
    gap: '0.8rem',
    background: palette.gray.main,
    border: `0.1rem solid ${palette.gray.default}`,
    borderTop: '0',
    borderBottomRadius: '0.4rem',
    padding: '1.6rem',

    [breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr 1fr',
    },
  },
  file: {
    [breakpoints.down('sm')]: {
      width: '12rem',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  fileSelector: {
    display: 'none',
  },
  title: {
    fontWeight: 'bold',
  },
}))

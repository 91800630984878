import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { getExternalId } from '_utils/helpers'

import styles from './styles.css'

const LocationSection = ({ text, id, icon, address, className }) => (
  <div className={classnames(styles.container, className)}>
    {id && (
      <h3>
        {text} <span>{getExternalId(id)}</span>
      </h3>
    )}
    <div className={styles.location}>
      <svg aria-hidden="true">
        <use xlinkHref={icon} />
      </svg>
      <p>{address}</p>
    </div>
  </div>
)

LocationSection.propTypes = {
  id: PropTypes.string,
  text: PropTypes.string,
  icon: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  className: PropTypes.string,
}

LocationSection.defaultProps = {
  id: '',
  text: '',
  className: '',
}

export default React.memo(LocationSection)

import { useEffect, useState } from 'react'

const useCepAutoComplete = value => {
  const [data, setData] = useState({})
  const [isLoading, setLoading] = useState(false)
  const numberCep = value && value.replace(/[^0-9]+/g, '')

  useEffect(() => {
    if (numberCep && numberCep.length === 8) {
      setLoading(true)
      fetch(`http://viacep.com.br/ws/${numberCep}/json/`)
        .then(response => response.json())
        .then(values => {
          setLoading(false)
          setData({
            city: values.localidade,
            neighborhood: values.bairro,
            address: values.logradouro,
            cep: numberCep,
            state: values.uf,
          })
        })
        .catch(() => {
          setLoading(false)
        })
    }
  }, [numberCep])
  return [isLoading, data]
}

export default useCepAutoComplete

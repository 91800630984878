import React, { useCallback } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'

import BuildingChanging from '_assets/images/building-changing.svg'

import styles from './styles.css'

const ChangingSection = ({ className }) => {
  const renderChangingCard = useCallback(
    (title, description) => (
      <li className={styles['changing-card-container']}>
        <CheckCircleOutlineIcon htmlColor="#41CEC0" className={styles.icon} />
        <div>
          <h3 className={styles['changing-card-title']}>{title}</h3>
          <p className={styles['changing-card-text']}>{description}</p>
        </div>
      </li>
    ),
    []
  )

  const formatDescription = description => {
    return description.split('\n').map(line => (
      <React.Fragment key={line}>
        {line}
        <br />
      </React.Fragment>
    ))
  }

  return (
    <div className={classnames(styles.container, className)}>
      <div className={styles['changing-header']}>
        <svg aria-hidden="true" className={styles.image}>
          <use xlinkHref={BuildingChanging} />
        </svg>
        <h2 className={classnames(styles['section-title'], styles['changing-title'])}>
          Nós estamos mudando
        </h2>
        <p className={styles['changing-text']}>
          Entendemos a importância de encontrar um lar. <br />
          Por isso, estamos em constante evolução.
        </p>
      </div>
      <ul className={styles['changing-list']}>
        {renderChangingCard(
          'Rapidez',
          formatDescription(`Agendamento de visita online
Assinatura de contrato digital
Análise de crédito de 15 minutos`)
        )}
        {renderChangingCard(
          'Menos papelada',
          'Várias opções de garantia para locação incluindo sem fiador e muito mais.'
        )}
        {renderChangingCard(
          'Cada vez mais nova',
          'Há 70 anos no mercado imobiliário sempre trazendo inovação.'
        )}
      </ul>
    </div>
  )
}

ChangingSection.propTypes = {
  className: PropTypes.string,
}

ChangingSection.defaultProps = {
  className: '',
}

export default React.memo(ChangingSection)

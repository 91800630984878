import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Paper, Grid, Typography, Button } from '@material-ui/core'
import { Launch } from '@material-ui/icons'
import { useParams } from '@reach/router'

import ContractSigned from '_assets/images/contract-signed.png'
import PhoneLogo from '_assets/images/phone-done.png'
import ScheduleVisit from '_assets/images/schedule-visit.png'
import MakeProposal from '_assets/images/proposal-make.png'
import CreditAnalysis from '_assets/images/credit-analysis.png'

import useStyles from './styles'

const STEPS = [
  {
    id: 1,
    title: 'Visita no imóvel',
    description: 'Agende através da plataforma',
    image: ScheduleVisit,
  },
  {
    id: 2,
    title: 'Proposta (opcional)',
    description: 'Negocie valores ou modificações com o proprietário',
    image: MakeProposal,
  },
  {
    id: 3,
    title: 'Análise de crédito',
    description: 'Envie os documentos de todas as pessoas que participarão do contrato.',
    image: CreditAnalysis,
  },
  {
    id: 4,
    title: 'Assinatura do contrato',
    description: 'Assine o contrato de forma digital',
    image: ContractSigned,
  },
]

const ScheduleFeedback = ({ className, paperStyle, showLabelRequestSent, showButtonProperty }) => {
  const { externalId } = useParams()
  const styles = useStyles()

  return (
    <Grid className={classnames(className, styles.container)}>
      {showLabelRequestSent && (
        <Grid component="section" className={styles.phoneContainer}>
          {showButtonProperty && (
            <Button
              href={`/imovel/${externalId}`}
              size="small"
              className={styles.redirectButton}
              endIcon={<Launch className={styles.launchIcon} fontSize="small" />}
              target="_blank"
              rel="noopener noreferrer"
            >
              Apartamento {externalId}
            </Button>
          )}
          <img className={styles.logo} alt="Solicitação enviada" src={PhoneLogo} />
          <Typography color="secondary" component="h1" variant="h3">
            Solicitação Enviada!
          </Typography>
          <Typography className={styles.contact} color="secondary" component="p" variant="h4">
            Um consultor entrará em contato em breve.
          </Typography>
        </Grid>
      )}

      <Paper
        component="section"
        variant="outlined"
        className={classnames(paperStyle, styles.paper)}
      >
        {showLabelRequestSent && (
          <Typography className={styles.title} component="h1" variant="h4">
            Como funciona para alugar um imóvel de forma digital?
          </Typography>
        )}
        <Grid className={styles.stepsContainer}>
          {STEPS.map(step => (
            <Grid key={step.id} className={styles.oneStep}>
              <img alt={`Figura de ${step.title}`} src={step.image} className={styles.image} />
              <Typography className={styles.name} component="h2" variant="subtitle1">
                {step.id}. {step.title}
              </Typography>
              <Typography className={styles.description} component="p" variant="subtitle1">
                {step.description}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Paper>
    </Grid>
  )
}

ScheduleFeedback.propTypes = {
  className: PropTypes.string,
  paperStyle: PropTypes.string,
  showLabelRequestSent: PropTypes.bool,
  showButtonProperty: PropTypes.bool,
}

ScheduleFeedback.defaultProps = {
  className: '',
  paperStyle: '',
  showLabelRequestSent: true,
  showButtonProperty: true,
}

export default React.memo(ScheduleFeedback)

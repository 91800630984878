export const FEATURE_FLAGS = {
  productVersionOneEnabled: false, // Limita o fluxo até a fase de agendamento de visitas
  contractEnabled: true, // Habilita o fluxo de negociações
  creditAnalysisIntegrationEnabled: false, // Habilita metodologia usada pelo ZAP, que tem integração com o Porto Seguro
  allowAllWarrantiesEnabled: true, // Habilita o fluxo para o usuário escolher o tipo de garantia no começo da negociação
  bookingNeededActionEnabled: false, // Mostra botão para mais informações na página de agendamento
  commercialListingsEnabled: false, // Habilita listagem de imóveis comerciais. Usado para mostrar os filtros.
  customPhoneAuthenticationEnabled: true, // Habilita autentiação através da AWS
  fireInsuranceEnabled: false, // Habilita a feature de seguro incêndio.
  emergencyContactEmailEnabled: false, // Habilita o campo contato de emergência que será mostrado junto ao Lead no backoffice.
  propertyRegistrationEnabled: true, // Habilita o botão - Anunciar Imóvel - no menu superior para que o proprietário passa registrar sua propriedade
  rentalOnlyPropertyRegistrationEnabled: true, // Permite que o proprietário cadastre apenas imóveis para locação
}

export const GENERAL = {
  // General
  brand: 'Nova Freitas',
  creci: '10355-J',
  email: 'qualidade@novafreitas.com.br',
  address: 'Avenida Anchieta',
  realEstateAgency: 'Nova Freitas Imóveis',
  citiesArray: ['Florianópolis', 'São José', 'Palhoça', 'Biguaçu'],
  searchCenterLat: '-23.219523',
  searchCenterLng: '-45.882979',
  baseUrl: 'http://cliqueealugue.com.br/',

  // Domain / URL
  appUrl: 'http://cliqueealugue.com.br/',
  websiteUrl: 'https://www.novafreitas.com.br',

  // Google Meta Tag
  metatagsHomeDescription:
    'A Nova Freitas Imóveis atua em São José dos Campos e Litoral Norte há 71 anos com Aluguel, Vendas e com os melhores lançamentos da região.',

  // Social Media
  landlordUrl: '/anunciar-imovel',
  instagramUrl: 'https://www.instagram.com/novafreitasimoveis/',
  facebookUrl: 'https://www.facebook.com/novafreitasimoveis',
  linkedinUrl: 'https://www.linkedin.com/company/nova-freitas-imóveis/',

  // brandLogoUrl: 'https://statics.terraz.com.br/public/settings/terraz/terraz-logo.svg',
  brandHeaderLogoHeight: 37,
  brandPictureUrl: 'https://statics.terraz.com.br/public/settings/woliver/woliver-logo.png',
  privacyPolicyUpdateDate: '2019-07-01T23:00:14Z', // changed
  privacyPolicyFile: null,
  // 'https://statics.terraz.com.br/public/settings/terraz/Politica_de_Privacidade__Cookies_Terraz.pdf',
  termsOfUseUpdateDate: '2019-07-01T23:00:17Z', // changed
  hoursReservedListing: 24,
}

export const parseNumber = cpf => (cpf ? cpf.replace(/[^0-9]+/g, '') : '')

export const MOBILE_BREAKPOINT = 480

export const COLLAPSE_MINIMUM_REQUESTS = 2

export const PROPERTY_TYPES = {
  RES_HOME: 'res_home',
  RES_APARTMENT: 'res_apartment',
  RES_FARM_RANCH: 'res_farm_ranch',
  RES_CONDO: 'res_condo',
  RES_FLAT: 'res_flat',
  RES_LAND_LOT: 'res_land_lot',
  RES_SOBRADO: 'res_sobrado',
  RES_PENTHOUSE: 'res_penthouse',
  RES_STUDIO: 'res_studio',
  RES_KITNET: 'res_kitnet',
  COM_CONSULTORIO: 'com_consultorio',
  COM_OFFICE: 'com_office',
  COM_AGRICULTURAL: 'com_agricultural',
  COM_INDUSTRIAL: 'com_industrial',
  COM_BUILDING: 'com_building',
  COM_LOJA: 'com_loja',
  COM_LAND_LOT: 'com_land_lot',
  COM_RESIDENTIAL_INCOME: 'com_residential_income',
  COM_RETAIL: 'com_retail',
  COM_ALL: 'com_all',
  RES_ALL: 'res_all',
}

export const DEFAULT_SEARCH = {
  defaultCity: 'todas',
  defaultRentPrice: null,
  defaultNeighborhood: 'todos',
  listingTypeChoices: [
    'com_loja',
    'res_penthouse',
    'com_agricultural',
    'res_flat',
    'res_land_lot',
    'com_building',
    'res_apartment',
    'res_home',
    'res_condo',
    'com_residential_income',
    'com_industrial',
    'res_kitnet',
    'com_business',
    'com_office',
    'res_sobrado',
  ],
}

export const RESIDENTIAL_PROPERTY_TYPES = [
  PROPERTY_TYPES.RES_HOME,
  PROPERTY_TYPES.RES_APARTMENT,
  PROPERTY_TYPES.RES_FARM_RANCH,
  PROPERTY_TYPES.RES_CONDO,
  PROPERTY_TYPES.RES_FLAT,
  PROPERTY_TYPES.RES_SOBRADO,
  PROPERTY_TYPES.RES_PENTHOUSE,
  PROPERTY_TYPES.RES_STUDIO,
  PROPERTY_TYPES.RES_KITNET,
]

export const COMMERCIAL_PROPERTY_TYPES = [
  PROPERTY_TYPES.COM_OFFICE,
  PROPERTY_TYPES.COM_INDUSTRIAL,
  PROPERTY_TYPES.COM_BUILDING,
  PROPERTY_TYPES.COM_LOJA,
  PROPERTY_TYPES.COM_RESIDENTIAL_INCOME,
  PROPERTY_TYPES.COM_RETAIL,
  PROPERTY_TYPES.COM_LAND_LOT,
  PROPERTY_TYPES.COM_CONSULTORIO,
]

export const PROPERTY_TYPES_LABELS = {
  [PROPERTY_TYPES.RES_HOME]: 'Casa',
  [PROPERTY_TYPES.RES_APARTMENT]: 'Apartamento',
  [PROPERTY_TYPES.RES_FARM_RANCH]: 'Sítio',
  [PROPERTY_TYPES.RES_CONDO]: 'Condomínio',
  [PROPERTY_TYPES.RES_FLAT]: 'Flat',
  [PROPERTY_TYPES.RES_LAND_LOT]: 'Terreno',
  [PROPERTY_TYPES.RES_SOBRADO]: 'Sobrado',
  [PROPERTY_TYPES.RES_PENTHOUSE]: 'Cobertura',
  [PROPERTY_TYPES.RES_STUDIO]: 'Studio',
  [PROPERTY_TYPES.RES_KITNET]: 'Kitnet',
  [PROPERTY_TYPES.COM_CONSULTORIO]: 'Consultório',
  [PROPERTY_TYPES.COM_OFFICE]: 'Escritório',
  [PROPERTY_TYPES.COM_AGRICULTURAL]: 'Comercial de agricultura',
  [PROPERTY_TYPES.COM_INDUSTRIAL]: 'Comercial industrial',
  [PROPERTY_TYPES.COM_BUILDING]: 'Ponto Comercial',
  [PROPERTY_TYPES.COM_RESIDENTIAL_INCOME]: 'Casa comercial',
  [PROPERTY_TYPES.COM_LOJA]: 'Loja',
  [PROPERTY_TYPES.COM_LAND_LOT]: 'Terreno Comercial',
  [PROPERTY_TYPES.COM_RETAIL]: 'Sala Comercial',
  [PROPERTY_TYPES.COM_ALL]: 'Todos',
  [PROPERTY_TYPES.RES_ALL]: 'Todos',
}

export const PURPOSE_TYPES = {
  RESIDENTIAL: 'residential',
  COMERCIAL: 'comercial',
}

export const COMMERCIAL = 'commercial'

export const PROPERTY_FURNITURES = {
  SEMI_FURNISHED: 'semi_furnished',
  UNFURNISHED: 'unfurnished',
  FURNISHED: 'furnished',
}

export const PROPERTY_BOOKING = {
  AVAILABLE: 'available',
  VISITING: 'visiting',
  BOOKED: 'booked',
  KEY_OWNER: 'key_owner',
}

export const PROPERTY_TRANSACTIONS = {
  RENT: 'rent',
}

export const WARRANTY_TYPES = {
  RAPID: 'rapid',
  CREDPAGO: 'credpago',
  INSURANCE: 'insurance',
  GUARANTOR: 'guarantor',
  CAPITALIZATION: 'capitalization',
  STUDENT: 'student',
  DEPOSIT: 'deposit',
  PROPERTY: 'property',
  OLD_CREDPAGO: 'old_credpago',
  VELO: 'velo',
  SIGAFY: 'sigafy',
}

export const WARRANTY_TRANSLATION = {
  [WARRANTY_TYPES.RAPID]: 'Aluguel Rápido',
  [WARRANTY_TYPES.CREDPAGO]: 'CredPago',
  [WARRANTY_TYPES.GUARANTOR]: 'Fiador',
  [WARRANTY_TYPES.INSURANCE]: 'Seguro Fiança',
  [WARRANTY_TYPES.CAPITALIZATION]: 'Título de Capitalização',
  [WARRANTY_TYPES.STUDENT]: 'Estudante',
  [WARRANTY_TYPES.DEPOSIT]: 'Caução',
  [WARRANTY_TYPES.PROPERTY]: 'Caução Bem Imóvel',
  [WARRANTY_TYPES.VELO]: 'Velo',
  [WARRANTY_TYPES.SIGAFY]: 'Seguro Fiança',
}

export const LABEL_RENT_PRICE = 'Aluguel'

export const PARTICIPANTS_TYPE = {
  GUARANTOR: 'guarantor',
  MAIN_TENANT: 'main_tenant',
  TENANT: 'tenant',
  INCOME_HELPER: 'income_helper',
  SPOUSE: 'spouse',
}

export const PARTICIPANTS_TRANSLATION = {
  [PARTICIPANTS_TYPE.GUARANTOR]: 'Fiadores',
  [PARTICIPANTS_TYPE.MAIN_TENANT]: 'Locatário Principal',
  [PARTICIPANTS_TYPE.TENANT]: 'Moradores',
  [PARTICIPANTS_TYPE.INCOME_HELPER]: 'Locatários Extra',
}

export const PARTICIPANTS_TRANSLATION_SINGULAR = {
  ...PARTICIPANTS_TRANSLATION,
  [PARTICIPANTS_TYPE.GUARANTOR]: 'Fiador',
  [PARTICIPANTS_TYPE.TENANT]: 'Morador',
}

export const SEARCH_BEDROOMS_OPTIONS = [
  { value: 1, label: '1+', description: '1 Quarto' },
  { value: 2, label: '2+', description: '2 Quartos' },
  { value: 3, label: '3+', description: '3 Quartos' },
  { value: 4, label: '4+', description: '4 Quartos' },
]

export const SEARCH_PROPERTY_TYPES_OPTIONS = [
  { value: 'residential', slug: 'res_', description: 'Residencial' },
  { value: 'commercial', slug: 'com_', description: 'Comercial' },
]

export const SEARCH_PRICE_OPTIONS = [
  { value: 1000, label: 1000 },
  { value: 2000, label: 2000 },
  { value: 3000, label: 3000 },
  { value: 4000, label: 4000 },
  { value: 5000, label: 5000 },
  { value: 10000, label: 10000 },
  { value: 20000, label: 20000 },
]

export const SORT_OPTIONS = {
  LATEST: 'latest',
  LOWEST_PRICE: 'lowest-price',
  HIGHER_PRICE: 'higher-price',
}

export const SEARCH_SORT_OPTIONS = [
  { value: SORT_OPTIONS.LATEST, label: 'Mais recente' },
  { value: SORT_OPTIONS.LOWEST_PRICE, label: 'Menor preço' },
  { value: SORT_OPTIONS.HIGHER_PRICE, label: 'Maior preço' },
]

export const SEARCH_FILTERS = {
  CITY: 'city',
  NEIGHBORHOODS: 'neighborhoods',
  PRICE: 'price',
  BEDROOMS: 'bedrooms',
  PURPOSE_TYPE: 'purposeType',
}

export const FURNITURE_VALUES = {
  WITH: 'COM',
  WITHOUT: 'SEM',
  YES: 'SIM',
  NO: 'NÃO',
  SEMI_FURNISHED: 'SEMIMOBILIADO',
  MAYBE: 'TANTO FAZ',
}

export const PET_VALUES = {
  YES: 'SIM',
  NO: 'NÃO',
  MAYBE: 'TANTO FAZ',
}
export const GENERAL_ROUTES = {
  WARRANTY: '/garantia',
  SQUEEZE_VISIT: '/solicitar-encaixe',
  MORE_INFO: '/mais-informacoes',
  SCHEDULE: '/agendamento',
  MY_VISITS: '/minhas-visitas',
  MY_NEGOTIATIONS: '/minhas-negociacoes',
  CONTRACT: '/contrato',
  PROPOSAL: '/proposta',
  CREDIT_ANALYSIS: '/analise-credito',
  ADVERTISE_PROPERTY: '/anunciar-imovel',
}

export const MENU_TITLE = {
  [GENERAL_ROUTES.WARRANTY]: 'Análise de crédito',
  [GENERAL_ROUTES.SQUEEZE_VISIT]: 'Encaixe de visita',
  [GENERAL_ROUTES.MORE_INFO]: 'Mais informações',
  [GENERAL_ROUTES.SCHEDULE]: 'Minhas Visitas',
  [GENERAL_ROUTES.MY_VISITS]: 'Minhas Visitas',
  [GENERAL_ROUTES.MY_NEGOTIATIONS]: 'Negociações',
  [GENERAL_ROUTES.CONTRACT]: 'Contrato',
  [GENERAL_ROUTES.PROPOSAL]: 'Proposta',
  [GENERAL_ROUTES.CREDIT_ANALYSIS]: 'Análise de Crédito',
}

export const OTHER_BOOKING_OPTIONS = {
  TIME_SLOT: 'time_slot',
  MORE_INFO: 'more_info',
}

export const CREDIT_ANALYSIS_STATUS = {
  PENDING: 'credit_analysis_pending',
  DATA_REQUESTED: 'credit_analysis_data_requested',
  DOCUMENTS_REQUESTED: 'credit_analysis_documents_requested',
  PARTICIPANT_REQUESTED: 'credit_analysis_participant_requested',
  WARRANTY_REQUESTED: 'credit_analysis_warranty_requested',
  API_ERROR: 'credit_analysis_api_error',
  IN_REVIEW: 'credit_analysis_in_review',
  APPROVED: 'credit_analysis_approved',
  INITIALLY_APPROVED: 'credit_analysis_initially_approved',
  CONTRACT_READY: 'credit_analysis_contract_approved',
}

export const LEASE_STATUS = {
  DEACTIVATED: 'deactivated',
  NOT_INITIATED: 'not_initiated',
  BOT_WITHOUT_ANSWER: 'bot_without_answer',
  BOT_WITHOUT_BOOKING: 'bot_without_booking',
  BOT_RECOMMENDATIONS_CREATED: 'bot_recommendations_created',
  BOT_MANUAL_SDR: 'bot_manual_sdr',
  ...CREDIT_ANALYSIS_STATUS,
  PRE_CONTRACT_IN_REVIEW: 'pre_contract_in_review',
  PRE_CONTRACT_MISSING_DOCUMENTS: 'pre_contract_missing_documents',
  PROPOSAL_PENDING: 'proposal_pending',
  PROPOSAL_APPROVED: 'proposal_approved',
  PROPOSAL_REJECTED: 'proposal_rejected',
  PROPOSAL_CANCELLED: 'proposal_cancelled',
  APPROVED: 'approved',
  DECLINED: 'declined',
  ACTIVE: 'active',
  CANCELLED: 'cancelled',
  REJECTED: 'rejected',
  INCOME_HELPERS_REQUESTED: 'income_helpers_requested',
  INCOME_HELPERS_PENDING: 'income_helpers_pending',
  CONTRACT_READY: 'contract_ready',
  CONTRACT_SIGNED: 'contract_signed',
  CONTRACT_IN_REVIEW: 'contract_in_review',
}

export const FB_STATUS = {
  STARTING_AUTHENTICATION: 'STARTING_AUTHENTICATION',
  NOT_AUTHENTICATED: 'NOT_AUTHENTICATED',
  PARTIALLY_AUTHENTICATED: 'PARTIALLY_AUTHENTICATED',
  BAD_PARAMS: 'BAD_PARAMS',
}

export const CIVIL_STATUS_NAMES = {
  SINGLE: 'single',
  MARRIED: 'married',
  DIVORCED: 'divorced',
  SEPARATED: 'separated',
  WIDOWED: 'widowed',
}
export const CIVIL_STATUS_LABEL = {
  [CIVIL_STATUS_NAMES.SINGLE]: 'Solteira(o)',
  [CIVIL_STATUS_NAMES.MARRIED]: 'Casada(o)',
  [CIVIL_STATUS_NAMES.DIVORCED]: 'Divorciada(o)',
  [CIVIL_STATUS_NAMES.SEPARATED]: 'Separada(o)',
  [CIVIL_STATUS_NAMES.WIDOWED]: 'Viúva(o)',
}

export const CIVIL_STATUS_VALUES = [
  { label: 'Solteira(o)', value: CIVIL_STATUS_NAMES.SINGLE },
  { label: 'Casada(o)', value: CIVIL_STATUS_NAMES.MARRIED },
  { label: 'Divorciada(o)', value: CIVIL_STATUS_NAMES.DIVORCED },
  { label: 'Separada(o)', value: CIVIL_STATUS_NAMES.SEPARATED },
  { label: 'Viúva(o)', value: CIVIL_STATUS_NAMES.WIDOWED },
]

export const GENDER_NAMES = {
  MALE: 'male',
  FEMALE: 'female',
  OTHER: 'other',
}
export const GENDER_LABEL = {
  [GENDER_NAMES.MALE]: 'Masculino',
  [GENDER_NAMES.FEMALE]: 'Feminino',
  [GENDER_NAMES.OTHER]: 'Outro',
}

export const GENDER_VALUES = [
  { value: GENDER_NAMES.MALE, label: GENDER_LABEL[GENDER_NAMES.MALE] },
  { value: GENDER_NAMES.FEMALE, label: GENDER_LABEL[GENDER_NAMES.FEMALE] },
  { value: GENDER_NAMES.OTHER, label: GENDER_LABEL[GENDER_NAMES.OTHER] },
]

export const DEFAULT_VALUE_PRICE = 'A consultar'

export const CURRENCY_MASK_DETAILS = {
  autoUnmask: true,
  radixPoint: ',',
  groupSeparator: '.',
  allowMinus: false,
  prefix: '',
  digits: 2,
  digitsOptional: false,
  rightAlign: true,
  unmaskAsNumber: true,
}

export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const SNACKBAR_INTERVAL = 5000

export const PATHNAMES_WITHOUT_PARAMETERS = [
  '/imovel/',
  '/minhas-visitas',
  '/minhas-negociacoes',
  '/contrato',
  '/feedback-visita',
  '/registrar-contratante-passo',
  '/convidar-participantes',
  '/seguro-incendio',
  '/agendamento',
  '/anunciar-imovel',
  '/problema-reportado',
  '/proposta/apresentacao/',
  '/proposta/resumo-locacao/',
  '/analise-credito/',
  '/proposta/feedback/',
]

export const BOOKING_FEEDBACK_TYPE = {
  CLIENT_BOOKING: 'client_booking', // Rating of the booking experience
  CLIENT_REVIEW: 'client_review', // User rating after the booking
  CLIENT_ISSUE: 'client_issue', // Reported problem
}

export const BOOKING_FEEDBACK_USER_ACTION = {
  PROPOSAL: 'proposal',
  MORE_LISTINGS: 'more_listings',
}

export const BOOKING_USER_TYPE = {
  STAFF: 'staff',
  UNACCOMPANIED: 'unaccompanied',
}

export const INPUT_MAX_CHARACTERS = { SMALL: 350, BIG: 500 }

export const BOOKING_STATUS = {
  // Booking tab
  BOOKING_SCHEDULED: 'booking_scheduled',
  BOOKING_CANCELLED: 'booking_cancelled',
  BOOKING_WAITING_FEEDBACK: 'booking_waiting_feedback',
  BOOKING_RESCHEDULE: 'booking_reschedule',
  BOOKING_FEEDBACK_ANSWERED: 'booking_feedback_answered',
  BOOKING_HAPPENED: 'booking_happened',
  BOOKING_NOT_HAPPENED: 'booking_not_happened',
  BOOKING_RESCHEDULED: 'booking_rescheduled',
  BOOKING_GAVE_UP_LISTING: 'booking_gave_up_listing',
  BOOKING_ISSUE_REPORTED: 'booking_issue_reported',
}

export const OWNER_FIELDS = {
  FULL_NAME: 'fullName',
  EMAIL: 'email',
  CODE: 'code',
  REGISTER: 'register',
  INBOUND_SOURCE: 'inboundSource',
  CPF_CNPJ: 'cpfCnpj',
  DATE_OF_BIRTH: 'dateOfBirth',
  RG_STATE_INSCRIPTION: 'rgStateInscription',
  ISSUING_AGENCY: 'issuingAgency',
  PHONE_NUMBER: 'phoneNumber',
  MOBILE_NUMBER: 'mobileNumber',
  RESIDENTIAL_NUMBER: 'residentialNumber',
  COMMERCIAL_NUMBER: 'commercialNumber',
  OCCUPATION: 'occupation',
  NATIONALITY: 'nationality',
  CIVIL_STATUS: 'civilStatus',
  MARRIAGE_STATUS: 'marriageStatus',
  ADDRESS: 'address',
  STREET_NUMBER: 'streetNumber',
  PICTURE: 'picture',
  COMPLEMENT: 'complement',
  BLOCK: 'block',
  NEIGHBORHOOD: 'neighborhood',
  CEP: 'cep',
  CITY: 'city',
  STATE: 'state',
  PARTNER: 'partner',
  COMMENTS: 'comments',
  WHATSAPP_ALLOWED: 'whatsappAllowed',
  EXTRA_ADDRESS_INFO: 'extraAddressInfo',
  EMERGENCY_CONTACT_NAME: 'emergencyContactName',
  EMERGENCY_CONTACT_NUMBER: 'emergencyContactPhoneNumber',
  EMERGENCY_CONTACT2_NAME: 'emergencyContact2Name',
  EMERGENCY_CONTACT2_NUMBER: 'emergencyContact2PhoneNumber',
}
export const PARTICIPANTS_INFO = {
  ...OWNER_FIELDS,
  GENDER: 'gender',
  CONSIDER_INCOME: 'considerIncome',
  INCOME: 'income',
  MONTHLY_INCOME: 'monthlyIncome',
  IS_DWELLER: 'isDweller',
  CPF: 'cpf',
  RG: 'rg',
  IS_TENANT: 'isTenant',
  IS_CONTRACT_PARTICIPANT: 'isContractParticipant',
  CURRENT_ADDRESS: 'currentAddress',
}

export const PARTICIPANTS_LABEL = {
  [PARTICIPANTS_INFO.FULL_NAME]: 'Nome',
  [PARTICIPANTS_INFO.CODE]: 'Código',
  [PARTICIPANTS_INFO.REGISTER]: 'Cadastro',
  [PARTICIPANTS_INFO.EMAIL]: 'Email',
  [PARTICIPANTS_INFO.DATE_OF_BIRTH]: 'Data de nascimento',
  [PARTICIPANTS_INFO.ISSUING_AGENCY]: 'Emissor',
  [PARTICIPANTS_INFO.PHONE_NUMBER]: 'Telefone principal',
  [PARTICIPANTS_INFO.MOBILE_NUMBER]: 'Celular',
  [PARTICIPANTS_INFO.NATIONALITY]: 'Nacionalidade',
  [PARTICIPANTS_INFO.CIVIL_STATUS]: 'Estado civil',
  [PARTICIPANTS_INFO.ADDRESS]: 'Endereço',
  [PARTICIPANTS_INFO.STREET_NUMBER]: 'Número',
  [PARTICIPANTS_INFO.COMPLEMENT]: 'Complemento',
  [PARTICIPANTS_INFO.NEIGHBORHOOD]: 'Bairro',
  [PARTICIPANTS_INFO.CEP]: 'CEP',
  [PARTICIPANTS_INFO.GENDER]: 'Gênero',
  [PARTICIPANTS_INFO.CONSIDER_INCOME]: 'Utilizará a renda para a análise de crédito?',
  [PARTICIPANTS_INFO.INCOME]: 'Renda',
  [PARTICIPANTS_INFO.IS_TENANT]: 'Irá morar no imóvel?',
  [PARTICIPANTS_INFO.CPF]: 'CPF',
  [PARTICIPANTS_INFO.RG]: 'RG',
  [PARTICIPANTS_INFO.IS_TENANT]: 'Morador',
  [PARTICIPANTS_INFO.IS_CONTRACT_PARTICIPANT]: 'Utilizará a renda para a análise de crédito?',
  [PARTICIPANTS_INFO.CURRENT_ADDRESS]: 'Endereço',
  [PARTICIPANTS_INFO.OCCUPATION]: 'Profissão',
  [PARTICIPANTS_INFO.CITY]: 'Cidade',
  [PARTICIPANTS_INFO.STATE]: 'Estado',
  [PARTICIPANTS_INFO.EXTRA_ADDRESS_INFO]: 'Complemento',
  [PARTICIPANTS_INFO.EMERGENCY_CONTACT_NAME]: 'Nome',
  [PARTICIPANTS_INFO.EMERGENCY_CONTACT2_NAME]: 'Nome',
  [PARTICIPANTS_INFO.EMERGENCY_CONTACT_NUMBER]: 'Telefone',
  [PARTICIPANTS_INFO.EMERGENCY_CONTACT2_NUMBER]: 'Telefone',
}

export const BRASIL_STATES = [
  { value: 'AC', label: 'Acre' },
  { value: 'AL', label: 'Alagoas' },
  { value: 'AP', label: 'Amapá' },
  { value: 'AM', label: 'Amazonas' },
  { value: 'BA', label: 'Bahia' },
  { value: 'CE', label: 'Ceará' },
  { value: 'DF', label: 'Distrito Federal' },
  { value: 'ES', label: 'Espírito Santo' },
  { value: 'GO', label: 'Goiás' },
  { value: 'MA', label: 'Maranhão' },
  { value: 'MT', label: 'Mato Grosso' },
  { value: 'MS', label: 'Mato Grosso do Sul' },
  { value: 'MG', label: 'Minas Gerais' },
  { value: 'PA', label: 'Pará' },
  { value: 'PB', label: 'Paraíba' },
  { value: 'PR', label: 'Paraná' },
  { value: 'PE', label: 'Pernambuco' },
  { value: 'PI', label: 'Piauí' },
  { value: 'RJ', label: 'Rio de Janeiro' },
  { value: 'RN', label: 'Rio Grande do Norte' },
  { value: 'RS', label: 'Rio Grande do Sul' },
  { value: 'RO', label: 'Rondônia' },
  { value: 'RR', label: 'Roraima' },
  { value: 'SC', label: 'Santa Catarina' },
  { value: 'SP', label: 'São Paulo' },
  { value: 'SE', label: 'Sergipe' },
  { value: 'TO', label: 'Tocantins' },
]

export const BOOKING_INFO = {
  bookingExtraInfo: ['IMPORTANTE!,Aguarde a confirmação antes de se deslocar até o imóvel!,None'],
  bookingKeyOption: 'Um consultor entrará em contato com mais informações',
}

export const BILLING_PLANS = [
  {
    id: 1,
    order: 1,
    title: 'Prata',
    fee: 6.0,
    isPercentage: true,
    exclusivity: false,
    competitor: false,
    exclusiveTag: '',
    description: '',
    list: [
      'Anúncio gratuito',
      'Seguro Incêndio',
      'Setor de Manutenção próprio',
      'Sede física',
      'Atendimento personalizado',
    ],
    feeDescription: 'taxa de administração',
  },
  {
    id: 2,
    order: 2,
    title: 'Diamante',
    fee: 10.0,
    isPercentage: true,
    exclusivity: false,
    competitor: false,
    exclusiveTag: '',
    description: '',
    list: [
      'Sem taxa de carência',
      'Anúncio gratuito',
      'Seguro Incêndio',
      'Setor de Manutenção próprio',
      'Assessoria Jurídica completa em caso de inadimplência',
      'Sede física',
      'Atendimento personalizado',
    ],
    feeDescription: 'taxa de administração',
  },
  {
    id: 3,
    order: 3,
    title: 'Plus',
    fee: 12.0,
    isPercentage: true,
    exclusivity: true,
    competitor: false,
    exclusiveTag: '',
    description: '',
    list: [
      'Anúncio gratuito',
      'Seguro Incêndio',
      'Setor de Manutenção próprio',
      'Assessoria Jurídica completa',
      'Garantia de 6 meses de aluguel e encargos',
      'Divulgação gratuita em todos os portais',
      'Isenção de 3 meses de taxa de administração',
      'Sede física',
      'Atendimento personalizado',
    ],
    feeDescription: 'taxa de administração',
  },
]

export const WARRANTIES = [
  {
    type: 'guarantor',
    enabled: true,
    description: 'Os locatários devem comprovar renda de 3x o valor do aluguel + encargos.',
    steps: [
      {
        description:
          'Locatários ou grupo de locatários precisam comprovar renda de 3x o valor do aluguel + encargos',
      },
      {
        description: 'Os fiadores precisam comprovar 4x o valor do aluguel + encargos',
      },
      {
        description:
          'Documentos solicitados: RG, CPF, Comprovante de Renda ou Extrato do Cartão de Crédito',
      },
      {
        description:
          'Se aprovado, você assina o contrato! Caso precise de algum dado ou garantia, informaremos',
      },
    ],
    documentCategories: [
      {
        id: 1,
        title: 'RG e CPF',
        description: 'Frente e verso',
        optional: true,
        participant: 'main_tenant',
        considerIncome: true,
      },
      {
        id: 1,
        title: 'RG e CPF',
        description: 'Frente e verso',
        optional: true,
        participant: 'guarantor',
        considerIncome: true,
      },
      {
        id: 6,
        title: 'Comprovante de Renda ou foto do extrato do cartão de crédito',
        description: '*Últimos 3 meses (opcional caso não tenha comprovante de renda)',
        optional: true,
        participant: 'main_tenant',
        considerIncome: true,
      },
      {
        id: 6,
        title: 'Comprovante de Renda ou foto do extrato do cartão de crédito',
        description: '*Últimos 3 meses (opcional caso não tenha comprovante de renda)',
        optional: true,
        participant: 'guarantor',
        considerIncome: true,
      },
    ],
    components: [
      {
        title: 'Os fiadores precisam',
        items: [
          {
            content: 'Os fiadores precisam comprovar 4x o valor do aluguel + encargos',
          },
          {
            content:
              'A comprovação pode ser feita pelo último contracheque ou extrato do cartão de crédito dos últimos 3 meses completos',
          },
          {
            content:
              'Há possibilidade de comprovar pelo limite do cartão de crédito que, nesse caso, deve ser de 4x o valor da locação (últimos 3 meses completos)',
          },
        ],
      },
      {
        title: 'Os locatários precisam',
        items: [
          {
            content:
              'Locatário ou grupo de locatários precisam comprovar 3x o valor do aluguel + encargos',
          },
          {
            content:
              'A comprovação pode ser feita pelo último contracheque ou extrato do cartão de crédito dos últimos 3 meses completos',
          },
          {
            content:
              'Há possibilidade de comprovar pelo limite do cartão de crédito que, nesse caso, deve ser de 4x o valor da locação (últimos 3 meses completos)',
          },
        ],
      },
    ],
    info: '',
    url: 'https://cliqueealugue.com.br/',
    tenantIncomeMultiplier: 3.0,
    guarantorIncomeMultiplier: 4.0,
    order: 2,
  },
  {
    type: 'sigafy',
    enabled: true,
    description: 'Os locatários devem comprovar renda de 3x o valor do aluguel + encargos.',
    steps: [
      {
        description:
          'Locatários ou grupo de locatários precisam comprovar 3x o valor do aluguel + encargos utilizando documentação de até 4 pessoas',
      },
      {
        description:
          'Taxa de 14% sobre o valor do aluguel + encargos para a seguradora ser sua fiadora',
      },
      {
        description:
          'Documentos solicitados: RG, CPF, Comprovante de Renda ou Extrato do Cartão de Crédito.',
      },
      {
        description:
          'Se aprovado, você assina o contrato! Caso precise de algum dado ou garantia, informaremos.',
      },
    ],
    documentCategories: [
      {
        id: 1,
        title: 'RG e CPF',
        description: 'Frente e verso',
        optional: true,
        participant: 'main_tenant',
        considerIncome: true,
      },
      {
        id: 6,
        title: 'Comprovante de Renda ou foto do extrato do cartão de crédito',
        description: '*Últimos 3 meses (opcional caso não tenha comprovante de renda)',
        optional: true,
        participant: 'main_tenant',
        considerIncome: true,
      },
      {
        id: 6,
        title: 'Comprovante de Renda ou foto do extrato do cartão de crédito',
        description: '*Últimos 3 meses (opcional caso não tenha comprovante de renda)',
        optional: true,
        participant: 'income_helper',
        considerIncome: true,
      },
      {
        id: 1,
        title: 'RG e CPF',
        description: 'Frente e verso',
        optional: true,
        participant: 'income_helper',
        considerIncome: true,
      },
      {
        id: 1,
        title: 'RG e CPF',
        description: 'Frente e verso',
        optional: true,
        participant: 'tenant',
        considerIncome: true,
      },
      {
        id: 6,
        title: 'Comprovante de Renda ou foto do extrato do cartão de crédito',
        description: '*Últimos 3 meses (opcional caso não tenha comprovante de renda)',
        optional: true,
        participant: 'tenant',
        considerIncome: true,
      },
    ],
    components: [
      {
        title: 'Você precisa (Pessoa Física):',
        items: [
          {
            content:
              'Pagar uma taxa de 14% sobre o valor do aluguel para a seguradora ser sua fiadora',
          },
          {
            content:
              'Necessário comprovar 3x o valor do aluguel + encargos utilizando documentação de até 5 pessoas',
          },
        ],
      },
    ],
    info: '',
    url: null,
    tenantIncomeMultiplier: 3.0,
    guarantorIncomeMultiplier: 0.0,
    order: 1,
  },
  {
    type: 'capitalization',
    enabled: true,
    description: 'Você investe 12x o valor do aluguel + encargos em título de capitalização',
    steps: [
      {
        description: 'Investir 12x o valor do aluguel + encargos em título de capitalização',
      },
      {
        description:
          'O dinheiro ficará retido junto a companhia em seu nome e você aluga o imóvel sem burocracia nem fiador',
      },
      {
        description: 'No fim do plano você resgata o seu dinheiro',
      },
      {
        description: 'Documentos solicitados: RG, CPF',
      },
    ],
    documentCategories: [
      {
        id: 1,
        title: 'RG e CPF',
        description: 'Frente e verso',
        optional: true,
        participant: 'main_tenant',
        considerIncome: true,
      },
      {
        id: 1,
        title: 'RG e CPF',
        description: 'Frente e verso',
        optional: true,
        participant: 'income_helper',
        considerIncome: true,
      },
    ],
    components: [
      {
        title: 'Você precisa:',
        items: [
          {
            content: 'Investir 12x o valor do aluguel + encargos em título de capitalização',
          },
          {
            content:
              'O dinheiro ficará retido junto a companhia em seu nome e você aluga o imóvel sem burocracia nem fiador',
          },
          {
            content: 'No fim do plano você resgata o seu dinheiro',
          },
        ],
      },
    ],
    info: '',
    url: null,
    tenantIncomeMultiplier: 0.0,
    guarantorIncomeMultiplier: 0.0,
    order: 3,
  },
  {
    type: 'credpago',
    enabled: true,
    description: 'Comprove renda a partir do extrato do cartão de crédito',
    steps: [
      {
        description:
          'Locatários ou grupo de locatários precisam comprovar renda que totalize 7x o valor do aluguel + encargos em até 3 cartões de crédito diferentes',
      },
      {
        description: 'Taxa de serviço de 10% em cima do valor do aluguel + encargos',
      },
      {
        description:
          'É cobrada uma taxa de setup de R$ 120,00 que pode ser parcelada em até 3x no cartão de crédito',
      },
      {
        description:
          'Documentos solicitados: RG, CPF, Comprovante de Renda ou Extrato do Cartão de Crédito',
      },
      {
        description:
          'Se aprovado, você assina o contrato! Caso precise de algum dado ou garantia, informaremos',
      },
    ],
    documentCategories: [
      {
        id: 1,
        title: 'RG e CPF',
        description: 'Frente e verso',
        optional: true,
        participant: 'main_tenant',
        considerIncome: true,
      },
      {
        id: 1,
        title: 'RG e CPF',
        description: 'Frente e verso',
        optional: true,
        participant: 'income_helper',
        considerIncome: true,
      },
      {
        id: 1,
        title: 'RG e CPF',
        description: 'Frente e verso',
        optional: true,
        participant: 'tenant',
        considerIncome: true,
      },
      {
        id: 8,
        title: 'Foto do cartão de crédito utilizado para a comprovação de renda',
        description: '',
        optional: false,
        participant: 'main_tenant',
        considerIncome: true,
      },
      {
        id: 8,
        title: 'Foto do cartão de crédito utilizado para a comprovação de renda',
        description: '',
        optional: false,
        participant: 'income_helper',
        considerIncome: true,
      },
      {
        id: 8,
        title: 'Foto do cartão de crédito utilizado para a comprovação de renda',
        description: '',
        optional: false,
        participant: 'tenant',
        considerIncome: true,
      },
      {
        id: 7,
        title: 'Foto do extrato do cartão de crédito do último mês',
        description: '',
        optional: true,
        participant: 'main_tenant',
        considerIncome: true,
      },
      {
        id: 7,
        title: 'Foto do extrato do cartão de crédito do último mês',
        description: '',
        optional: true,
        participant: 'income_helper',
        considerIncome: true,
      },
      {
        id: 7,
        title: 'Foto do extrato do cartão de crédito do último mês',
        description: '',
        optional: true,
        participant: 'tenant',
        considerIncome: true,
      },
    ],
    components: [
      {
        title: 'Você precisa:',
        items: [
          {
            content:
              'Comprovar renda que totalize 7x o valor do aluguel + encargos em até 3 cartões de crédito diferentes',
          },
          {
            content: 'Pagamento de taxa de serviço de 10% em cima do valor do aluguel + encargos',
          },
          {
            content:
              'É cobrada uma taxa de setup de R$ 120,00 que pode ser parcelada em até 3x no cartão de crédito',
          },
        ],
      },
    ],
    info: '',
    url: null,
    tenantIncomeMultiplier: 7.0,
    guarantorIncomeMultiplier: 0.0,
    order: 4,
  },
  {
    type: 'student',
    enabled: true,
    description: 'Com a apresentação do atestado de matrícula, parentes podem ser fiadores',
    steps: [
      {
        description:
          'Com a apresentação do atestado de matrícula, você estará isento da comprovação de renda',
      },
      {
        description: 'Fiadores precisam ser parentes diretos do estudante',
      },
      {
        description:
          'Fiadores devem comprovar renda de 4x o valor do aluguel + encargos com os mesmos documentos citados',
      },
      {
        description:
          'Fiadores também podem comprovar renda com o limite de 5x o valor do aluguel + encargos no cartão de crédito',
      },
      {
        description:
          'Documentos solicitados: RG, CPF, Comprovante de Renda ou Extrato do Cartão de Crédito',
      },
      {
        description:
          'Se aprovado, você assina o contrato! Caso precise de algum dado ou garantia, informaremos',
      },
    ],
    documentCategories: [
      {
        id: 1,
        title: 'RG e CPF',
        description: 'Frente e verso',
        optional: true,
        participant: 'main_tenant',
        considerIncome: true,
      },
      {
        id: 1,
        title: 'RG e CPF',
        description: 'Frente e verso',
        optional: true,
        participant: 'income_helper',
        considerIncome: true,
      },
      {
        id: 1,
        title: 'RG e CPF',
        description: 'Frente e verso',
        optional: true,
        participant: 'guarantor',
        considerIncome: true,
      },
      {
        id: 6,
        title: 'Comprovante de Renda ou foto do extrato do cartão de crédito',
        description: '*Últimos 3 meses (opcional caso não tenha comprovante de renda)',
        optional: true,
        participant: 'main_tenant',
        considerIncome: true,
      },
      {
        id: 6,
        title: 'Comprovante de Renda ou foto do extrato do cartão de crédito',
        description: '*Últimos 3 meses (opcional caso não tenha comprovante de renda)',
        optional: true,
        participant: 'income_helper',
        considerIncome: true,
      },
      {
        id: 6,
        title: 'Comprovante de Renda ou foto do extrato do cartão de crédito',
        description: '*Últimos 3 meses (opcional caso não tenha comprovante de renda)',
        optional: true,
        participant: 'guarantor',
        considerIncome: true,
      },
    ],
    components: [
      {
        title: 'Você precisa:',
        items: [
          {
            content:
              'Com a apresentação do atestado de matrícula, você estará isento da comprovação de renda',
          },
          {
            content: 'Fiadores precisam ser parentes diretos do estudante',
          },
          {
            content:
              'Fiadores devem comprovar renda de 4x o valor do aluguel + encargos com os mesmos documentos citados',
          },
          {
            content:
              'Fiadores também podem comprovar renda com o limite de 5x o valor do aluguel + encargos no cartão de crédito',
          },
        ],
      },
    ],
    info: '',
    url: null,
    tenantIncomeMultiplier: 0.0,
    guarantorIncomeMultiplier: 4.0,
    order: 5,
  },
]

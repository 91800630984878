import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Button, { ButtonVariant } from '_components/button'

import styles from './styles.css'

const ActionsSection = ({
  className,
  photosActive,
  mapsActive,
  streetActive,
  onPhotosClick,
  onMapsClick,
  onStreetClick,
  hasVideo,
  videoActive,
  onVideoClick,
}) => (
  <div
    className={classnames(className, styles['actions-section'], {
      [styles['have-video']]: hasVideo,
    })}
  >
    {hasVideo !== null && hasVideo !== '' && (
      <Button active={videoActive} onClick={onVideoClick} variant={ButtonVariant.BOLD}>
        VIDEO
      </Button>
    )}
    <Button active={photosActive} onClick={onPhotosClick} variant={ButtonVariant.BOLD}>
      FOTOS
    </Button>
    <Button active={mapsActive} onClick={onMapsClick} variant={ButtonVariant.BOLD}>
      MAPA
    </Button>
    <Button active={streetActive} onClick={onStreetClick} variant={ButtonVariant.BOLD}>
      RUA
    </Button>
  </div>
)

ActionsSection.propTypes = {
  className: PropTypes.string,
  photosActive: PropTypes.bool,
  mapsActive: PropTypes.bool,
  streetActive: PropTypes.bool,
  onPhotosClick: PropTypes.func.isRequired,
  onMapsClick: PropTypes.func.isRequired,
  onStreetClick: PropTypes.func.isRequired,
  hasVideo: PropTypes.bool.isRequired,
  videoActive: PropTypes.bool,
  onVideoClick: PropTypes.func,
}

ActionsSection.defaultProps = {
  className: '',
  videoActive: false,
  photosActive: true,
  mapsActive: false,
  streetActive: false,
  onVideoClick: () => {},
}

export default ActionsSection

import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Grid, Typography } from '@material-ui/core'
import { useSelector } from 'react-redux'
import Slider from 'react-slick'

import { TABLET } from '_config/media-queries'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { getUserLikedProperties } from '_modules/user/selectors'
import { primaryColor } from '_utils/colors'
import ListingCard from '_views/recommendations/listing-card'
import { Property } from '_models'
import PrevArrow from '_views/property/related-section/prev-arrow'
import NextArrow from '_views/property/related-section/next-arrow'

import useStyles from './styles'

const RelatedSection = ({ className, title, properties }) => {
  const styles = useStyles()

  const listLikedProperties = useSelector(getUserLikedProperties)

  const settings = useMemo(
    () => ({
      dots: false,
      infinite: false,
      speed: 100,
      slidesToShow: 3,
      prevArrow: <PrevArrow />,
      nextArrow: <NextArrow />,
      className: styles.slider,
      responsive: [
        {
          breakpoint: TABLET,
          settings: {
            draggable: true,
            arrows: false,
            slidesToShow: 1,
          },
        },
      ],
    }),
    [styles.slider]
  )

  const renderListingCards = useMemo(
    () =>
      properties.map(property => (
        <ListingCard
          key={property.externalId}
          info={property}
          primaryColor={primaryColor}
          listLikedProperties={listLikedProperties}
          className={styles.card}
          hideScheduleButton
        />
      )),
    [listLikedProperties, properties, styles.card]
  )

  return (
    <Grid className={className}>
      <Typography component="h3" variant="h2" color="textPrimary" className={styles.title}>
        {title}
      </Typography>
      <Slider {...settings}>{renderListingCards}</Slider>
    </Grid>
  )
}

RelatedSection.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  properties: ImmutablePropTypes.listOf(PropTypes.instanceOf(Property)).isRequired,
}

RelatedSection.defaultProps = {
  className: '',
  title: '',
}

export default RelatedSection

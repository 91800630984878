import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { primaryColor, primaryTextColor } from '_utils/colors'

import styles from './styles.css'

export const cardTheme = {
  DEFAULT: 'default',
  NO_BACKGROUND: 'no-background',
}

const FeatureCard = ({
  icon,
  text,
  name,
  active,
  onClick,
  theme,
  tabIndex,
  haveSvgFill,
  className,
}) => {
  const svgStyle = useMemo(() => {
    if (haveSvgFill) {
      return { fill: primaryColor }
    }

    if (active) {
      return { fill: primaryTextColor }
    }

    return {}
  }, [active, haveSvgFill])

  const onMouseDown = useCallback(event => {
    event.preventDefault()
  }, [])

  return (
    <button
      className={classnames(styles.card, styles[theme], { [styles.active]: active }, className)}
      onClick={onClick}
      name={name}
      tabIndex={tabIndex}
      onMouseDown={onMouseDown}
      style={active ? { color: primaryTextColor, backgroundColor: primaryColor } : {}}
      type="button"
    >
      <svg aria-hidden="true" style={svgStyle}>
        <use xlinkHref={icon} />
      </svg>
      <p style={active ? { color: primaryTextColor, fontWeight: 500 } : {}}>{text}</p>
    </button>
  )
}

FeatureCard.propTypes = {
  icon: PropTypes.string,
  text: PropTypes.string,
  name: PropTypes.string,
  active: PropTypes.bool,
  onClick: PropTypes.func,
  theme: PropTypes.oneOf(Object.keys(cardTheme).map(key => cardTheme[key])),
  tabIndex: PropTypes.number,
  haveSvgFill: PropTypes.bool,
  className: PropTypes.string,
}

FeatureCard.defaultProps = {
  icon: '',
  text: '',
  name: '',
  active: false,
  onClick: () => {},
  theme: cardTheme.DEFAULT,
  tabIndex: 0,
  haveSvgFill: false,
  className: '',
}

export default React.memo(FeatureCard)

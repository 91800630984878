import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ palette }) => ({
  select: {
    height: props => `${props.height}rem`,
    width: '100%',
    backgroundColor: 'white',
    '& .MuiSelect-selectMenu': {
      height: 'auto',
    },
    '& .MuiOutlinedInput-input': {
      padding: '1rem 1.4rem',
    },
  },
  control: {
    '& .MuiFormHelperText-root': {
      fontSize: '1.2rem',
    },
    '& input': {
      height: props => `${props.height}rem`,
      color: palette.text.secondary,
      padding: '0 1.4rem',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(1.4rem, -0.6rem) scale(0.857)',
    },
    '& legend': {
      fontSize: '1.2rem',
    },
    '& label': {
      height: props => `${props.height}rem`,
      fontSize: '1.4rem',
      top: '-0.35rem',
      opacity: 0.8,
    },
    "& label[data-shrink='true']": {
      top: 0,
    },
    '& .MuiSelect-root': {
      color: palette.text.secondary,
      marginTop: '0.6rem',
      fontSize: '1.4rem',
    },
  },
}))

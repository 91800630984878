import React, { useCallback, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import NumberFormat from 'react-number-format'

const NumberCurrency = props => {
  const { inputRef, onChange, name, id, value, ...other } = props
  const [textFieldValue, setTextField] = useState(value * 100)

  const onValueChange = useCallback(
    values => {
      if (values.value === '') {
        setTextField(0)
        onChange({
          target: {
            value: 0,
            name,
            id,
          },
        })
        return
      }
      setTextField(values.value)
      onChange({
        target: {
          value: values.floatValue / 100,
          name,
          id,
        },
      })
    },
    [id, name, onChange]
  )

  const currencyFormatter = useCallback(format => {
    const amount = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' })
      .format(format / 100)
      .replace('R$', '')

    return `${amount}`
  }, [])

  useEffect(() => {
    if (value) {
      setTextField(value * 100)
    }
  }, [textFieldValue, value])

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={onValueChange}
      allowNegative={false}
      format={currencyFormatter}
      value={textFieldValue}
    />
  )
}

NumberCurrency.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

NumberCurrency.defaultProps = {
  name: '',
  id: '',
  value: undefined,
}

export default NumberCurrency

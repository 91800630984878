import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Link } from '@reach/router'

import { primaryColor } from '_utils/colors'

import styles from './styles.css'

export const TagColor = {
  GRAY: 'gray',
  TRANSPARENT: 'transparent',
  DEFAULT: 'default',
}

export const TagVariant = {
  NORMAL: 'normal',
  BOLD: 'bold',
  SMALL: 'small',
}

class Tag extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    icon: PropTypes.string,
    iconOptions: PropTypes.shape({
      position: PropTypes.string,
    }),
    text: PropTypes.string,
    color: PropTypes.oneOf(Object.keys(TagColor).map(key => TagColor[key])),
    variant: PropTypes.oneOf(Object.keys(TagVariant).map(key => TagVariant[key])),
    onClick: PropTypes.func,
    to: PropTypes.string,
  }

  static defaultProps = {
    className: '',
    icon: '',
    iconOptions: { position: 'left' },
    text: '',
    color: TagColor.DEFAULT,
    variant: TagVariant.NORMAL,
    onClick: () => {},
    to: null,
  }

  getStyle = () => {
    const { color } = this.props

    if (color === TagColor.TRANSPARENT) {
      return {
        color: primaryColor,
      }
    }

    return {}
  }

  getSvgStyle = () => {
    const { color } = this.props

    if (color === TagColor.TRANSPARENT)
      return {
        fill: primaryColor,
      }

    return {}
  }

  render() {
    const { className, icon, text, color, variant, onClick } = this.props

    const { iconOptions } = this.props
    const renderIcon = () => (
      <Fragment>
        {icon !== '' && (
          <svg aria-hidden="true" style={this.getSvgStyle()}>
            <use xlinkHref={icon} />
          </svg>
        )}
      </Fragment>
    )
    const renderContent = () => (
      <div className={styles.wrapper}>
        {icon && iconOptions.position === 'left' && renderIcon()}
        <p>{text}</p>
        {icon && iconOptions.position === 'right' && renderIcon()}
      </div>
    )

    if (this.props.to) {
      // The page needs to reload so the server can render the AMP view
      if (this.props.to.includes('/imovel/')) {
        return (
          <a
            target="_blank"
            rel="noopener noreferrer"
            className={classnames(styles.tag, styles[color], styles[variant], className)}
            style={this.getStyle()}
            href={this.props.to}
          >
            {renderContent()}
          </a>
        )
      }
      return (
        <Link
          className={classnames(styles.tag, styles[color], styles[variant], className)}
          style={this.getStyle()}
          to={this.props.to}
        >
          {renderContent()}
        </Link>
      )
    }

    return (
      <button
        className={classnames(styles.tag, styles[color], styles[variant], className)}
        onClick={onClick}
        style={this.getStyle()}
        tabIndex={-1}
        type="button"
      >
        {renderContent()}
      </button>
    )
  }
}

export default Tag

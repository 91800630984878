import { Map, List } from 'immutable'

// INITIAL STATE
export const INITIAL_STATE = Map({})

// ACTIONS
export const ADD_DOCUMENTS = 'add-documents'
export const REMOVE_DOCUMENT = 'remove-document'
export const UPDATE_DOCUMENT = 'update-document'

// HELPER
const toListOfFiles = files =>
  files.reduce(
    (accumulator, current) =>
      accumulator.push(
        Map({
          file: current,
          percentage: 0,
          isUploading: false,
        })
      ),
    List()
  )

// REDUCER
export const reducer = (state, action) => {
  const { type, payload } = action
  const category = payload.documentCategory.toString()

  switch (type) {
    case ADD_DOCUMENTS: {
      if (!state.get(category)) {
        const newState = state.set(category, List())
        return newState.update(category, files => files.concat(toListOfFiles(payload.files)))
      }
      return state.update(category, files => files.concat(toListOfFiles(payload.files)))
    }
    case REMOVE_DOCUMENT: {
      return state.deleteIn([category, payload.id])
    }
    case UPDATE_DOCUMENT: {
      return state.updateIn([category, payload.id], document =>
        document
          .set('percentage', payload.percentage)
          .set('isUploading', payload.isUploading)
          .set('hasError', payload.hasError)
      )
    }
    default:
      return state
  }
}

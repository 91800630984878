export const PROPERTY_MARKER = primaryColor => `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 56" width="50" height="56">
      <defs>
          <style>
              .cls-1{fill:${primaryColor};stroke:#fff;stroke-width:3px}.cls-2{filter:url(#map-marker)}
          </style>
          <filter id="map-marker" width="50" height="56" x="0" y="0" filterUnits="userSpaceOnUse">
              <feOffset dy="3"/>
              <feGaussianBlur result="blur" stdDeviation="3"/>
              <feFlood flood-opacity=".161"/>
              <feComposite in2="blur" operator="in"/>
              <feComposite in="SourceGraphic"/>
          </filter>
      </defs>
      <g class="cls-2">
          <path id="map-marker-2" d="M8.374 23.516C1.311 13.642 0 12.629 0 9a9.171 9.171 0 0 1 9.333-9 9.171 9.171 0 0 1 9.333 9c0 3.629-1.311 4.642-8.374 14.516a1.192 1.192 0 0 1-1.918 0z" class="cls-1" data-name="map-marker" transform="translate(10.5 7.5)"/>
      </g>
  </svg>`

import React from 'react'
import PropTypes from 'prop-types'
import AriaModal from 'react-aria-modal'

import LikeIcon from '_assets/images/ic-gostei.svg'
import CloseIcon from '_assets/images/ic-fechar.svg'
import Button, { ButtonColor, ButtonVariant } from '_components/button'

import styles from './styles.css'

const onMouseDown = event => {
  event.preventDefault()
}

const LikeModal = ({ closeModal, mainAction, primaryColor }) => (
  <AriaModal titleText="Agendamento de visita" underlayClass={styles.underlay}>
    <div className={styles.modal}>
      <button className={styles.close} onClick={closeModal} onMouseDown={onMouseDown} type="button">
        <svg alt="close" className={styles.image}>
          <use xlinkHref={CloseIcon} />
        </svg>
      </button>
      <svg aria-hidden="true" className={styles.icon} style={{ fill: primaryColor }}>
        <use xlinkHref={LikeIcon} />
      </svg>
      <h3 className={styles.title} style={{ color: primaryColor }}>
        Gostei
      </h3>
      <p className={styles.info}>Você favoritou seu primeiro imóvel!</p>
      <Button
        color={ButtonColor.PRIMARY}
        variant={ButtonVariant.BOLD}
        onClick={mainAction}
        className={styles.acknowledge}
      >
        ENTENDI
      </Button>
    </div>
  </AriaModal>
)

LikeModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  mainAction: PropTypes.func.isRequired,
  primaryColor: PropTypes.string.isRequired,
}

export default LikeModal

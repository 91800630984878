import React, { useMemo, useEffect, Fragment } from 'react'
import { useNavigate, useLocation, useParams } from '@reach/router'
import { useDispatch, useSelector } from 'react-redux'
import qs from 'qs'

import withAuthOrIncomeHelper from '_hocs/with-auth-or-income-helper'
import CheckIcon from '_assets/images/ic-check-prim-ria.svg'
import { getLease } from '_modules/leases/actions'
import { getLeaseById, checkIsLoadingLease } from '_modules/leases/selectors'
import Building from '_components/building-animated'
import Button from '_components/material/button'
import { getLeaseStatus } from '_utils/lease'
import { LEASE_STATUS } from '_utils/constants'
import { primaryColor } from '_utils/colors'

import styles from './styles.css'

export const CONTRACT_ANTICIPATION_TIPS = {
  surveyTip: 'É preciso fazer a contestação da vistoria num prazo máximo de 72h.',
  vacancyTip: 'O condomínio vence todo dia 10 do mês e o aluguel vence no 5º dia útil.',
  expiredDateTip: 'Caso não receba o boleto, entre em contato com inovacao@brognoli.com.br',
  dutiesAndRightsTip:
    'Para a desocupação do imóvel é preciso de um aviso prévio de no mínimo 30 dias.',
  slipsTip: 'O aluguel, condomínio e despesas extraordinárias são deveres do locatário.',
  rentRulesTip: 'O aluguel é do mês subsequente (5 de Fevereiro é referente ao mês de Janeiro).',
}

const ContractPreview = () => {
  const { leaseId, contractId } = useParams()
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()

  const lease = useSelector(state => getLeaseById(state, leaseId))
  const leaseStatus = lease && getLeaseStatus(lease.getIn(['status']))
  const isLoadingLease = useSelector(checkIsLoadingLease)
  const createSVGStyle = useMemo(() => ({ fill: primaryColor }), [])
  const parsedQuery = useMemo(() => qs.parse((location.search || '').split('?').pop()), [
    location.search,
  ])

  const renderLink = useMemo(() => {
    if (parsedQuery && parsedQuery.verification_token) {
      return `/minhas-negociacoes/${leaseId}/contrato/${contractId}/revisao`
    }
    return `/minhas-negociacoes/${leaseId}/contrato/${contractId}/revisao${location.search}`
  }, [contractId, leaseId, location.search, parsedQuery])

  const cardContent = useMemo(() => {
    const content = []
    if (CONTRACT_ANTICIPATION_TIPS.surveyTip) {
      content.push({
        value: CONTRACT_ANTICIPATION_TIPS.surveyTip,
        label: 'Vistoria',
      })
    }
    if (CONTRACT_ANTICIPATION_TIPS.vacancyTip) {
      content.push({
        value: CONTRACT_ANTICIPATION_TIPS.vacancyTip,
        label: 'datas de vencimento',
      })
    }
    if (CONTRACT_ANTICIPATION_TIPS.expiredDateTip) {
      content.push({
        value: CONTRACT_ANTICIPATION_TIPS.expiredDateTip,
        label: 'boletos',
      })
    }
    if (CONTRACT_ANTICIPATION_TIPS.dutiesAndRightsTip) {
      content.push({
        value: CONTRACT_ANTICIPATION_TIPS.dutiesAndRightsTip,
        label: 'Desocupação',
      })
    }
    if (CONTRACT_ANTICIPATION_TIPS.slipsTip) {
      content.push({
        value: CONTRACT_ANTICIPATION_TIPS.slipsTip,
        label: 'deveres e direitos',
      })
    }
    if (CONTRACT_ANTICIPATION_TIPS.rentRulesTip) {
      content.push({
        value: CONTRACT_ANTICIPATION_TIPS.rentRulesTip,
        label: 'regras do aluguel',
      })
    }
    return content
  }, [])

  useEffect(() => {
    if (!lease.id) {
      const params = {}
      if (parsedQuery.token) {
        params.token = parsedQuery.token
      }
      if (leaseId) {
        dispatch(getLease(leaseId, params))
      }
    }
  }, [dispatch, lease, leaseId, parsedQuery.token])

  useEffect(() => {
    if (leaseStatus === LEASE_STATUS.CONTRACT_IN_REVIEW) {
      navigate(`/contrato/alteracao`)
    }
  })

  return (
    <div className={styles.main}>
      <div className={styles.content}>
        {isLoadingLease || !lease ? (
          <div className={styles.overlay}>
            <Building className={styles.building} />
          </div>
        ) : (
          <Fragment>
            <h1>Antecipação do contrato</h1>
            <div className={styles['cards-container']}>
              {cardContent.map(content => (
                <div className={styles.card} key={content.label}>
                  <svg aria-hidden="true" className={styles['card-icon']} style={createSVGStyle}>
                    <use xlinkHref={CheckIcon} />
                  </svg>
                  <p className={styles['card-title']}>{content.label}</p>
                  <p className={styles['card-text']}>{content.value}</p>
                </div>
              ))}
            </div>
            <Button variant="contained" className={styles.button} to={renderLink}>
              VER CONTRATO
            </Button>
          </Fragment>
        )}
      </div>
    </div>
  )
}

export default withAuthOrIncomeHelper(React.memo(ContractPreview))

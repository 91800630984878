import { makeStyles, withStyles } from '@material-ui/styles'
import { TextField } from '@material-ui/core'

export default makeStyles(({ palette, breakpoints }) => ({
  dialogPaper: {
    minHeight: '100%',
    maxHeight: '100%',
    margin: 0,
  },
  modal: {
    [breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0.8rem 1.6rem',
  },
  title: {
    fontSize: '2rem',
    color: palette.secondary.main,
    fontWeight: '500',
  },
  content: {
    display: 'grid',
    gridTemplateRows: 'auto',
    gridGap: '0.8rem',
    padding: '0 5rem 2rem',

    [breakpoints.down('sm')]: {
      padding: '0 2.4rem',
    },
  },
  label: {
    fontSize: '1.6rem',
    margin: '3.2rem 0 0.8rem',
    fontWeight: '500',
  },

  formControlLabel: {
    fontSize: '1.4rem',
  },
  formControlLabelSmall: {
    fontSize: '1.2rem',
  },
  listing: {
    display: 'flex',
    flexDirection: 'column',
  },
  group: {
    display: 'grid',
    gridTemplateColumns: 'repeat(5, auto)',
    gridGap: '1.6rem',
    marginTop: '0.8rem',

    [breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(4, auto)',
    },
  },
  pets: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, auto)',
    gridGap: '1.6rem',
    margin: '0.8rem 0 3rem',
  },
  furnished: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, auto)',
    gridGap: '1.6rem',
    marginTop: '0.8rem',
    [breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(3, auto)',
      gridTemplateRows: 'auto auto',
    },
  },
  button: {
    textTransform: 'uppercase',
    backgroundColor: palette.gray.main,
    border: `0.1rem solid ${palette.divider}`,
    fontWeight: 'normal',
    '&:hover': {
      backgroundColor: palette.primary.main,
      border: `0.1rem solid ${palette.divider}`,
      color: 'white',
    },
  },
  active: {
    backgroundColor: palette.primary.main,
    border: `0.1rem solid ${palette.divider}`,
    color: 'white',
    fontWeight: 'bold',
  },
  input: {
    width: '18rem',
    marginRight: '1rem',
  },
  currency: {
    height: '4.8rem',
    '& .MuiTypography-body1': {
      fontSize: '1.6rem',
      fontWeight: 'bold',
    },
  },
  multipleSelect: {
    '& .MuiSelect-root ': {
      boxSizing: 'border-box',
      padding: '1.6rem 0',
    },
    '& .MuiSelect-icon': {
      display: 'none',
    },
  },
  root: {
    height: '4rem',
    [breakpoints.down('md')]: {
      height: '4.8rem',
    },
  },
  formControlLabelCheckbox: {
    [breakpoints.down('md')]: {
      marginTop: '1.5rem',
    },
  },
  twoColumns: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: 'auto',
    [breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
  residential: {
    height: '4rem',
    marginBottom: '1rem',
  },
  allValues: {
    marginBottom: '0.6rem',
  },
  space: {
    width: '4rem',
    color: 'white',
  },
}))

export const StyledTextField = withStyles(({ breakpoints }) => ({
  root: {
    '& .MuiOutlinedInput-root': {
      height: '4rem',

      [breakpoints.down('md')]: {
        height: '4.8rem',
      },
    },
    '& .MuiOutlinedInput-input': {
      padding: '1.6rem',
      boxSizing: 'border-box',
    },
  },
}))(TextField)

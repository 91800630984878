import React, { useMemo, useState, useCallback } from 'react'
import { useLocation, Redirect, navigate } from '@reach/router'
import { Grid } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import TokenContext from '_context/token'
import Building from '_components/building-animated'
import useGetLease from '_hooks/use-get-lease'
import Snackbar from '_components/snackbar'
import { PARTICIPANTS_TYPE, WARRANTY_TYPES } from '_utils/constants'
import { getLeaseById } from '_modules/leases/selectors'
import { getIncomeHelper } from '_modules/income-helper/selectors'
import useOnSuccessCall from '_hooks/use-on-success'
import { CANCEL_LEASE } from '_modules/leases/actions'
import withAuth from '_hocs/with-auth'

import useStyles from './styles'

export const CREDIT_ANALYSIS_ROUTE = '/analise-credito/'

export const CREDIT_ANALYSIS_ROUTES = {
  ADD_GUARANTOR_DATA: '/adicionar-fiadores',
  ADD_GUARANTOR: '/adicionar-fiador',
  ADD_INCOME_HELPER: '/adicionar-responsaveis',
  ADD_TENANT: '/adicionar-morador',
  AVAILABLE: '/disponivel',
  CLOSED_LEASE: '/analise-encerrada',
  GUARANTORS: '/fiadores',
  INCOME_HELPERS: '/responsaveis',
  INVITE_GUARANTOR: '/convidar-fiador',
  INVITE_INCOME_HELPER: '/convidar-responsaveis',
  INVITE_TENANT: '/convidar-morador',
  MONTHLY_INCOME: '/renda',
  NEED_MORE_DATA: '/mais-dados',
  NEW_WARRANTY: '/nova-garantia',
  READY: '/pronto',
  REGISTER_DOCUMENTS: '/registrar-documentos-participante',
  REGISTER_INFORMATION: '/registrar-informacoes-participante',
  REGISTER_SPOUSE: '/registrar-informacoes-conjuge',
  START: '/inicio',
  STATUS: '/status',
  TENANTS: '/moradores',
  UPDATED: '/analise-atualizada',
}

const TOKEN_ROUTES = [
  CREDIT_ANALYSIS_ROUTES.REGISTER_DOCUMENTS,
  CREDIT_ANALYSIS_ROUTES.REGISTER_INFORMATION,
  CREDIT_ANALYSIS_ROUTES.REGISTER_SPOUSE,
  CREDIT_ANALYSIS_ROUTES.NEED_MORE_DATA,
  CREDIT_ANALYSIS_ROUTES.MONTHLY_INCOME,
]

const INCOME_HELPER_ROUTES = [
  CREDIT_ANALYSIS_ROUTES.INVITE_INCOME_HELPER,
  CREDIT_ANALYSIS_ROUTES.INCOME_HELPERS,
  CREDIT_ANALYSIS_ROUTES.ADD_INCOME_HELPER,
]

const GUARANTOR_ROUTES = [
  CREDIT_ANALYSIS_ROUTES.ADD_GUARANTOR,
  CREDIT_ANALYSIS_ROUTES.ADD_GUARANTOR_DATA,
  CREDIT_ANALYSIS_ROUTES.INVITE_GUARANTOR,
  CREDIT_ANALYSIS_ROUTES.GUARANTORS,
]

export const creditAnalysisFlow = (route, leaseId, token = '') =>
  `${CREDIT_ANALYSIS_ROUTE + leaseId + route}${token ? `?token=${token}` : ``}`

const CreditAnalysisFlow = ({ children }) => {
  const location = useLocation()
  const styles = useStyles()
  const [currentParticipant, setParticipant] = useState()
  const leaseId = location.pathname.split('/')[2]
  const pathname = `/${location.pathname.split('/')[3]}`
  const token = useMemo(
    () =>
      location.search.includes('?token=') || location.search.includes('&token=')
        ? location.search.match(/[?&]token=([^&#]*)/)[1]
        : undefined,
    [location.search]
  )
  const locationState = location.state
  const [isLoading] = useGetLease(leaseId, token)
  const lease = useSelector(state => getLeaseById(state, leaseId))
  const isCredpagoFlow = lease.warranty === WARRANTY_TYPES.CREDPAGO
  const participantLease = useSelector(getIncomeHelper)
  const activeLease = token ? participantLease : lease
  /*
    This memoized side effect change the "participantType"
    const for all subforms that decide which route to navigate
    based in this value
  */
  const participantType = useMemo(() => {
    if (INCOME_HELPER_ROUTES.includes(pathname)) {
      return PARTICIPANTS_TYPE.INCOME_HELPER
    }
    if (GUARANTOR_ROUTES.includes(pathname)) {
      return PARTICIPANTS_TYPE.GUARANTOR
    }
    return PARTICIPANTS_TYPE.TENANT
  }, [pathname])

  const handleClosedLease = useCallback(() => {
    navigate(creditAnalysisFlow(CREDIT_ANALYSIS_ROUTES.CLOSED_LEASE, leaseId))
  }, [leaseId])

  useOnSuccessCall(CANCEL_LEASE.ACTION, handleClosedLease)

  if (isLoading || !activeLease.id) {
    return (
      <Grid container className={styles.loading} justify="center" alignItems="center">
        <Building isSmall />
      </Grid>
    )
  }

  if (token && !TOKEN_ROUTES.includes(pathname)) {
    return <Redirect to="/" noThrow />
  }

  return (
    <TokenContext.Provider
      value={{
        token,
        leaseId,
        pathname,
        participantType,
        locationState,
        currentParticipant,
        setParticipant,
        isCredpagoFlow,
      }}
    >
      {children}
      <Snackbar />
    </TokenContext.Provider>
  )
}

CreditAnalysisFlow.propTypes = {
  children: PropTypes.node.isRequired,
}

export default withAuth(React.memo(CreditAnalysisFlow))

import React from 'react'

import SavedIcon from '_assets/images/saved.svg'
import Button, { ButtonColor, ButtonFormat, ButtonVariant } from '_components/button'
import { GENERAL } from '_utils/constants'

import styles from './styles.css'

const HelperInvited = () => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <svg alt="Pronto" className={styles.icon}>
          <use xlinkHref={SavedIcon} />
        </svg>

        <h2 className={styles.title}>Pronto, agora é só esperar</h2>

        <p className={styles.description}>
          Você poderá conferir o resultado no menu em <strong>Negociações</strong>
        </p>

        <Button
          color={ButtonColor.PRIMARY}
          format={ButtonFormat.ROUNDED}
          variant={ButtonVariant.BOLD}
          className={styles.button}
          to="/minhas-negociacoes"
        >
          ENTENDI
        </Button>
      </div>

      <div className={styles.footer}>
        <p>Em caso de dúvidas, entre em contato</p>
        <a className={styles.link} href={`mailto:${GENERAL.email}`}>
          {GENERAL.email}
        </a>
      </div>
    </div>
  )
}

export default React.memo(HelperInvited)

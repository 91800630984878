const MOBILE = 480
const TABLET = 1024

module.exports = {
  SM_VIEWPORT: `(min-width:160px) and (max-width:${MOBILE}px)`,
  MD_VIEWPORT: `(min-width:${MOBILE + 1}px) and (max-width:${TABLET - 1}px)`,
  LG_VIEWPORT: `(min-width:${TABLET}px)`,
  RETINA_DISPLAY: '(-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi)',
  MOBILE,
  TABLET,
}

import React from 'react'

import { GENERAL } from '_utils/constants'

import styles from './styles.css'

const PlansContact = () => (
  <section className={styles.contact}>
    <h1 className={styles.title}>Dúvidas sobre os planos?</h1>
    <p className={styles.body}>
      Envie um email para{' '}
      <a href={`mailto: ${GENERAL.email}`} className={styles.email}>
        {GENERAL.email}
      </a>
    </p>
  </section>
)

export default React.memo(PlansContact)

import React, { useMemo } from 'react'
import { Grid, TextField as MUITextField, InputAdornment } from '@material-ui/core'
import InputMask from 'react-input-mask'
import PropTypes from 'prop-types'

import NumberFormat from '_components/number-format/currency'

import useStyles from './styles'

export const TEXTFIELD_MASK = {
  CEP: '99999-999',
  PHONE_NUMBER: '+55 (99) 9.9999-9999',
  DATE: '99/99/9999',
  CPF: '999.999.999-99',
}

const TextField = ({
  mask,
  value,
  onChange,
  height,
  endAdornment,
  helperText,
  disabled,
  isCurrency,
  ...otherProps
}) => {
  const styles = useStyles({ height })

  const inputProps = useMemo(() => {
    if (isCurrency) {
      return {
        inputComponent: NumberFormat,
      }
    }
    if (endAdornment) {
      return {
        inputComponent: NumberFormat,
        endAdornment: (
          <InputAdornment disableTypography className={styles.endAdornment} position="end">
            {endAdornment}
          </InputAdornment>
        ),
      }
    }
    return {}
  }, [endAdornment, isCurrency, styles.endAdornment])

  return (
    <Grid className={styles.container}>
      {mask ? (
        <InputMask value={value} onChange={onChange} mask={mask} disabled={disabled}>
          {() => (
            <MUITextField fullWidth variant="outlined" helperText={helperText} {...otherProps} />
          )}
        </InputMask>
      ) : (
        <MUITextField
          fullWidth
          value={value}
          onChange={onChange}
          variant="outlined"
          InputProps={inputProps}
          helperText={helperText}
          disabled={disabled}
          {...otherProps}
        />
      )}
    </Grid>
  )
}

TextField.propTypes = {
  mask: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  endAdornment: PropTypes.string,
  onChange: PropTypes.func,
  height: PropTypes.number,
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
  isCurrency: PropTypes.bool,
}

TextField.defaultProps = {
  mask: '',
  endAdornment: '',
  height: 4.8,
  onChange: () => {},
  helperText: '',
  disabled: false,
  isCurrency: false,
}

export default React.memo(TextField)

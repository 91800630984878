import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ breakpoints, palette }) => ({
  container: {
    justifyContent: 'center',
    textAlign: 'center',
    width: '100%',
    maxWidth: '53.2rem',
    margin: 'auto auto 11rem',

    [breakpoints.down('sm')]: {
      margin: 'auto auto 9.6rem',
    },
  },
  content: {
    '& .MuiSvgIcon-root': {
      width: '27.7rem',
      height: '12.3rem',
    },
    width: '100%',
    maxWidth: '51rem',
    padding: '2.4rem 3.6rem',
    justifyContent: 'center',
    textAlign: 'center',
    marginTop: '5.6rem',

    [breakpoints.down('sm')]: {
      margin: 'auto',
      padding: '0.8rem',
      width: '31.2rem',
    },
  },

  justification: {
    textAlign: 'left',
    background: palette.gray.gallery,
    margin: '3.6rem auto 3.2rem',
    justifyItems: 'center',
    padding: '1.6rem 1.6rem 0.8rem',
    width: '51.2rem',
    height: '14.1rem',
    justifyContent: 'center',

    [breakpoints.down('sm')]: {
      width: '31.2rem',
      height: '15rem',
    },
  },

  comment: {
    padding: '2.4rem 2.4rem 3.2rem',
    fontSize: '1.6rem',
    color: palette.secondary.main,
  },

  icon: {
    width: '22.6rem',
    height: '10rem',
    margin: 'auto',
  },

  title: {
    fontWeight: 'bold',
    lineHeight: 2.4,
    justifySelf: 'center',
    margin: 'auto auto 1.6rem',
    [breakpoints.down('sm')]: {
      fontSize: '1.6rem',
      maxWidth: '25.5rem',
      margin: 'auto auto 3.2rem',
    },
  },

  subTitle: {
    fontWeight: 400,
    lineHeight: 1.4,
    width: '44.3rem',
    margin: 'auto auto 1.8rem',
    [breakpoints.down('sm')]: {
      maxWidth: '27.2rem',
      fontSize: '1.4rem',
    },
  },

  button: {
    maxWidth: '14.8rem',
    margin: 'auto',
  },

  buttonWarranty: {
    fontWeight: 'bold',
    maxWidth: '24.5rem',
    margin: 'auto',
    color: palette.primary.main,
  },

  buttonSecondary: {
    fontWeight: 'bold',
    maxWidth: '24.5rem',
    margin: '4rem auto auto',
    color: palette.secondary.main,
  },

  warranty: {
    display: 'flex',
    flexDirection: 'row',
    justifyItems: 'center',
    padding: '1.6rem 1.6rem 0.8rem',
    width: '51rem',
    height: '9.7rem',
    textAlign: 'left',
    justifyContent: 'center',
    marginTop: '3.6rem',
    [breakpoints.down('sm')]: {
      width: '31.2rem',
      height: '15rem',
      flexDirection: 'column',
    },
  },

  warrantyTitle: {
    fontWeight: 500,
    lineHeight: '2.1rem',
    color: palette.secondary.dark,
    marginBottom: '0.8rem',
  },

  justificationTitle: {
    fontWeight: 500,
    lineHeight: '2.1rem',
    color: palette.secondary.dark,
    marginBottom: '0.8rem',
  },

  subDescription: {
    width: '26.3rem',
    justifyContent: 'flex-end',
    [breakpoints.down('sm')]: {
      maxWidth: '27.6rem',
    },
  },

  subDescriptionJustification: {
    width: '46.4rem',
    justifyContent: 'flex-end',
    [breakpoints.down('sm')]: {
      maxWidth: '27.6rem',
    },
  },

  line: {
    width: '0.1rem',
    height: '100%',
    margin: 'auto',
    backgroundColor: palette.secondary.light,
    [breakpoints.down('sm')]: {
      height: '0.1rem',
      width: '100%',
      margin: '1rem auto',
    },
  },

  horizontalDivision: {
    height: '0.1rem',
    width: '100%',
    margin: '2rem auto',
    backgroundColor: palette.secondary.light,
  },

  building: {
    display: 'flex',
    height: '18rem',
    margin: '20rem auto',
  },

  firstSection: {
    display: 'grid',
    gridTemplateRows: '1fr 1fr',
  },
}))

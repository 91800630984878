import humps from 'humps'

import { get, patch, post } from './requests'

export const getBookings = key => get(['bookings'], { key })

export const getBooking = key => bookingId => get(['bookings', bookingId], { key })

export const cancelBooking = key => bookingId => post(['bookings', bookingId, 'cancel'], { key })

export const updateBooking = key => payload =>
  post(['bookings'], { key }, humps.decamelizeKeys(payload))

export const createLead = payload => post(['leads'], {}, humps.decamelizeKeys(payload))

export const sendBookingFeedback = key => (payload, bookingId) =>
  post(['bookings', bookingId, 'feedback'], { key }, humps.decamelizeKeys(payload))

export const updateBookingFeedback = key => (payload, bookingId, feedbackId) =>
  patch(['bookings', bookingId, 'feedback', feedbackId], { key }, humps.decamelizeKeys(payload))

export const giveUpListing = key => (payload, bookingId) =>
  post(['bookings', bookingId, 'give-up'], { key }, humps.decamelizeKeys(payload))

export const updateOccurrence = key => (payload, bookingId) =>
  post(['bookings', bookingId, 'update-occurrence'], { key }, humps.decamelizeKeys(payload))

import React, { createContext, useState } from 'react'
import PropTypes from 'prop-types'

export const RedirectContext = createContext()

const RedirectProvider = ({ children }) => {
  const [redirectUri, setRedirectUri] = useState(null)
  const [openLogin, setOpenLogin] = useState(false)
  const [bookingState, setBookingState] = useState(false)
  const [likedProperty, setLikedProperty] = useState(null)

  return (
    <RedirectContext.Provider
      value={{
        redirectUri,
        setRedirectUri,
        openLogin,
        setOpenLogin,
        bookingState,
        setBookingState,
        likedProperty,
        setLikedProperty,
      }}
    >
      {children}
    </RedirectContext.Provider>
  )
}

RedirectProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default RedirectProvider

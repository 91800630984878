import React, { useMemo } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import SlickCarousel from '_components/slick-carousel'

import styles from './styles.css'

const FavoriteCarousel = ({ className, children }) => {
  const carouselSettings = useMemo(
    () => ({
      className: styles.carousel,
      dots: false,
      infinite: false,
      speed: 100,
      initialSlide: 0,
      draggable: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      centerMode: true,
      centerPadding: '15px',
    }),
    []
  )

  return (
    <div className={classnames(className, styles.carousel)}>
      <SlickCarousel settings={carouselSettings}>{children}</SlickCarousel>
    </div>
  )
}

FavoriteCarousel.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
}

FavoriteCarousel.defaultProps = {
  className: '',
}
export default React.memo(FavoriteCarousel)

import React, { useMemo } from 'react'
import TextField from '@material-ui/core/TextField'
import PropTypes from 'prop-types'

import Button, { ButtonColor, ButtonVariant, ButtonFormat } from '_components/button'
import mobileClickImage from '_assets/images/mobile-click.svg'
import { EMAIL_REGEX } from '_utils/constants'
import arrow from '_assets/images/ic-voltar.svg'

import useStyles from '../styles'

import styles from './styles.css'

const SendEmail = ({ userInfo, onChange, onBackClick, isLoading }) => {
  const classes = useStyles()

  const isDisabled = useMemo(() => !EMAIL_REGEX.test(userInfo.email), [userInfo.email])

  const inputProps = useMemo(
    () => ({
      className: classes.input,
    }),
    [classes.input]
  )

  return (
    <div className={styles.content}>
      <div className={styles['content-image']}>
        <svg aria-hidden="true" className={styles.image}>
          <use xlinkHref={mobileClickImage} />
        </svg>
      </div>
      <div className={styles.login}>
        <h1 className={styles.title}>Trocou de número?</h1>
        <p className={styles.description}>
          Se você já possui uma conta, informe seu e-mail para atualizarmos no cadastro.
        </p>
        <div className={styles['user-information']}>
          <TextField
            variant="outlined"
            margin="none"
            label="E-mail"
            name="email"
            value={userInfo.email}
            onChange={onChange}
            className={classes.textField}
            InputProps={inputProps}
            fullWidth
          />
        </div>
      </div>
      <Button
        color={ButtonColor.PRIMARY}
        variant={ButtonVariant.BOLD}
        format={ButtonFormat.SQUARED}
        className={styles.button}
        disabled={isDisabled}
        isLoading={isLoading}
        type="submit"
      >
        ENVIAR
      </Button>
      <Button
        color={ButtonColor.TEXT_BLACK}
        variant={ButtonVariant.SMALL_ICON_TEXT}
        format={ButtonFormat.SQUARED}
        onClick={onBackClick}
        className={styles['back-button']}
        childrenClassName={styles['button-container']}
      >
        <svg className={styles.icon} aria-hidden="true">
          <use xlinkHref={arrow} />
        </svg>
        VOLTAR
      </Button>
    </div>
  )
}

SendEmail.propTypes = {
  userInfo: PropTypes.shape({
    email: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func,
  onBackClick: PropTypes.func,
  isLoading: PropTypes.bool,
}

SendEmail.defaultProps = {
  onChange: () => {},
  onBackClick: () => {},
  isLoading: false,
}

export default React.memo(SendEmail)

import { defineAction } from '_utils/redux'

export const TRIGGER_SNACKBAR = defineAction('TRIGGER_SNACKBAR')

export const triggerSnackbar = meta => dispatch =>
  dispatch({
    type: TRIGGER_SNACKBAR.ACTION,
    payload: new Promise(res => {
      setTimeout(() => {
        res()
      }, 10)
    }),
    meta,
  })

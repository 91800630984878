/* eslint-disable no-param-reassign */

import { validationCPF, dateValidation } from '_utils/helpers'
import { PARTICIPANTS_INFO, EMAIL_REGEX, WARRANTY_TYPES } from '_utils/constants'

const NOT_MANDATORY_FIELDS = [
  PARTICIPANTS_INFO.BUILDING,
  PARTICIPANTS_INFO.CPF_CNPJ,
  PARTICIPANTS_INFO.IS_TENANT,
  PARTICIPANTS_INFO.CONSIDER_INCOME,
  PARTICIPANTS_INFO.MONTHLY_INCOME,
  PARTICIPANTS_INFO.CURRENT_ADDRESS,
  PARTICIPANTS_INFO.ADDRESS,
  PARTICIPANTS_INFO.CEP,
  PARTICIPANTS_INFO.CITY,
  PARTICIPANTS_INFO.STATE,
  PARTICIPANTS_INFO.NEIGHBORHOOD,
  PARTICIPANTS_INFO.EXTRA_ADDRESS_INFO,
  PARTICIPANTS_INFO.STREET_NUMBER,
  PARTICIPANTS_INFO.EMERGENCY_CONTACT_NAME,
  PARTICIPANTS_INFO.EMERGENCY_CONTACT_NUMBER,
  PARTICIPANTS_INFO.EMERGENCY_CONTACT2_NAME,
  PARTICIPANTS_INFO.EMERGENCY_CONTACT2_NUMBER,
]

const REQUIRED_CREDPAGO_FLOW = [
  PARTICIPANTS_INFO.ADDRESS,
  PARTICIPANTS_INFO.CEP,
  PARTICIPANTS_INFO.CITY,
  PARTICIPANTS_INFO.STATE,
  PARTICIPANTS_INFO.NEIGHBORHOOD,
]
const mandatoryFields = values => {
  const errors = {}

  Object.entries(values).map(attribute => {
    const [key, value] = attribute

    if (!value && !NOT_MANDATORY_FIELDS.includes(key)) {
      errors[key] = 'Campo obrigatório'
    }
    // if (emergencyContactEnabled && !values[PARTICIPANTS_INFO.EMERGENCY_CONTACT_NAME]) {
    //   errors[PARTICIPANTS_INFO.EMERGENCY_CONTACT_NAME] = 'Campo obrigatório'
    // }
    // if (emergencyContactEnabled && !values[PARTICIPANTS_INFO.EMERGENCY_CONTACT_NUMBER]) {
    //   errors[PARTICIPANTS_INFO.EMERGENCY_CONTACT_NUMBER] = 'Campo obrigatório'
    // }
    // if (emergencyContactEnabled && !values[PARTICIPANTS_INFO.EMERGENCY_CONTACT2_NAME]) {
    //   errors[PARTICIPANTS_INFO.EMERGENCY_CONTACT2_NAME] = 'Campo obrigatório'
    // }
    // if (emergencyContactEnabled && !values[PARTICIPANTS_INFO.EMERGENCY_CONTACT2_NUMBER]) {
    //   errors[PARTICIPANTS_INFO.EMERGENCY_CONTACT2_NUMBER] = 'Campo obrigatório'
    // }

    if (key === PARTICIPANTS_INFO.DATE_OF_BIRTH && !dateValidation(value)) {
      errors[key] = 'Data inválida'
    }

    if (key === PARTICIPANTS_INFO.EMAIL && !EMAIL_REGEX.test(value)) {
      errors[key] = 'E-mail inválido'
    }

    if (key === PARTICIPANTS_INFO.CPF && !validationCPF(value)) {
      errors[key] = 'Insira um CPF válido'
    }
    return errors
  })

  return errors
}

const validateCredpago = values => {
  const errors = {}
  REQUIRED_CREDPAGO_FLOW.map(field => {
    if (!values[field] && values.warranty === WARRANTY_TYPES.CREDPAGO) {
      errors[field] = 'Campo obrigatório'
      return null
    }
    return null
  })
  return errors
}

export const validate = values => {
  const credpagoError =
    values.warranty === WARRANTY_TYPES.CREDPAGO ? { ...validateCredpago(values) } : {}
  const errors = mandatoryFields(values)
  return { ...errors, ...credpagoError }
}

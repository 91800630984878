import React, { useState, useCallback, useContext, useMemo } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { Divider, Grid, Typography, Button } from '@material-ui/core'
import { useDispatch } from 'react-redux'

import FileChip from '_components/file-chip'
import StatusChip from '_components/status-chip'
import {
  PARTICIPANTS_TRANSLATION_SINGULAR,
  PARTICIPANTS_TYPE,
  PARTICIPANTS_INFO,
  PARTICIPANTS_LABEL,
  GENDER_LABEL,
  CIVIL_STATUS_LABEL,
  WARRANTY_TYPES,
  BRASIL_STATES,
} from '_utils/constants'
import { updateParticipants } from '_modules/leases/actions'
import { STATUS_TYPE } from '_components/status-chip/constants'
import TokenContext from '_context/token'
import { formatDate, phoneNumberMask, cpfCnpjMask, cepMask } from '_utils/strings'

import useStyles from './styles'
import { FIELDS, FIELDS_CREDPAGO } from './constants'

const UPDATE_STATUS_TYPES = [STATUS_TYPE.NEW, STATUS_TYPE.UPDATED_BY_STAFF]
const CardParticipant = ({ participant, warranty }) => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const [seeMore, setSeeMore] = useState(false)
  const { leaseId } = useContext(TokenContext)
  const details = participant.spouse ? [participant, participant.spouse] : [participant]
  const isCredPago = WARRANTY_TYPES.CREDPAGO === warranty

  const toggle = useCallback(() => {
    if (
      !seeMore &&
      participant.category &&
      UPDATE_STATUS_TYPES.includes(participant.registrationTag)
    ) {
      dispatch(updateParticipants(leaseId, participant.id, { hasUpdatedRegistration: false }))
    }
    setSeeMore(prev => !prev)
  }, [
    dispatch,
    leaseId,
    participant.category,
    participant.id,
    participant.registrationTag,
    seeMore,
  ])

  const renderValue = useCallback(
    field => {
      if (field.occupation) {
        return participant.professionalData && participant.professionalData.occupation
      }
      const value = participant[field.value]
      switch (field.value) {
        case PARTICIPANTS_INFO.GENDER:
          return GENDER_LABEL[value]
        case PARTICIPANTS_INFO.DATE_OF_BIRTH: {
          if (!value) {
            return '-'
          }
          return formatDate(value)
        }
        case PARTICIPANTS_INFO.PHONE_NUMBER:
          return phoneNumberMask(value)
        case PARTICIPANTS_INFO.CIVIL_STATUS:
          return CIVIL_STATUS_LABEL[value]
        case PARTICIPANTS_INFO.CPF:
          return cpfCnpjMask(value)
        case PARTICIPANTS_INFO.CEP:
          if (!value) {
            return '-'
          }
          return cepMask(value)
        case PARTICIPANTS_INFO.STATE:
          if (!value) {
            return '-'
          }
          return BRASIL_STATES.find(state => state.value === value).label
        default:
          return value
      }
    },
    [participant]
  )

  const participantFields = useMemo(() => (isCredPago ? FIELDS_CREDPAGO : FIELDS), [isCredPago])

  const participantsType = useMemo(() => {
    if (participant.category === PARTICIPANTS_TYPE.INCOME_HELPER) {
      return 'Responsável pelo pagamento'
    }
    if (participant.category) {
      return PARTICIPANTS_TRANSLATION_SINGULAR[participant.category]
    }
    return PARTICIPANTS_TRANSLATION_SINGULAR[PARTICIPANTS_TYPE.MAIN_TENANT]
  }, [participant.category])

  return (
    <Grid className={styles.wrapper}>
      <Grid className={styles.header}>
        <Grid className={styles.textWrapper}>
          <Typography variant="h5" component="p" className={styles.label}>
            {participantsType}
          </Typography>
          <Typography variant="subtitle1" component="p" className={styles.name}>
            {participant.fullName}
          </Typography>
        </Grid>
        {participant.registrationTag && (
          <StatusChip option={participant.registrationTag} className={styles.statusChip} />
        )}
        <Button
          color="default"
          className={classnames(styles.button, styles.desktop)}
          onClick={toggle}
        >
          {seeMore ? 'Ocultar' : 'Ver mais'}
        </Button>
      </Grid>
      {seeMore &&
        !!details.length &&
        details.map((item, detailIndex) => (
          <Grid key={item.id} className={styles.details}>
            <Grid container spacing={3}>
              {participantFields.map((field, fieldIndex) => (
                <Grid key={field.value} item xs={12} md={field.md}>
                  <Typography
                    component="p"
                    variant="h5"
                    className={styles.fieldLabel}
                    color="secondary"
                  >
                    {field.occupation ? 'Profissão' : PARTICIPANTS_LABEL[field.value]}{' '}
                    {detailIndex === 1 && fieldIndex === 0 && 'do cônjuge'}
                  </Typography>
                  <Typography
                    component="p"
                    variant="subtitle1"
                    color="secondary"
                    className={styles.fields}
                  >
                    {renderValue(field) || '-'}
                  </Typography>
                </Grid>
              ))}
              {item.documents && !!item.documents.length && (
                <Grid item xs={12} className={styles.filesWrapper}>
                  {item.documents.map(file => (
                    <FileChip key={file.name} className={styles.file} file={file} showOnly />
                  ))}
                </Grid>
              )}
            </Grid>
          </Grid>
        ))}
      <Divider className={styles.divider} />
      <Button color="default" className={classnames(styles.button, styles.mobile)} onClick={toggle}>
        {seeMore ? 'Ocultar' : 'Ver mais'}
      </Button>
    </Grid>
  )
}

CardParticipant.propTypes = {
  participant: PropTypes.shape({
    id: PropTypes.number,
    registrationTag: PropTypes.string,
    category: PropTypes.string,
    fullName: PropTypes.string,
    spouse: PropTypes.shape({
      fullName: PropTypes.string,
    }),
    professionalData: PropTypes.shape({
      occupation: PropTypes.string,
    }),
  }),
  warranty: PropTypes.string,
}

CardParticipant.defaultProps = {
  participant: {
    spouse: null,
  },
  warranty: '',
}

export default CardParticipant

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import classnames from 'classnames'
import { Map } from 'immutable'
import { navigate } from '@reach/router'

import RedirectProvider, { RedirectContext } from '_context/redirect'
import SEO from '_components/seo'
import { User } from '_models'
import {
  getUser,
  phoneLogin,
  PHONE_LOGIN,
  getAuthToken,
  GET_AUTH_TOKEN,
} from '_modules/user/actions'
import { closeCookiesModal } from '_modules/modal/actions'
import { locationChanged } from '_modules/location/actions'
import { sendEvent, registerEvent } from '_utils/mixpanel'
import { MENU_TITLE, PATHNAMES_WITHOUT_PARAMETERS, GENERAL } from '_utils/constants'
import { TABLET } from '_config/media-queries'
import Loading from '_components/loading'
import { FACEBOOK_ID } from '_config/environment'

import Header, { HeaderTheme } from './header'
import Footer from './footer'
import styles from './styles.css'
import CookiesModal from './cookies'

const mapStateToProps = ({ user, modal, search, server, loading, error }) => ({
  user,
  currentScroll: search.scroll,
  showBlipChat: modal.get('showBlipChat'),
  showCookiesModal: modal.get('showCookiesModal'),
  isMobile: server.isMobile,
  origin: server.origin,
  isLogging: !!loading.get(PHONE_LOGIN.ACTION),
  loggingError: error.get(PHONE_LOGIN.ACTION),
  authTokenError: error.get(GET_AUTH_TOKEN.ACTION, Map()),
  isAuthLoading: !!loading.get(GET_AUTH_TOKEN.ACTION),
})

const mapDispatchToProps = {
  getUser,
  closeCookiesModal,
  locationChanged,
  phoneLogin,
  getAuthToken,
}

class App extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    location: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    user: PropTypes.instanceOf(User),
    getUser: PropTypes.func.isRequired,
    currentScroll: PropTypes.number,
    showCookiesModal: PropTypes.bool.isRequired,
    closeCookiesModal: PropTypes.func.isRequired,
    locationChanged: PropTypes.func.isRequired,
    getAuthToken: PropTypes.func.isRequired,
    isMobile: PropTypes.bool.isRequired,
    thumbnailPicture: PropTypes.string,
    isLogging: PropTypes.bool.isRequired,
    isAuthLoading: PropTypes.bool.isRequired,
    loggingError: PropTypes.instanceOf(Map),
    authTokenError: PropTypes.instanceOf(Map),
  }

  static defaultProps = {
    location: {},
    user: new User(),
    currentScroll: undefined,
    loggingError: Map(),
    authTokenError: Map(),
    thumbnailPicture: '',
  }

  constructor(props) {
    super(props)
    this.state = {
      showBanner: true,
      isMobile: typeof window !== 'undefined' ? window.innerWidth <= TABLET : props.isMobile,
    }
  }

  componentDidMount() {
    const { user, location } = this.props
    const hasVerificationToken =
      location.search.includes('?verification_token=') ||
      location.search.includes('&verification_token=')
    if (hasVerificationToken) {
      const verificationToken = location.search.match(/[?&]verification_token=([^&#]*)/)[1]
      this.props.getAuthToken({ verificationToken })
    }
    if (user.authToken && !hasVerificationToken) {
      this.props.getUser()
      registerEvent({ Nome: user.fullName, Email: user.email, Id: user.id })
    }
    this.dynamicFooter()
  }

  componentDidUpdate(prevProps) {
    const { location, currentScroll, authTokenError, isAuthLoading } = this.props
    if (location.key !== prevProps.location.key) {
      if (!(location.pathname === '/buscar' && typeof currentScroll !== 'undefined')) {
        window.scrollTo(0, 0)
      }

      this.props.locationChanged(prevProps.location)
    }
    if (!isAuthLoading && prevProps.isAuthLoading && authTokenError.size) {
      navigate('/login', { state: { redirectUri: location.pathname } })
    }
  }

  getMenuTitle = () => {
    const { location } = this.props
    const menuHeaderRoutes = Object.keys(MENU_TITLE).find(route =>
      location.pathname.includes(route)
    )
    if (menuHeaderRoutes) {
      return MENU_TITLE[menuHeaderRoutes]
    }

    return 'Agendamento de visita'
  }

  goBack = () => {
    sendEvent('Cabeçalho: Selecionou botão voltar para página anterior')

    switch (this.transformUrl(this.props.location.pathname)) {
      case '/minhas-visitas/:any':
        navigate('/minhas-visitas')
        return
      case '/analise-credito/':
      case '/contrato':
      case '/agendamento':
      case '/minhas-negociacoes/:any':
      case '/minhas-negociacoes':
        window.history.back()
        return
      case '/imovel':
        window.close()
        return
      default:
        break
    }
    if (this.dynamicHeader() === HeaderTheme.VISITS) {
      navigate('../')
      return
    }

    window.history.back()
  }

  transformUrl = url => {
    const pathName = PATHNAMES_WITHOUT_PARAMETERS.find(value => url.includes(value))
    if (pathName) return pathName

    return url
  }

  dynamicHeader = () => {
    const { location } = this.props
    const { isMobile } = this.state
    switch (this.transformUrl(location.pathname)) {
      case '/':
      case '/buscar':
      case '/search':
        return HeaderTheme.DEFAULT
      case '/agendamento':
        if (!isMobile) return HeaderTheme.DEFAULT
        return HeaderTheme.VISITS
      case '/agendamento/passo-5':
        if (!isMobile) return HeaderTheme.DEFAULT
        return HeaderTheme.VISITS
      case '/imovel':
        if (!isMobile) return HeaderTheme.DEFAULT
        return HeaderTheme.TRANSPARENT
      case '/politica-de-privacidade':
      case '/termos-de-servico':
        return HeaderTheme.PRIVACY
      case '/minhas-visitas':
      case '/minhas-visitas/:any':
      case '/minhas-negociacoes':
      case '/minhas-negociacoes/:any':
      case '/contrato':
      case '/proposta-enviada':
      case '/proposta-aprovada':
      case '/proposta-recusada':
      case '/garantia':
      case '/analise-credito/':
        return HeaderTheme.VISITS
      default:
        return HeaderTheme.DEFAULT
    }
  }

  dynamicFooter = () => {
    const { location } = this.props
    switch (this.transformUrl(location.pathname)) {
      case '/login':
      case '/agendamento':
      case '/minhas-visitas':
      case '/cadastro':
      case '/minhas-negociacoes':
      case '/minhas-negociacoes/:any':
      case '/contrato':
      case '/proposta-enviada':
      case '/proposta-aprovada':
      case '/proposta-recusada':
      case '/anunciar-imovel':
      case '/minhas-visitas/cancelada':
      case '/registrar-contratante-passo':
      case '/convidar-participantes':
      case '/seguro-incendio':
      case '/feedback-visita':
      case '/proposta/resumo-locacao/':
      case '/problema-reportado':
      case '/proposta/apresentacao/':
      case '/analise-credito/':
      case '/analise-credito':
      case '/proposta/feedback/':
        return null
      default:
        return <Footer className={styles.footer} />
    }
  }

  closeBanner = () => {
    this.setState({ showBanner: false })
    sendEvent('Página Inicial: Fechou Banner')
  }

  static contextType = RedirectContext

  render() {
    const {
      children,
      location,
      showCookiesModal,
      thumbnailPicture,
      user,
      isLogging,
      loggingError,
      isAuthLoading,
    } = this.props
    const { showBanner } = this.state
    const property = this.transformUrl(location.pathname) === '/imovel'

    return (
      <RedirectProvider>
        <section
          className={classnames(styles.container, {
            [styles['transparent-header']]:
              this.dynamicHeader() === HeaderTheme.TRANSPARENT && !showBanner,
            [styles['mobile-property']]: property,
          })}
          style={{ 'grid-template-rows': `${GENERAL.brandHeaderLogoHeight}px` }}
        >
          <SEO
            schema="WebPage"
            title={GENERAL.brand}
            description={GENERAL.metatagsHomeDescription}
            path={`${GENERAL.websiteUrl}${location.pathname}`}
            contentType="website"
            image={thumbnailPicture}
            brand={GENERAL.brand}
            facebookAppId={FACEBOOK_ID}
          />
          <Header
            theme={this.dynamicHeader()}
            goBack={this.goBack}
            isLoggedIn={!!user.authToken}
            isLogging={isLogging}
            loggingError={loggingError}
            menuTitle={this.getMenuTitle()}
          />
          {isAuthLoading ? <Loading /> : <div className={styles.children}>{children}</div>}
          {this.dynamicFooter()}
        </section>
        <CookiesModal
          closeCookiesModal={this.props.closeCookiesModal}
          className={styles['cookies-modal']}
          isOpen={showCookiesModal}
        />
      </RedirectProvider>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)

import { moment } from '_bootstrap'

export const DAY_MAPPING = {
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
  sunday: 7,
}

export const filterWeekdays = (agencySlots, momentDate) =>
  agencySlots.filter(slot => DAY_MAPPING[slot.get('weekday')] === momentDate.isoWeekday())

export const sortDate = (agencySlots, momentDate) =>
  agencySlots
    .sort((a, b) => {
      const timeAArray = a.get('endTime').split(':')
      const timeBArray = b.get('endTime').split(':')
      const momentA = momentDate
        .clone()
        .set('hour', timeAArray[0])
        .set('minute', timeAArray[1])
        .set('second', timeAArray[2])
      const momentB = momentDate
        .clone()
        .set('hour', timeBArray[0])
        .set('minute', timeBArray[1])
        .set('second', timeBArray[2])

      return momentB.valueOf() - momentA.valueOf()
    })
    .get(0)

export const filterAndSortWeekdays = (agencySlots, momentDate) =>
  sortDate(filterWeekdays(agencySlots, momentDate), momentDate)

export const initialState = {
  dataArray: [
    ...new Array(30).fill(undefined).map((val, index) =>
      moment()
        .set({
          hour: 17,
          minute: 30,
          second: 0,
          millisecond: 0,
        })
        .add(index, 'days')
        .toISOString()
    ),
  ],
  selectedOnce: false,
  selectedDateID: undefined,
  selectedTimeID: undefined,
  centeredDate: undefined,
  firstAvailableSlot: 0,
  timeArray: [],
  ready: false,
}

export const SET_VALUE = 'SET_VALUE'
export const LOAD_MORE_DATES = 'LOAD_MORE_DATES'

export const reducer = (state, action) => {
  const { type, payload } = action

  switch (type) {
    case SET_VALUE:
      return { ...state, ...payload }
    case LOAD_MORE_DATES: {
      const { dataArray } = state
      const lastDate = [...dataArray].pop()

      return {
        ...state,
        dataArray: [
          ...state.dataArray,
          ...new Array(30).fill(undefined).map((val, index) =>
            moment(lastDate)
              .add(index + 1, 'days')
              .toISOString()
          ),
        ],
      }
    }
    default:
      return state
  }
}

import { createSelector } from 'reselect'
import { Map } from 'immutable'

import {
  FIREBASE_LOGIN,
  UPDATE_USER,
  REQUEST_PHONE_CHANGE,
  CONFIRM_REQUEST_PHONE_CHANGE,
  VERIFY_PHONE_LOGIN_CODE,
  SEND_RATING,
  GET_AUTH_TOKEN,
} from './actions'

const getUpdateUser = ({ loading }) => !!loading.get(UPDATE_USER.ACTION)
const getUserLoggedIn = state => !!state.user.authToken

export const getAuthTokenLoadingSelector = ({ loading }) => !!loading.get(GET_AUTH_TOKEN.ACTION)
export const getAuthTokenErrorSelector = ({ error }) => error.get(GET_AUTH_TOKEN.ACTION, Map())
export const getRatingSent = state => state.loading.get(SEND_RATING.ACTION)
export const getUserLikedProperties = state => state.user.likedProperties
export const getUserInfoSelector = state => state.user
export const getActiveLeaseSelector = state => state.user.activeLease

export const getUpdateUserLoading = createSelector([getUpdateUser], isLoading => isLoading)

export const isUserLoggedIn = createSelector([getUserLoggedIn], isLoggedIn => isLoggedIn)

export const getLoadingLogin = ({ loading }) =>
  !!loading.get(FIREBASE_LOGIN.ACTION) || !!loading.get(VERIFY_PHONE_LOGIN_CODE.ACTION)

export const getLoginError = ({ error }) =>
  error.get(FIREBASE_LOGIN.ACTION) || error.get(VERIFY_PHONE_LOGIN_CODE.ACTION)

export const getLoadingFields = ({ loading }) => !!loading.get(UPDATE_USER.ACTION)
export const getLoadingFieldsError = ({ error }) => error.get(UPDATE_USER.ACTION)

export const getConfirmRequestFields = ({ loading }) =>
  !!loading.get(CONFIRM_REQUEST_PHONE_CHANGE.ACTION)
export const getConfirmRequestFieldsError = ({ error }) =>
  error.get(CONFIRM_REQUEST_PHONE_CHANGE.ACTION)

export const getRequestPhoneChangeLoading = ({ loading }) =>
  !!loading.get(REQUEST_PHONE_CHANGE.ACTION)
export const getRequestPhoneChangeError = ({ error }) => error.get(REQUEST_PHONE_CHANGE.ACTION)

export const isUpdatingUserSelector = ({ loading }) => !!loading.get(UPDATE_USER.ACTION)
export const updateUserErrorSelector = ({ error }) => error.get(UPDATE_USER.ACTION) || Map()
export const isSendingFeedbackSelector = state => !!state.loading.get(SEND_RATING.ACTION)
export const sendFeedbackErrorSelector = state => !!state.error.get(SEND_RATING.ACTION)

import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import AddToCalendar from 'react-add-to-calendar'
import MomentPropTypes from 'react-moment-proptypes'
import { Grid, Typography, Link } from '@material-ui/core'
import { CalendarToday, Room, VpnKey, Info } from '@material-ui/icons'

import { BOOKING_INFO } from '_utils/constants'

import useStyles from './styles'

const CalendarCard = ({ className, date, address, fullAddress, propertyTitle }) => {
  const styles = useStyles()

  const formattedDate = useMemo(
    () =>
      date.minutes()
        ? date.format('dddd[,] DD [de] MMMM [de] YYYY[. Às] HH[:]mm [horas]')
        : date.format('dddd[,] DD [de] MMMM [de] YYYY[. Às] HH [horas]'),
    [date]
  )

  const nextHour = useMemo(() => date.clone().add(1, 'hour'), [date])

  const event = useMemo(
    () => ({
      // eslint-disable-next-line no-irregular-whitespace
      title: `Visita no ${propertyTitle ? propertyTitle.toLowerCase() : 'imóvel'}`,
      description: '',
      location: address,
      startTime: date.toISOString(),
      endTime: nextHour.toISOString(),
    }),
    [address, date, nextHour, propertyTitle]
  )

  return (
    <Grid className={classnames(styles.main, className)} component="section">
      <Grid className={styles.content}>
        <Grid className={styles.titleContainer}>
          <CalendarToday className={styles.icon} />
          <Typography component="h1" className={styles.title}>
            Visita solicitada para:
          </Typography>
        </Grid>
        <Grid className={styles.subContentContainer}>
          <Typography component="p" className={styles.subContentText}>
            {formattedDate}
          </Typography>
          <Typography component="p" className={styles.information}>
            Em breve um profissional entrará em contato via WhatsApp para dar mais detalhes.
          </Typography>
        </Grid>
      </Grid>
      <Grid className={styles.content}>
        <Grid className={styles.titleContainer}>
          <Room className={styles.icon} />
          <Typography component="h2" className={styles.title}>
            Como chegar:
          </Typography>
        </Grid>
        <Grid className={styles.address}>
          <Link
            className={styles.fullAddress}
            href={`https://maps.google.com/?q=${address} ${fullAddress}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {address}{' '}
            <Typography component="span" className={styles.addressBold}>
              {fullAddress}
            </Typography>
          </Link>
        </Grid>
      </Grid>
      <Grid className={styles.content}>
        <Grid className={styles.titleContainer}>
          <VpnKey className={styles.icon} />
          <Typography component="h2" className={styles.title}>
            Retirada da chave
          </Typography>
        </Grid>
        <Grid className={styles.subContentContainer}>
          <Typography className={styles.subContentText} component="p">
            {BOOKING_INFO.bookingKeyOption}
          </Typography>
        </Grid>
      </Grid>
      <Grid className={styles.content}>
        <Grid className={styles.titleContainer}>
          <Info className={styles.icon} />
          <Typography component="h2" className={styles.title}>
            Outras informações
          </Typography>
        </Grid>
        <Grid className={styles.subContentContainer}>
          <Typography className={styles.subContentText} component="p">
            {BOOKING_INFO.bookingExtraInfo.map(item => (
              <Typography component="p" variant="subtitle1" key={item}>
                {item}
              </Typography>
            ))}
          </Typography>
        </Grid>
      </Grid>
      <Grid className={styles.addToCalendarSection}>
        <AddToCalendar
          event={event}
          buttonLabel="Adicionar ao calendário"
          buttonWrapperClass={styles.addToCalendar}
          rootClass={styles.addToCalendarRoot}
          dropdownClass={styles.dropdown}
          displayItemIcons={false}
        />
      </Grid>
    </Grid>
  )
}

CalendarCard.propTypes = {
  className: PropTypes.string,
  date: MomentPropTypes.momentObj.isRequired,
  address: PropTypes.string.isRequired,
  fullAddress: PropTypes.string.isRequired,
  propertyTitle: PropTypes.string.isRequired,
}

CalendarCard.defaultProps = {
  className: '',
}

export default CalendarCard

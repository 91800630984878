import React, { useMemo } from 'react'
import { Button as MUIButton, Grid } from '@material-ui/core'
import { PulseLoader } from 'react-spinners'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Link } from '@reach/router'

import useStyles from './styles'

export const ButtonTheme = {
  WHITE_BACKGROUND: 'whiteBackground',
  GRAY_WITH_BORDER: 'grayWithBorder',
}

export const ButtonFormat = {
  ROUNDED: 'rounded',
}

export const CONTAINED_VARIANT = 'contained'

const Button = ({
  to,
  isLoading,
  format,
  children,
  color,
  disabled,
  className,
  variant,
  theme,
  state,
  ...otherProps
}) => {
  const isContained = variant === CONTAINED_VARIANT
  const styles = useStyles()
  const overrideButton = useMemo(
    () => ({
      root: classnames(styles.button, styles[format]),
    }),
    [format, styles]
  )

  return (
    <MUIButton
      component={to ? Link : 'button'}
      classes={overrideButton}
      color={color}
      to={to}
      disabled={isLoading || disabled}
      className={classnames(styles[theme], { [styles.button]: isContained }, className)}
      disableElevation
      variant={variant}
      state={state}
      {...otherProps}
    >
      {isLoading ? (
        <Grid className={styles.loading}>
          <PulseLoader
            sizeUnit="px"
            size={8}
            margin="4px"
            color={color === 'primary' ? `rgba(255, 255, 255, 0.91)` : `rgba(0, 0, 0, 1)`}
            loading
          />
        </Grid>
      ) : (
        children
      )}
    </MUIButton>
  )
}

Button.propTypes = {
  isLoading: PropTypes.bool,
  color: PropTypes.string,
  to: PropTypes.string,
  className: PropTypes.string,
  variant: PropTypes.string,
  theme: PropTypes.string,
  disabled: PropTypes.bool,
  state: PropTypes.shape({}),
  children: PropTypes.node.isRequired,
  format: PropTypes.oneOfType([...Object.values(ButtonFormat), '']),
}

Button.defaultProps = {
  isLoading: false,
  to: null,
  color: 'primary',
  format: '',
  className: '',
  theme: '',
  variant: '',
  disabled: false,
  state: null,
}

export default React.memo(Button)

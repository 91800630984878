import humps from 'humps'

import { defineAction } from '_utils/redux'
import * as propertyService from '_services/property'

const camelizeResponse = response => humps.camelizeKeys(response)
export const GET_PROPERTY = defineAction('GET_PROPERTY')
export const LIKE_PROPERTY = defineAction('LIKE_PROPERTY')
export const BULK_LIKE_PROPERTY = defineAction('BULK_LIKE_PROPERTY')
export const DISLIKE_PROPERTY = defineAction('DISLIKE_PROPERTY')
export const BULK_DISLIKE_PROPERTY = defineAction('BULK_DISLIKE_PROPERTY')
export const UNLIKE_PROPERTY = defineAction('UNLIKE_PROPERTY')
export const UNDISLIKE_PROPERTY = defineAction('UNDISLIKE_PROPERTY')
export const BOOK_PROPERTY = defineAction('BOOK_PROPERTY')
export const GET_SIMILAR_PROPERTIES = defineAction('GET_SIMILAR_PROPERTIES')

export const getProperty = propertyId => (dispatch, getState) =>
  dispatch({
    type: GET_PROPERTY.ACTION,
    payload: propertyService.getProperty(getState().user.authToken)(propertyId),
  })

export const likeProperty = propertyId => (dispatch, getState) =>
  dispatch({
    type: LIKE_PROPERTY.ACTION,
    payload: getState().user.authToken
      ? propertyService.likeProperty(getState().user.authToken)(propertyId)
      : null,
    meta: {
      propertyId,
    },
  })

export const bulkLikeProperty = () => (dispatch, getState) =>
  dispatch({
    type: BULK_LIKE_PROPERTY.ACTION,
    payload: propertyService.bulkLikeProperty(getState().user.authToken)(
      getState().user.likedProperties
    ),
  })

export const dislikeProperty = propertyId => (dispatch, getState) =>
  dispatch({
    type: DISLIKE_PROPERTY.ACTION,
    payload: getState().user.authToken
      ? propertyService.dislikeProperty(getState().user.authToken)(propertyId)
      : null,
    meta: {
      propertyId,
    },
  })

export const bulkDislikeProperty = () => (dispatch, getState) =>
  dispatch({
    type: BULK_DISLIKE_PROPERTY.ACTION,
    payload: propertyService.bulkDislikeProperty(getState().user.authToken)(
      getState().user.dislikedProperties
    ),
  })

export const unLikeProperty = propertyId => (dispatch, getState) =>
  dispatch({
    type: UNLIKE_PROPERTY.ACTION,
    payload: getState().user.authToken
      ? propertyService.unLikeProperty(getState().user.authToken)(propertyId)
      : null,
    meta: {
      propertyId,
    },
  })

export const unDislikeProperty = propertyId => (dispatch, getState) =>
  dispatch({
    type: UNDISLIKE_PROPERTY.ACTION,
    payload: getState().user.authToken
      ? propertyService.unDislikeProperty(getState().user.authToken)(propertyId)
      : null,
    meta: {
      propertyId,
    },
  })

export const bookProperty = payload => (dispatch, getState) =>
  dispatch({
    type: BOOK_PROPERTY.ACTION,
    payload: propertyService.bookProperty(getState().user.authToken)(payload),
  })

export const getSimilarProperties = externalId => (dispatch, getState) => {
  return dispatch({
    type: GET_SIMILAR_PROPERTIES.ACTION,
    payload: propertyService
      .getSimilarProperties(getState().user.authToken)(externalId)
      .then(camelizeResponse),
  })
}

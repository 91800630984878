import React, { useMemo, useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import Button, { ButtonVariant, ButtonColor } from '_components/button'
import withAuth from '_hocs/with-auth'
import AddIcon from '_assets/images/ic-add.svg'
import { EMAIL_REGEX } from '_utils/constants'
import { updateParticipants } from '_modules/leases/actions'
import TextField from '_components/material/rounded-textfield'

import ContactCard from '../contact-card'
import MainInfo from '../main-info'

import styles from './styles.css'

const INITIAL_STATE = {
  id: 1,
  fullName: '',
  email: '',
  category: 'tenant',
  isTenant: true,
  emailError: false,
}

const InviteParticipant = ({
  leaseId,
  title,
  description,
  participantRef,
  handleRefUpdated,
  type,
  existingTenants,
  hasGuarantorParticipant,
  lease,
  externalId,
}) => {
  const [participants, setParticipants] = useState([{ ...INITIAL_STATE, category: type }])
  const dispatch = useDispatch()

  useEffect(
    () => {
      // eslint-disable-next-line no-param-reassign
      participantRef.current = participants.filter(
        ({ fullName, email }) => fullName.length && email.length
      )
      handleRefUpdated()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [participants]
  )

  // Add participants
  const onClickAddParticipant = useCallback(() => {
    setParticipants(prevParticipants => [
      ...prevParticipants,
      {
        ...INITIAL_STATE,
        id: prevParticipants.length + 1,
      },
    ])
  }, [])

  // Delete participants
  const onClickRemoveParticipant = useCallback(
    id => {
      setParticipants(prevParticipants =>
        prevParticipants
          .filter(participant => participant.id !== id)
          .map((participant, index) => ({
            ...participant,
            id: index + 1,
          }))
      )
    },
    [setParticipants]
  )
  // Handle Participants
  const handleParticipants = useCallback(
    event => {
      const { name, value, id } = event.target
      const newParticipants = participants.map(participant => {
        if (participant.id === Number(id)) {
          const newValue = { ...participant, [name]: value }
          if (name === 'email') {
            return { ...newValue, emailError: !EMAIL_REGEX.test(value) }
          }
          return newValue
        }
        return participant
      })
      setParticipants(newParticipants)
    },
    [participants]
  )

  const onDisableTenant = useCallback(
    id => {
      dispatch(updateParticipants(leaseId, id, { enabled: false }, externalId))
    },
    [dispatch, externalId, leaseId]
  )

  const textFieldProps = useCallback(
    (value, participant) => ({
      onChange: handleParticipants,
      value: participant[value],
      id: String(participant.id),
      name: value,
      error: participant.emailError,
    }),
    [handleParticipants]
  )

  const isAddButtonDisabled = useMemo(() => {
    if (!participants[participants.length - 1]) {
      return true
    }
    const { emailError, fullName, email } = participants[participants.length - 1]
    return emailError || (!fullName.length && !email.length)
  }, [participants])

  return (
    <section className={styles.container}>
      <header>
        <h1 className={styles.title}>{title}</h1>
        <div className={styles['sub-title']}>{description}</div>
      </header>
      {hasGuarantorParticipant && (
        <MainInfo
          lease={lease}
          considerIncomeRef={participantRef}
          handleRefUpdated={handleRefUpdated}
          hasGuarantorParticipant
        />
      )}
      {existingTenants &&
        existingTenants.map(participant => (
          <ContactCard
            key={participant.id}
            participant={participant}
            onClickRemoveParticipant={onDisableTenant}
          />
        ))}
      {participants.map(
        (participant, index) =>
          participants.length > 1 &&
          index < participants.length - 1 && (
            <ContactCard
              key={participant.id}
              participant={participant}
              onClickRemoveParticipant={onClickRemoveParticipant}
            />
          )
      )}
      {participants.map(
        (participant, index) =>
          participants.length - 1 === index && (
            <div key={participant.id}>
              <TextField
                label="Nome completo"
                autoComplete="name"
                {...textFieldProps('fullName', participant)}
              />
              <TextField
                label="E-mail"
                autoComplete="email"
                helperText={participant.emailError && 'Preencha todos os campos corretamente'}
                {...textFieldProps('email', participant)}
              />
            </div>
          )
      )}
      <Button
        variant={ButtonVariant.DEFAULT}
        color={ButtonColor.TEXT_BLACK}
        className={styles.button}
        onClick={onClickAddParticipant}
        disabled={isAddButtonDisabled}
      >
        <div className={styles['itens-button']}>
          <svg aria-hidden="true" className={styles.icon}>
            <use xlinkHref={AddIcon} />
          </svg>
          <p className={styles['text-button']}>Adicionar mais pessoas</p>
        </div>
      </Button>
    </section>
  )
}

InviteParticipant.propTypes = {
  title: PropTypes.string.isRequired,
  leaseId: PropTypes.string,
  externalId: PropTypes.string,
  type: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]).isRequired,
  participantRef: PropTypes.shape({ current: PropTypes.instanceOf({}) }),
  handleRefUpdated: PropTypes.func.isRequired,
  existingTenants: PropTypes.arrayOf(PropTypes.shape({})),
  hasGuarantorParticipant: PropTypes.bool,
  lease: PropTypes.shape({
    participants: PropTypes.arrayOf(PropTypes.shape({})),
  }),
}

InviteParticipant.defaultProps = {
  participantRef: PropTypes.shape({}),
  existingTenants: [],
  leaseId: '',
  externalId: '',
  hasGuarantorParticipant: false,
  lease: {
    participants: [],
  },
}

export default withAuth(React.memo(InviteParticipant))

import React, { useCallback } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import Plus from '_assets/images/ic-plus-slim.svg'

import styles from './styles.css'

const InputControls = ({ disabled, maxValue, minValue, valuePace, setValue, value, name }) => {
  const handleIncrementValue = useCallback(() => {
    if (value >= maxValue) {
      setValue(prevProps => ({ ...prevProps, [name]: maxValue }))
    } else {
      setValue(prevProps => ({ ...prevProps, [name]: value + valuePace }))
    }
  }, [maxValue, name, setValue, value, valuePace])

  const handleDecrementValue = useCallback(() => {
    if (value <= minValue) {
      setValue(prevProps => ({ ...prevProps, [name]: minValue }))
    } else {
      setValue(prevProps => ({ ...prevProps, [name]: value - valuePace }))
    }
  }, [minValue, name, setValue, value, valuePace])

  return (
    <div className={styles.controls}>
      <button
        className={styles['controls-button']}
        type="button"
        onClick={handleDecrementValue}
        disabled={disabled}
        aria-label="Diminuir o valor"
      >
        <div
          className={classnames(styles['controls-less'], {
            [styles['icon-disabled']]: disabled,
          })}
          aria-hidden="true"
        />
      </button>
      <button
        className={styles['controls-button']}
        type="button"
        onClick={handleIncrementValue}
        disabled={disabled}
        aria-label="Aumentar o valor"
      >
        <svg
          className={classnames(styles['controls-icon'], { [styles.disabled]: disabled })}
          aria-hidden="true"
        >
          <use xlinkHref={Plus} />
        </svg>
      </button>
    </div>
  )
}

InputControls.propTypes = {
  disabled: PropTypes.bool,
  maxValue: PropTypes.number,
  minValue: PropTypes.number,
  valuePace: PropTypes.number,
  value: PropTypes.number.isRequired,
  setValue: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
}

InputControls.defaultProps = {
  disabled: false,
  maxValue: 0,
  minValue: 0,
  valuePace: 1,
}

export default React.memo(InputControls)

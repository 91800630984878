import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ palette, breakpoints }) => ({
  day: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateRows: 'repeat(2, auto)',
  },
  weekDay: {
    textTransform: 'capitalize',
  },
  number: {
    fontWeight: '500',
  },
  buttonContainer: {
    width: '7rem',
    height: '7rem',
    margin: '0.4rem',

    '&:hover': {
      color: 'white',
      backgroundColor: palette.primary.main,
    },

    [breakpoints.down('sm')]: {
      width: '6.8rem',
    },
  },
  defaultButton: {
    backgroundColor: 'white',
    color: palette.secondary.main,
  },
}))

import React, { useMemo, useCallback } from 'react'
import { navigate } from '@reach/router'
import PropTypes from 'prop-types'

import BaloonIcon from '_assets/images/baloon-chat.svg'
import Button, { ButtonColor, ButtonVariant } from '_components/button'
import { primaryColor } from '_utils/colors'

import styles from './styles.css'

const NegotiationNeedMoreData = ({ id }) => {
  const createSVGStyle = useMemo(() => ({ fill: primaryColor }), [])
  const createTextStyle = useMemo(() => ({ color: primaryColor }), [])

  const onResendClick = useCallback(() => {
    navigate(`/minhas-negociacoes/${id}/documentacao-extra`)
  }, [id])

  return (
    <div className={styles.main}>
      <div className={styles.content}>
        <svg aria-hidden="true" className={styles.icon} style={createSVGStyle}>
          <use xlinkHref={BaloonIcon} />
        </svg>
        <h1 style={createTextStyle}>Precisamos de mais dados!</h1>
        <p className={styles.text}>Um consultor entrará em contato para explicar a situação.</p>
        <p>Estamos torcendo!</p>
        <Button
          color={ButtonColor.PRIMARY}
          variant={ButtonVariant.BOLD}
          className={styles.button}
          onClick={onResendClick}
        >
          REENVIAR DOCUMENTOS
        </Button>
      </div>
    </div>
  )
}

NegotiationNeedMoreData.propTypes = {
  id: PropTypes.string.isRequired,
}

export default React.memo(NegotiationNeedMoreData)

import React, { memo } from 'react'
import PropTypes from 'prop-types'

const NavbarIcon = ({ isOpen }) =>
  isOpen ? (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
      <path stroke="#222" strokeLinecap="round" strokeWidth="3" d="M1 1l16 16M1 17L17 1" />
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg">
      <path d="M2 3H24.8829" stroke="#222" strokeWidth="3" strokeLinecap="round" />
      <path d="M2 12H24.8829" stroke="#222" strokeWidth="3" strokeLinecap="round" />
      <path d="M13.291 21H25.0001" stroke="#222" strokeWidth="3" strokeLinecap="round" />
    </svg>
  )

NavbarIcon.propTypes = {
  isOpen: PropTypes.bool,
}

NavbarIcon.defaultProps = {
  isOpen: false,
}

export default memo(NavbarIcon)

import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import LazyLoad from 'react-lazyload'
import { List, Map } from 'immutable'
import classnames from 'classnames'
import { Link, navigate } from '@reach/router'
import ImmutablePropsTypes from 'react-immutable-proptypes'
import hash from 'object-hash'
import ReactPlayer from 'react-player'
import memoizeOne from 'memoize-one'

import { Property as PropertyModel, User } from '_models'
import Button, { ButtonColor, ButtonFormat, ButtonVariant } from '_components/button'
import FloatingAction from '_components/floating-action'
import PropertyCarousel from '_views/property/property-carousel'
import GoogleMap from '_components/google-map'
import HereMap from '_components/here-map'
import LocationSection from '_components/location-section'
import SpaceshipIcon from '_components/icon-components/spaceship-icon'
import ArrowIcon from '_assets/images/angle-left-solid.svg'
import LocationIcon from '_assets/images/location.svg'
import AreaIcon from '_assets/images/ic-tamanho.svg'
import RoomIcon from '_assets/images/ic-quarto.svg'
import BathroomIcon from '_assets/images/ic-banheiro.svg'
import GarageIcon from '_assets/images/ic-carro.svg'
import { toTitleCase } from '_utils/helpers'
import {
  getProperty,
  likeProperty,
  bulkLikeProperty,
  dislikeProperty,
  bulkDislikeProperty,
  unDislikeProperty,
  unLikeProperty,
  GET_PROPERTY,
} from '_modules/property/actions'
import { getBooking } from '_modules/booking/actions'
import { getPropertyById } from '_modules/property/selectors'
import { getProperty as getPropertyService } from '_services/property'
import DislikeModal from '_components/modals/dislike-modal'
import LikeModal from '_components/modals/like-modal'
import Breadcrumb from '_components/breadcrumb'
import { parseQuerystring, buildQuerystring } from '_utils/querystring'
import { setFilter, searchProperties, GET_PROPERTIES } from '_modules/search/actions'
import { getAllProposals } from '_modules/proposal/actions'
import Building from '_components/building-animated'
import { getPropertyAddress, getPropertyDefaultTitle } from '_utils/property'
import { sendEvent } from '_utils/mixpanel'
import SEO from '_components/seo'
import PropertyReducer from '_modules/property/reducer'
import { TABLET } from '_config/media-queries'
// import PetsIcon from '_assets/images/pets-icon.svg'
import FloorIcon from '_assets/images/floor-icon.svg'
import DislikeIcon from '_assets/images/trash-alt-solid.svg'
import LoveIcon from '_assets/images/ic-gostei.svg'
import PicturesFullScreen from '_components/modal-pictures-full-screen'
import { getProposalsByExternalId } from '_modules/proposal/selectors'
import ModalScheduleVisit from '_components/modal-schedule-visit'
import { RedirectContext } from '_context/redirect'
import { createListingViewLog } from '_services/logs'
import {
  viewTenSeconds,
  viewPicture,
  logLikedListing,
  logUnlikedListing,
  logDislikedListing,
  logUndislikedListing,
  viewMap,
  viewStreet,
  logScheduleClick,
} from '_modules/logs/actions'
import { searchHighlights, GET_HIGHLIGHTS } from '_modules/highlights/actions'
import { getDetailedHighlights } from '_modules/highlights/selectors'
import { primaryColor } from '_utils/colors'
import { GENERAL, FEATURE_FLAGS } from '_utils/constants'
import { FACEBOOK_ID, HERE_KEY } from '_config/environment'

import ActionsSection from './actions-section'
import RentSection from './rent-section'
import PropertySection from './property-section'
import FeatureSection from './feature-section'
import MapSection from './map-section'
import RelatedSection from './related-section'
import styles from './styles.css'

// How many times the viewTenSeconds can be sent to the BE
const TOTAL_COUNT = 36

const mapStateToProps = (state, { id }) => ({
  property: getPropertyById(state, id),
  isLoggedIn: !!state.user.authToken,
  isGettingProperty: !!state.loading.get(GET_PROPERTY.ACTION),
  getPropertyErrors: state.error.get(GET_PROPERTY.ACTION),
  user: state.user,
  query: state.search.query,
  relatedProperties: getDetailedHighlights(state),
  isGettingRelatedProperties: !!state.loading.get(GET_HIGHLIGHTS.ACTION),
  isMobile: state.server.isMobile,
  hasProposal: getProposalsByExternalId(state, id),
  booking: state.bookings.bookings,
})

const mapDispatchToProps = {
  getProperty,
  likeProperty,
  bulkLikeProperty,
  dislikeProperty,
  bulkDislikeProperty,
  unDislikeProperty,
  unLikeProperty,
  setFilter,
  getAllProposals,
  searchProperties,
  getBooking,
  searchHighlights,
  viewPicture,
  logLikedListing,
  logUnlikedListing,
  logDislikedListing,
  logUndislikedListing,
  viewMap,
  viewStreet,
  logScheduleClick,
  viewTenSeconds,
}

const renderIconText = (value, fieldName) => `${value || 0} ${fieldName}${value > 1 ? 's' : ''}`

const getPropertyFeatures = memoizeOne(property => [
  {
    icon: AreaIcon,
    text: !!property.livingArea && `${property.livingArea} m²`,
  },
  {
    icon: FloorIcon,
    text: property.floor && `${property.floor}º andar`,
  },
  // {
  //   icon: PetsIcon,
  //   text: property.pets && 'Aceita animais',
  // },
  {
    icon: RoomIcon,
    text: renderIconText(property.bedrooms, 'quarto'),
  },
  {
    icon: BathroomIcon,
    text: renderIconText(property.bathrooms, 'banheiro'),
  },
  {
    icon: GarageIcon,
    text: renderIconText(property.parkingSpots, 'vaga'),
  },
])

const textAdress = property => {
  let textAddress = ''
  if (property.address) {
    textAddress += toTitleCase(property.address)
  }
  if (property.neighborhood) {
    textAddress += textAddress
      ? `, ${toTitleCase(property.neighborhood.toLowerCase())}`
      : toTitleCase(property.neighborhood.toLowerCase())
  }
  if (property.city) {
    textAddress += textAddress
      ? `, ${toTitleCase(property.city.toLowerCase())}`
      : toTitleCase(property.city.toLowerCase())
  }
  if (property.state) {
    textAddress += textAddress ? ` - ${property.state}` : property.state
  }
  return textAddress
}

class Property extends PureComponent {
  static fetchData({ id }, { authToken = null }) {
    return [
      getPropertyService(authToken)(id).then(payload => ({
        properties: PropertyReducer(Map(), {
          type: GET_PROPERTIES.FULFILLED,
          payload,
        }),
      })),
    ]
  }

  static propTypes = {
    getProperty: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    property: PropTypes.instanceOf(PropertyModel),
    isLoggedIn: PropTypes.bool.isRequired,
    likeProperty: PropTypes.func.isRequired,
    bulkLikeProperty: PropTypes.func.isRequired,
    unLikeProperty: PropTypes.func.isRequired,
    dislikeProperty: PropTypes.func.isRequired,
    bulkDislikeProperty: PropTypes.func.isRequired,
    unDislikeProperty: PropTypes.func.isRequired,
    user: PropTypes.instanceOf(User),
    query: ImmutablePropsTypes.record.isRequired,
    setFilter: PropTypes.func.isRequired,
    getAllProposals: PropTypes.func.isRequired,
    hasProposal: PropTypes.shape(),
    location: PropTypes.shape({
      pathname: PropTypes.string,
      href: PropTypes.string,
      state: PropTypes.shape({
        search: PropTypes.string,
        scroll: PropTypes.number,
      }),
      search: PropTypes.shape(),
    }).isRequired,
    isGettingProperty: PropTypes.bool.isRequired,
    getPropertyErrors: ImmutablePropsTypes.map,
    searchProperties: PropTypes.func.isRequired,
    relatedProperties: ImmutablePropsTypes.list,
    isMobile: PropTypes.bool.isRequired,
    getBooking: PropTypes.func.isRequired,
    searchHighlights: PropTypes.func.isRequired,
    viewTenSeconds: PropTypes.func.isRequired,
    viewPicture: PropTypes.func.isRequired,
    logLikedListing: PropTypes.func.isRequired,
    logUnlikedListing: PropTypes.func.isRequired,
    logDislikedListing: PropTypes.func.isRequired,
    logUndislikedListing: PropTypes.func.isRequired,
    viewMap: PropTypes.func.isRequired,
    viewStreet: PropTypes.func.isRequired,
    logScheduleClick: PropTypes.func.isRequired,
    booking: ImmutablePropsTypes.list,
  }

  static defaultProps = {
    property: new PropertyModel(),
    user: new User(),
    getPropertyErrors: Map(),
    relatedProperties: List(),
    hasProposal: false,
    booking: List(),
  }

  constructor(props) {
    super(props)
    const { state } = props.location
    const componentState = {
      modalScheduleVisit: false,
      videoActive: false,
      photosActive: true,
      mapsActive: false,
      streetActive: false,
      dislikeModal: false,
      likeModal: false,
      saveQuery: state && state.search ? parseQuerystring(state.search) : undefined,
      isMobile: typeof window !== 'undefined' ? window.innerWidth <= TABLET : props.isMobile,
      isOpenPicturesFullScreen: false,
      openerWindow: null,
      filters: null,
      logId: null,
      viewedPictures: [],
      interval: null,
      countInterval: 0,
      propertyNotFound: false,
    }

    if (props.property.video) {
      componentState.videoActive = true
      componentState.photosActive = false
    }

    this.state = componentState
  }

  static getDerivedStateFromProps({ isSearchingProperties, searchPropertiesError, query }, state) {
    const newState = {
      isSearchingProperties,
    }

    if (typeof window !== 'undefined' && window.opener) {
      newState.openerWindow = window.opener
      window.opener = null
    }

    if (
      state.isSearchingProperties &&
      !isSearchingProperties &&
      !searchPropertiesError.size &&
      !state.saveQuery
    ) {
      return {
        ...newState,
        saveQuery: parseQuerystring(buildQuerystring(query)),
      }
    }

    return newState
  }

  componentDidMount() {
    const {
      id,
      location: { state },
      query,
      isLoggedIn,
    } = this.props

    if (this.state.openerWindow) {
      this.state.openerWindow.postMessage('getFilters', window.origin)
      window.addEventListener('message', this.onReceivedFiltersMessage)
    } else {
      this.setState({
        filters: {},
      })
    }

    const recommendationHash = this.props.location.search.includes('recommendation_hash')
      ? this.props.location.search
      : ''
    const propertyId = recommendationHash ? `${id}/${recommendationHash}` : id

    this.props.getProperty(propertyId)
    this.props.searchHighlights(id)

    if (isLoggedIn) {
      this.props.getAllProposals()
    }

    if (state && state.search && !query.locations.size) {
      const queryObj = parseQuerystring(state.search)
      this.props.setFilter(queryObj)
      this.props.searchProperties()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      id,
      isGettingProperty,
      getPropertyErrors,
      user,
      property,
      query,
      booking,
      getProperty: getPropertyAction,
      setFilter: setFilterAction,
      searchProperties: searchPropertiesAction,
      bulkLikeProperty: bulkLikePropertyAction,
      bulkDislikeProperty: bulkDislikePropertyAction,
      getBooking: getBookingAction,
    } = this.props

    if (
      (!!this.state.filters && !prevState.filters && !isGettingProperty) ||
      (prevProps.isGettingProperty && !isGettingProperty && !!this.state.filters)
    ) {
      this.createViewLog()
    }

    if (prevProps.id !== id) {
      getPropertyAction(id)
      if (this.state.interval && this.state.countInterval < 36) {
        clearInterval(this.state.interval)
      }
      this.setState({
        logId: null,
      })
    }

    if (prevProps.isGettingProperty && !isGettingProperty && !getPropertyErrors.size) {
      if (property.video !== null) {
        this.onGetPropertyWithVideo()
      } else {
        this.onGetPropertyWithoutVideo()
      }
    }

    if (
      !query.locations.size &&
      prevProps.isGettingProperty &&
      !isGettingProperty &&
      !getPropertyErrors.size
    ) {
      const locationObj = {
        city: property.city ? property.city.capitalize(true) : '',
        neighborhood: property.neighborhood ? property.neighborhood.capitalize(true) : '',
      }
      locationObj.name = locationObj.neighborhood || locationObj.city
      const objectHash = hash(locationObj)
      locationObj.hash = objectHash
      setFilterAction({
        price: property.rentPrice,
        locations: [locationObj],
        bedrooms: property.bedrooms,
      })
      searchPropertiesAction()
    }

    if (prevProps.user.authToken !== user.authToken) {
      if (user.likedProperties.size > 0) {
        bulkLikePropertyAction()
      }
      if (user.dislikedProperties.size > 0) {
        bulkDislikePropertyAction()
      }
    }

    if (prevProps.isGettingProperty && !isGettingProperty && !property.id) {
      this.setState({ propertyNotFound: true })
    }

    if (prevProps.user.dislikedOpened !== user.dislikedOpened) {
      window.close()
    }
    if (property.booking && property.booking.get('id') && !booking.size) {
      getBookingAction(property.booking.get('id'))
    }
  }

  componentWillUnmount() {
    if (this.state.interval) {
      clearInterval(this.state.interval)
    }
  }

  onReceivedFiltersMessage = event => {
    const { origin, data } = event
    if (origin !== window.origin) return

    if (typeof data === 'object' && data.message === 'filterValues') {
      const { message, ...filters } = data
      this.setState({
        filters,
      })
    }
  }

  onGetPropertyWithVideo = () => {
    this.setState({
      videoActive: true,
      photosActive: false,
      mapsActive: false,
      streetActive: false,
    })
  }

  onGetPropertyWithoutVideo = () => {
    this.setState({
      videoActive: false,
      photosActive: true,
      mapsActive: false,
      streetActive: false,
    })
  }

  onVideoClick = event => {
    event.preventDefault()

    this.setState({
      videoActive: true,
      photosActive: false,
      mapsActive: false,
      streetActive: false,
    })
    sendEvent('Imóvel: Selecionou visualizar video')
  }

  onPhotosClick = event => {
    event.preventDefault()

    this.setState({
      videoActive: false,
      photosActive: true,
      mapsActive: false,
      streetActive: false,
    })
    sendEvent('Imóvel: Selecionou visualizar fotos')
  }

  onMapsClick = async event => {
    const { logId } = this.state
    const { user } = this.props

    event.preventDefault()

    this.setState({
      videoActive: false,
      photosActive: false,
      mapsActive: true,
      streetActive: false,
    })

    if (logId) {
      this.props.viewMap(user.authToken, logId)
    }

    sendEvent('Imóvel: Selecionou visualizar mapa')
  }

  onStreetClick = async event => {
    const { logId } = this.state
    const { user } = this.props

    event.preventDefault()

    this.setState({
      videoActive: false,
      photosActive: false,
      mapsActive: false,
      streetActive: true,
    })

    if (logId) {
      this.props.viewStreet(user.authToken, logId)
    }

    sendEvent('Imóvel: Selecionou visualizar rua')
  }

  onDislikeClick = event => {
    event.preventDefault()
    const { user, id } = this.props

    const isLikedProperty = user.likedProperties.includes(id)
    const isDisLikedProperty = user.dislikedProperties.includes(id)

    if (!isLikedProperty && !user.dislikedOpened) {
      this.setState({
        dislikeModal: true,
      })
    } else if (isDisLikedProperty) {
      this.logUndislikedListing()
      this.props.unDislikeProperty(id)
      sendEvent(`Imóvel: Removeu das propriedades que não gosta`)
    } else if (isLikedProperty) {
      this.logUnlikedListing()
      this.props.unLikeProperty(id)
      sendEvent(`Imóvel: Removeu das propriedades que gosta`)
    } else {
      this.logDislikedListing()
      this.props.dislikeProperty(id)
      sendEvent(`Imóvel: Adicionou às propriedades que não gosta`)
    }
  }

  onScheduleClick = async () => {
    const { logId } = this.state
    const { user } = this.props

    if (logId) {
      this.props.logScheduleClick(user.authToken, logId)
    }

    if (this.props.hasProposal) {
      this.setState({ modalScheduleVisit: true })
      return
    }
    this.onConfirmScheduleVisit()
  }

  onConfirmScheduleVisit = () => {
    const { saveQuery } = this.state
    const { search } = this.props.location
    sendEvent(`Imóvel: Selecionou para agendar visita`)
    navigate(`/agendamento/${this.props.property.externalId}${search && search}`, {
      state: { saveQuery },
    })
  }

  onCloseModalScheduleVisit = () => {
    this.setState({
      modalScheduleVisit: false,
    })
  }

  onLikeClick = event => {
    event.preventDefault()
    const { user, id, location } = this.props
    const isLikedProperty = user.likedProperties.includes(id)
    const isDisLikedProperty = user.dislikedProperties.includes(id)

    if (this.props.isLoggedIn) {
      if (isLikedProperty) {
        this.logUnlikedListing()
        this.props.unLikeProperty(id)
        sendEvent(`Imóvel: Removeu das propriedades que gosta`)
      } else if (isDisLikedProperty) {
        this.logUndislikedListing()
        this.props.unDislikeProperty(id)
        sendEvent(`Imóvel: Removeu das propriedades que não gosta`)
      } else {
        this.logLikedListing()
        this.props.likeProperty(id)
        sendEvent(`Imóvel: Adicionou às propriedades que gosta`)
      }

      if (!user.likedOpened) {
        this.setState({
          likeModal: true,
        })
      }
    } else {
      this.logLikedListing()
      this.context.setLikedProperty(id)
      this.context.setRedirectUri(location.href)
      this.context.setOpenLogin(true)
    }
  }

  onPictureView = async picture => {
    const { viewedPictures, logId } = this.state
    const { user } = this.props

    if (!viewedPictures.includes(picture) && logId) {
      this.setState(state => ({
        viewedPictures: [...state.viewedPictures, picture],
      }))
      this.props.viewPicture(user.authToken, logId)
    }
  }

  // Check if the user is more than 6 minutes on the view
  // 6 checks every minutes during 6 minutes = 36
  onTenSecondsView = async () => {
    const { logId, countInterval, interval } = this.state
    const { user } = this.props

    this.setState(prevState => ({
      countInterval: prevState.countInterval + 1,
    }))

    if (logId && countInterval < TOTAL_COUNT) {
      this.props.viewTenSeconds(user.authToken, logId)
    }
    if (countInterval >= TOTAL_COUNT) {
      clearInterval(interval)
    }
  }

  getMakeProposalUrl = () => {
    const { property, booking } = this.props
    if (property.booking && booking.size) {
      const bookingId = property.booking.get('id')
      const leaseId = booking.find(visit => visit.id === bookingId)
      if (leaseId && leaseId.getIn(['lease', 'id'])) {
        if (FEATURE_FLAGS.productVersionOneEnabled) {
          return `/agendamento/${property.externalId}/mudar-horario/${leaseId.getIn([
            'lease',
            'id',
          ])}`
        }
        return `/proposta/apresentacao/${leaseId.getIn(['lease', 'id'])}`
      }
    }
    return ''
  }

  logLikedListing = async () => {
    const { logId } = this.state
    const { user } = this.props

    if (logId) {
      this.props.logLikedListing(user.authToken, logId)
    }
  }

  logUnlikedListing = async () => {
    const { logId } = this.state
    const { user } = this.props

    if (logId) {
      this.props.logUnlikedListing(user.authToken, logId)
    }
  }

  logDislikedListing = async () => {
    const { logId } = this.state
    const { user } = this.props

    if (logId) {
      this.props.logDislikedListing(user.authToken, logId)
    }
  }

  logUndislikedListing = async () => {
    const { logId } = this.state
    const { user } = this.props

    if (logId) {
      this.props.logUndislikedListing(user.authToken, logId)
    }
  }

  createViewLog = async () => {
    const { filters, logId, photosActive } = this.state
    const {
      id,
      user,
      property: { convertedPictures },
    } = this.props

    if (!logId) {
      try {
        const isLikedProperty = user.likedProperties.includes(id)
        const isDisLikedProperty = user.dislikedProperties.includes(id)

        const payload = {
          filters,
          listing: id,
          hasLiked: isLikedProperty,
          hasDisliked: isDisLikedProperty,
        }

        if (photosActive) {
          payload.picturesViewed = 1
          this.setState({
            viewedPictures: [convertedPictures.getIn([0, 'picture'])],
          })
        }
        const data = await createListingViewLog(user.authToken)(payload)
        // Repeats every 10 seconds
        const interval = setInterval(this.onTenSecondsView, 10000)

        this.setState({
          logId: data.id,
          interval,
        })
      } catch (e) {
        console.error('Failed to create log entry.')
      }
    }
  }

  dislikeOKAction = event => {
    event.preventDefault()
    const { user, id } = this.props
    const isDisLikedProperty = user.dislikedProperties.includes(id)
    const isLikedProperty = user.likedProperties.includes(id)

    if (isDisLikedProperty) {
      this.logUndislikedListing()
      this.props.unDislikeProperty(id)
      sendEvent(`Imóvel: Removeu das propriedades que não gosta`)
    } else if (isLikedProperty) {
      this.logUnlikedListing()
      this.props.unLikeProperty(id)
      sendEvent(`Imóvel: Removeu das propriedades que gosta`)
    } else {
      this.logDislikedListing()
      this.props.dislikeProperty(id)
      sendEvent(`Imóvel: Adicionou às propriedades que não gosta`)
    }

    this.setState({
      dislikeModal: false,
    })
  }

  closeDislikeModal = event => {
    event.preventDefault()
    this.setState({
      dislikeModal: false,
    })
    sendEvent(`Imóvel: Fechou modal de não gostei do imóvel`)
  }

  closeLikeModal = event => {
    event.preventDefault()
    this.setState({
      likeModal: false,
    })
    sendEvent(`Imóvel: Fechou modal de gostei do imóvel`)
  }

  mapsRef = ref => {
    this.map = ref
  }

  openModalPicturesFullScreen = event => {
    event.preventDefault()
    this.setState({
      isOpenPicturesFullScreen: true,
    })
  }

  closeModalPicturesFullScreen = event => {
    event.preventDefault()
    this.setState({
      isOpenPicturesFullScreen: false,
    })
  }

  static contextType = RedirectContext

  renderHeader = () => {
    const { videoActive, photosActive, mapsActive } = this.state
    const {
      property,
      property: { convertedPictures, video, coordinates },
    } = this.props

    if (videoActive) {
      return (
        <div className={styles['video-container']}>
          <ReactPlayer url={video} controls height="100%" width="100%" className={styles.video} />
        </div>
      )
    }
    if (photosActive) {
      return (
        <Fragment>
          {typeof window !== 'undefined' ? (
            <PropertyCarousel
              pictures={convertedPictures}
              className={styles['desktop-carousel']}
              primaryColor={primaryColor}
              openModalPicturesFullScreen={this.openModalPicturesFullScreen}
              onPictureView={this.onPictureView}
            />
          ) : (
            <div className={styles['image-container']}>
              <div className={styles.overlay} />
              <img
                src={convertedPictures.first().get('thumbnail')}
                className={styles['load-carousel']}
                alt="imagem do imóvel"
              />
            </div>
          )}
        </Fragment>
      )
    }
    if (mapsActive) {
      return (
        <div className={styles['map-container']} key="maps-view">
          <HereMap
            ref={this.mapsRef}
            propertyAddress={getPropertyAddress(property)}
            id="header-map"
            propertyId={this.props.id}
            isStatic
            coordinates={coordinates}
            primaryColor={primaryColor}
            hereApiKey={HERE_KEY}
          />
        </div>
      )
    }
    return (
      <div className={styles['map-container']} key="street-view">
        <GoogleMap
          ref={this.mapsRef}
          propertyAddress={getPropertyAddress(property)}
          id="street-view"
          isStreetView
        />
      </div>
    )
  }

  renderFloatingActions = () => {
    const {
      user,
      id,
      property: { booking },
    } = this.props

    const isLikedProperty = user.likedProperties.includes(id)
    const isDisLikedProperty = user.dislikedProperties.includes(id)

    if (booking && FEATURE_FLAGS.contractEnabled) {
      return (
        <FloatingAction
          className={classnames(styles.floating, styles['floating-confirm-contract'])}
        >
          <Button
            color={ButtonColor.PRIMARY}
            variant={ButtonVariant.BOLD}
            to={this.getMakeProposalUrl()}
          >
            FAZER PROPOSTA
          </Button>
        </FloatingAction>
      )
    }

    return (
      <FloatingAction className={styles.floating}>
        <Button
          color={isLikedProperty ? ButtonColor.TOGGLE_UNSELECTED : ButtonColor.TOGGLE_SELECTED}
          format={ButtonFormat.ICON}
          onClick={this.onDislikeClick}
          ariaLabel="Botão de não gostei da propriedade"
        >
          <svg aria-hidden="true" className={styles.dislike}>
            <use xlinkHref={DislikeIcon} />
          </svg>
        </Button>
        <Button
          color={ButtonColor.PRIMARY}
          variant={ButtonVariant.BOLD}
          format={ButtonFormat.ROUNDED}
          onClick={this.onScheduleClick}
        >
          AGENDAR VISITA
        </Button>
        <Button
          color={isDisLikedProperty ? ButtonColor.TOGGLE_UNSELECTED : ButtonColor.TOGGLE_SELECTED}
          format={ButtonFormat.ICON}
          onClick={this.onLikeClick}
          ariaLabel="Botão de gostei da propriedade"
        >
          <svg aria-hidden="true" className={styles.like}>
            <use xlinkHref={LoveIcon} />
          </svg>
        </Button>
      </FloatingAction>
    )
  }

  render() {
    const {
      videoActive,
      photosActive,
      mapsActive,
      streetActive,
      dislikeModal,
      likeModal,
      saveQuery,
      isOpenPicturesFullScreen,
      propertyNotFound,
    } = this.state
    const { property, isGettingProperty, relatedProperties, user, id, location } = this.props

    const markerColor = '0x890025'
    const coordinatesStr = `${property.address}, ${property.neighborhood} - ${property.city}, ${property.state} - ${property.postalCode}`

    const imageWidth = typeof window !== 'undefined' ? window.innerWidth : 1024
    const totalValue =
      property.rentPrice +
      property.condominiumFee +
      property.taxes +
      property.insurance +
      property.garbageFee

    if (!isGettingProperty && !property.id && propertyNotFound) {
      return (
        <div className={styles['property-not-found']}>
          <SpaceshipIcon className={styles.icon} primaryColor={primaryColor} />
          <p className={styles.title}>Imóvel não encontrado ou indisponível.</p>
          <Link to="/" className={styles['return-button']} onMouseDown={this.onMouseDown}>
            <svg alt="arrow" className={styles['arrow-icon']}>
              <use xlinkHref={ArrowIcon} />
            </svg>
            VOLTAR A PÁGINA INICIAL
          </Link>
        </div>
      )
    }

    const establishments = saveQuery && saveQuery.establishments ? saveQuery.establishments : []
    if (isGettingProperty || !property.id) {
      return (
        <div className={styles.loading}>
          <Building className={styles.building} />
        </div>
      )
    }
    const isLikedProperty = user.likedProperties.includes(id)
    const isDisLikedProperty = user.dislikedProperties.includes(id)

    return (
      <Fragment>
        <ModalScheduleVisit
          text="Você já enviou uma proposta para este imóvel"
          open={this.state.modalScheduleVisit}
          onClose={this.onCloseModalScheduleVisit}
          onConfirm={this.onConfirmScheduleVisit}
        />
        <SEO
          schema={GENERAL.brand.toLowerCase()}
          title={
            property.title
              ? `${GENERAL.brand}: ${property.title.capitalize(true)}`
              : `${GENERAL.brand}`
          }
          description={property.description || GENERAL.brand}
          path={`${GENERAL.websiteUrl}${location.pathname}`}
          contentType="website"
          image={`${property.id ? property.convertedPictures.first().get('thumbnail') : undefined}`}
          brand={GENERAL.brand}
          facebookAppId={FACEBOOK_ID}
        />
        <section
          className={classnames(styles.header, {
            [styles['header-with-grid']]: !photosActive,
            [styles['load-carousel-header']]: photosActive && typeof window === 'undefined',
          })}
        >
          {this.renderHeader(imageWidth, coordinatesStr, markerColor)}
        </section>
        <section className={styles.content}>
          <Breadcrumb className={styles.breadcrumb} />
          <ActionsSection
            videoActive={videoActive}
            photosActive={photosActive}
            mapsActive={mapsActive}
            streetActive={streetActive}
            onVideoClick={this.onVideoClick}
            onPhotosClick={this.onPhotosClick}
            onMapsClick={this.onMapsClick}
            onStreetClick={this.onStreetClick}
            className={styles.actions}
            hasVideo={property.video}
          />
          <LocationSection
            text={getPropertyDefaultTitle(property)}
            id={property.externalId}
            icon={LocationIcon}
            address={textAdress(property) || ''}
            className={styles.location}
          />
          <RentSection
            className={styles.rent}
            title="Preço"
            rentPrice={property.rentPrice}
            condominium={property.condominiumFee}
            fee={property.taxes}
            insurance={property.insurance}
            garbageFee={property.garbageFee}
            totalValue={totalValue}
            onDislikeClick={this.onDislikeClick}
            onScheduleClick={this.onScheduleClick}
            onLikeClick={this.onLikeClick}
            makeProposalUrl={this.getMakeProposalUrl()}
            activeLease={this.props.user && this.props.user.activeLease}
          />
          <div className={styles['sticky-rent']}>
            <div>
              <RentSection
                className={styles['rent-section']}
                title="Preço"
                rentPrice={property.rentPrice}
                condominium={property.condominiumFee}
                fee={property.taxes}
                insurance={property.insurance}
                garbageFee={property.garbageFee}
                totalValue={totalValue}
                onDislikeClick={this.onDislikeClick}
                onScheduleClick={this.onScheduleClick}
                onLikeClick={this.onLikeClick}
                isLikedProperty={isLikedProperty}
                isDisLikedProperty={isDisLikedProperty}
                booking={property.booking}
                makeProposalUrl={this.getMakeProposalUrl()}
                activeLease={this.props.user && this.props.user.activeLease}
              />
            </div>
          </div>
          {property.description && (
            <PropertySection
              title="Descrição"
              description={property.description}
              className={styles.property}
            />
          )}
          <FeatureSection
            className={styles.features}
            title="Características"
            features={getPropertyFeatures(property)}
            primaryColor={primaryColor}
          />
          <MapSection
            title="Deslocamento"
            address="Faculdade"
            propertyPlace={property}
            className={styles.map}
            establishments={establishments}
            id={id}
            coordinates={property.coordinates}
            primaryColor={primaryColor}
            citiesArray={GENERAL.citiesArray}
          />
          {property.listingDescription && (
            <PropertySection
              title="Imóvel"
              description={property.listingDescription}
              className={styles.listing}
            />
          )}
          {property.condominiumDescription && (
            <PropertySection
              title="Condomínio"
              description={property.condominiumDescription}
              className={styles.condominium}
            />
          )}
          {!!relatedProperties.size && (
            <LazyLoad once>
              <RelatedSection
                title="Imóveis parecidos"
                properties={relatedProperties}
                className={styles.related}
              />
            </LazyLoad>
          )}
        </section>
        {this.renderFloatingActions()}
        {dislikeModal && (
          <DislikeModal
            closeModal={this.closeDislikeModal}
            okAction={this.dislikeOKAction}
            primaryColor={primaryColor}
          />
        )}
        {likeModal && (
          <LikeModal
            closeModal={this.closeLikeModal}
            mainAction={this.closeLikeModal}
            primaryColor={primaryColor}
          />
        )}
        {isOpenPicturesFullScreen && (
          <PicturesFullScreen
            primaryColor={primaryColor}
            closeModal={this.closeModalPicturesFullScreen}
            openModal={isOpenPicturesFullScreen}
            titleText="Modal pictures full screen"
            onPictureView={this.onPictureView}
            externalId={id}
          />
        )}
      </Fragment>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Property)

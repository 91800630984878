import React from 'react'

import Button from '_components/material/button'
import withAuth from '_hocs/with-auth'
import CanceledVisitIcon from '_assets/images/phone-circle.svg'

import styles from './styles.css'

const CanceledVisit = () => (
  <section className={styles.container}>
    <svg aria-hidden="true" className={styles.icon}>
      <use xlinkHref={CanceledVisitIcon} />
    </svg>
    <h1 className={styles.title}>Visita cancelada</h1>
    <p className={styles.description}>
      Sua visita foi cancelada com sucesso. Você ainda poderá ver novos imóveis na{' '}
      <b>lista de imóveis</b>.
    </p>
    <Button variant="contained" color="primary" to="/buscar" className={styles.action}>
      VER OUTROS IMÓVEIS
    </Button>
  </section>
)

export default withAuth(CanceledVisit)

import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import { Grid, Typography, Button as MuiButton } from '@material-ui/core'
import ErrorIcon from '@material-ui/icons/Error'
import { useDispatch, useSelector } from 'react-redux'
import { navigate } from '@reach/router'

import HourglassIcon from '_assets/images/ic-hourglass.svg'
import Button from '_components/material/button'
import { WARRANTY_TRANSLATION } from '_utils/constants'
import { getLeaseById, checkIsCancellingLease, errorCancelLease } from '_modules/leases/selectors'
import TokenContext from '_context/token'
import { cancelLease } from '_modules/leases/actions'
import { usePrevious } from '_utils/hooks'
import useModal from '_hooks/use-modal'
import AlertModal from '_components/material/alert-modal'

import CreditAnalysisWrapper from '..'

import useStyles from './styles'

const CreditAnalysisPending = () => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const { leaseId } = useContext(TokenContext)
  const lease = useSelector(state => getLeaseById(state, leaseId))
  // TODO: integrate notification during credpago integration
  const notification = false
  const externalId = lease && lease.getIn(['listing', 'externalId'])
  const { modalOpen, handleModal } = useModal()

  const loading = useSelector(checkIsCancellingLease)
  const error = useSelector(errorCancelLease)
  const wasLoading = usePrevious(loading)

  useEffect(() => {
    if (!error && wasLoading && !loading) {
      navigate(`/analise-credito/${leaseId}/analise-encerrada`)
    }
  }, [error, leaseId, loading, wasLoading])

  const endLease = useCallback(() => {
    dispatch(cancelLease({ leaseId, externalId }))
  }, [dispatch, externalId, leaseId])

  const renderFooter = useMemo(
    () => (
      <Grid item>
        <MuiButton color="default" className={styles.footerButton} onClick={handleModal}>
          Encerrar negociação
        </MuiButton>
      </Grid>
    ),
    [handleModal, styles.footerButton]
  )

  return (
    <CreditAnalysisWrapper footer={renderFooter}>
      <Grid item className={styles.fullWidth}>
        <Grid className={styles.header}>
          <svg aria-hidden="true" className={styles.image}>
            <use xlinkHref={HourglassIcon} />
          </svg>
          <Grid>
            <Typography component="h1" variant="h3" className={styles.title}>
              Status: Em análise
            </Typography>
            {lease && (
              <Typography component="p" variant="h4" className={styles.subtitle}>
                Modalidade {WARRANTY_TRANSLATION[lease.get('warranty')]}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
      {notification && (
        <Grid item className={styles.fullWidth}>
          <Grid className={styles.notificationWrapper}>
            <Grid className={styles.iconWrapper}>
              <ErrorIcon className={styles.icon} />
              <Typography component="p" variant="h5" className={styles.text}>
                O valor do aluguel foi alterado pelo consultor para R$2.400. Caso tenha alguma
                dúvida entre em contato com a imobiliária.
              </Typography>
            </Grid>
            <Button variant="contained" className={styles.button}>
              Entendi
            </Button>
          </Grid>
        </Grid>
      )}
      {modalOpen && <AlertModal handleModal={handleModal} handleConfirmClick={endLease} />}
    </CreditAnalysisWrapper>
  )
}

export default CreditAnalysisPending

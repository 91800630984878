import { Map } from 'immutable'

import {
  SEND_EMAIL_LISTING_REGISTRATION,
  UPDATE_LISTING_REGISTRATION,
  GET_LISTING_REGISTRATION,
  CREATE_LISTING_REGISTRATION,
} from './actions'

export const listingRegistrationFormSelector = state => state.listingRegistrationForm.listing
export const currentPageSelectorSelector = state => state.listingRegistrationForm.currentPage
export const pricePredictionSelector = state => ({
  minPrice: state.listingRegistrationForm.listing.get('minPrice'),
  maxPrice: state.listingRegistrationForm.listing.get('maxPrice'),
  predictedPrice: state.listingRegistrationForm.listing.get('predictedPrice'),
})
export const isListingRegistrationLoading = ({ loading }) =>
  !!loading.get(UPDATE_LISTING_REGISTRATION.ACTION)

export const creatingListRegistration = ({ loading }) =>
  !!loading.get(CREATE_LISTING_REGISTRATION.ACTION)

export const creatingListRegistrationError = ({ error }) =>
  !!error.get(CREATE_LISTING_REGISTRATION.ACTION)

export const hasListingRegistrationError = state =>
  !!state.error.get(UPDATE_LISTING_REGISTRATION.ACTION)

export const isGetListingRegistrationLoading = ({ loading }) =>
  !!loading.get(GET_LISTING_REGISTRATION.ACTION)

export const listingRegistrationTaxes = state => ({
  taxesType: state.listingRegistrationForm.listing.get('taxesType'),
  taxes: state.listingRegistrationForm.listing.get('taxes'),
})
export const isSendEmailListingRegistrationLoading = ({ loading }) =>
  !!loading.get(SEND_EMAIL_LISTING_REGISTRATION.ACTION)
export const sendEmailError = ({ error }) =>
  error.get(SEND_EMAIL_LISTING_REGISTRATION.ACTION) || Map()

export const listingId = ({ listingRegistrationForm }) => listingRegistrationForm.listing.get('id')

export const getPropertyPhotosSelector = state =>
  state.listingRegistrationForm.listing.get('convertedPictures')

import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ breakpoints, palette }) => ({
  container: {
    justifyContent: 'center',
    textAlign: 'center',
    width: '100%',
    margin: 'auto auto 11rem',
    [breakpoints.down('sm')]: {
      width: '24.5rem',
      margin: 'auto auto 9.6rem',
    },
  },
  icon: {
    width: '15.4rem',
    height: '15.6rem',
    margin: 'auto',
    marginTop: '12rem',
    [breakpoints.down('sm')]: {
      width: '10rem',
      height: '10rem',
    },
  },

  title: {
    fontSize: '2rem',
    fontWeight: 'bold',
    lineHeight: '1.2',
    color: palette.secondary.dark,
    marginTop: '5.6rem',
    [breakpoints.down('sm')]: {
      fontSize: '1.6rem',
      marginTop: '4rem',
    },
  },

  button: {
    maxWidth: '24.5rem',
    margin: 'auto',
  },

  description: {
    fontSize: '1.6rem',
    lineHeight: '1.5',
    color: palette.secondary.main,
    margin: '2.4rem auto 3.2rem',
    fontWeight: 'normal',
    letterSpacing: 'normal',
    width: '100%',
    [breakpoints.down('sm')]: {
      fontSize: '1.4rem',
      margin: '1.6rem auto 4rem',
    },
  },

  bold: {
    fontWeight: 'bold',
    margin: '2.4rem 0.5rem 3.2rem',
    letterSpacing: 'normal',
    [breakpoints.down('sm')]: {
      fontSize: '1.4rem',
      margin: '1.6rem 0.3rem 4rem',
    },
  },

  action: {
    padding: '1rem 2.4rem',
    margin: 'auto',
    width: '10.6rem',
  },
}))

import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ breakpoints, palette }) => ({
  container: {
    alignItems: 'center',
    textAlign: 'left',
    width: '100%',
    maxWidth: '53.2rem',
    margin: 'auto auto 11rem',

    [breakpoints.down('sm')]: {
      margin: 'auto auto 9.6rem',
    },
  },
  content: {
    width: '100%',
    maxWidth: '34.7rem',
    padding: '2.4rem 0',
    justifyContent: 'center',

    margin: '5.6rem 0 0',

    [breakpoints.down('sm')]: {
      margin: 'auto',
      padding: '0.8rem',
      width: '31.6rem',
    },
  },

  title: {
    fontWeight: 'bold',
    lineHeight: 2.4,
    justifySelf: 'center',
    margin: 'auto auto 1.6rem',
    [breakpoints.down('sm')]: {
      fontSize: '1.6rem',
      maxWidth: '25.5rem',
      margin: 'auto 0 2.4rem',
    },
  },

  subTitle: {
    marginBottom: '0.8rem',
    [breakpoints.down('sm')]: {
      fontSize: '1.4rem',
      maxWidth: '31.6rem',
    },
  },

  description: {
    fontWeight: 400,
    lineHeight: 1.4,
    width: '33.5rem',
    margin: 'auto 0 1.8rem',
    [breakpoints.down('sm')]: {
      maxWidth: '30rem',
      fontSize: '1.2rem',
    },
  },

  descriptionHighlight: {
    fontWeight: 'bold',
    [breakpoints.down('sm')]: {
      fontSize: '1.2rem',
      lineHeight: '1.8rem',
    },
  },

  button: {
    marginTop: '4.8rem',
    width: '100%',
    maxWidth: '33.6rem',
    margin: 'auto',
    [breakpoints.down('sm')]: {
      width: '31.6rem',
    },
  },

  buttonSecondary: {
    fontWeight: 'bold',
    maxWidth: '24.5rem',
    width: '100%',
    margin: '4rem auto',
    color: palette.secondary.main,
    display: 'flex',
    flexDirection: 'column',
  },

  subDescription: {
    width: '26.3rem',
    justifyContent: 'flex-end',
    [breakpoints.down('sm')]: {
      maxWidth: '27.6rem',
    },
  },

  choicesCard: {
    width: '33.6rem',
    height: '4rem',
    margin: '1.6rem 0 0.2rem',
    padding: '0 1.5rem',

    [breakpoints.down('sm')]: {
      width: '31.6rem',
    },
  },

  choices: {
    '& .MuiSvgIcon-root': {
      width: '2.28rem',
      height: '1.8rem',
    },

    margin: 'auto auto 11.7rem',
  },

  choicesCardObs: {
    color: palette.text.primary,
    fontWeight: '500',
  },

  building: {
    display: 'flex',
    height: '18rem',
    margin: '20rem auto',
  },
}))

import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import SaveIcon from '_assets/images/ic-save.svg'
import Button, { ButtonVariant, ButtonColor, ButtonFormat } from '_components/button'

import styles from './styles.css'

const WarrantyFeedback = () => {
  const isNotMainUser = useSelector(state => !state.user.fullName)

  const contentScreen = useMemo(() => {
    if (isNotMainUser) {
      return {
        title: 'Agora é só esperar!',
        description: (
          <h2 className={styles.description}>
            Vamos analisar os seus documentos e assim que tivermos um resultado informaremos o
            locatário principal.
          </h2>
        ),
        url: '/',
      }
    }
    return {
      title: 'Pronto, agora é só esperar!',
      description: (
        <h2 className={styles.description}>
          Você poderá conferir o resultado no menu em
          <span className={styles.bold}> Negociações.</span>
        </h2>
      ),
      url: '/minhas-negociacoes',
    }
  }, [isNotMainUser])

  return (
    <section className={styles.container}>
      <svg aria-hidden="true" className={styles.icon}>
        <use xlinkHref={SaveIcon} />
      </svg>
      <h1 className={styles.title}>{contentScreen.title}</h1>
      {contentScreen.description}
      <Button
        color={ButtonColor.PRIMARY}
        variant={ButtonVariant.BOLD}
        format={ButtonFormat.ROUNDED}
        className={styles.action}
        to={contentScreen.url}
        aria-label="Ir para minhas negociações"
      >
        ENTENDI
      </Button>
    </section>
  )
}

export default React.memo(WarrantyFeedback)

import React, { useMemo } from 'react'
import classnames from 'classnames'
import TextField from '@material-ui/core/TextField'
import InputMask from 'react-input-mask'
import PropTypes from 'prop-types'

import Button, { ButtonColor, ButtonVariant, ButtonFormat } from '_components/button'

import useStyles from '../styles'

import styles from './styles.css'

const MAX_FIREBASE_CODE_LENGTH = 11

const InsertCode = ({ userInfo, onChange, isLoading, onNumberChange }) => {
  const classes = useStyles()

  const isDisabled = useMemo(() => !(userInfo.userInputCode.length === MAX_FIREBASE_CODE_LENGTH), [
    userInfo.userInputCode.length,
  ])

  return (
    <div className={styles.content}>
      <div className={styles.login}>
        <h1 className={styles.title}>Insira o código enviado</h1>
        <div className={styles['firebase-code']}>
          <InputMask
            mask="9 9 9 9 9 9"
            maskChar=""
            className={classnames(classes.textField, styles.textfield)}
            variant="outlined"
            margin="none"
            label="Código"
            name="userInputCode"
            value={userInfo.userInputCode}
            onChange={onChange}
            error={!!userInfo.error}
            helperText={userInfo.error}
            fullWidth
            type="tel"
          >
            {inheritedProps => <TextField {...inheritedProps} />}
          </InputMask>
        </div>
      </div>
      <div className={styles.footer}>
        <div className={styles.buttons}>
          <Button
            color={ButtonColor.PRIMARY}
            variant={ButtonVariant.BOLD}
            format={ButtonFormat.SQUARED}
            className={styles.button}
            disabled={isDisabled || isLoading}
            isLoading={userInfo.loading || isLoading}
            type="submit"
          >
            CONFIRMAR
          </Button>
          <Button color={ButtonColor.TEXT_BLACK} className={styles.link} onClick={onNumberChange}>
            Não recebeu o código?
          </Button>
        </div>
      </div>
    </div>
  )
}

InsertCode.propTypes = {
  userInfo: PropTypes.shape({
    userInputCode: PropTypes.string,
    error: PropTypes.string,
    loading: PropTypes.bool,
  }).isRequired,
  onChange: PropTypes.func,
  onNumberChange: PropTypes.func,
  isLoading: PropTypes.bool,
}

InsertCode.defaultProps = {
  onChange: () => {},
  onNumberChange: () => {},
  isLoading: false,
}

export default React.memo(InsertCode)

import React, { useCallback, useMemo, useEffect, useState } from 'react'
import { Link, useNavigate, Redirect, useParams } from '@reach/router'
import { useSelector, useDispatch } from 'react-redux'
import Slider, { Handle } from 'rc-slider'
import Tooltip from 'rc-tooltip'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import 'rc-slider/assets/index.css'
import RepparaGift from '_assets/gifs/reppara.gif'
import TooltipSvg from '_assets/images/tooltip.svg'
import ArrowIcon from '_assets/images/angle-left-solid.svg'
import HeartHouseIcon from '_assets/images/nps-content.svg'
import Button, { ButtonColor, ButtonVariant, ButtonFormat } from '_components/button'
import { getLease, sendRate } from '_modules/leases/actions'
import { checkIsLoadingLease, getLeaseById, checkIsSendingRate } from '_modules/leases/selectors'
import { usePrevious } from '_utils/hooks'
import { shadeColor, primaryColor } from '_utils/colors'
import { FEATURE_FLAGS, LEASE_STATUS } from '_utils/constants'

import styles from './styles.css'

const sliderDefaultOptions = () => ({
  style: {
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    color: primaryColor,
    fontSize: '1.4rem',
  },
})

const Nps = () => {
  const { leaseId, contractId } = useParams()
  const navigate = useNavigate()

  const lease = useSelector(state => getLeaseById(state, leaseId))
  const isLoadingLease = useSelector(checkIsLoadingLease)
  const isSendingRate = useSelector(checkIsSendingRate)
  const dispatch = useDispatch()

  const [score, setScore] = useState(0)

  const wasLoadingLease = usePrevious(isLoadingLease)
  const wasSendingRate = usePrevious(isSendingRate)

  const color = useMemo(() => ({ fill: primaryColor }), [])
  const lighterColor = shadeColor(primaryColor)

  const sliderSettings = useMemo(
    () => ({
      min: 0,
      max: 10,
      defaultValue: 0,
      marks: {
        0: {
          ...sliderDefaultOptions(primaryColor),
          label: 0,
        },
        1: '',
        2: '',
        3: '',
        4: '',
        5: '',
        6: '',
        7: '',
        8: '',
        9: '',
        10: {
          ...sliderDefaultOptions(primaryColor),
          label: 10,
        },
      },
      step: null,
      trackStyle: [
        {
          backgroundColor: primaryColor,
          zIndex: 1,
          height: 8,
          marginLeft: -4,
        },
      ],
      railStyle: {
        height: 8,
      },
      dotStyle: {
        borderColor: lighterColor,
        backgroundColor: lighterColor,
        top: 0,
      },
      handleStyle: {
        borderColor: primaryColor,
        height: 8,
        width: 8,
        marginLeft: -4,
        marginTop: 0,
        backgroundColor: primaryColor,
        boxShadow: 'none',
      },
      activeDotStyle: { boxShadow: 'none' },
    }),
    [lighterColor]
  )

  const handleSlider = useCallback(
    props => {
      const { value, dragging, index, ...restProps } = props

      if (score !== value) {
        setScore(value)
      }

      return (
        <Tooltip overlay={value} placement="left" visible={dragging} key={index}>
          <Handle value={value} {...restProps}>
            <div className={styles.tooltip}>
              <svg aria-hidden="true" className={styles['tooltip-icon']} style={color}>
                <use xlinkHref={TooltipSvg} />
              </svg>
              <p className={classnames(styles['tooltip-number'], { [styles.ten]: value === 10 })}>
                {value}
              </p>
            </div>
          </Handle>
        </Tooltip>
      )
    },
    [color, score]
  )

  const onSendRateClick = useCallback(() => {
    dispatch(
      sendRate({
        leaseId,
        score,
      })
    )
  }, [dispatch, leaseId, score])

  useEffect(() => {
    if (leaseId) {
      dispatch(getLease(leaseId))
    }
  }, [dispatch, leaseId])

  useEffect(() => {
    if (wasLoadingLease && !isLoadingLease && !lease) {
      navigate('/minhas-negociacoes')
    }
  }, [isLoadingLease, lease, navigate, wasLoadingLease])

  useEffect(() => {
    if (wasSendingRate && !isSendingRate) {
      navigate(`/minhas-negociacoes/${leaseId}/contrato/${contractId}/concluido`)
    }
  }, [contractId, isSendingRate, leaseId, navigate, wasSendingRate])

  if (!lease) {
    return null
  }

  if (
    lease &&
    lease.status &&
    lease.status !== LEASE_STATUS.CONTRACT_SIGNED &&
    lease.status !== LEASE_STATUS.CONTRACT_READY
  ) {
    return <Redirect to="/minhas-negociacoes" noThrow />
  }

  return (
    <div className={styles.container}>
      <div className={styles['left-section']}>
        <svg aria-hidden="true" className={styles.icon} style={{ fill: primaryColor }}>
          <use xlinkHref={HeartHouseIcon} />
        </svg>
        <h1 className={styles.title}>Contrato assinado!</h1>
        <p className={styles.text}>
          Aguarde contato da imobiliária para mais informações sobre a retirada de chaves.
        </p>
        {FEATURE_FLAGS.creditAnalysisIntegrationEnabled && (
          <a
            href="https://www.reppara.com.br/contratar?plano=repara-total"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Link para reppara"
          >
            <img
              src={RepparaGift}
              alt="gif para acessar site da empresa Reppara"
              className={styles.reppara}
            />
          </a>
        )}
      </div>
      <div className={styles['right-section']}>
        <p className={styles['nps-text']}>
          {`Em uma escala de 0 a 10, o quanto você indicaria ${FEATURE_FLAGS.brand} a um amigo ou família?`}
        </p>
        <div className={styles.slider}>
          <Slider {...sliderSettings} handle={handleSlider} />
        </div>
        <div className={styles['button-container']}>
          <Button
            className={styles['send-button']}
            color={ButtonColor.PRIMARY}
            format={ButtonFormat.ROUNDED}
            variant={ButtonVariant.BOLD}
            onClick={onSendRateClick}
            isLoading={isSendingRate}
          >
            ENVIAR
          </Button>
        </div>
      </div>
      <Link to="/" className={styles['return-link']}>
        <svg aria-hidden="true" className={styles.icon}>
          <use xlinkHref={ArrowIcon} />
        </svg>
        VOLTAR PARA PÁGINA INICIAL
      </Link>
    </div>
  )
}

Nps.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  dragging: PropTypes.bool.isRequired,
  index: PropTypes.string.isRequired,
}

export default React.memo(Nps)

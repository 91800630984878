import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import Shiitake from 'shiitake'
import classnames from 'classnames'
import moment from 'moment'

import Tag, { TagVariant } from '_components/tag'
import RentDetail from '_components/rent-detail'
import LocationIcon from '_assets/images/menu-location.svg'
import HouseIcon from '_assets/images/floor-icon.svg'
import CalendarPage from '_components/calendar-page'
import { getPropertyDefaultTitle, getPropertyDefaultAddress } from '_utils/property'
import { LEASE_STATUS } from '_utils/constants'
import { primaryColor } from '_utils/colors'

import styles from './styles.css'

export const VisitCardTheme = {
  DEFAULT: 'default',
  NEGOTIATION: 'negotiation',
  VISIT_SUMMARY: 'visit-summary',
}

const VisitCard = ({ className, booking, available, isSmall, isReview, theme, fireInsurance }) => {
  const createSvgStyle = useMemo(() => ({ fill: primaryColor }), [])

  const date = useMemo(() => (booking.dateAndTime ? booking.dateAndTime.split('T')[0] : ''), [
    booking,
  ])
  const day = useMemo(
    () =>
      moment(date)
        .format('D')
        .split('Z')[0],
    [date]
  )

  const rejectedLease = useMemo(() => booking.status === LEASE_STATUS.REJECTED, [booking.status])

  const cancelled = useMemo(() => booking && booking.cancelled, [booking])

  const reserved = useMemo(() => booking && booking.listing.reserved, [booking])

  const contractSigned = useMemo(() => booking && booking.listing.contractSigned, [booking])

  const month = useMemo(() => moment(date).format('MMM'), [date])

  const formattedMonth = useMemo(() => month.charAt(0).toUpperCase() + month.slice(1), [month])

  const picture = useMemo(
    () =>
      booking.listing && booking.listing.convertedPictures[0]
        ? booking.listing.convertedPictures[0].thumbnail
        : null,
    [booking.listing]
  )

  const styleCard = useMemo(
    () => ({
      [styles.smaller]: isSmall,
      [styles['review-visit']]: isReview,
      [styles.unavailable]: rejectedLease || !available || contractSigned,
    }),
    [available, contractSigned, isReview, isSmall, rejectedLease]
  )

  const { rentPrice, condominiumFee, taxes } = booking.listing
  const totalPrice = rentPrice + condominiumFee + taxes + fireInsurance

  const styleTag = useMemo(
    () =>
      classnames(styles.tag, {
        [styles['tag-cancelled']]: cancelled && !contractSigned && !reserved,
        [styles['tag-position-visit']]: theme === 'default',
        [styles['tag-position-visit-summary']]: theme === 'visit-summary',
      }),
    [cancelled, contractSigned, reserved, theme]
  )

  const renderTag = useCallback(() => {
    let tagText = null

    if (rejectedLease) {
      tagText = 'ENCERRADA'
    } else if (!available) {
      tagText = 'INDISPONÍVEL'
    }

    if (contractSigned) {
      tagText = 'ALUGADO'
    } else if (reserved) {
      tagText = 'RESERVADO'
    } else if (cancelled) {
      tagText = 'VISITA CANCELADA'
    }

    if (!tagText) {
      return null
    }

    return <Tag text={tagText} variant={TagVariant.BOLD} className={styleTag} />
  }, [available, cancelled, contractSigned, rejectedLease, reserved, styleTag])
  return (
    <div className={styles.container}>
      {renderTag()}
      <div className={classnames(className, styles.card, styles[theme], styleCard)}>
        <img src={picture} alt="imagem do imóvel" />
        <div className={styles.info}>
          <CalendarPage
            className={styles['date-container']}
            month={formattedMonth}
            day={day}
            primaryColor={primaryColor}
          />
          <div className={styles['property-details']}>
            <div className={styles['title-container']}>
              <svg aria-hidden="true" style={createSvgStyle} className={styles['house-icon']}>
                <use xlinkHref={HouseIcon} />
              </svg>
              <Shiitake className={styles.title} lines={2} tagName="p">
                {getPropertyDefaultTitle(booking.listing)}
              </Shiitake>
            </div>
            <div className={styles.divider} />
            <div className={styles['location-container']}>
              <svg aria-hidden="true" style={createSvgStyle} className={styles.icon}>
                <use xlinkHref={LocationIcon} />
              </svg>
              <Shiitake className={styles.address} lines={2} tagName="p">
                {getPropertyDefaultAddress(booking.listing)}
              </Shiitake>
            </div>
          </div>
        </div>
        <RentDetail
          rentPrice={rentPrice}
          condominium={condominiumFee}
          fee={taxes}
          insurance={fireInsurance}
          totalValue={fireInsurance ? booking.totalPrice : totalPrice}
          className={styles['rent-detail']}
        />
      </div>
    </div>
  )
}

VisitCard.propTypes = {
  className: PropTypes.string,
  booking: PropTypes.shape(),
  available: PropTypes.bool,
  theme: PropTypes.string,
  isSmall: PropTypes.bool,
  isReview: PropTypes.bool,
  fireInsurance: PropTypes.number,
}

VisitCard.defaultProps = {
  className: '',
  booking: {},
  available: true,
  isSmall: false,
  isReview: false,
  theme: VisitCardTheme.DEFAULT,
  fireInsurance: null,
}

export default React.memo(VisitCard)

/* eslint-disable consistent-return */
import React, { useEffect, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { navigate } from '@reach/router'
import { Grid, Typography, SvgIcon } from '@material-ui/core'

import Button from '_components/material/button'
import IconFolder from '_assets/images/ic-folder.svg'
import { getLeaseById, checkIsGettingLease } from '_modules/leases/selectors'
import { getLease, getParticipantLease, cancelLease, CANCEL_LEASE } from '_modules/leases/actions'
import Building from '_components/building-animated'
import withAuthOrIncomeHelper from '_hocs/with-auth-or-income-helper'
import { getIncomeHelper } from '_modules/income-helper/selectors'
import { CREDIT_ANALYSIS_ROUTES, creditAnalysisFlow } from '_views/credit-analysis/router'
import useModal from '_hooks/use-modal'
import AlertModal from '_components/material/alert-modal'
import useOnSuccessCall from '_hooks/use-on-success'

import useStyles from './styles'
import MissingInfo from './missing-info'

const MissingData = ({ id, location }) => {
  const dispatch = useDispatch()
  const styles = useStyles()
  const lease = useSelector(state => getLeaseById(state, id))
  const currentIncomeHelper = useSelector(getIncomeHelper)
  const isLoading = useSelector(checkIsGettingLease)
  const { modalOpen, handleModal } = useModal()
  // Participant
  const token = useMemo(
    () =>
      location.search.includes('?token=') || location.search.includes('&token=')
        ? location.search.match(/[?&]token=([^&#]*)/)[1]
        : undefined,
    [location.search]
  )
  const currentLease = token ? currentIncomeHelper : lease
  // Get current lease
  useEffect(() => {
    if (!lease.id && id) {
      dispatch(getLease(id))
    }
  }, [dispatch, id, lease, token])

  // Get participant lease
  useEffect(() => {
    if (token && !currentIncomeHelper.id) {
      dispatch(
        getParticipantLease({
          leaseId: id,
          token,
        })
      )
    }
  }, [currentIncomeHelper.id, dispatch, id, token])

  const onClickChoose = useCallback(() => {
    if (lease || currentIncomeHelper.id) {
      if (currentLease.hasRequestedDocuments) {
        navigate(creditAnalysisFlow(CREDIT_ANALYSIS_ROUTES.REGISTER_DOCUMENTS, id, token))
        return
      }
      if (currentLease.hasRequestedPersonalData) {
        navigate(creditAnalysisFlow(CREDIT_ANALYSIS_ROUTES.REGISTER_INFORMATION, id, token))
      }
      // TODO: Add condition to the professional data
      // if (currentLease.hasRequestedProfessionalData) {
      //   navigate(
      //     `/garantia/${id}/registrar-contratante-passo-3/${token ? `?token=${token}` : ``}`
      //   )
      // }
    }
  }, [currentIncomeHelper.id, currentLease, id, lease, token])

  const endLease = useCallback(() => {
    dispatch(cancelLease({ id, externalId: lease.getIn(['listing', 'externalId']) }))
  }, [dispatch, id, lease])

  const handleClosedLease = useCallback(() => {
    navigate(creditAnalysisFlow(CREDIT_ANALYSIS_ROUTES.CLOSED_LEASE, id))
  }, [id])
  useOnSuccessCall(CANCEL_LEASE.ACTION, handleClosedLease)
  if (isLoading || !currentLease.id) {
    return <Building className={styles.building} isSmall />
  }

  return (
    <Grid component="section" container direction="column" className={styles.container}>
      {!(lease || currentIncomeHelper.id) ? (
        <Building className={styles.building} />
      ) : (
        <Grid container direction="column" className={styles.content}>
          <SvgIcon aria-label="Ícone de uma pasta de arquivo" className={styles.icon}>
            <use xlinkHref={IconFolder} />
          </SvgIcon>
          <Typography variant="h3" component="h1" color="secondary" className={styles.title}>
            Opa, ainda precisamos de alguns documentos.
          </Typography>
          <Typography variant="h4" component="p" color="secondary" className={styles.subTitle}>
            Por favor, envie novamente:
          </Typography>
          <Grid className={styles.containerItems}>
            {currentLease && currentLease.hasRequestedDocuments && (
              <MissingInfo title="Documentos" />
            )}
            {currentLease && currentLease.hasRequestedPersonalData && (
              <MissingInfo title="Informações Pessoais" />
            )}
            {/* TODO: Add when professionalData view is complete */}
            {/* {currentLease &&
              currentLease.hasRequestedProfessionalData && (
                <MissingInfo title="Informações Profissionais" />
              )} */}
          </Grid>
          {currentLease && currentLease.requestedDataComment && (
            <Grid className={styles.containerItems}>
              <Typography
                variant="h4"
                component="h2"
                color="secondary"
                className={styles.titleItems}
              >
                Justificativa
              </Typography>
              <Typography variant="h5" component="p" color="secondary" className={styles.comment}>
                {currentLease.requestedDataComment}
              </Typography>
            </Grid>
          )}
          <Button
            className={styles.button}
            color="primary"
            variant="contained"
            onClick={onClickChoose}
          >
            Enviar
          </Button>
          {!token && (
            <Button className={styles.buttonSecondary} color="secondary" variant="text">
              Encerrar negociação
            </Button>
          )}
        </Grid>
      )}
      {modalOpen && <AlertModal handleModal={handleModal} handleConfirmClick={endLease} />}
    </Grid>
  )
}

MissingData.propTypes = {
  id: PropTypes.number.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
    pathname: PropTypes.string,
  }).isRequired,
}

export default withAuthOrIncomeHelper(React.memo(MissingData))

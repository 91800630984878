import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import {
  Paper,
  Radio,
  Typography,
  FormControlLabel,
  RadioGroup as MuiRadioGroup,
} from '@material-ui/core'

import useStyles from './styles'

const RadioGroup = ({ options, value, onChange, optionClassName }) => {
  const styles = useStyles()

  return (
    <MuiRadioGroup className={styles.choices} value={value} onChange={onChange}>
      {!!options.length &&
        options.map((option, index) => (
          <Paper
            variant="outlined"
            className={classnames(styles.choicesCard, optionClassName, {
              [styles.selected]: value === option.value,
            })}
          >
            <FormControlLabel
              value={option.value}
              control={<Radio color="primary" />}
              label={
                <Typography
                  component="span"
                  variant="subtitle1"
                  color="primary"
                  className={styles.choicesCardObs}
                >
                  {option.label}
                </Typography>
              }
              defaultChecked={!index}
            />
          </Paper>
        ))}
    </MuiRadioGroup>
  )
}

RadioGroup.propTypes = {
  optionClassName: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
    })
  ),
}

RadioGroup.defaultProps = {
  optionClassName: '',
  options: [],
}

export default React.memo(RadioGroup)

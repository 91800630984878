import React, { useState, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Dialog, Typography, Grid, IconButton, SvgIcon } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { useSelector, useDispatch } from 'react-redux'

import FeedbackHeader from '_assets/images/feedback-header.svg'
import FilledStar from '_assets/images/ic-star-full.svg'
import EmptyStar from '_assets/images/ic-star-empty.svg'
import { onMouseDown } from '_utils/helpers'
import MultilineTextField from '_components/modals/multiline-text-field'
import useOnSuccessCall from '_hooks/use-on-success'
import { sendBookingFeedback, SEND_BOOKING_FEEDBACK } from '_modules/booking/actions'
import { sendBookingFeedbackLoadingSelector } from '_modules/booking/selectors'
import { BOOKING_FEEDBACK_TYPE, INPUT_MAX_CHARACTERS } from '_utils/constants'
import Button from '_components/material/button'

import useStyles from './styles'

const FeedbackModal = ({ onClose, bookingId }) => {
  const dispatch = useDispatch()
  const [bookingRating, setRating] = useState(null)
  const [comment, setFeedback] = useState('')
  const [error, setError] = useState(false)
  const isSendingFeedback = useSelector(sendBookingFeedbackLoadingSelector)
  const [disabledRate, setDisabledRate] = useState(false)
  const styles = useStyles()

  const onStarClick = useCallback(event => {
    const { id } = event.currentTarget.dataset
    setRating(id)
  }, [])

  const onTypeFeedback = useCallback(event => {
    const { value } = event.currentTarget
    if (value.length <= INPUT_MAX_CHARACTERS.SMALL) {
      setFeedback(value)
      setError(false)
    } else {
      setError(true)
    }
  }, [])

  const helperText = useMemo(() => `${comment.length} / ${INPUT_MAX_CHARACTERS.SMALL}`, [comment])

  const onSendFeedback = useCallback(() => {
    const payload = {
      type: BOOKING_FEEDBACK_TYPE.CLIENT_BOOKING,
      bookingRating,
      comment,
    }
    dispatch(sendBookingFeedback(payload, bookingId))
    setDisabledRate(true)
  }, [bookingRating, comment, dispatch, bookingId])
  useOnSuccessCall(SEND_BOOKING_FEEDBACK.ACTION, onClose)

  return (
    <Dialog open onClose={onClose} aria-label="Avaliação de agendamento" className={styles.dialog}>
      <Grid className={styles.container} component="section">
        <Grid container direction="row" justify="flex-end">
          <IconButton
            onClick={onClose}
            variant="text"
            className={styles.closeButton}
            onMouseDown={onMouseDown}
            aria-label="Fechar dialogo"
          >
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid className={styles.content}>
          <Grid component="header" className={styles.header}>
            <SvgIcon aria-hidden="true" alt="feedback" className={styles.headerImage}>
              <use xlinkHref={FeedbackHeader} />
            </SvgIcon>
            <Typography variant="h1" className={styles.title}>
              Como foi a sua experiência em agendar uma visita online?
            </Typography>
          </Grid>
          <Grid className={styles.stars}>
            {[...Array(5)].map((star, index) => (
              <IconButton
                key={`star-${index + 1}`}
                className={classnames(styles.star, {
                  [styles.selected]: bookingRating >= index + 1,
                })}
                data-id={index + 1}
                onClick={onStarClick}
                disabled={disabledRate}
                aria-label={`Botão ${index + 1} estrela`}
                onMouseDown={onMouseDown}
                type="button"
              >
                <SvgIcon
                  aria-hidden="true"
                  viewBox={(bookingRating >= index + 1 ? FilledStar : EmptyStar).viewBox}
                >
                  <use xlinkHref={bookingRating >= index + 1 ? FilledStar : EmptyStar} />
                </SvgIcon>
              </IconButton>
            ))}
          </Grid>
          <MultilineTextField
            value={comment}
            onChange={onTypeFeedback}
            placeholder="Escreva algo..."
            helperText={helperText}
            error={error}
          />
          <Button
            variant="contained"
            fullWidth
            aria-label="Enviar avaliação"
            color="primary"
            className={styles.button}
            onClick={onSendFeedback}
            disabled={!bookingRating || isSendingFeedback}
          >
            Enviar avaliação
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  )
}

FeedbackModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  bookingId: PropTypes.number.isRequired,
}

export default React.memo(FeedbackModal)

import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ palette }) => ({
  rounded: {
    borderRadius: '2rem',
  },
  loading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '0.4rem',
  },
  whiteBackground: {
    backgroundColor: 'white',
    border: `0.1rem solid ${palette.gray.default}`,
    color: palette.text.primary,
    fontWeight: 500,
    '&:hover': {
      backgroundColor: palette.gray.light,
    },
  },
  grayWithBorder: {
    backgroundColor: palette.gray.main,
    border: `0.1rem solid ${palette.gray.default}`,
    color: `${palette.text.primary} !important`, // I wasn't able to do without this !important
    borderRadius: '0.4rem',
    fontWeight: 500,
    '&:hover': {
      backgroundColor: palette.gray.light,
    },
  },
}))

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { navigate, Redirect } from '@reach/router'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'

import Button, { ButtonColor, ButtonVariant } from '_components/button'
import { phoneLogin, PHONE_LOGIN } from '_modules/user/actions'
import { User } from '_models'
import phone from '_assets/images/phone.svg'
import { sendEvent } from '_utils/mixpanel'
import { RedirectContext } from '_context/redirect'

import styles from './styles.css'

const mapStateToProps = ({ user, error, loading }) => ({
  user,
  phoneLoginError: error.get(PHONE_LOGIN.ACTION),
  isLoadingPhoneLogin: !!loading.get(PHONE_LOGIN.ACTION),
})

const mapDispatchToProps = {
  phoneLogin,
}

class SchedulePhoneView extends PureComponent {
  static propTypes = {
    user: PropTypes.instanceOf(User),
    location: PropTypes.shape({
      state: PropTypes.shape({
        saveQuery: PropTypes.shape(),
        property: PropTypes.shape(),
        date: PropTypes.string,
        time: PropTypes.arrayOf(PropTypes.string),
        source: PropTypes.string,
        sourceId: PropTypes.string,
        anonymousId: PropTypes.string,
        staffHash: PropTypes.string,
        recommendationHash: PropTypes.string,
      }),
    }).isRequired,
    phoneLoginError: ImmutablePropTypes.map,
    isLoadingPhoneLogin: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    user: new User(),
    phoneLoginError: Map(),
  }

  componentDidUpdate(prevProps) {
    const { phoneLoginError, isLoadingPhoneLogin } = this.props
    if (prevProps.isLoadingPhoneLogin && !isLoadingPhoneLogin && !phoneLoginError.size) {
      this.onConfirmAccount()
    }
  }

  onConfirmAccount = () => {
    const {
      location: {
        state: {
          saveQuery,
          property,
          time,
          date,
          source,
          sourceId,
          anonymousId,
          staffHash,
          recommendationHash,
        },
      },
      user,
    } = this.props

    sendEvent('Agendamento Passo 3: Conta verificada')
    navigate('/agendamento/passo-4', {
      state: {
        saveQuery,
        property,
        time,
        date,
        user,
        source,
        sourceId,
        anonymousId,
        staffHash,
        recommendationHash,
      },
    })
  }

  handleStartButtonClick = event => {
    const {
      location: { state },
    } = this.props

    event.preventDefault()
    this.context.setRedirectUri('/agendamento/passo-5')
    this.context.setBookingState(state)
    navigate('/login')
  }

  static contextType = RedirectContext

  render() {
    const {
      location: { state },
    } = this.props

    if (!state || (state && !state.property)) {
      return <Redirect to="/" noThrow />
    }
    const {
      user: { authToken },
    } = this.props

    const hidePhoneButton = !!authToken
    if (hidePhoneButton) {
      this.onConfirmAccount()
    }
    return (
      <div className={styles.main}>
        <div className={styles.card}>
          <h3 className={styles.title}>Primeiros passos do seu cadastro</h3>
          <p className={styles.text}>
            Por favor, informe a seguir o número do seu celular para iniciar o seu cadastro
          </p>
          <svg alt="phone-icon" className={styles.phone}>
            <use xlinkHref={phone} />
          </svg>
          <Button
            color={ButtonColor.PRIMARY}
            variant={ButtonVariant.BOLD}
            className={styles.button}
            onClick={this.handleStartButtonClick}
          >
            COMEÇAR
          </Button>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SchedulePhoneView)

import moment from 'moment'

import { parseNumber } from '_utils/constants'

export const formatNumber = value => {
  return value && value.toLocaleString().replace(/,/g, '.')
}

export const fullDateFormat = ['dddd[,] DD [de] MMMM [de] YYYY', '[Às] HH:mm [horas]']

export const formatLongDate = date =>
  moment(date).format('dddd[,] DD [de] MMMM [de] YYYY [às] HH:mm [horas]')

export const formatDate = date => {
  return moment(date).format('DD/MM/YYYY')
}

export const formatHour = date => {
  return moment(date).format('H:mm')
}

export const formatCpf = cpf => (cpf ? cpf.replace(/[^0-9]+/g, '') : '')

export const formatPhoneNumber = phoneNumber =>
  phoneNumber ? phoneNumber.replace(/[^0-9+]+/g, '') : ''

export const formatCost = value => {
  if (value) {
    return value.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    })
  }
  return 'R$ 0,00'
}

export const PHONE_NUMBER_REGEX = /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})-?(\d{5}))$/

export const transformQuery = string =>
  string
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/\s/g, '-')
    .toLowerCase()

export const phoneNumberMask = value => {
  const number = parseNumber(value)
  if (number.length === 13) {
    return number.replace(/^(\d{2})(\d{2})(\d{5})(\d{4}).*/, '+$1 ($2) $3-$4')
  }
  if (number.length === 12) {
    return number.replace(/^(\d{2})(\d{2})(\d{4})(\d{4}).*/, '+$1 ($2) $3-$4')
  }

  return number
}

export const cpfCnpjMask = value => {
  const number = parseNumber(value)
  if (number.length === 16) {
    return number.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2}).*/, '$1.$2.$3/$4-$5')
  }
  if (number.length === 11) {
    return number.replace(/^(\d{3})(\d{3})(\d{3})(\d{2}).*/, '$1.$2.$3-$4')
  }

  return number
}

export const cepMask = value => {
  const number = parseNumber(value)
  return number.replace(/^([\d]{2})([\d]{3})([\d]{3})|^[\d]{2}.[\d]{3}-[\d]{3}.*/, '$1$2-$3')
}

import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Input from '_components/input'
import DeleteIcon from '_assets/images/ic-delete.svg'
import { onMouseDown } from '_utils/helpers'
import { primaryColor } from '_utils/colors'

import styles from './styles.css'

const FormIncomeHelper = ({
  handleValues,
  validateEmail,
  validateCPF,
  values: { id, name, email, cpf, monthlyIncome, ddd, phoneNumber, emailError, cpfError },
  index,
  deleteHelper,
}) => {
  // Handle values of inputs
  const eventHandler = useCallback(
    event => {
      handleValues(event, id)
    },
    [handleValues, id]
  )

  // Delete helper
  const handleDeleteHelper = useCallback(() => {
    deleteHelper(id)
  }, [deleteHelper, id])

  // Set input of currency
  const [isCurrency, setIsCurrency] = useState(false)
  const setInputCurrency = useCallback(() => {
    setIsCurrency(true)
  }, [])

  // Handle focus of input currency
  const [isAutoFocus, disableAutoFocus] = useState(true)
  const setDisableAutoFocus = useCallback(() => {
    disableAutoFocus(false)
  }, [])

  const eventValidateEmail = useCallback(
    event => {
      validateEmail(event, id)
    },
    [id, validateEmail]
  )

  const eventValidateCpf = useCallback(
    event => {
      validateCPF(event, id)
    },
    [id, validateCPF]
  )

  return (
    <div className={styles.card}>
      <div className={styles.action}>
        <p className={styles.info}>Pessoa {index}</p>
        <button
          className={styles['button-delete']}
          type="button"
          onMouseDown={onMouseDown}
          onClick={handleDeleteHelper}
        >
          <svg aria-hidden="true" className={styles.icon}>
            <use xlinkHref={DeleteIcon} />
          </svg>
        </button>
      </div>
      <Input
        className={styles.input}
        label="Renda / Salário"
        value={monthlyIncome}
        onChange={eventHandler}
        labelClassName={styles.label}
        ariaLabel="Entrada de texto para renda mensal"
        primaryColor={primaryColor}
        name="monthlyIncome"
        isCurrency={isCurrency}
        onClick={setInputCurrency}
        id={`${'monthlyIncome'}-${index}`}
        currencyAutoFocus={isAutoFocus}
        onBlur={setDisableAutoFocus}
      />
      <Input
        className={styles.input}
        label="Nome completo"
        value={name}
        onChange={eventHandler}
        labelClassName={styles.label}
        ariaLabel="Entrada de texto para nome Completo"
        primaryColor={primaryColor}
        name="name"
        id={`${'name'}-${index}`}
      />
      <Input
        className={styles.input}
        label="E-mail"
        value={email}
        onChange={eventHandler}
        type="email"
        onBlur={eventValidateEmail}
        error={emailError}
        labelClassName={styles.label}
        ariaLabel="Entrada de texto para e-mail"
        primaryColor={primaryColor}
        name="email"
        id={`${'email'}-${index}`}
      />
      <Input
        className={styles.input}
        label="CPF"
        value={cpf}
        onChange={eventHandler}
        type="tel"
        mask="999.999.999-99"
        onBlur={eventValidateCpf}
        error={cpfError}
        labelClassName={styles.label}
        ariaLabel="Entrada de texto para cpf"
        primaryColor={primaryColor}
        name="cpf"
        id={`${'cpf'}-${index}`}
      />
      <div className={styles.phoneNumber}>
        <Input
          className={classnames(styles.input, styles.ddd)}
          label="DDD"
          value={ddd}
          onChange={eventHandler}
          type="tel"
          mask="(99)"
          labelClassName={styles.label}
          ariaLabel="Entrada de texto para ddd"
          primaryColor={primaryColor}
          name="ddd"
          id={`${'ddd'}-${index}`}
        />
        <Input
          className={classnames(styles.input, styles.phone)}
          label="Telefone"
          value={phoneNumber}
          onChange={eventHandler}
          type="tel"
          mask="99999-9999"
          labelClassName={styles.label}
          ariaLabel="Entrada de texto para telefone"
          primaryColor={primaryColor}
          name="phoneNumber"
          id={`${'phoneNumber'}-${index}`}
        />
      </div>
      <div className={styles.line} />
    </div>
  )
}

FormIncomeHelper.propTypes = {
  handleValues: PropTypes.func.isRequired,
  validateEmail: PropTypes.func.isRequired,
  validateCPF: PropTypes.func.isRequired,
  values: PropTypes.shape().isRequired,
  index: PropTypes.number.isRequired,
  deleteHelper: PropTypes.func.isRequired,
}

export default React.memo(FormIncomeHelper)

import React, { useMemo } from 'react'
import AriaModal from 'react-aria-modal'
import PropTypes from 'prop-types'

import Button, { ButtonVariant, ButtonColor } from '_components/button'
import { onMouseDown } from '_utils/helpers'
import CloseIcon from '_assets/images/ic-fechar.svg'

import styles from './styles.css'

const ModalLayout = ({
  children,
  ariaModalTitle,
  svg,
  titleText,
  buttonText,
  onClose,
  primaryColor,
  onClickButton,
}) => {
  const textColorStyle = useMemo(
    () => ({
      color: primaryColor,
    }),
    [primaryColor]
  )

  const fillColorStyle = useMemo(
    () => ({
      fill: primaryColor,
    }),
    [primaryColor]
  )

  return (
    <AriaModal titleText={ariaModalTitle} underlayClass={styles.underlay} focusDialog>
      <div className={styles.modal}>
        <div className={styles.header}>
          <button
            type="button"
            className={styles.close}
            onClick={onClose}
            onMouseDown={onMouseDown}
            aria-label="fechar"
          >
            <svg aria-hidden="true" className={styles.icon}>
              <use xlinkHref={CloseIcon} />
            </svg>
          </button>
        </div>
        <div className={styles.body}>
          <svg aria-hidden="true" className={styles['svg-icon']} style={fillColorStyle}>
            <use xlinkHref={svg} />
          </svg>
          <p className={styles.title} style={textColorStyle}>
            {titleText}
          </p>
          <div className={styles.description}>{children}</div>
        </div>
        {buttonText && (
          <div className={styles.footer}>
            <Button
              variant={ButtonVariant.DEFAULT}
              color={ButtonColor.TEXT_BLACK}
              onClick={onClickButton}
            >
              <strong>{buttonText.toUpperCase()}</strong>
            </Button>
          </div>
        )}
      </div>
    </AriaModal>
  )
}

ModalLayout.propTypes = {
  children: PropTypes.node,
  svg: PropTypes.node.isRequired,
  ariaModalTitle: PropTypes.string.isRequired,
  titleText: PropTypes.string,
  buttonText: PropTypes.string,
  primaryColor: PropTypes.string,
  onClose: PropTypes.func,
  onClickButton: PropTypes.func,
}

ModalLayout.defaultProps = {
  onClose: () => {},
  buttonText: null,
  children: null,
  titleText: '',
  primaryColor: '#222',
  onClickButton: () => {},
}

export default React.memo(ModalLayout)

import { createReducer } from '_utils/redux'
import { Filter } from '_models'
import {
  FILTER_VALUES,
  COMMERCIAL_LISTINGS,
  RESIDENTIAL_LISTINGS,
  URL_LABELS,
  URL_NAMES,
  NUMBER_VALUES,
} from '_utils/url-friendly'

import { SET_SEARCH_FILTER } from './actions'

const INITIAL_STATE = new Filter()
const checkArrayValues = (initialState, value) =>
  initialState && initialState.length ? [...initialState, value] : [value]

const transformToNumber = value => (value ? Number(value) : value)
const transformUrlToNumber = (param, name) => transformToNumber(param.replace(name, ''))

const NUMBER_LABELS = NUMBER_VALUES.map(value => URL_LABELS[value])

const filter = createReducer(INITIAL_STATE, {
  [SET_SEARCH_FILTER.FULFILLED]: (state, { payload }) => {
    const params = payload
      .replace('buscar', '')
      .split('/')
      .filter(Boolean)

    const search = {}
    params.map((param, index) => {
      if (index === 0) {
        search[FILTER_VALUES.CITY] = param
      }
      if (
        COMMERCIAL_LISTINGS[param] &&
        COMMERCIAL_LISTINGS[param].length &&
        param !== URL_LABELS[FILTER_VALUES.COMMERCIAL]
      ) {
        search[FILTER_VALUES.COMMERCIAL] = checkArrayValues(
          search[FILTER_VALUES.COMMERCIAL],
          COMMERCIAL_LISTINGS[param]
        )
        search[FILTER_VALUES.LISTING_TYPE] = checkArrayValues(
          search[FILTER_VALUES.COMMERCIAL],
          COMMERCIAL_LISTINGS[param]
        )
      }
      if (RESIDENTIAL_LISTINGS[param] && RESIDENTIAL_LISTINGS[param].length) {
        search[FILTER_VALUES.RESIDENTIAL] = checkArrayValues(
          search[FILTER_VALUES.RESIDENTIAL],
          RESIDENTIAL_LISTINGS[param]
        )
        search[FILTER_VALUES.LISTING_TYPE] = checkArrayValues(
          search[FILTER_VALUES.RESIDENTIAL],
          RESIDENTIAL_LISTINGS[param]
        )
      }
      if (param.includes(URL_LABELS[FILTER_VALUES.PROPERTY_FEATURES])) {
        if (params.includes('semi-mobilia')) {
          search[FILTER_VALUES.PROPERTY_FEATURES] = 'SEMIMOBILIADO'
        }
        if (params.includes('com-mobilia')) {
          search[FILTER_VALUES.PROPERTY_FEATURES] = 'SIM'
        }
        if (params.includes('sem-mobilia')) {
          search[FILTER_VALUES.PROPERTY_FEATURES] = 'NÃO'
        }
      }
      if (param.includes(URL_LABELS[FILTER_VALUES.NEIGHBORHOODS])) {
        search[FILTER_VALUES.NEIGHBORHOODS] = checkArrayValues(
          search[FILTER_VALUES.NEIGHBORHOODS],
          param.replace('bairro-', '')
        )
      }
      if (param.includes(URL_LABELS[FILTER_VALUES.PETS])) {
        if (params.includes('com-pets')) {
          search[FILTER_VALUES.PETS] = 'SIM'
        }
        if (params.includes('sem-pets')) {
          search[FILTER_VALUES.PETS] = 'NÃO'
        }
      }
      if (param.includes(URL_LABELS[FILTER_VALUES.TAX_INCLUDED])) {
        search[FILTER_VALUES.TAX_INCLUDED] = true
      }
      if (param.includes(URL_LABELS[FILTER_VALUES.EXTERNAL_ID])) {
        search[FILTER_VALUES.EXTERNAL_ID] = param.replace(URL_LABELS[FILTER_VALUES.EXTERNAL_ID], '')
      }
      if (param.includes(URL_LABELS[FILTER_VALUES.RESIDENTIAL])) {
        search[FILTER_VALUES.PURPOSE_TYPE] = FILTER_VALUES.RESIDENTIAL
      }
      if (param.includes(URL_LABELS[FILTER_VALUES.COMMERCIAL])) {
        search[FILTER_VALUES.PURPOSE_TYPE] = FILTER_VALUES.COMMERCIAL
      }
      const numberValue = NUMBER_LABELS.find(value => param.includes(value))
      if (numberValue) {
        search[URL_NAMES[numberValue]] = transformUrlToNumber(param, numberValue)
      }
      if (URL_NAMES[params]) {
        search[URL_NAMES[params]] = URL_NAMES[params]
      }
      return null
    })
    return new Filter(search)
  },
})
export default filter

import React from 'react'
import { TextField } from '@material-ui/core'

import useStyles from './styles'

const RoundedTextField = ({ ...otherProps }) => {
  const styles = useStyles()
  return (
    <TextField
      fullWidth
      variant="outlined"
      margin="normal"
      className={styles.textfield}
      {...otherProps}
    />
  )
}

export default React.memo(RoundedTextField)

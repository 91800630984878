import * as logsActions from '_services/logs'
import { defineAction } from '_utils/redux'

export const VIEW_FIVE_SECONDS = defineAction('VIEW_FIVE_SECONDS')
export const VIEW_PICTURE = defineAction('VIEW_PICTURE')
export const LOG_LIKED_LISTING = defineAction('LOG_LIKED_LISTING')
export const LOG_UNLIKED_LISTING = defineAction('LOG_UNLIKED_LISTING')
export const LOG_DISLIKED_LISTING = defineAction('LOG_DISLIKED_LISTING')
export const LOG_UNDISLIKED_LISTING = defineAction('LOG_UNDISLIKED_LISTING')
export const VIEW_MAP = defineAction('VIEW_MAP')
export const VIEW_STREET = defineAction('VIEW_STREET')
export const LOG_SCHEDULE_CLICK = defineAction('LOG_SCHEDULE_CLICK')

export const viewTenSeconds = (key, id) => dispatch =>
  dispatch({
    type: VIEW_FIVE_SECONDS.ACTION,
    payload: logsActions.viewTenSeconds(key)(id),
  })

export const viewPicture = (key, id) => dispatch =>
  dispatch({
    type: VIEW_PICTURE.ACTION,
    payload: logsActions.viewPicture(key)(id),
  })

export const logLikedListing = (key, id) => dispatch =>
  dispatch({
    type: LOG_LIKED_LISTING.ACTION,
    payload: logsActions.logLikedListing(key)(id),
  })

export const logUnlikedListing = (key, id) => dispatch =>
  dispatch({
    type: LOG_UNLIKED_LISTING.ACTION,
    payload: logsActions.logUnlikedListing(key)(id),
  })

export const logDislikedListing = (key, id) => dispatch =>
  dispatch({
    type: LOG_DISLIKED_LISTING.ACTION,
    payload: logsActions.logDislikedListing(key)(id),
  })

export const logUndislikedListing = (key, id) => dispatch =>
  dispatch({
    type: LOG_UNDISLIKED_LISTING.ACTION,
    payload: logsActions.logUndislikedListing(key)(id),
  })

export const viewMap = (key, id) => dispatch =>
  dispatch({
    type: VIEW_MAP.ACTION,
    payload: logsActions.viewMap(key)(id),
  })

export const viewStreet = (key, id) => dispatch =>
  dispatch({
    type: VIEW_STREET.ACTION,
    payload: logsActions.viewStreet(key)(id),
  })

export const logScheduleClick = (key, id) => dispatch =>
  dispatch({
    type: LOG_SCHEDULE_CLICK.ACTION,
    payload: logsActions.logScheduleClick(key)(id),
  })

import { defineAction } from '_utils/redux'
import * as propertyService from '_services/property'

export const GET_HIGHLIGHTS = defineAction('GET_HIGHLIGHTS')

const QUERY = {
  furnished: 'furnished',
  price: 2000,
  transactionType: 'rent',
  bedrooms: '1',
  parkingSpots: '0',
}

export const searchHighlights = externalId => (dispatch, getState) => {
  const query = externalId ? { externalId } : QUERY

  return dispatch({
    type: GET_HIGHLIGHTS.ACTION,
    payload: propertyService.getSimilarProperties(getState().user.authToken)(query),
  })
}

import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import InputAdornment from '@material-ui/core/InputAdornment'
import CurrencyTextField from '@unicef/material-ui-currency-textfield'

import InputControls from './input-control'
import styles from './styles.css'
import useStyles from './styles'

const CurrencyInput = ({
  className,
  name,
  valuePace,
  maxValue,
  minValue,
  disabled,
  setValue,
  amount,
}) => {
  const classes = useStyles()

  const inputProps = useMemo(
    () => ({
      className: classes.input,
      endAdornment: (
        <InputAdornment
          className={classnames(styles.adornment, { [styles['adornment-disabled']]: disabled })}
          disableTypography
          disablePointerEvents
          position="end"
        >
          R$
        </InputAdornment>
      ),
    }),
    [classes.input, disabled]
  )

  const onChange = useCallback(
    (event, value) => setValue(prevProps => ({ ...prevProps, [name]: parseInt(value, 10) })),
    [name, setValue]
  )

  return (
    <div className={classnames(styles['input-container'], styles['grid-container'], className)}>
      <CurrencyTextField
        variant="outlined"
        InputProps={inputProps}
        value={amount}
        currencySymbol=""
        decimalCharacter=","
        digitGroupSeparator="."
        textAlign="left"
        onChange={onChange}
        leadingZero="deny"
        minimumValue={minValue}
        maximumValue={maxValue}
      />

      <InputControls
        disabled={disabled}
        name={name}
        maxValue={maxValue}
        minValue={minValue}
        valuePace={valuePace}
        setValue={setValue}
        value={amount}
      />
    </div>
  )
}

CurrencyInput.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  rules: PropTypes.shape({
    required: PropTypes.string,
    minLength: PropTypes.shape({
      value: PropTypes.number,
      message: PropTypes.string,
    }),
    pattern: PropTypes.shape({
      value: PropTypes.instanceOf(RegExp),
      message: PropTypes.string,
    }),
  }),
  valuePace: PropTypes.number,
  maxValue: PropTypes.number,
  minValue: PropTypes.number,
  disabled: PropTypes.bool,
  setValue: PropTypes.func.isRequired,
  amount: PropTypes.number.isRequired,
}

CurrencyInput.defaultProps = {
  className: '',
  name: '',
  rules: {},
  valuePace: 10,
  maxValue: 290,
  minValue: 40,
  disabled: false,
}

export default React.memo(CurrencyInput)

import React, { useCallback, useMemo, useReducer, useEffect, useState, useContext } from 'react'
import { navigate } from '@reach/router'
import { useSelector, useDispatch } from 'react-redux'

import WarrantyChip from '_views/credit-analysis/negotiation/warranty-chip'
import Button from '_components/material/button'
import { useWindowWidth, usePrevious } from '_utils/hooks'
import {
  FEATURE_FLAGS,
  MOBILE_BREAKPOINT,
  PARTICIPANTS_TYPE,
  WARRANTY_TYPES,
} from '_utils/constants'
import InputDocument from '_components/input-document'
import {
  getLeaseById,
  checkIsLoadingDocuments,
  checkIsUploadingParticipantsDocument,
} from '_modules/leases/selectors'
import { getIncomeHelper } from '_modules/income-helper/selectors'
import {
  uploadDocument,
  uploadParticipantsDocument,
  deleteDocument,
  deleteParticipantDocument,
} from '_modules/leases/actions'
import { getWarrantySelector } from '_modules/warranty/selectors'
import TokenContext from '_context/token'

import { CREDIT_ANALYSIS_ROUTES, creditAnalysisFlow } from '../../router'

import { INITIAL_STATE, reducer, ADD_DOCUMENTS, REMOVE_DOCUMENT, UPDATE_DOCUMENT } from './reducer'
import styles from './styles.css'

const CLIENT_ERROR = 400

// TODO: Refactor to material ui
const RegisterParticipantDocuments = () => {
  const windowWidth = useWindowWidth()
  const [documents, dispatchLocal] = useReducer(reducer, INITIAL_STATE)
  const dispatch = useDispatch()
  const { leaseId: id, token } = useContext(TokenContext)
  const [previousDocuments, setPreviousDocuments] = useState(undefined)

  // If the user is the mainTenant
  const activeLease = useSelector(state => getLeaseById(state, id))
  const externalId = useMemo(() => (activeLease ? activeLease.listing.get('externalId') : null), [
    activeLease,
  ])
  const isUploadingDocuments = useSelector(checkIsLoadingDocuments)
  const wasUploadingDocuments = usePrevious(isUploadingDocuments)
  const wasRequestingDocuments = usePrevious(activeLease && activeLease.hasRequestedDocuments)
  const currentIncomeHelper = useSelector(getIncomeHelper)
  const isUploadingHelperDocuments = useSelector(checkIsUploadingParticipantsDocument)
  const wasUploadingHelperDocuments = usePrevious(isUploadingHelperDocuments)
  const wasRequestingParticipantDocuments = usePrevious(currentIncomeHelper.hasRequestedDocuments)

  const participantType = useMemo(() => {
    if (!token) {
      return PARTICIPANTS_TYPE.MAIN_TENANT
    }
    if (currentIncomeHelper.category === PARTICIPANTS_TYPE.SPOUSE) {
      return PARTICIPANTS_TYPE.MAIN_TENANT
    }

    if (currentIncomeHelper.id) {
      return currentIncomeHelper.category
    }

    return PARTICIPANTS_TYPE.TENANT
  }, [currentIncomeHelper.category, currentIncomeHelper.id, token])

  const warrantyCategory = useMemo(() => {
    if (!token && activeLease) {
      return activeLease.warranty
    }

    if (currentIncomeHelper.id) {
      return currentIncomeHelper.lease.get('warranty')
    }

    return WARRANTY_TYPES.RAPID
  }, [activeLease, currentIncomeHelper.id, currentIncomeHelper.lease, token])
  const considerIncome = token
    ? currentIncomeHelper && currentIncomeHelper.considerIncome
    : activeLease && activeLease.considerIncome
  const documentCategories = useSelector(state =>
    getWarrantySelector(state, [warrantyCategory, participantType, considerIncome])
  )
  const requestedDocuments = useMemo(() => {
    if (currentIncomeHelper.hasRequestedDocuments) {
      return currentIncomeHelper.requestedDocuments
    }
    if (activeLease && activeLease.hasRequestedDocuments) {
      return activeLease.requestedDocuments
    }
    return false
  }, [
    activeLease,
    currentIncomeHelper.hasRequestedDocuments,
    currentIncomeHelper.requestedDocuments,
  ])

  const documentsNeeded = useMemo(() => {
    const lease = token ? currentIncomeHelper : activeLease
    if (lease) {
      if (requestedDocuments) {
        return requestedDocuments
          .map(document => documentCategories.find(doc => doc.value === Number(document)))
          .filter(document => document)
      }
      if (documentCategories && documentCategories.size) {
        return documentCategories && documentCategories.toJS()
      }
    }
    return []
  }, [activeLease, currentIncomeHelper, documentCategories, requestedDocuments, token])

  useEffect(() => {
    const lease = token ? currentIncomeHelper : activeLease
    if (lease && lease.documents.size && !previousDocuments) {
      setPreviousDocuments(
        lease.documents.toJS().map(document => ({
          url: document.file,
          documentCategory: document.documentCategory,
          title:
            document.fileName ||
            (documentsNeeded.find(name => name.id === document.documentCategory) &&
              documentsNeeded.find(name => name.id === document.documentCategory).title),
          documentId: document.id,
        }))
      )
    }
  }, [activeLease, currentIncomeHelper, documentsNeeded, previousDocuments, token])

  const isButtonDisabled = useMemo(() => {
    const lease = token ? currentIncomeHelper : activeLease
    return (
      documentsNeeded &&
      lease &&
      documentsNeeded
        .map(document => {
          if (!document) return null
          const isOptional = document.optional !== null
          const category = document.value.toString()
          if (isOptional) {
            return false
          }
          if (
            previousDocuments &&
            previousDocuments.find(file => file.documentCategory === document.id)
          ) {
            if (
              lease.hasRequestedDocuments &&
              (!documents.get(category) || documents.get(category).size === 0)
            ) {
              return true
            }
            return false
          }
          if (documents.get(category) !== undefined) {
            return !documents.get(document.id.toString()).size
          }

          return true
        })
        .reduce((total, value) => total || value)
    )
  }, [activeLease, currentIncomeHelper, documents, documentsNeeded, previousDocuments, token])

  const isMobile = useMemo(() => windowWidth < MOBILE_BREAKPOINT, [windowWidth])

  const handleAddDocuments = useCallback(
    payload =>
      dispatchLocal({
        type: ADD_DOCUMENTS,
        payload,
      }),
    []
  )

  const handleRemoveDocument = useCallback(
    payload =>
      dispatchLocal({
        type: REMOVE_DOCUMENT,
        payload,
      }),
    []
  )

  const handleDeletePreviousDocument = useCallback(
    documentId => {
      setPreviousDocuments(prevState =>
        prevState.filter(document => documentId !== document.documentId)
      )
      if (token) {
        dispatch(deleteParticipantDocument(documentId, currentIncomeHelper.id, id, token))
        return
      }
      dispatch(deleteDocument(activeLease.id, documentId))
    },
    [token, dispatch, activeLease, id, currentIncomeHelper]
  )

  const onUploadProgress = useCallback(
    ({ documentCategory, fileIndex }) => ({ loaded, total }) => {
      const percentage = Math.round((loaded * 100) / total)
      const payload = {
        type: UPDATE_DOCUMENT,
        payload: {
          percentage,
          isUploading: percentage < 100,
          documentCategory,
          id: fileIndex,
        },
      }
      dispatchLocal(payload)
    },
    []
  )

  // Validate response status
  // if has error, update the thumbnail
  // with error status
  const validateStatus = useCallback(
    ({ documentCategory, fileIndex }) => status => {
      if (status < CLIENT_ERROR) {
        return status >= 200 && status < 300
      }

      dispatchLocal({
        type: UPDATE_DOCUMENT,
        payload: {
          documentCategory,
          id: fileIndex,
          hasError: true,
          isUploading: false,
          percentage: 100,
        },
      })

      return false
    },
    []
  )

  const hasRequested = useCallback(
    (hasRequestedProfessionalData, hasRequestedPersonalData, hasRequestedDocuments) => {
      if (hasRequestedProfessionalData && !hasRequestedPersonalData) {
        navigate(`/garantia/${id}/registrar-contratante-passo-3/${token ? `?token=${token}` : ``}`)
        return
      }
      if (hasRequestedDocuments && !(hasRequestedProfessionalData || hasRequestedPersonalData)) {
        navigate(`/garantia/cadastro-finalizado/${token ? `?token=${token}` : ``}`)
        return
      }
      navigate(creditAnalysisFlow(CREDIT_ANALYSIS_ROUTES.REGISTER_INFORMATION, id, token))
    },
    [id, token]
  )

  const handleSendClick = useCallback(
    event => {
      event.preventDefault()
      const currentLease = !token ? activeLease : currentIncomeHelper
      // if the user sent a document before
      if (
        (activeLease || currentIncomeHelper.id) &&
        previousDocuments &&
        !documents.size &&
        !isButtonDisabled
      ) {
        const { hasRequestedProfessionalData, hasRequestedPersonalData, warranty } = currentLease
        const wasRequestingCurrent = !token
          ? wasRequestingDocuments
          : wasRequestingParticipantDocuments
        if (
          !token &&
          WARRANTY_TYPES.RAPID === warranty &&
          !wasRequestingCurrent &&
          FEATURE_FLAGS.creditAnalysisIntegrationEnabled
        ) {
          navigate(`/minhas-negociacoes/${id}/adicionar-locatario`)
          return
        }
        hasRequested(hasRequestedProfessionalData, hasRequestedPersonalData, wasRequestingCurrent)
      }
      // send documents to BE
      documents.entrySeq().forEach(async ([documentCategory, listOfDocuments]) => {
        if (!listOfDocuments.size) {
          return
        }

        const payload = {
          leaseId: id,
          externalId,
          documentCategory,
          onUploadProgress: onUploadProgress({
            documentCategory,
            fileIndex: 0,
          }),
          validateStatus: validateStatus({
            documentCategory,
            fileIndex: 0,
          }),
          fileName: [
            listOfDocuments
              .first()
              .get('file')
              .name.replace(',', ''),
          ],
        }

        if (listOfDocuments.size > 1) {
          const files = []
          const filesNames = []
          listOfDocuments.forEach(fileMap => {
            const file = fileMap.get('file')
            files.push(file)
            filesNames.push(file.name.replace(',', ''))
          })
          payload.files = files
          payload.filesNames = filesNames.join()
        } else {
          payload.file = listOfDocuments.get(0).get('file')
          payload.filesNames = [
            listOfDocuments
              .get(0)
              .get('file')
              .name.replace(',', ''),
          ]
        }
        if (token) {
          dispatch(
            uploadParticipantsDocument({
              ...payload,
              token,
            })
          )
          return
        }

        dispatch(uploadDocument(payload))
      })
    },
    [
      activeLease,
      currentIncomeHelper,
      dispatch,
      documents,
      externalId,
      hasRequested,
      id,
      isButtonDisabled,
      onUploadProgress,
      previousDocuments,
      token,
      validateStatus,
      wasRequestingDocuments,
      wasRequestingParticipantDocuments,
    ]
  )

  useEffect(() => {
    if (wasUploadingDocuments && !isUploadingDocuments) {
      const { hasRequestedProfessionalData, hasRequestedPersonalData, warranty } = activeLease
      const hasError = documents.filter(
        category => category.filter(document => document.get('hasError')).size
      ).size
      if (!hasError) {
        if (WARRANTY_TYPES.RAPID === warranty && FEATURE_FLAGS.creditAnalysisIntegrationEnabled) {
          if (!wasRequestingDocuments) {
            navigate(`/minhas-negociacoes/${id}/adicionar-locatario`)
            return
          }
        }
        hasRequested(hasRequestedProfessionalData, hasRequestedPersonalData, wasRequestingDocuments)
      }
    }
  }, [
    activeLease,
    documents,
    hasRequested,
    id,
    isUploadingDocuments,
    wasRequestingDocuments,
    wasUploadingDocuments,
  ])

  useEffect(() => {
    if (wasUploadingHelperDocuments && !isUploadingHelperDocuments) {
      const { hasRequestedProfessionalData, hasRequestedPersonalData } = currentIncomeHelper
      const hasError = documents.filter(
        category => category.filter(document => document.get('hasError')).size
      ).size
      if (!hasError) {
        hasRequested(
          hasRequestedProfessionalData,
          hasRequestedPersonalData,
          wasRequestingParticipantDocuments
        )
      }
    }
  }, [
    currentIncomeHelper,
    documents,
    hasRequested,
    isUploadingHelperDocuments,
    wasRequestingParticipantDocuments,
    wasUploadingHelperDocuments,
  ])

  return (
    <section className={styles.container}>
      {!token && <WarrantyChip warranty={warrantyCategory} />}
      <h1 className={styles.type}>Adicionar Documentos</h1>
      <p className={styles.description}>
        Adicione seus documentos para realizarmos a sua análise de crédito.
      </p>
      <form className={styles.form} onSubmit={handleSendClick}>
        {documentsNeeded.map(document => (
          <InputDocument
            key={document.value}
            title={document.label}
            description={document.description}
            isMobile={isMobile}
            documentCategory={document.value}
            documents={documents.get(document.value.toString())}
            handleAddDocuments={handleAddDocuments}
            handleRemoveDocument={handleRemoveDocument}
            previousDocuments={
              previousDocuments
                ? previousDocuments.filter(file => file.documentCategory === document.value)
                : []
            }
            deletePrevious={handleDeletePreviousDocument}
          />
        ))}
        <Button
          className={styles.button}
          variant="contained"
          type="submit"
          disabled={isButtonDisabled}
          isLoading={isUploadingDocuments || isUploadingHelperDocuments}
        >
          Próximo
        </Button>
      </form>
    </section>
  )
}

export default RegisterParticipantDocuments

import React, { useMemo, useCallback, useReducer, Fragment, useEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import CloseIcon from '@material-ui/icons/Close'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import MenuItem from '@material-ui/core/MenuItem'
import { useDispatch, useSelector } from 'react-redux'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Home from '@material-ui/icons/Home'
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import { FormGroup } from '@material-ui/core'

import { getCities, getNeighborhoods } from '_modules/utils/actions'
import { getCitiesValues, getNeighborhoodValues } from '_modules/utils/selectors'
import { useWindowSize } from '_utils/hooks'
import { Booking } from '_models'
import SelectMultipleCheckbox from '_components/select-multiple-checkbox'
import {
  FEATURE_FLAGS,
  PROPERTY_TYPES_LABELS,
  PURPOSE_TYPES,
  RESIDENTIAL_PROPERTY_TYPES,
  COMMERCIAL_PROPERTY_TYPES,
  PROPERTY_TYPES,
  DEFAULT_SEARCH,
} from '_utils/constants'

import useStyles, { StyledTextField } from './styles'
import {
  reducer,
  BUTTON_NUMBERS,
  BUTTON_FURNISHED,
  BUTTON_PETS,
  BUTTON_PURPOSE_TYPES,
  CHECKBOX_PROPERTY_TYPE,
  INITIAL_STATE,
  HANDLE_SELECTED_OPTION,
  HANDLE_MULTIPLE_SELECTED,
  CLEAR_NEIGHBORHOODS_SELECTED,
  RESET_STATE,
  LISTING_VALUES,
  CHECKBOX_TAX_TYPE,
  HANDLE_LISTING_TYPE,
  SET_INITIAL_STATE,
} from './reducer'

const TOGGLE_BUTTON_OPTIONS = [
  {
    label: 'Dormitório',
    value: LISTING_VALUES.BEDROOMS,
    shouldHideOn: LISTING_VALUES.COMMERCIAL,
  },
  {
    label: 'Suítes',
    value: LISTING_VALUES.SUITE,
    hideOnCommercial: true,
    shouldHideOn: LISTING_VALUES.COMMERCIAL,
  },
  {
    label: 'Banheiros',
    value: LISTING_VALUES.BATHROOMS,
  },
  {
    label: 'Garagem',
    value: LISTING_VALUES.PARKING_SPOTS,
  },
]

const MoreFiltersSection = ({ onCloseMoreFiltersSection, isOpen, onApplyClick, initialState }) => {
  const styles = useStyles()
  const [localState, dispatchLocal] = useReducer(reducer, INITIAL_STATE)
  const availableCities = useSelector(state => getCitiesValues(state))
  const availableNeighborhoods = useSelector(state => getNeighborhoodValues(state))
  const { isMobile } = useWindowSize()
  const dispatch = useDispatch()

  const paperProps = useMemo(() => ({ paper: styles.dialogPaper }), [styles.dialogPaper])

  const inputProps = useMemo(
    () => ({
      className: styles.currency,
    }),
    [styles.currency]
  )

  // Set initial state on the modal
  useEffect(() => {
    dispatchLocal({
      type: SET_INITIAL_STATE,
      payload: initialState,
      commercialListingsEnabled: FEATURE_FLAGS.commercialListingsEnabled,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const blipChat = document.getElementById('blip-chat-container')
    if (blipChat) {
      blipChat.style.display = isOpen ? 'none' : 'block'
    }
  }, [isOpen])

  const handleSelectedOption = useCallback(
    ({ target: { name, value } }) => {
      if (name === LISTING_VALUES.NEIGHBORHOODS) {
        dispatchLocal({
          type: HANDLE_MULTIPLE_SELECTED,
          payload: { name, value },
        })
      } else {
        dispatchLocal({
          type: HANDLE_SELECTED_OPTION,
          payload: { [name]: value },
        })
      }
      if (name === LISTING_VALUES.CITY) {
        dispatchLocal({ type: CLEAR_NEIGHBORHOODS_SELECTED })
        dispatch(getNeighborhoods({ cities: value }))
      }
    },
    [dispatch]
  )

  const handleCurrency = useCallback((_, value) => {
    dispatchLocal({
      type: HANDLE_SELECTED_OPTION,
      payload: { price: value },
    })
  }, [])

  const handleButtonOption = useCallback(
    (value, name) => () => {
      if (name === LISTING_VALUES.PURPOSE_TYPE) {
        dispatchLocal({
          type: HANDLE_SELECTED_OPTION,
          payload: {
            [LISTING_VALUES.LISTING_TYPE]:
              value === PURPOSE_TYPES.RESIDENTIAL
                ? RESIDENTIAL_PROPERTY_TYPES
                : COMMERCIAL_PROPERTY_TYPES,
          },
        })
      }
      dispatchLocal({
        type: HANDLE_SELECTED_OPTION,
        payload: { [name]: value },
      })
    },
    []
  )

  const handleListingTypeOption = useCallback(
    (value, name) => () => {
      if (value === 'both') {
        dispatchLocal({
          type: HANDLE_SELECTED_OPTION,
          payload: { [name]: [] },
        })
        return
      }
      dispatchLocal({
        type: HANDLE_SELECTED_OPTION,
        payload: { [name]: [value] },
      })
    },
    []
  )

  const cleanFilters = useCallback(() => {
    dispatchLocal({
      type: RESET_STATE,
    })
  }, [])

  const overrideButton = useCallback(
    (value, name) => ({
      root: classnames(styles.button, {
        [styles.active]: localState[name] === value,
      }),
    }),
    [localState, styles.active, styles.button]
  )

  const handleTenancyType = useCallback(event => {
    const { value, name } = event.target
    dispatchLocal({
      type: HANDLE_LISTING_TYPE,
      payload: { name, value },
    })
  }, [])

  const handleCheckBoxListingChoices = useCallback(
    item => {
      if (!FEATURE_FLAGS.commercialListingsEnabled) {
        return null
      }

      if (item.includes('all')) {
        return localState && localState.listingType && item === PROPERTY_TYPES.RES_ALL
          ? localState.listingType.length === RESIDENTIAL_PROPERTY_TYPES.length
          : localState.listingType.length === COMMERCIAL_PROPERTY_TYPES.length
      }
      return (
        localState &&
        localState.listingType &&
        localState.listingType.some(option => option === item)
      )
    },
    [localState]
  )

  const hideResidentialOptions = useMemo(
    () =>
      !(
        FEATURE_FLAGS.commercialListingsEnabled &&
        localState.purposeType === LISTING_VALUES.COMMERCIAL
      ),
    [localState.purposeType]
  )

  const renderListingCheckboxCommercialEnable = useMemo(() => {
    const isResidential =
      localState.purposeType === '' || localState.purposeType === PURPOSE_TYPES.RESIDENTIAL
    const currentSlug = isResidential ? 'res_' : 'com_'
    const listingTypes = DEFAULT_SEARCH.listingTypeChoices.filter(type =>
      type.includes(currentSlug)
    )

    if (!localState.purposeType) {
      return null
    }

    return (
      <Grid>
        <FormControlLabel
          key="checkbox-all"
          control={
            <Checkbox
              checked={handleCheckBoxListingChoices(`${currentSlug}all`)}
              onChange={handleTenancyType}
              color="primary"
              value={`${currentSlug}all`}
              name={LISTING_VALUES.LISTING_TYPE}
            />
          }
          className={styles.allValues}
          label={<Typography className={styles.formControlLabel}>Todos</Typography>}
        />
        <FormGroup row className={styles.twoColumns}>
          {listingTypes.map(item => (
            <FormControlLabel
              key={`checkbox-${item}`}
              control={
                <Checkbox
                  checked={handleCheckBoxListingChoices(item)}
                  onChange={handleTenancyType}
                  color="primary"
                  value={item}
                  name={LISTING_VALUES.LISTING_TYPE}
                />
              }
              label={
                <Typography className={styles.formControlLabel}>
                  {PROPERTY_TYPES_LABELS[item]}
                </Typography>
              }
            />
          ))}
        </FormGroup>
      </Grid>
    )
  }, [
    handleCheckBoxListingChoices,
    handleTenancyType,
    localState.purposeType,
    styles.allValues,
    styles.formControlLabel,
    styles.twoColumns,
  ])

  const renderListingCheckbox = useMemo(() => {
    return (
      <Grid className={styles.listing}>
        {CHECKBOX_PROPERTY_TYPE.map(item => (
          <FormControlLabel
            key={`checkbox-${item.value}`}
            control={
              <Checkbox
                checked={
                  item.value === localState.listingType[0] ||
                  (item.value === 'both' && localState.listingType.length === 0)
                }
                onChange={handleListingTypeOption(item.value, LISTING_VALUES.LISTING_TYPE)}
                color="primary"
              />
            }
            label={<Typography className={styles.formControlLabel}>{item.label}</Typography>}
          />
        ))}
      </Grid>
    )
  }, [styles.listing, styles.formControlLabel, localState.listingType, handleListingTypeOption])
  const renderButtonNumbers = useCallback(
    name => {
      return (
        <Grid className={styles.group}>
          {BUTTON_NUMBERS.map(
            (item, index) =>
              !(isMobile && index >= 4) && (
                <Button
                  classes={overrideButton(item[0], name)}
                  onClick={handleButtonOption(item[0], name)}
                  name={name}
                  key={`${name}-${item[0]}`}
                >
                  {item}
                </Button>
              )
          )}
        </Grid>
      )
    },
    [handleButtonOption, isMobile, overrideButton, styles.group]
  )

  const renderButtonValues = useCallback(
    (values, name) => (
      <Grid className={styles[name]}>
        {values.map(item => (
          <Button
            classes={overrideButton(item, name)}
            onClick={handleButtonOption(item, name)}
            name={name}
            key={`${name}-${item}`}
          >
            {item}
          </Button>
        ))}
      </Grid>
    ),
    [handleButtonOption, overrideButton, styles]
  )

  const handleApply = useCallback(() => {
    onApplyClick(localState)
  }, [localState, onApplyClick])

  useEffect(() => {
    if (isMobile && !!availableCities.size) {
      dispatch(getCities({}))
    }
  }, [availableCities.size, dispatch, isMobile])

  return (
    <Dialog
      scroll="paper"
      className={styles.modal}
      fullScreen={isMobile}
      classes={paperProps}
      open={isOpen}
      aria-describedby="Modal com outros filtros de busca"
      onClose={onCloseMoreFiltersSection}
    >
      <DialogTitle className={styles.actionButtons} disableTypography>
        <Typography component="h1" className={styles.title}>
          Mais Filtros
        </Typography>
        <IconButton size="small" aria-label="Fechar Modal" onClick={onCloseMoreFiltersSection}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={styles.content} dividers>
        <Typography component="h2" className={styles.label}>
          Buscar por código
        </Typography>
        <StyledTextField
          onChange={handleSelectedOption}
          value={localState.externalId}
          name={LISTING_VALUES.EXTERNAL_ID}
          placeholder="EX.: M02987"
          variant="outlined"
          className={styles.input}
        />
        <Typography component="h2" className={styles.label}>
          Cidade
        </Typography>
        <StyledTextField
          value={localState.city}
          onChange={handleSelectedOption}
          fullWidth
          variant="outlined"
          select
          placeholder="Busque por cidade"
          name={LISTING_VALUES.CITY}
        >
          <MenuItem value="" disabled>
            Cidade
          </MenuItem>
          {availableCities.map(optionsCity => (
            <MenuItem key={optionsCity.value} value={optionsCity.value}>
              {optionsCity.label}
            </MenuItem>
          ))}
        </StyledTextField>
        <Typography component="h2" className={styles.label}>
          Bairro
        </Typography>
        <SelectMultipleCheckbox
          displayEmpty
          classNames={classnames(styles.multipleSelect, styles.root)}
          value={localState.neighborhoods}
          handleChange={handleSelectedOption}
          name={LISTING_VALUES.NEIGHBORHOODS}
          startAdornmentIcon={<Home />}
          labelItem="Bairro"
          optionsItens={availableNeighborhoods}
          selected={localState.neighborhoods}
        />
        <Grid>
          <Typography component="h2" className={styles.label}>
            Faixa de preço
          </Typography>
          <Grid container direction="row" alignItems="center">
            <CurrencyTextField
              InputProps={inputProps}
              variant="outlined"
              value={localState.price}
              onChange={handleCurrency}
              digitGroupSeparator="."
              decimalCharacter=","
              currencySymbol="R$"
              textAlign="left"
              className={styles.input}
            />
            <FormControlLabel
              key={`checkbox-${LISTING_VALUES.TAX_INCLUDED}`}
              className={styles.formControlLabelCheckbox}
              control={
                <Checkbox
                  checked={!!localState.taxesIncluded}
                  onChange={handleButtonOption(
                    !localState.taxesIncluded,
                    LISTING_VALUES.TAX_INCLUDED
                  )}
                  color="primary"
                />
              }
              label={
                <Grid>
                  <Typography component="p" className={styles.formControlLabel}>
                    {CHECKBOX_TAX_TYPE.label}
                  </Typography>
                  <Typography component="span" className={styles.formControlLabelSmall}>
                    (IPTU, condomínio, seguro incêndio)
                  </Typography>
                </Grid>
              }
            />
          </Grid>
        </Grid>
        <Grid className={styles.listing}>
          <Typography component="h2" className={styles.label}>
            Tipo do imóvel
          </Typography>
          {FEATURE_FLAGS.commercialListingsEnabled && (
            <Grid className={styles.group}>
              {BUTTON_PURPOSE_TYPES.map(item => (
                <Button
                  classes={overrideButton(item.value, item.name)}
                  onClick={handleButtonOption(item.value, item.name)}
                  name={item.value}
                  key={`${item.value}-${item.value}`}
                  className={styles.residential}
                >
                  {item.label}
                </Button>
              ))}
            </Grid>
          )}
          <Grid>
            {FEATURE_FLAGS.commercialListingsEnabled
              ? renderListingCheckboxCommercialEnable
              : renderListingCheckbox}
          </Grid>
        </Grid>
        {TOGGLE_BUTTON_OPTIONS.map(
          type =>
            type.shouldHideOn !== localState.purposeType && (
              <Fragment key={type.label}>
                <Typography component="h2" className={styles.label}>
                  {type.label}
                </Typography>
                {renderButtonNumbers(type.value)}
              </Fragment>
            )
        )}
        <Typography component="h2" className={styles.label}>
          Mobília
        </Typography>
        {renderButtonValues(BUTTON_FURNISHED, LISTING_VALUES.FURNISHED)}
        {hideResidentialOptions && (
          <Fragment>
            <Typography component="h2" className={styles.label}>
              Aceita pet
            </Typography>
            {renderButtonValues(BUTTON_PETS, LISTING_VALUES.PETS)}
          </Fragment>
        )}
        <div className={styles.space}>*</div>
      </DialogContent>
      <DialogActions className={styles.actionButtons}>
        <Button color="secondary" onClick={cleanFilters}>
          LIMPAR FILTRO
        </Button>
        <Button color="secondary" variant="contained" onClick={handleApply}>
          APLICAR FILTROS
        </Button>
      </DialogActions>
    </Dialog>
  )
}

MoreFiltersSection.propTypes = {
  onCloseMoreFiltersSection: PropTypes.func,
  onApplyClick: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
  initialState: PropTypes.shape({
    externalId: PropTypes.string,
    bookingStatus: PropTypes.string,
    booking: PropTypes.instanceOf(Booking),
    notes: PropTypes.string,
    state: PropTypes.string,
    city: PropTypes.string,
    address: PropTypes.string,
    observation: PropTypes.string,
    listingType: PropTypes.string,
    neighborhoods: PropTypes.arrayOf(PropTypes.string),
    rentPrice: PropTypes.number,
    taxes: PropTypes.number,
    condominiumFee: PropTypes.number,
    bedrooms: PropTypes.number,
    bathrooms: PropTypes.number,
    parkingSpots: PropTypes.number,
    livingArea: PropTypes.number,
    pictures: PropTypes.oneOfType(PropTypes.array),
    furnished: PropTypes.string,
    insurance: PropTypes.number,
    streetNumber: PropTypes.string,
    id: PropTypes.number,
    purposeType: PropTypes.string,
  }).isRequired,
}

MoreFiltersSection.defaultProps = {
  onCloseMoreFiltersSection: () => {},
  onApplyClick: () => {},
}

export default MoreFiltersSection

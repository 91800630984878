import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import LeftArrow from '_assets/images/angle-right-solid.svg'
import { primaryColor } from '_utils/colors'

import styles from './styles.css'

class NextArrow extends PureComponent {
  static propTypes = {
    onClick: PropTypes.func,
    className: PropTypes.string,
  }

  static defaultProps = {
    onClick: () => {},
    className: '',
  }

  onMouseDown = event => {
    event.preventDefault()
  }

  render() {
    const { onClick, className } = this.props
    return (
      <div className={classnames(styles['arrow-container'], className)}>
        <button
          type="button"
          onClick={onClick}
          className={styles.arrow}
          onMouseDown={this.onMouseDown}
        >
          <svg
            aria-label="flexa direita"
            role="img"
            className={styles.icon}
            style={{ fill: primaryColor }}
          >
            <use xlinkHref={LeftArrow} />
          </svg>
          Próximo
        </button>
      </div>
    )
  }
}

export default NextArrow

import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  textField: {
    '& .MuiInputLabel-outlined': {
      fontSize: '1.4rem',
    },
    '& .MuiInputBase-input': {
      padding: '1.4rem 1.2rem',
      color: theme.palette.secondary.main,
    },
  },
  input: {
    height: '4.8rem',
  },
}))

export const createOptions = (length, name, options = {}) => {
  const populatedOptions = Array.from(new Float32Array(length)).map((_, index) => {
    const isLastOption = index + 1 === length
    const value = options.zeroIndex ? index : index + 1

    if (options.appendPlusToLast && isLastOption) {
      return {
        value: `${value}`,
        label: `${value}+`,
        id: `${name}-${value}`,
      }
    }

    return {
      value: `${value}`,
      label: `${value}`,
      id: `${name}-${value}`,
    }
  })

  if (options.appendFirstOption) {
    return [
      {
        ...options.appendFirstOption,
        id: `${name}-${options.appendFirstOption.value}`,
      },
      ...populatedOptions,
    ]
  }

  if (options.replaceFirstOption) {
    populatedOptions[0] = {
      ...options.replaceFirstOption,
      id: `${name}-${options.replaceFirstOption.value}`,
    }

    return populatedOptions
  }

  return populatedOptions
}

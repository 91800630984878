import React, { useMemo, useEffect } from 'react'
import classnames from 'classnames'
import TextField from '@material-ui/core/TextField'
import InputMask from 'react-input-mask'
import PropTypes from 'prop-types'

import Button, { ButtonColor, ButtonVariant, ButtonFormat } from '_components/button'
import { initRecaptcha, RECAPTCHA_CONTAINER_ID } from '_utils/firebase'

import useStyles from '../styles'

import styles from './styles.css'

const MAX_PHONE_NUMBER_LENGTH = 11
const MAX_AREA_LENGTH = 4

const NewUser = ({ userInfo, onChange, onNumberChange, changeNumberKey }) => {
  const classes = useStyles()

  useEffect(() => {
    initRecaptcha(RECAPTCHA_CONTAINER_ID)
  }, [])

  const isDisabled = useMemo(
    () =>
      !(
        userInfo.phoneNumber.length === MAX_PHONE_NUMBER_LENGTH &&
        userInfo.area.length === MAX_AREA_LENGTH
      ),
    [userInfo.area.length, userInfo.phoneNumber.length]
  )
  return (
    <div className={styles.content}>
      <div className={styles.login}>
        <h1 className={styles.title}>
          {changeNumberKey ? 'Redefinir número' : 'Informe o número do seu celular'}
        </h1>
        <p className={styles.description}>
          Enviaremos um <strong>código de acesso</strong> por SMS. Seus dados estão seguros e você
          não precisa memorizar a senha.
        </p>
        <div id={RECAPTCHA_CONTAINER_ID} />
        <div className={styles['phone-number']}>
          <InputMask
            mask="\+9999"
            maskChar=""
            className={classnames(classes.textField, styles.textfield)}
            margin="normal"
            variant="outlined"
            label="País"
            name="code"
            value={+55}
            error={!!userInfo.error}
            autoComplete="nope"
          >
            {inheritedProps => <TextField {...inheritedProps} />}
          </InputMask>

          <InputMask
            mask="(99)"
            maskChar=""
            className={classnames(classes.textField, styles.textfield)}
            margin="normal"
            variant="outlined"
            label="Área"
            name="area"
            value={userInfo.area}
            onChange={onChange}
            error={!!userInfo.error}
            type="tel"
            autoComplete="nope"
          >
            {inheritedProps => <TextField {...inheritedProps} />}
          </InputMask>
          <InputMask
            mask="9.9999-9999"
            maskChar=""
            className={classnames(classes.textField, styles.textfield)}
            margin="normal"
            variant="outlined"
            label="Celular"
            name="phoneNumber"
            value={userInfo.phoneNumber}
            onChange={onChange}
            error={!!userInfo.error}
            helperText={userInfo.error}
            type="tel"
            autoComplete="nope"
          >
            {inheritedProps => <TextField {...inheritedProps} />}
          </InputMask>
        </div>
      </div>
      <div className={styles.footer}>
        <div className={styles.buttons}>
          <Button
            color={ButtonColor.PRIMARY}
            variant={ButtonVariant.BOLD}
            format={ButtonFormat.SQUARED}
            className={styles.button}
            disabled={isDisabled || userInfo.loading}
            isLoading={userInfo.loading}
            type="submit"
          >
            CONFIRMAR
          </Button>
          {!changeNumberKey && (
            <Button onClick={onNumberChange} color={ButtonColor.TEXT_BLACK} className={styles.link}>
              Meu número mudou
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}

NewUser.propTypes = {
  userInfo: PropTypes.shape({
    area: PropTypes.string,
    code: PropTypes.string,
    phoneNumber: PropTypes.string,
    error: PropTypes.string,
    loading: PropTypes.bool,
  }).isRequired,
  onChange: PropTypes.func,
  onNumberChange: PropTypes.func,
  changeNumberKey: PropTypes.string,
}

NewUser.defaultProps = {
  onChange: () => {},
  onNumberChange: () => {},
  changeNumberKey: '',
}

export default React.memo(NewUser)

import { Map } from 'immutable'

import { createReducer } from '_utils/redux'
import { GET_RECOMMENDATIONS_PROPERTIES } from '_modules/recommendations/actions'
import { LOGOUT } from '_modules/user/actions'

const INITIAL_STATE = new Map()

const propertyFetchingReducer = (state, { payload }) =>
  state.merge(payload.entities.recommendationsProperties)

const recommendationsProperties = createReducer(INITIAL_STATE, {
  [GET_RECOMMENDATIONS_PROPERTIES.FULFILLED]: propertyFetchingReducer,
  [LOGOUT]: () => INITIAL_STATE,
})

export default recommendationsProperties

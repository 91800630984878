import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Hammer from 'react-hammerjs'
import { DIRECTION_LEFT, DIRECTION_RIGHT } from 'hammerjs'
import uuidv4 from 'uuid'

import NextArrow from '_components/paged-slick-carousel/next-arrow'
import PreviousArrow from '_components/paged-slick-carousel/prev-arrow'
import CarouselPaging from '_components/paged-slick-carousel/carousel-paging'
import { sendEvent } from '_utils/mixpanel'

import styles from './styles.css'

class Carousel extends PureComponent {
  static propTypes = {
    pictures: PropTypes.arrayOf(PropTypes.string).isRequired,
    height: PropTypes.number.isRequired,
    className: PropTypes.string,
    isMobile: PropTypes.bool,
  }

  static defaultProps = {
    className: '',
    isMobile: false,
  }

  constructor(props) {
    super(props)
    this.uuid = uuidv4()
    this.state = {
      activePicture: 0,
      showArrows: false,
    }
  }

  componentDidMount() {
    const { isMobile } = this.props

    if (isMobile) {
      this.setState({ showArrows: true })
    }
  }

  componentDidUpdate(previousProps) {
    const { isMobile } = this.props
    const { isMobile: wasMobile } = previousProps

    if (!wasMobile && isMobile) {
      this.setState({ showArrows: true })
    }

    if (wasMobile && !isMobile) {
      this.setState({ showArrows: false })
    }
  }

  onDotClick = event => {
    const { name } = event.target
    event.preventDefault()

    this.setState({
      activePicture: parseInt(name, 10),
    })
  }

  onNextClick = event => {
    const { activePicture } = this.state
    const { pictures } = this.props
    event.preventDefault()
    event.stopPropagation()

    if (activePicture !== pictures.size - 1) {
      this.setState({
        activePicture: activePicture + 1,
      })
    } else {
      this.setState({
        activePicture: 0,
      })
    }
    sendEvent('Busca: Navegou para próxima imagem no carrossel')
  }

  onPreviousClick = event => {
    const { activePicture } = this.state
    const { pictures } = this.props
    event.preventDefault()
    event.stopPropagation()

    if (activePicture !== 0) {
      this.setState({
        activePicture: activePicture - 1,
      })
    } else {
      this.setState({
        activePicture: pictures.size - 1,
      })
    }
    sendEvent('Busca: Navegou para imagem anterior no carrossel')
  }

  getTranslateAmount = () => {
    const { pictures } = this.props
    const { activePicture } = this.state
    const totalNumberOfDotsShown = 5
    const middleDot = 3
    const distanceBetweenDots = 14
    if (pictures.size > totalNumberOfDotsShown) {
      if (activePicture < middleDot) {
        return 0
      }
      if (activePicture > pictures.size - middleDot) {
        return -distanceBetweenDots * (pictures.size - totalNumberOfDotsShown)
      }
      return -distanceBetweenDots * activePicture + 2 * distanceBetweenDots
    }
    return 0
  }

  setArrayKey = (type, index) => `${this.uuid}-${type}-${index}`

  handleSwipe = event => {
    const { pictures } = this.props
    const { activePicture } = this.state

    if (event.direction === DIRECTION_LEFT) {
      if (activePicture === pictures.size - 1) {
        this.setState({
          activePicture: 0,
        })
        return
      }

      this.setState({
        activePicture: activePicture + 1,
      })
    }

    if (event.direction === DIRECTION_RIGHT) {
      if (activePicture === 0) {
        this.setState({
          activePicture: pictures.size - 1,
        })
        return
      }

      this.setState({
        activePicture: activePicture - 1,
      })
    }
  }

  showArrows = () => {
    this.setState({
      showArrows: true,
    })
  }

  hideArrows = () => {
    this.setState({
      showArrows: false,
    })
  }

  render() {
    const { pictures, height, className } = this.props
    const { activePicture, showArrows } = this.state

    return (
      <div
        className={classnames(styles.carousel, className)}
        onMouseEnter={this.showArrows}
        onMouseLeave={this.hideArrows}
      >
        <PreviousArrow
          onClick={this.onPreviousClick}
          visible={showArrows}
          className={styles.arrow}
        />
        <NextArrow onClick={this.onNextClick} visible={showArrows} className={styles.arrow} />
        <Hammer onSwipe={this.handleSwipe}>
          <div className={styles['carousel-inner']} style={{ height: `${height}px` }}>
            {pictures.map((picture, index) => (
              <img
                src={picture}
                alt="Imóvel"
                key={this.setArrayKey('hammer', index)}
                height={height}
                className={classnames(styles.picture, {
                  [styles.active]: activePicture === index,
                  [styles.show]:
                    [index + 1, index - 1].includes(activePicture) ||
                    (activePicture === 0 && index === activePicture - 1) ||
                    (activePicture === 0 && index === pictures.size - 1) ||
                    (activePicture === pictures.size - 1 && index === 0),
                })}
              />
            ))}
          </div>
        </Hammer>
        {pictures.size > 1 && (
          <div className={styles.container}>
            <CarouselPaging numberOfItems={pictures.size} selectedItem={activePicture} />
          </div>
        )}
      </div>
    )
  }
}

export default Carousel

import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ breakpoints, palette }) => ({
  container: {
    justifyContent: 'center',
    textAlign: 'center',
    width: '46.4rem',
    margin: 'auto auto 11rem',
    [breakpoints.down('sm')]: {
      width: '29.8rem',
      margin: 'auto auto 9.6rem',
    },
  },
  icon: {
    width: '30rem',
    height: '15.5rem',
    margin: 'auto',
    marginTop: '8rem',
    [breakpoints.down('sm')]: {
      width: '17.4rem',
      height: '9rem',
      marginTop: '4rem',
    },
  },

  title: {
    marginTop: '1.6rem',
    [breakpoints.down('sm')]: {
      fontSize: '1.6rem',
      fontWeight: 'bold',
      marginTop: '1.9rem',
    },
  },

  button: {
    maxWidth: '24.5rem',
    margin: 'auto',
  },

  buttonSecondary: {
    fontWeight: 'bold',
    maxWidth: '24.5rem',
    margin: '15.9rem auto auto',
    color: palette.secondary.dark,
  },

  description: {
    lineHeight: '1.5',
    fontWeight: '400',
    color: palette.secondary.dark,
    margin: '2.4rem auto 3.2rem',
    letterSpacing: 'normal',
    width: '100%',
    [breakpoints.down('sm')]: {
      width: '29.8rem',
      fontSize: '1.4rem',
      margin: '1.6rem auto 4rem',
    },
  },
  descriptionHighlighted: {
    lineHeight: 1.5,
    fontWeight: 400,
    letterSpacing: 'normal',

    '& a': {
      textDecoration: 'none',
      color: palette.info.main,
    },
    [breakpoints.down('sm')]: {
      fontSize: '1.4rem',
    },
  },
  containerItems: {
    width: '51rem',
    textAlign: 'left',
    background: palette.gray.gallery,
    borderRadius: '0.4rem',
    border: `0.1rem solid ${palette.divider}`,
    [breakpoints.down('sm')]: {
      maxWidth: '31.2rem',
    },
  },

  titleItems: {
    margin: '0',
    padding: '2.4rem 2.4rem 0.8rem',
    color: palette.secondary.dark,
    borderBottom: `0.1rem solid ${palette.secondary.light}`,
    [breakpoints.down('sm')]: {
      fontSize: '1.4rem',
    },
  },

  comment: {
    padding: '2.4rem 2.4rem 3.2rem',
    color: palette.secondary.main,
  },

  secondContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyItems: 'center',
    padding: '1.9rem 1.6rem 0.8rem',
    width: '51rem',
    height: '8.1rem',
    textAlign: 'left',
    justifyContent: 'center',
    marginTop: '3.6rem',
    '& .MuiGrid-container': {
      width: '65%',
    },
    [breakpoints.down('sm')]: {
      width: '31.2rem',
      height: '15rem',
      flexDirection: 'column',
    },
  },

  subDescription: {
    width: '21.9rem',
    fontWeight: 500,
    justifyContent: 'flex-start',
    [breakpoints.down('sm')]: {
      width: '22.4rem',
      marginBottom: '2.4rem',
    },
  },
  buttonContainer: {
    '& .MuiButtonBase-root': {
      width: '15.9rem',
      height: '3.1rem',
    },
    fontWeight: 'bold',
    width: '15.9rem',
    height: '3.1rem',
    padding: '0.5rem 1.6rem',
    marginTop: '0.6rem',
  },
}))

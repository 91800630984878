import React, { useMemo, useEffect, Fragment, useCallback } from 'react'
import { Grid, Typography, Paper } from '@material-ui/core'
import className from 'classnames'
import { useParams, navigate } from '@reach/router'
import { useDispatch, useSelector } from 'react-redux'

import { WARRANTY_TRANSLATION, LEASE_STATUS } from '_utils/constants'
import { getLease, cancelLease } from '_modules/leases/actions'
import { getLeaseById, checkIsLoadingLease } from '_modules/leases/selectors'
import withAuth from '_hocs/with-auth-or-income-helper'
import Button from '_components/material/button'
import MobileClick from '_assets/images/mobile-click.svg'
import Building from '_components/building-animated'

import { CREDIT_ANALYSIS_ROUTES } from '../router'

import useStyles from './styles'

const NewWarranty = () => {
  const styles = useStyles()
  const { leaseId } = useParams()
  const dispatch = useDispatch()
  const lease = useSelector(state => getLeaseById(state, leaseId))
  const isLoadingLease = useSelector(checkIsLoadingLease)
  const externalId = lease && lease.getIn(['listing', 'externalId'])

  useEffect(() => {
    if (!lease && leaseId) {
      dispatch(getLease(leaseId))
    }
  }, [dispatch, lease, leaseId])

  const request = useMemo(() => (lease && lease.getIn(['warrantyRequest', 'comment'])) || '', [
    lease,
  ])
  const endLease = useCallback(() => {
    dispatch(cancelLease({ leaseId, externalId }))
    navigate(`/analise-credito/${leaseId}/analise-encerrada`)
  }, [dispatch, externalId, leaseId])

  const currentWarranty = useMemo(() => (lease && lease.warranty) || '', [lease])

  const previousWarranty = useMemo(() => (lease && lease.previousWarranty) || '', [lease])

  const renderContent = useMemo(() => {
    if (lease && lease.warrantyRequest && lease.warrantyRequest.get('isFirstRequest'))
      return {
        title: 'Vamos escolher uma garantia?',
        description: (
          <Typography component="p" variant="h4" className={styles.description}>
            Para iniciar a análise de crédito, você precisa escolher uma modalidade de garantia.
          </Typography>
        ),
        complement: null,
        buttonTitle: 'Escolher garantia',
        url: `/minhas-negociacoes/${leaseId}/garantia/escolha`,
      }
    if (lease.status === LEASE_STATUS.WARRANTY_REQUESTED)
      return {
        title: 'Vamos tentar outra garantia?',
        description: (
          <Typography component="p" variant="h4" className={styles.description}>
            A modalidade anterior não foi suficiente para aprovarmos a sua análise. Confira as
            modalidades disponíveis!
          </Typography>
        ),
        complement: null,
        buttonTitle: 'Escolher garantia',
        url: `/minhas-negociacoes/${leaseId}/garantia/escolha`,
      }
    if (previousWarranty === '')
      return {
        title: 'Nova garantia',
        description: (
          <Typography component="p" variant="h4" className={styles.description}>
            {' '}
            Uma nova garantia foi adicionada pelo consultor. Para mais informações acesse o resumo
            da análise.
          </Typography>
        ),
        complement: null,
        buttonTitle: 'Ver resumo da análise',
        url: `/analise-credito/${leaseId}${CREDIT_ANALYSIS_ROUTES.STATUS}`,
      }

    return {
      title: 'Nova modalidade',
      description: (
        <Typography component="p" variant="subtitle1" className={styles.description}>
          Infelizmente não foi possível continuar com a garantia{' '}
          {WARRANTY_TRANSLATION[previousWarranty] &&
            WARRANTY_TRANSLATION[previousWarranty].toUpperCase()}
          . Portanto, o consultor responsável pela sua locação escolheu a garantia{' '}
          <strong>{WARRANTY_TRANSLATION[currentWarranty]}</strong> para dar continuidade ao processo
          de locação.
        </Typography>
      ),
      complement: 'Você será notificada caso falte algum dado ou documento.',
      buttonTitle: 'Ver resumo da análise',
      url: `/analise-credito/${leaseId}${CREDIT_ANALYSIS_ROUTES.STATUS}`,
    }
  }, [currentWarranty, lease, leaseId, previousWarranty, styles.description])

  return (
    <Fragment>
      {isLoadingLease || !lease ? (
        <Grid container justify="center" alignItems="center">
          <Building className={styles.building} isSmall />
        </Grid>
      ) : (
        <Grid className={styles.wrapper}>
          <Grid container alignItems="center" direction="column">
            <Grid item>
              <svg aria-hidden="true" className={styles.image}>
                <use xlinkHref={MobileClick} />
              </svg>
            </Grid>
            <Grid item>
              <Typography component="h1" variant="h3" className={styles.title}>
                {renderContent.title}
              </Typography>
            </Grid>
            <Grid item>{renderContent.description}</Grid>
            {renderContent.complement && (
              <Grid item>
                <Typography
                  component="p"
                  variant="h4"
                  className={className(styles.description, styles.complement)}
                >
                  {renderContent.complement}
                </Typography>
              </Grid>
            )}
            {request !== '' && (
              <Paper variant="outlined" className={styles.justification}>
                <Typography
                  variant="h4"
                  component="p"
                  color="secondary"
                  className={styles.justificationTitle}
                >
                  Justificativa
                </Typography>
                <Grid className={styles.horizontalDivision} />
                <Typography
                  variant="subtitle1"
                  component="p"
                  color="secondary"
                  className={styles.subDescriptionJustification}
                >
                  {request}
                </Typography>
              </Paper>
            )}
            <Grid item>
              <Button className={styles.button} to={renderContent.url} variant="contained">
                {renderContent.buttonTitle}
              </Button>
            </Grid>
            <Grid item>
              <Button className={styles.buttonEnd} onClick={endLease}>
                Encerrar negociação
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Fragment>
  )
}

export default withAuth(React.memo(NewWarranty))

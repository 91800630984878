import { makeStyles } from '@material-ui/styles'

export default makeStyles(() => ({
  root: {
    minHeight: '185px',
    width: '560px',
    boxSizing: 'border-box',
    padding: '0',
  },
  label: {
    padding: '16px',
    width: '100%',
    fontSize: '1.4rem',
    fontWeight: 'bold',
    letterSpacing: '1.25px',
    borderRadius: 0,
    textTransform: 'uppercase',
  },
  textField: {
    width: '100%',
    '& .MuiInputBase-root': {
      '& > input': {
        fontSize: '1.4rem',
        color: 'var(--light-gray-2)',
        paddingLeft: 42,
      },
    },
    '& .MuiFormHelperText-root': {
      position: 'absolute',
      top: 14,
      color: 'var(--mine-shaft)',
      fontSize: '1.4rem',
      fontWeight: 500,
    },
  },
}))

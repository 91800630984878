import humps from 'humps'

import { post, get, patch } from './requests'

export const phoneLogin = payload => post(['auth', 'accountkit'], {}, humps.decamelizeKeys(payload))

export const createOwner = payload => post(['register', 'owner'], {}, humps.decamelizeKeys(payload))

export const getUser = key => get(['user'], { key })

export const updateUser = key => payload => patch(['user'], { key }, humps.decamelizeKeys(payload))

export const sendRating = key => payload =>
  post(['ratings'], { key }, humps.decamelizeKeys(payload))

export const getLikedProperties = key => () => get(['listings', 'liked'], { key })

export const removeLikedProperty = key => externalId =>
  post(['listings', externalId, 'unlike'], { key })

// FIREBASE

export const firebaseLogin = payload =>
  post(['auth', 'firebase'], {}, humps.decamelizeKeys(payload))

export const requestPhoneChange = payload =>
  post(['phone', 'reset'], {}, humps.decamelizeKeys(payload))

export const confirmPhoneChange = payload =>
  post(['phone', 'reset', 'confirm'], {}, humps.decamelizeKeys(payload))

// Custom phone login
export const customPhoneLogin = payload =>
  post(['auth', 'phone'], {}, humps.decamelizeKeys(payload))

export const verifyPhoneLoginCode = payload =>
  post(['auth', 'phone', 'confirm'], {}, humps.decamelizeKeys(payload))

export const requestResetPhone = payload =>
  post(['auth', 'phone', 'reset'], {}, humps.decamelizeKeys(payload))

export const resetPhoneLogin = payload =>
  post(['auth', 'phone', 'reset', 'confirm'], {}, humps.decamelizeKeys(payload))

// Login through url

export const getAuthToken = payload => post(['auth', 'token'], {}, humps.decamelizeKeys(payload))

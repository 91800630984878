import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import classnames from 'classnames'

import { toCurrency } from '_utils/helpers'
import Button, { ButtonColor, ButtonVariant, ButtonFormat } from '_components/button'
import CloseIcon from '_assets/images/ic-fechar.svg'
import CheckIcon from '_assets/images/ic-check.svg'
import { planInfoShape } from '_utils/proptypes'
import { primaryColor } from '_utils/colors'

import styles from './styles.css'

const PlanDetailsModal = ({ handleClose, isOpen, planInfo, onChooseClick }) => {
  const createSVGStyle = useMemo(() => ({ fill: primaryColor }), [])
  const paperProps = useMemo(() => ({ classes: { root: styles.dialog } }), [])

  const valueFee = useMemo(() => {
    if (planInfo.isPercentage) {
      return planInfo.fee ? `${planInfo.fee}%` : '0%'
    }
    return planInfo.fee ? toCurrency(planInfo.fee) : 'R$ 0'
  }, [planInfo.fee, planInfo.isPercentage])

  return (
    <Dialog
      aria-labelledby="Plan details modal"
      aria-label="Modal com detalhe dos planos do cadastro"
      open={isOpen}
      disableAutoFocus
      PaperProps={paperProps}
    >
      <DialogActions className={styles['no-padding']}>
        <button className={styles.button} onClick={handleClose} type="button">
          <svg alt="fechar" className={styles['close-icon']}>
            <use xlinkHref={CloseIcon} />
          </svg>
        </button>
      </DialogActions>
      <DialogContent className={classnames(styles.container, styles['no-padding'])}>
        <h1 className={styles['plan-type']}>{planInfo.title}</h1>
        <p className={styles.taxes}>
          {valueFee}
          <br />
          {planInfo.description && <span className={styles.subtitle}>{planInfo.description}</span>}
        </p>
        <h2 className={styles.exclusivity}>{planInfo.exclusiveTag}</h2>
        {planInfo.list && (
          <ul className={styles.list}>
            {planInfo.list.map(item => (
              <li className={styles.item} key={item}>
                <svg aria-hidden="true" className={styles['check-icon']} style={createSVGStyle}>
                  <use xlinkHref={CheckIcon} />
                </svg>
                <p className={styles.description}>{item}</p>
              </li>
            ))}
          </ul>
        )}
        {!planInfo.competitor && (
          <Button
            color={ButtonColor.PRIMARY}
            variant={ButtonVariant.BOLD}
            format={ButtonFormat.SQUARED}
            className={styles['choose-button']}
            onClick={onChooseClick}
          >
            ESCOLHER PLANO
          </Button>
        )}
      </DialogContent>
    </Dialog>
  )
}

PlanDetailsModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  planInfo: planInfoShape.isRequired,
  onChooseClick: PropTypes.func.isRequired,
}

PlanDetailsModal.defaultProps = {
  isOpen: false,
}
export default React.memo(PlanDetailsModal)

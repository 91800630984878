import React, { Fragment, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'

import InputButton from '_components/button/input-button'
import { listingRegistrationFormSelector } from '_modules/listing-registration/selectors'

import styles from '../styles.css'

const Step1 = () => {
  const { transactionType } = useSelector(listingRegistrationFormSelector)
  const { setValue } = useFormContext()

  useEffect(() => {
    setValue('transactionType', transactionType)
  }, [setValue, transactionType])

  return (
    <Fragment>
      <header className={styles.header}>
        <h1 className={styles.title}>O que deseja fazer?</h1>
      </header>
      <div className={styles.group}>
        <InputButton
          className={styles.radio}
          name="transactionType"
          id="rent"
          type="radio"
          value="rent"
          label="Alugar"
        />
        <InputButton
          className={styles.radio}
          name="transactionType"
          id="sale"
          type="radio"
          value="sale"
          label="Vender"
        />
      </div>
    </Fragment>
  )
}

export default React.memo(Step1)

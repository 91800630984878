import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ palette, breakpoints }) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr 40rem 1fr',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '5.6rem',
    gridGap: '1.6rem',

    [breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
      gridGap: '0',
      paddingTop: '0',
    },
  },
  dateButton: {
    width: '7rem',
  },
  paper: {
    gridColumn: 2,
    height: '48.6rem',
    paddingTop: '2.4rem',
    display: 'flex',
    boxSizing: 'border-box',
    flexDirection: 'column',

    [breakpoints.down('sm')]: {
      gridColumn: 1,
      backgroundColor: 'inherit',
    },
  },
  title: {
    textAlign: 'center',
    lineHeight: '2.4rem',
    alignSelf: 'center',
  },
  reschedule: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, auto)',
    gridGap: '1.6rem',
    alignItems: 'center',
    width: '100%',
    padding: '1.6rem',
    borderTop: `0.1rem solid ${palette.divider}`,
    marginTop: 'auto',
  },
  icon: {
    color: palette.gray.solid,

    [breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  button: {
    backgroundColor: palette.gray.gallery,
    color: palette.secondary.main,
    fontSize: '1.2rem',
    borderColor: palette.gray.default,
    fontWeight: 'bold',
  },
  doubt: {
    gridColumn: 2,
    display: 'grid',
    gridTemplateColumns: 'repeat(3, auto)',
    gridGap: '0.8rem',
    padding: '1.6rem 1.2rem',

    [breakpoints.down('sm')]: {
      gridColumn: 1,
      marginTop: 'auto',
      gridTemplateRows: 'repeat(2, auto)',
      gridTemplateColumns: '250px',
      justifyContent: 'center',
      padding: '2.4rem 0',
    },
  },
  doubtText: {
    fontWeight: '500',

    [breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  noShadow: {
    boxShadow: 'none',
    marginBottom: '3.2rem',
    alignSelf: 'center',
    marginTop: '1.6rem',
  },
  date: {
    marginTop: '1.6rem',
    alignSelf: 'center',
  },
  datePicker: {
    padding: '1rem',
    height: '10rem',
    width: '40rem',

    [breakpoints.down('sm')]: {
      width: '100vw',
    },
  },
  timePicker: {
    width: '40rem',
    height: '6.3rem',
    padding: '1rem',
    boxSizing: 'border-box',

    [breakpoints.down('sm')]: {
      width: '100vw',
    },
  },
  subtitle: {
    [breakpoints.down('sm')]: {
      fontSize: '1.2rem',
    },
  },
  timeButton: {
    height: '4rem',
    width: '9rem',
    fontSize: '1.4rem',

    '&:hover': {
      color: 'white',
      backgroundColor: palette.primary.main,
    },

    [breakpoints.down('sm')]: {
      width: '8.6rem',
    },
  },
  defaultButton: {
    backgroundColor: 'white',
    boxShadow: '0 0.2rem 0.4rem rgba(0, 0, 0, 0.16)',
    color: palette.secondary.main,
  },
  loading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  timeItem: {
    height: '4.1rem',
  },
  arrow: {
    color: '#222',
    fill: '#222',
  },
  inverseArrow: {
    transform: 'rotate(45deg)',
  },
}))

import { makeStyles } from '@material-ui/styles'

export default makeStyles(() => ({
  grid: {
    display: 'grid',
    gridTemplate: '1fr auto / 3.8rem 1fr',
    marginTop: '2.4rem',
  },
  icon: {
    marginRight: '1.8rem',
    width: '2rem',
    height: '2.2rem',
  },
  content: {
    gridColumn: 2,
  },
}))

/* eslint-disable react/jsx-no-bind */
import React, { useMemo, useEffect, useCallback } from 'react'
import { Grid, Typography, SvgIcon, Paper } from '@material-ui/core'
import classnames from 'classnames'
import {
  SentimentSatisfied,
  Home,
  AccessAlarms,
  LocationOn,
  ContactSupport,
} from '@material-ui/icons'
import { useParams, navigate, useLocation } from '@reach/router'
import { useSelector, useDispatch } from 'react-redux'
import { Map } from 'immutable'
import qs from 'qs'

import HouseIcon from '_assets/images/ic_house_check.svg'
import Button from '_components/material/button'
import ExternalIdButton from '_components/material/button/external-id-button'
import Loading from '_components/loading'
import withAuth from '_hocs/with-auth'
import {
  sendBookingFeedback,
  getBooking,
  SEND_BOOKING_FEEDBACK,
  giveUpListing,
  GIVE_UP_LISTING,
} from '_modules/booking/actions'
import {
  getBookingById,
  bookingErrorSelector,
  giveUpListingLoadingSelector,
} from '_modules/booking/selectors'
import { useWindowSize } from '_utils/hooks'
import useModal from '_hooks/use-modal'
import useOnSuccessCall from '_hooks/use-on-success'
import { getPropertyType, getPropertyAddress, createSearchQuery } from '_utils/property'
import { toCurrency } from '_utils/helpers'
import { formatLongDate } from '_utils/strings'
import {
  FEATURE_FLAGS,
  BOOKING_FEEDBACK_TYPE,
  BOOKING_FEEDBACK_USER_ACTION,
} from '_utils/constants'
import Feedback from '_views/schedule/feedback'
import VisitOptionsModal from '_views/visit/visit-options-modal'
import VisitFeedbackModal from '_views/visit/visit-feedback-modal'
import { LISTING_VALUES } from '_components/more-filters-section/reducer'

import useStyles from './styles'

const VisitFeedback = () => {
  const styles = useStyles()
  const { isMobile } = useWindowSize()
  const { bookingId } = useParams()
  const dispatch = useDispatch()
  const { search } = useLocation()

  const booking = useSelector(state => getBookingById(state, bookingId))
  const isGiveUpLoading = useSelector(giveUpListingLoadingSelector)
  const hasBookingError = useSelector(bookingErrorSelector)
  const propertyPurposeType = useMemo(() => booking.get('listing').toJS().purposeType, [booking])
  const isCommercialProperty = useMemo(() => propertyPurposeType === LISTING_VALUES.COMMERCIAL, [
    propertyPurposeType,
  ])
  const shouldSkipNegotiation = useMemo(
    () => FEATURE_FLAGS.productVersionOneEnabled || !!isCommercialProperty,
    [isCommercialProperty]
  )

  const { modalOpen: visitOptionsModalOpen, handleModal: handleVisitOptionsModal } = useModal(
    search.includes('report-problem')
  )
  const { modalOpen: visitFeedbackModalOpen, handleModal: handleVisitFeedbackModal } = useModal(
    search.includes('happened')
  )
  const leaseId = booking && booking.lease && booking.lease.get('id')
  const parsedQuery = useMemo(() => qs.parse((search || '').split('?').pop()), [search])
  const hasHappenedField = Object.keys(parsedQuery).includes('happened')
    ? parsedQuery.happened === 'true'
    : undefined

  const listingType = booking && getPropertyType(booking.listing.get('listingType'))

  const startIcon = useMemo(() => <ContactSupport />, [])

  const visitDetails = useMemo(() => {
    const listing = booking ? booking.listing : Map()

    return [
      {
        title: `IMÓVEL ${listing.get('externalId')}`,
        description: `${toCurrency(listing.get('rentPrice'))} /mês`,
        icon: <Home />,
      },
      {
        title: 'Visita realizada em',
        description: booking && formatLongDate(booking.get('dateAndTime')),
        icon: <AccessAlarms />,
      },
      {
        title: 'Local',
        description: `${getPropertyAddress(listing.toJS())}`,
        icon: <LocationOn />,
      },
    ]
  }, [booking])

  const onFeedbackClick = useCallback(
    event => {
      const { name } = event.currentTarget
      const payload = {
        type: BOOKING_FEEDBACK_TYPE.CLIENT_REVIEW,
        userAction: name,
      }
      dispatch(sendBookingFeedback(payload, bookingId))
    },
    [bookingId, dispatch]
  )

  const handleFeedbackSuccess = useCallback(() => {
    if (shouldSkipNegotiation && booking.userAction === BOOKING_FEEDBACK_USER_ACTION.PROPOSAL) {
      navigate(`/proposta/feedback/${leaseId}/?feedback=true`)
    }
    if (!shouldSkipNegotiation && booking.userAction === BOOKING_FEEDBACK_USER_ACTION.PROPOSAL) {
      navigate(`/proposta/resumo-locacao/${leaseId}/?feedback=true`)
    }
    if (booking.userAction === BOOKING_FEEDBACK_USER_ACTION.MORE_LISTINGS) {
      handleVisitFeedbackModal()
    }
  }, [booking.userAction, handleVisitFeedbackModal, leaseId, shouldSkipNegotiation])
  useOnSuccessCall(SEND_BOOKING_FEEDBACK.ACTION, handleFeedbackSuccess)

  const onGiveUpListingClick = useCallback(() => {
    const payload = {
      giveUp: true,
    }
    dispatch(giveUpListing(payload, bookingId))
  }, [bookingId, dispatch])

  const handleGiveUpListingSuccess = useCallback(() => {
    const searchRoute = createSearchQuery(booking.listing)
    if (booking.gaveUpListing) {
      navigate(searchRoute)
    }
  }, [booking])
  useOnSuccessCall(GIVE_UP_LISTING.ACTION, handleGiveUpListingSuccess)

  useEffect(() => {
    dispatch(getBooking(bookingId))
  }, [bookingId, dispatch])

  useEffect(() => {
    if (hasBookingError.size) {
      navigate('/')
    }
  }, [dispatch, hasBookingError])

  useEffect(() => {
    if (Object.keys(parsedQuery).includes('happened')) {
      handleVisitFeedbackModal()
    }
  }, [dispatch, handleVisitFeedbackModal, hasBookingError, parsedQuery])

  if (!booking) {
    return <Loading />
  }

  return (
    <Grid component="section" className={styles.main}>
      <Grid className={styles.content}>
        <ExternalIdButton
          externalId={booking.listing.get('externalId')}
          slug={booking.listing.get('slug')}
          hasEndIcon={!isMobile}
          listingType={listingType}
        />
        <SvgIcon aria-hidden="true" className={styles.houseIcon} viewBox={HouseIcon.viewBox}>
          <use xlinkHref={HouseIcon} />
        </SvgIcon>
        <Grid className={styles.visitRealized}>
          <Typography
            component="h1"
            variant="h3"
            className={classnames(styles.title, styles.mainTitle)}
          >
            Como foi a sua visita?
          </Typography>
          <Button
            fullWidth
            variant="contained"
            disableElevation
            name={BOOKING_FEEDBACK_USER_ACTION.PROPOSAL}
            onClick={onFeedbackClick}
          >
            {shouldSkipNegotiation ? 'Quero alugar este imóvel' : 'Quero fazer uma proposta'}
          </Button>
          <Button
            variant="outlined"
            fullWidth
            color="secondary"
            className={styles.button}
            name={BOOKING_FEEDBACK_USER_ACTION.MORE_LISTINGS}
            onClick={onFeedbackClick}
          >
            Ainda quero ver outros imóveis
          </Button>
        </Grid>
        <Grid className={styles.visitFailed}>
          <Grid className={styles.visitFailedTitle}>
            <SentimentSatisfied className={styles.emojiNeutralIcon} />
            <Typography component="h2" variant="h4" className={styles.title}>
              Não conseguiu visitar o imóvel?
            </Typography>
          </Grid>
          <Button
            variant="outlined"
            color="secondary"
            className={styles.button}
            to={`/agendamento/${booking &&
              booking.listing.get('externalId')}/mudar-horario/${bookingId}`}
          >
            Reagendar
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            className={styles.button}
            onClick={onGiveUpListingClick}
            isLoading={isGiveUpLoading}
          >
            Desisti do imóvel
          </Button>
        </Grid>
        <Grid className={styles.visitDetailsContainer}>
          <Typography component="h2" variant="h4" className={styles.title}>
            Informações da visita
          </Typography>
          <Paper component="section" variant="outlined" className={styles.visitDetailsPaper}>
            <Grid className={styles.visitDetailsContent}>
              {visitDetails.map(detail => (
                <Grid key={detail.title} className={styles.detail}>
                  <Grid className={styles.icon}>{detail.icon}</Grid>
                  <Typography component="p" variant="h4" className={styles.visitDetailsTitle}>
                    {detail.title}
                  </Typography>
                  <Typography component="p" variant="subtitle1" className={styles.visitDetailsText}>
                    {detail.description}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Paper>
          <Button
            variant="outlined"
            fullWidth
            color="secondary"
            className={styles.button}
            startIcon={startIcon}
            onClick={handleVisitOptionsModal}
          >
            Reportar um problema
          </Button>
        </Grid>
        {!shouldSkipNegotiation && (
          <Grid className={styles.steps}>
            <Typography component="h2" variant="h4" className={styles.title}>
              Como funciona para alugar um imóvel de forma digital?
            </Typography>
            <Feedback
              showLabelRequestSent={false}
              className={styles.feedback}
              paperStyle={styles.feedbackPaper}
            />
          </Grid>
        )}
      </Grid>
      {visitOptionsModalOpen && (
        <VisitOptionsModal
          onClose={handleVisitOptionsModal}
          bookingId={bookingId}
          handleOpenNextStep={handleVisitFeedbackModal}
        />
      )}
      {visitFeedbackModalOpen && (
        <VisitFeedbackModal
          bookingId={bookingId}
          feedbackId={booking.feedbackId}
          onClose={handleVisitFeedbackModal}
          happened={hasHappenedField}
          exitRoute={createSearchQuery(booking.listing)}
          isReportProblemFlow={booking.isReportProblemFlow}
        />
      )}
    </Grid>
  )
}

export default withAuth(VisitFeedback)

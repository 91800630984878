import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import PencilIcon from '_assets/images/ic-pen.svg'

import styles from './styles.css'

export const CalendarTheme = {
  DEFAULT: 'default',
  BIG: 'big',
}

const CalendarPage = ({
  className,
  theme,
  monthClassName,
  dayClassName,
  pencilClassName,
  primaryColor,
  month,
  day,
  havePencilIcon,
}) => {
  const createCardStyle = useMemo(() => ({ backgroundColor: primaryColor }), [primaryColor])
  const createSvgStyle = useMemo(() => ({ fill: primaryColor }), [primaryColor])

  return (
    <div className={classnames(className, styles.container, styles[theme])}>
      <div className={styles.calendar}>
        <div className={classnames(monthClassName, styles.month)} style={createCardStyle}>
          {month}
        </div>
        <div className={classnames(dayClassName, styles.day)}>{day}</div>
      </div>
      {havePencilIcon && (
        <svg aria-hidden="true" style={createSvgStyle} className={pencilClassName}>
          <use xlinkHref={PencilIcon} />
        </svg>
      )}
    </div>
  )
}

CalendarPage.propTypes = {
  className: PropTypes.string,
  monthClassName: PropTypes.string,
  dayClassName: PropTypes.string,
  pencilClassName: PropTypes.string,
  primaryColor: PropTypes.string,
  month: PropTypes.string,
  day: PropTypes.string,
  havePencilIcon: PropTypes.bool,
  theme: PropTypes.string,
}

CalendarPage.defaultProps = {
  className: '',
  monthClassName: '',
  dayClassName: '',
  pencilClassName: '',
  primaryColor: '',
  month: '',
  day: '',
  havePencilIcon: false,
  theme: CalendarTheme.DEFAULT,
}

export default CalendarPage

import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
  wrapper: {
    margin: 'auto',
    padding: '2.4rem',
    boxSizing: 'content-box',
    backgroundColor: theme.palette.gray.alabaster,
    borderBottom: `0.1rem solid ${theme.palette.gray.gallery}`,
  },
  content: {
    maxWidth: '37.6rem',
    [theme.breakpoints.down('md')]: {
      maxWidth: 'unset',
      width: '100%',
    },
  },
  status: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  icon: {
    height: '2rem',
    width: '2rem',
  },
  iconButton: {
    [theme.breakpoints.down('md')]: {
      padding: '0.8rem',
    },
  },
  mobile: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'unset',
    },
  },
  desktop: {
    display: 'unset',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}))

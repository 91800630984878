import { makeStyles } from '@material-ui/styles'

export default makeStyles(() => ({
  customTooltip: {
    backgroundColor: '#222',
    padding: '8px',
    fontSize: '1.4rem',
    color: 'white',
  },
}))

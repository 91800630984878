import React from 'react'
import { Link } from '@reach/router'
import Waypoint from 'react-waypoint'

import { sendEvent } from '_utils/mixpanel'

import styles from './styles.css'

const onTermsOfServiceClick = () => {
  sendEvent('Política de Privacidade: Selecionou link para navegar para Termos de Serviço')
}

const onContactClick = () => {
  sendEvent('Política de Privacidade: Selecionou link para entrar em contato')
}

export const GENERAL_INFORMATION = privacyPolicyRealEstateAgencyName => [
  [
    '',
    [
      <span>
        Na {privacyPolicyRealEstateAgencyName}, nós acreditamos que é importante entender os tipos
        de informações que estamos coletando e o que pode acontecer com essas informações ao usar
        nosso site e recursos relacionados (coletivamente, o “<u>Serviço</u>”). Esta Política de
        Privacidade foi elaborada para explicar nossas políticas e procedimentos da{' '}
        {privacyPolicyRealEstateAgencyName} relacionados à coleta, uso e divulgação pela{' '}
        {privacyPolicyRealEstateAgencyName} de informações sobre você relacionadas ao <u>Serviço</u>
        .
      </span>,
      <span>
        Esta Política de Privacidade tem um escopo limitado e se aplica apenas ao <u>Serviço</u>. Ao
        acessar o <u>Serviço</u>, você concorda com a coleta, uso e divulgação de suas informações
        pessoais, conforme explicado nesta Política De Privacidade (a “Política de Privacidade”).
      </span>,
      <span>
        Esta Política de Privacidade está incorporada e está sujeita aos{' '}
        <Link to="/termos-de-servico" onClick={onTermsOfServiceClick} className={styles.link}>
          Termos de Serviço
        </Link>
        . Seu uso do <u>Serviço</u> e qualquer informação que você fornecer através do{' '}
        <u>Serviço</u> está sujeita aos termos desta Política de Privacidade e aos nossos{' '}
        <Link to="/termos-de-servico" onClick={onTermsOfServiceClick} className={styles.link}>
          Termos de Serviço
        </Link>
        .
      </span>,
    ],
  ],
]

export const GATHERING_INFORMATION = privacyPolicyRealEstateAgencyName => [
  [
    'COLETA ATIVA DE INFORMAÇÕES',
    [
      <span>
        Coletamos informações sobre você de várias maneiras em conexão com o <u>Serviço</u>.
      </span>,
    ],
  ],
  [
    'CADASTRO',
    [
      <span>
        Para utilizar algumas ferramentas e recursos disponíveis por meio desse <u>Serviço</u>, um
        usuário deve concluir nosso processo de registro. Durante o registro, o usuário é obrigado a
        fornecer certas categorias de informações, que podem incluir informações pessoalmente
        identificáveis e outras informações sobre você, como nome, endereço, endereço de e-mail,
        contas de redes sociais, senha, data de nascimento, gênero, número de telefone e como você
        ouviu falar sobre {privacyPolicyRealEstateAgencyName}.
      </span>,
    ],
  ],
  [
    'COLETA PASSIVA DE INFORMAÇÕES',
    [
      <span>
        Além das informações que você nos fornece ativamente por meio de métodos como concluir nosso
        processo de registro, comentar ou compartilhar algum imóvel, coletamos informações sobre
        como você interage com o <u>Serviço</u>. Coletamos informações passivas para fins como
        testar e melhorar a experiência do usuário com o <u>Serviço</u> e a compilação de dados
        demográficos e informações de uso relacionadas para fins internos e para divulgação a
        terceiros, como anunciantes e parceiros de negócios.
      </span>,
      <span>
        Os tipos de informações passivas que coletamos incluem, sem limitação, determinadas
        informações padrão enviadas pelo seu navegador, como seu endereço IP, seu ID de dispositivo,
        seu tipo de navegador e recursos e idioma, seu sistema operacional, a data e hora você
        acessa o <u>Serviço</u> e o site de referência do qual você se vinculou ao <u>Serviço</u>.
        Também coletamos informações relacionadas ao seu comportamento dentro do <u>Serviço</u>{' '}
        (como páginas visualizadas, <i>links</i>
        clicados e outras ações realizadas no <u>Serviço</u>).Também podemos utilizar
        <i>links</i> especiais, imagens eletrônicas ou outros objetos incorporados no Site - às
        vezes chamados de <i>web beacons</i> ou <i>pixels</i> de rastreamento - para coletar
        informações que ajudam a determinar e analisar como você e outros visitantes usam o Site.
      </span>,
      'Se você nos forneceu informações pessoais por meio de registro ou outro meio voluntário, poderemos conectar suas informações pessoais com informações passivas coletadas por meio do nosso site e usar as informações combinadas para ajudar a determinar seus possíveis interesses em determinados produtos, serviços ou informações e para outros fins descritos nesta Política de Privacidade.',
    ],
  ],
  [
    'COOKIES',
    [
      <span>
        Informações relacionadas às suas interações com o <u>Serviço</u> podem ser coletadas e
        armazenadas por nós em pequenos arquivos de dados - comumente referidos como <i>cookies</i>{' '}
        - que são colocados em seu dispositivo por nossos servidores. Podemos usar <i>cookies</i> de
        sessão temporária, que são encerrados quando você fecha a sessão atual, para armazenar
        determinadas informações pessoais fornecidas para fins de administração dessa sessão e para
        facilitar as transações solicitadas por você durante essa sessão.
      </span>,
      <span>
        Também podemos usar <i>cookies</i> persistentes, que permanecem salvos no seu dispositivo
        após a sessão atual ser fechada, para armazenar informações relacionadas às suas interações
        com o <u>Serviço</u> para facilitar futuras sessões. A maioria dos navegadores aceita{' '}
        <i>cookies</i> automaticamente, mas normalmente você pode modificar as configurações do seu
        navegador para recusar <i>cookies</i> ou notificá-lo quando um cookie é colocado em seu
        dispositivo.
      </span>,
      <span>
        Cada navegador é diferente, mas geralmente os usuários podem remover <i>cookies</i>
        seguindo as instruções fornecidas no arquivo &#34ajuda&#34 do navegador. Observe que, se
        você rejeitar <i>cookies</i> ou desativar <i>cookies</i>, alguns recursos do <u>Serviço</u>{' '}
        poderão não funcionar corretamente.
      </span>,
    ],
  ],
  [
    'COLETA DE INFORMAÇÕES SOBRE MENORES DE IDADE',
    [
      <span>
        O <u>Serviço</u> é destinado ao público em geral e não se destina a crianças menores de 16
        anos. O uso do <u>Serviço</u> é proibido para menores de 16 anos. Se você tiver entre 16 e
        18 anos de idade, você deve obter autorização de seus pais ou responsável legal antes de
        usar o <u>Serviço</u>.
      </span>,
      <span>
        Embora o <u>Serviço</u> possa conter informações que possam ser de interesse para as
        crianças, o <u>Serviço</u> não é direcionado a crianças e não coletamos ou solicitamos
        intencionalmente informações pessoais de crianças menores de 16 anos.
      </span>,
    ],
  ],
]

export const USE_OF_INFORMATION = privacyPolicyRealEstateAgencyName => [
  [
    '',
    [
      <span>
        O <u>Serviço</u> permite que os usuários acessem e compartilhem conteúdo do catálogo{' '}
        {privacyPolicyRealEstateAgencyName}, outras redes sociais e outros sites e serviços on-line
        e podem incluir vários recursos e funcionalidades, como comentários, quadros de mensagens ou
        áreas de bate-papo, onde os usuários podem postar informações e se comunicar uns com os
        outros, esteja ciente de que essas postagens e comunicações estão sendo disponibilizadas
        publicamente on-line e você o faz por sua conta e risco.Nós não usaremos, compartilharemos
        ou de outra forma transferiremos informações sobre você, exceto de acordo com os termos
        estabelecidos nesta Política de Privacidade.
      </span>,
      <span>
        Além disso, podemos usar, compartilhar ou transferir informações sobre você para outras
        finalidades para as quais obtivemos seu consentimento. Seu consentimento pode ser implícito,
        considerado (usando um mecanismo de cancelamento) ou expresso.
      </span>,
      <span>
        O consentimento expresso pode ser dado oralmente, eletronicamente ou por escrito. O
        consentimento implícito é o consentimento que pode ser razoavelmente inferido de sua ação ou
        omissão, independentemente da sua decisão em relação ao compartilhamento de suas informações
        pessoais, podemos compartilhar dados demográficos e informações de uso relacionadas com
        nossos parceiros de negócios de forma agregada ou anônima com o objetivo de remover ou
        ocultar quaisquer informações pessoais que possam identificar qualquer usuário individual.
        Podemos usar informações sobre você para fornecer produtos e serviços para você e para
        personalizar o <u>Serviço</u> de acordo com seus interesses e aprimorar sua experiência
        geral com o <u>Serviço</u>.
      </span>,
      <span>
        Tentamos reconhecê-lo usando vários métodos, inclusive fazer <i>login</i> com seu nome de
        usuário e sua senha.Assim que reconhecemos você, sua experiência pode ser personalizada para
        você, para que você possa ver o que mais lhe interessa, além disso, conforme descrito acima,
        utilizamos informações passivas para saber mais sobre como você interage com o{' '}
        <u>Serviço</u>.
      </span>,
      <span>
        Informações passivas podem ser combinadas com outras informações e usadas para finalidades
        como aprimorar sua experiência usando o <u>Serviço</u>, melhorar o <u>Serviço</u>, testar e
        aprimorar a segurança e a compilação de dados demográficos e informações de uso relacionadas
        para nossos propósitos internos e para outras finalidades descritas neste documento.
        Enquanto nos esforçamos para proteger as informações pessoais de nossos usuários, podemos
        usar, compartilhar ou transferir seu pessoal sem o seu conhecimento ou consentimento, se
        exigido pela lei aplicável ou requisitos regulamentares ou na crença de boa fé de que tal
        ação é necessária ou apropriada. De tempos em tempos, podemos decidir que é mais eficiente
        usar terceiros para executar funções específicas relacionadas ao <u>Serviço</u>, se usarmos
        um terceiro para executar funções específicas (como o envio de comunicações para nossos
        usuários em nosso nome), as informações (como informações de contato) poderão ser
        compartilhadas com esse terceiro na medida do necessário para que o terceiro execute essa
        função. Autorizamos esses prestadores de serviços terceirizados a usar informações
        pessoalmente identificáveis apenas com a finalidade de fornecer os serviços específicos
        solicitados por nós.
      </span>,
      <span>
        Os prestadores de serviços terceirizados cobertos por este parágrafo não incluem sites,
        aplicativos ou serviços de terceiros acessados ​​através de <i>links</i> dentro do Serviço,
        que são regidos por uma disposição mais específica abaixo. Se você optar por um recurso que
        permita a terceiros entrar em contato com você, suas informações pessoais (como suas
        informações de contato e outras informações coletadas durante sua visita ao Serviço) poderão
        ser compartilhadas com agregadores de dados, profissionais de marketing e outras
        organizações (possivelmente na forma de aluguel de lista).
      </span>,
      'No caso de vendermos, cedermos ou transferirmos alguns ou todos os nossos negócios para um sucessor ou adquirente, poderemos vender, ceder ou transferir todas as suas informações, independentemente do seu status, para tal sucessor ou adquirente.',
    ],
  ],
]

export const SECURITY = [
  [
    'GARANTIA',
    [
      <span>
        Temos medidas de segurança para ajudar a proteger contra o acesso não autorizado de
        informações pessoalmente identificáveis sob nosso controle. Utilizamos os métodos de
        segurança <i>online</i> e <i>offline</i>, incluindo <i>firewalls</i>, senhas e acesso físico
        restrito aos locais onde suas informações são armazenadas para ajudar a proteger as
        informações pessoalmente identificáveis.Nossa equipe é treinada para cumprir nossos
        procedimentos de segurança, e nossos procedimentos de segurança são revisados e revisados
        regularmente conforme julgarmos necessário.
      </span>,
    ],
  ],
  [
    'COMO VOCÊ PODE ACESSAR E ATUALIZAR AS SUAS INFORMAÇÕES',
    [
      'Nosso objetivo é que qualquer informação pessoal em nossa posse seja tão precisa, atual e completa quanto necessário para os propósitos para os quais usamos essa informação. Reservamo-nos o direito de não alterar nenhuma informação pessoal e, em vez disso, anexar qualquer informação alternativa que a pessoa em questão acredite ser apropriada. Até certo ponto, confiamos nos indivíduos para atualizar suas próprias informações pessoais, a menos que um indivíduo nos avise sobre essas mudanças importantes, talvez não tenhamos como saber sobre elas.',
    ],
  ],
]

export const EMAIL_LIST = [
  [
    '',
    [
      <span>
        Podemos usar suas informações de contato periodicamente para lhe enviar e-mails ou outras
        comunicações referentes a atualizações do <u>Serviço</u> e para alertá-lo ou lembrá-lo dos
        acontecimentos relacionados ao <u>Serviço</u>.
      </span>,
      'A frequência dessas mensagens irá variar dependendo de vários fatores. Se você não deseja mais receber nossos e-mails e deseja ser removido de nossa lista de e-mails, entre em contato conosco e inclua “Desinscrever E-mail” no corpo de sua mensagem.',
      'Se você optar por cancelar a inscrição em nossas listas de e-mails, manteremos suas informações de contato em um arquivo marcado, para que não entremos em contato inadvertidamente com você se seus detalhes forem posteriormente fornecidos a nós por terceiros.',
    ],
  ],
]

export const getOthers = (
  hideSticky,
  showSticky,
  privacyPolicyRealEstateAgencyName,
  contactEmail
) => [
  [
    '',
    [
      <span>
        Seu uso do <u>Serviço</u> indica uma aceitação dos termos da Política de Privacidade. Nós
        nos reservamos o direito de atualizar ou alterar esta Política de Privacidade a qualquer
        momento. Publicaremos qualquer versão revisada desta Política de Privacidade em nosso site,
        assim como enviaremos um e-mail alertando. As alterações contidas em qualquer versão
        revisada desta Política de Privacidade entrarão em vigor a partir do momento em que essa
        versão revisada for publicada, a menos que seja especificado de outra forma.
      </span>,
      <span>
        Esta Política de Privacidade não cria ou confere a qualquer indivíduo nenhumdireito, nem
        impõe à {privacyPolicyRealEstateAgencyName} quaisquer direitos ouobrigações fora ou além de
        quaisquer direitos ou obrigações impostos pelas leis deprivacidade da jurisdição de tal
        indivíduo, conforme aplicável. A legislação deprivacidade ou o direito individual à
        privacidade podem diferir de uma jurisdiçãopara outra.
      </span>,
      <span>
        Os direitos e obrigações descritos nesta Política de Privacidade podem não se aplicar a
        todos os indivíduos ou em todas as jurisdições. Caso haja, em um caso específico, qualquer
        inconsistência entre esta Política de Privacidade e as leis de privacidade aplicáveis ​​à
        jurisdição de tal indivíduo, esta Política de Privacidade deverá ser interpretada, em
        relação a esse caso, para dar efeito e cumprir tais leis de privacidade. Esteja ciente de
        que, ao acessar nosso <u>Serviço</u>, você reconhece que nossos servidores estão localizados
        atualmente nos Estados Unidos e que as várias comunicações resultam necessariamente na
        transferência de informações além das fronteiras internacionais.
      </span>,
      <span>
        Ao acessar o <u>Serviço</u> e comunicar-se eletronicamente conosco, você concorda com o
        processamento e a transferência de suas informações pessoais, conforme estabelecido nesta
        Política de Privacidade. As informações pessoais coletadas por nós podem ser armazenadas e
        processadas nos Estados Unidos da América ou em qualquer outro país em que tenhamos
        instalações. Ao optar por acessar o <u>Serviço</u>, você concorda com qualquer
        transferência, armazenamento ou processamento de dados pessoais fora do seu país.
      </span>,
      <span>
        Esta Política de Privacidade aplica-se unicamente às informações coletadas por nós on-line
        através do <u>Serviço</u> e não se aplica a qualquer site, aplicativo ou serviço que não
        seja o Serviço. O <u>Serviço</u> contém <i>links</i> para outros sites, aplicativos e
        serviços fornecidos por terceiros que não possuímos ou controlamos, e não somos responsáveis
        pelas políticas e práticas de privacidade desses terceiros.
      </span>,
      <span>
        Não somos responsáveis ​​pelas políticas e práticas de privacidade de quaisquer outros
        sites, aplicativos e serviços dos quais você tenha vinculado ao nosso <u>Serviço</u>
        .Incentivamos você a estar ciente quando sair do nosso <u>Serviço</u> e a ler as políticas
        de privacidade de cada um e de todos os terceiros que possam fornecer sites, aplicativos ou
        informações vinculadas ao <u>Serviço</u>. Esta Política de Privacidade pode incluir
        exemplos, mas não se destina a ser restrita em sua aplicação a tais exemplos,portanto,
        “incluindo” significa “incluindo, sem limitação”.
      </span>,
      <b>Sua privacidade é importante para nós.</b>,
      <Waypoint onEnter={hideSticky} onLeave={showSticky}>
        <span>
          Se você tiver dúvidas sobre qualquer uma das disposições descritas acima,{' '}
          <a
            href={`mailto:${contactEmail}`}
            onClick={onContactClick}
            className={styles['email-link']}
          >
            entre em contato conosco.
          </a>
        </span>
      </Waypoint>,
    ],
  ],
]

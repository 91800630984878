import { Tooltip } from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/styles'

export default makeStyles(() => ({
  file: {
    boxShadow: '0 0 0.5rem rgba(0, 0, 0, 0.2)',
    display: 'grid',
    gridTemplateColumns: 'auto 12rem 3.8rem',
    gridTemplateRows: '4rem',
    width: '20rem',
    boxSizing: 'border-box',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: '0.8rem',
    gap: '0.8rem',
  },
  iconButton: {
    width: '3.8rem',
    height: '4rem',
    borderRadius: '0',
  },
  iconFile: {
    width: '1.8rem',
    height: '1.8rem',
  },
  fileName: {
    cursor: 'pointer',
  },
}))

export const WhiteTooltip = withStyles(({ palette: { gray, text } }) => ({
  tooltip: {
    backgroundColor: 'white',
    border: `0.1rem solid ${gray.dusty}`,
    color: text.secondary,
    fontSize: '1rem',
    fontWeight: 'normal',
  },
}))(Tooltip)

import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'

import CheckIcon from '_assets/images/ic-check-circle-full.svg'
import { WARRANTY_TRANSLATION } from '_utils/constants'

import useStyles from './styles'

const WarrantyChip = ({ warranty }) => {
  const styles = useStyles()
  return (
    <Grid item xs={12}>
      <Grid className={styles.analysisWrapper}>
        <svg aria-hidden="true" className={styles.checkIcon}>
          <use xlinkHref={CheckIcon} />
        </svg>
        <Typography component="p" variant="h5" color="textSecondary">
          Você escolheu a garantia{' '}
          <Typography component="span" variant="h5" color="textSecondary" className={styles.bold}>
            {WARRANTY_TRANSLATION[warranty]}
          </Typography>
        </Typography>
      </Grid>
    </Grid>
  )
}

WarrantyChip.propTypes = {
  warranty: PropTypes.string.isRequired,
}

export default React.memo(WarrantyChip)

import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import ContractAlteration from '_assets/images/contract-alteration.svg'
import Button, { ButtonColor, ButtonVariant } from '_components/button'
import { primaryColor } from '_utils/colors'

import styles from './styles.css'

const ContractComplete = () => {
  const user = useSelector(state => state.user)
  const createTextStyle = useMemo(() => ({ fill: primaryColor }), [])

  return (
    <div className={styles.main}>
      <div className={styles.content}>
        <svg aria-hidden="true" className={styles} style={createTextStyle}>
          <use xlinkHref={ContractAlteration} />
        </svg>
        <h1>Contrato assinado com sucesso!</h1>
        {user.fullName && (
          <h2 className={styles.description}>
            Um corretor entrará em contato com você para agendar a entrega de chaves na imobiliária.
          </h2>
        )}
        <Button
          color={ButtonColor.PRIMARY}
          variant={ButtonVariant.BOLD}
          className={styles.button}
          to="/"
        >
          ENTENDI
        </Button>
      </div>
    </div>
  )
}

export default React.memo(ContractComplete)

import { makeStyles } from '@material-ui/styles'

export default makeStyles(() => ({
  textField: {
    '& .MuiInputBase-root': {
      lineHeight: '1.5',
      minHeight: '140px',
      alignItems: 'baseline',
    },
    '& .MuiOutlinedInput-input': {
      fontSize: '1.2rem',
      maxHeight: '100%',
      color: '#000',
    },
    '& .MuiFormHelperText-root': {
      position: 'absolute',
      fontSize: '1.2rem',
      bottom: '8px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '8px',
    },
  },
}))

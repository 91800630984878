import React, { useMemo, useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'

import Button, { ButtonVariant, ButtonColor } from '_components/button'
import withAuth from '_hocs/with-auth'
import AddIcon from '_assets/images/ic-add.svg'
import { EMAIL_REGEX } from '_utils/constants'
import TextField from '_components/material/rounded-textfield'

import ContactCard from '../contact-card'
import MainInfo from '../main-info'

import styles from './styles.css'

const INITIAL_PARTICIPANT_STATE = {
  id: 1,
  fullName: '',
  email: '',
  category: 'income_helper',
  isTenant: true,
  emailError: false,
}

const InviteIncomeHelper = ({
  incomeHelperRef,
  handleRefUpdated,
  lease,
  considerIncomeRef,
  onlyIncomeHelpers,
  description,
  title,
  hideSumRents,
}) => {
  const [participants, setParticipants] = useState([INITIAL_PARTICIPANT_STATE])

  useEffect(
    () => {
      // eslint-disable-next-line no-param-reassign
      incomeHelperRef.current = participants.filter(
        ({ fullName, email }) => fullName.length && email.length
      )
      handleRefUpdated()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [participants]
  )

  // Add participants
  const onClickAddParticipant = useCallback(() => {
    setParticipants(prevParticipants => [
      ...prevParticipants,
      {
        ...INITIAL_PARTICIPANT_STATE,
        id: prevParticipants.length + 1,
      },
    ])
  }, [setParticipants])

  // Delete participants
  const onClickRemoveParticipant = useCallback(
    id => {
      setParticipants(prevParticipants =>
        prevParticipants
          .filter(participant => participant.id !== id)
          .map((participant, index) => ({
            ...participant,
            id: index + 1,
          }))
      )
    },
    [setParticipants]
  )

  // Handle Participants
  const handleParticipants = useCallback(
    event => {
      const { name, value, id } = event.target
      const newParticipants = participants.map(participant => {
        if (participant.id === Number(id)) {
          const newValue = { ...participant, [name]: value }
          if (name === 'email') {
            return { ...newValue, emailError: !EMAIL_REGEX.test(value) }
          }
          return newValue
        }
        return participant
      })
      setParticipants(newParticipants)
    },
    [participants]
  )

  const textFieldProps = useCallback(
    (value, participant) => ({
      onChange: handleParticipants,
      value: participant[value],
      id: String(participant.id),
      name: value,
      error: participant.emailError,
    }),
    [handleParticipants]
  )

  const isAddButtonDisabled = useMemo(() => {
    if (!participants[participants.length - 1]) {
      return true
    }
    const { emailError, fullName, email } = participants[participants.length - 1]
    return emailError || (!fullName.length && !email.length)
  }, [participants])

  return (
    <section className={styles.container}>
      <header>
        <h1 className={styles.title}>{title || 'Informações dos locatários'}</h1>
        <p className={styles['sub-title']}>{description}</p>
      </header>
      <MainInfo
        lease={lease}
        considerIncomeRef={considerIncomeRef}
        handleRefUpdated={handleRefUpdated}
        onlyIncomeHelpers={onlyIncomeHelpers}
        hideSumRents={hideSumRents}
      />
      {participants.map(
        (participant, index) =>
          participants.length > 1 &&
          index < participants.length - 1 && (
            <ContactCard
              key={participant.id}
              participant={participant}
              onClickRemoveParticipant={onClickRemoveParticipant}
            />
          )
      )}
      {participants.map(
        (participant, index) =>
          participants.length - 1 === index && (
            <div key={participant.id}>
              <TextField
                label="Nome completo"
                autoComplete="name"
                {...textFieldProps('fullName', participant)}
              />
              <TextField
                label="E-mail"
                autoComplete="email"
                helperText={participant.emailError && 'Preencha todos os campos corretamente'}
                {...textFieldProps('email', participant)}
              />
            </div>
          )
      )}
      <Button
        variant={ButtonVariant.DEFAULT}
        color={ButtonColor.TEXT_BLACK}
        className={styles.button}
        onClick={onClickAddParticipant}
        disabled={isAddButtonDisabled}
      >
        <div className={styles['itens-button']}>
          <svg aria-hidden="true" className={styles.icon}>
            <use xlinkHref={AddIcon} />
          </svg>
          <p className={styles['text-button']}>Adicionar mais pessoas</p>
        </div>
      </Button>
    </section>
  )
}

InviteIncomeHelper.propTypes = {
  incomeHelperRef: PropTypes.shape({
    current: PropTypes.arrayOf(PropTypes.shape({}), PropTypes.arrayOf()),
  }),
  considerIncomeRef: PropTypes.shape({ current: PropTypes.shape({}) }),
  handleRefUpdated: PropTypes.func.isRequired,
  lease: PropTypes.shape({
    participants: PropTypes.arrayOf(
      PropTypes.shape({
        fullName: PropTypes.string,
        email: PropTypes.string,
        id: PropTypes.number,
        totalIncome: PropTypes.number,
        considerIncome: PropTypes.bool,
        monthlyIncome: PropTypes.number,
      })
    ),
  }).isRequired,
  onlyIncomeHelpers: PropTypes.bool,
  description: PropTypes.node,
  title: PropTypes.node,
  hideSumRents: PropTypes.bool,
}

InviteIncomeHelper.defaultProps = {
  incomeHelperRef: {},
  considerIncomeRef: {},
  onlyIncomeHelpers: false,
  description: {},
  title: '',
  hideSumRents: false,
}

export default withAuth(React.memo(InviteIncomeHelper))

import React, { useState, useCallback, useEffect, Fragment, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import classnames from 'classnames'
import { Grid, Typography } from '@material-ui/core'
import { navigate, useLocation, useParams } from '@reach/router'
import humps from 'humps'

import Loading from '_components/loading'
import MultilineTextField from '_components/modals/multiline-text-field'
import Button from '_components/material/button'
import { updateLease, UPDATE_LEASE } from '_modules/leases/actions'
import { getLeaseById } from '_modules/leases/selectors'
import { SEND_PROPOSAL } from '_modules/proposal/actions'
import { useWindowSize } from '_utils/hooks'
import { loadingSendProposalSelector } from '_modules/proposal/selectors'
import { INPUT_MAX_CHARACTERS, FEATURE_FLAGS } from '_utils/constants'
import useGetLease from '_hooks/use-get-lease'
import useOnSuccessCall from '_hooks/use-on-success'

import useStyles from './styles'

const peopleAmountOptions = ['1', '2', '3', '4', '5+']
export const liveStatus = {
  ALONE: 'alone',
  FAMILY: 'family',
  FRIENDS: 'friends',
}

const ProposalPresentation = () => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const { isMobile } = useWindowSize()
  const location = useLocation()
  const { leaseId } = useParams()
  useGetLease(leaseId)
  const lease = useSelector(state => getLeaseById(state, leaseId))
  const user = useSelector(state => state.user)
  const loadingSend = useSelector(loadingSendProposalSelector)

  const [stringCount, setStringCount] = useState({
    petDescription: `0 / ${INPUT_MAX_CHARACTERS.BIG}`,
    description: `0 / ${INPUT_MAX_CHARACTERS.BIG}`,
  })

  const [livingWithWho, setlivingWithWho] = useState(liveStatus.ALONE)
  const [numberOfPeople, setNumberOfPeople] = useState('1')
  const [hasPet, setHasPet] = useState(false)
  const [descriptions, setDescriptions] = useState({
    petDescription: '',
    description: '',
  })
  const formattedNumberOfPeople = useMemo(() => numberOfPeople && numberOfPeople.replace('+', ''), [
    numberOfPeople,
  ])
  useEffect(() => {
    setStringCount({
      petDescription: `${descriptions.petDescription.length} / ${INPUT_MAX_CHARACTERS.BIG}`,
      description: `${descriptions.description.length} / ${INPUT_MAX_CHARACTERS.BIG}`,
    })
  }, [descriptions.petDescription.length, descriptions.description.length])

  const handleRedirection = useCallback(() => {
    const leaseID = lease.get('id')
    const formatNumberOfPeople = numberOfPeople && numberOfPeople.replace(/[^\d]+/g, '')
    if (location.pathname.includes('negociacoes')) {
      if (FEATURE_FLAGS.allowAllWarrantiesEnabled && lease) {
        navigate(`/minhas-negociacoes/${leaseID}/garantia/escolha`, {
          state: { listing: lease.get('listing').toJS() },
        })
        return
      }

      navigate(lease && `/analise-credito/${leaseID}/inicio`, {
        state: { listing: lease.get('listing').toJS() },
      })
      return
    }

    navigate(`/proposta/fazer-proposta/${leaseID}`, {
      state: {
        leaseId: leaseID,
        livingWithWho,
        numberOfPeople: formatNumberOfPeople,
        hasPet,
        ...descriptions,
      },
    })
  }, [descriptions, hasPet, lease, livingWithWho, location.pathname, numberOfPeople])

  const handleProposal = useCallback(() => {
    const state = {
      livingWithWho,
      numberOfPeople: formattedNumberOfPeople,
      hasPet,
      ...descriptions,
    }
    localStorage.setItem('proposalState', JSON.stringify(state))
    handleRedirection()
  }, [descriptions, formattedNumberOfPeople, handleRedirection, hasPet, livingWithWho])

  useOnSuccessCall(UPDATE_LEASE.ACTION, handleProposal)
  useOnSuccessCall(SEND_PROPOSAL.ACTION, handleRedirection)

  const onChangeTextField = useCallback(
    event => {
      const value = event.target.value.length
      if (value > INPUT_MAX_CHARACTERS.BIG) {
        return
      }
      setDescriptions({
        ...descriptions,
        [event.currentTarget.name]: event.target.value,
      })
    },
    [descriptions]
  )

  const handleSetlivingWithWho = useCallback(event => {
    const { name } = event.currentTarget
    setlivingWithWho(name)
  }, [])

  const handleSetPeopleOption = useCallback(event => {
    const { name } = event.currentTarget
    setNumberOfPeople(name)
  }, [])

  const handleSetHasPet = useCallback(event => {
    const { name } = event.currentTarget
    setHasPet(name === 'SIM')
  }, [])

  const handleNextButton = useCallback(() => {
    if (!lease) {
      return
    }

    const state = {
      livingWithWho,
      numberOfPeople: formattedNumberOfPeople,
      hasPet,
      ...descriptions,
    }
    dispatch(updateLease(leaseId, { mainTenantData: humps.decamelizeKeys(state) }))
  }, [descriptions, dispatch, formattedNumberOfPeople, hasPet, lease, leaseId, livingWithWho])

  if (!user || !lease) {
    return <Loading />
  }

  return (
    <Grid className={styles.container}>
      <Grid component="main" className={styles.content}>
        <Grid>
          <Typography component="h1" variant="h4" color="textPrimary" className={styles.title}>
            Olá, {user.fullName}
          </Typography>
          <Typography component="p" variant="subtitle1" color="textSecondary">
            Antes de seguir com a proposta, queremos conhecer você melhor.
          </Typography>
        </Grid>
        {isMobile && (
          <Typography
            component="h2"
            variant="subtitle1"
            color="textSecondary"
            className={styles.mobileOnly}
          >
            Informações do locatário
          </Typography>
        )}
        <Grid className={styles.paper}>
          <Grid>
            <Typography
              component={isMobile ? 'h3' : 'h2'}
              variant="subtitle1"
              color="textSecondary"
              className={styles.subtitle}
            >
              Com quem vai morar?
            </Typography>
            <Button
              variant="contained"
              disableElevation
              color="primary"
              className={classnames(styles.button, {
                [styles.defaultButton]: livingWithWho !== liveStatus.ALONE,
              })}
              name={liveStatus.ALONE}
              onClick={handleSetlivingWithWho}
            >
              SOZINHO
            </Button>
            <Button
              variant="contained"
              disableElevation
              color="primary"
              className={classnames(styles.button, {
                [styles.defaultButton]: livingWithWho !== liveStatus.FAMILY,
              })}
              name={liveStatus.FAMILY}
              onClick={handleSetlivingWithWho}
            >
              FAMÍLIA
            </Button>
            <Button
              variant="contained"
              disableElevation
              color="primary"
              className={classnames(styles.button, {
                [styles.defaultButton]: livingWithWho !== liveStatus.FRIENDS,
              })}
              name={liveStatus.FRIENDS}
              onClick={handleSetlivingWithWho}
            >
              AMIGOS
            </Button>
          </Grid>
          <Grid>
            {livingWithWho !== liveStatus.ALONE && (
              <Fragment>
                <Typography
                  component={isMobile ? 'h3' : 'h2'}
                  variant="subtitle1"
                  color="textSecondary"
                  className={classnames(styles.subtitle, styles.extraMargin)}
                >
                  Quantas pessoas vão morar com você?
                </Typography>
                {peopleAmountOptions.map(option => (
                  <Button
                    key={option}
                    disableElevation
                    variant="contained"
                    color="primary"
                    className={classnames(styles.numberButton, {
                      [styles.defaultButton]: numberOfPeople !== option,
                    })}
                    name={option}
                    onClick={handleSetPeopleOption}
                  >
                    {option}
                  </Button>
                ))}
              </Fragment>
            )}
          </Grid>
          <Grid>
            <Typography
              component={isMobile ? 'h3' : 'h2'}
              variant="subtitle1"
              color="textSecondary"
              className={classnames(styles.subtitle, styles.extraMargin)}
            >
              Possui animais de estimação?
            </Typography>
            <Button
              disableElevation
              variant="contained"
              name="SIM"
              color="primary"
              className={classnames(styles.button, {
                [styles.defaultButton]: !hasPet,
              })}
              onClick={handleSetHasPet}
            >
              SIM
            </Button>
            <Button
              disableElevation
              variant="contained"
              name="NAO"
              color="primary"
              className={classnames(styles.button, {
                [styles.defaultButton]: hasPet,
              })}
              onClick={handleSetHasPet}
            >
              NÃO
            </Button>
          </Grid>
          <Grid>
            {hasPet && (
              <Fragment>
                <Typography
                  component={isMobile ? 'h3' : 'h2'}
                  variant="subtitle1"
                  color="textSecondary"
                  className={classnames(styles.subtitle, styles.extraMargin)}
                >
                  Especifique o tamanho
                </Typography>
                <MultilineTextField
                  name="petDescription"
                  value={descriptions.petDescription}
                  placeholder="Aproveite para contar um pouco mais sobre o seu pet."
                  helperText={stringCount.petDescription}
                  onChange={onChangeTextField}
                />
              </Fragment>
            )}
          </Grid>
        </Grid>
        <Grid className={styles.paper}>
          <Grid>
            <Typography
              component={isMobile ? 'h3' : 'h2'}
              variant="subtitle1"
              color="textSecondary"
              className={styles.subtitle}
            >
              Conte mais ao proprietário sobre você
            </Typography>
            <MultilineTextField
              name="description"
              value={descriptions.description}
              placeholder="Escreva algo..."
              helperText={stringCount.description}
              onChange={onChangeTextField}
            />
          </Grid>
          <Grid className={styles.buttonListFinish}>
            <Button
              disableElevation
              className={classnames(styles.button, styles.buttonCancel)}
              to="/minhas-visitas"
            >
              Cancelar
            </Button>
            <Button
              disableElevation
              variant="contained"
              className={classnames(styles.button, styles.buttonNext)}
              onClick={handleNextButton}
              isLoading={loadingSend}
            >
              Próximo
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ProposalPresentation

import React, { useMemo, useCallback, useContext } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { navigate } from '@reach/router'
import { useSelector } from 'react-redux'

import { toCurrency } from '_utils/helpers'
import { getUserInfoSelector } from '_modules/user/selectors'
import Button, { ButtonColor, ButtonVariant, ButtonFormat } from '_components/button'
import { RedirectContext } from '_context/redirect'

import CardItem from './card-item'
import styles from './styles.css'

const CardContent = ({
  isPercentage,
  fee,
  title,
  exclusiveTag,
  list,
  exclusivity,
  description,
  competitor,
  hasCompetitor,
  feeDescription,
}) => {
  const { setRedirectUri } = useContext(RedirectContext)

  const userInfo = useSelector(getUserInfoSelector)

  const cardItems = useMemo(
    () =>
      list ? list.map(item => <CardItem competitor={competitor} item={item} key={item} />) : null,
    [competitor, list]
  )

  const onAnnounceClick = useCallback(() => {
    if (userInfo.fullName) {
      navigate('/anunciar-imovel/cadastro-inicial')
      return
    }
    setRedirectUri('/anunciar-imovel/cadastro-inicial')
    navigate('/login')
  }, [setRedirectUri, userInfo.fullName])

  const valueFee = useMemo(() => {
    if (isPercentage) {
      return fee ? `${fee}%` : '0%'
    }
    return fee ? toCurrency(fee) : 'R$ 0'
  }, [fee, isPercentage])

  return (
    <div className={styles.plan}>
      {exclusivity && (
        <p
          className={classnames(styles.advantageous, {
            [styles.mobile]: !hasCompetitor,
          })}
        >
          MAIS VENDIDO!
        </p>
      )}
      <header className={classnames({ [styles['text-align']]: !hasCompetitor })}>
        {title && <h3 className={styles['plan-title']}>{title}</h3>}
        <p className={styles['plan-fee']}>{valueFee}</p>
        <p className={styles['plan-fee-subtitle']}>{feeDescription || ''}</p>
        <div className={classnames({ [styles['plan-exclusive-tag-empty']]: !exclusiveTag })}>
          {exclusiveTag && (
            <p
              className={classnames(styles['plan-exclusive'], {
                [styles['plan-exclusive-has-competitor']]: hasCompetitor,
              })}
            >
              {exclusiveTag}
            </p>
          )}
        </div>
        <p className={classnames(styles.description, { [styles.competitor]: hasCompetitor })}>
          {description}
        </p>
      </header>
      <ul
        className={classnames(styles['plan-list'], {
          [styles['list-competitor']]: hasCompetitor,
        })}
      >
        {cardItems}
      </ul>
      {!competitor && (
        <Button
          format={ButtonFormat.SQUARED}
          color={ButtonColor.PRIMARY}
          variant={ButtonVariant.BOLD}
          onClick={onAnnounceClick}
          className={styles['plan-button']}
          type="button"
        >
          QUERO ANUNCIAR
        </Button>
      )}
    </div>
  )
}

CardContent.propTypes = {
  list: PropTypes.arrayOf(PropTypes.string),
  isPercentage: PropTypes.bool,
  fee: PropTypes.string,
  title: PropTypes.string,
  exclusiveTag: PropTypes.string,
  exclusivity: PropTypes.bool,
  description: PropTypes.string,
  competitor: PropTypes.bool,
  hasCompetitor: PropTypes.bool,
  feeDescription: PropTypes.string,
}

CardContent.defaultProps = {
  list: [],
  isPercentage: false,
  fee: '',
  title: '',
  exclusiveTag: '',
  exclusivity: true,
  description: '',
  competitor: false,
  hasCompetitor: false,
  feeDescription: '',
}

export default React.memo(CardContent)

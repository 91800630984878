import {
  CIVIL_STATUS_VALUES,
  GENDER_VALUES,
  PARTICIPANTS_INFO,
  PARTICIPANTS_TYPE,
  BRASIL_STATES,
} from '_utils/constants'
import { TEXTFIELD_MASK } from '_components/material/textfield'

export const TYPE = {
  TENANT: PARTICIPANTS_TYPE.TENANT,
  GUARANTOR: PARTICIPANTS_TYPE.GUARANTOR,
  INCOME_HELPER: PARTICIPANTS_TYPE.INCOME_HELPER,
}

export const INPUTS = {
  SECTION_1: [
    {
      value: PARTICIPANTS_INFO.FULL_NAME,
    },
    {
      value: PARTICIPANTS_INFO.EMAIL,
    },
    {
      value: PARTICIPANTS_INFO.CPF,
      mask: TEXTFIELD_MASK.CPF,
    },
    {
      value: PARTICIPANTS_INFO.PHONE_NUMBER,
      mask: TEXTFIELD_MASK.PHONE_NUMBER,
    },
  ],
  SECTION_2: [
    {
      value: PARTICIPANTS_INFO.DATE_OF_BIRTH,
      mask: TEXTFIELD_MASK.DATE,
    },
    {
      value: PARTICIPANTS_INFO.GENDER,
      options: GENDER_VALUES,
      md: 6,
    },
    {
      value: PARTICIPANTS_INFO.CIVIL_STATUS,
      options: CIVIL_STATUS_VALUES,
      md: 6,
    },
    {
      value: PARTICIPANTS_INFO.RG,
      md: 6,
    },
    {
      value: PARTICIPANTS_INFO.ISSUING_AGENCY,
      md: 6,
    },
    {
      value: PARTICIPANTS_INFO.NATIONALITY,
      md: 6,
    },
    {
      value: PARTICIPANTS_INFO.OCCUPATION,
      md: 6,
    },
    {
      value: PARTICIPANTS_INFO.CURRENT_ADDRESS,
    },
  ],
  SECTION_CREDPAGO: [
    {
      value: PARTICIPANTS_INFO.DATE_OF_BIRTH,
      mask: TEXTFIELD_MASK.DATE,
      md: 6,
    },
    {
      value: PARTICIPANTS_INFO.CIVIL_STATUS,
      options: CIVIL_STATUS_VALUES,
      md: 6,
    },
    {
      value: PARTICIPANTS_INFO.GENDER,
      options: GENDER_VALUES,
      md: 6,
    },
    {
      value: PARTICIPANTS_INFO.NATIONALITY,
      md: 6,
    },
    {
      value: PARTICIPANTS_INFO.RG,
      md: 6,
    },
    {
      value: PARTICIPANTS_INFO.ISSUING_AGENCY,
      md: 6,
    },
    {
      value: PARTICIPANTS_INFO.OCCUPATION,
      md: 6,
    },
    {
      value: PARTICIPANTS_INFO.CEP,
      md: 6,
      isCredpagoRelated: true,
      mask: TEXTFIELD_MASK.CEP,
    },
    {
      value: PARTICIPANTS_INFO.ADDRESS,
      md: 6,
      isCredpagoRelated: true,
    },
    {
      value: PARTICIPANTS_INFO.NEIGHBORHOOD,
      md: 6,
      isCredpagoRelated: true,
    },
    {
      value: PARTICIPANTS_INFO.STREET_NUMBER,
      md: 6,
      isCredpagoRelated: true,
    },
    {
      value: PARTICIPANTS_INFO.EXTRA_ADDRESS_INFO,
      md: 6,
      isCredpagoRelated: true,
    },
    {
      value: PARTICIPANTS_INFO.CITY,
      md: 6,
      isCredpagoRelated: true,
    },
    {
      value: PARTICIPANTS_INFO.STATE,
      md: 6,
      isCredpagoRelated: true,
      options: BRASIL_STATES,
    },
  ],
  TOGGLES: [
    {
      value: PARTICIPANTS_INFO.IS_TENANT,
      options: [true, false],
    },
  ],
}

import { makeStyles } from '@material-ui/styles'

export default makeStyles(() => ({
  input: {
    '& .MuiOutlinedInput-input': {
      fontSize: '1.4rem',
      maxHeight: '200px',
      padding: '8px',
      color: '#b00020',
    },
  },
  textField: {
    '& .MuiInputBase-root': {
      lineHeight: '1.5',
      minHeight: '252px',
      alignItems: 'baseline',
      maxHeight: '252px',
      height: 'auto',
    },
    '& .MuiFormHelperText-root.Mui-error': {
      color: '#b00020',
    },
    '& .MuiOutlinedInput-input': {
      fontSize: '1.4rem',
      maxHeight: '200px',
      padding: '8px',
    },
    '& .MuiFormHelperText-root': {
      position: 'absolute',
      fontSize: '1.2rem',
      bottom: '8px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '12px',
    },
  },
}))

import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
  dialog: {
    width: '100%',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      '& .MuiDialog-paper': {
        margin: 0,
        borderRadius: 30,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        position: 'absolute',
        bottom: 0,
      },
    },
  },
  content: {
    width: '40.8rem',
    minHeight: '41.6rem',
    padding: '1.6rem',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '2.4rem 2.4rem 3.2rem',
    },
  },
  header: {
    display: 'grid',
    gridTemplateColumns: '1fr min-content',
    height: '3.2rem',
  },
  stepNumber: {
    justifySelf: 'start',
    padding: '0.8rem',
    borderRadius: '0.4rem',
    backgroundColor: theme.palette.gray.main,
    fontSize: '1.2rem',
    lineHeight: '1.8rem',
    fontWeight: 'bold',
    color: theme.palette.text.secondary,
  },
  closeButton: {
    justifySelf: 'end',
  },
  stepContent: {
    padding: '0 4.8rem',
    [theme.breakpoints.down('sm')]: {
      padding: '0 1.6rem',
    },
  },
}))

import { schema } from 'normalizr'
import { List } from 'immutable'

import { getPropertyDefaultTitle } from '_utils/property'

import Model from './model'

class Property extends Model({
  externalId: null,
  id: null,
  address: null,
  amenities: null,
  available: null,
  bathrooms: null,
  bedrooms: null,
  booking: null,
  city: null,
  condominiumDescription: null,
  condominiumFee: null,
  coordinates: null,
  description: null,
  exact: null,
  floor: null,
  furnished: null,
  insurance: null,
  listingDescription: null,
  listingType: null,
  livingArea: null,
  neighborhood: null,
  numberOfEnsuites: null,
  parkingSpots: null,
  pets: null,
  pictures: [], // TODO: check this to use immutable list
  purposeType: null,
  convertedPictures: List(),
  postalCode: null,
  rentPrice: null,
  state: null,
  streetNumber: null,
  taxes: null,
  title: null,
  totalArea: null,
  transactionType: null,
  url: null,
  video: null,
  reserved: null,
  slug: null,
  garbageFee: null,
}) {}

const propertySchema = new schema.Entity(
  'properties',
  {},
  {
    idAttribute: 'external_id',
    processStrategy: entity =>
      new Property({ ...entity, slug: getPropertyDefaultTitle(entity, true) }),
  }
)

export { propertySchema, Property as default }

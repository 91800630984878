import { schema } from 'normalizr'
import { List, Map } from 'immutable'

import Model from './model'

class Lease extends Model({
  available: undefined,
  bookings: List(),
  considerIncome: undefined,
  contractChanges: List(),
  contractPreview: undefined,
  contracts: List(),
  createdAt: undefined,
  dataRequest: undefined,
  documents: List(),
  fireInsuranceAgreed: undefined,
  fireInsurancePrice: undefined,
  hasNewDocuments: undefined,
  hasNewPersonalData: undefined,
  hasNewProfessionalData: false,
  hasRequestedDocuments: false,
  hasRequestedGuarantors: undefined,
  hasRequestedParticipants: undefined,
  hasRequestedPersonalData: false,
  hasRequestedProfessionalData: undefined,
  id: undefined,
  incomeApproved: undefined,
  incomeHelperRequest: undefined,
  incomeHelpers: List(),
  isTenant: undefined,
  listing: Map(),
  monthlyIncome: undefined,
  newDocuments: undefined,
  newPersonalData: undefined,
  newProfessionalData: undefined,
  participants: List(),
  previousWarranty: undefined,
  professionalData: undefined,
  proposal: undefined,
  registrationTag: undefined,
  registrationUpdateTag: undefined,
  rejectionComment: undefined,
  rentPrice: undefined,
  requestedComment: undefined,
  requestedDataComment: undefined,
  requestedDocuments: undefined,
  requestedPersonalData: undefined,
  requestedProfessionalData: undefined,
  requiredGuarantorIncome: undefined,
  requiredIncome: undefined,
  spouse: Map(),
  status: undefined,
  totalIncome: undefined,
  totalPrice: undefined,
  updatedAt: undefined,
  user: Map(),
  warranty: undefined,
  warrantyRequest: undefined,
}) {}

const leaseSchema = new schema.Entity(
  'leases',
  {},
  { processStrategy: entity => new Lease(entity) }
)
export { leaseSchema, Lease as default }

import React, { useEffect, useRef, useCallback, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { navigate } from '@reach/router'
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import classnames from 'classnames'

import Button, { ButtonVariant, ButtonFormat, ButtonColor } from '_components/button'
import {
  getLeaseById,
  checkIsCreatingParticipants,
  checkIsSendingToReview,
  checkErrorAddingIncomeHelpers,
  checkErrorSendingToReview,
  checkIsUpdatingUserData,
  checkIsUpdatingParticipant,
} from '_modules/leases/selectors'
import {
  getLease,
  inviteParticipants,
  sendLeaseToReview,
  updateParticipants,
  updateUserData,
} from '_modules/leases/actions'
import { usePrevious } from '_utils/hooks'
import InviteParticipant from '_components/add-income-helpers/invite-participant'
import InviteIncomeHelper from '_components/add-income-helpers/income-helper'
import { PARTICIPANTS_TYPE, WARRANTY_TYPES } from '_utils/constants'
import Snackbar from '_components/snackbar'
import { triggerSnackbar, TRIGGER_SNACKBAR } from '_modules/snackbar/actions'

import styles from './styles.css'
import useStyles from './styles'

const Invite = ({ id }) => {
  const classes = useStyles()
  // eslint-disable-next-line no-unused-vars
  const [isButtonDisabled, setDisabledButton] = useState(true)
  const dispatch = useDispatch()
  const tenantsRef = useRef()
  const incomeHelpersRef = useRef()
  const considerIncomeRef = useRef()
  const guarantorsRef = useRef()

  // Loading state to invite participants
  const isAddingIncomeHelper = useSelector(checkIsCreatingParticipants)
  const wasAddingIncomeHelper = usePrevious(isAddingIncomeHelper)

  // Loading state to send the lease to review
  const isSendingLeaseToReview = useSelector(checkIsSendingToReview)
  const wasSendingLeaseToReview = usePrevious(isSendingLeaseToReview)

  // Loading state to update the mainTenant considerIncome field
  const isUpdatingUser = useSelector(checkIsUpdatingUserData)
  const wasUpdatingUser = usePrevious(isUpdatingUser)

  // Loading state to update the participant considerIncome field
  const isUpdatingParticipant = useSelector(checkIsUpdatingParticipant)
  const wasUpdatingParticipant = usePrevious(isUpdatingParticipant)

  const errorAddingIncomeHelper = useSelector(checkErrorAddingIncomeHelpers)
  const errorSendingLeaseToReview = useSelector(checkErrorSendingToReview)
  const lease = useSelector(state => getLeaseById(state, id))
  const [monthlyIncome, setMonthlyIncome] = useState(lease && lease.monthlyIncome)

  const externalId = useMemo(() => (lease ? lease.listing.get('externalId') : null), [lease])

  useEffect(() => {
    if (!lease && id) {
      dispatch(getLease(id))
    }
  }, [dispatch, id, lease])

  useEffect(() => {
    if (lease) {
      setMonthlyIncome(lease.monthlyIncome)
    }
  }, [lease])

  useEffect(() => {
    if (errorAddingIncomeHelper || errorSendingLeaseToReview) {
      return
    }

    if (!isSendingLeaseToReview && wasSendingLeaseToReview) {
      navigate('/minhas-negociacoes/convidado')
    }
  }, [
    dispatch,
    errorAddingIncomeHelper,
    errorSendingLeaseToReview,
    isAddingIncomeHelper,
    isSendingLeaseToReview,
    isUpdatingParticipant,
    isUpdatingUser,
    lease,
    wasAddingIncomeHelper,
    wasSendingLeaseToReview,
    wasUpdatingParticipant,
    wasUpdatingUser,
  ])

  const isReadyToReview = useMemo(
    () => (!isAddingIncomeHelper && wasAddingIncomeHelper) || (!isUpdatingUser && wasUpdatingUser),
    [isAddingIncomeHelper, isUpdatingUser, wasAddingIncomeHelper, wasUpdatingUser]
  )

  useEffect(() => {
    if (isReadyToReview) {
      dispatch(sendLeaseToReview({ externalId, leaseId: id }))
    }
  }, [
    dispatch,
    externalId,
    id,
    isAddingIncomeHelper,
    isReadyToReview,
    lease,
    wasAddingIncomeHelper,
  ])

  const isGuarantor = useMemo(
    () => lease && lease.incomeHelperRequest && lease.incomeHelperRequest.getIn(['isGuarantor']),
    [lease]
  )
  const handleUpdateDisableButton = useCallback(() => {
    if (!isGuarantor && lease && considerIncomeRef.current.total > lease.requiredIncome) {
      setDisabledButton(false)
      return
    }
    if (tenantsRef.current && incomeHelpersRef.current) {
      const hasErrors =
        !!incomeHelpersRef.current.filter(item => item.emailError).length ||
        !!tenantsRef.current.filter(item => item.emailError).length

      const hasParticipants = incomeHelpersRef.current.length > 1 || tenantsRef.current.length > 1
      setDisabledButton(!(hasErrors || hasParticipants))
    }
  }, [isGuarantor, lease])

  const hasGuarantorParticipant = useMemo(() => {
    if (lease && lease.incomeHelperRequest && !lease.incomeHelperRequest.getIn(['isGuarantor'])) {
      return false
    }
    return !![WARRANTY_TYPES.GUARANTOR, WARRANTY_TYPES.STUDENT].includes(lease && lease.warranty)
  }, [lease])

  const updateAllParticipants = useCallback(
    participantsList => {
      if (participantsList.participants && participantsList.participants.length) {
        participantsList.participants.map(({ participantId, considerIncome }) => {
          return dispatch(
            updateParticipants(
              lease.get('id'),
              participantId,
              { considerIncome },
              lease.listing.get('externalId')
            )
          )
        })
      }
    },
    [dispatch, lease]
  )

  const onSubmit = useCallback(
    event => {
      event.preventDefault()
      if (isGuarantor) {
        const payload = [...guarantorsRef.current]
        if (payload.some(({ emailError }) => emailError)) {
          dispatch(triggerSnackbar())
          return
        }
        dispatch(inviteParticipants(lease.get('id'), payload))
        updateAllParticipants(guarantorsRef.current)
        dispatch(
          updateUserData(
            {
              leaseId: id,
              externalId,
              monthlyIncome,
            },
            externalId
          )
        )
      } else {
        const payload = hasGuarantorParticipant
          ? [...incomeHelpersRef.current, ...tenantsRef.current, ...guarantorsRef.current]
          : [...incomeHelpersRef.current, ...tenantsRef.current]
        if (payload.some(({ emailError }) => emailError)) {
          dispatch(triggerSnackbar())
          return
        }
        dispatch(inviteParticipants(lease.get('id'), payload))
        if (!considerIncomeRef.current.considerIncome) {
          dispatch(
            updateUserData(
              {
                leaseId: id,
                externalId,
                considerIncome: false,
              },
              externalId
            )
          )
        }

        if (hasGuarantorParticipant) {
          updateAllParticipants(guarantorsRef.current)
        }
        updateAllParticipants(considerIncomeRef.current)
      }
    },
    [
      dispatch,
      externalId,
      id,
      isGuarantor,
      hasGuarantorParticipant,
      lease,
      monthlyIncome,
      updateAllParticipants,
    ]
  )

  const handleMonthlyIncome = useCallback((_, value) => {
    setMonthlyIncome(value)
  }, [])

  const existingTenants = useMemo(
    () =>
      lease && lease.participants.size
        ? lease.participants
            .toJS()
            .filter(({ category, enabled }) => category === PARTICIPANTS_TYPE.TENANT && enabled)
        : [],
    [lease]
  )

  const incomeHelperDescription = useCallback(
    () => (
      <p>
        <strong>Confira as informações dos locatários</strong> ou se necessário convide mais pessoas
        para compor renda.
      </p>
    ),
    []
  )

  const guarantorDescription = useMemo(
    () => (
      <p>
        Lembre-se: <strong>Fiadores não podem ser moradores</strong>. Os fiadores devem somar uma
        renda igual ou superior a 4x o valor do aluguel + encargos.
      </p>
    ),
    []
  )
  const inputProps = useMemo(
    () => ({
      className: classes.input,
    }),
    [classes.input]
  )

  if (!lease) return null

  const isLoading =
    isAddingIncomeHelper || isSendingLeaseToReview || isUpdatingParticipant || isUpdatingUser

  return (
    <div className={styles.container}>
      <form className={styles.content} onSubmit={onSubmit}>
        <h1 className={styles.title}>
          {isGuarantor
            ? `${lease.user.get('fullName')}, atualize as informações.`
            : 'Convide outros locatários'}
        </h1>
        <h2 className={styles['sub-title']}>
          {isGuarantor
            ? 'Confira as informações e se necessário, atualize sua renda e os fiadores que farão parte da locação'
            : 'As pessoas convidadas abaixo devem ser diferentes das inseridas anteriormente.'}
        </h2>

        <div className={styles.income}>
          <h3 className={styles['income-title']}>Renda Mensal Líquida</h3>
          <CurrencyTextField
            variant="outlined"
            value={monthlyIncome}
            currencySymbol="R$"
            outputFormat="number"
            onChange={handleMonthlyIncome}
            InputProps={inputProps}
            fullWidth
            className={classnames(classes.textField, styles.adornment)}
            decimalCharacter="."
            digitGroupSeparator=","
            id="monthly-income"
            placeholder="Digite um valor"
            type="tel"
          />
        </div>

        {!isGuarantor && (
          <InviteIncomeHelper
            incomeHelperRef={incomeHelpersRef}
            handleRefUpdated={handleUpdateDisableButton}
            lease={lease.toJS()}
            considerIncomeRef={considerIncomeRef}
            onlyIncomeHelpers={hasGuarantorParticipant}
            description={incomeHelperDescription}
          />
        )}
        {(isGuarantor || hasGuarantorParticipant) && (
          <InviteParticipant
            title="Convide os fiadores"
            description={guarantorDescription}
            type="guarantor"
            participantRef={guarantorsRef}
            handleRefUpdated={handleUpdateDisableButton}
            hasGuarantorParticipant
            lease={lease.toJS()}
          />
        )}
        {!isGuarantor && (
          <InviteParticipant
            title="Outros Moradores"
            description="Se necessário, atualize a lista de moradores que não comprovam renda."
            type="tenant"
            participantRef={tenantsRef}
            handleRefUpdated={handleUpdateDisableButton}
            existingTenants={existingTenants}
            leaseId={id}
            externalId={lease.listing.get('externalId')}
          />
        )}
        <Button
          color={ButtonColor.PRIMARY}
          variant={ButtonVariant.BOLD}
          format={ButtonFormat.ROUNDED}
          className={styles.action}
          // disabled={isButtonDisabled}
          isLoading={isLoading}
          type="submit"
        >
          CONVIDAR
        </Button>
      </form>
      <Snackbar
        variant="error"
        message="Verifique se todos os participantes estão com os dados corretos"
        action={TRIGGER_SNACKBAR.ACTION}
      />
    </div>
  )
}
Invite.propTypes = {
  id: PropTypes.string.isRequired,
}
export default React.memo(Invite)

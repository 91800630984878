import React, { useMemo, useCallback } from 'react'
import { navigate } from '@reach/router'

import ContractAlteration from '_assets/images/contract-alteration.svg'
import Button, { ButtonColor, ButtonVariant } from '_components/button'
import { primaryColor } from '_utils/colors'

import styles from './styles.css'

const SuccessfullContractAlteration = () => {
  const createTextStyle = useMemo(() => ({ fill: primaryColor }), [])

  const onUnderstoodClick = useCallback(() => navigate('/minhas-negociacoes'), [])

  return (
    <div className={styles.main}>
      <div className={styles.content}>
        <svg aria-hidden="true" className={styles} style={createTextStyle}>
          <use xlinkHref={ContractAlteration} />
        </svg>
        <h1>Alterações no contrato solicitadas!</h1>
        <Button
          color={ButtonColor.PRIMARY}
          variant={ButtonVariant.BOLD}
          className={styles.button}
          onClick={onUnderstoodClick}
        >
          ENTENDI
        </Button>
      </div>
    </div>
  )
}

export default React.memo(SuccessfullContractAlteration)

import React, { Fragment } from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import CloseIcon from '@material-ui/icons/Close'

import { PARTICIPANTS_TYPE } from '_utils/constants'
import { formatCost } from '_utils/strings'

import styles from './styles.css'

const ParticipantInfo = ({
  handleCheckbox,
  onDisableParticipant,
  isMainTenant,
  values,
  personalInfo,
  hasGuarantorParticipant,
}) => {
  return (
    <Fragment>
      <div className={classnames(styles['main-tenant'], styles.border)}>
        {isMainTenant ? (
          <div className={styles.title}>
            <div className={styles.participant}>
              <h3 className={styles.name}>
                {personalInfo.fullName} <br />{' '}
                <p className={styles.type}>Locatário Principal (Você)</p>{' '}
              </h3>
              <p className={styles.info}>{personalInfo.email} </p>
            </div>
          </div>
        ) : (
          <div className={styles.title}>
            <div className={styles.participant}>
              <h3 className={styles.name}>
                {personalInfo.fullName}{' '}
                {hasGuarantorParticipant && <p className={styles.type}>Fiador</p>}
              </h3>
              <p className={styles.info}>{personalInfo.email} </p>
            </div>
            <button
              type="button"
              className={styles.click}
              onClick={onDisableParticipant}
              name={personalInfo.id}
            >
              <CloseIcon className={styles.close} />
            </button>
          </div>
        )}
      </div>
      <div className={styles.income}>
        <p className={styles.value}>
          Renda:{' '}
          <strong>
            {values.monthlyIncome ? formatCost(values.monthlyIncome) : 'Não informado'}
          </strong>
        </p>
        <Checkbox
          onClick={handleCheckbox}
          color="primary"
          value={values.monthlyIncome}
          inputProps={{ 'aria-label': 'Marque se você for para considerar essa renda' }}
          id={String(values.id)}
          className={styles.checkbox}
          defaultChecked={values.considerIncome}
          name={isMainTenant ? PARTICIPANTS_TYPE.MAIN_TENANT : ''}
        />
        <span className={styles['text-checkbox']}>Considerar renda</span>
      </div>
    </Fragment>
  )
}

ParticipantInfo.propTypes = {
  values: PropTypes.shape({
    id: PropTypes.number,
    monthlyIncome: PropTypes.number,
    considerIncome: PropTypes.bool,
    requiredIncome: PropTypes.number,
  }).isRequired,
  personalInfo: PropTypes.shape({
    fullName: PropTypes.string,
    email: PropTypes.string,
    id: PropTypes.number,
    totalIncome: PropTypes.number,
  }).isRequired,
  handleCheckbox: PropTypes.func.isRequired,
  onDisableParticipant: PropTypes.func,
  isMainTenant: PropTypes.bool,
  hasGuarantorParticipant: PropTypes.bool,
}

ParticipantInfo.defaultProps = {
  isMainTenant: false,
  hasGuarantorParticipant: false,
  onDisableParticipant: () => {},
}

export default React.memo(ParticipantInfo)

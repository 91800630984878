import humps from 'humps'

import { get, post } from './requests'

export const getProperty = key => (propertyId, params = {}) =>
  get(['listings', propertyId], { key, params })

export const getSitemapXml = () => get(['sitemap-listing-xml'])

export const getProperties = key => params => {
  const payload = humps.decamelizeKeys({ ...params })
  if (params.neighborhoods) {
    if (params.neighborhoods.length > 0) {
      payload.neighborhoods = params.neighborhoods.join(',')
    } else {
      payload.neighborhoods = undefined
    }
  }
  if (params.listingType && Array.isArray(params.listingType)) {
    if (payload.listing_type.includes('res_all') || payload.listing_type.includes('com_all')) {
      payload.listing_type = undefined
    } else {
      payload.listing_type = params.listingType.join(',')
    }
  }
  if (params.externalId) {
    // eslint-disable-next-line camelcase
    const { external_id } = payload
    // eslint-disable-next-line camelcase
    return get(['listings', external_id, 'similar'], {
      key,
    })
  }

  return get(['listings'], { params: payload, key })
}

export const getSimilarProperties = key => params => {
  if (params.externalId) {
    const { externalId } = params
    return get(['listings', externalId, 'similar'], { key, params: {} })
  }
  return get(['listings'], { params: humps.decamelizeKeys(params), key })
}

export const likeProperty = key => propertyId => post(['listings', propertyId, 'like'], { key })

export const bulkLikeProperty = key => listingIds =>
  post(['listings', 'like'], { key }, { listing_ids: listingIds })

export const unLikeProperty = key => propertyId => post(['listings', propertyId, 'unlike'], { key })

export const dislikeProperty = key => propertyId =>
  post(['listings', propertyId, 'dislike'], { key })

export const bulkDislikeProperty = key => listingIds =>
  post(['listings', 'dislike'], { key }, { listing_ids: listingIds })

export const unDislikeProperty = key => propertyId =>
  post(['listings', propertyId, 'undislike'], { key })

export const bookProperty = key => payload =>
  post(['bookings'], { key }, humps.decamelizeKeys(payload))

export const getAvailability = (propertyId, date) =>
  get(['listings', propertyId, 'availability'], { params: { date } })

export const getLikedList = key => id => get(['deals', id, 'liked-listings'], { key })

import React, { useState, useCallback, useMemo, Fragment, useRef, useEffect } from 'react'
import { usePdf } from 'react-pdf-js'
import PropTypes from 'prop-types'

import Building from '_components/building-animated'
import { onMouseDown } from '_utils/helpers'
import { primaryColor } from '_utils/colors'

import styles from './styles.css'

const PDFViewer = ({ file }) => {
  const [page, setPage] = useState(1)
  const [pages, setPages] = useState(null)

  const enabledStyle = useMemo(
    () => ({
      backgroundColor: primaryColor,
    }),
    []
  )

  const onPreviousPageClick = useCallback(() => setPage(prevPage => prevPage - 1), [])
  const onNextPageClick = useCallback(() => setPage(prevPage => prevPage + 1), [])

  const renderPagination = useMemo(() => {
    if (!pages) {
      return null
    }

    const isPreviousDisabled = page === 1
    const isNextDisabled = page === pages

    return (
      <div className={styles.pagination}>
        <button
          type="button"
          className={styles.button}
          onClick={onPreviousPageClick}
          disabled={isPreviousDisabled}
          style={isPreviousDisabled ? {} : enabledStyle}
          onMouseDown={onMouseDown}
        >
          Anterior
        </button>
        <button
          type="button"
          className={styles.button}
          onClick={onNextPageClick}
          disabled={isNextDisabled}
          style={isNextDisabled ? {} : enabledStyle}
          onMouseDown={onMouseDown}
        >
          Próximo
        </button>
      </div>
    )
  }, [enabledStyle, onNextPageClick, onPreviousPageClick, page, pages])

  const canvasEl = useRef(null)

  const [loading, numPages] = usePdf({
    file,
    page,
    canvasEl,
  })

  useEffect(() => {
    setPages(numPages)
  }, [numPages])

  return (
    <Fragment>
      {(loading || !file) && <Building className={styles.building} />}
      {renderPagination}
      <canvas ref={canvasEl} className={styles.pdf} />
      {renderPagination}
    </Fragment>
  )
}

PDFViewer.propTypes = {
  file: PropTypes.string,
}

PDFViewer.defaultProps = {
  file: '',
}

export default React.memo(PDFViewer)

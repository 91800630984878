import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(({ breakpoints, palette }) => ({
  container: {
    display: 'grid',
    width: '33.6rem',
    boxSizing: 'border-box',
    boxShadow: '0 0.3rem .6rem rgba(0, 0, 0, 0.161)',
    borderRadius: '0.4rem',
    overflow: 'hidden',
    textDecoration: 'none',
    marginBottom: '1.6rem',
    backgroundColor: 'white',
    '&:hover': {
      boxShadow: `0 0.55rem 0.8rem ${palette.gray.transparent}`,
    },

    [breakpoints.down('sm')]: {
      width: '32.5rem',
    },
  },
  header: {
    position: 'relative',
    overflow: 'hidden',
    borderRadius: '0.4rem 0.4rem 0 0',
  },
  picture: {
    width: '100%',
    height: '20.3rem',
    objectFit: 'cover',

    [breakpoints.down('sm')]: {
      height: '100%',
    },
  },
  externalId: {
    position: 'absolute',
    zIndex: 1,
    top: '1.6rem',
    left: '1.6rem',
    padding: '0.3rem 0.8rem',
    borderRadius: '0.4rem',
    backgroundColor: 'white',
    fontWeight: 500,
  },
  content: {
    display: 'grid',
    padding: '1.6rem 1.6rem 0 1.6rem',
    height: '100%',

    [breakpoints.down('sm')]: {
      padding: '1.6rem 1.6rem 0',
    },
  },
  topContent: {
    height: '12.9rem',

    [breakpoints.down('sm')]: {
      height: 'auto',
    },
  },
  propertyType: {
    fontWeight: 500,
    marginBottom: '0.8rem',

    [breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  address: {
    [breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  detail: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    margin: '1.6rem 0 0.8rem 0',

    [breakpoints.down('sm')]: {
      margin: '0',
    },
  },
  line: {
    width: 'calc(100% + 3.2rem)',
    transform: 'translateX(-1.6rem)',
    height: '0.1rem',
    border: 'none',
    borderTop: `0.1rem solid ${palette.gray.mercury}`,

    [breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  bottomContent: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gridTemplateAreas: "'rent-price heart-button' 'total-price heart-button'",
    justifyContent: 'space-between',
    padding: '1.6rem 0 2.4rem 0',
    height: '7.4rem',

    [breakpoints.down('sm')]: {
      height: 'auto',
    },
  },
  rentPrice: {
    gridArea: 'rent-price',
    color: palette.text.secondary,
  },
  totalPrice: {
    gridArea: 'total-price',
    color: palette.text.secondary,
  },
  heartButton: {
    gridArea: 'heart-button',
    width: '4rem',
    height: '4rem',
    padding: '0.4rem',
    backgroundColor: 'transparent',
  },
  bookingButton: {
    width: '100%',
    height: '5.4rem',
    borderTop: `0.1rem solid ${palette.gray.mercury}`,
    borderRadius: '0',
    fontWeight: 'bold',
    '&:hover': {
      boxShadow: `0 0.55rem 0.8rem ${palette.gray.transparent}`,
    },

    [breakpoints.down('sm')]: {
      height: '4.8rem',
    },
  },
  smallText: {
    fontSize: '1.2rem',
    lineHeight: '1.8rem',
    color: palette.gray.dusty,
  },
  mediumText: {
    fontSize: '1.4rem',
    lineHeight: '2.1rem',
    color: palette.text.secondary,
  },
  bigText: {
    fontSize: '1.6rem',
    fontWeight: 'bold',
    lineHeight: '2.4rem',
    color: palette.text.primary,
  },
  link: {
    '&&': {
      textDecoration: 'none',
    },
  },
  heartIcon: {
    color: palette.custom.red,
  },
}))

import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { ErrorMessage } from 'react-hook-form'

import SimpleCarousel from '_components/simple-carousel'
import InputButton from '_components/button/input-button'

import FormGroup from '../form-group'

import styles from './styles.css'

const InputCarousel = ({ label, values, name, children, className, type, rules }) => {
  const ErrorComponent = useMemo(
    () => ({ children: errorText }) => {
      return <p className={styles.error}>{errorText}</p>
    },
    []
  )

  return (
    <FormGroup label={label} className={className}>
      <ErrorMessage as={ErrorComponent} name={name} />
      {children}
      <SimpleCarousel className={styles.carousel} slotClassName={styles.slot}>
        {values.map(value => (
          <InputButton
            className={styles.radio}
            name={name}
            key={value.id}
            id={value.id}
            value={value.value}
            label={value.label}
            type={type}
            rules={rules}
          />
        ))}
      </SimpleCarousel>
    </FormGroup>
  )
}

InputCarousel.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  label: PropTypes.string,
  values: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.string, id: PropTypes.string, label: PropTypes.string })
  ),
  name: PropTypes.string,
  type: PropTypes.string,
  rules: PropTypes.shape(),
}

InputCarousel.defaultProps = {
  children: undefined,
  className: '',
  label: '',
  values: [{ value: '', id: '', label: '' }],
  name: '',
  type: 'radio',
  rules: {},
}

export default React.memo(InputCarousel)

export const INITIAL_STATE = {
  fullName: '',
  email: '',
  cpf: '',
  emailError: false,
  cpfError: false,
  errorDescription: '',
}

export const ON_INPUT_CHANGE = 'ON_INPUT_CHANGE'

export const reducer = (state, action) => {
  switch (action.type) {
    case ON_INPUT_CHANGE:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}

import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/styles'
import MUIFormControlLabel from '@material-ui/core/FormControlLabel'
import MUICheckbox from '@material-ui/core/Checkbox'

import { primaryColor } from '_utils/colors'

const Checkbox = ({ name, label, className, onChange, value, inputRef }) => {
  const FormControlLabel = useMemo(
    () =>
      withStyles(theme => ({
        root: {
          marginLeft: '0',
        },
        label: {
          color: theme.palette.secondary.dark,
          fontSize: '1.4rem',
          fontWeight: 'normal',
          lineHeight: '2.1rem',
          float: 'right',
        },
      }))(MUIFormControlLabel),
    []
  )

  const StyledCheckbox = useMemo(
    () =>
      withStyles(() => ({
        root: { '&$checked': { color: primaryColor } },
        checked: {},
      }))(MUICheckbox),
    []
  )

  const CheckboxComponent = useMemo(
    () => <StyledCheckbox name={name} onChange={onChange} inputRef={inputRef} value={value} />,
    [inputRef, name, onChange, value]
  )

  return <FormControlLabel control={CheckboxComponent} label={label} className={className} />
}

Checkbox.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  inputRef: PropTypes.func,
  value: PropTypes.string,
}

Checkbox.defaultProps = {
  name: '',
  label: '',
  className: '',
  onChange: () => undefined,
  inputRef: () => undefined,
  value: '',
}

export default React.memo(Checkbox)

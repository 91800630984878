import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Hammer from 'react-hammerjs'
import { DIRECTION_LEFT, DIRECTION_RIGHT } from 'hammerjs'
import uuidv4 from 'uuid'

import NextArrow from '_components/modal-pictures-full-screen/paged-slick-carousel/next-arrow'
import PreviousArrow from '_components/modal-pictures-full-screen/paged-slick-carousel/prev-arrow'
import CarouselPaging from '_components/modal-pictures-full-screen/paged-slick-carousel/carousel-paging'

import styles from './styles.css'

class Carousel extends PureComponent {
  static propTypes = {
    pictures: PropTypes.arrayOf(PropTypes.string).isRequired,
    className: PropTypes.string,
    activePicture: PropTypes.string,
    onClickArrowPreviews: PropTypes.func.isRequired,
    onClickArrowNext: PropTypes.func.isRequired,
    primaryColor: PropTypes.string.isRequired,
    onPictureView: PropTypes.func,
  }

  static defaultProps = {
    className: '',
    activePicture: 0,
    onPictureView: () => {},
  }

  constructor(props) {
    super(props)
    this.uuid = uuidv4()
    this.state = {
      showArrows: false,
    }
  }

  getTranslateAmount = () => {
    const { pictures } = this.props
    const { activePicture } = this.state
    const totalNumberOfDotsShown = 5
    const middleDot = 3
    const distanceBetweenDots = 14
    if (pictures.length > totalNumberOfDotsShown) {
      if (activePicture < middleDot) {
        return 0
      }
      if (activePicture > pictures.length - middleDot) {
        return -distanceBetweenDots * (pictures.length - totalNumberOfDotsShown)
      }
      return -distanceBetweenDots * activePicture + 2 * distanceBetweenDots
    }
    return 0
  }

  setArrayKey = (type, index) => `${this.uuid}-${type}-${index}`

  handleSwipe = event => {
    const { pictures, onPictureView } = this.props
    const { activePicture } = this.state
    const picturesSize = pictures.size || pictures.length

    if (event.direction === DIRECTION_LEFT) {
      if (activePicture === picturesSize - 1) {
        this.setState({
          activePicture: 0,
        })
        onPictureView(pictures[0])
        return
      }

      this.setState({
        activePicture: activePicture + 1,
      })
      onPictureView(pictures[activePicture + 1])
    }

    if (event.direction === DIRECTION_RIGHT) {
      if (activePicture === 0) {
        this.setState({
          activePicture: picturesSize - 1,
        })
        onPictureView(pictures[picturesSize - 1])
        return
      }

      this.setState({
        activePicture: activePicture - 1,
      })
      onPictureView(pictures[activePicture - 1])
    }
  }

  showArrows = () => {
    this.setState({
      showArrows: true,
    })
  }

  hideArrows = () => {
    this.setState({
      showArrows: false,
    })
  }

  render() {
    const {
      pictures,
      className,
      activePicture,
      onClickArrowPreviews,
      onClickArrowNext,
      primaryColor,
    } = this.props
    const { showArrows } = this.state
    const picturesSize = pictures.size || pictures.length

    const disabledButtonPrevious = activePicture <= 1
    const disabledButtonNext = activePicture >= pictures.size
    return (
      <div
        className={classnames(styles.carousel, className)}
        onMouseEnter={this.showArrows}
        onMouseLeave={this.hideArrows}
      >
        <PreviousArrow
          onClick={onClickArrowPreviews}
          disabled={disabledButtonPrevious}
          visible={showArrows}
          primaryColor={primaryColor}
        />
        <NextArrow
          primaryColor={primaryColor}
          onClick={onClickArrowNext}
          disabled={disabledButtonNext}
          visible={showArrows}
        />
        <Hammer onSwipe={this.handleSwipe}>
          <div className={styles['carousel-inner']}>
            {pictures.map((picture, index) => (
              <div
                className={classnames(styles['picture-container'], {
                  [styles.active]: activePicture === index + 1,
                })}
              >
                <img
                  src={picture}
                  alt="Imóvel"
                  key={this.setArrayKey('hammer', index)}
                  index={index}
                  className={classnames(styles.picture)}
                />
              </div>
            ))}
          </div>
        </Hammer>
        {picturesSize > 1 && (
          <div className={styles.container}>
            <CarouselPaging
              numberOfItems={pictures.size || pictures.length}
              selectedItem={activePicture - 1}
            />
          </div>
        )}
      </div>
    )
  }
}

export default Carousel

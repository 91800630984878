import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
  button: {
    zIndex: '1',
    position: 'absolute',
    left: '-8rem',
    top: '50%',
    transform: 'translate(0, -60%)',
    width: '4.8rem',
    height: '4.8rem',
    backgroundColor: 'white',
    margin: 'auto',
  },
  arrow: {
    margin: 'auto 0 auto 1rem',
    padding: 'auto',
    color: theme.palette.secondary.dark,
  },
}))

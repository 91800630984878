import React, { useCallback, useMemo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormContext, useForm } from 'react-hook-form'
import { navigate } from '@reach/router'

import { useWizardForm, useWindowWidth, usePrevious, useProtectedRoute } from '_utils/hooks'
import {
  createListingRegistration,
  updateListingRegistration,
  UPDATE_LISTING_REGISTRATION,
  updateCurrentPage,
  removeCurrentPage,
  removeListingRegistration,
  sendEmailListingRegistration,
} from '_modules/listing-registration/actions'
import Button, { ButtonColor, ButtonVariant, ButtonFormat } from '_components/button'
import { FEATURE_FLAGS, MOBILE_BREAKPOINT } from '_utils/constants'
import {
  isListingRegistrationLoading,
  listingRegistrationFormSelector,
  creatingListRegistration,
  creatingListRegistrationError,
  currentPageSelectorSelector,
} from '_modules/listing-registration/selectors'

import { fieldsAreEmpty } from './helpers'
import styles from './styles.css'
import Step1 from './steps/step-one'
import Step2 from './steps/step-two'
import Step3 from './steps/step-three'
import Step4 from './steps/step-four'
import Step5 from './steps/step-five'
import Step6 from './steps/step-six'

const pagesArray = [
  <Step1 key="step1" />,
  <Step2 key="step2" />,
  <Step3 key="step3" />,
  <Step4 key="step4" />,
  <Step5 key="step5" />,
  <Step6 key="step6" />,
]

const hasImplementedFlow = {
  rent: {
    com_building: false,
    com_industrial: false,
    com_loja: false,
    com_office: false,
    com_residential_income: false,
    com_retail: false,
    res_apartment: true,
    res_condo: false,
    res_farm_ranch: false,
    res_flat: false,
    res_home: true,
    res_kitnet: false,
    res_penthouse: false,
    res_sobrado: false,
    res_studio: false,
  },
  sale: {},
}

const ListingRegistrationForm = () => {
  useProtectedRoute('/anunciar-imovel')

  const dispatch = useDispatch()
  const windowWidth = useWindowWidth()
  const listingRegistrationValues = useSelector(listingRegistrationFormSelector)
  const cookiesPages = useSelector(currentPageSelectorSelector)
  const { currentPage, nextPage, prevPage, maxPage } = useWizardForm(cookiesPages, 5)
  const methods = useForm({
    defaultValues: listingRegistrationValues,
  })
  const formFields = methods.watch(undefined, listingRegistrationValues)
  const [isListingCreated, setListingCreated] = useState(false)
  const isLoading = useSelector(isListingRegistrationLoading)
  const isCreatingListingRegistration = useSelector(creatingListRegistration)
  const hasCreatingListingRegistrationError = useSelector(creatingListRegistrationError)

  const hasRegisterError = useSelector(
    state => !!state.error.get(UPDATE_LISTING_REGISTRATION.ACTION)
  )
  const previousLoading = usePrevious(isLoading)

  const onSubmit = useCallback(
    data => {
      if (
        currentPage === 1 &&
        FEATURE_FLAGS.rentalOnlyPropertyRegistrationEnabled &&
        !isListingCreated &&
        !listingRegistrationValues.id
      ) {
        return (
          dispatch(createListingRegistration(data)),
          setListingCreated(true),
          dispatch(updateCurrentPage(currentPage))
        )
      }
      if (currentPage === 0 && !isListingCreated && !listingRegistrationValues.id) {
        return (
          dispatch(createListingRegistration(data)),
          setListingCreated(true),
          dispatch(updateCurrentPage(currentPage))
        )
      }
      if (currentPage === 5 && listingRegistrationValues.id) {
        dispatch(sendEmailListingRegistration())
      }
      return dispatch(updateListingRegistration(data))
    },
    [currentPage, dispatch, isListingCreated, listingRegistrationValues]
  )

  const wasCreatingListingRegistration = usePrevious(isCreatingListingRegistration)

  useEffect(() => {
    if (currentPage === 0 && FEATURE_FLAGS.rentalOnlyPropertyRegistrationEnabled) {
      nextPage()
    }
    if (
      currentPage <= 1 &&
      wasCreatingListingRegistration &&
      !isCreatingListingRegistration &&
      !hasCreatingListingRegistrationError
    ) {
      nextPage()
    }
  })

  const onClickPreviousPage = useCallback(() => {
    if (
      (currentPage === 1 && FEATURE_FLAGS.rentalOnlyPropertyRegistrationEnabled) ||
      currentPage === 0
    ) {
      return navigate(['/anunciar-imovel'])
    }
    return prevPage()
  }, [currentPage, prevPage])

  useEffect(() => {
    if (previousLoading && !isLoading && !hasRegisterError) {
      const { listingType, transactionType } = listingRegistrationValues

      if (currentPage === maxPage) {
        dispatch(removeCurrentPage())
        // This is just while the other views are not ready
        if (transactionType && hasImplementedFlow[transactionType][listingType]) {
          navigate(['/anunciar-imovel/finalizado'])
          return
        }
        if (
          FEATURE_FLAGS.rentalOnlyPropertyRegistrationEnabled &&
          hasImplementedFlow.rent[listingType]
        ) {
          navigate(['/anunciar-imovel/finalizado'])
        } else {
          // Send email to user with information to get back on the future
          dispatch(removeListingRegistration())
          navigate(['/anunciar-imovel/andamento'])
        }
      } else {
        nextPage()
        dispatch(updateCurrentPage(currentPage))
      }
    }
  }, [
    currentPage,
    dispatch,
    hasRegisterError,
    isLoading,
    listingRegistrationValues,
    maxPage,
    nextPage,
    previousLoading,
  ])

  const isButtonDisabled = useMemo(() => {
    const { extraAddressInfo, ...values } = formFields
    return fieldsAreEmpty(values)
  }, [formFields])

  return (
    <FormContext {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} className={styles.container}>
        {pagesArray[currentPage]}
        <div className={styles.footer}>
          <Button
            color={ButtonColor.TEXT_BLACK}
            variant={ButtonVariant.BOLD}
            format={ButtonFormat.SQUARED}
            className={styles.back}
            onClick={onClickPreviousPage}
          >
            VOLTAR
          </Button>
          <Button
            color={
              windowWidth < MOBILE_BREAKPOINT ? ButtonColor.INVERTED_PRIMARY : ButtonColor.PRIMARY
            }
            variant={ButtonVariant.BOLD}
            format={ButtonFormat.SQUARED}
            className={styles.next}
            disabled={isButtonDisabled || isLoading}
            isLoading={isLoading || isCreatingListingRegistration}
            type="submit"
          >
            PRÓXIMO
          </Button>
        </div>
      </form>
    </FormContext>
  )
}

export default React.memo(ListingRegistrationForm)

import React from 'react'
import { Grid, Typography, Button } from '@material-ui/core'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import { moment } from '_bootstrap'

import useStyles from './styles'

const DateButton = ({ isSelected, isDisabled, date, ...otherProps }) => {
  const styles = useStyles()
  return (
    <Button
      className={classnames(styles.buttonContainer, { [styles.defaultButton]: !isSelected })}
      color={isSelected ? 'primary' : 'default'}
      variant="contained"
      disabled={isDisabled}
      {...otherProps}
    >
      <Grid className={styles.day}>
        <Typography className={styles.number} variant="h2">
          {moment(date).format('D')}
        </Typography>
        <Typography className={styles.weekDay} variant="subtitle1">
          {moment(date).format('dddd')}
        </Typography>
      </Grid>
    </Button>
  )
}

DateButton.propTypes = {
  date: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  isDisabled: PropTypes.bool,
}

DateButton.defaultProps = {
  isSelected: false,
  isDisabled: false,
}

export default React.memo(DateButton)

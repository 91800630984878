import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import styles from './styles.css'

const AlertCard = ({ icon, content, children, className, primaryColor }) => (
  <div className={classnames(styles.card, className)}>
    <svg alt="alert icon" className={styles.icon} style={{ fill: primaryColor }}>
      <use xlinkHref={icon} />
    </svg>
    <div className={styles.content}>
      {content && content.map(text => <p className={styles.text}>{text}</p>)}
      {children && children}
    </div>
  </div>
)

AlertCard.propTypes = {
  icon: PropTypes.string.isRequired,
  content: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.node,
  className: PropTypes.string,
  primaryColor: PropTypes.string.isRequired,
}

AlertCard.defaultProps = {
  className: '',
  content: [''],
  children: undefined,
}

export default AlertCard

import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { navigate } from '@reach/router'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Button, { ButtonColor, ButtonVariant, ButtonFormat } from '_components/button'
import Person from '_assets/images/ic-person.svg'
import Edit from '_assets/images/ic-pen.svg'
import { getBooking } from '_modules/booking/actions'
import { SEND_PROPOSAL } from '_modules/proposal/actions'
import { getBookingById } from '_modules/booking/selectors'
import { getPropertyType, getPropertyAddress } from '_utils/property'
import { getLeaseByListingId } from '_modules/leases/selectors'
import { toCurrency, formatNumberPad2, getExternalId } from '_utils/helpers'
import { usePrevious } from '_utils/hooks'
import { primaryColor, primaryTextColor } from '_utils/colors'
import { FEATURE_FLAGS } from '_utils/constants'

import styles from './styles.css'

const ProposalSummary = ({ id, location }) => {
  const dispatch = useDispatch()
  const booking = useSelector(state => getBookingById(state, id))
  const isSendingProposal = useSelector(state => state.loading.get(SEND_PROPOSAL.ACTION))
  const wasSendingProposal = usePrevious(isSendingProposal)
  const [values, setValues] = useState({})
  const [user, setUser] = useState(undefined)
  const lease = useSelector(state =>
    getLeaseByListingId(state, booking && booking.getIn(['listing', 'externalId']))
  )

  const tagColors = useMemo(
    () => ({
      backgroundColor: primaryColor,
      color: primaryTextColor,
    }),
    []
  )

  const data = useMemo(() => {
    const { key, ...proposalData } = location.state
    return proposalData
  }, [location.state])

  const total = useMemo(() => {
    if (!values.listing) return 0
    const { totalPrice, rentPrice } = values.listing
    return toCurrency(totalPrice - rentPrice + Number(data.rentPrice))
  }, [data.rentPrice, values.listing])

  const interestedPeople = useMemo(
    () => lease && formatNumberPad2(lease.getIn(['listing', 'interestedPeople'])),
    [lease]
  )

  useEffect(() => {
    dispatch(getBooking(id))
  }, [dispatch, id])

  useEffect(() => {
    if (wasSendingProposal && !isSendingProposal) {
      navigate('/minhas-negociacoes')
    }
  }, [isSendingProposal, wasSendingProposal])

  useEffect(() => {
    if (!booking) return
    setUser(booking.toJS().user)

    const images = booking.getIn(['listing', 'convertedPictures']).first()
    setValues({
      listing: { ...booking.get('listing').toJS() },
      thumbnail: images.get('thumbnail'),
    })
  }, [booking])

  const onClickChangeProposal = useCallback(() => {
    navigate(`/proposta/fazer-proposta/${id}`, { state: data })
  }, [data, id])

  const onClickSubmit = useCallback(() => {
    // if (!lease) return
    // const {
    // livingWithWho,
    // numberOfPeople,
    // hasPet,
    // petDescription,
    // description,
    // rentPrice,
    // requestDescription,
    // } = data
    // const translatedHasPet = hasPet ? 'sim' : 'não'
    // const livingNotAlone = livingWithWho === liveStatus.FAMILY ? 'familia' : 'amigos'
    // const translatedlivingWithWho = livingWithWho === liveStatus.ALONE ? 'sozinho' : livingNotAlone
    // dispatch(
    //   sendProposal(lease.id, {
    //     booking: id,
    //     livingWithWho,
    //     numberOfPeople,
    //     hasPet,
    //     petDescription,
    //     description,
    //     rentPrice,
    //     requestDescription: removeIndentation(`
    //       Com quem vai morar? ${translatedlivingWithWho}
    //       Quantas pessoas? ${numberOfPeople}
    //       Possui animais? ${translatedHasPet}${hasPet ? `, ${petDescription}` : ''}
    //       Mais informações: ${description}
    //       Modificações no imóvel: ${requestDescription}
    //       Solicitou baixar o valor do aluguel para ${toCurrency(rentPrice)}.
    //     `),
    //   })
    // )
  }, [])

  const onClickQuit = useCallback(() => {
    navigate('/minhas-visitas/')
  }, [])

  if (!values.listing) return null
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h1>Resumo da proposta</h1>
        {interestedPeople > 0 && (
          <div className={classnames(styles.card, styles['card-person'])}>
            <svg aria-label="pessoa" className={styles.icon}>
              <use xlinkHref={Person} />
            </svg>
            <p>
              {interestedPeople} {interestedPeople === 1 ? 'pessoa está' : 'pessoas estão'} de olho
              neste imóvel
            </p>
          </div>
        )}
        <div className={classnames(styles['card-info'], styles.card)}>
          <img className={styles.thumbnail} src={values.thumbnail} alt="apartment" />
          <div className={styles.info}>
            <p className={styles.title}>cód {getExternalId(values.listing.externalId)}</p>
            <p className={styles.title}>{getPropertyType(values.listing.listingType)}</p>
            <p>{getPropertyAddress(values.listing)}</p>
          </div>
        </div>
        <Button
          className={styles['small-button']}
          color={ButtonColor.PRIMARY_TEXT}
          variant={ButtonVariant.BOLD}
          format={ButtonFormat.ROUNDED}
          onClick={onClickChangeProposal}
        >
          <svg aria-label="editar" className={styles['small-icon']}>
            <use xlinkHref={Edit} />
          </svg>
          Alterar proposta
        </Button>
        <div className={styles.card}>
          <div className={styles.row}>
            <p>Modificações no imóvel</p>
            <p className={styles.tag} style={tagColors}>
              PENDENTE
            </p>
          </div>
          <p className={styles.subtitle}>{user.fullName}</p>
          <p>{data.requestDescription}</p>
        </div>

        <div className={styles.card}>
          <div className={styles['table-list']}>
            <p className={styles.title}>Valores</p>
            {data.rentPrice !== values.listing.rentPrice && (
              <div className={classnames(styles.item, styles.disabled)}>
                <span>Aluguel anunciado</span>
                <span>{toCurrency(values.listing.rentPrice)}</span>
              </div>
            )}
            <div className={classnames(styles.item, styles['header-item'])}>
              <span>
                {data.rentPrice !== values.listing.rentPrice ? 'Aluguel sugerido' : 'Aluguel'}
              </span>
              <span>{toCurrency(Number(data.rentPrice))}</span>
            </div>
            <div className={styles.item}>
              <span>Condomínio</span>
              <span>{toCurrency(values.listing.condominiumFee)}</span>
            </div>
            <div className={styles.item}>
              <span>IPTU</span>
              <span>{toCurrency(values.listing.taxes)}</span>
            </div>
            {FEATURE_FLAGS.fireInsuranceEnabled && (
              <div className={styles.item}>
                <span>Seguro incêndio</span>
                <span>{toCurrency(values.fireInsuranceProce)}</span>
              </div>
            )}
            <hr className={styles.line} />
            <div className={styles.item}>
              <span>Total</span>
              <span>{total}</span>
            </div>
          </div>
          <div className={styles['buttons-list']}>
            <Button
              className={styles.button}
              color={ButtonColor.DEFAULT_COLOR}
              variant={ButtonVariant.BOLD}
              format={ButtonFormat.ROUNDED}
              onClick={onClickQuit}
            >
              DESISTIR
            </Button>
            <Button
              color={ButtonColor.PRIMARY}
              variant={ButtonVariant.BOLD}
              format={ButtonFormat.ROUNDED}
              isLoading={isSendingProposal}
              onClick={onClickSubmit}
            >
              ENVIAR PROPOSTA
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

ProposalSummary.propTypes = {
  id: PropTypes.string.isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      key: PropTypes.string,
      livingWithWho: PropTypes.string,
      numberOfPeople: PropTypes.string,
      hasPet: PropTypes.bool,
      petDescription: PropTypes.string,
      description: PropTypes.string,
      requestDescription: PropTypes.string,
      rentPrice: PropTypes.number,
    }),
  }).isRequired,
}

export default ProposalSummary

import { Link } from '@reach/router'
import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'

import FacebookDesktopIcon from '_assets/images/ic-fb-31-px.svg'
import { GENERAL } from '_utils/constants'
import InstagramDesktopIcon from '_assets/images/ic-instagram-31-px.svg'
import LinkedinDesktopIcon from '_assets/images/ic-in-31-px.svg'
import NovaFreitasLogo from '_assets/images/novafreitas_logo.svg'
// import WhatsappDesktopIcon from '_assets/images/ic-whatsapp.svg';
import { sendEvent } from '_utils/mixpanel'

import styles from './styles.css'

const Footer = ({ className }) => {
  const onLinkClick = link => () => {
    sendEvent(`Rodapé: Selecionou para navegar para ${link}`)
  }

  return (
    <footer className={classnames(styles.container, className)}>
      <div className={styles.content}>
        <div className={styles.institutional}>
          <Link
            href="/"
            to="/"
            aria-label="Botão voltar para página inicial"
            onClick={onLinkClick('Página Inicial')}
          >
            <svg aria-hidden="true" className={styles.logo}>
              <use xlinkHref={NovaFreitasLogo} />
            </svg>
          </Link>
          <h2 className={styles.description}>{GENERAL.metatagsHomeDescription}</h2>
          <div className={styles.social}>
            <a
              href={GENERAL.facebookUrl}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Link para facebook"
              onClick={onLinkClick('facebook')}
            >
              <svg alt="facebook">
                <use xlinkHref={FacebookDesktopIcon} />
              </svg>
            </a>
            <a
              href={GENERAL.linkedinUrl}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Link para linkedin"
              onClick={onLinkClick('LinkedIn')}
            >
              <svg alt="linkedin">
                <use xlinkHref={LinkedinDesktopIcon} />
              </svg>
            </a>
            <a
              href={GENERAL.instagramUrl}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Link para instagram"
              onClick={onLinkClick('Instagram')}
            >
              <svg alt="instagram">
                <use xlinkHref={InstagramDesktopIcon} />
              </svg>
            </a>
            {/* <a
              href={GENERAL.whatsappUrl}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Link para Whatsapp"
              onClick={onLinkClick('Whatsapp')}
            >
              <svg alt="whatsapp">
                <use className={styles['whatsapp-icon']} xlinkHref={WhatsappDesktopIcon} />
              </svg>
            </a> */}
          </div>
        </div>
        <div className={styles.contact}>
          <h2>Vamos conversar?</h2>
          <p>{GENERAL.email}</p>
          <p>{GENERAL.address}</p>
        </div>
        <div className={styles.links}>
          <h2>Links úteis</h2>
          <a
            className={styles.link}
            href={GENERAL.privacyPolicyFile || '/politica-de-privacidade'}
            onClick={onLinkClick('Política de Privacidade')}
            rel="noopener noreferrer"
            target="_blank"
          >
            <h3>Política de Privacidade</h3>
          </a>
          <Link
            href="/termos-de-servico"
            onClick={onLinkClick('Termos de Serviço')}
            to="/termos-de-servico"
            className={styles.link}
          >
            <h3>Termos de Serviço</h3>
          </Link>
        </div>
        <div className={styles['know-more']}>
          <h2>Saiba mais</h2>
          <a
            href={GENERAL.websiteUrl}
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Link para site da imobiliária"
            className={styles.link}
          >
            <h3>Sobre a Imobiliária</h3>
          </a>
          {/* <a
            href={GENERAL.blogUrl}
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Link para blog da imobiliária"
            onClick={onLinkClick('Blog')}
            className={styles.link}
          >
            <h3>Blog</h3>
          </a>
          <a
            href={GENERAL.workWithUsUrl}
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Link para página de trabalhe conosco da imobiliária"
            onClick={onLinkClick('Blog')}
            className={styles.link}
          >
            <h3>Trabalhe conosco</h3>
          </a> */}
        </div>
        <div className={styles.copyright}>
          <p>{`© ${GENERAL.realEstateAgency} • Todos os direitos reservados • CRECI ${GENERAL.creci}`}</p>
        </div>
      </div>
    </footer>
  )
}

Footer.propTypes = {
  className: PropTypes.string,
}

Footer.defaultProps = {
  className: '',
}

export default React.memo(Footer)

import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Map } from 'immutable'

import { usePrevious } from '_utils/hooks'

const useOnSuccessCall = (action, onSuccess = () => {}) => {
  const isLoading = useSelector(state => !!state.loading.get(action))
  const wasLoading = usePrevious(isLoading)
  const error = useSelector(state => state.error.get(action, Map()))
  useEffect(() => {
    if (!isLoading && wasLoading && !error.size) {
      onSuccess()
    }
  })

  // TODO: REFACTOR
  // useEffect(() => {
  //   if (!isLoading && wasLoading && error.size && isUsingSnackbar) {
  //     dispatch(
  //       triggerSnackbar({
  //         message: ON_SUCCESS_SNACKBAR_ERROR_MESSAGE,
  //         variant: SNACKBAR_VARIANT.ERROR,
  //       })
  //     )
  //   }
  // }, [dispatch, error.size, isLoading, isUsingSnackbar, wasLoading])
  return [isLoading, error]
}
export default useOnSuccessCall

import { PARTICIPANTS_TYPE, PARTICIPANTS_INFO, EMAIL_REGEX } from '_utils/constants'
import { TEXTFIELD_MASK } from '_components/material/textfield'
import { validationPhoneNumber } from '_utils/helpers'
import { formatPhoneNumber } from '_utils/strings'
// TODO: Integrate options and prop name "TENANT", "GUARANTOR", "INCOME_HELPER"
export const CREDIT_ANALYSIS_NEGOTIATION_INVITE = {
  [PARTICIPANTS_TYPE.TENANT]: {
    title: 'Morador',
    description:
      'Informe o e-mail e telefone do morador para que ele faça o próprio cadastro através de um link que enviaremos no e-mail/celular.',
  },
  [PARTICIPANTS_TYPE.GUARANTOR]: {
    title: 'Fiador',
    description:
      'Informe o e-mail e telefone do fiador para que ele faça o próprio cadastro através de um link que enviaremos no e-mail/celular.',
  },
  [PARTICIPANTS_TYPE.INCOME_HELPER]: {
    title: 'Responsáveis pelo pagamento',
    description:
      'Informe o e-mail e telefone do responsável pelo pagamento para que ele faça o próprio cadastro através de um link que enviaremos no e-mail/celular.',
  },
}

export const INPUTS = [
  {
    label: 'Nome',
    value: PARTICIPANTS_INFO.FULL_NAME,
  },
  {
    label: 'Email',
    value: PARTICIPANTS_INFO.EMAIL,
  },
  {
    label: 'Telefone',
    value: PARTICIPANTS_INFO.PHONE_NUMBER,
    mask: TEXTFIELD_MASK.PHONE_NUMBER,
  },
]

export const INITIAL_STATE = {
  [PARTICIPANTS_INFO.FULL_NAME]: '',
  [PARTICIPANTS_INFO.EMAIL]: '',
  [PARTICIPANTS_INFO.PHONE_NUMBER]: '',
  error: {},
}

const MANDATORY_FIELD = 'Campo obrigatório'
const INVALID_FIELD = 'Campo inválido'

export const parsePayload = value => {
  const payload = { ...value }
  payload[PARTICIPANTS_INFO.PHONE_NUMBER] = formatPhoneNumber(
    payload[PARTICIPANTS_INFO.PHONE_NUMBER]
  )
  return payload
}

export const validation = values => {
  const errors = {}
  Object.entries(values).map(attribute => {
    const [key, value] = attribute
    if (!value.length) {
      errors[key] = MANDATORY_FIELD
    }
    if (key === PARTICIPANTS_INFO.EMAIL && !EMAIL_REGEX.test(value)) {
      errors[key] = INVALID_FIELD
    }
    if (key === PARTICIPANTS_INFO.PHONE_NUMBER && validationPhoneNumber(value)) {
      errors[key] = INVALID_FIELD
    }
    return null
  })
  return errors
}

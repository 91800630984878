import humps from 'humps'

import { createFormData } from '_utils/helpers'

import { get, post, patch } from './requests'

export const createListingRegistration = key => payload =>
  post(['listings-registrations'], { key }, humps.decamelizeKeys(payload))

export const updateListingRegistration = key => (id, payload) => {
  return patch(['listings-registrations', id], { key }, humps.decamelizeKeys(payload))
}

export const uploadListingPictures = key => (id, payload) =>
  post(['listings-registrations', id, 'send-pictures'], { key }, createFormData(payload))

export const getPricePrediction = key => id =>
  get(['listings-registrations', id, 'price-prediction'], { key })

export const getPricePredictionEmail = key => id =>
  get(['listings-registrations', id, 'price-prediction'], { params: { token: key } })

export const getListingPictures = key => id =>
  get(['listings-registrations', id, 'converted-pictures'], { key })

export const getListingRegistration = key => id =>
  get(['listings-registrations', id], { params: { token: key } })

export const updateListingRegistrationEmail = key => (id, payload) =>
  patch(['listings-registrations', id], { params: { token: key } }, humps.decamelizeKeys(payload))

export const sendEmailListingRegistration = key => id =>
  post(['listings-registrations', id, 'postpone'], { key })

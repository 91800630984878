import {
  FURNITURE_VALUES,
  PET_VALUES,
  PROPERTY_TYPES,
  RESIDENTIAL_PROPERTY_TYPES,
  COMMERCIAL_PROPERTY_TYPES,
} from '_utils/constants'
import { NUMBER_VALUES } from '_utils/url-friendly'

export const LISTING_VALUES = {
  EXTERNAL_ID: 'externalId',
  PETS: 'pets',
  CITY: 'city',
  BEDROOMS: 'bedrooms',
  FURNISHED: 'furnished',
  NEIGHBORHOODS: 'neighborhoods',
  PARKING_SPOTS: 'parkingSpots',
  LISTING_DESCRIPTION: 'listingDescription',
  PRICE: 'price',
  PROPERTY_FEATURES: 'listingAmenities',
  CONDOMINIUM_FEATURES: 'condominiumAmenities',
  PROPERTY_MIN_AREA: 'minArea',
  PROPERTY_MAX_AREA: 'maxArea',
  SUITE: 'numberOfEnsuites',
  BATHROOMS: 'bathrooms',
  TAX_INCLUDED: 'taxesIncluded',
  RESIDENTIAL: 'residential',
  COMMERCIAL: 'commercial',
  PURPOSE_TYPE: 'purposeType',
  LISTING_TYPE: 'listingType',
}

export const BUTTON_NUMBERS = ['1+', '2+', '3+', '4+', '5+']
export const BUTTON_PETS = Object.values(PET_VALUES)
export const BUTTON_FURNISHED = [
  FURNITURE_VALUES.MAYBE,
  FURNITURE_VALUES.YES,
  FURNITURE_VALUES.NO,
  FURNITURE_VALUES.SEMI_FURNISHED,
]

export const BUTTON_PURPOSE_TYPES = [
  {
    value: LISTING_VALUES.RESIDENTIAL,
    label: 'Residencial',
    name: LISTING_VALUES.PURPOSE_TYPE,
  },
  {
    value: LISTING_VALUES.COMMERCIAL,
    label: 'Comercial',
    name: LISTING_VALUES.PURPOSE_TYPE,
  },
]

export const ALL_ITENS = 'todos'

export const CHECKBOX_PROPERTY_TYPE = [
  {
    value: 'both',
    label: 'Tanto faz',
  },
  {
    value: 'res_apartment',
    label: 'Apartamento',
  },
  {
    value: 'res_home',
    label: 'Casa',
  },
]

export const CHECKBOX_PROPERTY_FEATURES = [
  {
    value: 'balcony',
    label: 'Sacada',
  },
  {
    value: 'large-windows',
    label: 'Janelas grandes',
  },
  {
    value: 'new-or-remodeled',
    label: 'Novos ou reformados',
  },
  {
    value: 'moning-sun',
    label: 'Sol da manhã',
  },
  {
    value: 'afternoon-sun',
    label: 'Sol da tarde',
  },
  {
    value: 'sight-free',
    label: 'Vista livre',
  },
]

export const CHECKBOX_CONDOMINIUN_FEATURES = [
  {
    value: '24h-doorman',
    label: 'Portaria 24h',
  },
  {
    value: 'lift',
    label: 'Elevador',
  },
  {
    value: 'silent-street',
    label: 'Rua silenciosa',
  },
  {
    value: 'party-room',
    label: 'Salão de festas',
  },
  {
    value: 'gym',
    label: 'Academia',
  },
  {
    value: 'pool',
    label: 'Piscina',
  },
  {
    value: 'barbecue-grill',
    label: 'Churrasqueira',
  },
]

export const CHECKBOX_TAX_TYPE = {
  value: true,
  label: 'Todas as contas inclusas',
}

export const INITIAL_STATE = {
  externalId: '',
  city: '',
  neighborhoods: [],
  price: '',
  minArea: null,
  maxArea: null,
  bedrooms: '',
  numberOfEnsuites: '',
  bathrooms: '',
  listingAmenities: [],
  condominiumAmenities: [],
  taxesIncluded: false,
  purposeType: '',
  listingType: [],
  parkingSpots: '',
  pets: 'TANTO FAZ',
  furnished: 'TANTO FAZ',
}

export const HANDLE_SELECTED_OPTION = 'HANDLE_SELECTED_OPTION'
export const HANDLE_MULTIPLE_CHECKBOX = 'HANDLE_MULTIPLE_CHECKBOX'
export const HANDLE_INPUT_CHANGE = 'HANDLE_INPUT_CHANGE'
export const SET_INITIAL_STATE = 'SET_INITIAL_STATE'
export const RESET_STATE = 'RESET_STATE'
export const HANDLE_MULTIPLE_SELECTED = 'HANDLE_MULTIPLE_SELECTED'
export const CLEAR_NEIGHBORHOODS_SELECTED = 'CLEAR_NEIGHBORHOODS_SELECTED'
export const HANDLE_LISTING_TYPE = 'HANDLE_LISTING_TYPE'
export const HANDLE_COMERTIAL_ENABLED = 'HANDLE_COMERTIAL_ENABLED'

export const reducer = (state, action) => {
  switch (action.type) {
    case HANDLE_SELECTED_OPTION: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case HANDLE_LISTING_TYPE: {
      const { name, value } = action.payload
      if (value.includes('all')) {
        if (state.listingType.length === 0) {
          return {
            ...state,
            [name]:
              value === PROPERTY_TYPES.RES_ALL
                ? RESIDENTIAL_PROPERTY_TYPES
                : COMMERCIAL_PROPERTY_TYPES,
          }
        }
        return {
          ...state,
          [name]: [],
        }
      }
      if (state.listingType.includes(value)) {
        return {
          ...state,
          [name]: [...state.listingType.filter(listing => listing !== value)],
        }
      }
      return {
        ...state,
        [name]: [...state[name], value],
      }
    }

    case HANDLE_INPUT_CHANGE:
      return { ...state, [action.payload.name]: action.payload.value }

    case HANDLE_MULTIPLE_CHECKBOX: {
      const { name, value } = action.payload
      if (state[name].includes(value)) {
        return {
          ...state,
          [name]: state[name].filter(item => item !== value),
        }
      }

      return { ...state, [name]: [...state[name], value] }
    }

    case HANDLE_MULTIPLE_SELECTED: {
      const { name, value } = action.payload
      if (value.includes(ALL_ITENS)) {
        if (value[0] === ALL_ITENS && value.length > 1) {
          return { ...state, [name]: [value[1]] }
        }

        return {
          ...state,
          [name]: [ALL_ITENS],
        }
      }
      return { ...state, [name]: [...value] }
    }

    case RESET_STATE: {
      const { city } = state
      return { ...INITIAL_STATE, city }
    }
    case SET_INITIAL_STATE: {
      const filter = action.payload
      const payload = { ...filter }
      if (action.commercialListingsEnabled) {
        payload.listingType = []
        payload[LISTING_VALUES.PURPOSE_TYPE] = payload[LISTING_VALUES.PURPOSE_TYPE] || ''
        payload[LISTING_VALUES.LISTING_TYPE] =
          payload[payload[LISTING_VALUES.PURPOSE_TYPE]] &&
          payload[payload[LISTING_VALUES.PURPOSE_TYPE]].length
            ? payload[payload[LISTING_VALUES.PURPOSE_TYPE]]
            : []
      }
      NUMBER_VALUES.map(value => {
        if (payload[value]) {
          payload[value] = `${payload[value]}`
        }
        return null
      })
      return { ...INITIAL_STATE, ...payload }
    }

    case CLEAR_NEIGHBORHOODS_SELECTED: {
      return { ...state, neighborhoods: [] }
    }
    case HANDLE_COMERTIAL_ENABLED: {
      if (!state.purposeType) {
        return {
          ...state,
          [LISTING_VALUES.PURPOSE_TYPE]: LISTING_VALUES.RESIDENTIAL,
          [LISTING_VALUES.LISTING_TYPE]: ['res_all'],
        }
      }
      return {
        ...state,
        [LISTING_VALUES.PURPOSE_TYPE]: state.purposeType,
        [LISTING_VALUES.LISTING_TYPE]:
          state.purposeType === LISTING_VALUES.RESIDENTIAL ? ['res_all'] : ['com_all'],
      }
    }

    default:
      return state
  }
}

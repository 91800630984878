import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography } from '@material-ui/core'

import useStyles from './styles'

const ListItem = ({ icon: Icon, title, content }) => {
  const styles = useStyles()

  return (
    <Grid className={styles.grid}>
      <Icon className={styles.icon} />
      <Typography variant="h4">{title}</Typography>
      <Grid className={styles.content}>
        <Typography variant="subtitle1">{content}</Typography>
      </Grid>
    </Grid>
  )
}

ListItem.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
}

ListItem.defaultProps = {
  icon: null,
  content: '',
}

export default React.memo(ListItem)

import React, { Fragment, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'

import { listingRegistrationFormSelector } from '_modules/listing-registration/selectors'
import InputButton from '_components/button/input-button'

import styles from '../styles.css'

const Step4 = () => {
  const { available } = useSelector(listingRegistrationFormSelector)
  const { setValue } = useFormContext()

  useEffect(() => {
    setValue('available', `${available}`)
  }, [available, setValue])

  return (
    <Fragment>
      <header className={styles.header}>
        <h1 className={styles.title}>O imóvel está ocupado?</h1>
      </header>
      <div className={styles.group}>
        <InputButton
          className={styles.radio}
          name="available"
          id="not_available"
          value="false"
          label="Sim"
        />
        <InputButton
          className={styles.radio}
          name="available"
          id="available"
          value="true"
          label="Não"
        />
      </div>
    </Fragment>
  )
}

export default React.memo(Step4)

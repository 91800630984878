import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import IconCheck from '_assets/images/ic-vector.svg'
import { primaryColor } from '_utils/colors'

import styles from './styles.css'

const MissingInfo = ({ title }) => {
  const createSvgStyle = useMemo(() => ({ fill: primaryColor }), [])
  return (
    <div className={styles['item-requested']}>
      <svg
        style={createSvgStyle}
        aria-label="Ícone de uma pasta de arquivo"
        className={styles['icon-check']}
      >
        <use xlinkHref={IconCheck} />
      </svg>
      <p className={styles['requested-title']}>{title}</p>
    </div>
  )
}

MissingInfo.propTypes = {
  title: PropTypes.string.isRequired,
}

export default React.memo(MissingInfo)

import * as agencyService from '_services/agency'
import { defineAction } from '_utils/redux'

export const GET_AGENCY = defineAction('GET_AGENCY')
export const SEND_PIPEDRIVE_AGENCY_LEAD_DATA = defineAction('SEND_PIPEDRIVE_AGENCY_LEAD_DATA')

export const getAgency = listing => (dispatch, getState) => {
  const token = getState().user.authToken
  return dispatch({
    type: GET_AGENCY.ACTION,
    payload: agencyService.getAgency(token)(listing),
  })
}
export const sendPipedriveAgencyLeadData = payload => dispatch =>
  dispatch({
    type: SEND_PIPEDRIVE_AGENCY_LEAD_DATA.ACTION,
    payload: agencyService.sendPipedriveAgencyLeadData(payload),
  })

import { createSelector } from 'reselect'
import { Map } from 'immutable'

import { REMOVE_PROPOSAL } from '_modules/proposal/actions'
import {
  CREATE_LEAD,
  GET_BOOKING,
  SEND_BOOKING_FEEDBACK,
  GIVE_UP_LISTING,
  UPDATE_BOOKING,
  UPDATE_OCCURRENCE,
  CANCEL_BOOKING,
} from '_modules/booking/actions'
import { LEASE_STATUS } from '_utils/constants'
import { Booking } from '_models/'

export const getBookingsSelector = state => state.bookings.bookings
export const getNewBookingsSelector = state => state.bookings.get('newBooking')
const getParam = (state, param) => param

export const getBookingById = createSelector(
  [getBookingsSelector, getParam],
  (bookings, bookingId) => {
    const bookingIndex = bookings.findIndex(booking => booking.get('id') === Number(bookingId))
    return bookings.get(bookingIndex) || new Booking()
  }
)

export const getActiveBookings = createSelector([getBookingsSelector], bookings => {
  return bookings
    .toJS()
    .filter(({ active, lease }) => active && lease.status !== LEASE_STATUS.DEACTIVATED)
})

export const loadingRemoveProposal = state => !!state.loading.get(REMOVE_PROPOSAL.ACTION)

export const createLeadLoadingSelector = state => !!state.loading.get(CREATE_LEAD.ACTION)

export const createLeadErrorSelector = state => state.error.get(CREATE_LEAD.ACTION, Map())

export const bookingErrorSelector = state => state.error.get(GET_BOOKING.ACTION, Map())

export const sendBookingFeedbackLoadingSelector = state =>
  !!state.loading.get(SEND_BOOKING_FEEDBACK.ACTION)
export const bookingFeedbackErrorSelector = state =>
  state.error.get(SEND_BOOKING_FEEDBACK.ACTION, Map())

export const giveUpListingLoadingSelector = state => !!state.loading.get(GIVE_UP_LISTING.ACTION)

export const updateBookingLoadingSelector = state => !!state.loading.get(UPDATE_BOOKING.ACTION)

export const updateOccurrenceLoadingSelector = state =>
  !!state.loading.get(UPDATE_OCCURRENCE.ACTION)

export const isLoadingBookingSelector = state => !!state.loading.get(GET_BOOKING.ACTION)
export const isCancellingBookingSelector = state => !!state.loading.get(CANCEL_BOOKING.ACTION)

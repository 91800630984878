import React, { Fragment, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useFormContext } from 'react-hook-form'

import InputButton from '_components/button/input-button'
import { listingRegistrationFormSelector } from '_modules/listing-registration/selectors'

import styles from '../styles.css'

const SALE = 'sale'

const Step2 = () => {
  const { purpose, transactionType } = useSelector(listingRegistrationFormSelector)
  const { setValue } = useFormContext()

  useEffect(() => {
    setValue('purpose', purpose)
  }, [purpose, setValue])

  return (
    <Fragment>
      <header className={styles.header}>
        <h1 className={styles.title}>
          Qual imóvel você deseja {transactionType === SALE ? 'vender' : 'alugar'}?
        </h1>
      </header>
      <div className={styles.group}>
        <InputButton
          className={styles.radio}
          name="purpose"
          id="residential"
          type="radio"
          value="residential"
          label="Residencial"
        />
        <InputButton
          className={styles.radio}
          name="purpose"
          id="commercial"
          type="radio"
          value="commercial"
          label="Comercial"
        />
      </div>
    </Fragment>
  )
}

export default React.memo(Step2)

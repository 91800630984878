import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'

import styles from './styles.css'

const PrevArrow = ({ onClick, smallSize }) => (
  <div className={classnames(styles['arrow-container'], { [styles.small]: smallSize })}>
    <button type="button" onClick={onClick} className={styles.arrow}>
      <ArrowForwardIosIcon />
      Anterior
    </button>
  </div>
)

PrevArrow.propTypes = {
  onClick: PropTypes.func,
  smallSize: PropTypes.bool,
}

PrevArrow.defaultProps = {
  onClick: () => {},
  smallSize: false,
}

export default PrevArrow

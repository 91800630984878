import queryString from 'query-string'

export const buildQuerystring = (query, commercialListingsEnabled = false) => {
  const initialQuery = query.toJS()
  const objQuery = {}
  Object.keys(initialQuery).forEach(key => {
    if (typeof initialQuery[key] === 'undefined') return
    if (key === 'locations') {
      objQuery[key] = initialQuery[key].map(loc => encodeURIComponent(JSON.stringify(loc)))
    } else {
      objQuery[key] = initialQuery[key]
    }
    if (key === 'neighborhoods') {
      if (objQuery[key] === '') {
        objQuery[key] = []
      } else {
        objQuery[key] = objQuery[key].join(',')
      }
    }
    if (commercialListingsEnabled && key === 'listingType') {
      if (Array.isArray(objQuery[key])) {
        objQuery[key] = objQuery[key].join(',')
      }
    }
  })
  return `?${queryString.stringify(objQuery, { arrayFormat: 'bracket' })}`
}

export const parseQuerystring = (querystring, commercialListingsEnabled = false) => {
  const query = queryString.parse(querystring.slice(1), { arrayFormat: 'bracket' })
  // eslint-disable-next-line
  Object.keys(query).forEach(key => {
    if (key === 'locations') {
      query[key] = query[key].map(val => JSON.parse(decodeURIComponent(val)))
    }
    if (key === 'neighborhoods') {
      if (query[key] === '') {
        query[key] = []
      } else {
        query[key] = query[key].split(',')
      }
    }
    if (commercialListingsEnabled && key === 'listingType') {
      if (query[key].length) {
        query[key] = query[key].split(',')
      }
    }
  })
  return query
}

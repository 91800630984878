import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import ImgPhoneClick from '_assets/images/mobile-click-arm.svg'

import styles from './styles.css'

const WelcomeSection = ({ name, className }) => (
  <section className={classnames(styles.container, className)}>
    <div className={styles.text}>
      <h1 className={styles.title}>Olá{name ? `, ${name}.` : '!'}</h1>
      <h2 className={styles.subtitle}>Veja os imóveis que encontramos para você:</h2>
    </div>
    <svg aria-hidden="true" className={styles['img-phone-click']}>
      <use xlinkHref={ImgPhoneClick} />
    </svg>
  </section>
)

WelcomeSection.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
}

WelcomeSection.defaultProps = {
  name: () => {},
  className: '',
}

export default React.memo(WelcomeSection)

import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import ImmutablePropsTypes from 'react-immutable-proptypes'

import MapRouteStartMarker from '_assets/images/map-start-marker.png'
import MapRouteFinishMarker from '_assets/images/map-finish-marker.png'
import { sendEvent } from '_utils/mixpanel'
import { usePrevious, useHereMap } from '_utils/hooks'

import styles from './styles.css'
import { PROPERTY_MARKER } from './constants'
import DirectionsInput from './directions-input'

const HereMap = ({
  coordinates,
  id,
  primaryColor,
  featureActive,
  isStatic,
  propertyAddress,
  propertyId,
  citiesArray,
}) => {
  // Previous property ID
  const previousPropertyId = usePrevious(propertyId)

  // Get property coordinates
  const propertyCoordinates = useMemo(() => {
    if (coordinates) {
      const coord = {
        lat: coordinates.get('coordinates').get(1),
        lng: coordinates.get('coordinates').get(0),
      }
      return coord
    }
    return { lat: -27.59034, lng: -48.51946 } // Default to Florianópolis location
  }, [coordinates])

  // HERE objects
  const [platform, map] = useHereMap(id, propertyCoordinates)
  const [propertyMarker, setPropertyMarker] = useState()

  // Map references
  const [havePropertyMarker, setHavePropertyMarker] = useState(false)
  const [inputCoordinates, setInputCoordinates] = useState({})
  const [isPropertyToLocation, setIsPropertyToLocation] = useState(true)
  const [haveRoute, setHaveRoute] = useState(false)
  const [distance, setDistance] = useState('')
  const [time, setTime] = useState('')

  // Input reference
  const inputRef = useRef()

  // Remove current route
  const removeRoute = useCallback(() => {
    if (!map) return

    map.getObjects().forEach(object => {
      if (object.id === 'route') {
        map.removeObject(object)
      }
    })
  }, [map])

  // Get directions
  const routingParams = useMemo(() => {
    return {
      routingMode: 'fast',
      transportMode: featureActive,
      origin: isPropertyToLocation
        ? `${propertyCoordinates.lat},${propertyCoordinates.lng}`
        : `${inputCoordinates.lat},${inputCoordinates.lng}`,
      destination: isPropertyToLocation
        ? `${inputCoordinates.lat},${inputCoordinates.lng}`
        : `${propertyCoordinates.lat},${propertyCoordinates.lng}`,
      return: 'polyline,summary',
    }
  }, [
    featureActive,
    inputCoordinates.lat,
    inputCoordinates.lng,
    isPropertyToLocation,
    propertyCoordinates.lat,
    propertyCoordinates.lng,
  ])

  const getDirections = useCallback(() => {
    const onResult = result => {
      if (haveRoute) {
        removeRoute()
      }
      if (result.routes.length) {
        const route = result.routes[0]
        const section = route.sections[0]

        // Get section polyline
        const linestring = window.H.geo.LineString.fromFlexiblePolyline(section.polyline)
        const polyline = new window.H.map.Polyline(linestring, {
          style: { strokeColor: 'blue', lineWidth: 3 },
        })
        polyline.id = 'route'
        polyline.setZIndex(999)

        // Create start marker
        const startMarkerIcon = new window.H.map.Icon(MapRouteStartMarker, {
          anchor: {
            x: 21,
            y: 20,
          },
          size: { w: 40, h: 40 },
        })

        const startMarker = new window.H.map.Marker(
          {
            lat: section.departure.place.location.lat,
            lng: section.departure.place.location.lng,
          },
          {
            icon: startMarkerIcon,
            zIndex: 2,
          }
        )
        startMarker.id = 'route'

        // Create end marker
        const finishMarkerIcon = new window.H.map.Icon(MapRouteFinishMarker, {
          anchor: {
            x: 26,
            y: 40,
          },
          size: { w: 50, h: 56 },
        })

        const endMarker = new window.H.map.Marker(
          {
            lat: section.arrival.place.location.lat,
            lng: section.arrival.place.location.lng,
          },
          {
            icon: finishMarkerIcon,
            zIndex: 2,
          }
        )
        endMarker.id = 'route'

        // Clean property marker if exists
        if (havePropertyMarker) {
          setHavePropertyMarker(false)
          map.removeObject(propertyMarker)
        }

        // Calculate time and distance
        setDistance(`${Math.round(section.summary.length / 100) / 10} km`)
        setTime(moment.duration(section.summary.duration, 'seconds').humanize())

        // Update map
        map.getViewModel().setLookAtData({ bounds: polyline.getBoundingBox() })
        map.addObjects([polyline, startMarker, endMarker])
        setHaveRoute(true)
      }
    }

    const onError = () => null

    const router = platform.getRoutingService(null, 8)
    router.calculateRoute(routingParams, onResult, onError)
  }, [havePropertyMarker, haveRoute, map, platform, propertyMarker, removeRoute, routingParams])

  // Set property Marker
  const onUpdatePropertyMarker = useCallback(() => {
    if (!map) return

    if (!haveRoute && propertyMarker) {
      map.removeObject(propertyMarker)
    }

    if (!coordinates) return

    const markerIcon = new window.H.map.Icon(PROPERTY_MARKER(primaryColor), {
      anchor: {
        x: 26,
        y: 40,
      },
      size: { w: 50, h: 56 },
    })
    const herePropertyMarker = new window.H.map.Marker(propertyCoordinates, {
      icon: markerIcon,
      zIndex: 2,
    })
    setPropertyMarker(herePropertyMarker)
    setHavePropertyMarker(true)
    map.addObject(herePropertyMarker)
    map.setCenter(propertyCoordinates)
    map.setZoom(14)
  }, [coordinates, haveRoute, map, primaryColor, propertyCoordinates, propertyMarker])

  // Input interactions
  const onLocationClick = useCallback(position => {
    setInputCoordinates(position)
  }, [])

  const onFlipClick = useCallback(() => {
    setIsPropertyToLocation(state => !state)
    sendEvent('Imóvel: Selecionou botão inverter rota no mapa')
  }, [])

  const onCancelClick = useCallback(() => {
    removeRoute()
    setTime('')
    setDistance('')
    setInputCoordinates({})
    if (!havePropertyMarker) {
      onUpdatePropertyMarker()
    }
  }, [onUpdatePropertyMarker, havePropertyMarker, removeRoute])

  // Get new directions when input cooredinates or route direction is changed
  useEffect(
    () => {
      if (inputCoordinates.lat || inputCoordinates.lng) {
        getDirections()
      }
    },
    [isPropertyToLocation, inputCoordinates, featureActive] // eslint-disable-line react-hooks/exhaustive-deps
  )

  // Handle propertyId update
  useEffect(() => {
    if (previousPropertyId && propertyId !== previousPropertyId) {
      if (haveRoute) {
        inputRef.current.clearInput()
        removeRoute()
        setTime('')
        setDistance('')
        setInputCoordinates({})
      }

      onUpdatePropertyMarker()
    }
  }, [haveRoute, onUpdatePropertyMarker, previousPropertyId, propertyId, removeRoute])

  // Once map is loaded, update property marker
  useEffect(
    () => {
      if (map) {
        onUpdatePropertyMarker()
      }
    },
    [map] // eslint-disable-line react-hooks/exhaustive-deps
  )

  return (
    <div className={styles.main}>
      <div className={styles.map} id={id} />
      {!isStatic && (
        <DirectionsInput
          propertyAddress={propertyAddress}
          id={id}
          propertyId={propertyId}
          onLocationClick={onLocationClick}
          onFlipClick={onFlipClick}
          propertyToLocation={isPropertyToLocation}
          onCancelClick={onCancelClick}
          onEmptyInput={onCancelClick}
          distance={distance}
          duration={time}
          setInputRef={inputRef}
          citiesArray={citiesArray}
          searchCenterLat={propertyCoordinates.lat}
          searchCenterLng={propertyCoordinates.lng}
        />
      )}
    </div>
  )
}

HereMap.propTypes = {
  id: PropTypes.string.isRequired,
  propertyAddress: PropTypes.string.isRequired,
  propertyId: PropTypes.string.isRequired,
  featureActive: PropTypes.string,
  isStatic: PropTypes.bool,
  coordinates: ImmutablePropsTypes.map.isRequired,
  primaryColor: PropTypes.string.isRequired,
  citiesArray: PropTypes.arrayOf(PropTypes.string),
}

HereMap.defaultProps = {
  featureActive: 'car',
  isStatic: false,
  citiesArray: [],
}

export default React.memo(HereMap)

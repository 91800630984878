/* eslint-disable camelcase */
import React, { useCallback, useEffect, useState, useMemo, Fragment } from 'react'
import { Grid } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useLocation, navigate } from '@reach/router'
import LazyLoad from 'react-lazyload'
import URI from 'urijs'
import { Cookies } from 'react-cookie'

import { useWindowSize, usePrevious } from '_utils/hooks'
import useModal from '_hooks/use-modal'
import Button, { ButtonFormat } from '_components/material/button'
import Building from '_components/building-animated'
import { getNextPage, searchPropertiesFromRecommendations } from '_modules/search/actions'
import { getExactMatches, nextPageSelector, getPartialMatches } from '_modules/search/selectors'
import {
  getRecommendationPropertiesFromHash,
  loadMoreProperties,
  setFilterRecommendations,
} from '_modules/recommendations/actions'
import {
  getRecommendationsPropertiesSelector,
  getRecommendations,
  getDetailedResults,
} from '_modules/recommendations/selectors'
import MoreFiltersSection from '_components/more-filters-section'
import { getUserLikedProperties } from '_modules/user/selectors'
import { checkIsLoadingProperties, getLikingProperty } from '_modules/property/selectors'
import Snackbar from '_components/snackbar'
import { LIKE_PROPERTY } from '_modules/property/actions'
import { SORT_OPTIONS, SNACKBAR_INTERVAL } from '_utils/constants'
import { sortingProperty } from '_utils/property'
import FiltersSection from '_components/filters-section'
import { parseFilterToUrl } from '_utils/url-friendly'
import { primaryColor } from '_utils/colors'

import WelcomeSection from './welcome-section'
import ListingCard from './listing-card'
import useStyles from './styles'

const cookies = new Cookies()

const Recommendations = () => {
  const { hash } = useParams()
  const location = useLocation()
  const dispatch = useDispatch()
  const { isMobile } = useWindowSize()
  const styles = useStyles()

  const isLoadingRecommendations = useSelector(getRecommendationsPropertiesSelector)
  const isLoadingProperties = useSelector(checkIsLoadingProperties)
  const listLikedProperties = useSelector(getUserLikedProperties)
  const nextPageSearch = useSelector(nextPageSelector)
  const exactMatches = useSelector(state => getExactMatches(state))
  const detailedMatches = useSelector(state => getDetailedResults(state))
  const partialMatches = useSelector(state => getPartialMatches(state))
  const isLikePropertyLoading = useSelector(getLikingProperty)
  const wasLikePropertyLoading = usePrevious(isLikePropertyLoading)
  const {
    nextPage: nextPageRecommendations,
    name,
    filters: initialFilters,
    results: resultsRecommendations,
  } = useSelector(getRecommendations)

  const [didChangeFilter, setDidChangeFilter] = useState(false)
  const [filterOrder, setFilterOrder] = useState('')
  const [showSnackBar, setShowSnackBar] = useState(true)
  const {
    modalOpen: isMoreFiltersSectionOpen,
    setModalOpen: setMoreFiltersSection,
    handleModal: toggleMoreFiltersSection,
  } = useModal()

  // Get uri info
  const hashInfo = useMemo(() => {
    const { staff_hash } = URI(location.search).search(true)
    const recommendation_hash = hash

    return {
      staffHash: staff_hash || '',
      recommendationHash: recommendation_hash || '',
    }
  }, [hash, location.search])

  useEffect(() => {
    const recommendationHashParam = location.search ? `${hash}/${location.search}` : hash
    dispatch(getRecommendationPropertiesFromHash(recommendationHashParam))
  }, [dispatch, hash, location.search])

  const onFilterChange = useCallback(
    filters => {
      dispatch(setFilterRecommendations(filters))
      setDidChangeFilter(true)
      dispatch(searchPropertiesFromRecommendations())
      setMoreFiltersSection(false)
      navigate(`../${parseFilterToUrl('buscar', filters)}`)
    },
    [dispatch, setMoreFiltersSection]
  )

  const onShowMoreClicked = useCallback(
    event => {
      event.preventDefault()

      if (detailedMatches.length < 30 || didChangeFilter) {
        dispatch(getNextPage())
        return
      }

      dispatch(loadMoreProperties(hash))
    },
    [detailedMatches, didChangeFilter, dispatch, hash]
  )

  const setSnackBar = useCallback(() => {
    setShowSnackBar(false)
  }, [])

  const handleReorderFilter = useCallback(value => {
    setFilterOrder(value)
  }, [])

  const propertyOrder = useMemo(() => {
    // On initial load render recommendations from hash, afterwards render from filter search

    const exactMatchesFiltered =
      exactMatches && exactMatches.length
        ? exactMatches.filter(
            match => !resultsRecommendations.find(detail => detail === match.externalId)
          )
        : []
    const results =
      exactMatches.length === 0
        ? detailedMatches
        : detailedMatches.concat(exactMatchesFiltered).concat(partialMatches)

    switch (filterOrder) {
      case SORT_OPTIONS.LOWEST_PRICE: {
        return sortingProperty(results, true)
      }
      case SORT_OPTIONS.HIGHER_PRICE: {
        return sortingProperty(results)
      }
      case SORT_OPTIONS.LATEST: {
        return results.sort((propertyA, propertyB) => propertyA.id - propertyB.id)
      }
      default: {
        return results
      }
    }
  }, [detailedMatches, exactMatches, filterOrder, partialMatches, resultsRecommendations])

  useEffect(() => {
    if (!isLikePropertyLoading && wasLikePropertyLoading && showSnackBar) {
      setInterval(setSnackBar, SNACKBAR_INTERVAL)
    }
  }, [isLikePropertyLoading, setSnackBar, showSnackBar, wasLikePropertyLoading])

  useEffect(() => {
    cookies.set('recommendationsHash', hash, { path: '/' })
  }, [hash])

  const nextPage = nextPageRecommendations || nextPageSearch
  const isLoading = isLoadingRecommendations || isLoadingProperties
  const initialState = initialFilters && initialFilters.toJS()
  return (
    <Grid className={styles.container}>
      <FiltersSection
        clientName={name}
        initialState={initialState}
        onChange={onFilterChange}
        onOpenMoreFilters={toggleMoreFiltersSection}
        className={styles.filters}
        reorderProperties={handleReorderFilter}
        isRecommendation
      />
      {(propertyOrder.length || !isLoadingRecommendations) && (
        <Fragment>
          <WelcomeSection name={name} className={styles.welcome} />
          <Grid component="section" className={styles.properties}>
            {propertyOrder.map(property => (
              <LazyLoad height={isMobile ? 286 : 214} key={`property-${property.externalId}`}>
                <ListingCard
                  info={property}
                  location={location}
                  primaryColor={primaryColor}
                  listLikedProperties={listLikedProperties}
                  staffHash={hashInfo.staffHash}
                  recommendationHash={hashInfo.recommendationHash}
                  search={location.search}
                  hideScheduleButton
                />
              </LazyLoad>
            ))}
          </Grid>
        </Fragment>
      )}
      {nextPage && !isLoading && (
        <Grid component="section" className={styles.buttonSection}>
          <Button variant="contained" format={ButtonFormat.ROUNDED} onClick={onShowMoreClicked}>
            MOSTRAR MAIS
          </Button>
        </Grid>
      )}
      {isLoading && <Building isSmall className={styles.loading} />}
      {showSnackBar && (
        <Snackbar
          variant="default"
          message="Você favoritou um imóvel. Confira no menu favoritos."
          action={LIKE_PROPERTY.ACTION}
          className={styles.snackBar}
        />
      )}
      {isMoreFiltersSectionOpen && (
        <MoreFiltersSection
          isOpen={isMoreFiltersSectionOpen}
          initialState={initialState}
          onCloseMoreFiltersSection={toggleMoreFiltersSection}
          onApplyClick={onFilterChange}
        />
      )}
    </Grid>
  )
}

export default React.memo(Recommendations)

import React, { useState, useCallback, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Typography, Grid } from '@material-ui/core'

import MultilineTextField from '_components/modals/multiline-text-field'
import Button from '_components/material/button'
import { INPUT_MAX_CHARACTERS } from '_utils/constants'

import useStyles from './styles'

const VisitFeedbackStepThree = ({ onNextClick, isLoading, hasProblem }) => {
  const styles = useStyles()
  const [comment, setFeedback] = useState('')
  const [error, setError] = useState(false)

  const onTypeFeedback = useCallback(event => {
    const { value } = event.currentTarget
    if (value.length <= INPUT_MAX_CHARACTERS.SMALL) {
      setFeedback(value)
      setError(false)
      return
    }
    setError(true)
  }, [])

  const sendFeedback = useCallback(() => {
    onNextClick({ comment })
  }, [comment, onNextClick])

  const helperText = `${comment.length} / ${INPUT_MAX_CHARACTERS.SMALL}`

  return (
    <Fragment>
      <Grid component="header" className={styles.text}>
        <Typography component="h1" variant="h4" className={styles.title}>
          {hasProblem ? 'Reporte seu problema' : 'Como podemos melhorar?'}
        </Typography>
        <Typography component="p" variant="subtitle1" className={styles.description}>
          {hasProblem ? 'Nossa equipe entrará em contato' : ' O seu feedback nos ajuda a melhorar'}
        </Typography>
      </Grid>
      <MultilineTextField
        value={comment}
        onChange={onTypeFeedback}
        placeholder="Escreva algo..."
        helperText={helperText}
        error={error}
      />
      <Button
        variant="contained"
        fullWidth
        disableElevation
        color="primary"
        className={styles.button}
        disabled={comment.length > INPUT_MAX_CHARACTERS.SMALL}
        onClick={sendFeedback}
        isLoading={isLoading}
      >
        Enviar avaliação
      </Button>
    </Fragment>
  )
}

VisitFeedbackStepThree.propTypes = {
  onNextClick: PropTypes.func.isRequired,
  hasProblem: PropTypes.bool,
  isLoading: PropTypes.bool,
}

VisitFeedbackStepThree.defaultProps = {
  isLoading: false,
  hasProblem: false,
}

export default React.memo(VisitFeedbackStepThree)

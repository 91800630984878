import React, { useCallback } from 'react'
import classnames from 'classnames'
import { Grid, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'

import Button from '_components/material/button'

import useStyles from './styles'

const TRUE = 'true'
const FALSE = 'false'

const ToggleButton = ({ value, onClick, name, label }) => {
  const styles = useStyles()
  const handleClick = useCallback(
    event => {
      onClick({ target: { value: event.currentTarget.value, name } })
    },
    [name, onClick]
  )
  return (
    <Grid container direction="column">
      <Typography htmlFor="label-buttons" component="label" variant="h5" color="textSecondary">
        {label}
      </Typography>
      <Grid id="label-buttons" className={styles.toggleWrapper}>
        <Button
          color="default"
          variant="contained"
          className={classnames(styles.toggleButton, {
            [styles.buttonColor]: value === TRUE,
          })}
          onClick={handleClick}
          name={name}
          value={TRUE}
        >
          SIM
        </Button>
        <Button
          color="default"
          variant="contained"
          className={classnames(styles.toggleButton, {
            [styles.buttonColor]: value === FALSE,
          })}
          onClick={handleClick}
          name={name}
          value={FALSE}
        >
          NÃO
        </Button>
      </Grid>
    </Grid>
  )
}

ToggleButton.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  onClick: PropTypes.func,
}

ToggleButton.defaultProps = {
  label: '',
  name: '',
  value: '',
  onClick: () => {},
}

export default React.memo(ToggleButton)

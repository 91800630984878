import React, { useCallback, useMemo, useState, useEffect, Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControl from '@material-ui/core/FormControl'
import classnames from 'classnames'
import { useForm, FormContext } from 'react-hook-form'
import { navigate, Redirect, Link } from '@reach/router'
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import InputAdornment from '@material-ui/core/InputAdornment'

import {
  updateListingRegistration,
  updateListingRegistrationEmail,
  getPricePrediction,
  getPricePredictionEmail,
} from '_modules/listing-registration/actions'
import {
  pricePredictionSelector,
  isListingRegistrationLoading,
  hasListingRegistrationError,
  listingRegistrationFormSelector,
} from '_modules/listing-registration/selectors'
import Button, { ButtonColor, ButtonVariant, ButtonFormat } from '_components/button'
import { MOBILE_BREAKPOINT, BILLING_PLANS } from '_utils/constants'
import { useWindowWidth, usePrevious, useProtectedRoute } from '_utils/hooks'
import { primaryColor } from '_utils/colors'

import ProgressBar from '../progress-bar'

import PlanPreview from './plan-preview'
import styles from './styles.css'
import useStyles from './styles'

const MAX_VALUE = 1000000
const MIN_VALUE = 0

const ListingRegistrationDetailedStepFour = () => {
  useProtectedRoute('/anunciar-imovel')

  const dispatch = useDispatch()
  const classes = useStyles()
  const predictionPrice = useSelector(pricePredictionSelector)
  const windowWidth = useWindowWidth()
  const [rentPrice, setRentPrice] = useState(predictionPrice.predictedPrice)
  const listingRegistrationForm = useSelector(listingRegistrationFormSelector)
  const methods = useForm({
    defaultValues: listingRegistrationForm,
  })
  const billingPlans = BILLING_PLANS.toJS().sort((a, b) => b.order - a.order)
  const [fee, setFee] = useState(billingPlans[0].fee)
  const [isPercentage, setIsPercentage] = useState(billingPlans[0].isPercentage)
  const [billingPlanId, setBillingPlanId] = useState(billingPlans[0].id)
  const [billingPlan, setBillingPlan] = useState(billingPlans[0].title)
  const isLoading = useSelector(isListingRegistrationLoading)
  const hasRegisterError = useSelector(hasListingRegistrationError)
  const previousLoading = usePrevious(isLoading)

  const inputProps = useMemo(
    () => ({
      className: classes.input,
      endAdornment: (
        <InputAdornment
          className={styles.adornment}
          disableTypography
          disablePointerEvents
          position="end"
        >
          R$
        </InputAdornment>
      ),
    }),
    [classes.input]
  )

  const handleSubmit = useCallback(
    data => {
      const { id, userToken } = listingRegistrationForm
      const payload = { ...data, rentPrice, billingPlan: billingPlanId }
      if (userToken) {
        return dispatch(updateListingRegistrationEmail(id, userToken, payload))
      }
      return dispatch(updateListingRegistration(payload))
    },
    [billingPlanId, dispatch, listingRegistrationForm, rentPrice]
  )

  const { id, userToken, predictedPrice } = listingRegistrationForm

  useEffect(() => {
    if (!predictedPrice) {
      if (userToken) {
        dispatch(getPricePredictionEmail(id, userToken))
      }
      dispatch(getPricePrediction())
    }
  }, [dispatch, id, userToken, predictedPrice])

  useEffect(() => {
    if (previousLoading && !isLoading && !hasRegisterError) {
      navigate(['/anunciar-imovel/cadastro-finalizado'])
    }
  }, [hasRegisterError, isLoading, previousLoading])

  const onChooseButton = useCallback(
    value => {
      setBillingPlan(value)
      setFee(billingPlans.filter(plan => plan.title === value)[0].fee)
    },
    [billingPlans]
  )

  const handleChange = useCallback(
    event => {
      const { value } = event.target
      const billingPlainId = billingPlans.filter(plan => plan.title === value)[0].id
      setBillingPlanId(billingPlainId)
      setIsPercentage(billingPlans.filter(plan => plan.title === value)[0].isPercentage)
      setBillingPlan(value)
      setFee(billingPlans.filter(plan => plan.title === value)[0].fee)
    },
    [billingPlans]
  )

  const buttonColor = useMemo(
    () => (windowWidth > MOBILE_BREAKPOINT ? ButtonColor.PRIMARY : ButtonColor.INVERTED_PRIMARY),
    [windowWidth]
  )

  const rentValue = useMemo(() => rentPrice || predictionPrice.predictedPrice, [
    predictionPrice.predictedPrice,
    rentPrice,
  ])

  const adminFee = useMemo(() => (isPercentage ? ((fee / 100) * rentValue || 0).toFixed(2) : fee), [
    fee,
    isPercentage,
    rentValue,
  ])

  const totalValue = useMemo(() => {
    return (rentValue - adminFee || 0).toFixed(2)
  }, [adminFee, rentValue])

  const onChange = useCallback((event, value) => setRentPrice(value), [])

  useEffect(() => {
    if (rentPrice === undefined && predictionPrice.predictedPrice > 0) {
      setRentPrice(predictionPrice.predictedPrice)
    }
  }, [predictionPrice.predictedPrice, rentPrice])

  if (!listingRegistrationForm) {
    return <Redirect to="/anunciar-imovel" noThrow />
  }

  return (
    <FormContext {...methods}>
      <ProgressBar value={100} />
      <form className={styles.container} onSubmit={methods.handleSubmit(handleSubmit)}>
        <h1 className={styles.title}>Como você quer anunciar?</h1>
        <div className={styles['plan-details']}>
          <FormControl className={styles['plan-details']}>
            <RadioGroup
              aria-label="Tipo de plano para anúncio"
              value={billingPlan}
              name="billingPlan"
              onChange={handleChange}
            >
              {billingPlans.map(plan => (
                <PlanPreview
                  onChooseButton={onChooseButton}
                  key={`planInfos-${plan.title}`}
                  planInfo={plan}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </div>
        <div className={styles.calculator}>
          <h3 className={styles.section}>Calculadora de aluguel</h3>
          {predictionPrice.maxPrice && (
            <Fragment>
              <span className={styles.description}>
                Segundo o que você nos informou, sugerimos que o valor do aluguel esteja nessa
                faixa:
              </span>
              <h2 className={styles.value} style={{ color: primaryColor }}>
                R$ {predictionPrice.minPrice} - R$ {predictionPrice.maxPrice}¹
              </h2>
            </Fragment>
          )}
          <p className={styles.label}>Quanto você quer cobrar?</p>
          <CurrencyTextField
            variant="outlined"
            InputProps={inputProps}
            currencySymbol=""
            decimalCharacter=","
            digitGroupSeparator="."
            textAlign="left"
            leadingZero="deny"
            minimumValue={MIN_VALUE}
            maximumValue={MAX_VALUE}
            value={rentPrice}
            onChange={onChange}
            className={styles.input}
          />
        </div>
        <div className={styles['final-receipt']}>
          <h3 className={styles.section}>Receita Final</h3>
          <p className={styles['final-values']}>
            Aluguel <span>R$ {rentValue}</span>
          </p>
          <p className={styles['final-values']}>
            Taxa de administração <span>(-) R$ {adminFee}</span>
          </p>
          <p className={classnames(styles['final-values'], styles.total)}>
            Você receberá <span>R$ {totalValue}</span>
          </p>
          <span className={classnames(styles['value-info'], styles.description)}>
            ¹Quanto mais informações você preencher, mais precisa será a nossa calculadora de
            aluguel.
          </span>
        </div>
        <div className={styles['buttons-container']}>
          {windowWidth > MOBILE_BREAKPOINT && (
            <Link to="/anunciar-imovel/passo-3" className={styles.back}>
              VOLTAR
            </Link>
          )}
          <Button
            color={buttonColor}
            variant={ButtonVariant.BOLD}
            format={ButtonFormat.SQUARED}
            type="submit"
            className={styles.button}
            isLoading={isLoading}
          >
            CADASTRAR
          </Button>
        </div>
      </form>
    </FormContext>
  )
}

export default React.memo(ListingRegistrationDetailedStepFour)

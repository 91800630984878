import React, { useEffect, useCallback, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { navigate } from '@reach/router'

import { toCurrency } from '_utils/helpers'
import { usePrevious } from '_utils/hooks'
import { FEATURE_FLAGS, LABEL_RENT_PRICE, WARRANTY_TYPES } from '_utils/constants'
import {
  getLeaseById,
  checkIsSubmittingWarranty,
  checkErrorSubmittingWarranty,
} from '_modules/leases/selectors'
import { getWarrantiesSelector } from '_modules/warranty/selectors'
import { getLease, submitWarranty } from '_modules/leases/actions'
import Button, { ButtonColor } from '_components/button'

import WarrantyChoiceCard from './warranty-choice-card'
import styles from './styles.css'

const WarrantyChoice = ({ id }) => {
  const dispatch = useDispatch()

  const lease = useSelector(state => getLeaseById(state, id))
  const warrantiesAvailable = useSelector(getWarrantiesSelector)
  const [warranty, setWarranty] = useState('')

  const isLoading = useSelector(checkIsSubmittingWarranty)
  const hasError = useSelector(checkErrorSubmittingWarranty)
  const wasLoading = usePrevious(isLoading)

  useEffect(() => {
    if (!lease && id) {
      dispatch(getLease(id))
    }
  }, [dispatch, id, lease])

  const hasWarrantySteps = useMemo(() => {
    return warrantiesAvailable.toJS().filter(warrantyOption => warrantyOption.type === warranty)
  }, [warrantiesAvailable, warranty])

  useEffect(() => {
    if (wasLoading && !isLoading && !hasError) {
      if (hasWarrantySteps[0].steps && hasWarrantySteps[0].steps.length) {
        navigate(`/analise-credito/${id}/inicio`)
      } else navigate('/garantia/concluido')
    }
  }, [hasError, hasWarrantySteps, id, isLoading, warrantiesAvailable, warranty, wasLoading])

  const onChangeWarranty = useCallback(value => {
    setWarranty(value)
  }, [])

  const onClickNext = useCallback(() => {
    dispatch(
      submitWarranty(id, {
        warranty,
      })
    )
  }, [dispatch, id, warranty])

  const warrantiesList = useMemo(() => {
    if (FEATURE_FLAGS.allowAllWarrantiesEnabled) {
      return warrantiesAvailable.sortBy(item => item.get('order'))
    }
    return warrantiesAvailable
      .filter(warrantyOption => warrantyOption.getIn(['type']) !== WARRANTY_TYPES.RAPID)
      .sortBy(item => item.get('order'))
  }, [warrantiesAvailable])

  if (!lease) {
    return null
  }

  return (
    <section className={styles.container}>
      <h1 className={styles.heading3}>Garantias</h1>
      <p className={styles.description}>Você escolhe a melhor forma de alugar o seu imóvel</p>
      <div className={styles.value}>
        <span>{LABEL_RENT_PRICE} + encargos</span>
        <span>{toCurrency(lease.getIn(['listing', 'totalPrice']))}</span>
      </div>
      <div>
        {warrantiesList &&
          warrantiesList.map(item => (
            <WarrantyChoiceCard key={item.type} data={item.toJS()} onChange={onChangeWarranty} />
          ))}
      </div>
      <div className={styles['bottom-button']}>
        <Button
          color={ButtonColor.PRIMARY}
          onClick={onClickNext}
          disabled={!warranty}
          isLoading={isLoading}
        >
          PRÓXIMO
        </Button>
      </div>
    </section>
  )
}

WarrantyChoice.propTypes = {
  id: PropTypes.string.isRequired,
}

export default React.memo(WarrantyChoice)

import React, { PureComponent } from 'react'
import { Link } from '@reach/router'
import PropTypes from 'prop-types'

import SpaceshipIcon from '_components/icon-components/spaceship-icon'
import arrowIcon from '_assets/images/angle-left-solid.svg'
import { primaryColor } from '_utils/colors'

import styles from './styles.css'

class ErrorComponent extends PureComponent {
  static propTypes = {
    onReloadClick: PropTypes.func,
    haveReloadButton: PropTypes.bool,
  }

  static defaultProps = {
    onReloadClick: () => {},
    haveReloadButton: false,
  }

  onMouseDown = event => {
    event.preventDefault()
  }

  render() {
    const { onReloadClick, haveReloadButton } = this.props

    return (
      <div className={styles.main}>
        <SpaceshipIcon className={styles.icon} primaryColor={primaryColor} />
        <p>Oops! Algo de errado aconteceu.</p>
        <Link to="/" className={styles['return-button']} onMouseDown={this.onMouseDown}>
          <svg alt="arrow" className={styles['arrow-icon']}>
            <use xlinkHref={arrowIcon} />
          </svg>
          VOLTAR A PÁGINA INICIAL
        </Link>
        {haveReloadButton && (
          <button
            onClick={onReloadClick}
            onMouseDown={this.onMouseDown}
            className={styles['reload-button']}
            type="button"
          >
            RECARREGAR
          </button>
        )}
      </div>
    )
  }
}

export default ErrorComponent

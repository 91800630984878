import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import styles from './styles.css'

const FormGroup = ({ children, className, label, disabled, labelClassName }) => {
  return (
    <div className={classnames(styles.group, className)}>
      <p className={classnames({ [styles.disabled]: disabled }, labelClassName, styles.label)}>
        {label}
      </p>
      {children}
    </div>
  )
}

FormGroup.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  labelClassName: PropTypes.string,
}

FormGroup.defaultProps = {
  children: undefined,
  className: '',
  label: '',
  disabled: false,
  labelClassName: '',
}

export default React.memo(FormGroup)

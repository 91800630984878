import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import RightArrow from '_assets/images/arrow-right.svg'

import styles from './styles.css'

const NextArrow = ({ onClick, smallSize, inverse }) => (
  <div
    className={classnames(
      styles['arrow-container'],
      { [styles.small]: smallSize },
      `${inverse ? styles['inverse-arrow'] : ''}`
    )}
  >
    <button type="button" onClick={onClick} className={styles.arrow}>
      <svg alt="next icon" className={styles.icon}>
        <use xlinkHref={RightArrow} />
      </svg>
      Próximo
    </button>
  </div>
)

NextArrow.propTypes = {
  onClick: PropTypes.func,
  smallSize: PropTypes.bool,
  inverse: PropTypes.bool,
}

NextArrow.defaultProps = {
  onClick: () => {},
  smallSize: false,
  inverse: false,
}

export default NextArrow

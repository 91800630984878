import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import FeatureCard, { cardTheme } from '_components/feature-card'

import styles from './styles.css'

const FeatureSection = ({ className, title, features }) => (
  <div className={classnames(className, styles['feature-section'])}>
    <h3>{title}</h3>
    <div className={styles.features}>
      {features.map(
        feature =>
          feature.text && (
            <FeatureCard
              key={feature.text}
              tabIndex={-1}
              icon={feature.icon}
              text={feature.text}
              theme={cardTheme.NO_BACKGROUND}
              haveSvgFill
              className={styles['no-outline']}
            />
          )
      )}
    </div>
    <div className={styles['features-desktop']}>
      {features.map(
        feature =>
          feature.text && (
            <FeatureCard
              key={feature.text}
              icon={feature.icon}
              text={feature.text}
              theme={cardTheme.NO_BACKGROUND}
              tabIndex={-1}
              className={styles['no-outline']}
            />
          )
      )}
    </div>
  </div>
)

FeatureSection.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  features: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      text: PropTypes.string,
    })
  ).isRequired,
}

FeatureSection.defaultProps = {
  className: '',
  title: '',
}

export default React.memo(FeatureSection)

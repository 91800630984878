import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(({ breakpoints, palette }) => ({
  underlay: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modal: {
    background: 'white',
    boxShadow: '0 1.5rem 1.2rem rgba(0, 0, 0, 0.22), 0 1.9rem 3.8rem rgba(0, 0, 0, 0.3)',
    borderRadius: '0.4rem',
    width: '65rem',
    height: '34.9rem',

    [breakpoints.down('sm')]: {
      width: '32.5rem',
      marginBottom: '6.4rem',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%',
  },
  listingContainer: {
    display: 'grid',
    gridTemplateColumns: '16.4rem auto',
    gridTemplateRows: 'repeat(auto, 2)',
    gridTemplateAreas: "'listing-image title' 'listing-image info'",
    columnGap: '2.4rem',
    padding: '2.4rem',
    width: '65rem',
    height: '18.9rem',
    backgroundColor: palette.gray.main,
    borderRadius: '0.4rem 0.4rem 0 0',

    [breakpoints.down('sm')]: {
      width: '100%',
      height: 'auto',
      gridTemplateColumns: 'auto auto',
      gridTemplateRows: 'auto auto',
      gridTemplateAreas: "'title close' 'listing-image listing-image' 'info info'",
      columnGap: '0',
    },
  },
  closeButton: {
    [breakpoints.down('sm')]: {
      gridArea: 'close',
      outline: '0',
      height: '2.4rem',
      width: '2.4rem',
      marginLeft: 'auto',
    },
  },
  closeIcon: {
    [breakpoints.down('sm')]: {
      fill: palette.gray.solid,
    },
  },
  listingImage: {
    width: '16.4rem',
    height: '14.1rem',
    borderRadius: '0.4rem',
    objectFit: 'cover',
    gridArea: 'listing-image',

    [breakpoints.down('sm')]: {
      width: '28rem',
      height: '7.6rem',
    },
  },
  title: {
    fontWeight: 'bold',
    fontSize: '1.6rem',
    lineHeight: '2.4rem',
    color: palette.text.primary,
    gridArea: 'title',

    [breakpoints.down('sm')]: {
      marginBottom: '1.6rem',
    },
  },
  listingInfos: {
    display: 'flex',
    flexDirection: 'column',
    gridArea: 'info',
  },
  listingType: {
    fontWeight: 500,
    fontSize: '1.4rem',
    lineHeight: '2.1rem',
    color: palette.custom.eerieBlack,
    marginTop: '1.6rem',
  },
  listingNeighborhood: {
    fontWeight: 500,
    fontSize: '1.4rem',
    lineHeight: '2.1rem',
    color: palette.text.secondary,
    marginTop: '0.8rem',
  },
  listingAddress: {
    fontSize: '1.4rem',
    lineHeight: '2.1rem',
    display: 'flex',
    alignItems: 'center',
    textTransform: 'capitalize',
    color: palette.gray.dusty,
  },
  listingTotalPrice: {
    fontWeight: 500,
    fontSize: '1.4rem',
    lineHeight: '2.1rem',
    color: palette.custom.eerieBlack,
    marginTop: '0.8rem',
  },
  adviceContainer: {
    backgroundColor: 'white',
    [breakpoints.down('sm')]: {
      padding: '1.6rem 2.4rem',
    },
  },
  adviceTitle: {
    fontWeight: 'bold',
    fontSize: '1.6rem',
    lineHeight: '2.4rem',
    color: palette.text.primary,

    [breakpoints.down('sm')]: {
      fontSize: '1.4rem',
    },
  },
  adviceDescription: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '1.6rem',
    lineHeight: '2.4rem',
    color: palette.text.secondary,

    [breakpoints.down('sm')]: {
      fontSize: '1.4rem',
    },
  },
  button: {
    outline: '0',
    fontWeight: 'bold',
    fontSize: '1.4rem',
    lineHeight: '2.1rem',
    textTransform: 'uppercase',
    width: '32.5rem',
    '&&': {
      borderRadius: '0',
      border: `0.1rem solid ${palette.divider}`,
      color: palette.text.primary,
    },
  },
  cancelButton: {
    '&&': {
      borderRight: '0',
      color: palette.text.secondary,
    },
  },
}))

import React from 'react'
import PropTypes from 'prop-types'
import { Grid, SvgIcon, Typography } from '@material-ui/core'

import SaveIcon from '_assets/images/ic-save.svg'
import Button from '_components/material/button'
import { CREDIT_ANALYSIS_ROUTES, creditAnalysisFlow } from '_views/credit-analysis/router'

import useStyles from './styles'

const DocumentAssessment = ({ leaseId }) => {
  const styles = useStyles()

  return (
    <Grid component="section" container direction="column" className={styles.container}>
      <SvgIcon aria-hidden="true" className={styles.icon}>
        <use xlinkHref={SaveIcon} />
      </SvgIcon>
      <Typography variant="h1" className={styles.title}>
        Pronto, agora é só esperar!
      </Typography>
      <Typography component="h2" className={styles.description}>
        Você poderá conferir o resultado no menu em
        <Typography component="span" variant="h4" color="secondary" className={styles.bold}>
          Negociações.
        </Typography>
      </Typography>
      <Button
        to={creditAnalysisFlow(CREDIT_ANALYSIS_ROUTES.STATUS, leaseId)}
        className={styles.button}
        color="primary"
        variant="contained"
      >
        Ver resumo da análise
      </Button>
    </Grid>
  )
}

DocumentAssessment.propTypes = {
  leaseId: PropTypes.string,
}

DocumentAssessment.defaultProps = {
  leaseId: '',
}

export default React.memo(DocumentAssessment)

import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ breakpoints, palette }) => ({
  container: {
    display: 'grid',
    alignItems: 'center',
    justifyContent: 'center',
    gridTemplateColumns: 'minmax(12px, 1fr) minmax(auto, 1040px) minmax(12px, 1fr)',
    gridTemplateRows: 'repeat(5, auto)',
    gridGap: '16px',
    paddingBottom: '5rem',

    [breakpoints.down('md')]: {
      gridGap: '10px',
    },
  },
  filters: {
    gridColumn: 2,
    borderBottom: `0.01rem solid ${palette.divider}`,
  },
  loading: {
    gridColumn: 2,
    margin: '3.6rem auto',
  },
  welcome: {
    gridColumn: 2,

    [breakpoints.down('md')]: {
      margin: 'auto',
    },
  },
  properties: {
    gridColumn: 2,
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridTemplateRows: 'auto',
    gridGap: '8px',
    margin: '1rem 0 5rem',

    [breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
      justifyItems: 'center',
    },
  },
  buttonSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gridColumn: 2,
  },
}))

import React, { useMemo, useEffect, Fragment, useCallback, useState } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { useLocation, navigate } from '@reach/router'
import { useSelector, useDispatch } from 'react-redux'
import LazyLoad from 'react-lazyload'

import FiltersSection from '_components/filters-section'
import ReorderButton from '_views/recommendations/reorder-section'
import PropertyResultsSection, {
  PropertyResultsSectionTheme,
} from '_components/property-results-section'
import { parseQuerystring } from '_utils/querystring'
import { useWindowSize, usePrevious } from '_utils/hooks'
import useModal from '_hooks/use-modal'
import { searchProperties, getNextPage } from '_modules/search/actions'
import {
  checkIsLoadingProperties,
  getPropertiesErrorSelector,
  getLikingProperty,
} from '_modules/property/selectors'
import Building from '_components/building-animated'
import { getExactMatches, getPartialMatches, searchSelector } from '_modules/search/selectors'
import ListingCard from '_views/recommendations/listing-card'
import { GENERAL, FEATURE_FLAGS, SORT_OPTIONS, SNACKBAR_INTERVAL } from '_utils/constants'
import { getUserLikedProperties } from '_modules/user/selectors'
import MoreFiltersSection from '_components/more-filters-section'
import Button, { ButtonFormat } from '_components/material/button'
import ErrorComponent from '_components/error-component'
import { getCitiesValues } from '_modules/utils/selectors'
import SEO from '_components/seo'
import { LIKE_PROPERTY } from '_modules/property/actions'
import Snackbar from '_components/snackbar'
import { sortingProperty } from '_utils/property'
import { setSearchFilter, SET_SEARCH_FILTER } from '_modules/filter/actions'
import { filterSelector } from '_modules/filter/selectors'
import useOnSuccessCall from '_hooks/use-on-success'
import { parseFilterToUrl } from '_utils/url-friendly'
import { primaryColor } from '_utils/colors'
import { FACEBOOK_ID } from '_config/environment'

import useStyles from './styles'

const SearchProperties = () => {
  const styles = useStyles()
  const location = useLocation()
  const dispatch = useDispatch()
  const { isMobile } = useWindowSize()

  const filter = useSelector(filterSelector)

  const { modalOpen, setModalOpen, handleModal } = useModal()
  const [filterOrder, setFilterOrder] = useState('')
  const [showSnackBar, setShowSnackBar] = useState(true)

  const isLoadingProperties = useSelector(checkIsLoadingProperties)
  const searchErrors = useSelector(getPropertiesErrorSelector)
  const { nextPage, exactSize } = useSelector(searchSelector)
  const listLikedProperties = useSelector(getUserLikedProperties)
  const exactMatches = useSelector(state => getExactMatches(state))
  const partialMatches = useSelector(state => getPartialMatches(state))
  const availableCities = useSelector(state => getCitiesValues(state))
  const isLikePropertyLoading = useSelector(getLikingProperty)
  const wasLikePropertyLoading = usePrevious(isLikePropertyLoading)

  const initialState = useMemo(
    () =>
      location.search
        ? parseQuerystring(location.search, FEATURE_FLAGS.commercialListingsEnabled)
        : {},
    [location.search]
  )

  const currentCity = useMemo(
    () => initialState && availableCities.find(city => initialState.city === city.value),
    [availableCities, initialState]
  )

  const notFoundError = useMemo(
    () => !!searchErrors.length && searchErrors.get('detail') === 'Not found.',
    [searchErrors]
  )

  const currentLocation = useMemo(() => {
    if (location.search) {
      const parsedQuery = parseQuerystring(location.search, FEATURE_FLAGS.commercialListingsEnabled)
      const { city, neighborhoods } = parsedQuery
      if (neighborhoods) {
        return [...new Set([city, neighborhoods])]
      }
      if (city) {
        return [...new Set([city])]
      }
    }
    return []
  }, [location.search])

  const handleFilterRequestMessage = useCallback(
    event => {
      if (event.origin !== window.origin) return

      if (!event.origin) return

      if (event.data === 'getFilters') {
        event.source.postMessage(
          {
            message: 'filterValues',
            ...initialState,
          },
          event.origin
        )
      }
    },
    [initialState]
  )

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('message', handleFilterRequestMessage, false)
    }
  }, [handleFilterRequestMessage])

  const loadProperties = useCallback(() => {
    dispatch(searchProperties())
  }, [dispatch])

  useOnSuccessCall(SET_SEARCH_FILTER.ACTION, loadProperties)

  const onFilterChange = useCallback(
    values => {
      setModalOpen(false)
      navigate(parseFilterToUrl(location.pathname, values))
    },
    [location.pathname, setModalOpen]
  )

  const handleReorderFilter = useCallback(value => {
    setFilterOrder(value)
  }, [])

  const propertyOrder = useCallback(
    results => {
      switch (filterOrder) {
        case SORT_OPTIONS.LOWEST_PRICE: {
          return sortingProperty(results, true)
        }
        case SORT_OPTIONS.HIGHER_PRICE: {
          return sortingProperty(results)
        }
        case SORT_OPTIONS.LATEST: {
          return results.sort((propertyA, propertyB) => propertyA.id - propertyB.id)
        }
        default: {
          return results
        }
      }
    },
    [filterOrder]
  )

  const renderProperty = useCallback(
    results => {
      if (results.length) {
        return (
          <Grid className={styles.properties}>
            {propertyOrder(results).map(property =>
              filterOrder === '' ? (
                <LazyLoad
                  once
                  height={isMobile ? 286 : 214}
                  key={`property-${property.externalId}`}
                >
                  <ListingCard
                    info={property}
                    location={location}
                    primaryColor={primaryColor}
                    listLikedProperties={listLikedProperties}
                    hideScheduleButton
                  />
                </LazyLoad>
              ) : (
                <ListingCard
                  key={`property-${property.externalId}`}
                  info={property}
                  location={location}
                  primaryColor={primaryColor}
                  listLikedProperties={listLikedProperties}
                  hideScheduleButton
                />
              )
            )}
          </Grid>
        )
      }
      return <PropertyResultsSection theme={PropertyResultsSectionTheme.NO_RESULT} />
    },
    [filterOrder, isMobile, listLikedProperties, location, propertyOrder, styles.properties]
  )
  const onShowMore = useCallback(() => {
    dispatch(getNextPage('v2'))
  }, [dispatch])

  const renderFinalExactMatches = useMemo(() => {
    if (nextPage && exactSize > exactMatches.length && !isLoadingProperties) {
      return (
        <Grid className={styles.buttonSection}>
          <Button
            variant="contained"
            format={ButtonFormat.ROUNDED}
            color="primary"
            onClick={onShowMore}
          >
            MOSTRAR MAIS
          </Button>
        </Grid>
      )
    }
    if (exactSize > 0 && exactMatches.length === exactSize && !isLoadingProperties) {
      return (
        <Grid className={styles.section}>
          <PropertyResultsSection theme={PropertyResultsSectionTheme.ALL_RESULTS} />
        </Grid>
      )
    }
    return null
  }, [
    exactMatches.length,
    exactSize,
    isLoadingProperties,
    nextPage,
    onShowMore,
    styles.buttonSection,
    styles.section,
  ])

  const setSnackBar = useCallback(() => {
    setShowSnackBar(false)
  }, [])

  useEffect(() => {
    if (!isLikePropertyLoading && wasLikePropertyLoading && showSnackBar) {
      setInterval(setSnackBar, SNACKBAR_INTERVAL)
    }
  }, [isLikePropertyLoading, setSnackBar, showSnackBar, wasLikePropertyLoading])

  useEffect(() => {
    dispatch(setSearchFilter(location.pathname))
  }, [dispatch, location.pathname])

  return (
    <Grid component="section" className={styles.container}>
      <FiltersSection
        initialState={filter}
        onOpenMoreFilters={handleModal}
        className={styles.filter}
        onChange={onFilterChange}
        reorderProperties={handleReorderFilter}
      />
      {(exactMatches.length > 0 || partialMatches.length > 0) && (
        <Fragment>
          <Grid className={styles.orderOptions}>
            {exactSize && (
              <Fragment>
                <Typography
                  color="secondary"
                  component="h1"
                  variant="subtitle1"
                  className={styles.propertiesFound}
                >
                  <strong>
                    {exactSize} imóve{exactSize > 1 ? 'is' : 'l'} encontrado{exactSize > 1 && 's'}
                  </strong>{' '}
                  {currentCity && `em ${currentCity.label}`}
                </Typography>
              </Fragment>
            )}
            <ReorderButton reorderProperties={handleReorderFilter} />
          </Grid>
          <Grid className={styles.exactMatches}>{renderProperty(exactMatches)}</Grid>
          {renderFinalExactMatches}
          {partialMatches.length && (
            <Grid className={styles.partial}>
              <Typography
                component="h1"
                variant="subtitle1"
                color="secondary"
                className={styles.result}
              >
                Resultados semelhantes a sua busca
              </Typography>
              {renderProperty(partialMatches)}
              {!isLoadingProperties && nextPage && (
                <Button
                  variant="contained"
                  format={ButtonFormat.ROUNDED}
                  color="primary"
                  onClick={onShowMore}
                >
                  MOSTRAR MAIS
                </Button>
              )}
            </Grid>
          )}
        </Fragment>
      )}
      {isLoadingProperties && (
        <Grid className={styles.building}>
          <Building isSmall />
        </Grid>
      )}
      {notFoundError && (
        <Grid className={styles.error}>
          <PropertyResultsSection theme={PropertyResultsSectionTheme.NO_RESULT} error />
        </Grid>
      )}
      {searchErrors.length > 0 && !notFoundError && (
        <Grid className={styles.error}>
          <ErrorComponent onReloadClick={loadProperties} haveReloadButton />
        </Grid>
      )}
      {modalOpen && (
        <MoreFiltersSection
          isOpen={modalOpen}
          onCloseMoreFiltersSection={handleModal}
          initialState={filter}
          onApplyClick={onFilterChange}
        />
      )}
      <SEO
        schema="WebPage"
        title={`Imóveis para alugar em ${currentLocation.join(', ')} - ${GENERAL.brand}`}
        description={`Encontre um novo lar em ${currentLocation.join(', ')}. Na imobiliária ${
          GENERAL.brand
        } você agenda visitas online, aluga rápido e com segurança.`}
        path={`${GENERAL.baseUrl}${
          location.search ? location.pathname + location.search : location.pathname
        }`}
        contentType="website"
        image={GENERAL.brandPictureUrl}
        brand={GENERAL.brand}
        facebookAppId={FACEBOOK_ID}
      />
      {showSnackBar && (
        <Snackbar
          variant="default"
          message="Você favoritou um imóvel. Confira no menu favoritos."
          action={LIKE_PROPERTY.ACTION}
          className={styles.snackBar}
        />
      )}
    </Grid>
  )
}

export default React.memo(SearchProperties)

import { createReducer } from '_utils/redux'
import { Agency } from '_models'

import { GET_AGENCY } from './actions'

const INITIAL_STATE = new Agency()

const agency = createReducer(INITIAL_STATE, {
  [GET_AGENCY.FULFILLED]: (state, { payload }) => {
    return state.mergeProps(payload)
  },
})

export default agency

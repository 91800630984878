import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from '@reach/router'

import Ilustration from '_assets/images/house.svg'
import { removeListingRegistration } from '_modules/listing-registration/actions'
import { primaryColor } from '_utils/colors'
import { GENERAL } from '_utils/constants'

import styles from './styles.css'

const ListingRegistrationDetailedFinalStep = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(removeListingRegistration())
  }, [dispatch])

  return (
    <div className={styles.container}>
      <svg aria-hidden="true" className={styles.svg}>
        <use xlinkHref={Ilustration} />
      </svg>
      <div className={styles.body}>
        <h1 className={styles.title}>Quase lá!</h1>
        <p className={styles.content}>Em breve um especialista entrará em contato com você.</p>
        <Link style={{ backgroundColor: primaryColor }} className={styles.button} to="/">
          ENTENDI
        </Link>
      </div>
      <footer className={styles.email}>
        Dúvidas?
        <br />
        <a href={`mailto:${GENERAL.email}`} className={styles['email-link']}>
          {GENERAL.email}
        </a>
      </footer>
    </div>
  )
}

export default React.memo(ListingRegistrationDetailedFinalStep)

import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import InfoIcon from '@material-ui/icons/Info'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import WarningIcon from '@material-ui/icons/Warning'

import { TRIGGER_SNACKBAR } from '_modules/snackbar/actions'
import { materialStyles } from '_components/snackbar/material-ui'
import { usePrevious } from '_utils/hooks'
import { snackbarSelector } from '_modules/utils/selectors'

const CLICK_AWAY = 'clickaway'

export const SNACKBAR_VARIANT = {
  ERROR: 'error',
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
  DEFAULT: 'default',
}

const variantIcon = {
  [SNACKBAR_VARIANT.SUCCESS]: CheckCircleIcon,
  [SNACKBAR_VARIANT.WARNING]: WarningIcon,
  [SNACKBAR_VARIANT.ERROR]: ErrorIcon,
  [SNACKBAR_VARIANT.INFO]: InfoIcon,
  [SNACKBAR_VARIANT.DEFAULT]: CheckCircleIcon,
}

const MySnackbar = ({
  className,
  message,
  openSnackBar,
  action,
  variant,
  isLocalDispatch,
  snackbarClassName,
  ...other
}) => {
  const classes = materialStyles()
  const snackbarInfo = useSelector(snackbarSelector)

  // snackbar
  const isLoading = useSelector(state => {
    if (typeof action === 'string') {
      return !!state.loading.get(action)
    }

    return action.reduce((acc, curr) => {
      return acc || !!state.loading.get(curr)
    }, false)
  })
  const hasError = useSelector(state => {
    if (typeof action === 'string') {
      return !!state.error.get(action)
    }

    return action.reduce((acc, curr) => {
      return acc || !!state.error.get(curr)
    }, false)
  })
  const [showSnackbar, handleSnackbar] = useState(false)

  const Icon = hasError ? variantIcon.warning : variantIcon[variant]

  const closeSnackBar = useCallback((event, type) => {
    if (type !== CLICK_AWAY) {
      handleSnackbar(false)
    }
  }, [])

  const snackbarText = useMemo(() => {
    if (hasError) {
      return 'Não foi possível efetuar essa operação!'
    }

    if (snackbarInfo && snackbarInfo.message) return snackbarInfo.message

    return message
  }, [hasError, message, snackbarInfo])

  const renderMessage = useMemo(() => {
    return (
      <span id="client-snackbar" className={classes.message}>
        <Icon className={classnames(classes.icon, classes.iconVariant)} />
        {snackbarText}
      </span>
    )
  }, [classes.icon, classes.iconVariant, classes.message, snackbarText])
  const colorVariant = useMemo(() => {
    if (hasError) {
      return SNACKBAR_VARIANT.ERROR
    }
    if (snackbarInfo && snackbarInfo.variant) {
      return snackbarInfo.variant
    }
    return variant
  }, [hasError, snackbarInfo, variant])

  const actionSnackbar = useMemo(
    () => [
      <IconButton key="close" aria-label="close" color="inherit" onClick={closeSnackBar}>
        <CloseIcon className={classes.icon} />
      </IconButton>,
    ],
    [classes.icon, closeSnackBar]
  )

  const prevIsLoading = usePrevious(isLoading)

  useEffect(() => {
    if (prevIsLoading && !isLoading) {
      handleSnackbar(true)
    }
  }, [hasError, isLoading, openSnackBar, prevIsLoading])

  useEffect(() => {
    if (isLocalDispatch) {
      handleSnackbar(true)
    }
  }, [isLocalDispatch])

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={showSnackbar}
      autoHideDuration={5000}
      onClose={closeSnackBar}
      className={classnames(classes.snackbar, snackbarClassName)}
    >
      <SnackbarContent
        className={classnames(classes[colorVariant], className)}
        aria-describedby="client-snackbar"
        message={renderMessage}
        action={actionSnackbar}
        {...other}
      />
    </Snackbar>
  )
}

MySnackbar.propTypes = {
  className: PropTypes.string,
  snackbarClassName: PropTypes.string,
  message: PropTypes.string,
  openSnackBar: PropTypes.func,
  variant: PropTypes.oneOf(Object.values(SNACKBAR_VARIANT)).isRequired,
  action: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  isLocalDispatch: PropTypes.bool,
}
MySnackbar.defaultProps = {
  className: '',
  snackbarClassName: '',
  message: '',
  openSnackBar: () => {},
  action: TRIGGER_SNACKBAR.ACTION,
  isLocalDispatch: false,
}

export default React.memo(MySnackbar)

import { schema } from 'normalizr'
import { Map, List } from 'immutable'

import Model from './model'

class Booking extends Model({
  available: null,
  cancelled: null,
  createdAt: null,
  dateAndTime: null,
  id: null,
  listing: Map(),
  phoneNumber: null,
  updatedAt: null,
  user: null,
  warned: null,
  lease: null,
  active: null,
  gaveUpListing: null,
  userAction: null,
  reporter: Map(),
  unaccompanied: null,
  type: null,
  booking: null,
  bookingRating: null,
  comment: null,
  happened: null,
  listingRating: null,
  staffRated: null,
  staffRatedName: null,
  staffRating: null,
  feedback: List(),
  feedbackId: null,
  staff: null,
  hasReportedFeedback: false,
  isReportProblemFlow: false,
  isConfirmed: false,
  responsibleStaffFullName: '',
  responsibleStaffFormattedPhoneNumber: '',
}) {}

const bookingSchema = new schema.Entity(
  'bookings',
  {},
  { processStrategy: entity => new Booking(entity) }
)
export { bookingSchema, Booking as default }

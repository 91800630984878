import React, { useCallback, useContext, useEffect } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { navigate } from '@reach/router'

import useModal from '_hooks/use-modal'
import AlertModal from '_components/material/alert-modal'
import UpdateIcon from '_assets/images/ic-update.svg'
import Button from '_components/material/button'
import TokenContext from '_context/token'
import { getLeaseById, checkIsCancellingLease, errorCancelLease } from '_modules/leases/selectors'
import { cancelLease } from '_modules/leases/actions'
import { usePrevious } from '_utils/hooks'

import useStyles from './styles'

const CreditAnalysisUpdated = () => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const { leaseId } = useContext(TokenContext)
  const lease = useSelector(state => getLeaseById(state, leaseId))
  const externalId = lease && lease.getIn(['listing', 'externalId'])

  const loading = useSelector(checkIsCancellingLease)
  const error = useSelector(errorCancelLease)
  const wasLoading = usePrevious(loading)
  const { modalOpen, handleModal } = useModal()

  useEffect(() => {
    if (!error && wasLoading && !loading) {
      navigate(`/analise-credito/${leaseId}/analise-encerrada`)
    }
  }, [error, leaseId, loading, wasLoading])

  const endLease = useCallback(() => {
    dispatch(cancelLease({ leaseId, externalId }))
  }, [dispatch, externalId, leaseId])

  return (
    <Grid component="section" container direction="column" className={styles.container}>
      <svg aria-hidden="true" className={styles.icon}>
        <use xlinkHref={UpdateIcon} />
      </svg>
      <Typography component="h1" variant="h3" color="secondary" className={styles.title}>
        Sua análise foi atualizada
      </Typography>
      <Typography component="h2" variant="h4" className={styles.description}>
        Houve uma alteração em sua análise de crédito feita pela imobiliária. Para ver mais
        detalhes, clique em Ver resumo.
      </Typography>
      <Button
        to={`/analise-credito/${leaseId}/status`}
        className={styles.button}
        color="primary"
        variant="contained"
      >
        Ver resumo da análise
      </Button>

      <Button
        className={styles.buttonSecondary}
        color="secondary"
        variant="text"
        onClick={handleModal}
      >
        Encerrar negociação
      </Button>
      {modalOpen && <AlertModal handleModal={handleModal} handleConfirmClick={endLease} />}
    </Grid>
  )
}

export default React.memo(CreditAnalysisUpdated)

import { normalize, schema } from 'normalizr'

import { defineAction } from '_utils/redux'
import * as recommendationsService from '_services/recommendations'
import { recommendationsPropertySchema } from '_models'

export const GET_RECOMMENDATIONS_PROPERTIES = defineAction('GET_RECOMMENDATIONS_PROPERTIES')
export const SET_FILTER_RECOMMENDATIONS = 'SET_FILTER_RECOMMENDATIONS'

const propertySchema = new schema.Entity('paginated', {
  results: new schema.Array(recommendationsPropertySchema),
})

export const getRecommendationPropertiesFromHash = hash => (dispatch, getState) => {
  if (getState().recommendations.results.isEmpty()) {
    return dispatch({
      type: GET_RECOMMENDATIONS_PROPERTIES.ACTION,
      payload: recommendationsService
        .getRecommendationByHash(hash)
        .then(result => normalize(result, propertySchema)),
      meta: {
        initialSearch: true,
      },
    })
  }

  return undefined
}

export const loadMoreProperties = hash => (dispatch, getState) =>
  dispatch({
    type: GET_RECOMMENDATIONS_PROPERTIES.ACTION,
    payload: recommendationsService
      .getRecommendationByHash(hash, { page: getState().recommendations.nextPage })
      .then(result => normalize(result, propertySchema)),
    meta: {
      initialSearch: false,
    },
  })

export const setFilterRecommendations = filter => dispatch =>
  dispatch({
    type: SET_FILTER_RECOMMENDATIONS,
    payload: {
      ...filter,
    },
  })

import React, { Fragment } from 'react'
import classnames from 'classnames'

import FormField from '../../outlined-field'
import styles from '../styles.css'

const Step6 = () => (
  <Fragment>
    <header className={styles.header}>
      <h1 className={styles.title}>Fale um pouco sobre o imóvel</h1>
    </header>
    <div className={classnames(styles.group, styles['group--grid'])}>
      <FormField
        className={styles['grid-span-2']}
        name="address"
        placeholder="Endereço"
        variant="outlined"
        label="Endereço"
        autoComplete="street-address"
      />
      <FormField
        name="extraAddressInfo"
        placeholder="Complemento"
        variant="outlined"
        label="Complemento"
        autoComplete="address-level4"
      />
      <FormField
        name="postalCode"
        placeholder="CEP"
        variant="outlined"
        label="CEP"
        mask="99.999-999"
        autoComplete="postal-code"
      />
      <FormField
        className={styles['sm-grid-span-2']}
        name="city"
        placeholder="Cidade"
        variant="outlined"
        label="Cidade"
        autoComplete="address-level2"
      />
      <FormField
        className={styles['sm-grid-span-2']}
        name="neighborhood"
        placeholder="Bairro"
        variant="outlined"
        label="Bairro"
        autoComplete="address-level3"
      />
    </div>
  </Fragment>
)

export default React.memo(Step6)

import { List, OrderedMap } from 'immutable'

import { createReducer } from '_utils/redux'
import { LOGOUT } from '_modules/user/actions'
import { Property } from '_models/'
import { getPropertyDefaultTitle } from '_utils/property'

import { GET_HIGHLIGHTS } from './actions'
// Initial State
const INITIAL_STATE = List()

const transformOrderedMap = data =>
  OrderedMap(
    data.map(payload => [
      payload.id,
      new Property({ ...payload, slug: getPropertyDefaultTitle(payload, true) }),
    ])
  )

const highlights = createReducer(INITIAL_STATE, {
  [GET_HIGHLIGHTS.FULFILLED]: (state, { payload }) => {
    return transformOrderedMap(payload.results)
  },

  [LOGOUT]: () => INITIAL_STATE,
})

export default highlights

import React, { useMemo, useCallback, useState, useEffect, useContext } from 'react'
import { navigate } from '@reach/router'
import { useSelector, useDispatch } from 'react-redux'
import { Grid, Typography } from '@material-ui/core'

import TokenContext from '_context/token'
import RadioGroup from '_components/radio-group'
import { getLeaseById } from '_modules/leases/selectors'
import Button from '_components/material/button'
import TextField from '_components/material/textfield'
import { getIncomeHelper } from '_modules/income-helper/selectors'
import {
  sendMonthlyIncome,
  SEND_MONTHLY_INCOME,
  sendParticipantsData,
  SEND_PARTICIPANTS_DATA,
} from '_modules/leases/actions'
import { PARTICIPANTS_TYPE, WARRANTY_TYPES } from '_utils/constants'
import useOnSuccessCall from '_hooks/use-on-success'

import { CREDIT_ANALYSIS_ROUTES, creditAnalysisFlow } from '../router'

import useStyles from './styles'

const options = [
  { value: 'true', label: 'Sim, desejo utilizar minha renda' },
  { value: 'false', label: 'Não desejo utilizar minha renda' },
]

const CreditAnalysisConsiderIncome = () => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const { leaseId, token } = useContext(TokenContext)

  const leaseByLeaseId = useSelector(state => getLeaseById(state, leaseId))
  const [considerIncome, setConsiderIncome] = useState(null)
  const [monthlyIncome, setMonthlyIncome] = useState(null)
  const currentIncomeHelper = useSelector(getIncomeHelper)

  const showIncomeInput = leaseByLeaseId.get('warranty') !== WARRANTY_TYPES.CREDPAGO

  useEffect(() => {
    if (leaseByLeaseId && leaseByLeaseId.monthlyIncome && monthlyIncome === null) {
      setMonthlyIncome(leaseByLeaseId.monthlyIncome)
      setConsiderIncome(`${leaseByLeaseId.considerIncome}`)
    }
  }, [considerIncome, currentIncomeHelper.category, leaseByLeaseId, monthlyIncome, token])
  useEffect(() => {
    if (currentIncomeHelper.category === PARTICIPANTS_TYPE.TENANT) {
      navigate(creditAnalysisFlow(CREDIT_ANALYSIS_ROUTES.REGISTER_DOCUMENTS, leaseId, token))
    }
  }, [currentIncomeHelper.category, leaseId, token])
  const handleSuccess = useCallback(() => {
    navigate(creditAnalysisFlow(CREDIT_ANALYSIS_ROUTES.REGISTER_DOCUMENTS, leaseId, token))
  }, [leaseId, token])

  const [isSendingMonthlyIncomeLoading] = useOnSuccessCall(
    SEND_MONTHLY_INCOME.ACTION,
    handleSuccess
  )
  const [isSendingParticipantsDataLoading] = useOnSuccessCall(
    SEND_PARTICIPANTS_DATA.ACTION,
    handleSuccess
  )

  const handleConsiderIncome = useCallback(event => {
    const { value } = event.target
    setConsiderIncome(value)
  }, [])

  const handleMonthlyIncome = useCallback(event => {
    const { value } = event.target
    setMonthlyIncome(value)
  }, [])
  const onNextClick = useCallback(() => {
    const payload = { considerIncome: considerIncome === 'true', monthlyIncome }
    if (token) {
      payload.token = token
      dispatch(sendParticipantsData({ payload }))
      return
    }
    dispatch(sendMonthlyIncome(payload))
  }, [considerIncome, dispatch, monthlyIncome, token])

  const isButtonDisabled = useMemo(() => {
    if (showIncomeInput) {
      if (considerIncome === 'false') {
        return false
      }
      return monthlyIncome === null || considerIncome === null
    }
    return considerIncome === null
  }, [considerIncome, monthlyIncome, showIncomeInput])

  return (
    <Grid component="section" container direction="column" className={styles.container}>
      <Typography variant="h3" component="h1" color="secondary" className={styles.title}>
        Responsáveis pelo pagamento
      </Typography>
      <Grid component="section" className={styles.sectionOne}>
        <Typography component="h2" variant="h4" color="secondary" className={styles.titleSection}>
          Deseja utilizar a sua receita para comprovar renda nesta locação?
        </Typography>
        <RadioGroup value={considerIncome} onChange={handleConsiderIncome} options={options} />
      </Grid>
      {showIncomeInput && (
        <Grid component="section" className={styles.sectionTwo}>
          <Typography variant="h4" color="secondary">
            Qual a sua renda mensal líquida?
          </Typography>
          <Typography variant="subtitle1" className={styles.descriptionSecondary}>
            Caso não possua, você pode deixar em branco.
          </Typography>
          <TextField
            value={monthlyIncome}
            endAdornment="R$"
            outputFormat="number"
            onChange={handleMonthlyIncome}
            className={styles.textField}
          />
        </Grid>
      )}
      <Button
        className={styles.button}
        color="primary"
        variant="contained"
        onClick={onNextClick}
        isLoading={isSendingMonthlyIncomeLoading || isSendingParticipantsDataLoading}
        disabled={isButtonDisabled}
      >
        Próximo
      </Button>
    </Grid>
  )
}

export default CreditAnalysisConsiderIncome

import React, { memo } from 'react'
import PropTypes from 'prop-types'

import styles from './styles.css'

const CheckListIcon = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 36 36"
    height="30"
    width="30"
  >
    <defs>
      <filter id="Rectangle_1123" width="36" height="36" x="0" y="0" filterUnits="userSpaceOnUse">
        <feOffset dy="3" />
        <feGaussianBlur result="blur" stdDeviation="3" />
        <feFlood floodOpacity=".161" />
        <feComposite in2="blur" operator="in" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="Union_6"
        width="24.131"
        height="20.07"
        x="11.828"
        y="6.477"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="2" />
        <feGaussianBlur result="blur-2" stdDeviation="1" />
        <feFlood floodOpacity=".161" />
        <feComposite in2="blur-2" operator="in" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g id="Group_1278" data-name="Group 1278">
      <g className={styles['cls-6']}>
        <g
          id="Rectangle_1123-2"
          className={styles['cls-1']}
          data-name="Rectangle 1123"
          transform="translate(9 6)"
        >
          <rect width="18" height="18" className={styles['cls-3']} rx="4" />
          <rect width="17" height="17" x=".5" y=".5" className={styles['cls-4']} rx="3.5" />
        </g>
      </g>
      <g className={styles['cls-5']}>
        <path
          id="Union_6-2"
          d="M18179.512 14329.521l-5.09-5.127a1.5 1.5 0 0 1 0-2.086l1.164-1.16a1.5 1.5 0 0 1 2.082 0l2.879 2.9 6.658-6.63a1.51 1.51 0 0 1 2.086 0l1.16 1.164a1.5 1.5 0 0 1 0 2.082l-7.531 7.5a1.4 1.4 0 0 1-.162.2l-1.164 1.164a1.5 1.5 0 0 1-2.082 0z"
          className={styles['cls-2']}
          data-name="Union 6"
          transform="translate(-18158.54 -14308.89)"
        />
      </g>
    </g>
  </svg>
)

CheckListIcon.propTypes = {
  className: PropTypes.string,
}

CheckListIcon.defaultProps = {
  className: '',
}

export default memo(CheckListIcon)

import React from 'react'

import { BILLING_PLANS } from '_utils/constants'

import Card from './card/simple-card'
import styles from './styles.css'

const PlansSection = () => (
  <section className={styles['plans-section']}>
    <header className={styles.header}>
      <h1 className={styles.title}>Planos</h1>
      <p className={styles.body}>Você escolhe a melhor forma de alugar o seu imóvel</p>
    </header>
    <div>
      <div className={styles['card-container']}>
        {BILLING_PLANS.map(plan => (
          <Card {...plan} key={plan.id} hasCompetitor />
        ))}
      </div>
    </div>
  </section>
)

export default React.memo(PlansSection)

import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ breakpoints, palette }) => ({
  toggleWrapper: {
    display: 'grid',
    gridTemplateColumns: '16rem 16rem',
    gap: '1.6rem',
  },
  toggleButton: {
    width: '16rem',
    height: '4rem',
    marginTop: '1.6rem',
    color: palette.secondary.dark,
    backgroundColor: palette.gray.gallery,
    [breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  buttonColor: {
    color: 'white',
    backgroundColor: palette.primary.main,
  },
}))

import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
  main: {
    display: 'grid',
    gridTemplateColumns: 'auto 42.4rem auto',
    gridTemplateAreas: "' . content . '",
    padding: '4.8rem 0 8rem',
    [theme.breakpoints.down('sm')]: {
      display: 'grid',
      gridTemplateColumns: 'auto 32.8rem auto',
      gridTemplateAreas: "' . content . '",
      padding: '1.6rem 0 4.8rem',
    },
  },
  content: {
    display: 'grid',
    gridArea: 'content',
  },
  launchIcon: {
    width: '1.6rem',
    height: '1.6rem',
    marginLeft: '1.6rem',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  houseIcon: {
    width: '32rem',
    height: '16rem',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      width: '20rem',
      height: '10rem',
    },
  },
  visitRealized: {
    display: 'grid',
    gridRowGap: '1.6rem',
    paddingBottom: '3.2rem',
    [theme.breakpoints.down('sm')]: {
      gridRowGap: '0.8rem',
      paddingBottom: '4.4rem',
    },
  },
  mainTitle: {
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '2.4rem',
    },
  },
  title: {
    color: theme.palette.secondary.dark,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.6rem',
    },
  },
  button: {
    backgroundColor: theme.palette.gray.gallery,
    borderColor: theme.palette.gray.default,
    color: theme.palette.secondary.dark,
  },
  visitDetailsContainer: {
    padding: '3rem 0',
    [theme.breakpoints.down('sm')]: {
      padding: '4rem 0',
      textAlign: 'left',
    },
  },
  visitDetailsPaper: {
    padding: '3.4rem',
    margin: '1.6rem 0',
    [theme.breakpoints.down('sm')]: {
      padding: '2.4rem 1.8rem',
      margin: '1.6rem 0 2.4rem',
    },
  },
  visitDetailsContent: {
    display: 'grid',
    gridTemplateRows: 'repeat(3, auto)',
    gridGap: '3.2rem',
  },
  detail: {
    display: 'grid',
    gridTemplate: '2.4rem auto / min-content auto',
    gridTemplateAreas: "'icon title' 'icon text'",
    gridColumnGap: '1rem',
    [theme.breakpoints.down('sm')]: {
      gridColumnGap: '2.4rem',
    },
  },
  visitDetailsTitle: {
    gridArea: 'title',
  },
  visitDetailsText: {
    gridArea: 'text',
  },
  icon: {
    gridArea: 'icon',
    color: theme.palette.gray.solid,
  },
  visitFailed: {
    display: 'grid',
    gridTemplate: '1fr 1fr / 1fr 1fr',
    gridTemplateAreas: "'title title' 'button button'",
    gridColumnGap: '2.4rem',
    padding: '3.2rem 0',
    borderStyle: 'solid none solid none',
    border: `0.01rem solid ${theme.palette.gray.default}`,
  },
  visitFailedTitle: {
    gridArea: 'title',
    display: 'flex',
    justifyContent: 'center',
  },
  emojiNeutralIcon: {
    width: '2rem',
    height: '2rem',
    marginRight: '1.2rem',
    color: theme.palette.secondary.dark,
  },
  steps: {
    display: 'grid',
    gridRowGap: '2.4rem',
    paddingTop: '4.8rem',
    borderTop: `0.01rem solid ${theme.palette.gray.default}`,
  },
  feedbackPaper: {
    maxWidth: '42.4rem',
    padding: '3.6rem 8rem',
    [theme.breakpoints.down('sm')]: {
      minWidth: '32.8rem',
      marginLeft: '-2.4rem',
      padding: '3rem 3.8rem',
    },
  },
  feedback: {
    maxWidth: '42.4rem',
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      minWidth: '32.8rem',
    },
  },
}))

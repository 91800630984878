import React from 'react'
import PropTypes from 'prop-types'
import { Button, SvgIcon } from '@material-ui/core'

import SmileIcon from '_assets/images/ic-smile.svg'

import useStyles from './styles'

const FloatingFeedbackButton = ({ label, ...props }) => {
  const styles = useStyles()

  return (
    <Button variant="contained" className={styles.button} {...props}>
      <SvgIcon aria-hidden="true" className={styles.icon}>
        <use xlinkHref={SmileIcon} />
      </SvgIcon>
      {label}
    </Button>
  )
}

FloatingFeedbackButton.propTypes = {
  label: PropTypes.string,
}

FloatingFeedbackButton.defaultProps = {
  label: 'Avaliar a experiência',
}

export default React.memo(FloatingFeedbackButton)

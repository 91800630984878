import HousePlusIcon from '_assets/images/ic_house_plus.svg'
import MoneyIcon from '_assets/images/ic-money.svg'
import { PARTICIPANTS_TYPE } from '_utils/constants'

export const HOUSE_IMAGE = 'houseImage'
export const IMAGE = 'image'
export const NEW = 'new'
export const INVITE = 'invite'
export const NO = 'no'

export const CREDIT_ANALYSIS_NEGOTIATION = {
  [PARTICIPANTS_TYPE.TENANT]: {
    style: HOUSE_IMAGE,
    icon: HousePlusIcon,
    title: 'Adicionar moradores',
    subtitle: 'Moradores são pessoas que estarão no contrato mas não comprovam renda',
    question: 'Deseja adicionar moradores?',
    questionDescription:
      'Ao cadastrá-los, você insere dados e documentos dos responsáveis. Ao convidá-los, você informa o e-mail e telefone deles para que eles façam o próprio cadastro através de um link que enviaremos no e-mail/celular.',
    options: [
      { value: NEW, label: 'Sim, quero cadastrá-lo' },
      { value: INVITE, label: 'Sim, vou convidá-lo' },
      { value: NO, label: 'Não preciso adicionar mais moradores' },
    ],
  },
  [PARTICIPANTS_TYPE.GUARANTOR]: {
    style: IMAGE,
    icon: MoneyIcon,
    title: 'Adicionar fiador',
    subtitle:
      'Os fiadores são responsáveis pelo pagamento da dívida, caso o locatário principal não consiga cumprir com seu compromisso.',
    question: 'Deseja adicionar fiadores?',
    questionDescription:
      'Ao cadastrá-los, você insere dados e documentos dos responsáveis. Ao convidá-los, você informa o e-mail e telefone deles para que eles façam o próprio cadastro através de um link que enviaremos no e-mail/celular.',
    options: [
      { value: NEW, label: 'Sim, quero cadastrá-lo' },
      { value: INVITE, label: 'Sim, vou convidá-lo' },
    ],
  },
  [PARTICIPANTS_TYPE.INCOME_HELPER]: {
    style: IMAGE,
    icon: MoneyIcon,
    title: 'Adicionar responsáveis pelo pagamento',
    subtitle: 'Os responsáveis pelo pagamento comprovam renda junto com você',
    question: 'Deseja adicionar responsável?',
    questionDescription:
      'Ao cadastrá-los, você insere dados e documentos dos responsáveis. Ao convidá-los, você informa o e-mail e telefone deles para que eles façam o próprio cadastro através de um link que enviaremos no e-mail/celular.',
    options: [
      { value: NEW, label: 'Sim, quero cadastrá-lo' },
      { value: INVITE, label: 'Sim, vou convidá-lo' },
      { value: NO, label: 'Não, apenas minha renda é suficiente' },
    ],
  },
  [`${PARTICIPANTS_TYPE.INCOME_HELPER}_REQUESTED`]: {
    style: null,
    icon: null,
    title: 'Adicionar novo locatário',
    subtitle: '',
    question: 'Como você prefere adicionar um novo locatário?',
    questionDescription:
      'Ao cadastrá-los, você insere dados e documentos dos responsáveis. Ao convidá-los, você informa o e-mail e telefone deles para que eles façam o próprio cadastro através de um link que enviaremos no e-mail/celular.',
    options: [
      { value: NEW, label: 'Quero cadastrá-lo' },
      { value: INVITE, label: 'Vou convidá-lo' },
    ],
  },
}

import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import styles from './styles.css'

export const TAG_STATUS = {
  INITIAL: {
    style: 'initial',
    label: '',
  },
  PENDING: {
    style: 'pending',
    label: 'Resposta pendente',
  },
  APPROVED: {
    style: 'approved',
    label: 'Proposta aceita',
  },
  REJECTED: {
    style: 'rejected',
    label: 'Proposta recusada',
  },
}

const ProposalTag = ({ status }) => {
  return (
    status !== TAG_STATUS.INITIAL && (
      <p className={classnames(styles.tag, styles[status.style])}>{status.label}</p>
    )
  )
}

ProposalTag.propTypes = {
  status: PropTypes.string.isRequired,
}

export default React.memo(ProposalTag)

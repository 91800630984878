import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'inherit',
  },
  content: {
    display: 'grid',
    width: '51.2rem',
    margin: '4.8rem auto 8rem',
    padding: '4rem 2.4rem 2.4rem',
    boxShadow: '0 0.3rem 0.6rem rgba(0, 0, 0, 0.16)',
    borderRadius: '0.4rem',
    backgroundColor: 'white',
    [theme.breakpoints.down('sm')]: {
      width: '31.2rem',
      margin: '3.2rem auto 4.8rem',
      padding: 0,
      boxShadow: 'none',
    },
  },
  paper: {
    [theme.breakpoints.down('sm')]: {
      width: '31.2rem',
      padding: '2.4rem 1rem 2.4rem',
      boxShadow: '0 0.2rem 0.4rem rgba(0, 0, 0, 0.16)',
      borderRadius: '0.8rem',
      backgroundColor: 'white',
      marginTop: '1.6rem',
    },
  },
  title: {
    marginBottom: '0.8rem',
  },
  subtitle: {
    fontWeight: 'bold',
    lineHeight: '2.1rem',
    margin: '2.4rem 0 1.6rem',
    [theme.breakpoints.down('sm')]: {
      margin: '0 0 1.6rem',
    },
  },
  extraMargin: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '2.4rem',
    },
  },
  mobileOnly: {
    marginTop: '2.4rem',
    fontWeight: 'bold',
    lineHeight: '2.1rem',
  },
  button: {
    minWidth: '9.2rem',
    height: '4rem',
    padding: '0.8rem 1.6rem',
    marginRight: '0.8rem',
    fontSize: '1.4rem',
    fontWeight: 'normal',
    lineHeight: '2.4rem',
    '&:hover': {
      color: 'white',
      backgroundColor: theme.palette.primary.main,
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: '8.4rem',
    },
  },
  buttonCancel: {
    color: theme.palette.secondary.main,
    fontWeight: 500,
    '&:hover': {
      color: theme.palette.secondary.main,
      backgroundColor: theme.palette.gray.gallery,
    },
  },
  buttonNext: {
    fontWeight: 500,
    '&:hover': {
      color: 'white',
      backgroundColor: theme.palette.primary.main,
    },
  },
  defaultButton: {
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.gray.gallery,
  },
  numberButton: {
    minWidth: '4.2rem',
    height: '4rem',
    marginRight: '0.8rem',
    padding: 0,
    '&:hover': {
      color: 'white',
      backgroundColor: theme.palette.primary.main,
    },
    '& .MuiButton-label': {
      maxWidth: '1.6rem',
      margin: 0,
      padding: 0,
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '4.2rem',
    },
  },
  buttonListFinish: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '1.6rem',
  },
}))

import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
  dialog: {
    width: '100%',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      '& .MuiDialog-paper': {
        margin: 0,
        borderRadius: 30,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        position: 'absolute',
        bottom: 0,
      },
    },
  },
  content: {
    width: '40.8rem',
    minHeight: '41.6rem',
    padding: '1.6rem',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '2.4rem 2.4rem 3.2rem',
    },
  },
  closeContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    height: '3.2rem',
  },
  main: {
    display: 'grid',
    justifyItems: 'center',
    padding: '0 4.8rem',
    [theme.breakpoints.down('sm')]: {
      padding: '0 1.6rem',
    },
  },
  imageBackground: {
    display: 'flex',
    width: '7rem',
    height: '7rem',
    backgroundColor: theme.palette.custom.zircon,
    borderRadius: '50%',
    margin: '1.4rem 0 3.2rem',
  },
  image: {
    width: '110%',
    height: '110%',
    margin: '0 -5% 0',
  },
  title: {
    marginBottom: '2.4rem',
  },
  secondaryButton: {
    height: '4rem',
    backgroundColor: theme.palette.gray.gallery,
    borderColor: theme.palette.gray.default,
    color: theme.palette.secondary.dark,
    marginBottom: '0.8rem',
    '&:hover': {
      color: theme.palette.secondary.dark,
    },
  },
  selected: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
  },
  button: {
    height: '4rem',
    marginTop: '2.4rem',
  },
}))

import { Record } from 'immutable'
import { Cookies } from 'react-cookie'

import { createReducer } from '_utils/redux'
import { ListingRegistration } from '_models/'

import {
  CREATE_LISTING_REGISTRATION,
  UPDATE_LISTING_REGISTRATION,
  GET_PRICE_PREDICTION,
  GET_LISTING_PICTURES,
  GET_LISTING_REGISTRATION,
  UPDATE_CURRENT_PAGE,
  ADD_USER_TOKEN,
  REMOVE_CURRENT_PAGE,
  REMOVE_LISTING_REGISTRATION,
  UPLOAD_LISTING_PICTURES,
} from './actions'

const cookies = new Cookies()
const INITIAL_STATE = Record({
  listing: new ListingRegistration({
    ...cookies.get('listingRegistration'),
  }),
  currentPage: parseInt(cookies.get('currentPage'), 10) || 0,
  userToken: cookies.get('userToken', { path: '/anunciar-imovel' }),
  currentId: cookies.get('listingRegistration') && cookies.get('listingRegistration').id,
})()
const listingRegistrationForm = createReducer(INITIAL_STATE, {
  [CREATE_LISTING_REGISTRATION.FULFILLED]: (state, { payload }) => {
    const { externalId, ...values } = payload
    cookies.set('listingRegistration', JSON.stringify(values), {
      path: '/anunciar-imovel',
    })
    return state.setIn(['listing'], new ListingRegistration(values)).set('currentId', payload.id)
  },
  [UPDATE_LISTING_REGISTRATION.FULFILLED]: (state, { payload }) => {
    const { externalId, ...values } = payload
    const jsonPayload = JSON.stringify(values)
    const valueToStringPayload = JSON.parse(jsonPayload, (_, value) =>
      typeof value !== 'object' && value !== null ? String(value) : value
    )
    cookies.set('listingRegistration', valueToStringPayload, {
      path: '/anunciar-imovel',
    })
    return state.set('listing', state.get('listing').merge(new ListingRegistration(values)))
  },
  [UPDATE_CURRENT_PAGE]: (state, { payload }) => {
    const { currentPage } = payload
    if (currentPage <= 5) {
      cookies.set('currentPage', currentPage, {
        path: '/anunciar-imovel',
      })
      return state.set('currentPage', currentPage)
    }
    cookies.set('currentPage', 0, {
      path: '/anunciar-imovel',
    })
    return state.set('currentPage', 0)
  },
  [ADD_USER_TOKEN]: (state, { payload }) => {
    const { userToken } = payload
    cookies.set('userToken', userToken, {
      path: '/anunciar-imovel',
    })
    return state.set('userToken', userToken)
  },
  [GET_LISTING_PICTURES.FULFILLED]: (state, { payload }) => {
    return state.setIn(['listing', 'convertedPictures'], payload)
  },
  [GET_LISTING_REGISTRATION.FULFILLED]: (state, { payload }) => {
    const { externalId, ...values } = payload
    const jsonPayload = JSON.stringify(values)
    const valueToStringPayload = JSON.parse(jsonPayload, (_, value) =>
      typeof value !== 'object' && value !== null ? String(value) : value
    )
    cookies.set('listingRegistration', valueToStringPayload, {
      path: '/anunciar-imovel',
    })
    return state.setIn(['listing'], new ListingRegistration(values)).set('currentId', values.id)
  },
  [GET_PRICE_PREDICTION.FULFILLED]: (state, { payload }) => {
    return state.set('listing', state.get('listing').merge(payload))
  },
  [REMOVE_LISTING_REGISTRATION]: () => {
    cookies.remove('listingRegistration', { path: '/anunciar-imovel' })
    cookies.remove('userToken', { path: '/anunciar-imovel' })
    return INITIAL_STATE
  },
  [REMOVE_CURRENT_PAGE]: state => {
    cookies.remove('currentPage', { path: '/anunciar-imovel' })
    return state.set('currentPage', 0)
  },
  [UPLOAD_LISTING_PICTURES.FULFILLED]: (state, { payload }) => {
    return state
      .setIn(['listing'], payload.externalId)
      .setIn(['results', String(payload.id)], new ListingRegistration(payload))
  },
})

export default listingRegistrationForm

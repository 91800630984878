import React from 'react'
import PropTypes from 'prop-types'
import { ArrowForwardIos as ArrowForwardIcon } from '@material-ui/icons'
import { Fab } from '@material-ui/core'

import useStyles from './styles'

const NextArrow = ({ onClick }) => {
  const styles = useStyles()

  return (
    <Fab aria-label="Próximo imóvel" className={styles.button} onClick={onClick}>
      <ArrowForwardIcon className={styles.arrow} />
    </Fab>
  )
}

NextArrow.propTypes = {
  onClick: PropTypes.func,
}

NextArrow.defaultProps = {
  onClick: () => {},
}

export default NextArrow

import React, { useState, useCallback, useContext, useEffect } from 'react'
import { Grid, Typography, Paper } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { navigate } from '@reach/router'

import TextField from '_components/material/textfield'
import Button from '_components/material/button'
import TokenContext from '_context/token'
import { PARTICIPANTS_TYPE } from '_utils/constants'
import {
  inviteParticipants,
  INVITE_PARTICIPANTS,
  UPDATE_REGISTERED_PARTICIPANT,
  updateRegisteredParticipant,
} from '_modules/leases/actions'
import useOnSuccessCall from '_hooks/use-on-success'

import { CREDIT_ANALYSIS_ROUTES, creditAnalysisFlow } from '../../router'

import useStyles from './styles'
import {
  CREDIT_ANALYSIS_NEGOTIATION_INVITE,
  INPUTS,
  INITIAL_STATE,
  validation,
  parsePayload,
} from './constants'

const CreditAnalysisNegotiationInvite = () => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const [state, setState] = useState(INITIAL_STATE)
  const { participantType, leaseId, currentParticipant, setParticipant } = useContext(TokenContext)
  const content = CREDIT_ANALYSIS_NEGOTIATION_INVITE[participantType]
  const participantId = currentParticipant && currentParticipant.id

  const handleOnChange = useCallback(({ target: { name, value } }) => {
    setState(prevState => ({ ...prevState, [name]: value }))
  }, [])
  const handleOnSubmit = useCallback(
    event => {
      event.preventDefault()
      const { error, ...values } = state
      const currentErrors = validation(values)
      if (Object.keys(currentErrors).length) {
        setState(prevState => ({ ...prevState, error: currentErrors }))
        return
      }
      if (participantId) {
        dispatch(
          updateRegisteredParticipant(participantId, {
            ...parsePayload(values),
            category: participantType,
            isInvited: true,
          })
        )
        return
      }
      dispatch(
        inviteParticipants(leaseId, [{ ...parsePayload(values), category: participantType }])
      )
    },
    [dispatch, leaseId, participantId, participantType, state]
  )

  const handleSuccess = useCallback(() => {
    setParticipant({})
    if (participantType === PARTICIPANTS_TYPE.INCOME_HELPER) {
      navigate(creditAnalysisFlow(CREDIT_ANALYSIS_ROUTES.INCOME_HELPERS, leaseId), {
        state: { participantType },
      })
      return
    }
    if (participantType === PARTICIPANTS_TYPE.GUARANTOR) {
      navigate(creditAnalysisFlow(CREDIT_ANALYSIS_ROUTES.GUARANTORS, leaseId), {
        state: { participantType },
      })
      return
    }
    navigate(creditAnalysisFlow(CREDIT_ANALYSIS_ROUTES.TENANTS, leaseId), {
      state: { participantType },
    })
  }, [leaseId, participantType, setParticipant])

  const [isLoading] = useOnSuccessCall(
    participantId ? UPDATE_REGISTERED_PARTICIPANT.ACTION : INVITE_PARTICIPANTS.ACTION,
    handleSuccess
  )

  useEffect(() => {
    if (currentParticipant && currentParticipant.id && !state.fullName) {
      const participant = { ...INITIAL_STATE }
      Object.keys(INITIAL_STATE).map(key => {
        participant[key] = currentParticipant[key]
        return null
      })
      setState(participant)
    }
  }, [currentParticipant, state.fullName])

  return (
    <Grid className={styles.wrapper}>
      <Grid container onSubmit={handleOnSubmit} spacing={2} alignItems="center" component="form">
        <Grid item xs={12}>
          <Paper className={styles.formWrapper}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12}>
                <Grid className={styles.addInformation}>
                  <Grid>
                    <Typography
                      component="h1"
                      variant="h4"
                      color="textPrimary"
                      className={styles.infoTitle}
                    >
                      {content.title}
                    </Typography>
                    <Typography
                      component="p"
                      variant="body2"
                      color="textSecondary"
                      className={styles.description}
                    >
                      {content.description}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {INPUTS.map(({ value, label, mask }) => (
                <Grid item xs={12} key={value}>
                  <TextField
                    label={label}
                    value={state[value]}
                    onChange={handleOnChange}
                    name={value}
                    mask={mask}
                    error={state.error && !!state.error[value]}
                    helperText={state.error && state.error[value]}
                  />
                </Grid>
              ))}
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} className={styles.buttonWrapper}>
          <Button isLoading={isLoading} type="submit" variant="contained" className={styles.button}>
            Salvar
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}
export default React.memo(CreditAnalysisNegotiationInvite)

import { createSelector } from 'reselect'
import { List, Map } from 'immutable'

import { BOOK_PROPERTY, GET_PROPERTY, LIKE_PROPERTY } from '_modules/property/actions'
import { GET_PROPERTIES } from '_modules/search/actions'

export const getProperties = state => state.properties
const getSearchResults = state => state.search.results
const getSearchExactResults = state => state.search.exactResults
const getSearchPartialResults = state => state.search.partialResults
const getParam = (state, param) => param
export const getIsLoadingProperty = ({ loading }) => !!loading.get(GET_PROPERTY.ACTION)
export const checkIsLoadingProperties = ({ loading }) => !!loading.get(GET_PROPERTIES.ACTION)
export const getLikingProperty = ({ loading }) => !!loading.get(LIKE_PROPERTY.ACTION)
export const hasLikedPropertyError = ({ error }) => !!error.get(LIKE_PROPERTY.ACTION, Map()).size
export const getPropertyById = createSelector(
  [getProperties, getParam],
  (properties, propertyId) => {
    const property = properties.get(propertyId)
    if (!property) return undefined
    return property
  }
)

export const getRelatedProperties = createSelector(
  [getProperties, getSearchResults, getSearchExactResults, getSearchPartialResults, getParam],
  (properties, searchResults, exactResults, partialResults, propertyId) => {
    const allResults = exactResults.concat(partialResults)
    if (searchResults.includes(propertyId)) {
      const index = searchResults.indexOf(propertyId)
      const list = searchResults.slice(index + 1)
      return list.map(id => properties.get(id))
    }
    if (allResults.includes(propertyId)) {
      const index = allResults.indexOf(propertyId)
      const list = allResults.slice(index + 1)
      return list.map(id => properties.get(id))
    }
    return List()
  }
)

export const checkIsLoadingProperty = createSelector([getIsLoadingProperty], isLoading => isLoading)
export const getPropertyError = ({ error }) => error.get(GET_PROPERTY.ACTION) || Map()
export const getPropertiesErrorSelector = ({ error }) => error.get(GET_PROPERTIES.ACTION, Map())

export const getBookingLoading = ({ loading }) => !!loading.get(BOOK_PROPERTY.ACTION)
export const getBookingError = ({ error }) => error.get(BOOK_PROPERTY.ACTION) || Map()

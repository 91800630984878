import React from 'react'
import PropTypes from 'prop-types'
import AriaModal from 'react-aria-modal'

import DislikeIcon from '_assets/images/ic-naogostei.svg'
import CloseIcon from '_assets/images/ic-fechar.svg'
import Button, { ButtonColor, ButtonVariant } from '_components/button'

import styles from './styles.css'

const onMouseDown = event => {
  event.preventDefault()
}

const DislikeModal = ({ closeModal, okAction, primaryColor }) => (
  <AriaModal titleText="Agendamento de visita" underlayClass={styles.underlay}>
    <div className={styles.modal}>
      <button className={styles.close} onClick={closeModal} onMouseDown={onMouseDown} type="button">
        <svg alt="close" className={styles.image}>
          <use xlinkHref={CloseIcon} />
        </svg>
      </button>
      <svg
        aria-label="imagem não gostei"
        role="img"
        className={styles.icon}
        style={{ fill: primaryColor }}
      >
        <use xlinkHref={DislikeIcon} />
      </svg>
      <h3 className={styles.title} style={{ color: primaryColor }}>
        Não gostei
      </h3>
      <p className={styles.info}>
        Essa ação indica que você não está interessado nesse imóvel. Ele não aparecerá nas suas
        pesquisas, mas estará disponível nas suas Listas dentro do menu.
      </p>
      <Button
        color={ButtonColor.PRIMARY}
        variant={ButtonVariant.BOLD}
        onClick={closeModal}
        className={styles.cancel}
      >
        CANCELAR
      </Button>
      <Button
        color={ButtonColor.BLACK_WHITE}
        variant={ButtonVariant.BOLD}
        onClick={okAction}
        className={styles.ok}
      >
        OK
      </Button>
    </div>
  </AriaModal>
)

DislikeModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  okAction: PropTypes.func.isRequired,
  primaryColor: PropTypes.func.isRequired,
}

export default DislikeModal

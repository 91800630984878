import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import RentDetail from '_components/rent-detail'
import Button, { ButtonColor, ButtonFormat, ButtonVariant } from '_components/button'
import DislikeIcon from '_assets/images/trash-alt-solid.svg'
import LoveIcon from '_assets/images/ic-gostei.svg'
import { DEFAULT_VALUE_PRICE, FEATURE_FLAGS } from '_utils/constants'
import { Booking } from '_models/'

import styles from './styles.css'

const RentSection = ({
  className,
  title,
  rentPrice,
  condominium,
  fee,
  insurance,
  garbageFee,
  totalValue,
  onDislikeClick,
  onScheduleClick,
  onLikeClick,
  isLikedProperty,
  isDisLikedProperty,
  makeProposalUrl,
  booking,
}) => (
  <div className={classnames(className, styles['price-section'])}>
    <h3>{title}</h3>
    <div className={styles.container}>
      <RentDetail
        rentPrice={rentPrice}
        condominium={condominium}
        fee={fee}
        insurance={insurance}
        garbageFee={garbageFee}
        totalValue={totalValue}
        className={styles['rent-detail']}
      />
      {booking && booking.get('id') && FEATURE_FLAGS.contractEnabled ? (
        <Button
          color={ButtonColor.PRIMARY}
          variant={ButtonVariant.BOLD}
          className={styles['close-deal-button']}
          to={makeProposalUrl}
        >
          {FEATURE_FLAGS.productVersionOneEnabled ? 'REAGENDAR VISITA' : 'FAZER PROPOSTA'}
        </Button>
      ) : (
        <div className={styles.buttons}>
          <Button
            color={isLikedProperty ? ButtonColor.TOGGLE_UNSELECTED : ButtonColor.TOGGLE_SELECTED}
            format={ButtonFormat.ICON}
            onClick={onDislikeClick}
            ariaLabel="Botão não gostei do imóvel"
          >
            <svg aria-hidden="true" className={styles.dislike}>
              <use xlinkHref={DislikeIcon} />
            </svg>
          </Button>
          <Button
            color={ButtonColor.PRIMARY}
            variant={ButtonVariant.BOLD}
            format={ButtonFormat.ROUNDED}
            onClick={onScheduleClick}
          >
            AGENDAR VISITA
          </Button>
          <Button
            color={isDisLikedProperty ? ButtonColor.TOGGLE_UNSELECTED : ButtonColor.TOGGLE_SELECTED}
            format={ButtonFormat.ICON}
            onClick={onLikeClick}
            ariaLabel="Botão gostei do imóvel"
          >
            <svg aria-hidden="true" className={styles.like}>
              <use xlinkHref={LoveIcon} />
            </svg>
          </Button>
        </div>
      )}
    </div>
  </div>
)

RentSection.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  rentPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  condominium: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fee: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  insurance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  garbageFee: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  totalValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onDislikeClick: PropTypes.func,
  onScheduleClick: PropTypes.func,
  onLikeClick: PropTypes.func,
  isLikedProperty: PropTypes.bool,
  isDisLikedProperty: PropTypes.bool,
  booking: PropTypes.instanceOf(Booking),
  makeProposalUrl: PropTypes.string.isRequired,
}

RentSection.defaultProps = {
  className: '',
  rentPrice: DEFAULT_VALUE_PRICE,
  condominium: '',
  fee: '',
  insurance: '',
  totalValue: '',
  garbageFee: '',
  onDislikeClick: () => {},
  onScheduleClick: () => {},
  onLikeClick: () => {},
  isLikedProperty: false,
  isDisLikedProperty: false,
  booking: new Booking(),
}

export default RentSection

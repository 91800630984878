import { makeStyles } from '@material-ui/styles'

import { HOUSE_IMAGE, IMAGE } from './constants'

export default makeStyles(theme => ({
  wrapper: {
    maxWidth: '51.2rem',
    margin: 'auto',
    boxSizing: 'content-box',
    padding: '8rem 2.4rem 2.4rem',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '4rem',
    },
  },
  addInformation: {
    gap: '1.6rem',
    width: '100%',
    display: 'grid',
    alignItems: 'center',
    gridAutoFlow: 'column',
    justifyContent: 'flex-start',
    backgroundColor: theme.palette.gray.main,
    padding: '1.6rem 2.4rem',
    borderRadius: '0.4rem',
    [theme.breakpoints.down('md')]: {
      padding: '1.6rem 1rem',
    },
  },
  infoTitle: {
    marginBottom: '0.8rem',
  },
  margins: {
    margin: '0 1.6rem',
    [theme.breakpoints.down('md')]: {
      margin: 0,
    },
  },
  option: {
    width: 'unset',
  },
  analysisWrapper: {
    gap: '1rem',
    width: '100%',
    display: 'grid',
    alignItems: 'center',
    gridAutoFlow: 'column',
    justifyContent: 'flex-start',
    backgroundColor: theme.palette.gray.gallery,
    padding: '1.8rem 1.7rem',
    borderRadius: '0.4rem',
  },
  checkIcon: {
    width: '2rem',
    height: '2rem',
  },
  [HOUSE_IMAGE]: {
    width: '5.6rem',
    height: '3rem',
  },
  [IMAGE]: {
    width: '4rem',
    height: '4rem',
  },
  bold: {
    fontWeight: 700,
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    color: 'white',
    height: '4rem',
    width: '14.4rem',
    fontSize: '1.4rem',
    marginTop: '2.4rem',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  desktop: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}))

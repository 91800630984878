import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form'
import { withStyles } from '@material-ui/styles'
import FormControl from '@material-ui/core/FormControl'
import MUIFormControlLabel from '@material-ui/core/FormControlLabel'
import MUISwitch from '@material-ui/core/Switch'

import { shadeColor, primaryColor } from '_utils/colors'

const ToggleSwitch = ({ className, label, name }) => {
  const { register } = useFormContext()

  const lighterColor = shadeColor(primaryColor)

  const StyledSwitch = useMemo(
    () =>
      withStyles(theme => ({
        switchBase: {
          color: theme.palette.gray.light,

          '& + $track': {
            backgroundColor: theme.palette.gray.opaque,
          },

          '&$checked': {
            color: primaryColor,
          },

          '&$checked + $track': {
            backgroundColor: lighterColor,
          },
        },
        checked: {},
        track: {},
      }))(MUISwitch),
    [lighterColor]
  )

  const FormControlLabel = useMemo(
    () =>
      withStyles(theme => ({
        root: {
          marginLeft: '0',
        },
        label: {
          color: theme.palette.secondary.dark,
          fontSize: '1.6rem',
          fontWeight: 'bold',
          lineHeight: '2.4rem',
          marginRight: '4.8rem',
        },
      }))(MUIFormControlLabel),
    []
  )

  const Switch = useMemo(() => <StyledSwitch inputRef={register} name={name} />, [name, register])

  return (
    <FormControl className={className}>
      <FormControlLabel control={Switch} label={label} labelPlacement="start" />
    </FormControl>
  )
}

ToggleSwitch.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
}

ToggleSwitch.defaultProps = {
  className: '',
  name: '',
  label: '',
}

export default React.memo(ToggleSwitch)

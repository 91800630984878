import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { Redirect, navigate } from '@reach/router'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'

import { createLeaseZap } from '_modules/leases/actions'
import {
  getCreatingLeaseZap,
  getCreatingLeaseZapError,
  getLeaseByListingId,
} from '_modules/leases/selectors'
import { getUserInfoSelector } from '_modules/user/selectors'
import Button, { ButtonColor, ButtonFormat } from '_components/button'
import { usePrevious } from '_utils/hooks'
import Input from '_components/input'
import { validationCPF } from '_utils/helpers'
import { EMAIL_REGEX } from '_utils/constants'
import { formatCpf } from '_utils/strings'
import { primaryColor } from '_utils/colors'
import Snackbar from '_components/snackbar'

import FormIncomeHelper from './formIncomeHelper'
import styles from './styles.css'

const CPF_LENGTH = 14

const ZapMonthlyIncome = ({ location }) => {
  const dispatch = useDispatch()
  const user = useSelector(getUserInfoSelector)

  const externalId = useMemo(() => (location.state ? location.state.externalId : null), [
    location.state,
  ])
  const lease = useSelector(state => getLeaseByListingId(state, externalId))

  const isCreatingLease = useSelector(getCreatingLeaseZap)
  const creatingLeaseError = useSelector(getCreatingLeaseZapError)

  const [values, setValues] = useState([])

  const [isOwnerMonthlyIncome, setOwnerMonthlyIncome] = useState()

  const [ownerMonthlyIncome, updateOwnerMonthlyIncome] = useState({ monthlyIncome: '' })
  const [isAlertOpen, setAlertOpen] = useState(false)

  const onChangeOwnerMonthlyIncome = useCallback(event => {
    const { name, value } = event.target

    updateOwnerMonthlyIncome({ [name]: value })
  }, [])

  const onAddMoreIncomeHelper = useCallback(() => {
    const newIncomeHelper = {
      id: values.length + 1,
      name: '',
      email: '',
      cpf: '',
      emailError: false,
      cpfError: false,
      monthlyIncome: '',
      ddd: '',
      phoneNumber: '',
    }
    setValues(prevValues => [...prevValues, newIncomeHelper])
  }, [values])

  const onChooseTenantResponsible = useCallback(event => {
    const { value } = event.currentTarget

    setOwnerMonthlyIncome(value)
    if (value === '2') {
      updateOwnerMonthlyIncome({ monthlyIncome: '' })
    }
  }, [])

  const wasCreatingLease = usePrevious(isCreatingLease)

  useEffect(() => {
    if (wasCreatingLease && !isCreatingLease && !creatingLeaseError) {
      navigate(`/minhas-negociacoes/${lease.get('id')}/documentacao`)
    }
  }, [creatingLeaseError, externalId, isCreatingLease, lease, wasCreatingLease])
  const handleValues = useCallback(
    (event, id) => {
      const { name, value } = event.target
      const newValues = values.map(helper => {
        if (helper.id === id) {
          return { ...helper, [name]: value, [`${name}Error`]: false }
        }
        return helper
      })
      setValues(newValues)
    },
    [values]
  )

  const deleteHelper = useCallback(
    id => {
      const newValues = values.filter(helper => helper.id !== id)

      setValues(newValues)
    },
    [values]
  )

  const validateEmail = useCallback(
    (event, id) => {
      const emailRegex = EMAIL_REGEX
      const { value } = event.target
      const emailToCheck = value || ''
      const newValues = values.map(helper => {
        if (helper.id === id) {
          return {
            ...helper,
            emailError: !emailRegex.test(emailToCheck) || emailToCheck === user.email,
          }
        }
        return { ...helper, emailError: false }
      })
      setValues(newValues)
    },
    [user.email, values]
  )

  const validateCPF = useCallback(
    (event, id) => {
      const { value } = event.target

      const validationResult = value
        ? !validationCPF(value.replace(/\./g, '').replace(/-/g, ''))
        : false
      const checkDuplicatedCpf =
        value.length === CPF_LENGTH &&
        (values.filter(allValues => allValues.cpf === value).length > 1 ||
          user.cpf === formatCpf(value))
      const newValues = values.map(helper => {
        if (helper.id === id) {
          return { ...helper, cpfError: validationResult || checkDuplicatedCpf }
        }
        return helper
      })
      setAlertOpen(checkDuplicatedCpf)
      setValues(newValues)
    },
    [user.cpf, values]
  )

  const onContinueClick = useCallback(() => {
    const valuesPayload = values.map(helper => ({
      id: helper.id,
      name: helper.name,
      email: helper.email,
      cpf: formatCpf(helper.cpf),
      monthlyIncome: Number(
        helper.monthlyIncome
          .replace(/^\D+/g, '')
          .split(',', 1)[0]
          .replace('.', '')
      ),
      ddd: helper.ddd.replace(/["'()]/g, ''),
      phoneNumber: helper.phoneNumber.replace('-', ''),
    }))

    const monthlyIncomePayload = Number(
      ownerMonthlyIncome.monthlyIncome
        .replace(/^\D+/g, '')
        .split(',', 1)[0]
        .replace('.', '')
    )

    const payload = {
      listing: externalId,
      monthlyIncome: monthlyIncomePayload,
      incomeHelpers: valuesPayload || '',
    }
    dispatch(createLeaseZap(payload))
  }, [dispatch, externalId, ownerMonthlyIncome.monthlyIncome, values])

  // Set input of currency
  const [isCurrency, setIsCurrency] = useState(false)
  const setInputCurrency = useCallback(() => {
    setIsCurrency(true)
  }, [])

  // Handle focus of input currency
  const [isAutoFocus, disableAutoFocus] = useState(true)

  const setDisableAutoFocus = useCallback(() => {
    disableAutoFocus(false)
  }, [])

  const isNextDisabled = useMemo(() => {
    const incompleteHelpers = values.filter(helper => {
      return (
        !helper.name ||
        !helper.email ||
        !helper.cpf ||
        !helper.monthlyIncome ||
        (isOwnerMonthlyIncome === '1' && !ownerMonthlyIncome.monthlyIncome) ||
        helper.emailError ||
        helper.cpfError
      )
    })

    return (
      !!incompleteHelpers.length ||
      (values.length === 0 && isOwnerMonthlyIncome === '2') ||
      (isOwnerMonthlyIncome === '1' &&
        (!ownerMonthlyIncome.monthlyIncome ||
          !parseInt(
            ownerMonthlyIncome.monthlyIncome
              .replace('R$ ', '')
              .replace('.', '')
              .replace(',', ''),
            10
          ))) ||
      !isOwnerMonthlyIncome
    )
  }, [isOwnerMonthlyIncome, ownerMonthlyIncome.monthlyIncome, values])

  if (!user.authToken) {
    return <Redirect to="/" noThrow />
  }
  if (!externalId) {
    return <Redirect to="/minhas-visitas" noThrow />
  }

  return (
    <div className={styles.main}>
      <div className={styles.card}>
        <h1>Análise de crédito</h1>
        <p className={styles.subtitle}>Você será responsável pela comprovação de renda?</p>
        <div className={styles.actions}>
          <Button
            format={ButtonFormat.ROUNDED}
            onClick={onChooseTenantResponsible}
            className={styles['button-top']}
            active={isOwnerMonthlyIncome === '1'}
            value={1}
          >
            SIM
          </Button>
          <Button
            format={ButtonFormat.ROUNDED}
            onClick={onChooseTenantResponsible}
            className={styles['button-top']}
            active={isOwnerMonthlyIncome === '2'}
            value={2}
          >
            NÃO
          </Button>
        </div>
        {isOwnerMonthlyIncome === '1' && (
          <div>
            <div className={styles.line} />
            <p className={styles.income}>Qual a sua renda?</p>
            <Input
              className={styles.input}
              label="Renda / Salário"
              value={ownerMonthlyIncome.monthlyIncome}
              onChange={onChangeOwnerMonthlyIncome}
              labelClassName={styles.label}
              ariaLabel="Entrada de texto para renda mensal"
              primaryColor={primaryColor}
              name="monthlyIncome"
              isCurrency={isCurrency}
              onClick={setInputCurrency}
              id={`${'monthlyIncome'}`}
              currencyAutoFocus={isAutoFocus}
              onBlur={setDisableAutoFocus}
            />
          </div>
        )}
        <p className={styles.subtitle}>Convide outras pessoas para lhe ajudar a comprovar renda</p>
        <div className={styles.line} />
        {values.map((helper, index) => (
          <FormIncomeHelper
            values={helper}
            handleValues={handleValues}
            validateEmail={validateEmail}
            validateCPF={validateCPF}
            primaryColor={primaryColor}
            key={helper.id}
            index={index + 1}
            id={helper.id}
            deleteHelper={deleteHelper}
          />
        ))}
        <Button
          format={ButtonFormat.SQUARED}
          onClick={onAddMoreIncomeHelper}
          className={styles['button-plus']}
        >
          + Adicionar mais
        </Button>
        <Button
          color={ButtonColor.PRIMARY}
          format={ButtonFormat.ROUNDED}
          onClick={onContinueClick}
          className={styles['button-next']}
          isLoading={isCreatingLease}
          disabled={isNextDisabled}
        >
          PRÓXIMO
        </Button>
        <p className={styles.terms}>
          Ao confirmar você concorda com os
          <a href="/termos-de-servico" target="_blank" style={{ color: primaryColor }}>
            Termos de serviço
          </a>{' '}
          e
          <a href="/politica-de-privacidade" target="_blank" style={{ color: primaryColor }}>
            Política de Privacidade
          </a>
        </p>
        <Snackbar
          isLocalDispatch={isAlertOpen}
          message="Você não pode conter participantes com o mesmo CPF na sua negociação"
          variant="error"
        />
      </div>
    </div>
  )
}

ZapMonthlyIncome.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      redirectTo: PropTypes.string,
      externalId: PropTypes.string,
    }),
  }).isRequired,
}

export default React.memo(ZapMonthlyIncome)

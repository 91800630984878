import React from 'react'

import ImageClickMobile from '_assets/images/mobile-click-positive.svg'
import Button, { ButtonColor, ButtonVariant } from '_components/button'

import styles from './styles.css'

const ProposalSent = () => {
  return (
    <section className={styles.container}>
      <div className={styles.content}>
        <svg aria-label="Ícone de um documento" className={styles.icon}>
          <use xlinkHref={ImageClickMobile} />
        </svg>
        <h1 className={styles.title}>Proposta enviada ao proprietário!</h1>
        <p className={styles['sub-title']}>
          Em alguns dias você terá a resposta. Acompanhe o andamento no menu em{' '}
          <strong>Negociações</strong>.
        </p>

        <Button
          className={styles.button}
          color={ButtonColor.PRIMARY}
          variant={ButtonVariant.BOLD}
          to="/minhas-negociacoes"
        >
          MINHAS NEGOCIAÇÕES
        </Button>
      </div>
    </section>
  )
}

export default React.memo(ProposalSent)

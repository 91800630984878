import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { toCurrency, getExternalId } from '_utils/helpers'
import { LABEL_RENT_PRICE, DEFAULT_VALUE_PRICE } from '_utils/constants'

import styles from './styles.css'

const RentDetails = ({ externalId, rentPrice, condominum, taxes, insurance, totalValue }) => {
  const checkRentPriceInfo = useMemo(() => {
    return toCurrency(rentPrice)
  }, [rentPrice])
  const checkCondominumInfo = useMemo(() => {
    return toCurrency(condominum)
  }, [condominum])
  const checkTaxesInfo = useMemo(() => {
    return toCurrency(taxes)
  }, [taxes])
  const checkInsuranceInfo = useMemo(() => {
    return toCurrency(insurance)
  }, [insurance])
  const checkTotalValueInfo = useMemo(() => {
    return toCurrency(totalValue)
  }, [totalValue])
  return (
    <div className={styles.container}>
      <p className={styles.title}>
        Imóvel <span className={styles.code}>{getExternalId(externalId)}</span>
      </p>
      <div className={styles.content}>
        <div className={styles['rent-info']}>
          <p className={classnames(styles.info, styles.highlight)}>{LABEL_RENT_PRICE}</p>
          <p className={classnames(styles.info, styles.highlight)}>{checkRentPriceInfo}</p>
        </div>
        <div className={styles['rent-info']}>
          <p className={styles.info}>Condomínio *</p>
          <p className={styles.info}>{checkCondominumInfo}</p>
        </div>
        <div className={styles['rent-info']}>
          <p className={styles.info}>IPTU *</p>
          <p className={styles.info}>{checkTaxesInfo}</p>
        </div>
        <div className={styles['rent-info']}>
          <p className={styles.info}>Seguro incêndio</p>
          <p className={styles.info}>{checkInsuranceInfo}</p>
        </div>
        <div className={styles['rent-info']}>
          <p className={classnames(styles.info, styles.hint)}>* O valor pode variar.</p>
        </div>
        <div className={styles['horizontal-line']} />
        <div className={styles['rent-info']}>
          <p className={classnames(styles.info, styles.highlight)}>Valor Total</p>
          <p className={classnames(styles.info, styles.highlight)}>{checkTotalValueInfo}</p>
        </div>
      </div>
    </div>
  )
}

RentDetails.propTypes = {
  externalId: PropTypes.string,
  rentPrice: PropTypes.number,
  condominum: PropTypes.number,
  taxes: PropTypes.number,
  insurance: PropTypes.number,
  totalValue: PropTypes.number,
}

RentDetails.defaultProps = {
  externalId: null,
  rentPrice: DEFAULT_VALUE_PRICE,
  condominum: DEFAULT_VALUE_PRICE,
  taxes: DEFAULT_VALUE_PRICE,
  insurance: DEFAULT_VALUE_PRICE,
  totalValue: DEFAULT_VALUE_PRICE,
}

export default React.memo(RentDetails)

import React, { useMemo, Fragment, useCallback } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { List } from 'immutable'

import Proposal from '_models/proposal'
import { COLLAPSE_MINIMUM_REQUESTS } from '_utils/constants'
import { toCurrency } from '_utils/helpers'

import styles from './styles.css'

const ProposalValue = ({ proposal, requests, fromStaff, listtingRentPrice }) => {
  const ownerRentPrice = useMemo(() => listtingRentPrice, [listtingRentPrice])
  const rentPrice = useMemo(() => proposal && proposal.getIn(['listing', 'rentPrice']), [proposal])
  const rentValueContent = useMemo(
    () => (
      <p className={styles.row}>
        <span>{rentPrice ? 'Aluguel anunciado' : 'Aluguel'}</span>
        <span>{toCurrency(rentPrice)}</span>
      </p>
    ),
    [rentPrice]
  )

  const last = requests.last()

  const handleTitleRequests = useCallback(
    price => (price.get('isStaffRequest') ? 'Contraproposta' : 'Proposta'),
    []
  )

  if (!proposal) return rentValueContent

  if (!requests) {
    return rentValueContent
  }

  const collapse = requests.size > COLLAPSE_MINIMUM_REQUESTS

  const list = collapse ? List() : requests

  if (fromStaff) {
    return (
      <Fragment>
        <p className={classnames(styles.row)}>
          <span>{rentPrice ? 'Aluguel anunciado' : 'Aluguel'}</span>
          <span>{toCurrency(rentPrice)}</span>
        </p>
        <p className={classnames(styles.row, styles.disabled)}>
          <span>Valor sugerido pelo locatário</span>
          <span>{toCurrency(proposal.get('suggestedPrice'))}</span>
        </p>
        <p className={classnames(styles.row, styles.bold)}>
          <span>Valor aceito pelo proprietário</span>
          <span>{toCurrency(proposal.get('ownerPrice'))}</span>
        </p>
      </Fragment>
    )
  }

  if (last && last.get('approved') === false && list.size) {
    return (
      <Fragment>
        <p className={classnames(styles.row)}>
          <span>{rentPrice ? 'Aluguel anunciado' : 'Aluguel'}</span>
          <span>{toCurrency(rentPrice)}</span>
        </p>
        {list.map(price => (
          <p
            className={classnames(styles.row, {
              [styles.disabled]: true,
              [styles.bold]: false,
            })}
          >
            <span>{handleTitleRequests(price)}</span>
            <span>{toCurrency(price.get('rentPrice'))}</span>
          </p>
        ))}
        <p
          className={classnames(styles.row, {
            [styles.disabled]: false,
            [styles.bold]: true,
          })}
        >
          <span>Valor Final</span>
          <span>{toCurrency(rentPrice)}</span>
        </p>
      </Fragment>
    )
  }

  return (
    <Fragment>
      <p className={classnames(styles.row)}>
        <span>{rentPrice ? 'Aluguel anunciado' : 'Aluguel'}</span>
        <span>{toCurrency(ownerRentPrice)}</span>
      </p>
      {list.map(price => (
        <p
          className={classnames(styles.row, {
            [styles.disabled]: !price.approved && price !== last,
            [styles.bold]: price === last,
          })}
        >
          <span>{handleTitleRequests(price)}</span>
          <span>{toCurrency(price.get('rentPrice'))}</span>
        </p>
      ))}
    </Fragment>
  )
}

ProposalValue.propTypes = {
  proposal: PropTypes.instanceOf(Proposal).isRequired,
  requests: PropTypes.instanceOf(List).isRequired,
  fromStaff: PropTypes.bool,
  listtingRentPrice: PropTypes.number,
}

ProposalValue.defaultProps = {
  fromStaff: false,
  listtingRentPrice: 0,
}

export default React.memo(ProposalValue)

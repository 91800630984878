import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Dialog, Grid, IconButton, SvgIcon, Typography } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import classnames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { navigate } from '@reach/router'

import { UPDATE_OCCURRENCE, updateOccurrence } from '_modules/booking/actions'
import { updateOccurrenceLoadingSelector } from '_modules/booking/selectors'
import Button from '_components/material/button'
import TwoHousesImg from '_assets/images/feedback-header.svg'
import useOnSuccessCall from '_hooks/use-on-success'

import useStyles from './styles'

const VisitOptionsModal = ({ onClose, bookingId }) => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const [happened, setHappened] = useState()
  const isLoading = useSelector(updateOccurrenceLoadingSelector)

  const handleClick = useCallback(event => {
    const { value } = event.currentTarget
    setHappened(value === 'true')
  }, [])

  const handleHappened = useCallback(() => {
    dispatch(updateOccurrence({ happened }, bookingId))
  }, [bookingId, dispatch, happened])
  const handleNextButton = useCallback(() => {
    navigate(`/feedback-visita/${bookingId}/?happened=${happened}`)
    onClose()
  }, [bookingId, happened, onClose])
  useOnSuccessCall(UPDATE_OCCURRENCE.ACTION, handleNextButton)

  return (
    <Dialog open onClose={onClose} aria-label="Avaliação da visita" className={styles.dialog}>
      <Grid className={styles.content}>
        <Grid className={styles.closeContainer}>
          <IconButton aria-label="Fechar modal" size="small" onClick={onClose}>
            <Close />
          </IconButton>
        </Grid>
        <Grid component="section" className={styles.main}>
          <Grid className={styles.imageBackground}>
            <SvgIcon aria-hidden="true" className={styles.image} viewBox={TwoHousesImg.viewBox}>
              <use xlinkHref={TwoHousesImg} />
            </SvgIcon>
          </Grid>
          <Typography component="h1" variant="h4" className={styles.title}>
            Esta visita aconteceu?
          </Typography>
          <Button
            fullWidth
            variant="outlined"
            color="secondary"
            value="true"
            className={classnames(styles.secondaryButton, {
              [styles.selected]: happened,
            })}
            onClick={handleClick}
          >
            Visitei o imóvel
          </Button>
          <Button
            fullWidth
            variant="outlined"
            color="secondary"
            value="false"
            className={classnames(styles.secondaryButton, {
              [styles.selected]: !happened && happened !== undefined,
            })}
            onClick={handleClick}
          >
            Não visitei o imóvel
          </Button>
          <Button
            fullWidth
            variant="contained"
            disableElevation
            color="primary"
            className={styles.button}
            disabled={happened === undefined}
            onClick={handleHappened}
            isLoading={isLoading}
          >
            Próximo
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  )
}

VisitOptionsModal.propTypes = {
  onClose: PropTypes.func,
  bookingId: PropTypes.string.isRequired,
}

VisitOptionsModal.defaultProps = {
  onClose: () => {},
}

export default React.memo(VisitOptionsModal)

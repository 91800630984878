/* eslint-disable import/no-cycle */
import humps from 'humps'
import moment from 'moment'

import { defineAction } from '_utils/redux'
import * as leaseService from '_services/lease'
import { createFormData, notEmptyPayload } from '_utils/helpers'
import { CIVIL_STATUS_NAMES, GENDER_NAMES, PARTICIPANTS_INFO } from '_utils/constants'
import { formatCpf, formatPhoneNumber } from '_utils/strings'
import {
  getCurrentLeaseId,
  getCurrentParticipantId,
  getCurrentExternalId,
} from '_modules/leases/selectors'

export const SUBMIT_WARRANTY = defineAction('SUBMIT_WARRANTY')
export const CREATE_LEASE = defineAction('CREATE_LEASE')
export const GET_LEASE = defineAction('GET_LEASE')
export const GET_HELPER_LEASE = defineAction('GET_HELPER_LEASE')
export const UPLOAD_DOCUMENT = defineAction('UPLOAD_DOCUMENT')
export const CREATE_INCOME_HELPER = defineAction('CREATE_INCOME_HELPER')
export const CREATE_MULTIPLE_INCOME_HELPER = defineAction('CREATE_MULTIPLE_INCOME_HELPER')
export const GET_LEASES = defineAction('GET_LEASES')
export const GET_ALL_LEASES = defineAction('GET_ALL_LEASES')
export const SEND_LEASE_TO_REVIEW = defineAction('SEND_LEASE_TO_REVIEW')
export const CANCEL_LEASE = defineAction('CANCEL_LEASE')
export const SEND_TENTANT_DATA = defineAction('SEND_TENTANT_DATA')
export const SEND_TENANT_PROFESSIONAL_DATA = defineAction('SEND_TENANT_PROFESSIONAL_DATA')
export const SEND_SPOUSE_DATA = defineAction('SEND_SPOUSE_DATA')
export const GET_CONTRACT_PREVIEW = defineAction('GET_CONTRACT_PREVIEW')
export const GENERATE_CONTRACT_LINK = defineAction('GENERATE_CONTRACT_LINK')
export const GENERATE_CONTRACT_LINK_HELPER = defineAction('GENERATE_CONTRACT_LINK_HELPER')
export const REQUEST_CONTRACT_CHANGES = defineAction('REQUEST_CONTRACT_CHANGES')
export const SEND_HELPER_DATA = defineAction('SEND_HELPER_DATA')
export const UPLOAD_HELPER_DOCUMENT = defineAction('UPLOAD_HELPER_DOCUMENT')
export const SEND_HELPER_SPOUSE_DATA = defineAction('SEND_HELPER_SPOUSE_DATA')
export const SEND_RATE = defineAction('SEND_RATE')
export const CREATE_LEASE_ZAP = defineAction('CREATE_LEASE_ZAP')
export const INVITE_PARTICIPANTS = defineAction('INVITE_PARTICIPANTS')
export const SEND_INCOME_HELPER_DATA = defineAction('SEND_INCOME_HELPER_DATA')
export const SEND_USER_DATA = defineAction('SEND_USER_DATA')
export const UPDATE_USER_DATA = defineAction('UPDATE_USER_DATA')
export const UPDATE_PARTICIPANTS = defineAction('UPDATE_PARTICIPANTS')
export const SEND_PARTICIPANTS_DATA = defineAction('SEND_PARTICIPANTS_DATA')
export const UPLOAD_PARTICIPANTS_DOCUMENT = defineAction('UPLOAD_PARTICIPANTS_DOCUMENT')
export const REGISTER_PARTICIPANT_DOCUMENT = defineAction('REGISTER_PARTICIPANT_DOCUMENT')
export const SEND_PARTICIPANTS_SPOUSE_DATA = defineAction('SEND_PARTICIPANTS_SPOUSE_DATA')
export const GET_PARTICIPANT_LEASE = defineAction('GET_PARTICIPANT_LEASE')
export const DELETE_DOCUMENT = defineAction('DELETE_DOCUMENT')
export const DELETE_PARTICIPANT_DOCUMENT = defineAction('DELETE_PARTICIPANT_DOCUMENT')
export const SEND_FIRE_INSURANCE = defineAction('SEND_FIRE_INSURANCE')
export const SEND_MONTHLY_INCOME = defineAction('SEND_MONTHLY_INCOME')
export const UPDATE_LEASE = defineAction('UPDATE_LEASE')
export const UPDATE_REGISTERED_PARTICIPANT = defineAction('UPDATE_REGISTERED_PARTICIPANT')
export const REGISTER_PARTICIPANT = defineAction('REGISTER_PARTICIPANT')
export const REGISTER_PARTICIPANT_SPOUSE = defineAction('REGISTER_PARTICIPANT_SPOUSE')
export const REGISTER_PARTICIPANT_CONFIRM = defineAction('REGISTER_PARTICIPANT_CONFIRM')
export const DELETE_PARTICIPANT = defineAction('DELETE_PARTICIPANT')
export const SUBMIT_CREDPAGO_ANALYSIS = defineAction('SUBMIT_CREDPAGO_ANALYSIS')

const camelizeResponse = response => humps.camelizeKeys(response)

const parseGender = gender => {
  if (Object.values(GENDER_NAMES).includes(gender)) return gender
  switch (gender) {
    case 'Masculino':
      return GENDER_NAMES.MALE
    case 'Feminino':
      return GENDER_NAMES.FEMALE
    case 'Outro':
      return GENDER_NAMES.OTHER
    default:
      return ''
  }
}

const parseCivilStatus = civilStatus => {
  if (Object.values(CIVIL_STATUS_NAMES).includes(civilStatus)) return civilStatus
  switch (civilStatus) {
    case 'Solteira(o)':
      return CIVIL_STATUS_NAMES.SINGLE
    case 'Casada(o)':
      return CIVIL_STATUS_NAMES.MARRIED
    case 'Divorciada(o)':
      return CIVIL_STATUS_NAMES.DIVORCED
    case 'Separada(o)':
      return CIVIL_STATUS_NAMES.SEPARATED
    case 'Viúva(o)':
      return CIVIL_STATUS_NAMES.WIDOWED
    default:
      return ''
  }
}

const parsePayload = payload => {
  const newPayload = {}
  Object.entries(notEmptyPayload(payload)).map(attribute => {
    const [key, value] = attribute
    switch (key) {
      case PARTICIPANTS_INFO.IS_TENANT: {
        if (typeof value === 'string') {
          newPayload[key] = value === 'true'
          return null
        }
        newPayload[key] = value
        return null
      }
      case PARTICIPANTS_INFO.DATE_OF_BIRTH: {
        if (value === 'Invalid date') {
          return null
        }
        newPayload[key] = moment(value, 'DD/MM/YYYY').format('YYYY-MM-DD')
        return null
      }
      case PARTICIPANTS_INFO.CIVIL_STATUS: {
        newPayload[key] = parseCivilStatus(value)
        return null
      }
      case PARTICIPANTS_INFO.GENDER: {
        newPayload[key] = parseGender(value)
        return null
      }
      case PARTICIPANTS_INFO.CPF: {
        newPayload[key] = formatCpf(value)
        return null
      }
      case PARTICIPANTS_INFO.PHONE_NUMBER: {
        newPayload[key] = formatPhoneNumber(value)
        return null
      }
      default: {
        newPayload[key] = value
        return null
      }
    }
  })
  return newPayload
}

export const createLease = payload => (dispatch, getState) =>
  dispatch({
    type: CREATE_LEASE.ACTION,
    payload: leaseService
      .createLease(getState().user.authToken)(payload)
      .then(camelizeResponse),
    meta: {
      externalId: payload.listing,
    },
  })

export const createLeaseZap = payload => (dispatch, getState) =>
  dispatch({
    type: CREATE_LEASE_ZAP.ACTION,
    payload: leaseService
      .createLeaseZap(getState().user.authToken)(payload)
      .then(camelizeResponse),
    meta: {
      externalId: payload.listing,
    },
  })

export const getLease = (leaseId, params = {}) => (dispatch, getState) =>
  dispatch({
    type: GET_LEASE.ACTION,
    payload: leaseService
      .getLease(getState().user.authToken)(leaseId, params)
      .then(camelizeResponse),
  })

export const sendMonthlyIncome = payload => (dispatch, getState) => {
  return dispatch({
    type: SEND_MONTHLY_INCOME.ACTION,
    payload: leaseService.sendMonthlyIncome(getState().user.authToken)(
      getCurrentLeaseId(getState()),
      payload
    ),
  })
}

export const registerParticipant = payload => (dispatch, getState) => {
  return dispatch({
    type: REGISTER_PARTICIPANT.ACTION,
    payload: leaseService
      .registerParticipant(getState().user.authToken)(
        getCurrentLeaseId(getState()),
        parsePayload(payload)
      )
      .then(camelizeResponse),
  })
}

export const registerParticipantConfirm = () => (dispatch, getState) => {
  return dispatch({
    type: REGISTER_PARTICIPANT_CONFIRM.ACTION,
    payload: leaseService
      .registerParticipantConfirm(getState().user.authToken)(getCurrentLeaseId(getState()))
      .then(camelizeResponse),
  })
}

export const registerParticipantSpouse = payload => (dispatch, getState) => {
  return dispatch({
    type: REGISTER_PARTICIPANT_SPOUSE.ACTION,
    payload: leaseService
      .registerParticipantSpouse(getState().user.authToken)(
        getCurrentLeaseId(getState()),
        getCurrentParticipantId(getState()),
        parsePayload(payload)
      )
      .then(camelizeResponse),
  })
}

export const updateRegisteredParticipant = (participantId, payload) => (dispatch, getState) => {
  return dispatch({
    type: UPDATE_REGISTERED_PARTICIPANT.ACTION,
    payload: leaseService
      .updateRegisteredParticipant(getState().user.authToken)(
        participantId,
        getCurrentLeaseId(getState()),
        parsePayload(payload)
      )
      .then(camelizeResponse),
  })
}

export const uploadDocument = ({
  externalId,
  leaseId,
  onUploadProgress,
  validateStatus,
  ...payload
}) => (dispatch, getState) => {
  const { category, ...formData } = payload
  const newPayload = {
    leaseId,
    onUploadProgress,
    validateStatus,
    payload: createFormData(formData),
  }

  return dispatch({
    type: UPLOAD_DOCUMENT.ACTION,
    payload: leaseService
      .uploadDocument(getState().user.authToken)(newPayload)
      .then(camelizeResponse),
    meta: {
      externalId,
    },
  })
}

export const uploadHelperDocument = ({
  externalId,
  leaseId,
  onUploadProgress,
  validateStatus,
  token,
  ...payload
}) => dispatch => {
  const newPayload = {
    leaseId,
    onUploadProgress,
    validateStatus,
    token,
    payload: createFormData(payload),
  }

  return dispatch({
    type: UPLOAD_HELPER_DOCUMENT.ACTION,
    payload: leaseService
      .uploadHelperDocument()(newPayload)
      .then(camelizeResponse),
    meta: {
      externalId,
    },
  })
}

export const uploadParticipantsDocument = ({
  externalId,
  leaseId,
  onUploadProgress,
  validateStatus,
  token,
  ...payload
}) => dispatch => {
  const { category, ...formData } = payload
  const newPayload = {
    leaseId,
    onUploadProgress,
    validateStatus,
    token,
    payload: createFormData(formData),
  }

  return dispatch({
    type: UPLOAD_PARTICIPANTS_DOCUMENT.ACTION,
    payload: leaseService
      .uploadParticipantsDocument()(newPayload)
      .then(camelizeResponse),
    meta: {
      externalId,
    },
  })
}

export const registerParticipantDocument = ({ onUploadProgress, validateStatus, ...payload }) => (
  dispatch,
  getState
) => {
  const { participant, ...formData } = payload
  const newPayload = {
    leaseId: getCurrentLeaseId(getState()),
    participantId: participant || getCurrentParticipantId(getState()),
    onUploadProgress,
    validateStatus,
    payload: createFormData(formData),
  }

  return dispatch({
    type: REGISTER_PARTICIPANT_DOCUMENT.ACTION,
    payload: leaseService
      .registerParticipantDocument(getState().user.authToken)(newPayload)
      .then(camelizeResponse),
  })
}

export const createLeaseIncomeHelper = ({ externalId, payload }) => (dispatch, getState) =>
  dispatch({
    type: CREATE_INCOME_HELPER.ACTION,
    payload: leaseService
      .createLeaseIncomeHelper(getState().user.authToken)(payload)
      .then(camelizeResponse),
    meta: {
      externalId,
    },
  })

export const createMultipleIncomeHelpers = (leaseId, payload) => (dispatch, getState) =>
  dispatch({
    type: CREATE_MULTIPLE_INCOME_HELPER.ACTION,
    payload: leaseService
      .createMultipleIncomeHelpers(getState().user.authToken)(leaseId, payload)
      .then(camelizeResponse),
  })

export const inviteParticipants = (leaseId, payload) => (dispatch, getState) => {
  const newPayload = payload.map(participant => {
    const { id, ...info } = participant

    return info
  })
  return dispatch({
    type: INVITE_PARTICIPANTS.ACTION,
    payload: leaseService
      .inviteParticipants(getState().user.authToken)(leaseId, newPayload)
      .then(camelizeResponse),
  })
}

export const getLeases = (page = null) => (dispatch, getState) =>
  dispatch({
    type: GET_LEASES.ACTION,
    payload: leaseService.getLeases(getState().user.authToken)(page),
    meta: {
      page,
    },
  })

export const getAllLeases = (page = null) => (dispatch, getState) =>
  dispatch({
    type: GET_ALL_LEASES.ACTION,
    payload: leaseService.getLeases(getState().user.authToken)(page),
    meta: {
      page,
    },
  })

export const sendLeaseToReview = ({ leaseId, externalId }) => (dispatch, getState) =>
  dispatch({
    type: SEND_LEASE_TO_REVIEW.ACTION,
    payload: leaseService
      .sendLeaseToReview(getState().user.authToken)(leaseId)
      .then(camelizeResponse),
    meta: {
      externalId,
    },
  })

export const cancelLease = ({ leaseId, externalId }) => (dispatch, getState) =>
  dispatch({
    type: CANCEL_LEASE.ACTION,
    payload: leaseService.cancelLease(getState().user.authToken)(leaseId),
    meta: {
      externalId,
    },
  })

export const sendTenantData = ({ externalId, ...payload }) => (dispatch, getState) =>
  dispatch({
    type: SEND_TENTANT_DATA.ACTION,
    payload: leaseService
      .sendTenantData(getState().user.authToken)(parsePayload(payload))
      .then(camelizeResponse),
    meta: {
      externalId,
    },
  })

export const sendTenantProfessionalData = ({ externalId, ...payload }) => (dispatch, getState) =>
  dispatch({
    type: SEND_TENANT_PROFESSIONAL_DATA.ACTION,
    payload: leaseService
      .sendTenantData(getState().user.authToken)(parsePayload(payload))
      .then(camelizeResponse),
    meta: {
      externalId,
    },
  })

export const getHelperLease = ({ leaseId, token }) => dispatch =>
  dispatch({
    type: GET_HELPER_LEASE.ACTION,
    payload: leaseService.getHelperLease({ leaseId, token }).then(camelizeResponse),
  })

export const getParticipantLease = ({ leaseId, token }) => dispatch =>
  dispatch({
    type: GET_PARTICIPANT_LEASE.ACTION,
    payload: leaseService.getParticipantLease({ leaseId, token }).then(camelizeResponse),
  })

export const sendHelperData = ({ externalId, payload }) => dispatch =>
  dispatch({
    type: SEND_HELPER_DATA.ACTION,
    payload: leaseService.sendHelperData(parsePayload(payload)).then(camelizeResponse),
    meta: {
      externalId,
    },
  })

export const sendHelperSpouseData = ({ externalId, payload }) => dispatch =>
  dispatch({
    type: SEND_HELPER_SPOUSE_DATA.ACTION,
    payload: leaseService.sendHelperSpouseData(parsePayload(payload)).then(camelizeResponse),
    meta: {
      externalId,
    },
  })

export const sendParticipantsSpouseData = ({ externalId, payload }) => dispatch =>
  dispatch({
    type: SEND_PARTICIPANTS_SPOUSE_DATA.ACTION,
    payload: leaseService.sendParticipantsSpouseData(parsePayload(payload)).then(camelizeResponse),
    meta: {
      externalId,
    },
  })

export const sendSpouseData = ({ externalId, ...payload }) => (dispatch, getState) =>
  dispatch({
    type: SEND_SPOUSE_DATA.ACTION,
    payload: leaseService
      .sendSpouseData(getState().user.authToken)(parsePayload(payload))
      .then(camelizeResponse),
    meta: {
      externalId,
    },
  })

export const getContractPreview = ({ externalId, leaseId }) => (dispatch, getState) =>
  dispatch({
    type: GET_CONTRACT_PREVIEW.ACTION,
    payload: leaseService.getContractPreview(getState().user.authToken)(leaseId),
    meta: {
      externalId,
    },
  })

export const generateContractLink = ({ externalId, leaseId, contractId }) => (dispatch, getState) =>
  dispatch({
    type: GENERATE_CONTRACT_LINK.ACTION,
    payload: leaseService.generateContractLink(getState().user.authToken)(leaseId, contractId),
    meta: {
      externalId,
    },
  })

export const getContractPreviewIncomeHelper = ({ externalId, leaseId, token }) => dispatch =>
  dispatch({
    type: GET_CONTRACT_PREVIEW.ACTION,
    payload: leaseService.getContractPreviewIncomeHelper({ token, leaseId }),
    meta: {
      externalId,
    },
  })

export const sendParticipantsData = ({ payload }) => (dispatch, getState) =>
  dispatch({
    type: SEND_PARTICIPANTS_DATA.ACTION,
    payload: leaseService.sendParticipantsData(
      parsePayload({ ...payload, leaseId: getCurrentLeaseId(getState()) })
    ),
    meta: {
      externalId: getCurrentExternalId(getState()),
    },
  })

export const sendIncomeHelperData = ({ externalId, ...payload }) => dispatch =>
  dispatch({
    type: SEND_INCOME_HELPER_DATA.ACTION,
    payload: leaseService.sendIncomeHelperData(parsePayload(payload)),
    meta: {
      externalId,
    },
  })

export const generateContractLinkIncomeHelper = ({
  externalId,
  leaseId,
  contractId,
  token,
}) => dispatch =>
  dispatch({
    type: GENERATE_CONTRACT_LINK_HELPER.ACTION,
    payload: leaseService.generateContractLinkIncomeHelper({ leaseId, contractId, token }),
    meta: {
      externalId,
    },
  })

export const requestContractChanges = ({ externalId, ...payload }) => (dispatch, getState) =>
  dispatch({
    type: REQUEST_CONTRACT_CHANGES.ACTION,
    payload: leaseService.requestContractChanges(getState().user.authToken)(payload),
    meta: {
      externalId,
    },
  })

export const submitWarranty = (id, payload) => (dispatch, getState) =>
  dispatch({
    type: SUBMIT_WARRANTY.ACTION,
    payload: leaseService
      .submitWarranty(getState().user.authToken)(id, payload)
      .then(camelizeResponse),
  })

export const sendRate = payload => (dispatch, getState) =>
  dispatch({
    type: SEND_RATE.ACTION,
    payload: leaseService.sendRate(getState().user.authToken)(payload),
  })

export const sendUserData = ({ externalId, ...payload }) => (dispatch, getState) =>
  dispatch({
    type: SEND_USER_DATA.ACTION,
    payload: leaseService
      .sendUserData(getState().user.authToken)(parsePayload(payload))
      .then(camelizeResponse),
    meta: {
      externalId,
    },
  })

export const updateUserData = ({ externalId, ...payload }) => (dispatch, getState) =>
  dispatch({
    type: UPDATE_USER_DATA.ACTION,
    payload: leaseService
      .updateUserData(getState().user.authToken)(parsePayload(payload))
      .then(camelizeResponse),
    meta: {
      externalId,
    },
  })

export const updateParticipants = (leaseId, participantId, payload, externalId) => (
  dispatch,
  getState
) =>
  dispatch({
    type: UPDATE_PARTICIPANTS.ACTION,
    payload: leaseService
      .updateParticipants(getState().user.authToken)(leaseId, participantId, payload)
      .then(camelizeResponse),
    meta: {
      externalId,
    },
  })

export const deleteDocument = (leaseId, documentId) => (dispatch, getState) =>
  dispatch({
    type: DELETE_DOCUMENT.ACTION,
    payload: leaseService.deleteDocument(getState().user.authToken)(leaseId, documentId),
  })

export const deleteParticipant = participantId => (dispatch, getState) =>
  dispatch({
    type: DELETE_PARTICIPANT.ACTION,
    payload: leaseService.deleteParticipant(getState().user.authToken)(
      getCurrentLeaseId(getState()),
      participantId
    ),
    meta: { participantId },
  })

export const deleteParticipantDocument = (documentId, participantId, id, token) => (
  dispatch,
  getState
) => {
  const leaseId = id || getCurrentLeaseId(getState())
  return dispatch({
    type: DELETE_PARTICIPANT_DOCUMENT.ACTION,
    payload: leaseService.deleteParticipantDocument(getState().user.authToken)(
      leaseId,
      documentId,
      participantId,
      token
    ),
  })
}

export const sendFireInsurance = (externalId, leaseId, payload) => (dispatch, getState) =>
  dispatch({
    type: SEND_FIRE_INSURANCE.ACTION,
    payload: leaseService.sendFireInsurance(getState().user.authToken)(leaseId, payload),
    meta: {
      externalId,
    },
  })

export const updateLease = (leaseId, payload) => (dispatch, getState) =>
  dispatch({
    type: UPDATE_LEASE.ACTION,
    payload: leaseService.updateLease(getState().user.authToken)(leaseId, payload),
  })

export const submitCredpagoAnalysis = leaseId => (dispatch, getState) =>
  dispatch({
    type: SUBMIT_CREDPAGO_ANALYSIS.ACTION,
    payload: leaseService.submitCredpagoAnalysis(getState().user.authToken)(leaseId),
  })

import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ palette, breakpoints }) => ({
  appBar: {
    position: 'relative',
    backgroundColor: 'white',
    border: `solid 0.1rem ${palette.gray.default}`,
    boxShadow: 'none',
  },
  formTitle: {
    fontWeight: 600,
  },
  title: {
    marginLeft: '1.6rem',
    flex: 1,
    textAlign: 'left',
    fontSize: '2rem',
    fontWeight: 500,
  },
  root: {
    color: palette.secondary.main,
    fontSize: '2.4rem',
  },
  contentWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginTop: '5.6rem',
    [breakpoints.down('md')]: {
      marginTop: '2.4rem',
    },
  },
  content: {
    maxWidth: '56rem',
    width: '100%',
    [breakpoints.down('md')]: {
      padding: '0 1.2rem',
    },
  },
  select: {
    width: '100%',
  },
  subsequentSections: {
    marginBottom: '-0.8rem',
    marginTop: '1.6rem',
    fontWeight: 600,
  },
  toggleWrapper: {
    maxWidth: '18.4rem',
  },
  toggleButton: {
    width: '16rem',
    height: '4rem',
    [breakpoints.down('md')]: {
      width: '100%',
    },
  },
  buttonColor: {
    color: 'black',
  },
  nextButton: {
    width: '16rem',
    height: '4rem',
    [breakpoints.down('md')]: {
      maxWidth: '51.2rem',
      width: '100%',
    },
  },
  buttonWrapper: {
    width: '100%',
    display: 'flex',
    padding: '1.2rem 4rem',
    marginTop: '4rem',
    justifyContent: 'flex-end',
    [breakpoints.down('md')]: {
      justifyContent: 'center',
      padding: '0 2.4rem 2.4rem 2.4rem',
    },
  },
  spouseWrapper: {
    padding: '1.6rem',
    display: 'grid',
    gap: '0.8rem',
    gridAutoFlow: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: palette.gray.light,
    borderRadius: '0.4rem',
  },
  icon: {
    height: '1.6rem',
    width: '1.6rem',
  },
  documentsWrapper: {
    display: 'grid',
    gap: '1.6rem',
    gridAutoFlow: 'row',
  },
  textField: {
    textAlign: 'left',
    '& .MuiInputLabel-outlined': {
      fontSize: '1.4rem',
    },
    '& p': {
      fontSize: '1.6rem',
    },
  },
  input: {
    height: 48,
    marginBottom: 24,
    textAlign: 'left',
    '& .MuiInputAdornment-root.': {
      fontSize: 16,
    },
  },
}))

import { makeStyles } from '@material-ui/styles'

export default makeStyles(() => ({
  textField: {
    '& .MuiSelect-iconOutlined': {
      color: 'transparent',
    },
    '& .MuiSelect-root ': {
      padding: '2.2rem 1.6rem 1.6rem 0',
      fontSize: 14,
    },
  },
  currencyTextField: {
    '& .MuiInputBase-input': {
      fontSize: '1.4rem',

      '&::placeholder': {
        opacity: 1,
      },
    },
  },
  multipleSelect: {
    '& .MuiSelect-root ': {
      padding: '2.2rem 1.6rem 1.6rem 0',
      fontSize: 14,
    },
    '& .MuiSelect-icon': {
      display: 'none',
    },
  },
}))

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import debounce from 'lodash.debounce'

import SearchIcon from '_assets/images/ic-pesquisa.svg'
import CloseIcon from '_assets/images/baseline-close-24px.svg'
import { sendEvent } from '_utils/mixpanel'
import { HERE_KEY } from '_config/environment'

import styles from './styles.css'

export const InputTheme = {
  DEFAULT: 'default',
  DIRECTION: 'direction',
}
class LocationInput extends PureComponent {
  static propTypes = {
    theme: PropTypes.string,
    searchFocused: PropTypes.bool,
    placeholder: PropTypes.string,
    onLocationClick: PropTypes.func,
    onCancelClick: PropTypes.func,
    className: PropTypes.string,
    onEmptyInput: PropTypes.func,
    setInputRef: PropTypes.func,
    searchCenterCoordinates: PropTypes.string,
  }

  static defaultProps = {
    theme: InputTheme.DEFAULT,
    searchFocused: false,
    placeholder: '',
    onLocationClick: () => {},
    onCancelClick: () => {},
    className: '',
    onEmptyInput: () => {},
    setInputRef: () => {},
    searchCenterCoordinates: '-27.592900,-48.550173', // Florianópolis coordinates
  }

  constructor(props) {
    super(props)

    this.searchLocation = debounce(this.searchLocation, 300)
  }

  state = {
    locations: [],
    isDropdownOpen: false,
    value: '',
    showCloseButton: false,
  }

  componentDidMount() {
    if (window && window.H && window.H.service) {
      const platform = new window.H.service.Platform({
        apikey: HERE_KEY,
      })

      this.service = platform.getSearchService()
    }
  }

  onInputChange = event => {
    const { value } = event.target

    this.setState(
      {
        value,
        showCloseButton: true,
      },
      this.searchLocation
    )
  }

  onCancelClick = () => {
    this.setState(
      {
        showCloseButton: false,
        value: '',
        isDropdownOpen: false,
      },
      this.props.onCancelClick
    )

    sendEvent('Imóvel: Selecionou botão cancelar rota no mapa ')
  }

  onMouseDown = event => {
    event.preventDefault()
  }

  onEnterDown = event => {
    const { locations } = this.state
    if (event.key === 'Enter' && locations.length) {
      const suggestion = locations[0]
      this.getAddress(suggestion)()
    }
  }

  getAddress = suggest => () => {
    this.props.onLocationClick(suggest.position)
    this.setState({
      showCloseButton: true,
      value: suggest.title,
      isDropdownOpen: false,
    })
  }

  clearInput = () => {
    this.setState({
      showCloseButton: false,
      value: '',
      isDropdownOpen: false,
    })
  }

  searchLocation = () => {
    if (!this.service) return

    const { value } = this.state
    const { searchCenterCoordinates } = this.props
    const params = {
      q: value,
      in: `circle:${searchCenterCoordinates};r=150000`,
      resultTypes: 'street,place',
    }

    if (value === '') {
      this.setState(
        {
          isDropdownOpen: false,
          showCloseButton: false,
          locations: [],
        },
        () => this.props.onEmptyInput()
      )
      return
    }

    const onSuccess = result => {
      this.setState(
        {
          locations: result.items,
        },
        () => this.openDropdown()
      )
    }

    const onError = () => {
      this.setState({
        locations: [],
      })
    }

    this.service.autosuggest(params, onSuccess, onError)
  }

  openDropdown = () => {
    this.setState({
      isDropdownOpen: true,
    })
  }

  renderSuggestItem = suggest => {
    return (
      <button
        className={styles.item}
        key={suggest.id}
        onClick={this.getAddress(suggest)}
        onMouseDown={this.onMouseDown}
        type="button"
      >
        <p className={styles.address}>{suggest.title}</p>
      </button>
    )
  }

  render() {
    const { theme, searchFocused, placeholder, className, setInputRef } = this.props
    const { locations, isDropdownOpen, value, showCloseButton } = this.state
    const maxSuggestList = 5

    return (
      <div
        className={classnames(
          styles.search,
          { [styles.opened]: searchFocused },
          styles[theme],
          className
        )}
      >
        <svg className={styles['search-icon']} alt="search icon">
          <use xlinkHref={SearchIcon} />
        </svg>
        <input
          className={styles.input}
          placeholder={placeholder}
          type="text"
          value={value}
          onChange={this.onInputChange}
          onKeyDown={this.onEnterDown}
          ref={setInputRef}
          key="location-input"
        />
        {showCloseButton && (
          <button
            className={styles['close-button']}
            onClick={this.onCancelClick}
            onMouseDown={this.onMouseDown}
            aria-label="Botão de cancelar rota"
            type="button"
          >
            <svg alt="close icon" className={styles['close-icon']}>
              <use xlinkHref={CloseIcon} />
            </svg>
          </button>
        )}
        {isDropdownOpen && (
          <div
            className={classnames(styles.dropdown, { [styles['dropdown-opened']]: isDropdownOpen })}
          >
            {locations.slice(0, maxSuggestList).map(s => this.renderSuggestItem(s))}
          </div>
        )}
      </div>
    )
  }
}

export default LocationInput

import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import LocationIcon from '_assets/images/location.svg'
import FlipIcon from '_assets/images/baseline-swap_vert-24px.svg'
import DotsIcon from '_assets/images/round-more_vert-24px.svg'
import CircleIcon from '_assets/images/baseline-trip_origin-24px.svg'

import LocationInput, { InputTheme } from './location-input'
import styles from './styles.css'

const DirectionsInput = ({
  propertyAddress,
  distance,
  duration,
  propertyId,
  propertyToLocation,
  onEmptyInput,
  onCancelClick,
  onLocationClick,
  onFlipClick,
  setInputRef,
  citiesArray,
  searchCenterLat,
  searchCenterLng,
}) => (
  <div className={styles['direction-container']}>
    <div className={styles['icons-container']}>
      <svg alt="circle icon" className={styles['circle-icon']}>
        <use xlinkHref={CircleIcon} />
      </svg>
      <svg alt="dots icon" className={styles['dots-icon']}>
        <use xlinkHref={DotsIcon} />
      </svg>
      <svg alt="location icon" className={styles['location-icon']}>
        <use xlinkHref={LocationIcon} />
      </svg>
    </div>
    <div className={styles['input-container']}>
      <div
        className={classnames({
          [styles['direction-origin']]: !propertyToLocation,
          [styles['direction-destination']]: propertyToLocation,
        })}
      >
        <LocationInput
          theme={InputTheme.DIRECTION}
          placeholder="Para onde deseja ir?"
          onLocationClick={onLocationClick}
          propertyId={propertyId}
          onCancelClick={onCancelClick}
          onEmptyInput={onEmptyInput}
          ref={setInputRef}
          cities={citiesArray}
          searchCenterCoordinates={`${searchCenterLat},${searchCenterLng}`}
        />
      </div>
      <div className={styles.line} />
      <p
        className={classnames(styles['property-address-label'], {
          [styles['direction-origin']]: propertyToLocation,
          [styles['direction-destination']]: !propertyToLocation,
        })}
      >
        {propertyAddress}
      </p>
      <p
        className={classnames(styles.info, {
          [styles['show-info']]: !!distance && !!duration,
        })}
      >
        Distância: {distance} ({duration})
      </p>
    </div>
    <button
      className={styles['flip-button']}
      onClick={onFlipClick}
      aria-label="Botão de inverter rota"
      onMouseDown={DirectionsInput.onMouseDown}
      type="button"
    >
      <svg alt="flip icon" className={styles['flip-icon']}>
        <use xlinkHref={FlipIcon} />
      </svg>
    </button>
  </div>
)

DirectionsInput.onMouseDown = event => {
  event.preventDefault()
}

DirectionsInput.propTypes = {
  propertyAddress: PropTypes.string.isRequired,
  distance: PropTypes.string.isRequired,
  duration: PropTypes.string.isRequired,
  propertyId: PropTypes.string.isRequired,
  onLocationClick: PropTypes.func.isRequired,
  onFlipClick: PropTypes.func.isRequired,
  propertyToLocation: PropTypes.bool.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  onEmptyInput: PropTypes.func.isRequired,
  setInputRef: PropTypes.shape({}).isRequired,
  citiesArray: PropTypes.arrayOf(PropTypes.string).isRequired,
  searchCenterLat: PropTypes.number.isRequired,
  searchCenterLng: PropTypes.number.isRequired,
}

export default React.memo(DirectionsInput)

import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import styles from './styles.css'

const FloatingAction = ({ children, className, setRef }) => (
  <div className={classnames(styles.floating, className)} ref={setRef}>
    {children}
  </div>
)

FloatingAction.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  setRef: PropTypes.func,
}

FloatingAction.defaultProps = {
  className: '',
  setRef: () => {},
}

export default FloatingAction

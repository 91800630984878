import React, { Fragment, useCallback, useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { FormContext, useForm } from 'react-hook-form'
import { navigate, Link, Redirect } from '@reach/router'

import {
  updateListingRegistration,
  updateListingRegistrationEmail,
} from '_modules/listing-registration/actions'
import {
  isListingRegistrationLoading,
  hasListingRegistrationError,
  listingRegistrationFormSelector,
} from '_modules/listing-registration/selectors'
import Button, { ButtonColor, ButtonFormat, ButtonVariant } from '_components/button'
import { useWindowWidth, usePrevious, useProtectedRoute } from '_utils/hooks'
import { MOBILE_BREAKPOINT, PROPERTY_TYPES } from '_utils/constants'

import OutlinedField from '../../outlined-field'
import InputCarousel from '../form/input-carousel'
import FormGroup from '../form/form-group'
import { TextField } from '../form/text-field'
import CheckboxGroup from '../form/checkbox-group'
import ProgressBar from '../progress-bar'

import { OPTIONS } from './options'
import styles from './styles.css'

const {
  common: {
    animals,
    solarOrientation,
    parkingSpots,
    coveredParkingSpots,
    publicizePermission,
    buildingPosition,
    concierge,
    extras,
  },
} = OPTIONS

const ListingRegistrationDetailedStepTwo = () => {
  useProtectedRoute('/anunciar-imovel')
  const listingRegistrationForm = useSelector(listingRegistrationFormSelector)
  const methods = useForm({
    defaultValues: {
      ...listingRegistrationForm,
      totalArea: 50,
      livingArea: 50,
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  const fields = methods.watch()
  const windowWidth = useWindowWidth()

  const dispatch = useDispatch()
  const isLoading = useSelector(isListingRegistrationLoading)
  const hasRegisterError = useSelector(hasListingRegistrationError)
  const previousLoading = usePrevious(isLoading)

  const handleSubmit = useCallback(
    data => {
      const { id, userToken } = listingRegistrationForm
      if (userToken) {
        return dispatch(updateListingRegistrationEmail(id, userToken, data))
      }
      return dispatch(updateListingRegistration(data))
    },
    [dispatch, listingRegistrationForm]
  )

  useEffect(() => {
    if (previousLoading && !isLoading && !hasRegisterError) {
      navigate(['/anunciar-imovel/passo-3'])
    }
  }, [hasRegisterError, isLoading, previousLoading])

  const checkEmptyObject = useMemo(() => Object.keys(listingRegistrationForm).length === 0, [
    listingRegistrationForm,
  ])

  if (checkEmptyObject) {
    return <Redirect to="/proprietário" />
  }

  return (
    <FormContext {...methods}>
      <ProgressBar value={50} />
      <form className={styles.form} onSubmit={methods.handleSubmit(handleSubmit)}>
        <div className={styles.container}>
          <InputCarousel key={animals.name} {...animals} />
          <InputCarousel key={solarOrientation.name} {...solarOrientation} />
          <InputCarousel className={styles.carousel} key={parkingSpots.name} {...parkingSpots} />
          <InputCarousel
            className={styles.carousel}
            key={coveredParkingSpots.name}
            {...coveredParkingSpots}
          />
          {/* Check user input at parking spots and shows the proper number of fields */}
          {listingRegistrationForm.listingType === PROPERTY_TYPES.RES_APARTMENT &&
            !!Number(fields.parkingSpots) && (
              <FormGroup label="Qual o número da(s) vaga(s) de garagem?">
                {[...Array(Number(fields.parkingSpots)).keys()].map(value => {
                  const name = `parkingSpotsNumbers-${value}`
                  return (
                    <OutlinedField
                      placeholder={`Número da garagem ${value + 1}`}
                      label={`Número da garagem ${value + 1}`}
                      type="number"
                      name={name}
                      key={name}
                      className={styles['parking-numbers-field']}
                      rules={{ required: '* Este campo precisa ser preenchido' }}
                      minValue={1}
                    />
                  )
                })}
              </FormGroup>
            )}
          <FormGroup label="Área privativa" className={styles['half-width']}>
            <OutlinedField
              placeholder="Digite um valor "
              label="Digite um valor"
              type="number"
              name="livingArea"
              endAdornment="m&#178;"
              showInputControls={windowWidth < MOBILE_BREAKPOINT}
            />
          </FormGroup>
          <FormGroup label="Área total" className={styles['half-width']}>
            <OutlinedField
              placeholder="Digite um valor "
              label="Digite um valor"
              type="number"
              name="totalArea"
              endAdornment="m&#178;"
              showInputControls={windowWidth < MOBILE_BREAKPOINT}
            />
          </FormGroup>
          {listingRegistrationForm.listingType === PROPERTY_TYPES.RES_APARTMENT && (
            <Fragment>
              <InputCarousel key={publicizePermission.name} {...publicizePermission} />
              <InputCarousel key={buildingPosition.name} {...buildingPosition} />
              <InputCarousel key={concierge.name} {...concierge} />
              <TextField
                label="Qual o nome do edificio?"
                name="buildingName"
                showApplicableOption
              />
            </Fragment>
          )}
          <CheckboxGroup key={extras.name} {...extras} />
        </div>
        <hr className={styles.separator} />
        <div className={styles.footer}>
          <Link className={styles.back} to="/anunciar-imovel/passo-1">
            VOLTAR
          </Link>
          <Button
            color={
              windowWidth > MOBILE_BREAKPOINT ? ButtonColor.PRIMARY : ButtonColor.INVERTED_PRIMARY
            }
            variant={ButtonVariant.BOLD}
            format={ButtonFormat.SQUARED}
            className={styles.next}
            isLoading={isLoading}
            type="submit"
          >
            SALVAR E CONTINUAR
          </Button>
        </div>
      </form>
    </FormContext>
  )
}

export default React.memo(ListingRegistrationDetailedStepTwo)

import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
  participants: {
    width: '100%',
    maxWidth: 688,
  },
  sectionText: {
    color: theme.palette.text.primary,
    marginTop: theme.spacing(5),
  },
  participantWrapper: {
    width: '100%',
  },
}))

import React from 'react'
import PropTypes from 'prop-types'
import CloseIcon from '@material-ui/icons/Close'

import Button, { ButtonVariant, ButtonColor } from '_components/button'

import styles from './styles.css'

const ContactCard = ({ participant, onClickRemoveParticipant }) => {
  return (
    <div className={styles.card}>
      <Button
        variant={ButtonVariant.DEFAULT}
        color={ButtonColor.TEXT_BLACK}
        onClick={onClickRemoveParticipant}
        id={participant.id}
        className={styles.button}
      >
        <CloseIcon className={styles.icon} />
      </Button>
      <h3 className={styles.name}>{participant.fullName}</h3>
      <p className={styles.email}>{participant.email}</p>
    </div>
  )
}

ContactCard.propTypes = {
  participant: PropTypes.shape({
    fullName: PropTypes.string,
    email: PropTypes.string,
    id: PropTypes.number,
  }).isRequired,
  onClickRemoveParticipant: PropTypes.func.isRequired,
}

export default React.memo(ContactCard)

import { createOptions } from '../helpers'

export const OPTIONS = {
  res_home: {
    floor: {
      rules: { required: '* Este campo precisa ser preenchido' },
      values: createOptions(10, 'floor', {
        appendPlusToLast: true,
        replaceFirstOption: { value: '0', label: 'T' },
      }),
      label: 'Quantos andares tem a casa?',
      name: 'floor',
    },
    furnished: {
      label: 'A casa possui mobilia?',
      name: 'furnished',
      rules: { required: '* Este campo precisa ser preenchido' },
      values: [
        {
          id: 'unfurnished',
          value: 'unfurnished',
          label: 'Sem Mobília',
        },
        {
          id: 'furnished',
          value: 'furnished',
          label: 'Mobiliado',
        },
        {
          id: 'semiFurnished',
          value: 'semi_furnished',
          label: 'Semi-Mobiliado',
        },
      ],
    },
  },
  res_apartment: {
    penthouse: {
      label: 'É uma cobertura?',
      name: 'penthouse',
    },
    floor: {
      rules: { required: '* Este campo precisa ser preenchido' },
      values: createOptions(200, 'floor', {
        appendPlusToLast: true,
        appendFirstOption: { value: '0', label: 'T' },
      }),
      label: 'Qual o andar do apartamento?',
      name: 'floor',
    },
    furnished: {
      label: 'O apartamento possui mobilia?',
      name: 'furnished',
      rules: { required: '* Este campo precisa ser preenchido' },
      values: [
        {
          id: 'unfurnished',
          value: 'unfurnished',
          label: 'Sem Mobília',
        },
        {
          id: 'furnished',
          value: 'furnished',
          label: 'Mobiliado',
        },
        {
          id: 'semiFurnished',
          value: 'semi_furnished',
          label: 'Semi-Mobiliado',
        },
      ],
    },
  },
  common: {
    bedrooms: {
      label: 'Quantos dormitórios?',
      name: 'bedrooms',
      rules: { required: '* Este campo precisa ser preenchido' },
      values: createOptions(10, 'bedrooms', {
        appendPlusToLast: true,
      }),
    },
    ensuites: {
      label: 'Quantas suítes?',
      name: 'numberOfEnsuites',
      rules: { required: '* Este campo precisa ser preenchido' },
      values: createOptions(11, 'numberOfEnsuites', {
        appendPlusToLast: true,
        zeroIndex: true,
      }),
    },
    bathrooms: {
      label: 'Quantos banheiros?',
      name: 'bathrooms',
      rules: { required: '* Este campo precisa ser preenchido' },
      values: createOptions(10, 'bathrooms', {
        appendPlusToLast: true,
      }),
    },
    hobbybox: {
      label: 'Possui hobby box?',
      name: 'hobbybox',
      rules: { required: '* Este campo precisa ser preenchido' },
      values: [
        { value: 'true', id: 'yes', label: 'Sim' },
        { value: 'false', id: 'no', label: 'Não' },
      ],
    },
    keytype: {
      label: 'Qual o tipo de chave?',
      name: 'keyType',
      rules: { required: '* Este campo precisa ser preenchido' },
      values: [
        {
          id: 'common',
          value: 'common',
          label: 'Comum',
        },
        {
          id: 'password',
          value: 'password',
          label: 'Senha',
        },
        {
          id: 'biometry',
          value: 'biometry',
          label: 'Biometria',
        },
      ],
    },
  },
}

import React, { useMemo, useCallback, useContext } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useSelector, useDispatch } from 'react-redux'
import Button from '@material-ui/core/Button'
import { List } from 'immutable'
import URI from 'urijs'
import { Avatar, Box, Divider, Grid, Link, Typography } from '@material-ui/core'
import { Favorite, FavoriteBorder } from '@material-ui/icons'

import { useWindowWidth, useWindowSize } from '_utils/hooks'
import { likeProperty, unLikeProperty } from '_modules/property/actions'
import Carousel from '_components/carousel'
import { toTitleCase } from '_utils/helpers'
import { getPropertyType } from '_utils/property'
import { formatNumber } from '_utils/strings'
import { RedirectContext } from '_context/redirect'
import { listingInformationShape } from '_utils/proptypes'
import { FEATURE_FLAGS, COMMERCIAL } from '_utils/constants'

import { useStyles } from './styles'

const ListingCard = ({
  info,
  className,
  listLikedProperties,
  staffHash,
  recommendationHash,
  hideScheduleButton,
}) => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const user = useSelector(state => state.user)
  const { setRedirectUri, setOpenLogin, setLikedProperty } = useContext(RedirectContext)
  const isLoggedIn = !!user.authToken
  const isLiked = useMemo(() => {
    return listLikedProperties.includes(info.externalId)
  }, [info.externalId, listLikedProperties])
  const SQUARE_METERS = 'm²'
  const { isMobile } = useWindowSize()

  const onLikeClick = useCallback(
    event => {
      event.preventDefault()
      const { externalId } = info
      const isLikedProperty = user.likedProperties.includes(externalId)

      if (isLoggedIn) {
        if (isLikedProperty) {
          dispatch(unLikeProperty(externalId))
        } else {
          dispatch(likeProperty(externalId))
        }
        return
      }
      setLikedProperty(externalId)
      setRedirectUri(window.location.href)
      setOpenLogin(true)
    },
    [
      dispatch,
      info,
      isLoggedIn,
      setLikedProperty,
      setOpenLogin,
      setRedirectUri,
      user.likedProperties,
    ]
  )

  const width = useWindowWidth()

  const carouselHeight = useMemo(() => {
    if (width < 480) {
      return 176
    }
    if (width < 1024) {
      return 280
    }
    return 336
  }, [width])

  const totalPrice = useMemo(
    () => info.rentPrice + info.condominiumFee + info.taxes + info.insurance,
    [info]
  )

  const textAddress = useMemo(
    () =>
      info && `${toTitleCase(info.address)}${info.streetNumber ? `, ${info.streetNumber}` : ''}`,
    [info]
  )

  const textNeighborhood = useMemo(() => info && toTitleCase(info.neighborhood), [info])

  const queryParameter = useMemo(() => {
    if (!staffHash && !!recommendationHash) {
      return URI('').addSearch('recommendation_hash', recommendationHash)
    }
    return staffHash !== '' || recommendationHash !== ''
      ? URI('').addSearch('staff_hash', staffHash, 'recommendation_hash', recommendationHash)
      : ''
  }, [recommendationHash, staffHash])

  const thumbnailPictures = useMemo(
    () => (info ? info.convertedPictures.map(picture => picture.get('thumbnail')) : []),
    [info]
  )

  const isCommercialListing = useMemo(() => {
    return info && info.purposeType && info.purposeType === COMMERCIAL
  }, [info])

  return (
    <Link
      href={`/imovel/${info.slug}/${queryParameter}`}
      target="_blank" // eslint-disable-line react/jsx-no-target-blank
      rel="noreferrer"
      className={classnames(styles.container, styles.link, className)}
    >
      <Box className={styles.header} component="section">
        {typeof pictures === 'string' ? (
          <Avatar
            src={thumbnailPictures}
            alt="Propriedade"
            className={styles.picture}
            variant="square"
          />
        ) : (
          <Carousel
            pictures={thumbnailPictures}
            height={carouselHeight}
            className={styles.picture}
            isMobile={isMobile}
          />
        )}
        <Typography className={classnames(styles.externalId, styles.mediumText)}>
          cód {info.externalId}
        </Typography>
      </Box>
      <Box className={styles.content} component="section">
        <Grid className={styles.topContent}>
          <Typography className={classnames(styles.propertyType, styles.smallText)}>
            {getPropertyType(info.listingType)}
          </Typography>
          <Typography className={styles.bigText}>{textNeighborhood}</Typography>
          <Typography className={classnames(styles.address, styles.smallText)}>
            {textAddress}
          </Typography>
          <Box className={classnames(styles.detail, styles.mediumText)} component="div">
            {!!info.bedrooms && !isCommercialListing && (
              <Typography className={styles.mediumText}>
                {info.bedrooms} {info.bedrooms === 1 ? 'Quarto' : 'Quartos'}
              </Typography>
            )}
            {!!info.totalArea && FEATURE_FLAGS.commercialListingsEnabled && isCommercialListing && (
              <Typography className={styles.mediumText}>
                {info.totalArea} {SQUARE_METERS}
              </Typography>
            )}
            {!!info.bathrooms && (
              <Typography className={styles.mediumText}>
                {info.bathrooms} {info.bathrooms === 1 ? 'Banheiro' : 'Banheiros'}
              </Typography>
            )}
            {!!info.parkingSpots && (
              <Typography className={styles.mediumText}>
                {info.parkingSpots} {info.parkingSpots === 1 ? 'Garagem' : 'Garagens'}
              </Typography>
            )}
          </Box>
        </Grid>
        <Divider className={styles.line} />
        <Grid className={styles.bottomContent}>
          <Typography className={classnames(styles.rentPrice, styles.smallText)}>
            Aluguel R$ {formatNumber(info.rentPrice)}
          </Typography>
          <Typography className={classnames(styles.totalPrice, styles.bigText)}>
            Total R$ {formatNumber(totalPrice)}
          </Typography>
          <Button onClick={onLikeClick} className={styles.heartButton}>
            {isLiked ? (
              <Favorite className={styles.heartIcon} />
            ) : (
              <FavoriteBorder className={styles.heartIcon} />
            )}
          </Button>
        </Grid>
      </Box>
      {!hideScheduleButton && (
        <Button
          component="a"
          href={`/agendamento/${info.externalId}/${queryParameter}`}
          target="_blank"
          rel="noopener noreferrer"
          className={classnames(styles.bookingButton, styles.mediumText)}
        >
          AGENDAR VISITA
        </Button>
      )}
    </Link>
  )
}

ListingCard.propTypes = {
  info: listingInformationShape.isRequired,
  className: PropTypes.string,
  staffHash: PropTypes.string,
  recommendationHash: PropTypes.string,
  listLikedProperties: PropTypes.oneOfType([PropTypes.array, PropTypes.instanceOf(List)])
    .isRequired,
  hideScheduleButton: PropTypes.bool,
}

ListingCard.defaultProps = {
  className: '',
  staffHash: '',
  recommendationHash: '',
  hideScheduleButton: false,
}

export default React.memo(ListingCard)

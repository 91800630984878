import React from 'react'
import PropTypes from 'prop-types'

import UserIcon from '_assets/images/ic-user.svg'
import CPFIcon from '_assets/images/ic-cpf.svg'
import EmailIcon from '_assets/images/ic-email.svg'

import styles from './styles.css'

const UserDetails = ({ name, document, email }) => (
  <div className={styles.container}>
    <p className={styles.title}>Locatário</p>
    <div className={styles.content}>
      <div className={styles['user-info']}>
        <svg aria-hidden="true" className={styles.icon}>
          <use xlinkHref={UserIcon} />
        </svg>
        <p className={styles.info}>{name}</p>
      </div>
      <div className={styles['user-info']}>
        <svg aria-hidden="true" className={styles.icon}>
          <use xlinkHref={CPFIcon} />
        </svg>
        <p className={styles.info}>CPF {document}</p>
      </div>
      <div className={styles['user-info']}>
        <svg aria-hidden="true" className={styles.icon}>
          <use xlinkHref={EmailIcon} />
        </svg>
        <p className={styles.info}>{email}</p>
      </div>
    </div>
  </div>
)

UserDetails.propTypes = {
  name: PropTypes.string,
  document: PropTypes.string,
  email: PropTypes.string,
}

UserDetails.defaultProps = {
  name: null,
  document: null,
  email: null,
}

export default React.memo(UserDetails)

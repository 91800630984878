import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import AriaModal from 'react-aria-modal'

import CloseIcon from '_assets/images/ic-fechar.svg'
import Button, { ButtonColor, ButtonVariant } from '_components/button'
import ContractIcon from '_assets/images/ic-contract.svg'
import { onMouseDown } from '_utils/helpers'
import { primaryColor } from '_utils/colors'

import styles from './styles.css'

const ChangeContract = ({ closeModal, changeContract }) => {
  const createSvgStyle = useMemo(() => ({ fill: primaryColor }), [])
  const colorStyle = useMemo(() => ({ color: primaryColor }), [])

  return (
    <AriaModal titleText="Alterar o contrato" underlayClass={styles.underlay}>
      <div className={styles.modal}>
        <button
          aria-label="Botão para fechar modal"
          className={styles.close}
          onClick={closeModal}
          onMouseDown={onMouseDown}
          type="button"
        >
          <svg aria-hidden="true" className={styles.image}>
            <use xlinkHref={CloseIcon} />
          </svg>
        </button>
        <div className={styles.content}>
          <svg aria-hidden="true" className={styles.icon} style={createSvgStyle}>
            <use xlinkHref={ContractIcon} />
          </svg>
          <p style={colorStyle} className={styles.title}>
            Alterar o contrato
          </p>
          <p className={styles.info}>
            Tem certeza que deseja fazer alterações no contrato? <br /> Você só terá acesso às
            chaves do imóvel após a assinatura.
          </p>
          <div className={styles.buttons}>
            <Button
              color={ButtonColor.SECONDARY}
              variant={ButtonVariant.BOLD}
              onClick={changeContract}
              className={styles.cancel}
            >
              SOLICITAR
            </Button>
            <Button
              color={ButtonColor.PRIMARY}
              variant={ButtonVariant.BOLD}
              onClick={closeModal}
              className={styles.ok}
            >
              CANCELAR
            </Button>
          </div>
        </div>
      </div>
    </AriaModal>
  )
}

ChangeContract.propTypes = {
  closeModal: PropTypes.func.isRequired,
  changeContract: PropTypes.func.isRequired,
}

export default React.memo(ChangeContract)

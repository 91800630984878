import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
  wrapper: {
    maxWidth: '51.2rem',
    margin: 'auto',
    boxSizing: 'content-box',
    padding: '8rem 2.4rem 2.4rem',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '4rem',
    },
    textAlign: 'center',
    color: theme.palette.text.primary,
  },
  title: {
    paddingBottom: '1.6rem',
    paddingTop: '4rem',
    fontWeight: 700,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.6rem',
    },
  },
  description: {
    maxWidth: '46.4rem',
    paddingBottom: '2.4rem',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.4rem',
      maxWidth: '31.1rem',
    },
  },
  complement: {
    paddingBottom: '3.2rem',
  },
  justification: {
    textAlign: 'left',
    background: theme.palette.gray.gallery,
    margin: '3.6rem auto 3.2rem',
    justifyItems: 'center',
    padding: '1.6rem 1.6rem 0.8rem',
    minWidth: '51.2rem',
    minHeight: '14.1rem',
    justifyContent: 'center',

    [theme.breakpoints.down('md')]: {
      minWidth: 'auto',
      width: '44.0rem',
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: 'auto',
      width: '31.2rem',
      minHeight: '15rem',
    },
  },
  justificationTitle: {
    lineHeight: '2.1rem',
    color: theme.palette.text.primary,
    marginBottom: '0.8rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.4rem',
    },
  },
  subDescriptionJustification: {
    fontWeight: 400,
    justifyContent: 'flex-end',
  },
  horizontalDivision: {
    height: '0.1rem',
    width: '100%',
    margin: '2rem auto',
    backgroundColor: theme.palette.secondary.light,
  },
  button: {
    margin: '2rem 0',
  },
  buttonEnd: {
    margin: '2rem 0',
    color: theme.palette.text.primary,
  },
  building: {
    marginTop: '10.0rem',
  },
}))

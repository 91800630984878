import humps from 'humps'

import { defineAction } from '_utils/redux'
import * as userService from '_services/user'
import { formatCpf } from '_utils/strings'
import { FEATURE_FLAGS } from '_utils/constants'

export const PHONE_LOGIN = defineAction('PHONE_LOGIN')
export const CREATE_OWNER = defineAction('CREATE_OWNER')
export const GET_USER = defineAction('GET_USER')
export const UPDATE_USER = defineAction('UPDATE_USER')
export const SEND_RATING = defineAction('SEND_RATING')
export const GET_LIKED_PROPERTIES = defineAction('GET_LIKED_PROPERTIES')
export const REMOVE_LIKED_PROPERTY = defineAction('REMOVE_LIKED_PROPERTY')
export const UNAUTHORIZED = 'UNAUTHORIZED'
export const LOGOUT = 'LOGOUT'
export const FIREBASE_LOGIN = defineAction('FIREBASE_LOGIN')
export const REQUEST_PHONE_CHANGE = defineAction('REQUEST_PHONE_CHANGE')
export const CONFIRM_REQUEST_PHONE_CHANGE = defineAction('CONFIRM_REQUEST_PHONE_CHANGE')
export const VERIFY_PHONE_LOGIN_CODE = defineAction('VERIFY_PHONE_LOGIN_CODE')
export const GET_AUTH_TOKEN = defineAction('GET_AUTH_TOKEN')

const camelizeResponse = response => humps.camelizeKeys(response)

const parsePayload = payload => {
  const { phoneNumber, cpf, ...restPayload } = payload

  if (!cpf) {
    return payload
  }

  return {
    ...restPayload,
    cpf: formatCpf(cpf),
  }
}

export const phoneLogin = payload => dispatch =>
  dispatch({
    type: PHONE_LOGIN.ACTION,
    payload: userService.phoneLogin(payload),
  })

export const firebaseLogin = payload => dispatch =>
  dispatch({
    type: FIREBASE_LOGIN.ACTION,
    payload: userService.firebaseLogin(payload),
  })

export const verifyPhoneLoginCode = payload => dispatch =>
  dispatch({
    type: VERIFY_PHONE_LOGIN_CODE.ACTION,
    payload: userService.verifyPhoneLoginCode(payload),
  })

export const createOwner = payload => dispatch =>
  dispatch({
    type: CREATE_OWNER.ACTION,
    payload: userService.createOwner(payload).then(camelizeResponse),
  })

export const getUser = () => (dispatch, getState) =>
  dispatch({
    type: GET_USER.ACTION,
    payload: userService.getUser(getState().user.authToken),
  })

export const updateUser = payload => (dispatch, getState) =>
  dispatch({
    type: UPDATE_USER.ACTION,
    payload: userService.updateUser(getState().user.authToken)(parsePayload(payload)),
  })

export const sendRating = payload => (dispatch, getState) =>
  dispatch({
    type: SEND_RATING.ACTION,
    payload: userService.sendRating(getState().user.authToken)(payload),
  })

export const getLikedProperties = () => (dispatch, getState) =>
  dispatch({
    type: GET_LIKED_PROPERTIES.ACTION,
    payload: userService
      .getLikedProperties(getState().user.authToken)()
      .then(camelizeResponse),
  })

export const logout = () => ({
  type: LOGOUT,
})

export const requestPhoneChange = payload => dispatch => {
  dispatch({
    type: REQUEST_PHONE_CHANGE.ACTION,
    payload: FEATURE_FLAGS.customPhoneAuthenticationEnabled
      ? userService.requestResetPhone(payload)
      : userService.requestPhoneChange(payload),
  })
}

export const confirmPhoneChange = payload => dispatch => {
  dispatch({
    type: CONFIRM_REQUEST_PHONE_CHANGE.ACTION,
    payload: userService.confirmPhoneChange(payload),
  })
}

export const getAuthToken = payload => dispatch => {
  dispatch({
    type: GET_AUTH_TOKEN.ACTION,
    payload: userService.getAuthToken(payload),
  })
}

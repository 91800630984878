import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
// import { useSelector } from 'react-redux'
import { Link, Typography } from '@material-ui/core'

import { GENERAL } from '_utils/constants'

import useStyles from './styles'

const PrivacyPolicyLink = ({ className }) => {
  const styles = useStyles()
  // const privacyPolicyFile = useSelector(state => state.saas.privacyPolicyFile)

  return (
    <Typography className={classnames(styles.text, className)} component="strong" variant="h6">
      Ao preencher este formulário concordo com a coleta e tratamento dos meus dados, conforme{' '}
      <Link
        className={styles.link}
        href="/politica-de-privacidade"
        rel="noopener noreferrer"
        target="_blank"
      >
        Política de Privacidade
      </Link>
      , nos termos da Lei 13.709/2018, permitindo desde já eventual armazenamento destes dados e o
      contato comercial da {GENERAL.realEstateAgency}
    </Typography>
  )
}

PrivacyPolicyLink.propTypes = {
  className: PropTypes.string,
}

PrivacyPolicyLink.defaultProps = {
  className: '',
}

export default PrivacyPolicyLink

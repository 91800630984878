import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
  analysisWrapper: {
    gap: '1rem',
    width: '100%',
    display: 'grid',
    alignItems: 'center',
    gridAutoFlow: 'column',
    justifyContent: 'flex-start',
    backgroundColor: theme.palette.gray.gallery,
    padding: '1.8rem 1.7rem',
    borderRadius: '0.4rem',
  },
  checkIcon: {
    width: '2rem',
    height: '2rem',
  },
  bold: {
    fontWeight: 700,
  },
}))

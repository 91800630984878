import { createSelector } from 'reselect'
import { fromJS } from 'immutable'

import { PARTICIPANTS_TYPE, WARRANTIES } from '_utils/constants'

const getWarranties = () => fromJS(WARRANTIES)
const getParam = (state, param) => param

export const warrantRapid = () => WARRANTIES.toJS().find(warranty => warranty.type === 'rapid')
export const getWarrantiesSelector = () => fromJS(WARRANTIES.filter(warranty => !!warranty.enabled))

export const getCurrentWarranty = createSelector(
  [getWarranties, getParam],
  (warranties, currentWarranty) =>
    warranties.filter(warranty => warranty.get('type') === currentWarranty).get(0)
)

export const getWarrantySelector = createSelector(
  [getWarranties, getParam],
  (warranties, infoNeeded) => {
    const [type, participant, considerIncomeParticipant] = infoNeeded
    if (!type || !participant) {
      return []
    }
    const considerIncome =
      considerIncomeParticipant === undefined ? true : considerIncomeParticipant
    const warrantySelected = warranties
      .find(warranty => warranty.get('type') === type)
      .get('documentCategories')
      .filter(document => {
        if (participant === PARTICIPANTS_TYPE.TENANT) {
          return document.get('participant') === participant
        }
        const documentConsiderIncome =
          document.get('considerIncome') === null ? false : document.get('considerIncome')
        return (
          document.get('participant') === participant && documentConsiderIncome === considerIncome
        )
      })
      .map(document => ({
        label: document.get('title'),
        value: document.get('id'),
        description: document.get('description'),
      }))
    return warrantySelected
  }
)

import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import NumberFormat from 'react-number-format'

const NumberFormatCustom = props => {
  const { inputRef, onChange, ...other } = props

  const onValueChange = useCallback(
    values => {
      onChange({
        target: {
          value: values.value,
        },
      })
    },
    [onChange]
  )

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={onValueChange}
      thousandSeparator="."
      decimalSeparator=","
      allowNegative={false}
      isNumericString
    />
  )
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default NumberFormatCustom

import { makeStyles } from '@material-ui/styles'

export default makeStyles(() => ({
  textField: {
    '& .MuiInputBase-root': {
      lineHeight: '1.5',
      alignItems: 'baseline',
    },
    '& .MuiOutlinedInput-root': {
      fontSize: '1.4rem',
      color: '#f5f5f5',
      height: '48px',
    },
    '& .MuiOutlinedInput-input': {
      padding: '14px 20px 20px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '30px',
    },
    '& .MuiInputLabel-outlined': {
      fontSize: 14,
      paddingBottom: '10px',
    },
  },
  size: {
    height: '48px',
  },
}))

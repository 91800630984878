import React, { useMemo, useState, useCallback, Fragment } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Waypoint from 'react-waypoint'

import { useCarouselScroll, useWindowWidth } from '_utils/hooks'
import AngleLeft from '_assets/images/angle-left-solid.svg'
import AngleRight from '_assets/images/angle-right-solid.svg'

import styles from './styles.css'

const CAROUSEL_BREAKPOINT = 650

const SimpleCarousel = ({ className, children, slotClassName }) => {
  const {
    carouselRef,
    scrollLeft,
    scrollRight,
    translateStyle,
    hasScrollLeft,
    hasScrollRight,
  } = useCarouselScroll()
  const windowWidth = useWindowWidth()

  const ArrowLeft = useMemo(
    () => () => (
      <svg
        aria-hidden="true"
        onClick={scrollLeft}
        className={classnames(styles.arrow, styles['arrow-left'])}
      >
        <use xlinkHref={AngleLeft} />
      </svg>
    ),
    [scrollLeft]
  )

  const ArrowRight = useMemo(
    () => () => (
      <svg
        aria-hidden="true"
        onClick={scrollRight}
        className={classnames(styles.arrow, styles['arrow-right'])}
      >
        <use xlinkHref={AngleRight} />
      </svg>
    ),
    [scrollRight]
  )

  const [partialChildren, setPartialChildren] = useState(children.slice(0, 30))

  const getMoreElements = useCallback(lastIndex => children.slice(lastIndex + 1, lastIndex + 30), [
    children,
  ])

  const onEnter = useCallback(() => {
    const moreElements = getMoreElements(partialChildren.length)
    setPartialChildren([...partialChildren, ...moreElements])
  }, [partialChildren, getMoreElements])

  return (
    <div className={classnames(styles.carousel, className)}>
      <div className={styles.wrapper}>
        <div className={styles.container} ref={carouselRef} style={translateStyle}>
          {windowWidth < CAROUSEL_BREAKPOINT
            ? partialChildren.map((child, index) => {
                const hasWaypoint = index === partialChildren.length - 8

                return (
                  <Fragment>
                    {hasWaypoint && <Waypoint horizontal onEnter={onEnter} />}
                    <div className={classnames(styles.slot, slotClassName)}>{child}</div>
                  </Fragment>
                )
              })
            : children.map(child => (
                <div className={classnames(styles.slot, slotClassName)}>{child}</div>
              ))}
        </div>
      </div>
      {hasScrollLeft && <ArrowLeft />}
      {hasScrollRight && <ArrowRight />}
    </div>
  )
}

SimpleCarousel.propTypes = {
  className: PropTypes.string,
  slotClassName: PropTypes.string,
  children: PropTypes.node.isRequired,
}

SimpleCarousel.defaultProps = {
  className: '',
  slotClassName: '',
}

export default React.memo(SimpleCarousel)

import React from 'react'
import PropTypes from 'prop-types'
import { Router } from '@reach/router'

import PropertyDetail from './property-detail'
import Search from './search'

const Breadcrumb = ({ className, basepath, searchClassName }) => (
  <Router basepath={basepath} primary={false} className={className}>
    <PropertyDetail path="imovel/:id" />
    <Search path="buscar" className={searchClassName} />
  </Router>
)

Breadcrumb.propTypes = {
  className: PropTypes.string,
  basepath: PropTypes.string,
  searchClassName: PropTypes.string,
}

Breadcrumb.defaultProps = {
  className: undefined,
  basepath: '/',
  searchClassName: '',
}

export default Breadcrumb

import React from 'react'

import FinishedIcon from '_assets/images/finished.svg'
import Button, { ButtonColor, ButtonVariant } from '_components/button'

import styles from './styles.css'

const WarrantyFinished = () => (
  <div className={styles.container}>
    <div className={styles.content}>
      <svg alt="Pronto" className={styles.icon}>
        <use xlinkHref={FinishedIcon} />
      </svg>

      <h1 className={styles.title}>Oba, falta pouco para você alugar o seu imóvel.</h1>

      <p className={styles.description}>Aguarde, em breve consultor entrará em contato com você.</p>

      <Button
        color={ButtonColor.PRIMARY}
        variant={ButtonVariant.BOLD}
        to="/minhas-negociacoes"
        aria-label="Ir para minhas negociações"
      >
        OBRIGADO
      </Button>
    </div>
  </div>
)
export default React.memo(WarrantyFinished)

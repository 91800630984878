import React from 'react'
import classnames from 'classnames'
import { Grid, Typography, SvgIcon } from '@material-ui/core'
import { useParams } from '@reach/router'
import { useSelector } from 'react-redux'

import RoundPhoneIcon from '_assets/images/round-phone.svg'
import withAuth from '_hocs/with-auth'
import { getLeaseById } from '_modules/leases/selectors'
import { getExternalId } from '_utils/helpers'
import ExternalIdButton from '_components/material/button/external-id-button'
import useGetLease from '_hooks/use-get-lease'
import Building from '_components/building-animated'

import useStyles from './styles'

const ProposalFeedback = () => {
  const styles = useStyles()
  const { id: leaseId } = useParams()
  const [isLoading] = useGetLease(leaseId)
  const lease = useSelector(state => getLeaseById(state, leaseId))
  const externalId =
    lease &&
    lease.listing &&
    lease.listing.get('externalId') &&
    getExternalId(lease.listing.get('externalId'))
  if (isLoading) {
    return (
      <Grid container className={styles.loading} justify="center" alignItems="center">
        <Building isSmall />
      </Grid>
    )
  }
  return (
    <Grid component="section" className={styles.main}>
      <Grid className={styles.content}>
        <ExternalIdButton
          listingType={lease.listing.get('listingType')}
          externalId={externalId}
          slug={lease.listing.get('slug')}
        />
        <SvgIcon aria-hidden="true" className={styles.phoneIcon} viewBox={RoundPhoneIcon.viewBox}>
          <use xlinkHref={RoundPhoneIcon} />
        </SvgIcon>
        <Typography
          component="h1"
          variant="h3"
          className={classnames(styles.title, styles.mainTitle)}
        >
          Falta pouco para você alugar este imóvel!
        </Typography>
        <Typography component="p" className={styles.text}>
          Um consultor entrará em contato para informar os próximos passos da locação.
        </Typography>
      </Grid>
    </Grid>
  )
}

export default withAuth(ProposalFeedback)

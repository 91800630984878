import React from 'react'
import TextField from '@material-ui/core/TextField'

import useStyles from './styles'

const MultilineTextField = ({ ...otherProps }) => {
  const classes = useStyles()

  return (
    <TextField
      className={classes.textField}
      multiline
      fullWidth
      variant="outlined"
      {...otherProps}
    />
  )
}

export default MultilineTextField

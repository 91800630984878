import React from 'react'
import PropTypes from 'prop-types'

import { shadeColor } from '_utils/colors'

const MedalIcon = ({ className, primaryColor }) => {
  const lighterColor = shadeColor(primaryColor)

  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="112" height="112">
      <g clipPath="url(#a)">
        <path
          fill={primaryColor}
          d="M21.299 69.319L17.226 76.4 8 92.363l16.679-.885 7.563 14.855 11.665-20.194c-9.365-2.822-17.316-8.853-22.608-16.82zM103.918 92.252L90.564 69.208C85.3 77.176 77.35 83.234 68.012 86.083l11.72 20.25 7.563-14.855 16.679.885-.056-.11z"
        />
        <path
          fill={lighterColor}
          d="M22.267 67.659l-.97 1.66c5.292 7.94 13.243 13.97 22.608 16.792l1.523-2.628c-9.447-2.435-17.593-8.133-23.161-15.824zM90.592 69.208l-.97-1.66c-5.541 7.69-13.659 13.417-23.106 15.907l1.274 2.186.25.442c9.336-2.85 17.288-8.908 22.552-16.875z"
        />
        <path
          fill={primaryColor}
          d="M22.434 67.631a42.04 42.04 0 0 0 14.02 12.255 41.564 41.564 0 0 0 9.142 3.57l-.027.054a42.271 42.271 0 0 0 10.472 1.328c3.685 0 7.232-.47 10.612-1.383l-.028-.055A41.441 41.441 0 0 0 89.73 67.493l.028.028a41.094 41.094 0 0 0 7.758-22.63C96.74 22.733 78.483 5 56.069 5S15.397 22.733 14.594 44.92c.305 8.492 3.158 16.321 7.84 22.739v-.028z"
        />
        <path
          fill={primaryColor}
          d="M36.316 79.886c-5.569-2.987-10.362-7.164-14.019-12.255l-.027.028c5.568 7.69 13.714 13.39 23.161 15.824l.028-.056a38.917 38.917 0 0 1-9.143-3.54zM66.488 83.427l.028.056c9.448-2.49 17.565-8.216 23.106-15.907l-.027-.028a41.577 41.577 0 0 1-23.107 15.88z"
        />
        <path
          fill="#fff"
          d="M80.922 40.023c1.109-1.08.499-2.988-1.053-3.21L65.546 34.74c-.61-.11-1.136-.47-1.413-1.051l-6.4-12.947c-.693-1.411-2.715-1.411-3.408 0l-6.373 12.947c-.276.553-.83.94-1.412 1.05l-14.297 2.076c-1.551.22-2.16 2.13-1.052 3.209L41.525 50.12a1.98 1.98 0 0 1 .554 1.66L39.64 66c-.277 1.548 1.357 2.738 2.743 1.99l12.8-6.721a1.836 1.836 0 0 1 1.773 0l12.8 6.722c1.385.72 2.992-.443 2.743-1.992l-2.466-14.22a1.85 1.85 0 0 1 .554-1.659l10.334-10.097z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h96v101.333H0z" transform="translate(8 5)" />
        </clipPath>
      </defs>
    </svg>
  )
}

MedalIcon.propTypes = {
  primaryColor: PropTypes.string,
  className: PropTypes.string,
}

MedalIcon.defaultProps = {
  primaryColor: '',
  className: '',
}

export default MedalIcon

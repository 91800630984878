import React from 'react'
import { useSelector } from 'react-redux'

import IconMessage from '_assets/images/icon-message.svg'

import styles from './styles.css'

const RecommendationsEnd = () => {
  const email = useSelector(state => state.saas.email)

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <svg aria-hidden="true" className={styles.icon}>
          <use xlinkHref={IconMessage} />
        </svg>
        <p className={styles['text-one']}>Você encerrou esse interesse</p>
        <p className={styles['text-two']}>
          Você não receberá mais recomendações personalizadas para esse interesse
        </p>
        <div className={styles.line} />
        <p className={styles['text-three']}>
          Dúvidas ou sugestões entre em contato conosco pelo e-mail <br />
          {email && <span>{email}</span>}
        </p>
      </div>
    </div>
  )
}

export default React.memo(RecommendationsEnd)

import React, { useState, useRef, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import FormControl from '@material-ui/core/FormControl'
import InputMask from 'react-input-mask'
import { FormHelperText } from '@material-ui/core'

import styles from './styles.css'

const FormField = ({
  label,
  placeholder,
  type,
  className,
  select,
  id,
  children,
  value,
  name,
  mask,
  handleChange,
  error,
}) => {
  const [labelWidth, setLabelWidth] = useState(0)
  const labelRef = useRef(null)

  useEffect(() => {
    setLabelWidth(labelRef.current.offsetWidth)
  }, [])

  const inputStyles = useMemo(
    () => ({
      root: styles['input-outlined'],
      notchedOutline: styles.notch,
    }),
    []
  )

  const helperTextStyles = useMemo(
    () => ({
      error: styles.error,
    }),
    []
  )

  const inputProps = useMemo(
    () => ({
      classes: inputStyles,
      id,
      type,
      placeholder,
      labelWidth,
      name,
      error: !!error,
    }),
    [error, id, inputStyles, labelWidth, name, placeholder, type]
  )

  const InputElement = useMemo(
    () =>
      mask ? (
        <InputMask mask={mask} onChange={handleChange} value={value}>
          {inheritedProps => <OutlinedInput {...inheritedProps} {...inputProps} />}
        </InputMask>
      ) : (
        <OutlinedInput onChange={handleChange} value={value} {...inputProps} />
      ),
    [mask, handleChange, value, inputProps]
  )

  return (
    <FormControl className={classnames(styles['input-container'], className)} variant="outlined">
      <InputLabel className={styles['input-label']} ref={labelRef} htmlFor={id}>
        {label}
      </InputLabel>
      {select ? (
        <Select id={id} value={value} input={InputElement} classes={{ root: styles.select }}>
          {children}
        </Select>
      ) : (
        InputElement
      )}
      {!!error && (
        <FormHelperText classes={helperTextStyles} error={error}>
          {error}
        </FormHelperText>
      )}
    </FormControl>
  )
}

FormField.propTypes = {
  placeholder: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  select: PropTypes.bool,
  children: PropTypes.node,
  value: PropTypes.string,
  handleChange: PropTypes.func,
  name: PropTypes.string,
  error: PropTypes.bool,
  mask: PropTypes.string,
}

FormField.defaultProps = {
  placeholder: '',
  label: '',
  type: '',
  className: '',
  select: false,
  children: null,
  value: '',
  handleChange: () => undefined,
  name: '',
  error: false,
  mask: '',
}

export default React.memo(FormField)

import React from 'react'
import PropTypes from 'prop-types'

import RightArrow from '_assets/images/angle-right-solid.svg'
import { onMouseDown } from '_utils/helpers'

import styles from './styles.css'

const NextArrow = ({ onClick, disabled, primaryColor }) => (
  <div className={styles['arrow-container']}>
    <button
      aria-label="Próxima imagem"
      type="button"
      onClick={onClick}
      onMouseDown={onMouseDown}
      className={styles.arrow}
      disabled={disabled}
    >
      <svg aria-hidden="true" className={styles.arrow} style={{ fill: primaryColor }}>
        <use xlinkHref={RightArrow} />
      </svg>
      Próximo
    </button>
  </div>
)

NextArrow.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool.isRequired,
  primaryColor: PropTypes.string.isRequired,
}

NextArrow.defaultProps = {
  onClick: () => {},
}

export default NextArrow

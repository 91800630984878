import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ palette }) => ({
  modal: {
    minHeight: '19.2rem',
    maxHeight: '41.5rem',
    width: '56rem',
    boxSizing: 'border-box',
  },
  description: {
    fontSize: '1.6rem',
  },
  title: {
    '& > .MuiTypography-root': {
      marginTop: '0.8rem',
      fontSize: '2rem',
    },
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    padding: 0,
    borderTop: `0.1rem solid ${palette.divider}`,
  },
  button: {
    flex: 1,
    height: '5.6rem',
  },
  buttonLabel: {
    fontSize: '1.4rem',
    fontWeight: 'bold',
  },
}))

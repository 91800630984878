import PropTypes from 'prop-types'

import { Booking } from '_models'

export const formShape = PropTypes.shape({
  fullName: PropTypes.string,
  cpf: PropTypes.string,
  email: PropTypes.string,
  dateOfBirth: PropTypes.string,
  rg: PropTypes.string,
  issuingAgency: PropTypes.string,
  gender: PropTypes.string,
  civilStatus: PropTypes.string,
  nationality: PropTypes.string,
  occupation: PropTypes.string,
  currentAddress: PropTypes.string,
})

export const formInitialShape = PropTypes.shape({
  fullNameError: PropTypes.string,
  emailError: PropTypes.string,
  dateOfBirthError: PropTypes.bool,
  rgError: PropTypes.string,
  issuingAgencyError: PropTypes.string,
  cpfError: PropTypes.string,
  genderError: PropTypes.string,
  maritalStatusError: PropTypes.string,
  nationalityError: PropTypes.string,
  occupationError: PropTypes.string,
  currentAddressError: PropTypes.string,
})

export const planInfoShape = PropTypes.shape({
  type: PropTypes.string,
  typeOfExclusivity: PropTypes.string,
  adminTaxes: PropTypes.string,
  advantages: PropTypes.arrayOf(PropTypes.string),
  description: PropTypes.string,
})

export const listingInformationShape = PropTypes.shape({
  externalId: PropTypes.string,
  bookingStatus: PropTypes.string,
  booking: PropTypes.instanceOf(Booking),
  notes: PropTypes.string,
  state: PropTypes.string,
  city: PropTypes.string,
  address: PropTypes.string,
  observation: PropTypes.string,
  listingType: PropTypes.string,
  neighborhood: PropTypes.string,
  rentPrice: PropTypes.number,
  taxes: PropTypes.number,
  condominiumFee: PropTypes.number,
  bedrooms: PropTypes.number,
  bathrooms: PropTypes.number,
  parkingSpots: PropTypes.number,
  livingArea: PropTypes.number,
  pictures: PropTypes.oneOfType(PropTypes.array),
  furnished: PropTypes.string,
  insurance: PropTypes.number,
  streetNumber: PropTypes.string,
  id: PropTypes.number,
})

export const arrayValuesShape = PropTypes.arrayOf(
  PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  })
)

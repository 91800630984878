import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import LaunchIcon from '@material-ui/icons/Launch'

import { getPropertyType } from '_utils/property'
import Button from '_components/material/button'
import { PROPERTY_TYPES } from '_utils/constants'

import useStyles from './styles'

const ExternalIdButton = ({ className, externalId, hasEndIcon, listingType, slug }) => {
  const styles = useStyles()
  const type = Object.values(PROPERTY_TYPES).includes(listingType)
    ? getPropertyType(listingType)
    : listingType
  return (
    <Button
      className={classnames(styles.button, className)}
      color="secondary"
      component="a"
      href={`/imovel/${slug}`}
      target="_blank"
      rel="noopener noreferrer"
      endIcon={hasEndIcon && <LaunchIcon className={styles.icon} fontSize="small" />}
    >
      {type} {externalId}
    </Button>
  )
}
ExternalIdButton.propTypes = {
  className: PropTypes.string,
  externalId: PropTypes.string.isRequired,
  hasEndIcon: PropTypes.bool,
  listingType: PropTypes.string,
  slug: PropTypes.string,
}
ExternalIdButton.defaultProps = {
  className: '',
  hasEndIcon: true,
  listingType: 'Imóvel',
  slug: '',
}
export default React.memo(ExternalIdButton)

import React, { useCallback, useEffect, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { navigate } from '@reach/router'
import classnames from 'classnames'

import { getBooking } from '_modules/booking/actions'
import { getBookingById } from '_modules/booking/selectors'
import IconDocument from '_assets/images/ic-doc-disapproved.svg'
import Button, { ButtonColor, ButtonVariant, ButtonFormat } from '_components/button'
import withAuth from '_hocs/with-auth'
import ModalNegotiation from '_components/modal-negotiation'
import ModalReserved from '_components/modal-reserved'

import styles from './styles.css'

const ProposalDisapproved = ({ id }) => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.user)
  const booking = useSelector(state => getBookingById(state, id))
  const activeLease = useMemo(() => booking && booking.getIn(['lease', 'id']), [booking])
  const [closeDeal, setCloseDeal] = useState(false)
  const reserved = useMemo(() => booking && booking.getIn(['listing', 'reserved']), [booking])

  const [reservedModal, setReservedModal] = useState(false)

  const onCloseModal = useCallback(() => {
    setCloseDeal(false)
    setReservedModal(false)
  }, [])

  useEffect(() => {
    if (!booking) {
      dispatch(getBooking(id))
    }
  }, [booking, dispatch, id])

  const onClickConfirm = useCallback(() => {
    if (reserved) {
      setReservedModal(true)
      return
    }
    if (user.activeLease && user.activeLease !== activeLease) {
      if (!closeDeal) {
        setCloseDeal(true)
        return
      }
    }

    if (!booking) return
    setCloseDeal(false)
    navigate(`/minhas-negociacoes/${booking.getIn(['listing', 'externalId'])}`, {
      state: { listing: booking.get('listing').toJS() },
    })
  }, [activeLease, booking, closeDeal, reserved, user.activeLease])

  const onClickMakeProposal = useCallback(() => {
    navigate(`/proposta/resumo-locacao/${id}`)
  }, [id])

  return (
    <div className={styles.container}>
      <ModalNegotiation
        isOpen={closeDeal}
        onClose={onCloseModal}
        onConfirmNegotiation={onClickConfirm}
      />
      <ModalReserved openModal={reservedModal} closeModal={onCloseModal} />
      <div className={styles.content}>
        <svg aria-label="Ícone de um documento" className={styles.icon}>
          <use xlinkHref={IconDocument} />
        </svg>
        <p className={styles.title}>Sentimos muito, sua proposta não foi aprovada :(</p>
        <p className={styles['sub-title']}>
          Faça uma nova proposta ou siga com a análise cadastral clicando em &quot;Aceitar e Iniciar
          Negociação&quot;
        </p>
        <Button
          color={ButtonColor.PRIMARY}
          variant={ButtonVariant.BOLD}
          ButtonFormat={ButtonFormat.ROUNDED}
          className={styles.button}
          onClick={onClickConfirm}
        >
          ACEITAR E INICIAR NEGOCIAÇÃO
        </Button>
        <Button
          variant={ButtonVariant.BOLD}
          ButtonFormat={ButtonFormat.ROUNDED}
          className={classnames(styles.button, styles['button-gray'])}
          onClick={onClickMakeProposal}
        >
          FAZER NOVA PROPOSTA
        </Button>
      </div>
    </div>
  )
}

ProposalDisapproved.propTypes = {
  id: PropTypes.number.isRequired,
}

export default withAuth(React.memo(ProposalDisapproved))

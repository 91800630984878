import React, { useState, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Dialog, Chip, Grid, IconButton } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { useDispatch } from 'react-redux'
import { navigate } from '@reach/router'

import {
  sendBookingFeedback,
  SEND_BOOKING_FEEDBACK,
  updateBookingFeedback,
  UPDATE_BOOKING_FEEDBACK,
} from '_modules/booking/actions'
import { BOOKING_FEEDBACK_TYPE, BOOKING_FEEDBACK_USER_ACTION } from '_utils/constants'
import useOnSuccessCall from '_hooks/use-on-success'

import VisitFeedbackStepThree from './visit-feedback-step-three'
import VisitFeedbackStepOne from './visit-feedback-step-one'
import VisitFeedbackStepTwo from './visit-feedback-step-two'
import useStyles from './styles'

const RATING_STEPS = {
  ONE: 1,
  TWO: 2,
  THREE: 3,
}

const TRUE = 'true'

const VisitFeedbackModal = ({
  bookingId,
  feedbackId,
  isNegotiationFlow,
  onClose,
  userAction,
  exitRoute,
  happened,
  isReportProblemFlow,
}) => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const showSteps = happened || happened === undefined

  const [step, setStep] = useState(showSteps ? RATING_STEPS.ONE : RATING_STEPS.THREE)

  const onCloseClick = useCallback(() => {
    if ((isNegotiationFlow && userAction === TRUE) || isReportProblemFlow) {
      onClose()
      return
    }
    navigate(exitRoute)
  }, [isReportProblemFlow, isNegotiationFlow, userAction, exitRoute, onClose])

  const handleNextStep = useCallback(() => {
    if (step === RATING_STEPS.THREE && !isReportProblemFlow) {
      onCloseClick()
      return
    }
    if (step === RATING_STEPS.THREE && isReportProblemFlow) {
      navigate(`/problema-reportado/${feedbackId}`)
      return
    }
    setStep(prevStep => prevStep + 1)
  }, [feedbackId, isReportProblemFlow, onCloseClick, step])

  const onFeedbackClick = useCallback(
    values => {
      const payload = {
        ...values,
        type: isReportProblemFlow
          ? BOOKING_FEEDBACK_TYPE.CLIENT_ISSUE
          : BOOKING_FEEDBACK_TYPE.CLIENT_REVIEW,
      }
      if (step === RATING_STEPS.ONE || (isReportProblemFlow && !showSteps)) {
        const newPayload = Object.values(BOOKING_FEEDBACK_USER_ACTION).includes(userAction)
          ? { ...payload, userAction }
          : payload
        dispatch(sendBookingFeedback(isReportProblemFlow ? newPayload : payload, bookingId))
        return
      }
      dispatch(updateBookingFeedback(payload, bookingId, feedbackId))
    },
    [bookingId, dispatch, feedbackId, isReportProblemFlow, showSteps, step, userAction]
  )
  const [isSendLoading] = useOnSuccessCall(SEND_BOOKING_FEEDBACK.ACTION, handleNextStep)
  const [isUpdateLoading] = useOnSuccessCall(UPDATE_BOOKING_FEEDBACK.ACTION, handleNextStep)
  const isLoading = isSendLoading || isUpdateLoading

  const feedbackSteps = useMemo(() => {
    const props = { onNextClick: onFeedbackClick, isLoading }

    return {
      [RATING_STEPS.ONE]: <VisitFeedbackStepOne {...props} />,
      [RATING_STEPS.TWO]: <VisitFeedbackStepTwo {...props} />,
      [RATING_STEPS.THREE]: <VisitFeedbackStepThree hasProblem={isReportProblemFlow} {...props} />,
    }
  }, [isReportProblemFlow, isLoading, onFeedbackClick])

  return (
    <Dialog open onClose={onCloseClick} aria-label="Avaliação da visita" className={styles.dialog}>
      <Grid className={styles.content}>
        <Grid component="header" className={styles.header}>
          {showSteps && <Chip label={`Passo ${step}/3`} className={styles.stepNumber} />}
          <IconButton
            className={styles.closeButton}
            aria-label="Fechar modal"
            size="small"
            onClick={onCloseClick}
          >
            <Close />
          </IconButton>
        </Grid>
        <Grid className={styles.stepContent}>{feedbackSteps[step]}</Grid>
      </Grid>
    </Dialog>
  )
}
VisitFeedbackModal.propTypes = {
  bookingId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  feedbackId: PropTypes.number,
  isNegotiationFlow: PropTypes.bool,
  onClose: PropTypes.func,
  userAction: PropTypes.string,
  exitRoute: PropTypes.string,
  happened: PropTypes.bool,
  isReportProblemFlow: PropTypes.bool,
}
VisitFeedbackModal.defaultProps = {
  feedbackId: null,
  isNegotiationFlow: false,
  onClose: () => {},
  userAction: '',
  exitRoute: '/buscar',
  happened: undefined,
  isReportProblemFlow: false,
}

export default React.memo(VisitFeedbackModal)

import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ breakpoints, layers, palette, typography }) => ({
  boldText: {
    fontWeight: 'bold',
  },
  button: {
    background: palette.success.main,
    borderRadius: '0.4rem',
    color: 'white',
    maxHeight: '4rem',
    padding: '1rem 3.6rem',

    '&:hover': {
      background: palette.success.dark,
    },

    [breakpoints.down('sm')]: {
      marginTop: '1.6rem',
    },
  },
  container: {
    position: 'fixed',
    bottom: '1.6rem',
    width: '100%',
    zIndex: layers.cookiesModal,
  },
  content: {
    background: 'white',
    border: `0.1rem solid ${palette.gray.main}`,
    borderRadius: '0.8rem',
    boxShadow: '0 0 3rem rgba(196, 196, 196, 0.2)',
    marginBottom: '1.6rem',
    maxWidth: '104rem',
    padding: '1.9rem 3.2rem',
    width: '100%',

    [breakpoints.down('sm')]: {
      marginBottom: '2.4rem',
      padding: '1.6rem',
      width: '86.66%',
    },
  },
  link: {
    ...typography.h5,
    color: palette.text.primary,
    fontWeight: 'bold',

    [breakpoints.down('sm')]: {
      ...typography.h6,
    },
  },
  text: {
    fontWeight: 'normal',
    maxWidth: '80%',

    [breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
}))

import React, { useState, useCallback, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Typography, Grid, IconButton, SvgIcon } from '@material-ui/core'
import { StarBorder, Star } from '@material-ui/icons'

import HeaderImg from '_assets/images/ic-head.svg'
import { onMouseDown } from '_utils/helpers'
import Button from '_components/material/button'
import { BOOKING_USER_TYPE } from '_utils/constants'

import useStyles from './styles'

const VisitFeedbackStepOne = ({ onNextClick, isLoading }) => {
  const styles = useStyles()

  const [staffRating, setRating] = useState(null)

  const handleStarClick = useCallback(event => {
    const { id } = event.currentTarget.dataset
    setRating(id)
  }, [])

  const sendFeedback = useCallback(
    event => {
      const { name } = event.currentTarget
      if (name === BOOKING_USER_TYPE.STAFF) {
        onNextClick({ staffRating })
        return
      }
      onNextClick({ unaccompanied: false })
    },
    [onNextClick, staffRating]
  )

  return (
    <Fragment>
      <Grid component="header" className={styles.header}>
        <Grid className={styles.imageBackground}>
          <SvgIcon aria-hidden="true" className={styles.image} viewBox={HeaderImg.viewBox}>
            <use xlinkHref={HeaderImg} />
          </SvgIcon>
        </Grid>
        <Typography component="h1" variant="h4" className={styles.title}>
          O que achou do(a) corretor(a)?
        </Typography>
        <Typography component="p" variant="subtitle1" className={styles.description}>
          O seu feedback nos ajuda a melhorar
        </Typography>
      </Grid>
      <Grid className={styles.starsContainer}>
        {[...Array(5)].map((star, index) => (
          <IconButton
            key={`star-${index + 1}`}
            aria-label={`Botão ${index + 1} estrela`}
            data-id={index + 1}
            onClick={handleStarClick}
            onMouseDown={onMouseDown}
          >
            {staffRating >= index + 1 ? (
              <Star className={styles.star} />
            ) : (
              <StarBorder className={styles.emptyStar} />
            )}
          </IconButton>
        ))}
      </Grid>
      <Button
        variant="contained"
        fullWidth
        disableElevation
        color="primary"
        className={styles.button}
        disabled={!staffRating}
        onClick={sendFeedback}
        isLoading={isLoading}
        name={BOOKING_USER_TYPE.STAFF}
      >
        Próximo
      </Button>
      <Button
        fullWidth
        color="secondary"
        onClick={sendFeedback}
        name={BOOKING_USER_TYPE.UNACCOMPANIED}
      >
        Minha visita não foi acompanhada
      </Button>
    </Fragment>
  )
}

VisitFeedbackStepOne.propTypes = {
  onNextClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
}

VisitFeedbackStepOne.defaultProps = {
  isLoading: false,
}

export default React.memo(VisitFeedbackStepOne)

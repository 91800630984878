import qs from 'qs'
import { Record, List, OrderedMap } from 'immutable'

import { createReducer } from '_utils/redux'
import { LOGOUT } from '_modules/user/actions'
import { Model, Property } from '_models/'
import { getPropertyDefaultTitle } from '_utils/property'

import {
  SET_FILTER,
  GET_PROPERTIES,
  SET_SCROLL,
  RESET_SEARCH,
  DELETE_OTHER_FILTERS,
} from './actions'

class QueryModel extends Model({
  locations: List(),
  establishments: List(),
  bedrooms: undefined,
  parkingSpots: undefined,
  furnished: undefined,
  city: undefined,
  neighborhoods: [],
  price: undefined,
  pets: undefined,
  services: undefined,
  minArea: undefined,
  maxArea: undefined,
  tempInputValue: undefined,
  listingType: undefined,
  externalId: undefined,
  numberOfEnsuites: undefined,
  bathrooms: undefined,
  purposeType: undefined,
  taxesIncluded: true,
}) {}

export const INITIAL_QUERY = new QueryModel()

const INITIAL_STATE = new Record({
  nextPage: undefined,
  query: INITIAL_QUERY,
  scroll: undefined,
  exactSize: undefined,
  partialSize: undefined,
  results: OrderedMap(),
  partialResults: OrderedMap(),
  exactResults: OrderedMap(),
})()

const transformOrderedMap = data =>
  OrderedMap(
    data.map(payload => [
      payload.id,
      new Property({ ...payload, slug: getPropertyDefaultTitle(payload, true) }),
    ])
  )

// Reducer
const search = createReducer(INITIAL_STATE, {
  [SET_FILTER]: (state, { payload }) => state.set('query', state.query.mergeProps(payload)),
  [SET_SCROLL]: (state, { payload }) => state.set('scroll', payload),

  [GET_PROPERTIES.PENDING]: (state, { meta }) => {
    if (meta.loadMore || (meta && meta.isRecommendation)) {
      return state
    }
    return state
      .set('results', OrderedMap())
      .set('partialResults', OrderedMap())
      .set('exactResults', OrderedMap())
      .set('exactSize', 0)
      .set('partialSize', 0)
      .set('nextPage', undefined)
      .set('scroll', undefined)
  },

  [GET_PROPERTIES.FULFILLED]: (state, { payload }) => {
    const { results, count, next, exact } = payload

    const parsedQuery = qs.parse((next || '').split('?').pop())

    const exactResults = transformOrderedMap(results.filter(result => result.exact))
    const partialResults = transformOrderedMap(results.filter(result => !result.exact))
    return state
      .set('nextPage', parsedQuery.page)
      .set('exactSize', exact)
      .set('partialSize', count - exact)
      .mergeIn(['exactResults'], exactResults)
      .mergeIn(['partialResults'], partialResults)
  },

  [RESET_SEARCH]: () => INITIAL_STATE,

  [DELETE_OTHER_FILTERS]: state => {
    const newQuery = state.getIn(['query', 'city'], state.query.city)
    return state.set('query', INITIAL_QUERY).setIn(['query', 'city'], newQuery)
  },

  [LOGOUT]: () => INITIAL_STATE,
})

export default search

import React, { useMemo, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { navigate } from '@reach/router'

import BaloonIcon from '_assets/images/ic-documentcao-aprovado.svg'
import Button, { ButtonColor, ButtonVariant } from '_components/button'
import { getLeaseById, checkIsLoadingLease } from '_modules/leases/selectors'
import { getLease } from '_modules/leases/actions'
import { usePrevious } from '_utils/hooks'
import { primaryColor } from '_utils/colors'

import styles from './styles.css'

const DocumentationApproved = ({ id }) => {
  const lease = useSelector(state => getLeaseById(state, id))
  const isLoadingLease = useSelector(checkIsLoadingLease)
  const dispatch = useDispatch()

  const createSVGStyle = useMemo(() => ({ fill: primaryColor }), [])

  const wasLoadingLease = usePrevious(isLoadingLease)

  const contracts = lease.get('contracts').toArray() || []
  const contractId = contracts.length ? contracts[0].get('id') : '-'

  const onNextClick = useCallback(() => {
    navigate(`/minhas-negociacoes/${id}/contrato/${contractId}/antecipacao/`)
  }, [contractId, id])

  useEffect(() => {
    if (id) {
      dispatch(getLease(id))
    }
  }, [dispatch, id])

  useEffect(() => {
    // TO-DO: Check the error state
    if (wasLoadingLease && !isLoadingLease && !lease) {
      navigate('/minhas-negociacoes')
    }
  }, [isLoadingLease, lease, wasLoadingLease])

  if (!lease) {
    // TO-DO: Add a loading state
    return null
  }

  return (
    <div className={styles.main}>
      <div className={styles.content}>
        <svg aria-hidden="true" className={styles.icon} style={createSVGStyle}>
          <use xlinkHref={BaloonIcon} />
        </svg>
        <h1>Dados aprovados!</h1>
        <p>O contrato já está disponível para assinatura!</p>
        <Button
          color={ButtonColor.PRIMARY}
          variant={ButtonVariant.BOLD}
          className={styles.button}
          onClick={onNextClick}
        >
          IR PARA O CONTRATO
        </Button>
      </div>
    </div>
  )
}

DocumentationApproved.propTypes = {
  id: PropTypes.string.isRequired,
}

export default React.memo(DocumentationApproved)

import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import checkIcon from '_assets/images/ic-check.svg'
import { WARRANTY_TRANSLATION, WARRANTY_TYPES } from '_utils/constants'

import styles from './styles.css'
// TODO: REfactor to Material UI
const WarrantyType = ({ type, className }) => {
  if (type === WARRANTY_TYPES.RAPID) return null

  return (
    <div className={classnames(styles['main-title'], className)}>
      <svg aria-hidden="true" className={styles.icon}>
        <use xlinkHref={checkIcon} />
      </svg>
      <p className={styles.description}>
        Você escolheu a garantia{' '}
        <strong className={styles.type}>{WARRANTY_TRANSLATION[type]}</strong>
      </p>
    </div>
  )
}

WarrantyType.propTypes = {
  type: PropTypes.string.isRequired,
  className: PropTypes.shape({}),
}

WarrantyType.defaultProps = {
  className: '',
}

export default React.memo(WarrantyType)

import humps from 'humps'

import { defineAction } from '_utils/redux'
import * as serviceProposal from '_services/proposal'

export const SEND_PROPOSAL = defineAction('SEND_PROPOSAL')
export const REMOVE_PROPOSAL = defineAction('REMOVE_PROPOSAL')
export const SEND_VALUE_PROPOSAL = defineAction('SEND_VALUE_PROPOSAL')
export const SEND_MODIFICATION_PROPOSAL = defineAction('SEND_MODIFICATION_PROPOSAL')
export const GET_ALL_PROPOSALS = defineAction('GET_ALL_PROPOSALS')
export const SEND_APPROVATION_VALUE = defineAction('SEND_APPROVATION_VALUE')
export const SEND_APPROVATION_MODIFICATION = defineAction('SEND_APPROVATION_MODIFICATION')

const camelizeResponse = response => humps.camelizeKeys(response)

const decamelizeResponse = response => humps.decamelizeKeys(response)

export const sendProposal = (id, payload) => (dispatch, getState) =>
  dispatch({
    type: SEND_PROPOSAL.ACTION,
    payload: serviceProposal.sendProposal(getState().user.authToken)(
      id,
      decamelizeResponse(payload)
    ),
  })
export const removeProposal = (idLease, idProposal) => (dispatch, getState) =>
  dispatch({
    type: REMOVE_PROPOSAL.ACTION,
    payload: serviceProposal.deleteProposal(getState().user.authToken)(idLease, idProposal),
  })

export const sendValueProposal = (leaseId, proposalId, payload) => (dispatch, getState) =>
  dispatch({
    type: SEND_VALUE_PROPOSAL.ACTION,
    payload: serviceProposal.sendValue(getState().user.authToken)(
      leaseId,
      proposalId,
      decamelizeResponse(payload)
    ),
  })

export const sendModificationProposal = (leaseId, proposalId, payload) => (dispatch, getState) =>
  dispatch({
    type: SEND_MODIFICATION_PROPOSAL.ACTION,
    payload: serviceProposal.sendModifications(getState().user.authToken)(
      leaseId,
      proposalId,
      decamelizeResponse(payload)
    ),
  })

export const sendApprovationValue = (leaseId, proposalId, payload) => (dispatch, getState) =>
  dispatch({
    type: SEND_APPROVATION_VALUE.ACTION,
    payload: serviceProposal.sendApprovationValue(getState().user.authToken)(
      leaseId,
      proposalId,
      decamelizeResponse(payload)
    ),
  })

export const sendApprovationModification = (leaseId, proposalId, payload) => (dispatch, getState) =>
  dispatch({
    type: SEND_APPROVATION_MODIFICATION.ACTION,
    payload: serviceProposal.sendApprovationModification(getState().user.authToken)(
      leaseId,
      proposalId,
      decamelizeResponse(payload)
    ),
  })

export const getAllProposals = () => (dispatch, getState) =>
  dispatch({
    type: GET_ALL_PROPOSALS.ACTION,
    payload: serviceProposal.getAllProposals(getState().user.authToken).then(camelizeResponse),
  })

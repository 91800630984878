import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
  wrapper: {
    maxWidth: 688,
    margin: 'auto',
    boxSizing: 'content-box',
    padding: theme.spacing(10, 3, 3),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(5),
    },
  },
  loading: {
    marginTop: '11rem',
  },
}))

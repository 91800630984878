import React, { PureComponent, Fragment } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { Link } from '@reach/router'

import Home from '_assets/images/ic-home.svg'
import { sendEvent } from '_utils/mixpanel'

import styles from './styles.css'

class Base extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    sections: PropTypes.arrayOf({
      name: PropTypes.string,
      url: PropTypes.string,
    }).isRequired,
  }

  static defaultProps = {
    className: '',
  }

  onHomeClick = () => {
    sendEvent('Mapa de Navegação: Selecionou navegar para página inicial')
  }

  onSearchClick = index => () => {
    const { sections } = this.props
    if (sections[index].mixpanel) {
      sendEvent(`Mapa de Navegação: Selecionou ${sections[index].mixpanel}`)
    }
  }

  render() {
    const { sections, className } = this.props
    return (
      <div className={classnames(styles.breadcrumb, className)}>
        <Link key="breadcrumb-home" to="/" onClick={this.onHomeClick}>
          <svg alt="Home" className={styles.icon}>
            <use xlinkHref={Home} />
          </svg>
        </Link>

        {sections.map((section, index) => (
          <Fragment>
            <p>{'>'}</p>
            <Link
              key={`breadcrumb-${section.name}`}
              to={section.url}
              onClick={this.onSearchClick(index)}
            >{`${section.name}`}</Link>
          </Fragment>
        ))}
      </div>
    )
  }
}

export default Base

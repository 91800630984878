import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Chip } from '@material-ui/core'

import { STATUS_CHIP_THEME, STATUS_OPTIONS } from './constants'
import useStyles from './styles'

const StatusChip = ({ className, size, option }) => {
  const styles = useStyles()
  return (
    option && (
      <Chip
        size={size}
        label={STATUS_OPTIONS[option].label}
        className={classnames(styles.chip, styles[STATUS_OPTIONS[option].theme], className)}
      />
    )
  )
}

StatusChip.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string,
  option: PropTypes.shape({
    theme: PropTypes.oneOf(Object.values(STATUS_CHIP_THEME)),
    label: PropTypes.string,
  }),
}

StatusChip.defaultProps = {
  className: '',
  size: 'small',
  option: null,
}

export default StatusChip

import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, globalHistory } from '@reach/router'
import qs from 'qs'
import PropTypes from 'prop-types'

import { RedirectContext } from '_context/redirect'
import { isUserLoggedIn } from '_modules/user/selectors'
import { primaryColor } from '_utils/colors'

const withAuthOrIncomeHelper = WrappedComponent => {
  const WithAuthOrIncomeHelper = props => {
    const parsedQuery = qs.parse((props.location.search || '').split('?').pop())
    const isLoggedIn = useSelector(isUserLoggedIn)
    const isMobile = useSelector(state => state.server.isMobile)
    const redirect = useContext(RedirectContext)
    const { location } = globalHistory
    const hasVerificationToken =
      location.search.includes('?verification_token=') ||
      location.search.includes('&verification_token=')
    if (!isLoggedIn && !parsedQuery.token && !hasVerificationToken) {
      redirect.setRedirectUri(location.pathname + location.search)
      redirect.setOpenLogin(true)
      return <Redirect to="/" noThrow />
    }

    return <WrappedComponent primaryColor={primaryColor} isMobile={isMobile} {...props} />
  }

  WithAuthOrIncomeHelper.propTypes = {
    location: PropTypes.shape({
      search: PropTypes.string,
    }).isRequired,
  }

  return React.memo(WithAuthOrIncomeHelper)
}

export default withAuthOrIncomeHelper

import humps from 'humps'

import { post } from './requests'

export const createListingViewLog = key => payload =>
  post(['listing-view-logs'], { key }, humps.decamelizeKeys(payload))

export const viewPicture = key => id => post(['listing-view-logs', id, 'view-picture'], { key })

export const viewTenSeconds = key => id =>
  post(['listing-view-logs', id, 'view-five-seconds'], { key })

export const logLikedListing = key => id => post(['listing-view-logs', id, 'liked'], { key })

export const logUnlikedListing = key => id => post(['listing-view-logs', id, 'unliked'], { key })

export const logDislikedListing = key => id => post(['listing-view-logs', id, 'liked'], { key })

export const logUndislikedListing = key => id => post(['listing-view-logs', id, 'unliked'], { key })

export const viewMap = key => id => post(['listing-view-logs', id, 'view-map'], { key })

export const viewStreet = key => id => post(['listing-view-logs', id, 'view-street'], { key })

export const logScheduleClick = key => id => post(['listing-view-logs', id, 'schedule'], { key })

import React, { useState, useCallback, useContext, useMemo } from 'react'
import { Grid, Typography } from '@material-ui/core'
import classnames from 'classnames'
import { navigate } from '@reach/router'
import { useSelector, useDispatch } from 'react-redux'

import TokenContext from '_context/token'
import RadioGroup from '_components/radio-group'
import Button from '_components/material/button'
import ModalAddPerson from '_components/modal-add-person'
import useModal from '_hooks/use-modal'
import useOnSuccessCall from '_hooks/use-on-success'
import { PARTICIPANTS_TYPE, WARRANTY_TYPES } from '_utils/constants'
import { getLeaseById } from '_modules/leases/selectors'
import { SUBMIT_CREDPAGO_ANALYSIS, submitCredpagoAnalysis } from '_modules/leases/actions'

import { CREDIT_ANALYSIS_ROUTES, creditAnalysisFlow } from '../router'

import WarrantyChip from './warranty-chip'
import useStyles from './styles'
import { CREDIT_ANALYSIS_NEGOTIATION, NEW, INVITE } from './constants'

const CreditAnalysisNegotiation = () => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const { leaseId, participantType } = useContext(TokenContext)
  const lease = useSelector(state => getLeaseById(state, leaseId))
  const { modalOpen: isAddModalOpen, handleModal } = useModal()
  const [answer, setAnswer] = useState(NEW)

  const content = useMemo(() => {
    if (participantType === PARTICIPANTS_TYPE.INCOME_HELPER && lease.hasRequestedParticipants) {
      return CREDIT_ANALYSIS_NEGOTIATION[`${participantType}_REQUESTED`]
    }
    return CREDIT_ANALYSIS_NEGOTIATION[participantType]
  }, [lease.hasRequestedParticipants, participantType])

  const handleRadioOnChange = useCallback(event => {
    setAnswer(event.target.value)
  }, [])

  const handleRedirection = useCallback(() => {
    switch (answer) {
      case NEW:
        handleModal()
        break
      case INVITE:
        if (participantType === PARTICIPANTS_TYPE.INCOME_HELPER) {
          navigate(creditAnalysisFlow(CREDIT_ANALYSIS_ROUTES.INVITE_INCOME_HELPER, leaseId))
          return
        }
        if (participantType === PARTICIPANTS_TYPE.GUARANTOR) {
          navigate(creditAnalysisFlow(CREDIT_ANALYSIS_ROUTES.INVITE_GUARANTOR, leaseId))
          return
        }
        navigate(creditAnalysisFlow(CREDIT_ANALYSIS_ROUTES.INVITE_TENANT, leaseId))
        break

      default:
        if (
          participantType === PARTICIPANTS_TYPE.INCOME_HELPER &&
          lease.warranty === WARRANTY_TYPES.GUARANTOR
        ) {
          navigate(creditAnalysisFlow(CREDIT_ANALYSIS_ROUTES.ADD_GUARANTOR, leaseId))
          return
        }
        if (participantType === PARTICIPANTS_TYPE.INCOME_HELPER) {
          navigate(creditAnalysisFlow(CREDIT_ANALYSIS_ROUTES.ADD_TENANT, leaseId))
          return
        }
        navigate(creditAnalysisFlow(CREDIT_ANALYSIS_ROUTES.READY, leaseId))
    }
  }, [answer, handleModal, lease.warranty, leaseId, participantType])

  const handleOnClick = useCallback(() => {
    if (
      participantType === PARTICIPANTS_TYPE.TENANT &&
      lease.warranty === WARRANTY_TYPES.CREDPAGO
    ) {
      dispatch(submitCredpagoAnalysis(leaseId))
      return
    }
    handleRedirection()
  }, [dispatch, handleRedirection, lease.warranty, leaseId, participantType])

  const [isLoading] = useOnSuccessCall(SUBMIT_CREDPAGO_ANALYSIS.ACTION, handleRedirection)

  return (
    <div className={styles.wrapper}>
      <Grid container spacing={2} alignItems="center">
        <WarrantyChip warranty={lease && lease.warranty} />
        <Grid item xs={12}>
          <Grid className={styles.addInformation}>
            {content.icon && (
              <svg aria-hidden="true" className={classnames(styles[content.style], styles.desktop)}>
                <use xlinkHref={content.icon} />
              </svg>
            )}
            <Grid>
              <Typography
                component="h1"
                variant="h4"
                color="textPrimary"
                className={styles.infoTitle}
              >
                {content.title}
              </Typography>
              <Typography component="p" variant="subtitle1" color="textPrimary">
                {content.subtitle}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className={styles.margins}>
          <Typography variant="h5" component="h2" color="textPrimary">
            {content.question}
          </Typography>
        </Grid>
        <Grid item xs={12} className={styles.margins}>
          <Typography variant="subtitle1" component="p" color="textSecondary">
            {content.questionDescription}
          </Typography>
        </Grid>
        <Grid item xs={12} className={styles.margins}>
          <RadioGroup
            value={answer}
            onChange={handleRadioOnChange}
            optionClassName={styles.option}
            options={content.options}
          />
        </Grid>
        <Grid item xs={12} className={styles.buttonWrapper}>
          <Button
            isLoading={isLoading}
            variant="contained"
            className={styles.button}
            onClick={handleOnClick}
          >
            Próximo
          </Button>
        </Grid>
      </Grid>
      {isAddModalOpen && (
        <ModalAddPerson
          onClose={handleModal}
          type={participantType}
          warrantyType={lease && lease.warranty}
          leaseId={leaseId}
        />
      )}
    </div>
  )
}

export default React.memo(CreditAnalysisNegotiation)

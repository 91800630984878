import { makeStyles } from '@material-ui/styles'

export default makeStyles(() => ({
  simpleCard: {
    display: 'grid',
    width: '100%',
    padding: '2.4rem',
  },
  cardContent: {
    padding: 0,
  },
  title: {
    fontWeight: '500',
    lineHeight: '2.1rem',
    marginBottom: '0.8rem',
  },
  description: {
    marginBottom: '3.2rem',
  },
  cardActions: {
    padding: 0,
  },
  button: {
    width: '100%',
    height: '4rem',
  },
}))
